import classNames from "classnames";
import DefaultError from "client/ui-components/DefaultError";
import React, { useEffect } from "react";
import { ItemFilters, ItemsTable } from "./components";
import { useItems } from "./helpers/hooks";
import { useInventoryStore } from "./helpers/inventoryStore";
import { Wrapper } from "./styles";

type InventoryProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

const Inventory = ({ className, ...props }: InventoryProps) => {
  const { error, isLoading, items, count } = useItems();
  const { setHasBulkOptions } = useInventoryStore();
  if (error) return <DefaultError />;

  useEffect(() => {
    setHasBulkOptions(false);
  }, []);

  return (
    <Wrapper {...props} className={classNames("mb-5 px-0", className)}>
      <ItemFilters count={count} />
      <ItemsTable items={items} isLoading={isLoading} count={count || 0} />
    </Wrapper>
  );
};

export default Inventory;
