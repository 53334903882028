import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import z from "zod";

import { Button, Heading, TextInput } from "../../../../ui-components";

import { Container, Form, StyledHeading, Subtitle } from "./styles";

import newspaperSvg from "client/assets/newspaper.png";
import { SubmitHandler, useForm } from "react-hook-form";

const PARTNER_FORM_URL = "https://share.hsforms.com/1cZ5RWZgiQfKIdoOLZ8SHLwc60y2";
const CLIENT_FORM_URL = "https://share.hsforms.com/1tKzN2L4-TK2JaxMXoNdAZQc60y2";

const formSchema = z.object({
  email: z.string().min(1, { message: "This field is required" }),
});

type FormSchema = z.infer<typeof formSchema>;

function SignForNewsLetter({
  setSubscribed,
  isClientForm,
}: {
  setSubscribed: (subscribed: boolean) => void;
  isClientForm?: boolean;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchema>({
    mode: "onSubmit",
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormSchema> = data => {
    const formLink = `${isClientForm ? CLIENT_FORM_URL : PARTNER_FORM_URL}?email=${data.email}`;
    window.open(formLink, "_blank");
    setSubscribed(true);
  };

  return (
    <Container>
      <div className="text-center">
        <img loading="lazy" width={41} height={48} src={newspaperSvg} alt="newspaper" />
        <StyledHeading className="mt-25 m-auto" forwardedAs="h3" variant="h3" textAlign="center">
          Sign up for our newsletter
        </StyledHeading>
      </div>
      <Subtitle>
        Sign up for our newsletter and stay up to date on how Boomerang is revolutionizing lost and
        found.
      </Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label=""
          type="email"
          tabIndex={1}
          rounded
          placeholder="Enter your email"
          ariaLabel="Enter your email"
          errors={errors.email}
          isValidated={errors.email !== undefined}
          {...register("email", { required: true })}
        />

        <Button variant="secondary" aria-label="Sign up" type="submit">
          Subscribe
        </Button>
      </Form>
    </Container>
  );
}

const ThanksForSubscribing = () => {
  return (
    <Container>
      <div className="text-center">
        <img loading="lazy" width={41} height={48} src={newspaperSvg} alt="newspaper" />
        <Heading
          className="mt-25 m-auto"
          style={{ maxWidth: "768px", lineHeight: "48px" }}
          as="h3"
          variant="h3"
          textAlign="center"
        >
          Thanks for subscribing!
        </Heading>
      </div>
      <Subtitle
        style={{
          maxWidth: 476,
        }}
      >
        Keep an eye on your inbox—we're excited to bring you along on our journey.
      </Subtitle>
    </Container>
  );
};

export default function SignForNewsletter({ isClientForm }: { isClientForm?: boolean }) {
  const [subscribed, setSubscribed] = useState(false);

  return subscribed ? (
    <ThanksForSubscribing />
  ) : (
    <SignForNewsLetter setSubscribed={setSubscribed} isClientForm={isClientForm} />
  );
}
