const countryCurrencyMap = {
  US: "USD",
  GB: "GBP",
};

export default function formatCurrency({
  amount,
  currency,
  country = "US",
}: {
  amount: number;
  currency?: string;
  country?: string;
}) {
  const currentCurrency = currency || countryCurrencyMap[country];
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currentCurrency,
  }).format(amount);
}
