import usePartnerLocations from "./data/partner/usePartnerLocations";

type UnitSystem = Record<"dimensions" | "weight", "in" | "cm" | "lbs" | "kg">;

const metric: UnitSystem = {
  dimensions: "cm",
  weight: "kg",
} as const;

const imperial: UnitSystem = {
  dimensions: "in",
  weight: "lbs",
} as const;

const preferredUnits: Record<string, UnitSystem> = {
  US: imperial,
  GB: metric,
};

export const useLocationPreferredUnits = (partnerId?: string) => {
  const { defaultPartnerLocation } = usePartnerLocations(partnerId || "");
  const country = defaultPartnerLocation?.address.country_code ?? "";
  return preferredUnits[country] || preferredUnits.US;
};
