import React from "react";
import { useParams } from "react-router-dom";
import useGetImagesWithClaimId from "../../../hooks/data/user/useGetImagesWithClaimId";

import useClaim from "client/hooks/data/user/useClaim";
import Loader from "../../../components/Loader";
import ClaimForm from "./ClaimForm";

interface UrlParams {
  claimId: string;
}

export const EditClaim = () => {
  const { claimId } = useParams<UrlParams>();

  const { claim, isLoading: claimLoading } = useClaim(claimId);
  const { data: claimImages, isLoading: claimImagesLoading } = useGetImagesWithClaimId(claimId);

  const loading = claimLoading || claimImagesLoading;
  if (loading) {
    return <Loader />;
  }

  return <ClaimForm claim={claim} claimImages={claimImages} />;
};
