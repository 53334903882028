import classNames from "classnames";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import theme from "../../theme";

import { useAuthState } from "../../context/auth";

import { CustomerMenuItems } from "../../types/menuElements";

import BasicDropdown from "./BasicDropdown";

import { StyledSpan, StyledUserIcon, UserDropdownItemWrapper } from "./styles";
import { DropdownAlignment } from "./types";

enum DropdownStates {
  OPEN = "dropdown-open",
}

const UserProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const { user } = useAuthState();

  return (
    <BasicDropdown
      ariaLabel="User Profile Dropdown"
      closeOnTab={false}
      dropdownAlignment={DropdownAlignment.BOTTOM_END}
      dropdownTriggerElement={
        <div role="button" style={{ cursor: "pointer" }} data-testid="userProfileDropdown">
          <StyledUserIcon
            width={36}
            height={36}
            tabIndex={0}
            className={dropdownOpen && DropdownStates.OPEN}
            titleId="ProfileDropdownIconTitle"
            accessibilityTitle={`${dropdownOpen ? "Close" : "Open"} profile dropdown`}
          />
        </div>
      }
      showDropdown={dropdownOpen}
      setShowDropdown={setDropdownOpen}
      width="414px"
    >
      {CustomerMenuItems.map((menuItem, i) => {
        if (menuItem.name === "Logout") {
          return (
            <div key={i}>
              <div>
                <hr className="dropdown-divider my-25 mx-35" />
              </div>
              <div className="px-35 py-2">
                <StyledSpan bold>{user.full_name}</StyledSpan>
                <StyledSpan>{user.email}</StyledSpan>
              </div>
              <UserDropdownItem
                isActive={location.pathname === menuItem.url}
                text={menuItem.name}
                icon={<div className="me-3 ms-0">{menuItem.icon}</div>}
                key={i}
                onClick={() => {
                  menuItem.onClick();
                  setDropdownOpen(false);
                }}
              />
            </div>
          );
        }
        return (
          <UserDropdownItem
            isActive={location.pathname === menuItem.url}
            text={menuItem.name}
            icon={<div className="me-3 ms-0">{menuItem.icon}</div>}
            key={i}
            onClick={() => {
              menuItem.onClick();
              setDropdownOpen(false);
            }}
          />
        );
      })}
    </BasicDropdown>
  );
};

interface UserDropdownItemProps {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
  isActive?: boolean;
}

const UserDropdownItem: React.FC<UserDropdownItemProps> = ({ text, icon, isActive, onClick }) => {
  return (
    <UserDropdownItemWrapper
      onClick={onClick}
      tabIndex={0}
      className={classNames("px-35 fw500 align-items-center", {
        active: isActive,
      })}
      color={text === "Logout" ? theme.colors.red : ""}
      data-testid={text}
    >
      {!!icon && icon} {text}
    </UserDropdownItemWrapper>
  );
};

export default UserProfileDropdown;
