import { ArtifactImagePreview } from "../../../types";

export enum ClaimSteps {
  PARTNER_SELECTION = 0,
  LOCATION_SELECTION = 1,
  ITEM_DETAILS = 2,
  CONTACT_DETAILS = 3,
  REVIEW_DETAILS = 4,
  VERIFICATION_CODE = 5,
  SUBMITTED = 6,
}

export type ItemDetails = {
  foundDate: Date;
  itemCategoryIdValue: string;
  detailedDescription: string;
  itemName: string;
  images: ArtifactImagePreview[];
  storageLocationIdValue: string;
};
