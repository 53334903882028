import { useQueryClient } from "@tanstack/react-query";
import { useBulkItemUpdate } from "client/actions/partnerItems";
import { InlineDatePicker } from "client/components/InlineDatePicker";
import Modal from "client/components/Modal";
import { usePartnerUnclaimedStatuses } from "client/hooks/data/category-by-partner";
import itemQueries from "client/hooks/data/partner/queryFactory/itemQueries";
import { UnclaimedStatus } from "client/types";
import { If } from "client/utils/If";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { PartnerItem } from "../../../../types/models";
import { toSuggestedUnclaimedStatus, toUnclaimedAction } from "../../UnclaimedSuggestion/helpers";
import { StatusPicker } from "./StatusPicker";

const HeaderContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 28px;
  flex-direction: column;
  max-width: 386px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  color: var(--primary-700-for-text, #00174a);
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
`;

const Description = styled.div`
  color: var(--primary-700-for-text, #00174a);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

interface SuccessModalProps {
  isLg: boolean;
  item: PartnerItem;
  closeModal: () => void;
  showModal: boolean;
}

const ArchiveModal: React.FC<SuccessModalProps> = props => {
  const { closeModal, item, showModal } = props;
  const [step, setStep] = React.useState(0);
  const [status, setStatus] = React.useState<UnclaimedStatus>();
  const [date, setDate] = React.useState<Date>(new Date());
  const partnerId = useCurrentPartner();
  const queryClient = useQueryClient();
  const { data: unclaimedStatuses } = usePartnerUnclaimedStatuses(partnerId);
  const validUnclaimedStatuses = unclaimedStatuses?.map(({ unclaimed_status }) => unclaimed_status);
  const { mutateAsync: updateItems, isPending: isUpdating } = useBulkItemUpdate();
  const previousStep = () => setStep(current => Math.max(0, current - 1));
  const nextStep = () => setStep(current => Math.min(1, current + 1));

  const onClose = () => {
    setStep(0);
    setStatus(undefined);
    closeModal();
  };

  const onSubmit = async () => {
    try {
      if (!item?.id) throw new Error("Missing item id");
      await updateItems({
        partner_id: partnerId,
        item_ids: [item.id],
        set_to_unclaimed: true,
        unclaimed_status: status,
        unclaimed_status_set_at: date,
      });
      queryClient.invalidateQueries({
        queryKey: ["item", partnerId, item.id],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: itemQueries.items(partnerId),
        refetchType: "all",
      });
      onClose();
      return toast.success(`Item has been successfully archived.`);
    } catch (e) {
      console.error(e);
      toast.error("Failed to archive this item.");
    }
  };

  return (
    <Modal
      topBtnText={step === 0 || !status ? "Next" : toSuggestedUnclaimedStatus(status)}
      topBtnDisabled={step === 0 ? !status : !date}
      topBtnOnClick={step === 0 ? nextStep : onSubmit}
      topBtnLoading={isUpdating}
      bottomBtnText="Go back"
      bottomBtnOnClick={step === 0 ? onClose : previousStep}
      onXButton={onClose}
      show={showModal}
      showXButton
    >
      <If condition={step === 0}>
        <StatusContainer>
          <HeaderContainer>
            <Header>What will you do with the item?</Header>
            <Description>Select an appropriate status from the options below.</Description>
          </HeaderContainer>
          <div style={{ padding: 4 }}>
            <StatusPicker
              selected={status}
              allowedStatuses={validUnclaimedStatuses ?? []}
              onChange={setStatus}
            />
          </div>
        </StatusContainer>
      </If>
      <If condition={step === 1}>
        <CalendarContainer>
          <HeaderContainer>
            <Header>Set Archived Date</Header>
            <Description>
              Choose the date to mark the item as{" "}
              {status === UnclaimedStatus.MORE_LIFE
                ? "sold through Boomerang’s More Life Program"
                : toUnclaimedAction(status as any)}
              .
            </Description>
          </HeaderContainer>
          <InlineDatePicker onChange={setDate as any} />
        </CalendarContainer>
      </If>
    </Modal>
  );
};

export default ArchiveModal;
