import classNames from "classnames";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useClickAway } from "../../../hooks";
import { Content, Dialog, Footer, Header, ModalWrapper, Overlay } from "./styles";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  body?: React.ReactNode;
};

export default function DatePickerModal({
  id,
  isOpen,
  onClose,
  body,
  footer,
  header,
  ...props
}: Props) {
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);

  useClickAway(modalRef, () => onClose());

  useEffect(() => {
    isOpen
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [isOpen]);

  return !isOpen
    ? null
    : ReactDOM.createPortal(
        <Overlay isOpen={isOpen} ref={overlayRef}>
          <ModalWrapper
            {...props}
            aria-hidden={isOpen ? "false" : "true"}
            aria-labelledby={id}
            className={classNames("modal fade", isOpen && "show")}
            id={id}
            isOpen={isOpen}
            role="dialog"
            tabIndex={-1}
          >
            <Dialog className="modal-dialog modal-dialog-centered" role="document" ref={modalRef}>
              <Content className="modal-content" data-testid="calendarModal">
                {header && <Header className="modal-header">{header}</Header>}

                {body && <div className="modal-body">{body}</div>}

                {footer && (
                  <Footer className="modal-footer" data-testid="calendarCancelButton">
                    {footer}
                  </Footer>
                )}
              </Content>
            </Dialog>
          </ModalWrapper>
        </Overlay>,
        document.body,
      );
}
