import { fetchApiData } from "client/utils/fetchApiData";
import {
  CompleteTransactionRequest,
  CreateCheckoutBody,
  CreateCheckoutResponse,
  MailingAddressCreationBody,
  MailingAddressCreationResponse,
  MailingAddressResponse,
  ShipmentCreationBody,
  ShipmentCreationResponse,
  ShipmentResponse,
  StateResponse,
  StatesResponse,
} from "../types";
import { getCommonHeaders } from "../utils/headers";
import { ApiHandlerRes, useApiHandler } from "./index";

const getStates = async (): Promise<StatesResponse> => {
  return fetchApiData({ url: `/api/user/states/?ordering=name` });
};

export const useGetStates = (): ApiHandlerRes<null, StatesResponse> => useApiHandler(getStates);

const getState = async (stateId: string): Promise<StateResponse> => {
  return fetchApiData({ url: `/api/user/states/${stateId}/` });
};

// As of ENG-1432, this one is no longer being used,
// but we'll leave it here just in case there is a use for it in the future
export const useGetState = (): ApiHandlerRes<string, StateResponse | null> =>
  useApiHandler(getState);

const createMailingAddress = async (
  mailingAddress: MailingAddressCreationBody,
): Promise<MailingAddressCreationResponse | null> => {
  return fetchApiData({
    url: "/api/user/mailing-addresses/",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(mailingAddress),
    },
  });
};

export const useCreateMailingAddress = (): ApiHandlerRes<
  MailingAddressCreationBody,
  MailingAddressCreationResponse | null
> => useApiHandler(createMailingAddress);

const getMailingAddress = async (addressId: string): Promise<MailingAddressResponse> => {
  return fetchApiData({ url: `/api/user/mailing-addresses/${addressId}/` });
};

export const useGetMailingAddress = (): ApiHandlerRes<string, MailingAddressResponse> =>
  useApiHandler(getMailingAddress);

const patchMailingAddress = async (
  addressId: string,
  mailingAddress: MailingAddressCreationBody,
): Promise<MailingAddressResponse> => {
  return fetchApiData({
    url: `/api/user/mailing-addresses/${addressId}/`,
    options: {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify(mailingAddress),
    },
  });
};

export const usePatchMailingAddress = (): ApiHandlerRes<string, MailingAddressResponse> =>
  useApiHandler(patchMailingAddress);

export const getShipment = async (shipmentId: string): Promise<ShipmentResponse> => {
  return fetchApiData({ url: `/api/user/shipments/${shipmentId}/` });
};
export const useGetShipment = (): ApiHandlerRes<string, ShipmentResponse> =>
  useApiHandler(getShipment);

const createShipment = async (
  shipmentDetails: ShipmentCreationBody,
): Promise<ShipmentCreationResponse> => {
  return fetchApiData({
    url: "/api/user/shipments/",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(shipmentDetails),
    },
  });
};

export const useCreateShipment = (): ApiHandlerRes<
  ShipmentCreationBody,
  ShipmentCreationResponse
> => useApiHandler(createShipment);

const completeTransaction = async (
  request: CompleteTransactionRequest,
): Promise<CreateCheckoutResponse> => {
  return fetchApiData({
    url: `/api/user/transactions/${request.transactionId}/complete/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(request.rate),
    },
  });
};

export const useCompleteTransaction = (): ApiHandlerRes<
  CompleteTransactionRequest,
  CreateCheckoutResponse
> => useApiHandler(completeTransaction);

const patchShipment = async (
  shipmentId: string,
  shipmentDetails: ShipmentCreationBody,
): Promise<ShipmentCreationResponse> => {
  return fetchApiData({
    url: `/api/user/shipments/${shipmentId}/`,
    options: {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify(shipmentDetails),
    },
  });
};
export const usePatchShipment = (): ApiHandlerRes<string, ShipmentCreationResponse> =>
  useApiHandler(patchShipment);

const createCheckout = async (
  shipmentId: string,
  createCheckoutBody: CreateCheckoutBody,
): Promise<CreateCheckoutResponse> => {
  return fetchApiData({
    url: `/api/user/shipments/${shipmentId}/checkout/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(createCheckoutBody),
    },
  });
};

export const useCreateCheckout = (): ApiHandlerRes<string, CreateCheckoutResponse> =>
  useApiHandler(createCheckout);
