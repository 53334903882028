import styled from "styled-components";

export const SuggestedItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 16px;
  background: #fff;
  min-height: 60px;
  padding: 12px;
  gap: 12px;

  /* Focus (State) */
  &:focus {
    box-shadow: 0px 0px 0px 2px #0772e4;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--gray-500-for-lines, #dce1e8);
  background: var(--Primary-300, #f3f9ff);
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
