import classNames from "classnames";
import Edit from "client/assets/ui-icons/Edit";
import useCancelClaim from "client/hooks/data/user/useCancelClaim";
import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { CancelClaimContext } from "../../../../../../context/CancelClaim";
import { ClaimDetailsContext } from "../../../../../../context/ClaimDetails";
import history from "../../../../../../context/history";
import { useClickAway } from "../../../../../../hooks";
import { Button, Heading } from "../../../../../../ui-components";
import {
  ButtonWrapper,
  CloseButton,
  ModalContent,
  ModalDialog,
  ModalHeader,
  ModalWrapper,
  StyledButton,
} from "./styles";

type Props = {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

export default function Modal({ isOpened, setIsOpened }: Props): React.ReactPortal | null {
  const { pathname } = useLocation();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = useState<{ isReady: boolean }>({ isReady: false });
  const { claim } = useContext(ClaimDetailsContext);
  const { setStep } = useContext(CancelClaimContext);
  const cancelClaim = useCancelClaim();

  useClickAway(modalRef, () => setIsOpened(false));

  useEffect(() => {
    setState(s => ({ ...s, isReady: true }));
  }, []);

  useEffect(() => {
    if (cancelClaim.isError) {
      toast.error("There's been an error while canceling your claim.");
    }
  }, [cancelClaim.isError]);

  useEffect(() => {
    if (cancelClaim.isPending) return;
    if (cancelClaim.isSuccess) {
      history.push(`${claim?.id}/cancel`);
    }
  }, [cancelClaim.isSuccess, cancelClaim.isPending, claim?.id]);

  return !state.isReady || claim === null || claim === undefined
    ? null
    : createPortal(
        <ModalWrapper
          className={classNames("modal fade", isOpened && "show", "modal-dialog-centered")}
          id="editClaimModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="editClaimModal"
          aria-hidden={!isOpened}
          isOpened={isOpened}
        >
          <ModalDialog ref={modalRef} className="modal-dialog" role="document">
            <ModalContent className="modal-content">
              <ModalHeader className="modal-header justify-content-end">
                <CloseButton
                  type="button"
                  className="close"
                  data-testid="closeModalXButton"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsOpened(false)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8332 1.16675L1.1665 12.8334M1.1665 1.16675L12.8332 12.8334"
                      stroke="#00174A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </CloseButton>
              </ModalHeader>

              <div className="modal-body p-0">
                <Heading variant="h4" as="h4" textAlign="center" className="mb-4 mb-lg-45">
                  Claim options
                </Heading>

                <ButtonWrapper className="d-flex flex-column gap-25">
                  <Button
                    size="2xl"
                    href={`${pathname}/edit`}
                    aria-label="Edit claim"
                    data-testid="editClaimButton"
                    variant="outline"
                    icon={<Edit accessibilityTitle="edit" titleId="edit" color="#00174A" />}
                  >
                    Edit claim
                  </Button>
                  <StyledButton
                    size="2xl"
                    aria-label="Cancel claim"
                    variant="outline"
                    /* TODO: Remove this when collect the cancel reason is implemented */
                    onClick={() => {
                      cancelClaim.mutate({
                        claimId: claim.id,
                        request: {
                          canceled_reason: "OTHER",
                          canceled_reason_other: "Not collected",
                        },
                      });
                      setStep("confirmation");
                    }}
                  >
                    Cancel your claim
                  </StyledButton>
                </ButtonWrapper>
              </div>
            </ModalContent>
          </ModalDialog>
        </ModalWrapper>,
        document.body,
        "modal",
      );
}
