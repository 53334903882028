import { useHistory, useLocation } from "react-router-dom";

type SearchParamValue = string | number;

function formatValue<T extends SearchParamValue>(value: string | null, defaultValue: T): T {
  if (!value) return defaultValue;
  if (typeof defaultValue === "string") {
    defaultValue;
    return (value || defaultValue) as T;
  }
  if (typeof defaultValue === "number") {
    return (Number(value) || defaultValue) as T;
  }
  return defaultValue;
}

function useSearchParam<T extends SearchParamValue>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void] {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const value = formatValue(searchParams.get(key), defaultValue);

  const setQueryParam = (value: T) => {
    searchParams.set(key, value?.toString() ?? "");
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return [value, setQueryParam];
}

export default useSearchParam;
