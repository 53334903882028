import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

import { TextInputWrapper } from "./styles";

type InputProps = React.PropsWithoutRef<JSX.IntrinsicElements["input"]> & {
  ariaLabel: string;
  errors?: FieldError;
  isValidated?: boolean;
  label?: string;
  rounded?: boolean;
};

const TextInput = (
  { ariaLabel, className, errors, isValidated, label, name, rounded, ...props }: InputProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  return (
    <TextInputWrapper>
      {label && <label htmlFor={name}>{label}</label>}

      <input
        {...props}
        aria-label={ariaLabel}
        className={classNames(className, {
          "px-35 py-25 rounded": rounded,
          "is-invalid": isValidated && errors !== undefined,
        })}
        id={name}
        name={name}
        ref={ref}
      />

      {errors !== undefined && <span>{errors.message}</span>}
    </TextInputWrapper>
  );
};

export default React.forwardRef(TextInput);
