import classNames from "classnames";
import React, { forwardRef } from "react";
import theme from "../../theme";
import Text from "../Text";
import { Input } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["input"]> & {
  wrapperProps?: React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
    "data-testid"?: string;
  };
  label?: string;
  squareSize?: "md" | "lg";
  error?: string;
  withoutLabel?: boolean;
};

function Checkbox(
  { className, wrapperProps, id, label, error, squareSize = "md", withoutLabel, ...props }: Props,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <div {...wrapperProps} className={classNames("form-check", wrapperProps?.className)}>
      <Input
        {...props}
        className={classNames(
          "form-check-input",
          `size-${squareSize}`,
          { "is-invalid": error !== undefined },
          className,
        )}
        type="checkbox"
        id={id}
        ref={ref}
      />
      {!withoutLabel && (
        <>
          <Text as="label" className="form-check-label ms-25" htmlFor={id}>
            {label}
          </Text>

          <div className="invalid-feedback ms-n4">
            <Text fontSize="14px" lineHeight="20px" color={theme.colors.red}>
              {error}
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, Props>(Checkbox);
