import { useQuery } from "@tanstack/react-query";
import { PartnerReturnsCountByModeRequest } from "client/types";
import queryFactory from "../queryFactory";

export default (request: PartnerReturnsCountByModeRequest) => {
  const { data, isLoading, error } = useQuery(
    queryFactory.returnCountByRecoveryMethodFiltered(request),
  );

  return {
    returnsCountByRecoveryMethod: data,
    isLoading,
    error,
  };
};
