import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "../context/auth";

function AnonRoute({ children, ...rest }) {
  const { user, partner } = useAuthState();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user?.id ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: partner ? "/partner/overview" : "/claims",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default AnonRoute;
