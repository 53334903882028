import React from "react";

import { AuthReducer, AuthReducerContext } from "./reducer";
import { AuthContext, initialAuthState } from "./state";

export function useAuthState() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a Auth Provider");
  }

  return context;
}

// Auth if you need to modify the auth state
export function useAuthDispatch() {
  const context = React.useContext(AuthReducerContext);

  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a Auth Provider");
  }

  return context;
}

// use if you need to read and modify the auth state or dispatch an action
export function useAuthContext() {
  return [useAuthState(), useAuthDispatch];
}

// add both read and write providers
export const AuthProvider = props => {
  const { children, initialState } = props;
  const [state, dispatch] = React.useReducer(AuthReducer, initialState ?? initialAuthState);

  return (
    <AuthContext.Provider value={state}>
      <AuthReducerContext.Provider value={dispatch}>{children}</AuthReducerContext.Provider>
    </AuthContext.Provider>
  );
};
