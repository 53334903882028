import React, { useEffect } from "react";
import { useAuthState } from "../../context/auth";
import usePartners from "../../hooks/data/partner/usePartners";
import theme from "../../theme";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";
import Loader from "../Loader";
import { AccountListItem } from "../MobileMenu/SwitchAccountTab";
import SimpleHeader from "../SimpleHeader";
import { Subheader } from "../typography";
import { Content, Divider, Wrapper } from "./styles";

interface SwitchAccountDesktopProps {
  onLeftArrowClick: () => void;
  onPartnerSelect: (partnerId: string) => void;
  partnerId: string;
}

const SwitchAccountDesktop: React.FC<SwitchAccountDesktopProps> = props => {
  const { onLeftArrowClick, onPartnerSelect, partnerId } = props;
  const { user } = useAuthState();

  const { partners: allPartners, isLoading } = usePartners(user.partners);

  useEffect(() => {
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  return (
    <>
      <SimpleHeader onLeftArrowClick={onLeftArrowClick} title={"Switch account"} />
      <Content role="main" className="row mx-0 justify-content-center px-3 px-lg-0">
        <Wrapper className="mb-5 py-35">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Subheader aria-label="menu title for select an account" className="px-45">
                Select an account
              </Subheader>
              <Divider className="mb-0" />
              {allPartners.map((partner, index) => {
                if (!partner) {
                  return;
                }

                return (
                  <AccountListItem
                    key={index}
                    className="px-45"
                    data-testid="accountList"
                    fontSize={theme.fontSizes.default}
                    highlightColor={theme.colors.gray10}
                    isCurrent={partner.id === partnerId}
                    itemKey={index}
                    onClick={() => {
                      onPartnerSelect(partner.id);
                    }}
                    name={partner.name}
                  />
                );
              })}
            </>
          )}
        </Wrapper>
      </Content>
    </>
  );
};

export default SwitchAccountDesktop;
