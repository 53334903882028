import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArtifactImage, ImagePreview, UpdateClaimRequest, UpdateClaimResponse } from "client/types";
import updateClaim from "./fetchFunctions/updateClaim";
import queryFactory from "./queryFactory";
import useCreateClaimImages from "./useCreateClaimImages";
import useDeleteClaimImages, { DeleteClaimImagesMutationResponse } from "./useDeleteClaimImages";

type UpdateClaimMutationResponse = {
  claim: UpdateClaimResponse;
  createdImages?: ArtifactImage[];
  deletedImages?: DeleteClaimImagesMutationResponse;
};

export default () => {
  const queryClient = useQueryClient();
  const createClaimImagesMutation = useCreateClaimImages();
  const deleteClaimImagesMutation = useDeleteClaimImages();

  return useMutation({
    mutationFn: async ({
      claim,
      createdImages,
      deletedImageIds,
    }: {
      claim: UpdateClaimRequest;
      createdImages?: ImagePreview[];
      deletedImageIds?: string[];
    }): Promise<UpdateClaimMutationResponse> => {
      const claimData = await updateClaim(claim);

      let createdImageData: ArtifactImage[] | undefined;
      if (createdImages && createdImages.length > 0) {
        createdImageData = await createClaimImagesMutation.mutateAsync({
          claimId: claim.id,
          images: createdImages,
        });
      }

      let deletedImageData: DeleteClaimImagesMutationResponse | undefined;
      if (deletedImageIds && deletedImageIds.length > 0) {
        deletedImageData = await deleteClaimImagesMutation.mutateAsync({
          claimId: claim.id,
          imageIds: deletedImageIds,
        });
      }

      return {
        claim: claimData,
        createdImages: createdImageData,
        deletedImages: deletedImageData,
      };
    },
    onSuccess: (_data, variables) => {
      return queryClient.invalidateQueries({
        queryKey: queryFactory.claim(variables.claim.id).queryKey,
        refetchType: "all",
      });
    },
  });
};
