import styled from "styled-components";

export const StyledImage = styled.img`
  width: 100%;
  max-width: 88px;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.lg}) {
    max-width: 120px;
  }
`;
