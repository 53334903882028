import privacyPolicy from "client/documents/privacyPolicy.pdf";
import termsAndConditions from "client/documents/termsAndConditions.pdf";
import { config } from "../../../frontendConfig";

export type FooterSection = {
  title: string;
  links: Array<{
    text: string;
    href: string;
    blank?: boolean;
    requiresSession?: boolean;
    partnersOnly?: boolean;
  }>;
};

const footerSections: FooterSection[] = [
  {
    title: "Lost & Found",
    links: [
      {
        text: "Home",
        href: "/",
      },
      {
        text: "Report a lost item",
        href: "/lost",
      },
      {
        text: "Check a claim",
        href: "/sign-in",
        requiresSession: false,
        partnersOnly: false,
      },
      {
        text: "Sign out",
        href: "/sign-out",
        requiresSession: true,
        partnersOnly: false,
      },
    ],
  },
  {
    title: "Partners",
    links: [
      {
        text: "Why Boomerang?",
        href: "/for-partners",
      },
      {
        text: "Partner sign in",
        href: config.portalUrl,
        requiresSession: false,
        partnersOnly: true,
      },
      {
        text: "Partner sign out",
        href: "/sign-out",
        requiresSession: true,
        partnersOnly: true,
      },
      {
        text: "About",
        href: "/about",
      },
    ],
  },
  {
    title: "Company",
    links: [
      {
        text: "Our blog",
        href: "/blog",
        blank: true,
      },
      {
        text: "Careers",
        href: "/careers",
        blank: true,
      },
    ],
  },
  {
    title: "Social",
    links: [
      {
        text: "Instagram",
        href: "https://www.instagram.com/thanksboomerang/",
        blank: true,
      },
      {
        text: "Twitter",
        href: "https://twitter.com/thanksboomerang/",
        blank: true,
      },
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/company/thanks-boomerang",
        blank: true,
      },
      {
        text: "Facebook",
        href: "https://www.facebook.com/Thanks-Boomerang-106774534604876",
        blank: true,
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        text: "Terms",
        href: termsAndConditions,
        blank: true,
      },
      {
        text: "Privacy",
        href: privacyPolicy,
        blank: true,
      },
    ],
  },
];

export default footerSections;
