import config from "../../config";

export const fetchApiData = async <T>({ url, options }: { url: string; options?: RequestInit }) => {
  const response = await fetch(
    `${(config.env === "test" && config["backendUrl"]) || ""}${url}`,
    options,
  );

  // Make sure we throw any errors for consumers of this api
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = await response.text();
  if (data) {
    return JSON.parse(data) as T;
  }

  // Treat responses with no content as null
  return null as T;
};
