// Column layouts
export const ClaimsColumns = [
  { name: "Claim name & category", size: 4 },
  { name: "Contact details", size: 4 },
  { name: "Date lost", size: 2 },
  { name: "Status", size: 2 },
];

export const InventoryColumns = [
  { name: "Item name & category", size: 4 },
  { name: "Description", size: 4 },
  { name: "Date found", size: 2 },
  { name: "Status", size: 2 },
];

export const ReturnsColumns = [
  { name: "Item name & category", size: 5 },
  { name: "Contact details", size: 4 },
  { name: "Status/Action", size: 3 },
];

export const MatchesColumns = [{ name: "Name & category", size: 1 }, { name: "Action" }];
