import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { ButtonTypes } from "../../../components/Button/Button.types";
import { ModalButtonTextEnum } from "../../../components/Modal/Modal.types";
import Tabs from "../../../components/Tabs";
import { ShippingCheckoutContext } from "../../../context/ShippingCheckout";
import theme from "../../../theme";
import BasicInfoDetail from "./BasicInfoDetail";
import ConfirmationDetail from "./ConfirmationDetail";
import ConfirmationSummary from "./ConfirmationDetail/ConfirmationSummary";
import DeliveryDetail from "./DeliveryDetail";
import Header from "./Header";
import { ShippingCheckoutWrapper } from "./styles";

export default function ShippingCheckout() {
  const history = useHistory();
  const isSm = useMediaQuery({ query: `(min-width: ${theme.breakpoints.sm})` });
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { checkoutData, setCheckoutData, isLoading } = useContext(ShippingCheckoutContext);

  useEffect(() => {
    setCheckoutData(prev => ({
      ...prev,
      shippingAddressTo: null,
      shippoRates: [],
      shippingId: null,
      paymentIntentId: null,
      clientSecret: null,
      total: null,
      selectedDeliveryMethod: null,
    }));
  }, [setCheckoutData]);

  const tabs = useMemo(
    () => [
      {
        content: <BasicInfoDetail setCurrentIndex={setCurrentIndex} />,
        headerTitle: "Get it delivered!",
        subTitle: "Enter your details below",
        title: "Basic Info",
        footerProps: {
          loading: isLoading,
          rightBtnText: isEditing && checkoutData.shippingAddressTo ? "Update" : "Next",
          rightButtonProps: {
            rightBtnType: ButtonTypes.submit,
            form: "basicInfo",
            loading: isLoading,
            type: "submit",
            disabled: isLoading,
          },
        },
        onBack: () => history.push(`/claim/${checkoutData.claimId}`),
      },
      {
        content: <DeliveryDetail setCurrentIndex={setCurrentIndex} />,
        headerTitle: "Get it delivered!",
        subTitle: "Enter your details below",
        title: "Delivery",
        footerProps: {
          rightBtnText: isEditing && checkoutData.clientSecret ? "Update" : "Next",
          rightButtonProps: {
            form: "deliveryOptions",
            loading: isLoading,
            type: "submit",
            disabled: isLoading,
            className:
              isLoading || (checkoutData?.shippoRates?.length ?? 0) > 0 ? "w-100" : "d-none",
          },
          leftBtnText: "Back",
          leftButtonProps: {
            onClick: () => setCurrentIndex(0),
            disabled: isLoading,
            className: isLoading || (checkoutData?.shippoRates?.length ?? 0) > 0 ? "" : "d-none",
            variant: "outline",
          },
        },
        onBack: () => setCurrentIndex(0),
      },
      {
        content: currentIndex === 2 && <ConfirmationDetail />,
        contentAfterPanel: currentIndex === 2 && <ConfirmationSummary />,
        headerTitle: "Almost there!",
        subTitle: "Review your details below",
        title: isSm ? ModalButtonTextEnum.PAY_AND_CONFIRM : ModalButtonTextEnum.CONFIRM,
        footerProps: {
          leftBtnText: "Back",
          rightBtnText: ModalButtonTextEnum.PAY_AND_CONFIRM,
          rightButtonProps: {
            form: "paymentInfo",
            loading: isLoading,
            type: "submit",
            disabled: isLoading,
            variant: "secondary",
          },
          leftButtonProps: {
            onClick: () => setCurrentIndex(1),
            disabled: isLoading,
          },
        },
        onBack: () => setCurrentIndex(1),
      },
    ],
    [
      isLoading,
      isEditing,
      checkoutData?.shippingAddressTo,
      checkoutData?.clientSecret,
      checkoutData?.shippoRates?.length,
      checkoutData?.claimId,
      currentIndex,
      isSm,
      history,
    ],
  );

  return (
    <ShippingCheckoutWrapper className="d-flex flex-column align-items-center">
      <Header
        onBack={tabs[currentIndex].onBack}
        subTitle={tabs[currentIndex].subTitle}
        title={tabs[currentIndex].headerTitle}
      />

      <Tabs
        className="mt-sm-n35 mx-4"
        clickable={false}
        currentIndex={currentIndex}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onTabChange={newIndex => {
          if (newIndex === 2) {
            setIsEditing(false);
          }
          setCurrentIndex(newIndex);
        }}
        setCurrentIndex={setCurrentIndex}
        tabs={tabs}
      />
    </ShippingCheckoutWrapper>
  );
}
