import styled from "styled-components";

export const Content = styled.div`
  background-color: ${props => props.theme.colors.gray6};
`;

export const Divider = styled.hr`
  color: ${props => props.theme.colors.gray8};
  opacity: 1;
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};
  height: fit-content;
  margin-top: -1.2rem;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
  padding: 0;
`;
