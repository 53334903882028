import React from "react";
import { Column, ThreeColumnContainer } from "./styles";

interface Props {
  column1: React.ReactNode;
  column2: React.ReactNode;
  column3: React.ReactNode;
}
const ThreeColumnLayout = (props: Props) => {
  return (
    <ThreeColumnContainer>
      <Column>{props.column1}</Column>
      <Column>{props.column2}</Column>
      <Column>{props.column3}</Column>
    </ThreeColumnContainer>
  );
};

export default ThreeColumnLayout;
