import theme from "client/theme";
import { Heading, Text } from "client/ui-components";
import React from "react";
import { CategoryFilter } from "./CategoryFilter";
import { ClearFilterButton } from "./ClearFilterButton";
import { DateFilter } from "./DateFilter";
import { Wrapper } from "./styles";
import SuggestedListsSection from "./SuggestedListsSection";
import { UnclaimedItemsTable } from "./UnclaimedItemsTable";

export function UnclaimedInventory() {
  return (
    <Wrapper className="d-flex flex-column  pt-4">
      <SuggestedListsSection />
      <div className="d-flex flex-column gap-2 mt-1">
        <Heading data-testid="noSuggestionsHeading" variant="h4">
          No suggestions for these items
        </Heading>
        <Text data-testid="noSuggestionsCopy" color={theme.ui.colors.gray600}>
          These items are currently unassigned. Please assign a status for each unassigned item
          manually and confirm the date of update to ensure they are properly tracked and managed.
        </Text>
      </div>
      <div className="d-flex gap-2 flex-column flex-lg-row pt-2 align-items-lg-center">
        <DateFilter />
        <CategoryFilter />
        <ClearFilterButton />
      </div>
      <UnclaimedItemsTable />
    </Wrapper>
  );
}
