import styled from "styled-components";
import Container from "../../ui-components/Container";

export const Wrapper = styled(Container)`
  height: calc(100vh - ${props => props.theme.ui.heights.navBase});
  padding-block: 64px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    height: calc(100vh - ${props => props.theme.ui.heights.navLg});

    display: flex;
    align-items: center;
  }

  @media only screen and (max-height: 800px) {
    height: auto;
    min-height: calc(100vh - ${props => props.theme.ui.heights.navBase});
    padding-block: 64px;

    @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
      height: auto;
      min-height: calc(100vh - ${props => props.theme.ui.heights.navLg});

      display: flex;
      align-items: center;
    }
  }
`;
