import React, { useContext } from "react";
import { ClaimDetailsContext } from "../../../context/ClaimDetails";
import theme from "../../../theme";
import { ShipmentStatus } from "../../../types";
import { Link, Text } from "../../../ui-components";

export default function Details() {
  const { shipment } = useContext(ClaimDetailsContext);
  const carrier =
    shipment?.carrier && shipment?.carrier.length > 0
      ? `${shipment.carrier} Tracking number`
      : "Tracking number";

  if (shipment === null) return null;

  if (
    shipment.status === ShipmentStatus.PENDING ||
    shipment.status === ShipmentStatus.PACKAGED ||
    shipment.status === ShipmentStatus.SHIPPED
  ) {
    return (
      <div>
        <Text as="p" className="mb-0" color={theme.ui.colors.primary700} fontWeight="700">
          {carrier}
        </Text>

        {shipment.tracking_number.length > 0 && (
          <Text
            as="p"
            color={theme.ui.colors.primary500}
            fontWeight="700"
            className="mb-0"
            data-testid="trackingNumber"
          >
            {shipment.tracking_url_provider ? (
              <Link
                href={shipment.tracking_url_provider}
                target="_blank"
                data-testid="trackingNumberLink"
              >
                {shipment.tracking_number}
              </Link>
            ) : (
              shipment.tracking_number
            )}
          </Text>
        )}

        {/* TODO: Implement after API is updated
            <Text className="mb-0 mt-25">
              Expected delivery
              <Text as="span" className="fw-bold">{` ${expectedDelivery}`}</span>
            </Text> 
          */}
      </div>
    );
  }

  if (shipment.status === ShipmentStatus.DELIVERED) {
    return (
      <div>
        <Text as="p" className="mb-0" color={theme.ui.colors.primary700} fontWeight="700">
          {carrier}
        </Text>

        <Text as="p" color={theme.ui.colors.primary500} fontWeight="700" className="mb-0">
          {shipment.tracking_number}
        </Text>

        {/* TODO: Implement after API is updated
            <DeliveryDetails className="mb-0 mt-25">
              Delivered
              <span className="fw-bold">{` ${expectedDelivery}`}</span>
            </DeliveryDetails>
          */}
      </div>
    );
  }

  return (
    <div>
      <p className="mb-0">You will be notified when your item is shipped!</p>
    </div>
  );
}
