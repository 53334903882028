import classnames from "classnames";
import theme from "client/theme";
import { Typography } from "client/ui-components";
import React, { CSSProperties, forwardRef, Ref } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { StyledFloatingLabelInput } from "./styles";

interface StyledFloatingLabelProps extends Omit<FormControlProps, "type"> {
  ariaLabel?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  errors?: FieldError;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  isValidated?: FieldError;
  label: string;
  lpIgnore?: string;
  min?: string | number;
  name?: string;
  required?: boolean;
  style?: CSSProperties;
  tabIndex: number;
  title?: string;
  type: JSX.IntrinsicElements["input"]["type"];
  rounded?: boolean;
}

const StyledFloatingLabel = forwardRef(
  (props: StyledFloatingLabelProps, ref: Ref<HTMLInputElement>) => {
    const {
      ariaLabel,
      autoComplete,
      autoFocus,
      className,
      disabled,
      errors,
      icon,
      iconEnd,
      isValidated,
      label,
      lpIgnore,
      name,
      required,
      style,
      tabIndex,
      title,
      rounded,
      ...rest
    } = props;

    return (
      <StyledFloatingLabelInput
        label={label}
        className={`${rounded ? "rounded" : ""} ${className}`}
        icon={icon}
        required={required}
        disabled={disabled}
      >
        <Form.Control
          {...rest}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          data-testid={props["data-testid"]}
          autoFocus={autoFocus}
          className={classnames({
            "is-invalid": isValidated && errors,
            "is-valid": isValidated && !errors,
            rounded: rounded,
            "with-shadow": rounded,
          })}
          data-lpignore={lpIgnore}
          name={name}
          ref={ref}
          required={required}
          style={style}
          tabIndex={tabIndex}
          title={title || label}
        />

        {icon}

        {React.isValidElement(iconEnd) &&
          React.cloneElement(iconEnd as React.ReactElement<any>, {
            className: "iconEnd",
          })}

        {errors?.types // Multiple errors
          ? Object.entries(errors?.types || {}).map(([type, message]) => (
              <Typography
                key={type}
                className="invalid-feedback d-block"
                as="span"
                fontSize={theme.ui.fontSizes.sm}
                color={theme.ui.colors.error500}
                fontWeight={500}
                lineHeight={1.7}
              >
                {message}
              </Typography>
            ))
          : errors?.type && ( // Single error
              <Typography
                key={errors.type}
                className="invalid-feedback d-block"
                as="span"
                fontSize={theme.ui.fontSizes.sm}
                color={theme.ui.colors.error500}
                fontWeight={500}
                lineHeight={1.7}
              >
                {errors.message}
              </Typography>
            )}
      </StyledFloatingLabelInput>
    );
  },
);
export default StyledFloatingLabel;
