import styled from "styled-components";

export const Header = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.ui.colors.primary700};
  transition: padding 0.3s ease-in-out;

  @media (max-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 800px;
  }
`;

export const CardContent = styled.div`
  padding: 20px;
  overflow: hidden;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const CardImage = styled.img<{
  imagePosition: "start" | "end";
}>`
  width: 100%;
  max-width: 192px;
  height: auto;
  border-radius: 16px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props => (props.imagePosition === "start" ? "340px" : "192px")};
    align-self: start;
  }
`;
