import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  background-color: ${({ theme }) => theme.ui.colors.white};

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.lg}) {
    padding-inline: 40px;
    padding-block: 40px 60px;
    margin-top: 32px;
    margin-bottom: 64px;
    border-radius: 16px;
  }
`;
