import React, { useContext, useEffect, useState } from "react";

import { KEYBOARD_KEY } from "client/types/keyboardKey";
import { useLocation } from "react-router-dom";
import history from "../../context/history";
import { PartnerGlobalSearchContext } from "../../context/PartnerGlobalSearch";
import AllResults from "./components/AllResults";
import DefaultView from "./components/DefaultView";
import SearchResults from "./components/SearchResults";
import { Container, StyledLoader } from "./styles";

export enum ResultType {
  ITEMS = "items",
  CLAIMS = "claims",
  RETURNS = "returns",
}

export default function PartnerGlobalSearch() {
  const [bannerHeight, setBannerHeight] = useState<number>(200);
  const location = useLocation<{ searchInitiator?: string }>();
  const { setLastVisitedPage } = useContext(PartnerGlobalSearchContext);

  const {
    setIsSearchModeOn,
    setSearchTerm,
    showAllResults,
    searchResults,
    isLoading,
    searchTerm,
    isSearchModeOn,
    lastVisitedPage,
    setShowAllResults,
  } = useContext(PartnerGlobalSearchContext);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEYBOARD_KEY.ESCAPE) {
        setSearchTerm("");
        setIsSearchModeOn(false);
        setShowAllResults(false);
        history.push(lastVisitedPage, {
          query: history.location.search,
          pathname: history.location.pathname,
          searchInitiator: location.state?.searchInitiator,
        });
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    lastVisitedPage,
    location.state?.searchInitiator,
    setIsSearchModeOn,
    setSearchTerm,
    setShowAllResults,
  ]);

  useEffect(() => {
    if (location.state?.searchInitiator) {
      setLastVisitedPage(location.state.searchInitiator);
    }
  }, [location, setLastVisitedPage]);

  useEffect(() => {
    const banner: HTMLElement | null = document.querySelector('[role="banner"]');
    if (banner !== null) {
      setBannerHeight(banner.offsetHeight);
    }
  }, [searchResults]);

  return (
    <>
      {searchTerm.length < 3 && <DefaultView />}
      {isLoading && searchTerm.length >= 3 && (
        <StyledLoader className="mx-auto my-auto" bannerHeight={bannerHeight} />
      )}
      <Container className="pb-5" bannerHeight={bannerHeight}>
        {showAllResults && isSearchModeOn ? <AllResults /> : <SearchResults />}
      </Container>
    </>
  );
}
