import styled from "styled-components";

export const Status = styled.div`
  padding-right: 20px;
  display: flex;
`;

export const StatusText = styled.div`
  border-radius: 50px;
  border: 1px dashed var(--gray-500-for-lines, #dce1e8);
  background: var(--Base-White, #fff);
  display: flex;
  padding: 4px 18px;
  justify-content: center;
  text-wrap: nowrap;
  line-height: 24px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;
