import React from "react";
import { ImageSearchResponse, PartnerStorageLocation } from "../../types/actions";
import { PartnerClaim, PartnerItem } from "../../types/models";

export interface PartnerUserStateLegacy {
  currentClaim?: PartnerClaim;
  currentItem?: PartnerItem;
  currentItemImages?: ImageSearchResponse;
  partnerStorageLocations?: PartnerStorageLocation[];
  partnerUserStateLoading: boolean;
}

export const initialPartnerUserState: PartnerUserStateLegacy = {
  currentClaim: undefined,
  currentItem: undefined,
  currentItemImages: undefined,
  partnerStorageLocations: undefined,
  partnerUserStateLoading: false,
};

export const PartnerUserContextLegacy = React.createContext(initialPartnerUserState);
