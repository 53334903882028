import styled from "styled-components";

export const ShippingCheckoutWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  min-height: 100vh;
  font-family: ${props => props.theme.ui.fontFamilies.default};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    background-color: ${props => props.theme.colors.gray6};
  }
`;
