import { AlertCircle } from "@assets/ui-icons";
import React from "react";
import theme from "../../../../theme";
import { Text } from "../../../../ui-components";

export default function NotFound() {
  return (
    <div className="d-flex flex-column gap-25 align-items-center p-35">
      <AlertCircle
        accessibilityTitle="partner-not-found"
        titleId="partner-not-found"
        color={theme.ui.colors.gray600}
      />

      <Text fontWeight={700} color={theme.ui.colors.gray600}>
        No results found
      </Text>

      <Text fontWeight={400} color={theme.ui.colors.gray600}>
        Try another location.
      </Text>
    </div>
  );
}
