import styled from "styled-components";

export const GridImage = styled.div<{
  width?: string;
  height?: string;
  verticalPadding?: string;
  horizontalPadding?: string;
  active?: boolean;
  src?: string;
}>`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  border-radius: 16px;
  margin-top: ${props => props.verticalPadding || "0"};
  margin-bottom: ${props => props.verticalPadding || "0"};
  margin-left: ${props => props.horizontalPadding || "0"};
  margin-right: ${props => props.horizontalPadding || "0"};
  opacity: ${props => (props.active ? "1" : "0.5")};
`;
