import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "../context/auth";
import { PartnerUserContextProviderLegacy } from "../context/PartnerUser";

function PrivatePartnerRoute({ children, ...rest }) {
  const { user, partner } = useAuthState();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.id && partner ? (
          <PartnerUserContextProviderLegacy>{children}</PartnerUserContextProviderLegacy>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivatePartnerRoute;
