import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { StyledCarousel } from "../ImageCarousel";

const { Item } = Carousel;

export const InfoWrapper = styled.div`
  min-width: 0;
  display: flex;
`;

export const PhotoCarousel = styled(StyledCarousel)`
.carousel-control-prev,
.carousel-control-next {
  display: ${props => (props.$showSingleImage ? "none" : "flex")};} 
}
  &&&{
    height: 100%;
    max-width: 100px;
    min-width: 100px;
    min-height 100px;
    &.large {
      height: 100%;
      max-width: 140px;
      min-width: 140px;
      min-height 140px;
    }
    &.portrait {
      aspect-ratio: 53/83;
      max-width: 159px;
      min-width: 159px;
      width: 100%;
    }
  }
`;

export const StyledCarouselItem = styled(Item)`
  background-image: url(${props => props.url});
  &&&{
    height: 100%;
    max-width: 100px;
    min-width: 100px;
    min-height 100px;
    &.large {
      height: 100%;
      max-width: 140px;
      min-width: 140px;
      min-height 140px;
    }
    &.portrait {
      aspect-ratio: 53/83;
      max-width: 159px;
      min-width: 159px;
      width: 100%;
    }
  }
`;

export const StyledLabel = styled.div`
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 1.33;
`;

export const StyledListItem = styled.div<{
  cursorPointer?: boolean;
  highlight?: boolean;
}>`
  cursor: ${props => (props.cursorPointer ? "pointer" : "inherit")};
  display: flex;
  justify-content: space-between;
  overflow: auto;
  &.active {
    background-color: ${props => props.theme.colors.lightBlue}80;
    box-shadow: inset 7px 0 0 0 ${props => props.theme.colors.primaryBlue};
    cursor: default;
  }
  &:hover {
    ${props => (props.highlight ? "background-color: " + props.theme.colors.lightBlue + "80" : "")};
  }
`;
