import styled from "styled-components";

export const TabsWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: -0.25rem;
  margin-bottom: 12px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1072px;
  padding: 0 20px;
  box-sizing: content-box;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ToolbarSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  margin-left: auto;
  align-self: flex-end;

  & > * {
    text-wrap: nowrap;
    white-space: nowrap;
    vertical-align: middle;
  }

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

export const Divider = styled.hr<{ hasBulkOptions?: boolean }>`
  border-top: 1px solid ${props => props.theme.ui.colors.gray500};
  opacity: 1;
  max-width: 1072px;
  width: 100%;
  padding: 0 20px;
  margin: 12px auto;
`;
