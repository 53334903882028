import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { Carousel, Slide } from "./styles";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["ul"]> & {
  slides: React.ReactNode[];
  startFrom?: "start" | "end";
  interactive: boolean;
  slideWidth?: number;
};

export default function DraggableSlider({
  slides,
  startFrom = "start",
  slideWidth = 128,
  interactive = true,
  ...props
}: Props) {
  const carouselRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (startFrom === "end" && carouselRef.current !== null) {
      carouselRef.current.scrollLeft =
        carouselRef.current.scrollWidth - carouselRef.current?.clientWidth;
    }
  }, [carouselRef.current?.scrollWidth]);

  return (
    <Carousel {...props} ref={carouselRef} className={classNames(!interactive && "not-scrollable")}>
      {slides.map((slide, index) => (
        <Slide key={index} slideWidth={slideWidth}>
          {slide}
        </Slide>
      ))}
    </Carousel>
  );
}
