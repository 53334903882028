import ReviewClaimDetails from "client/components/ArtifactForms/ReviewClaimDetails";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import { ArtifactDetailsForm, useArtifactDetailsForm } from "../../../components/ArtifactForms";
import { useContactDetailsForm } from "../../../components/ContactDetailsForm";
import SimpleHeader from "../../../components/SimpleHeader";
import SmallConfirmationModal from "../../../components/SmallConfirmationModal";
import { useAuthState } from "../../../context/auth";
import { UpdateClaimStepCopy } from "../../../copy/artifactFormCopy";
import { isLgQuery } from "../../../helpers/mediaQuery";
import useCategories from "../../../hooks/data/user/useCategories";
import { useUserState } from "../../../hooks/useUserState";
import { Claim, ImageSearchResponse } from "../../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import SubmittedPage from "./steps/SubmittedPage/SubmittedPage";
import { Container, Content, Wrapper } from "./styles";
import { ClaimSteps, ItemDetails } from "./types";

export interface ClaimProps {
  claim?: Claim;
  claimImages?: ImageSearchResponse;
}

const ClaimForm = ({ claim, claimImages }: ClaimProps): JSX.Element => {
  const history = useHistory();
  const categories = useCategories();

  const stepCopy = UpdateClaimStepCopy;
  const initialStep = ClaimSteps.ITEM_DETAILS;

  const [step, setStep] = useState<ClaimSteps>(initialStep);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  // Original of editing form, so they can be reset
  const [claimDetailsOverride, setClaimDetailsOverride] = useState<ItemDetails>();
  const [claimId, setClaimId] = useState<string>();
  const [imageIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
  const { user } = useAuthState();
  const [userState] = useUserState();

  // Set the step of the window so that the browser back and forward arrows function
  // See https://thanksboomerang.atlassian.net/browse/ENG-701
  const stateHasStep = window.history.state?.step !== undefined;
  const stateStepDiffersFromCurrentStep = window.history.state?.step !== step;
  // Replace if state unset, otherwise push current step to state.
  if (!stateHasStep) {
    window.history.replaceState({ step: step }, "", window.location.href);
  } else if (stateStepDiffersFromCurrentStep) {
    window.history.pushState({ step: step }, "", window.location.href);
  }

  const artifactDetailsForm = useArtifactDetailsForm();
  const contactDetailsForm = useContactDetailsForm();

  const images = artifactDetailsForm.watch("images");

  const isLg = isLgQuery();

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    mountBodyGray6();
    artifactDetailsForm.reset();
    contactDetailsForm.reset();
    return () => {
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    // Anytime the URL is changed this is triggered
    if (history.action === "POP" || history.action === "PUSH") {
      const unblock = history.block(() => {
        const backStep = window.history.state?.step;
        if (backStep !== undefined) {
          setStep(backStep);
        }
      });
      return () => {
        unblock();
      };
    }
  });

  const backOrCancelFunctionality = (isLeftArrow: boolean) => {
    if (isEditing) {
      if (claim && step == ClaimSteps.ITEM_DETAILS) {
        handleShowModal();
        return;
      }

      if (isLeftArrow) {
        if (claimDetailsOverride) {
          artifactDetailsForm.setValue("foundDate", claimDetailsOverride.foundDate);
          artifactDetailsForm.setValue(
            "itemCategoryIdValue",
            claimDetailsOverride.itemCategoryIdValue,
          );
          artifactDetailsForm.setValue(
            "detailedDescription",
            claimDetailsOverride.detailedDescription,
          );
          artifactDetailsForm.setValue("itemName", claimDetailsOverride.itemName);
          artifactDetailsForm.setValue("images", claimDetailsOverride.images);
        }
      }
      setStep(ClaimSteps.REVIEW_DETAILS);
      setIsEditing(false);
    } else if (step === ClaimSteps.ITEM_DETAILS) {
      handleShowModal();
    } else if (claim && step == ClaimSteps.REVIEW_DETAILS) {
      setStep(ClaimSteps.ITEM_DETAILS);
    }
  };

  const onSubmitReportLostItemForm = artifactDetailsForm.handleSubmit(() => {
    setStep(isEditing || user?.id ? ClaimSteps.REVIEW_DETAILS : ClaimSteps.CONTACT_DETAILS);
    setIsEditing(false);
  });

  const onCancelOrBackFromReportLostItemForm = () => {
    backOrCancelFunctionality(false);
  };

  const onCancelOrBackFromReviewDetailsForm = () => {
    backOrCancelFunctionality(true);
  };

  return (
    <>
      {step <= ClaimSteps.ITEM_DETAILS && (
        <SmallConfirmationModal
          show={showModal}
          leftBtnOnClick={() => setShowModal(false)}
          leftBtnText="Never mind"
          rightBtnText="Yes, cancel"
          rightBtnOnClick={() => {
            if (claim) {
              history.push(`/claim/${claim.id}`);
            } else {
              history.push("/");
            }
          }}
          data-testid="cancel-claim-popup"
          header={"Cancel claim updates?"}
        />
      )}
      {step === ClaimSteps.SUBMITTED && (
        <SubmittedPage
          isLarge={isLg}
          claimId={claimId}
          existingClaim={true}
          imageUrl={images?.length > 0 ? images[0].preview : ""}
          editAgain={() => setStep(ClaimSteps.ITEM_DETAILS)}
        />
      )}
      {step !== ClaimSteps.SUBMITTED && (
        <>
          <SimpleHeader
            leftArrowText={"Claim details"}
            onLeftArrowClick={() => {
              backOrCancelFunctionality(true);
            }}
            subHeaderText={""}
            title={stepCopy[step].title || ""}
          />
          {
            <Container>
              <Content className="row mx-0 justify-content-center px-3 px-lg-0">
                <Wrapper
                  className={`zIndex1 ${step === ClaimSteps.REVIEW_DETAILS ? "mb-3" : "mb-5"}`}
                >
                  {step === ClaimSteps.ITEM_DETAILS && (
                    <ArtifactDetailsForm
                      {...artifactDetailsForm}
                      artifact={claim}
                      artifactImages={claimImages}
                      categories={categories.data ?? []}
                      contextState={userState}
                      detailsOverride={claimDetailsOverride}
                      entryPoint={ClaimSteps.ITEM_DETAILS}
                      isClaim={true}
                      isEditing={isEditing}
                      onCancelOrBack={onCancelOrBackFromReportLostItemForm}
                      onSubmit={onSubmitReportLostItemForm}
                      partnerMessage={""}
                      setImageIdsToDelete={setImageIdsToDelete}
                      stepCopy={stepCopy}
                    />
                  )}
                  {step === ClaimSteps.REVIEW_DETAILS && !!claim && (
                    <ReviewClaimDetails
                      {...artifactDetailsForm}
                      existingClaim={claim}
                      categories={categories.data ?? []}
                      images={images}
                      imageIdsToDelete={imageIdsToDelete}
                      onCancelOrBack={onCancelOrBackFromReviewDetailsForm}
                      selectedLocation={claim.lost_location}
                      setClaimId={setClaimId}
                      setImageIdsToDelete={setImageIdsToDelete}
                      setClaimDetailsOverride={setClaimDetailsOverride}
                      setStep={setStep}
                      isSubmitEnabled={!!user?.id}
                    />
                  )}
                </Wrapper>
              </Content>
            </Container>
          }
        </>
      )}
    </>
  );
};

export default ClaimForm;
