import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../theme/uiTheme";
import { LinkVariants } from "./index";

const getLinkColors = (variant: LinkVariants) => {
  switch (variant) {
    case "gray":
      return `
                    color: ${colors.gray600};
                    &:hover {
                        color: ${colors.gray700};
                    }
                    &:focus {
                        color: ${colors.gray600}; 
                    }   
                `;
    case "error":
      return `
                    color: ${colors.error500};
                    &:hover {
                        color: ${colors.error600};
                    }
                    &:focus {
                        color: ${colors.error500}; 
                    }
                `;
    case "dark":
      return `
                    color: ${colors.gray500};
                    &:hover {
                        color: ${colors.white};
                    }
                    &:focus {
                        color: ${colors.white}; 
                    }
                `;
    default:
      return `
                    color: ${colors.primary500};
                    &:hover {
                        color: ${colors.primary600};
                    }
                    &:focus {
                        color: ${colors.primary600}; 
                    }
                `;
  }
};

const getWrapperStyles = (variant: LinkVariants) => {
  switch (variant) {
    case "dark":
      return `
      &:hover, &:focus {
        text-decoration: underline;
        text-decoration-color: ${colors.white};
      }
      `;
    default:
      return `
        &:hover, &:focus {
        text-decoration: none;
        }
        `;
  }
};

export const StyledLinkText = styled.span<{
  variant: LinkVariants;
}>`
  ${({ variant }) => getLinkColors(variant)};
  transition: ${({ theme }) => theme.ui.transitions.textSizeColor};
  font-weight: ${({ theme }) => theme.ui.fontWeights.bold};
  line-height: 1.5;
  font-family: ${({ theme }) => theme.ui.fontFamilies.default};
`;

export const StyledExternalLink = styled.a<{
  variant: LinkVariants;
}>`
  ${({ variant }) => getWrapperStyles(variant)};
`;
export const StyledInternalLink = styled(Link)<{
  variant: LinkVariants;
}>`
  ${({ variant }) => getWrapperStyles(variant)};
`;

export const StyledButtonLink = styled.button<{
  variant: LinkVariants;
}>`
  ${({ variant }) => getWrapperStyles(variant)};
  border: none;
`;
