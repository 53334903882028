import { useQuery } from "@tanstack/react-query";
import { GetPartnerReturnsRequest } from "client/types";
import queryFactory from "./queryFactory";

export default (partnerReturnsReq: GetPartnerReturnsRequest) => {
  const { data, isLoading, error } = useQuery(queryFactory.returnsFiltered(partnerReturnsReq));

  return {
    partnerReturns: data,
    isLoading,
    error,
  };
};
