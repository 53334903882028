import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    width: ${props => props.theme.toast.width};
    padding: 0;
  }
  .Toastify__toast {
    padding: 20px;
    border-radius: 12px;
    gap: 12px;
  }
  .Toastify__close-button {
    padding-right: 6px;
  }
  .Toastify__toast-icon {
    width: 24px;
    padding: 0;
    margin: 0;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    align-items: flex-start;
    gap: 12px;
    div:nth-child(2) {
      font-family: "Lato";
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 1.5;
      font-weight: 600;
    }
  }
  .toast-description {
    font-weight: 400 !important;
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast--error {
    .Toastify__toast-body {
      background: ${props => props.theme.colors.lightRed};
    }
  }

  .Toastify__toast--success {
    background: ${props => props.theme.colors.lightGreen};
    color: ${props => props.theme.colors.darkSuccessGreen};
    border: 1px solid ${props => props.theme.ui.colors.secondary400};
    .toast-title,
    .toast-description {
      padding: 0px;
      color: ${props => props.theme.colors.darkSuccessGreen};
    }
  }

  .Toastify__toast--error {
    background: ${props => props.theme.colors.lightRed};
    color: ${props => props.theme.ui.colors.error600};
    border: 1px solid ${props => props.theme.colors.error400};
    .toast-title,
    .toast-description {
      color: ${props => props.theme.ui.colors.error600};
    }
  }

  .Toastify__toast--warning {
    background: ${props => props.theme.ui.colors.yellow400};
    color: ${props => props.theme.ui.colors.yellow600};
    border: 1px solid ${props => props.theme.ui.colors.yellow500};
    .toast-title,
    .toast-description {
      color: ${props => props.theme.ui.colors.yellow600};
    }
  }

  .Toastify__toast--info {
    background: ${props => props.theme.ui.colors.primary300};
    border: 1px solid ${props => props.theme.colors.primary400};
    color: ${props => props.theme.ui.colors.primary600};
    .toast-title,
    .toast-description {
      color: ${props => props.theme.ui.colors.primary600};
    }
  }
`;
