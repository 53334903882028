import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import TextLabelValue from "../TextLabelValue/TextLabelValue";
import { HeaderTitle, Label } from "../typography";

const { Item } = Carousel;

export const StyledHeaderTitle = styled(HeaderTitle)`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  max-width: 260px;
  @media (min-width: 330px) {
    max-width: 387px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 207px;
  }
  @media (min-width: 1285px) {
    max-width: 292px;
  }
  word-break: break-word;
  display: block;
`;

export const StyledTextLabelValue = styled(TextLabelValue)`
  max-width: 167px;
  word-break: break-word;
  display: block;
  &.header-label-light {
    label {
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const StyledCarouselItem = styled(Item)`
  &&& {
    height: 100%;
    min-width: 110px;
    min-height: 190px;
    &.thumbnail {
      min-height: 84px;
      min-width: 84px;
      width: 84px;
      height: 84px;
    }
  }
`;

export const StyledClaimList = styled.div`
  background: ${props => props.theme.colors.white};
  width: 420px;
`;

export const StyledContainer = styled.div`
  .thumbnail {
    min-height: 84px;
    min-width: 84px;
    width: 84px;
    height: 84px;
  }
`;

export const StyledHeader = styled.div`
  height: ${props => props.theme.heights.singleViewHeader};
  background: ${props => props.theme.ui.colors.primary700};
  color: ${props => props.theme.colors.white};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: ${props => props.theme.heights.singleViewHeaderLg};
  }
`;

export const StyledHeaderLabelText = styled(Label)`
  color: ${props => props.theme.colors.white};
`;
