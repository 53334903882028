import { ArrowNarrowLeft, ArrowNarrowRight, Calender, ChevronRight } from "@assets/ui-icons";
import classNames from "classnames";
import {
  differenceInCalendarDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  intlFormat,
  isBefore,
  isFuture,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import theme from "../../theme";
import Heading from "../Heading";
import { Button } from "../index";
import Text from "../Text";
import DatePickerModal from "./DatePickerModal";
import { Content, DateSelectButton, DayNamesHeader, DayPickerButton, Row } from "./styles";

const now = new Date();

const dayNames = eachDayOfInterval({
  start: startOfWeek(now),
  end: endOfWeek(now),
}).map(d => format(d, "EEE"));

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["button"]> & {
  fullWidth?: boolean;
  onChange?: (date: Date) => void;
  currentDate: Date;
  limitPrevDays?: number;
};

export default function DateInput({
  fullWidth = false,
  onChange,
  currentDate,
  limitPrevDays,
  ...props
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const startOfTheMonth = useMemo(
    () => startOfMonth(new Date(currentYear, currentMonth)),
    [currentYear, currentMonth],
  );
  const lastActiveDay = subDays(now, limitPrevDays || 0);

  const weeks = useMemo(() => {
    return eachWeekOfInterval({
      start: startOfTheMonth,
      end: endOfMonth(startOfTheMonth),
    }).map(d =>
      eachDayOfInterval({
        start: startOfWeek(d),
        end: endOfWeek(d),
      }),
    );
  }, [startOfTheMonth]);

  const buttonText = useMemo(() => {
    const interval = differenceInCalendarDays(now, currentDate);

    const intervalText =
      interval === 0
        ? "Today, "
        : interval === 1
          ? "Yesterday, "
          : interval === 2
            ? "2 days ago, "
            : "";

    return (
      <>
        {intervalText}
        {intlFormat(
          currentDate,
          { month: "short", day: "numeric", year: "numeric" },
          { locale: "en-US" },
        )
          .split(",")
          .join("")}
      </>
    );
  }, [currentDate]);

  useEffect(() => {
    setCurrentMonth(currentDate.getMonth());

    setCurrentYear(currentDate.getFullYear());
  }, [currentDate]);

  function closeModal() {
    setIsModalOpen(false);

    setCurrentMonth(currentDate.getMonth());

    setCurrentYear(currentDate.getFullYear());
  }

  return (
    <>
      <DateSelectButton
        {...props}
        fullWidth={fullWidth}
        data-toggle="modal"
        data-target="#date-select-modal"
        onClick={() => setIsModalOpen(true)}
        type="button"
      >
        <Content>
          <Calender accessibilityTitle="date-select-calender-icon" titleId="date-select-calender" />

          <Text as="span" fontWeight={400}>
            {buttonText}
          </Text>
        </Content>

        <ChevronRight accessibilityTitle="date-select-open-modal" titleId="date-select-arrow" />
      </DateSelectButton>

      <DatePickerModal
        isOpen={isModalOpen}
        onClose={() => closeModal()}
        header={
          <div className="d-flex flex-row gap-2 justify-content-between flex-grow-1 align-items-center">
            <Button
              aria-label="prev-month"
              className="p-0 border-0 shadow-none h-auto"
              data-testid="calendarPrevNavButton"
              onClick={() =>
                setCurrentMonth(prev => {
                  if (prev === 0) {
                    setCurrentYear(prev => prev - 1);
                    return 11;
                  }

                  return prev - 1;
                })
              }
              variant="outline"
            >
              <ArrowNarrowLeft accessibilityTitle="prev-month" titleId="prev-month" />
            </Button>

            <Heading variant="h4" as="h3">
              {intlFormat(startOfTheMonth, { month: "long", year: "numeric" }, { locale: "en-US" })}
            </Heading>

            <Button
              aria-label="next-month"
              className="p-0 border-0 shadow-none h-auto"
              data-testid="calendarNextNavButton"
              onClick={() =>
                setCurrentMonth(prev => {
                  if (prev === 11) {
                    setCurrentYear(prev => prev + 1);
                    return 0;
                  }

                  return prev + 1;
                })
              }
              variant="outline"
            >
              <ArrowNarrowRight accessibilityTitle="next-month" titleId="next-month" />
            </Button>
          </div>
        }
        body={
          <>
            <DayNamesHeader>
              {dayNames.map(name => (
                <Text
                  key={name}
                  fontWeight={700}
                  color={theme.ui.colors.gray600}
                  textAlign="center"
                  className="py-2"
                >
                  {name}
                </Text>
              ))}
            </DayNamesHeader>

            {weeks.map(week => (
              <Row>
                {week.map(day => (
                  <DayPickerButton
                    disabled={
                      (limitPrevDays && isBefore(day, lastActiveDay)) ||
                      isFuture(day) ||
                      !isSameMonth(day, new Date(currentYear, currentMonth, 1))
                    }
                    onClick={() => {
                      onChange && onChange(day);
                      closeModal();
                    }}
                    className={classNames(isSameDay(currentDate, day) && "active")}
                    key={day.toISOString()}
                    data-testid="calendarDatePicker"
                  >
                    {intlFormat(day, {
                      day: "numeric",
                    })}
                  </DayPickerButton>
                ))}
              </Row>
            ))}
          </>
        }
        footer={
          <Button
            aria-label="close-date-picker-modal"
            fullWidth
            onClick={() => closeModal()}
            size="lg"
            className="m-0"
            variant="outline"
          >
            Cancel
          </Button>
        }
      />
    </>
  );
}
