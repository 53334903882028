import Cookies from "js-cookie";
import config from "../../config";
import { logout } from "../context/auth/actions";
import { LocalStorageConsts } from "../types/localStorage";

class BoomError extends Error {
  metadata?: Record<string, unknown>;
  constructor(message, metadata) {
    super(message);
    this.metadata = metadata;
  }
}

// Deprecated: use `fetchApiData` instead.
const fetchData = async <T>(
  url: string,
  options?: RequestInit,
  mutateData?: (res: T) => void,
): Promise<T | null> => {
  const res = await fetch(
    `${(config.env === "test" && config["backendUrl"]) || ""}${url}`,
    options,
  );
  if ([200, 201, 204].includes(res.status)) {
    if (res?.body) {
      let resJson: T;
      try {
        resJson = (await res.json()) as T;
        !!mutateData && mutateData(resJson);
      } catch (e) {
        throw new BoomError(res.statusText, {
          url,
          options,
        });
      }
      return resJson;
    } else {
      return null;
    }
  } else if (res.status === 401 || res.status === 403) {
    if (localStorage.getItem(LocalStorageConsts.CURRENT_USER) || Cookies.get("sessionid")) {
      //@ts-expect-error - restoring this previous code where dispatch was not passed in
      logout();
    } else {
      if (res?.body) {
        const resJson: T = (await res.json()) as T;
        !!mutateData && mutateData(resJson);
        return resJson;
      } else {
        return null;
      }
    }
  } else if ([400, 404].includes(res.status) || res.status >= 500) {
    if (res.body) {
      let resJson: T | string;
      try {
        resJson = (await res.json()) as T;
        !!mutateData && mutateData(resJson);
      } catch (e) {
        resJson = res.statusText;
      }
      // Throw on these errors so that the error json body can be used downstream
      throw new BoomError(JSON.stringify(resJson), {
        url,
        options,
      });
    } else {
      return null;
    }
  }
  return null;
};

export default fetchData;
