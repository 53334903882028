import styled from "styled-components";
import Button from "../../ui-components/Button";
import Container from "../../ui-components/Container";

export const Wrapper = styled(Container)`
  height: calc(100vh - ${props => props.theme.ui.heights.navBase});
  padding-block: 64px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    height: calc(100vh - ${props => props.theme.ui.heights.navLg});
    padding-block: 172px 64px;
    display: flex;
  }
`;

export const Content = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding-top: 0;
  transition:
    max-width,
    padding-top 0.3s ease-in-out;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 768px;
  }
`;

export const CustomButton = styled(Button)`
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    align-self: center;

    &.btn {
      width: 400px;
    }
  }
`;
