import React, { useContext } from "react";
import { UserLoginContext } from "../../context";
import UserPageLayout from "../../ui-components/Layouts/UserPage";
import { CodeVerificationView, EmailView } from "./components";
import { Wrapper } from "./styles";

export default function Login() {
  const { step } = useContext(UserLoginContext);

  return (
    <UserPageLayout showFooter>
      <Wrapper withCityView>
        <>
          {step === "email" && <EmailView />}

          {step === "code" && <CodeVerificationView />}
        </>
      </Wrapper>
    </UserPageLayout>
  );
}
