import { FloatingLabel } from "react-bootstrap";
import styled from "styled-components";

export const StyledFloatingLabelInput = styled(FloatingLabel)`
  line-height: 1.75;
  line-height: 24px;

  &&& > input {
    font-family: "Lato", sans-serif;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &.initialValue {
    .form-control {
      color: ${props => props.theme.colors.gray3};
    }
  }

  .form-control {
    color: ${props => props.theme.colors.gray1};
    border: 1px solid ${props => props.theme.colors.gray3};
    font-weight: 500;
  }

  &.form-floating > label {
    transform-origin: -1.5rem 0;
    padding-left: ${props => (props.icon ? "56px" : "1.125rem")};
    font-size: ${props => props.theme.fontSizes.sm};
    font-weight: ${props => props.theme.fontWeights.default};
    color: ${props => props.theme.ui.colors.primary700};
    opacity: 0.5;
  }

  &.form-floating > .form-control {
    padding-left: ${props => (props.icon ? "50px" : "1rem")};
    border-radius: ${props => props.theme.borderRadius.sm};

    ${props => !props.required && "background-image: none;"}
    ${props => props.disabled && "pointer-events: none;"}
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.primaryBlue};
      outline-style: none;
      box-shadow: none;
    }

    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.gray3};
    }

    &:focus ~ svg {
      color: ${props => props.theme.colors.primaryBlue} !important;
      fill: ${props => props.theme.colors.primaryBlue} !important;
    }
  }

  &.form-floating > .form-control.with-shadow:not(.is-invalid) {
    ${props => !props.required && "background-image: none;"}
    ${props => props.disabled && "pointer-events: none;"}
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 4px rgba(3, 70, 245, 0.2) !important;
      border: 2px solid ${props => props.theme.ui.colors.primary500} !important;
    }
  }

  &.form-floating > .form-control.with-shadow.form-is-invalid {
    border: 2px solid ${props => props.theme.ui.colors.error500} !important;
    box-shadow: 0 0 0 4px rgba(176, 17, 33, 0.2);

    ${props => !props.required && "background-image: none;"}
    ${props => props.disabled && "pointer-events: none;"}
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px ${props => props.theme.ui.colors.error500} !important;
    }
  }

  &.form-floating > .form-control.is-invalid {
    color: ${props => props.theme.ui.colors.error500};
    border-color: ${props => props.theme.ui.colors.error500};
    box-shadow: 0 0 0 1px ${props => props.theme.ui.colors.error500};

    ~ label,
    ~ svg {
      color: ${props => props.theme.ui.colors.error500};
    }

    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.ui.colors.error500} !important;
      outline-style: none;
      box-shadow: none;
    }

    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.ui.colors.error500};
    }

    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.red}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }

    &:focus ~ svg {
      color: ${props => props.theme.ui.colors.error500} !important;
      fill: ${props => props.theme.ui.colors.error500} !important;
    }
  }

  &.form-floating > .form-control.rounded {
    border-radius: 150px !important;
    font-family: ${props => props.theme.ui.fontFamilies.default};
  }

  svg {
    position: absolute;
    left: 21px;
    top: 31px; // Not happy with this. Error messages expanding height of the input led me to this hardcoded value
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.default};
    cursor: text;
    color: ${props => props.theme.colors.gray3};

    &.iconEnd {
      left: auto;
      right: 21px;
    }
  }
`;
