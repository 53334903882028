import { ClaimSteps } from "../scenes/public/ClaimForm/types";

export interface Step {
  [key: number]: {
    title?: string;
    heading?: string;
    description?: string;
  };
}

export const ClaimStepCopy: Step = {
  0: {
    title: "Where was it lost?",
    heading: "Supported partners",
  },
  1: {
    title: "Where exactly?",
    heading: "Supported locations",
  },
  2: {
    title: "Report a lost item",
    heading: "Help us find your item",
    description: "Give us a detailed description of your lost item.",
  },
  3: {
    title: "Report a lost item",
    heading: "How should we contact you?",
    description: "Your contact details will help us update you on your lost item.",
  },
  4: {
    title: "Review claim",
    heading: "Supported locations",
    description: "Give us a detailed description of your lost item.",
  },
  5: {
    title: "Check your email!",
  },
  6: {
    title: "Claim submitted!",
    description: "Sit back, relax and let us do the searching for you.",
  },
};

export const UpdateClaimStepCopy: Step = {
  [ClaimSteps.ITEM_DETAILS]: {
    title: "Edit claim details",
    heading: "Edit details",
    description: "Give us a detailed description of your lost item.",
  },
  [ClaimSteps.REVIEW_DETAILS]: {
    title: "Review changes",
  },
  [ClaimSteps.SUBMITTED]: {
    title: "Claim updated!",
    description: "Sit back, relax and let us do the searching for you.",
  },
};

export const ItemStepCopy: Step = {
  0: {
    title: "Where exactly?",
    heading: "Supported locations",
  },
  1: {
    title: "Add found item",
    heading: "Let’s get some details",
    description:
      "The more details you provide, the better chance Boomerang can help connect someone with their lost item.",
  },
  2: {
    title: "Review item",
  },
  3: {
    title: "Item added!",
  },
};

// TODO: rename file to have both.
export const UpdateItemStepCopy: Step = {
  0: {
    title: "Where exactly?",
    heading: "Supported locations",
  },
  1: {
    title: "Edit item details",
    heading: "Edit details",
    description:
      "The more details you provide, the better chance Boomerang can help connect someone with their lost item.",
  },
  2: {
    title: "Review changes",
  },
  3: {
    title: "Item updated!",
  },
};
