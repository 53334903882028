import { ApiHandlerRes, useApiHandler } from ".";
import { PartnerShipment, PartnerShipmentByIdRequest } from "../types";
import fetchData from "../utils/fetchData";
import { getCommonHeaders } from "../utils/headers";

export const getPartnerShipmentById = async (
  partnerShipmentIdReq: PartnerShipmentByIdRequest,
): Promise<PartnerShipment | null> => {
  return fetchData(
    `/api/partner/partners/${partnerShipmentIdReq.partnerId}/shipments/${partnerShipmentIdReq.shipmentId}/`,
  );
};

const markAsPackaged = (
  partnerShipmentIdReq: PartnerShipmentByIdRequest,
): Promise<PartnerShipment | null> => {
  return fetchData(
    `/api/partner/partners/${partnerShipmentIdReq.partnerId}/shipments/${partnerShipmentIdReq.shipmentId}/packaged/`,
    {
      method: "POST",
      headers: getCommonHeaders(),
    },
  );
};

export const useGetPartnerShipmentById = (): ApiHandlerRes<
  PartnerShipmentByIdRequest,
  PartnerShipment | null
> => useApiHandler(getPartnerShipmentById);

export const useMarkAsPackaged = (): ApiHandlerRes<
  PartnerShipmentByIdRequest,
  PartnerShipment | null
> => useApiHandler(markAsPackaged);
