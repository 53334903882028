import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import { useAuthState } from "../../context/auth";
import { ShippingCheckoutContext } from "../../context/ShippingCheckout";
import useClaim from "../../hooks/data/user/useClaim";
import { ErrMessage } from "../typography";

export default function CheckoutForm() {
  const { checkoutData, setIsLoading } = useContext(ShippingCheckoutContext);
  const stripe = useStripe();
  const { user } = useAuthState();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { claim } = useClaim(checkoutData.claimId);
  const country = claim?.lost_location.address.country_code ?? "US";

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: [location.origin, "claim", checkoutData.claimId]
          .join("/")
          .concat(
            `?success=true&transactionId=${checkoutData.transactionId}&rateId=${checkoutData.selectedDeliveryMethod?.id}`,
          ),
        payment_method_data: {
          billing_details: {
            name: user.full_name,
            email: user.email,
          },
        },
      },
      //redirect: "if_required"
    });

    if (error) {
      setErrorMessage(error.message);
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} id="paymentInfo">
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                country,
              },
            },
          },
        }}
      />

      <ErrMessage>{errorMessage}</ErrMessage>
    </form>
  );
}
