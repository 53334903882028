import { usePagination, useSearchParam } from "client/hooks";
import { RETURNS_ALL } from "client/hooks/data/partner/partnerReturnCounts";
import useCategoryCounts from "client/hooks/data/partner/partnerReturnCounts/useCategoryCounts";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { useSearchParamsWithZod } from "client/hooks/useSearchParamsWithZod";
import { PartnerReturnFilters, ReturnModeEnum, ReturnStatus } from "client/types";
import { useMemo } from "react";
import { z } from "zod";

type useReturnFiltersProps = {
  count: number;
};

export default function useReturnFilters({ count = 0 }: useReturnFiltersProps) {
  const { partnerUserLocation } = usePartnerLocationStore();
  const { page, pageSize } = usePagination({ count });
  const [queryRecoveryMethod] = useSearchParam<ReturnModeEnum | "all">("recoveryMethod", "all");
  const { searchParams: filterParams } = useSearchParamsWithZod({
    custom_status: z.string().default(RETURNS_ALL),
    category: z.string().default(RETURNS_ALL),
    storage_location: z.string().default(RETURNS_ALL),
  });
  const { categoryCounts: allCategories } = useCategoryCounts({});

  const {
    custom_status: queryStatus,
    category: queryCategory,
    storage_location: queryStorageLocation,
  } = filterParams;

  const selectedCategory = useMemo(
    () => allCategories?.find(({ value }) => value === queryCategory),
    [allCategories, queryCategory],
  );

  const returnFilters = useMemo<PartnerReturnFilters>(() => {
    return {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      ...(queryRecoveryMethod === null || queryRecoveryMethod === "all"
        ? {}
        : {
            mode: queryRecoveryMethod.split(",") as ReturnModeEnum[],
          }),
      status: ReturnStatus.PENDING,
      ...(partnerUserLocation.id !== "" ? { lost_location: [partnerUserLocation.id] } : {}),
      custom_status: queryStatus === RETURNS_ALL ? undefined : queryStatus,
      storage_location: queryStorageLocation === RETURNS_ALL ? undefined : queryStorageLocation,
      category: selectedCategory?.value === RETURNS_ALL ? undefined : selectedCategory?.value,
      include_filtered_counts: "True",
    };
  }, [
    queryRecoveryMethod,
    partnerUserLocation,
    page,
    pageSize,
    queryStatus,
    queryStorageLocation,
    selectedCategory,
  ]);

  return returnFilters;
}
