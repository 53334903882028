import styled from "styled-components";

export const ModalWrapper = styled.div<{
  isOpen: boolean;
}>`
  ${props =>
    props.isOpen &&
    `
  display: block; 
  position: initial;
  `}
`;

export const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray200};
  padding: 20px;
`;

export const Footer = styled.div`
  border-top: 1px solid ${props => props.theme.ui.colors.gray200};
  padding: 16px;
`;

export const Dialog = styled.div`
  border-radius: 16px;
  max-width: 400px;
  margin: 6px;

  @media (min-width: 412px) {
    margin: 6px auto;
  }
`;

export const Content = styled.div`
  border: none;
`;

export const Overlay = styled.div<{
  isOpen: boolean;
}>`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: ${props => (props.isOpen ? "block" : "none")};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
`;
