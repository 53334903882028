import Badge, { BadgeSizesEnum, BadgeVariantsEnum } from "client/components/Badge";
import { SmallText } from "client/components/typography";
import { isMdQuery } from "client/helpers/mediaQuery";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import theme from "client/theme";
import { Category, PartnerItem, UnclaimedStatus } from "client/types";
import { NoPhotoImagePlaceholder } from "client/ui-components/ImageCarousel/styles";
import Text from "client/ui-components/Text";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { If } from "client/utils/If";
import { formattedInventoryId } from "client/utils/stringUtils";
import React from "react";
import { ConfirmedDatePicker } from "./ConfirmedDatePicker";
import { StatusPicker } from "./StatusPicker";
import { TruncatedText } from "./styles";

interface Props {
  item: PartnerItem;
  category?: Category;
  unclaimedStatuses?: UnclaimedStatus[] | null;
  onItemChange?: (item: PartnerItem) => void;
}

export function ItemDetailsWithControls({
  item,
  category,
  unclaimedStatuses,
  onItemChange,
}: Props) {
  const isMd = isMdQuery();
  const { name, images = [] } = item;
  const label = `ID: ${formattedInventoryId(item.id)}`;
  const dateFormatter = useDateFormatter();

  return (
    <div className="d-flex flex-column gap-3 w-100 " key={item.id}>
      <div className="d-flex  gap-3 w-100 h-100 ">
        {images.length ? (
          <img
            style={{ width: 100, height: 100, borderRadius: 16, flexShrink: 0 }}
            src={images?.[0]?.image}
          />
        ) : (
          <NoPhotoImagePlaceholder
            className="flex-shrink-0"
            $dimensions={{ width: 100, height: 100 }}
          />
        )}
        <div
          className="d-flex flex-column w-100 gap-1 justify-content-between overflow-hidden"
          style={{ maxHeight: 100 }}
        >
          <div className="d-flex flex-grow-1 flex-column gap-2 ">
            <Badge
              variant={BadgeVariantsEnum.Contained}
              className="text-truncate text-nowrap badge overflow-hidden"
              size={BadgeSizesEnum.Regular}
              label={category?.name ?? ""}
              color={theme.ui.colors.primary700}
              style={{ fontFamily: "lato" }}
            />
            <Text fontWeight={theme.ui.fontWeights.bold} fontFamily={theme.ui.fontFamilies.default}>
              <TruncatedText>{name}</TruncatedText>
            </Text>
          </div>
          <div>
            <Text
              color={theme.ui.colors.gray600}
              fontSize={theme.ui.fontSizes.sm}
              fontFamily={theme.ui.fontFamilies.default}
            >
              {label}
            </Text>
          </div>
          <If condition={!isMd}>
            <div className="d-flex flex-column gap-2">
              <SmallText>
                Date found:{" "}
                {item.collected_at
                  ? dateFormatter(convertToDateIgnoringTime(item.collected_at), "long")
                  : "-"}
              </SmallText>
            </div>
          </If>
        </div>
      </div>
      <If condition={!isMd}>
        <div className="d-flex gap-3 flex-fill justify-content-start">
          <StatusPicker
            label="Status"
            selected={item.unclaimed_status}
            allowedStatuses={unclaimedStatuses ?? []}
            onChange={status =>
              onItemChange?.({ ...item, unclaimed_status: status as UnclaimedStatus })
            }
          />
          <ConfirmedDatePicker
            label="Date updated"
            date={item.unclaimed_status_set_at ?? null}
            onSelect={date => onItemChange?.({ ...item, unclaimed_status_set_at: date })}
          />
        </div>
      </If>
    </div>
  );
}
