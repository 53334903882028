import styled from "styled-components";
import { CheckSolidIcon } from "../../../../../../assets/icons/icons";
import Button from "../../../../../../ui-components/Button";

export const StyledCheckSolidIcon = styled(CheckSolidIcon)`
  width: 38px;
  height: 38px;
  color: #06844f;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    width: 57px;
    height: 57px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  margin-bottom: 140px;
`;

export const StyledButton = styled(Button)`
  margin: 0 20px;
  width: calc(100% - 40px);
  max-width: 400px !important;
  height: 60px !important;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px !important;
  }
`;

export const Thumbnail = styled.img`
  width: 71px;
  height: 71px;
  border-radius: 16px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    width: 106.5px;
    height: 106.5px;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const FloatingCheckSolidIcon = styled(StyledCheckSolidIcon)`
  position: absolute;
  right: calc(50% + -50px);

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    right: calc(50% + -80px);
  }
`;
