export const lostItemNameCopy = {
  heading: "What did you lose?",
  subheading: "Lost something?",
  supportingText: "What did you lose at ",
  inputPlaceholder: "Name of lost item",
  inputErrorMessage: "This field is required.",
  nextButton: "Next",
  nextButtonUpdate: "Update",
  previousButton: "Go back",
  previousButtonUpdate: "Cancel",
};
