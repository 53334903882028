import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Controller, UseFormReturn, useFormState } from "react-hook-form";
import CardFormFooter from "../CardFormFooter";
import Dropzone from "../Dropzone";
import DropzoneThumbnails from "../DropzoneThumbnails";
import StepHeader from "../StepHeader";
import StyledDatepicker from "../StyledDatepicker";
import StyledFloatingLabel from "../StyledFloatingLabel";
import StyledSelect from "../StyledSelect";
import StyledTextarea from "../StyledTextarea";

import { DateIcon, EditIcon, LocationPin, ShippingIcon } from "../../assets/icons/icons";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";

import { ItemSteps } from "../../scenes/private/ItemForm/types";
import { ClaimSteps } from "../../scenes/public/ClaimForm/types";

import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { PartnerUserStateLegacy } from "../../context/PartnerUser/state";
import { UserState } from "../../context/User/state";
import { Step } from "../../copy/artifactFormCopy";
import { isLgQuery } from "../../helpers/mediaQuery";
import {
  ArtifactImagePreview,
  Category,
  Claim,
  ImageSearchResponse,
  PartnerItem,
  PartnerStorageLocation,
} from "../../types";
import { convertFromHEIC } from "../../utils/imageConversion";
import useQueryString from "../../utils/useQueryString";
import { StyledDropzoneCol } from "./styles";
import { ArtifactDetails } from "./useArtifactDetailsForm";

const MAX_FILES_UPLOAD = 3;

interface ArtifactDetailsFormProps {
  artifact?: Claim | PartnerItem;
  artifactImages?: ImageSearchResponse;
  detailsOverride?: ArtifactDetails;
  partnerMessage?: string;
  categories: Category[];
  storageLocations?: PartnerStorageLocation[];
  contextState?: UserState | PartnerUserStateLegacy;
  isClaim: boolean;
  isEditing: boolean;
  entryPoint?: ClaimSteps | ItemSteps;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onCancelOrBack: () => void;
  setImageIdsToDelete: (value: string[] | ((prevState: string[]) => string[])) => void;
  stepCopy: Step;
}

export const ArtifactDetailsForm = ({
  artifact,
  artifactImages,
  contextState,
  categories,
  storageLocations,
  control,
  detailsOverride,
  entryPoint,
  getValues,
  isClaim,
  isEditing = false,
  onCancelOrBack,
  onSubmit,
  partnerMessage,
  reset,
  setImageIdsToDelete,
  setValue,
  stepCopy,
  trigger,
  watch,
}: ArtifactDetailsFormProps & UseFormReturn<ArtifactDetails, object>) => {
  let locationSlug = window.location.pathname.split("/").slice(-1)[0];
  const query = useQueryString();
  if (query.has("l")) {
    locationSlug = query.get("l") as string;
  }
  const { isSubmitting, isDirty, errors } = useFormState({ control });
  const images = watch("images");
  const [imagesLoading, setImagesLoading] = useState<number>(0);
  const [revokeObjectUrl, setRevokeObjectUrl] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");
  const [leftBtnText, setLeftBtnText] = useState<string>("");
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  const isExistingArtifact = artifact !== undefined;
  const isLg = isLgQuery();
  const rightBtnText = isExistingArtifact || !isEditing ? "Next" : "Update";
  const rightBtnDisabled = isSubmitting || !isDirty || imagesLoading > 0;
  const enumStepToUse = isClaim ? ClaimSteps : ItemSteps;

  const stepHeading = stepCopy[enumStepToUse.ITEM_DETAILS].heading || "";
  const stepDescription = `${stepCopy[enumStepToUse.ITEM_DETAILS].description}${
    partnerMessage ?? ""
  }`;
  const noBottomPadding = Boolean(partnerMessage);

  const foundDate = watch("foundDate");
  const detailedDescription = watch("detailedDescription");

  const hasImages = artifactImages !== undefined && artifactImages?.count > 0;
  const existingImages: ArtifactImagePreview[] = hasImages
    ? (artifactImages.results || []).map(i => {
        return {
          id: i.id,
          preview: i.image,
        };
      })
    : [];

  // onChange handlers
  const onChangeNameOfItem = e => {
    setValue("itemName", e.target.value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    if (errors.itemName) {
      trigger("itemName");
    }
  };

  const onChangeCategory = e => {
    const options = {
      shouldDirty: true,
      shouldTouch: true,
    };
    setValue("itemCategoryIdValue", e.target.value, options);
    trigger("itemCategoryIdValue");
  };

  const onChangeStorageSelection = e => {
    const options = {
      shouldDirty: true,
      shouldTouch: true,
    };
    setValue("storageLocationIdValue", e.target.value, options);
    trigger("storageLocationIdValue");
  };

  const onChangeCharacteristics = e => {
    setValue("detailedDescription", e.target.value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    if (errors.detailedDescription) {
      trigger("detailedDescription");
    }
  };

  const onChangeFoundDate = date => {
    const options = {
      shouldDirty: true,
      shouldTouch: true,
    };
    setValue("foundDate", date, options);
    if (errors.foundDate) {
      trigger("foundDate");
    }
  };
  const dropzoneInputRef = useRef<HTMLDivElement>(null);

  const onAddImages = async newImages => {
    setImagesLoading(newImages.length);

    for (let i = 0; i < newImages.length; i++) {
      // HEIC to JPG here
      newImages[i] = await convertFromHEIC(newImages[i]);
    }

    const newValue = [...getValues("images")].concat(newImages);
    setImagesLoading(0);
    setValue("images", newValue, { shouldDirty: true });
  };

  const onDeleteImage = (index, id) => {
    if (isExistingArtifact && id) {
      setImageIdsToDelete(urls => [...urls, id]);
    }
    const newValue = [...getValues("images")];
    newValue.splice(index, 1);
    setValue("images", newValue, { shouldDirty: true });
  };

  // useEffects
  useEffect(() => {
    if (!artifact) {
      return;
    }
    if (artifact && !detailsOverride) {
      let foundDate: Date;
      let itemCategoryIdValue: string;
      let storageLocationIdValue: string | undefined;

      if (isClaim) {
        foundDate = convertToDateIgnoringTime((artifact as Claim).lost_at);
        itemCategoryIdValue = (artifact.category as Category).id;
      } else {
        const partnerItem = artifact as PartnerItem;
        foundDate = partnerItem.collected_at
          ? convertToDateIgnoringTime(partnerItem.collected_at)
          : new Date();
        itemCategoryIdValue = partnerItem.category;

        if (typeof partnerItem.storage_location === "string") {
          if (partnerItem.storage_location.toUpperCase() === "MOVED") {
            storageLocationIdValue = partnerItem.storage_location.toUpperCase();
          } else {
            storageLocationIdValue = partnerItem.storage_location;
          }
        } else if (partnerItem.storage_location?.id) {
          storageLocationIdValue = partnerItem.storage_location?.id;
        }
      }

      reset({
        foundDate,
        itemCategoryIdValue,
        storageLocationIdValue,
        detailedDescription: artifact.description,
        itemName: artifact.name,
        images: existingImages,
      });
    }
  }, []);

  useEffect(() => {
    if (!artifact) {
      return;
    }
    isSubmitting && setValue("images", existingImages);
  }, [contextState]);

  useEffect(() => {
    if (dropzoneInputRef.current) {
      (
        dropzoneInputRef.current as HTMLDivElement
      )?.children[0]?.children[0]?.children[0]?.setAttribute("tabIndex", String(isLg ? 0 : 5));
    }
  }, [dropzoneInputRef]);

  useEffect(() => {
    mountBodyGray6();
    return () => {
      setRevokeObjectUrl(true);
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    if (!detailedDescription) {
      return;
    }
    setHelperText(() => {
      if (detailedDescription.length === 0) {
        return "Enter at least 50 characters";
      }
      if (detailedDescription.length < 50) {
        return `${50 - detailedDescription.length} more characters to go...`;
      }
      return "";
    });
  }, [detailedDescription]);

  useEffect(() => {
    setLeftBtnText(() => {
      if (isExistingArtifact) {
        return "Cancel";
      }

      if (isEditing) {
        return "";
      }
      if (entryPoint === enumStepToUse.ITEM_DETAILS) {
        return "Cancel";
      }

      return "Back";
    });
  }, [isEditing, entryPoint]);

  useEffect(() => {
    setLoadingText(() => {
      if (imagesLoading > 0) {
        return "Uploading photos";
      }
      return undefined;
    });
  }, [imagesLoading]);

  // inputs

  const itemName = (
    <Col xs="12" lg="6" className="order-1 px-0 pe-lg-225">
      <Controller
        render={({ field }) => (
          <StyledFloatingLabel
            {...field}
            autoFocus
            errors={errors?.itemName}
            data-testid="itemNameField"
            ariaLabel="Item name"
            label="Item name"
            placeholder="Item name"
            className="mb-3"
            isValidated={errors.itemName}
            onChange={onChangeNameOfItem}
            icon={<EditIcon titleId="pencilIcon" accessibilityTitle="pencil icon" />}
            required
            lpIgnore="true"
            tabIndex={isLg ? 0 : 1}
            type="text"
          />
        )}
        name="itemName"
        control={control}
        defaultValue=""
        rules={{
          required: "Item name is required",
          minLength: {
            value: 3,
            message: "Item name must be at least 3 characters",
          },
        }}
      />
    </Col>
  );

  const category = (
    <Col xs="12" lg="6" className="order-2 order-lg-3 px-0 pe-lg-225">
      <Controller
        render={({ field }) => (
          <StyledSelect
            {...field}
            label="Category"
            className="mb-3"
            icon={<ShippingIcon titleId="boxIcon" accessibilityTitle="box icon" />}
            options={categories}
            onChange={onChangeCategory}
            errors={errors.itemCategoryIdValue}
            isValidated={errors.itemCategoryIdValue}
            required
            tabIndex={isLg ? 0 : 3}
          />
        )}
        name="itemCategoryIdValue"
        control={control}
        rules={{
          required: "A category is required",
        }}
      />
    </Col>
  );

  const hasStorageLocations = storageLocations && storageLocations.length > 0;
  const isStorageLocationMoved =
    ((artifact as PartnerItem)?.storage_location as string)?.toUpperCase?.() === "MOVED";

  const storageLocation = hasStorageLocations && (
    <Col xs="12" lg="6" className="order-4 order-lg-4 px-0 ps-lg-225">
      <Controller
        render={({ field }) => (
          <StyledSelect
            {...field}
            label="Storage location"
            className="mb-3"
            icon={<LocationPin accessibilityTitle="storageLocation" titleId="storageLocation" />}
            options={
              isStorageLocationMoved
                ? [{ id: "MOVED", name: "Moved to other location" }, ...storageLocations]
                : storageLocations
            }
            onChange={onChangeStorageSelection}
            errors={errors.storageLocationIdValue}
            isValidated={errors.storageLocationIdValue}
            required
            tabIndex={isLg ? 0 : 3}
          />
        )}
        name="storageLocationIdValue"
        control={control}
        rules={{
          required: "A storage location is required",
        }}
      />
    </Col>
  );

  const description = (
    <Col
      xs="12"
      lg="6"
      className={`order-3 order-lg-5 ${!hasStorageLocations ? "w-100 pe-lg-0" : "pe-lg-225"} px-0 `}
    >
      <Controller
        render={({ field }) => (
          <StyledTextarea
            {...field}
            errors={errors.detailedDescription}
            helperText={helperText}
            ariaLabel="Unique characteristics"
            label="Unique characteristics"
            placeholder="Unique characteristics"
            className="mb-3"
            type="textarea"
            icon={<EditIcon titleId="pencilIcon" accessibilityTitle="pencil icon" />}
            isValidated={errors.detailedDescription}
            onChange={onChangeCharacteristics}
            required
            tabIndex={isLg ? 0 : 2}
          />
        )}
        name="detailedDescription"
        control={control}
        defaultValue=""
        rules={{
          required: "Detailed description is required",
          minLength: {
            value: 50,
            message: helperText,
          },
        }}
      />
    </Col>
  );

  const foundDateInput = (
    <Col xs="12" lg="6" className="order-4 order-lg-2 px-0 ps-lg-225">
      <Controller
        render={({ field }) => (
          <StyledDatepicker
            {...field}
            selected={foundDate}
            value={foundDate}
            ariaLabel={`${isClaim ? "Lost" : "Found"} date`}
            label={`${isClaim ? "Lost" : "Found"} date`}
            onChange={onChangeFoundDate}
            errors={errors.foundDate}
            icon={<DateIcon titleId="calendarIcon" accessibilityTitle="calendar icon" />}
            className="mb-3"
            required
            isValidated={errors.foundDate}
            maxDate={new Date()}
            minDate={new Date(new Date().setDate(new Date().getDate() - 90))}
            tabIndex={isLg ? 0 : 4}
          />
        )}
        name="foundDate"
        control={control}
        defaultValue={new Date()}
        rules={{
          required: `${isClaim ? "Lost" : "Found"} date is required`,
        }}
      />
    </Col>
  );

  const photos = (
    <>
      <StyledDropzoneCol
        className={`mb-3 ${
          !hasStorageLocations && isLg ? "order-4 offset-6" : "order-5"
        }  px-0 ps-lg-225 col-12 col-lg-6 align-self-center`}
      >
        <div className="row" ref={dropzoneInputRef}>
          <Dropzone
            files={images}
            loading={imagesLoading !== 0}
            maxFiles={MAX_FILES_UPLOAD}
            revokeObjectUrl={revokeObjectUrl}
            setFiles={onAddImages}
            tabIndex={isLg ? 0 : 5}
          />
        </div>
      </StyledDropzoneCol>
      <DropzoneThumbnails
        deleteImage={onDeleteImage}
        images={images}
        imagesLoading={imagesLoading}
        maxFilesUpload={MAX_FILES_UPLOAD}
        className={`mt-lg-45 ${
          (MAX_FILES_UPLOAD > images?.length &&
            (images?.length !== 0 || imagesLoading > 0) &&
            "mb-lg-4") ||
          ""
        }`}
        tabIndex={isLg ? 0 : 6}
      />
    </>
  );

  return (
    <Form data-testid="report-lost-item-form" noValidate className="px-3" onSubmit={onSubmit}>
      <div className="px-1 px-lg-4 pt-4 pb-0 rounded-0 modal-body">
        <StepHeader
          noBottomPadding={noBottomPadding}
          title={stepHeading}
          text={stepDescription}
          warning={
            /* NOTE: Implemented for https://thanksboomerang.atlassian.net/browse/ENG-1345 */
            [
              "spectrum-resort-orlando",
              "encore-resort-orlando",
              "margaritaville-cottages-orlando",
            ].indexOf(locationSlug.toLowerCase()) > -1
              ? "**If you stayed in one of our rooms, please provide your room number."
              : undefined
          }
        />
        <div className="row mx-0">
          {itemName}
          {category}
          {description}
          {foundDateInput}
          {photos}
          {storageLocation}
        </div>
      </div>
      <CardFormFooter
        leftBtnOnClick={onCancelOrBack}
        leftBtnTabIndex={isLg ? 0 : 6}
        leftBtnText={leftBtnText}
        loading={isSubmitting || imagesLoading > 0}
        loadingText={loadingText}
        rightBtnDisabled={rightBtnDisabled}
        rightBtnTabIndex={isLg ? 0 : 7}
        rightBtnText={rightBtnText}
      />
    </Form>
  );
};
