export const OTPCopy = {
  heading: "Check your email",
  subheading: "Lost something?",
  instructions:
    "Enter the code below to confirm your email address and finish submitting your claim.",
  codeErrorMessage: "Incorrect passcode",
  receivedCode: "Haven't received your code in 5 minutes?",
  codeResent: "Code resent!",
  resendCode: "Resend the code.",
  previousButton: "Go back",
};
