import React from "react";
import Button from "../../Button";
import Link from "../../Link";
import { NavbarContent } from "./types";

const User: NavbarContent = {
  leftMenuItems: [
    <Button
      aria-label="For Partners"
      variant="text"
      href="/for-partners"
      data-testid="forPartnersButton"
    >
      For Partners
    </Button>,
  ],
  leftMenuItemsMobile: [
    <Link variant="gray" href="/for-partners">
      For Partners
    </Link>,
  ],
  rightMenuItems: [
    <Button aria-label="Sign in" variant="text" href="/sign-in" data-testid="userSigninButton">
      Sign in
    </Button>,
    <Button href="/lost" aria-label="Report lost item" data-testid="navBarReportLostItemButton">
      Report a lost item
    </Button>,
  ],
  rightMenuItemsMobile: [
    <Button
      aria-label="Sign in"
      className="mb-275"
      size="2xl"
      fullWidth
      href="/sign-in"
      variant="outline"
    >
      Sign in
    </Button>,
    <Button href="/lost" aria-label="Report lost item" size="2xl" fullWidth>
      Report lost item
    </Button>,
  ],
};

export default User;
