import { AllCaughtUp as AllCaughtUpIcon } from "@assets/icons/icons";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isLgQuery, isMdQuery, isXsQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Typography } from "../../../../ui-components";
import Button from "../../../../ui-components/Button";
import { ButtonVariants } from "../../../../ui-components/Button/types";
import Text from "../../../../ui-components/Text";
import { useMatchingStore } from "../matchingStore";
import SideOrTopPanel from "./SideOrTopPanel";
import { MainWrapper, MatchingCard, StyledContainer, Wrapper } from "./styles";

type CTAProps = {
  ariaLabel: string;
  dataTestId: string;
  variant: ButtonVariants;
  href: string;
  children: React.ReactNode;
};

function CTA({ ariaLabel, dataTestId, variant, href, children }: CTAProps) {
  const isMd = isMdQuery();
  const setShowAllCaughtUp = useMatchingStore(state => state.setShowAllCaughtUp);

  useEffect(() => {
    return () => {
      setShowAllCaughtUp(false);
    };
  }, []);

  return (
    <Button
      style={{ minWidth: "197px" }}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      fullWidth={!isMd}
      size={"xl"}
      className="mt-25"
      variant={variant}
      href={href}
    >
      {children}
    </Button>
  );
}

type ParagrapahProps = {
  bold: boolean;
  text: string;
};

function Paragraph({ bold, text }: ParagrapahProps) {
  return (
    <Typography
      as="p"
      fontWeight={bold ? theme.fontWeights.bold : theme.fontWeights.light}
      fontSize={theme.ui.fontSizes.base}
      color={theme.ui.colors.primary700}
      lineHeight={1.5}
      textAlign="center"
    >
      {text}
    </Typography>
  );
}

export default function AllCaughtUp() {
  const isSm = isXsQuery();
  const isLg = isLgQuery();
  const location = useLocation<{ returnPath: string }>();
  const returnPath = location.state?.returnPath;

  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);

  const goToPotentialMatchesButton = (
    <CTA
      ariaLabel="GoToPotentialMatchesButton"
      dataTestId="goToPotentialMatchesButton"
      variant="primary"
      href="/partner/matches"
    >
      <Text fontWeight={theme.fontWeights.bold} color={theme.colors.white}>
        Go to Potential matches
      </Text>
    </CTA>
  );

  const goToClaimDetailsButton = (
    <CTA
      ariaLabel="GoToClaimDetailsButton"
      dataTestId="goToClaimDetailsButton"
      variant="outline"
      href={`/partner/claim/${artifactToMatch?.id}`}
    >
      <Text fontWeight={theme.fontWeights.bold}>Go to Claims details</Text>
    </CTA>
  );

  const ctasComingFromPotentialMatches = !isLg ? (
    <>
      {goToPotentialMatchesButton}
      {goToClaimDetailsButton}
    </>
  ) : (
    <div className="d-flex gap-25">
      {goToPotentialMatchesButton}
      {goToClaimDetailsButton}
    </div>
  );

  const addItemToInventoryButton = (
    <CTA
      ariaLabel="AddItemToInventoryButton"
      dataTestId="addItemToInventoryButton"
      variant="primary"
      href="/additem"
    >
      <Text fontWeight={theme.fontWeights.bold} color={theme.colors.white}>
        Add item to inventory
      </Text>
    </CTA>
  );

  const checkReturnsButton = (
    <CTA
      ariaLabel="CheckReturnsButton"
      dataTestId="checkReturnsButton"
      variant="outline"
      href="/partner/returns"
    >
      <Text fontWeight={theme.fontWeights.bold}>Check returns</Text>
    </CTA>
  );

  const ctasComingFromClaims = !isLg ? (
    <>
      {addItemToInventoryButton}
      {checkReturnsButton}
    </>
  ) : (
    <div className="d-flex gap-25">
      {checkReturnsButton}
      {addItemToInventoryButton}
    </div>
  );

  const ctas =
    returnPath !== undefined && returnPath.includes("/partner/claim")
      ? ctasComingFromPotentialMatches
      : ctasComingFromClaims;

  return (
    <StyledContainer className={`${isLg ? "" : "row"} mx-0 d-flex`}>
      <SideOrTopPanel isLookingForMatches={false} />
      <MainWrapper>
        <MatchingCard className="d-flex flex-columnn justify-content-center">
          <Wrapper>
            <AllCaughtUpIcon
              accessibilityTitle="AllCaughtUpIcon"
              titleId="AllCaughtUpIcon"
              className="mb-3"
            />
            <Typography
              heading
              as="h3"
              data-testid="noMoreMatchesHeading"
              fontWeight={theme.fontWeights.bold}
              fontSize={isSm ? "32px" : "24px"}
              lineHeight={isSm ? 1.25 : 1.35}
              color={theme.ui.colors.primary700}
              textAlign="center"
            >
              You're all caught up!
            </Typography>
            <Paragraph
              bold={false}
              text="Our engine will keep diligently searching for matches. Rest
              assured we will notify your team as soon as we find something."
            />
            <Paragraph bold text="What to do next?" />
            {ctas}
          </Wrapper>
        </MatchingCard>
      </MainWrapper>
    </StyledContainer>
  );
}
