import styled from "styled-components";

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  align-self: stretch;

  .react-datepicker {
    box-shadow: 0 9px 30px rgb(0 0 0 / 8%);
    border: none;
    font-family: Lato, sans-serif;
    font-size: ${props => props.theme.fontSizes.default};
    padding: 0;
    border-radius: ${props => props.theme.borderRadius.lg};
    display: flex;
    flex-direction: column;
  }
  .react-datepicker-wrapper {
    height: 36px;
    width: 100%;
  }
  .react-datepicker__navigation {
  }
  .react-datepicker__month-container {
    width: fit-content;
  }
  .react-datepicker-popper {
    min-height: 440px;
  }
  .react-datepicker__header {
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.white};
    text-align: center;
    padding: 0;
    border-top-right-radius: ${props => props.theme.borderRadius.lg};
    border-top-left-radius: ${props => props.theme.borderRadius.lg};
    font-family: Poppins, sans-serif;
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-weight: 700;
    font-family: Lato, sans-serif;
  }
  .react-datepicker__day {
    font-size: 14px;
    line-height: normal;
    border: 1px solid transparent;
    margin: 0.5px;
  }

  .react-datepicker__day-name {
    color: ${props => props.theme.colors.gray3};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${props => props.theme.ui.colors.primary500};
  }
  .react-datepicker__day:hover {
    background-color: ${props => props.theme.ui.colors.primary300};
    border: 1px solid ${props => props.theme.ui.colors.primary500};
  }
  .react-datepicker__day--disabled:hover {
    background-color: inherit;
    border: 1px solid transparent;
  }
  .react-datepicker__day--selected:focus {
    /* border: 1px solid ${props => props.theme.ui.colors.primary500}; */
    & > * {
      border: 1px solid ${props => props.theme.ui.colors.white};
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
  }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: ${props => props.theme.fontSizes.lg};
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    padding-left: 64px;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${props => props.theme.colors.gray1};
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 11px;
    position: absolute;
    top: 13px;
    width: 11px;
    border-radius: 2px 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    font-weight: bold;
    font-size: ${props => props.theme.fontSizes.lg};
    padding-left: 64px;
    color: ${props => props.theme.colors.gray1};
  }
  .react-datepicker__day {
    color: ${props => props.theme.colors.gray1};
    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled {
      color: ${props => props.theme.colors.disabled};
      &.react-datepicker__day--selected,
      &.react-datepicker__day--in-selecting-range,
      &.react-datepicker__day--in-range {
        color: ${props => props.theme.colors.gray1};
      }
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 40px;
    line-height: 40px;
    text-align: center;
  }
  .react-datepicker__day.react-datepicker__day--selected:focus {
    outline-width: 4px;
  }
  .react-datepicker__children-container {
    width: 100%;
  }

  .react-datepicker__day--highlighted {
    background-color: #dce1e8;
    color: ${props => props.theme.ui.colors.primary700} !important;
  }
  .react-datepicker__day--selected {
    background-color: ${props => props.theme.ui.colors.primary500} !important;
    color: ${props => props.theme.ui.colors.white} !important;
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${props => props.theme.ui.colors.primary400} !important;
    color: ${props => props.theme.ui.colors.primary700} !important;
    margin: 0.5px 0;
    width: 41px;
    border-radius: 0;
  }

  .react-datepicker__day--in-range {
    margin: 0.5px 0;
    width: 41px;
    border-radius: 0;
  }

  /*  */
  .react-datepicker__week .react-datepicker__day--in-range:last-child,
  .react-datepicker__week .react-datepicker__day--in-selecting-range:last-child {
    border-radius: 0 100px 100px 0;
  }
  .react-datepicker__week .react-datepicker__day--in-range:first-child,
  .react-datepicker__week .react-datepicker__day--in-selecting-range:first-child {
    border-radius: 100px 0 0 100px;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: ${props => props.theme.ui.colors.primary500} !important;
    color: ${props => props.theme.ui.colors.white} !important;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  color: ${props => props.theme.ui.colors.primary700};
  font-weight: 700;
`;

export const StyledArrow = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.ui.colors.primary700};
  border-radius: ${props => props.theme.borderRadius.xs};
  width: 30px;
  height: 30px;
  padding: 1px;
  outline: none;
  & > svg {
    border: 1px solid transparent;
    border-radius: ${props => props.theme.borderRadius.xs};
    width: 25px;
    height: 25px;
  }
  &:hover {
    border: 1px solid ${props => props.theme.ui.colors.primary500};
    background-color: ${props => props.theme.ui.colors.primary300};
  }
  &:focus {
    border: 1px solid ${props => props.theme.ui.colors.primary500};
    padding: 0;
    & > svg {
      border: 1px solid ${props => props.theme.ui.colors.gray500};
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 8px 8px;
  padding: 12px 16px;
  font-weight: 700;
  width: 100%;
  gap: 12px;
  border-top: 1px solid ${props => props.theme.ui.colors.gray200};
`;
