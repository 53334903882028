import React from "react";
import Modal from "../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../components/Modal/Modal.types";

interface ConfirmPickupModalProps {
  closeModal: () => void;
  onSubmit: () => void;
  isLoading;
  children: React.ReactNode;
  modalLoading: boolean;
  partnerId: string;
  showModal: boolean;
}

const ConfirmPickupModal: React.FC<ConfirmPickupModalProps> = props => {
  const { closeModal, onSubmit, children, modalLoading, showModal, isLoading } = props;
  return (
    <Modal
      bottomBtnText={ModalButtonTextEnum.CANCEL}
      bottomBtnOnClick={closeModal}
      header={"Complete pickup"}
      children={children}
      modalLoading={modalLoading}
      show={showModal}
      showXButton
      subHeader={"Almost there! Please review item and contact details."}
      topBtnOnClick={onSubmit}
      topBtnLoading={isLoading}
      topBtnText={ModalButtonTextEnum.YES_COMPLETE_PICKUP}
    />
  );
};

export default ConfirmPickupModal;
