import "react-phone-number-input/style.css";
import styled from "styled-components";
import { Title } from "../typography";

export const ButtonWrapper = styled.div<{
  claimsStyle?: boolean;
  isUserButton?: boolean;
}>`
  max-width: ${props => (props.claimsStyle ? "800px" : "100%")};
  display: flex;
  .btn {
    width: 100%;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props =>
      props.claimsStyle ? props.theme.widths.claimMax : props.theme.widths.portalMax};

    position: relative;
    top: ${props => (props.claimsStyle ? "-28px" : "24px")};
    display: block;
    .btn {
      width: ${props => (props.isUserButton ? "187px" : "221px")};
      float: right;
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.gray1};
    }
  }
`;

export const ContentRow = styled.div<{
  useFullRow?: boolean;
  restrictWidth?: boolean;
  userViewMaxWidth?: boolean;
}>`
  display: grid;
  grid-template-columns: ${props => (props.useFullRow ? "100%" : "auto 1fr auto")};
  align-items: center;
  margin: auto;
  max-width: ${props =>
    props.userViewMaxWidth ? props.theme.widths.claimMax : props.theme.widths.portalMax};
  &.negative-margin {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin-bottom: -74px;
    }
  }
`;

export const HeaderTextWrapper = styled.div`
  height: fit-content;
  height: 126.5px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0 auto;
  }
`;

export const GridElementWrapper = styled.div<{ column: number }>`
  grid-column: ${props => props.column};
  justify-self: center;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.sm};

  &.justify-self-start {
    justify-self: flex-start;
    z-index: ${props => props.theme.zIndexes.header};
  }

  &.clip-overflow {
    min-width: 0;
    overflow-x: clip;
    &.justify-self-center {
      justify-self: center;
    }
    &:not(.justify-self-center) {
      justify-self: auto;
    }
  }
`;

export const NavRow = styled.div<{
  height?: string;
}>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: auto;
  height: ${props => (props.height ? props.height : "auto")};
`;

export const StyledSubHeader = styled.div`
  font-weight: 500;
  color: ${props => props.theme.colors.white};
  svg {
    color: ${props => props.theme.colors.gray4};
  }
`;
export const StyledImg = styled.img`
  width: ${props => props.theme.widths.iconLogo};
  cursor: pointer;
`;

export const StyledTitle = styled(Title)`
  line-height: 1.12;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    line-height: 1.41;
  }
  color: ${props => props.theme.colors.white};
`;

export const TopBackgroundAccent = styled.div<{
  height: string;
}>`
  background-color: ${props => props.theme.ui.colors.primary700};
  height: ${props => props.height};
`;

export const StyledContainerLg = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props => props.theme.widths.portalMax};
    margin: 0 auto;
  }
`;

export const ReturnsIndicator = styled.a`
  color: ${props => props.theme.colors.gray1};
  background-color: ${props => props.theme.colors.white};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  height: fit-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border: 2px solid ${props => props.theme.colors.white};
  /* Glow Blue */
  box-shadow: 0px 0px 15px ${props => props.theme.ui.colors.primary700};

  &.active {
    border: 2px solid ${props => props.theme.ui.colors.primary700};
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.colors.gray1};
  }
`;

export const SearchBarSeparator = styled.div<{
  multipleLocations: boolean;
}>`
  border-bottom: #d1e7ff33 solid 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: ${props => (props.multipleLocations ? "112px" : "100px")};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    top: 103px;
  }
`;
