import styled from "styled-components";
import DateInput from "../../../../../../../ui-components/DateInput";

export const StyledDateInput = styled(DateInput)`
  position: relative;
  background-color: #f3f9ff;

  &:focus-visible {
    outline: none;
    background-color: #f3f9ff;
  }

  &:focus-visible::after {
    content: "";
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    background-color: transparent;
    position: absolute;
    top: -9px;
    left: -9px;
    border-color: ${props => props.theme.colors.primaryBlue};
    border-style: solid;
    border-width: 2px;
    border-radius: inherit;
  }
`;
