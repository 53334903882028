import { ArrowLeft, ArrowRight } from "@assets/icons/icons";
import React, { useRef } from "react";
import { ContainerBgColors } from "../../../../ui-components/SectionContainer";
import { testimonialsCopy } from "../../content";
import {
  Quote,
  Separator,
  StyledCarousel,
  StyledCircleButton,
  StyledControlsContainer,
  StyledLogo,
  StyledSectionContainer,
} from "./styles";

const { Item } = StyledCarousel;

type CarouselRef = {
  prev: () => void;
  next: () => void;
};

const Testimonials = () => {
  const ref = useRef<CarouselRef>(null);

  const onPrevClick = () => {
    ref.current?.prev();
  };

  const onNextClick = () => {
    ref.current?.next();
  };

  return (
    <StyledSectionContainer bgColor={ContainerBgColors.BLUE} className="justify-content-center">
      <StyledCarousel controls={false} indicators={false} ref={ref}>
        {testimonialsCopy.map(({ quote, logo, author }, index) => (
          <Item key={index}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Quote>{quote}</Quote>
              <Separator />
              <StyledLogo src={logo} alt={author} />
              <StyledControlsContainer className="d-flex">
                <StyledCircleButton onClick={onPrevClick} position="left">
                  <ArrowLeft
                    accessibilityTitle={`${index} - Carousel Left Arrow`}
                    titleId={`${index} - Carousel Left Arrow`}
                    color="black"
                  />
                </StyledCircleButton>
                <StyledCircleButton onClick={onNextClick}>
                  <ArrowRight
                    accessibilityTitle={`${index} - Carousel Right Arrow`}
                    titleId={`${index} - Carousel Right Arrow`}
                    color="black"
                  />
                </StyledCircleButton>
              </StyledControlsContainer>
            </div>
          </Item>
        ))}
      </StyledCarousel>
    </StyledSectionContainer>
  );
};
export default Testimonials;
