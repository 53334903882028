import { UserIcon } from "@assets/icons/icons";
import { PlusCircle } from "@styled-icons/bootstrap/PlusCircle";
import { Envelope } from "@styled-icons/boxicons-regular";
import { ContactCard } from "@styled-icons/fluentui-system-regular";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useAcceptPartnerInvite, useGetInviteForPartner } from "../../../actions/invites";
import LoadingButton from "../../../components/Button";
import { ButtonModes } from "../../../components/Button/Button.types";
import { PartnerRoutesEnum } from "../../../components/Routes";
import StyledFloatingLabel from "../../../components/StyledFloatingLabel";
import StyledPhoneInput from "../../../components/StyledPhoneInput";
import privacyPolicy from "../../../documents/privacyPolicy.pdf";
import { StyledHeader, StyledSubHeader } from "../StyledComponentsAuthPages";

const MontserratTypography = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.fontSizes.lg};
  color: ${props => props.theme.colors.gray1};
`;
const Email = styled.a`
  color: ${props => props.theme.colors.primaryBlue};
  font-family: Raleway;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.md};
  line-height: 2;
  letter-spacing: -0.01em;
`;

function useURLQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Welcome() {
  const {
    control: controlOne,
    formState: { errors: errorsOne, isValid: isValidOne, isDirty: isDirtyOne },
    handleSubmit: handleSubmitOne,
    trigger: triggerOne,
    setValue: setValueOne,
    watch: watchOne,
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      phoneNumber: "", // Required to register untouched form for required error.
    },
  });
  const { isSubmitted: isSubmittedOne, isSubmitting: isSubmittingOne } = useFormState({
    control: controlOne,
  });
  const {
    control: controlTwo,
    formState: { errors: errorsTwo },
    handleSubmit: handleSubmitTwo,
    trigger: triggerTwo,
    watch: watchTwo,
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      phoneNumber: "", // Required to register untouched form for required error.
    },
  });
  const {
    isSubmitted: isSubmittedTwo,
    isSubmitting: isSubmittingTwo,
    isValid: isValidTwo,
    isDirty: isDirtyTwo,
  } = useFormState({
    control: controlTwo,
  });

  const password = watchTwo("password");

  const [step, setStep] = useState(1);
  const [invalidInviteId, setInvalidInviteId] = useState(true);

  let history = useHistory();
  let query = useURLQuery();
  const EXTERNAL_ID = query.get("invite_id");

  const [getPartnerInvite, { data: partnerInviteRes }] = useGetInviteForPartner();
  const [acceptInvite, { data: acceptInviteRes, error: acceptInviteError }] =
    useAcceptPartnerInvite();

  useEffect(() => {
    getPartnerInvite(EXTERNAL_ID).catch(() => setInvalidInviteId(true));
  }, []);

  useEffect(() => {
    const fillUserDataFields = partnerInvite => {
      setValueOne("email", partnerInvite?.email);
      setValueOne("firstName", partnerInvite?.first_name);
      setValueOne("lastName", partnerInvite?.last_name);
    };
    if (partnerInviteRes?.external_id) {
      fillUserDataFields(partnerInviteRes);
      setInvalidInviteId(false);
    } else {
      setInvalidInviteId(true);
    }
  }, [partnerInviteRes]);

  const handleSubmit = data => {
    const inviteRequest /*: AcceptInviteRequest*/ = {
      external_id: String(EXTERNAL_ID),
      invite: {
        first_name: watchOne("firstName"),
        last_name: watchOne("lastName"),
        job_title: watchOne("jobTitle"),
        phone_number: watchOne("phoneNumber"),
        password: String(data.password),
      },
    };
    void acceptInvite(inviteRequest);
  };

  useEffect(() => {
    acceptInviteRes?.id && history.push(PartnerRoutesEnum.SIGN_IN);
  }, [acceptInviteRes]);

  useEffect(() => {
    acceptInviteError && toast.error("Error accepting invite");
  }, [acceptInviteError]);

  const renderSwitch = param => {
    switch (param) {
      case 1:
        return "Start onboarding";
      case 2:
        return "Contact info";
      case 3:
        return "Create password";
      default:
        return "Invite ID not found";
    }
  };

  return (
    <>
      <Row className="justify-content-center mt-4 mx-0">
        <Col xs="12" className="mt-5 mb-3 mw520">
          {invalidInviteId !== true && (
            <>
              <MontserratTypography className="mb-3">
                <span className="pe-3" style={{ fontWeight: 700 }}>
                  {step} / 3
                </span>{" "}
                {renderSwitch(step)}
              </MontserratTypography>
              <div className="progress" style={{ height: "6px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: (step / 3) * 100 + "%" }}
                  aria-valuenow={(step / 3) * 100 + "%"}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </>
          )}
        </Col>
      </Row>
      {step === 1 && (
        <Row className="justify-content-center mt-4 mx-0">
          <Col xs="12" sm="auto" className="mt-5 ps-sm-5 ms-sm-n2">
            <StyledSubHeader>
              <span aria-label="Hand waving" role="img" style={{ fontWeight: 500 }}>
                👋
              </span>{" "}
              {partnerInviteRes?.first_name ? `Hello ${partnerInviteRes?.first_name},` : `Hello,`}
            </StyledSubHeader>
            <StyledHeader>Welcome to Boomerang!</StyledHeader>
          </Col>
          <div className="w-100" />
          <Col xs="12" className="mw520">
            {invalidInviteId === true ? (
              <div>
                Oops there’s been a problem - an invite cannot be found. Please use the link we sent
                to your business email.
                <br />
                <br />
                If the email link does not work, please contact
                <br />
                <Email
                  aria-label="Email link to support"
                  href="mailto:support@thanksboomerang.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="no-decorate"
                >
                  <Envelope size="22" /> <span>support@thanksboomerang.com</span>
                </Email>
              </div>
            ) : (
              <Button
                aria-label="Start onboarding button"
                variant="outline-primary"
                onClick={() => setStep(step + 1)}
                className="fw700 px-4"
              >
                <span style={{ fontSize: 16, fontFamily: "Montserrat" }}>Start onboarding</span>
              </Button>
            )}
          </Col>
        </Row>
      )}
      {step === 2 && (
        <Form className={`d-grid`} noValidate onSubmit={handleSubmitOne(() => setStep(3))}>
          <Row className="justify-content-center mt-4 mx-0">
            <Col xs="12" className="mt-5 mw520">
              <StyledSubHeader>Let’s check that everything is right</StyledSubHeader>
              <StyledHeader>Contact info</StyledHeader>
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    ariaLabel="First name"
                    errors={errorsOne.firstName}
                    isValidated={isSubmittedOne}
                    label="First name"
                    placeholder="First name"
                    className="mb-3"
                    type="text"
                    icon={<UserIcon size="20" />}
                    onBlur={e => setValueOne("firstName", e.target.value.trim())}
                    required
                    lpIgnore="true"
                  />
                )}
                name="firstName"
                control={controlOne}
                defaultValue=""
                rules={{
                  required: "First name is required",
                }}
              />
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    isValidated={isSubmittedOne}
                    errors={errorsOne.lastName}
                    ariaLabel="Last name"
                    label="Last name"
                    placeholder="Last name"
                    className="mb-3"
                    type="text"
                    icon={<PlusCircle size="20" />}
                    onBlur={e => setValueOne("lastName", e.target.value.trim())}
                    required
                    lpIgnore="true"
                  />
                )}
                name="lastName"
                control={controlOne}
                defaultValue=""
                rules={{
                  required: "Last name is required",
                }}
              />
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    isValidated={isSubmittedOne}
                    errors={errorsOne.jobTitle}
                    ariaLabel="Job title"
                    label="Job title"
                    placeholder="Job title"
                    className="mb-3"
                    type="text"
                    icon={<ContactCard size="22" />}
                    required
                  />
                )}
                name="jobTitle"
                control={controlOne}
                defaultValue=""
                rules={{
                  required: "Job title is required",
                }}
              />
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    errors={errorsOne.email}
                    isValidated={isSubmittedOne}
                    ariaLabel="Email"
                    label="Email"
                    placeholder="Email"
                    className="mb-3"
                    type="text"
                    icon={<Envelope size="22" />} // TODO get better icons
                    onBlur={e => setValueOne("email", e.target.value.trim())}
                    required
                    lpIgnore="true"
                  />
                )}
                name="email"
                control={controlOne}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
              />
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                name="phoneNumber"
                control={controlOne}
                render={({ field: { onChange, value } }) => (
                  <StyledPhoneInput
                    value={value}
                    onChange={onChange}
                    onBlur={() => triggerOne("phoneNumber")}
                    ariaLabel="Mobile number"
                    label="Mobile number"
                    defaultCountry="US"
                    className={
                      !isSubmittedOne
                        ? ""
                        : errorsOne?.phoneNumber?.types
                          ? "is-invalid"
                          : "is-valid"
                    }
                  />
                )}
                rules={{
                  required: "Phone number is required",
                  validate: value => isValidPhoneNumber(String(value)) || "Phone number is invalid",
                }}
              />
              {errorsOne?.phoneNumber?.types &&
                Object.entries(errorsOne?.phoneNumber?.types).map(([type, message]) => (
                  <div className="text-danger invalid-feedback d-block" key={type}>
                    {message}
                  </div>
                ))}
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520 pt-2 pb-1">
              <p style={{ color: "#666666" }}>
                By providing your information, you consent to the use of your information in
                accordance with our{" "}
                <a target="_blank" rel="noreferrer" href={privacyPolicy}>
                  Privacy Policy
                </a>
                . We take your privacy seriously and will only process your information for its
                intended purposes.
              </p>
            </Col>
            <div className="w-100" />
            <Col xs="12" className="row mx-0 justify-content-end col-6 mw520">
              <LoadingButton
                ariaLabel="Submit button"
                mode={ButtonModes.primary}
                className="fw700 mt-1 col-auto px-4"
                type="submit"
                disabled={!!isSubmittingOne || !isValidOne || !isDirtyOne}
                text="Continue"
                loading={!!isSubmittingOne}
              />
            </Col>
          </Row>
        </Form>
      )}
      {step === 3 && (
        <Form noValidate onSubmit={handleSubmitTwo(handleSubmit)} className={`d-grid`}>
          <Row className="justify-content-center mt-4 mx-0">
            <Col xs="12" className="mt-5 mw520">
              <StyledSubHeader>New account</StyledSubHeader>
              <StyledHeader>Create Password</StyledHeader>
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    ariaLabel="New password"
                    label="New password"
                    placeholder="New password"
                    className="mb-3"
                    errors={errorsTwo.password}
                    type="password"
                    title="Enter a new password at least 8 characters long"
                    autoComplete="new-password"
                    autoFocus
                    isValidated={isSubmittedTwo}
                    onKeyUp={() => {
                      isSubmittedTwo && triggerTwo("passwordConfirm");
                    }}
                    required
                  />
                )}
                name="password"
                control={controlTwo}
                defaultValue=""
                rules={{
                  required: "New password is required",
                  minLength: {
                    value: 8,
                    message: "New password must be at least 8 characters",
                  },
                  validate: {
                    hasNumber: value => /[\d]/.test(value) || "New password must contain a number",
                    hasSpecialChar: value =>
                      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value) ||
                      "New password must contain a special character",
                  },
                }}
              />
              <Controller
                render={({ field }) => (
                  <StyledFloatingLabel
                    {...field}
                    errors={errorsTwo.passwordConfirm}
                    ariaLabel="Confirm password"
                    label="Re-type new password"
                    placeholder="Re-type new password"
                    className={`mb-2`}
                    type="password"
                    isValidated={isSubmittedTwo}
                    required
                  />
                )}
                name="passwordConfirm"
                control={controlTwo}
                defaultValue=""
                rules={{
                  required: "Re-typing password is required",
                  validate: {
                    passwordMatch: value => value === password || "Passwords must match",
                  },
                }}
              />
            </Col>
            <div className="w-100" />
            <Col xs="12" className="mw520">
              <p style={{ color: "#666666" }}>
                Your new password must be at least 8 characters, contain a number and a special
                character ( ! # $ % ? )
              </p>
            </Col>
            <div className="w-100" />
            <Col xs="12" className="row mx-0 justify-content-between col-6 mw520">
              <LoadingButton
                ariaLabel="Back button"
                mode={ButtonModes.open}
                className="fw700 px-5 col-auto"
                onClick={() => setStep(step - 1)}
                text="Back"
                disabled={isSubmittingTwo}
              />

              <LoadingButton
                ariaLabel="Submit button to create profile"
                mode={ButtonModes.primary}
                className="fw700 mt-1 col-auto px-4"
                type="submit"
                disabled={isSubmittingTwo || !isDirtyTwo || !isValidTwo}
                text="Create profile"
                loading={isSubmittingTwo}
                loadingText="Creating profile"
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
