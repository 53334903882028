import React, { useContext } from "react";
import { PartnerClaimContext } from "../../../../../context";
import { Heading } from "../../../../../ui-components";
import TextDisplay from "../TextDisplay";

export default function ContactInfo() {
  const { claim } = useContext(PartnerClaimContext);

  return claim === null ? null : (
    <div className="d-flex flex-column gap-35" data-testid="contactInfoSection">
      <Heading variant="h4">Contact info</Heading>

      <div className="gx-7 gy-45 gy-lg-35">
        <div className="col-12 col-lg-auto">
          <TextDisplay title="Name" data-testid="userName" content={claim.user.full_name} />
        </div>

        {claim.user.phone_number && (
          <div className="col-12 col-lg-auto flex-lg-grow-1">
            <TextDisplay title="Phone" content={claim.user.phone_number} />
          </div>
        )}

        <div className="col-12">
          <TextDisplay title="Email" content={claim.user.email} isLink />
        </div>
      </div>
    </div>
  );
}
