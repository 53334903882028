import { parsePhoneNumberFromString } from "libphonenumber-js";

const formatPhoneNumber = (phoneNumber: string): string => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)?.formatInternational();
  if (!phoneNumber || !parsedPhoneNumber) return "";

  const [country, ...rest] = parsedPhoneNumber.split(" ");

  return `${country} ${rest.join("-")}`;
};

export default formatPhoneNumber;
