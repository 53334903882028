import React, { useContext } from "react";
import { PartnerSelectionContext } from "../../context";
import { isMdQuery } from "../../helpers/mediaQuery";
import UserPageLayout from "../../ui-components/Layouts/UserPage";
import { PartnerSelectSearchView, PartnerSelectView } from "./components";
import { Wrapper } from "./styles";

export default function PartnerSelection() {
  const { isSearchViewVisible } = useContext(PartnerSelectionContext);
  const isMd = isMdQuery();

  return !isMd && isSearchViewVisible ? (
    <PartnerSelectSearchView />
  ) : (
    <UserPageLayout showFooter>
      <Wrapper withCityView>
        <PartnerSelectView />
      </Wrapper>
    </UserPageLayout>
  );
}
