import styled from "styled-components";
import Button from "../../../../ui-components/Button";
import Text from "../../../../ui-components/Text";

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 24px;
  gap: 14px;
  position: relative;
`;

export const Divider = styled.hr`
  opacity: 1;
  margin: 0;
  border-color: ${props => props.theme.ui.colors.gray500};
`;

export const ListItem = styled.button`
  position: relative;
  width: 100%;
  outline-offset: -4px;
  background: transparent;
  border: none;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
  }
`;

export const HighlightedText = styled(Text)<{
  isMatch: boolean;
}>`
  font-weight: inherit;
  background-color: ${props => (props.isMatch ? "#ECB205" : "transparent")};
`;

export const ResetButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
