import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  padding: 2.5rem 2.5rem 1.25rem 2.5rem;
  border-radius: ${props => props.theme.borderRadius.lg};
  flex: 1;
  min-height: 555px;
`;

export const SeeAll = styled.a`
  color: ${props => props.theme.colors.primaryBlue};
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const NoReturns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
`;
