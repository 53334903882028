import React, { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PartnerGlobalSearchContext } from "../../../../client/context/PartnerGlobalSearch";
import theme from "../../../theme";
import { KEYBOARD_KEY } from "../../../types/keyboardKey";
import { MenuItem, PartnerMenuItemNames } from "../../../types/menuElements";
import MoreDropdown from "../../Dropdowns/MoreDropdown";
import { ActionIndicator, ItemWrapper, MenuWrapper, TextSVGWrapper, Underline } from "./styles";

interface NavigationProps {
  menuItems: MenuItem[];
  pathname: string;
  potentialMatchCount: number;
}

const Navigation = (props: NavigationProps) => {
  const { menuItems, pathname, potentialMatchCount } = props;
  const navigationRef = useRef<any>(null);

  const [numItemsVisible, setNumItemsVisible] = useState<number>(5);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState<boolean>(false);
  const fullNavFits = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.navFits})`,
  });

  const { setIsSearchModeOn, setSearchTerm, setShowAllResults } = useContext(
    PartnerGlobalSearchContext,
  );

  useEffect(() => {
    // add when mounted
    window.addEventListener("resize", handleResize);

    // return function to be called when unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [navigationRef?.current?.parentElement?.offsetWidth]);

  const handleResize = () => {
    if (fullNavFits) {
      return menuItems.length - 1;
    }
    const maxWidth = navigationRef?.current?.parentElement?.offsetWidth;
    let allocatedWidth = 0;

    const itemWidths: number[] = [];
    let moreItemIndex = 0;
    navigationRef?.current?.childNodes.forEach((child, i) => {
      if (child.classList.contains("more-item")) {
        moreItemIndex = i;
      }
      allocatedWidth += child.offsetWidth;
      itemWidths.push(child.offsetWidth);
    });
    // If all elements fit besides More, show all
    let numElementsFit = itemWidths.length - 1;
    if (maxWidth < allocatedWidth - itemWidths[moreItemIndex]) {
      for (let i = itemWidths.length - 1; i >= 0; i--) {
        if (i !== moreItemIndex) {
          if (maxWidth < allocatedWidth) {
            numElementsFit--;
            allocatedWidth -= itemWidths[i];
          }
        }
      }
    }
    if (numElementsFit !== numItemsVisible) {
      setNumItemsVisible(numElementsFit);
    }
  };

  const isActive = (url?: string) => {
    if (!url) {
      for (let i = numItemsVisible; i < menuItems.length; i++) {
        if (menuItems[i].url === pathname) {
          return true;
        }
      }
      return false;
    } else {
      return pathname === url;
    }
  };

  const onNavItemClick = (menuItem: MenuItem) => {
    const isMore = menuItem.name === "More";

    if (isMore) {
      setMoreDropdownOpen(!moreDropdownOpen);
      return;
    }

    setIsSearchModeOn(false);
    setSearchTerm("");
    setShowAllResults(false);
    menuItem.onClick();
  };

  const navItems = menuItems.map((menuItem, i) => {
    const isMore = menuItem.name === "More";
    return (
      <NavigationItem
        testId={menuItem.testId}
        key={i}
        showActionIndicator={potentialMatchCount > 0}
        text={menuItem.name}
        icon={<div className={!isMore ? "me-3" : ""}>{menuItem.icon}</div>}
        isActive={isActive(menuItem.url)}
        onClick={() => onNavItemClick(menuItem)}
        className={`${
          // If the item index is less than the number of items visible, or it is More set to visible
          fullNavFits || i < numItemsVisible || (isMore && numItemsVisible !== menuItems.length - 1)
            ? ""
            : "invisible"
        } ${isMore ? "more-item" : ""}`}
        moreDropdown={
          isMore ? (
            <MoreDropdown
              dropdownOpen={moreDropdownOpen}
              menuItems={menuItems.slice(numItemsVisible, -1)}
              pathname={pathname}
              setDropdownOpen={setMoreDropdownOpen}
            />
          ) : undefined
        }
        dropdownOpen={moreDropdownOpen}
      />
    );
  });

  const navItemsReordered =
    numItemsVisible !== menuItems.length - 1
      ? navItems
          .slice(0, numItemsVisible)
          .concat(navItems.slice(-1))
          .concat(navItems.slice(numItemsVisible, -1))
      : navItems;

  return (
    <MenuWrapper ref={navigationRef} className="justify-content-center">
      {fullNavFits ? navItems.slice(0, navItems.length - 1) : navItemsReordered}
    </MenuWrapper>
  );
};

const NavigationItem = (props: {
  className?: string;
  dropdownOpen: boolean;
  icon: React.ReactNode;
  isActive: boolean;
  moreDropdown?: React.ReactNode;
  onClick: () => void;
  showActionIndicator: boolean;
  text: string;
  testId?: string;
}) => {
  const {
    className,
    dropdownOpen,
    icon,
    isActive,
    moreDropdown,
    onClick,
    showActionIndicator,
    text,
    testId,
  } = props;
  if (moreDropdown !== undefined) {
    return (
      <ItemWrapper className={`dropdown ${className}`}>
        <TextSVGWrapper
          data-testid={testId}
          tabIndex={0}
          className={`p-35 fw500 text-nowrap more-wrapper ${isActive ? "active" : ""}`}
          onClick={onClick}
          onKeyPress={e => {
            if (e.key === KEYBOARD_KEY.ENTER) {
              onClick();
            }
          }}
          dropdownOpen={dropdownOpen}
        >
          {text}{" "}
          {text === PartnerMenuItemNames.POTENTIAL_MATCHES && showActionIndicator && (
            <ActionIndicator />
          )}
          {!!icon && icon}
        </TextSVGWrapper>
        <Underline className={`rounded ${isActive ? "active" : ""}`} />
        {moreDropdown}
      </ItemWrapper>
    );
  }
  return (
    <ItemWrapper className={className}>
      <TextSVGWrapper
        data-testid={testId}
        tabIndex={0}
        className={`p-35 fw500 text-nowrap position-relative ${isActive ? "active" : ""}`}
        onClick={onClick}
        onKeyPress={e => {
          if (e.key === KEYBOARD_KEY.ENTER) {
            onClick();
          }
        }}
      >
        {!!icon && icon}
        {text}{" "}
        {text === PartnerMenuItemNames.POTENTIAL_MATCHES && showActionIndicator && (
          <ActionIndicator />
        )}
      </TextSVGWrapper>
      <Underline className={`rounded ${isActive ? "active" : ""}`} />
    </ItemWrapper>
  );
};

export default Navigation;
