import * as Sentry from "@sentry/react";

const captureException = (error: Error, additionalData: any): void => {
  Sentry.captureException(error, additionalData);
};

const captureMessage = (message: string): void => {
  Sentry.captureMessage(message);
};

export default { captureException, captureMessage };
