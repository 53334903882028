import { useQuery } from "@tanstack/react-query";
import useCurrentPartner from "client/utils/useCurrentPartner";
import queryFactory from "./queryFactory";

export default ({ query }: { query: Record<string, any> }) => {
  const partnerId = useCurrentPartner();
  const { data, isLoading, error } = useQuery(queryFactory.itemsFiltered({ partnerId, query }));

  const { results: items, count } = data ?? {};

  return { items, count, isLoading, error };
};
