import { useEffect } from "react";
import useSearchParam from "./useSearchParam";

interface Props {
  count: number | null;
  pageSize?: number;
  key?: string;
}

export default function usePagination({ count, pageSize = 12, key = "" }: Props) {
  const [page, setPage] = useSearchParam<number>(`${key}page`, 1);

  useEffect(() => {
    if (!count) return;
    const maxPage = Math.ceil(count / pageSize);
    if (page < 1) setPage(1);
    if (page > maxPage) setPage(maxPage);
  }, [count, page, pageSize]);

  return { page, pageSize, setPage };
}
