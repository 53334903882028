import classNames from "classnames";
import React, { SyntheticEvent, useEffect, useMemo } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { Country, State } from "../../types";
import StyledFloatingLabel from "../StyledFloatingLabel";
import CountrySelect from "./CountrySelect";
import StateSelect from "./StateSelect";

export type HydratedAddress = {
  country: Country;
  address1: string;
  address2: string;
  city: string;
  state: State;
  zipCode: string;
};

export type AddressFormDataType = {
  country: string;
  address1: string;
  address2: string;
  city: string;
  state: Pick<State, "name" | "id">;
  zipCode: string;
  errors: Record<"address", string[]>;
};

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["form"]> & {
  onAddressCreation?: (address: AddressFormDataType) => void;
  disabled?: boolean;
  errors?: Record<"address", string[]> | null;
  reset: () => void;
  countryId?: string;
};

export default function AddressForm({
  onAddressCreation,
  disabled = false,
  errors: addressErrors,
  countryId,
  reset,
  ...props
}: Props) {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<AddressFormDataType>({
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const country = watch("country");

  const onSubmit: SubmitHandler<AddressFormDataType> = (data: AddressFormDataType) => {
    onAddressCreation && onAddressCreation(data);
  };

  const isUSSelected = useMemo<boolean>(() => {
    return country === "US";
  }, [country]);

  useEffect(() => {
    if (addressErrors !== null) {
      setError("root.serverError", {
        type: "400",
        message: undefined,
      });
    }
  }, [addressErrors]);

  useEffect(() => {
    const subscription = watch(() => {
      clearErrors("root.serverError");

      reset();
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (countryId) {
      setValue("country", countryId);
    }
  }, [countryId, setValue]);

  return (
    <form {...props} onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="d-flex flex-column gap-25" disabled={disabled}>
        <CountrySelect
          {...register("country", {
            onChange: (e: SyntheticEvent<HTMLSelectElement>) => {
              const select = e.nativeEvent.target as HTMLSelectElement;

              const selectedIndex = select.selectedIndex;

              setValue("country", select.options[selectedIndex].value);
            },
          })}
          ariaLabel="Country"
          data-testid="country"
          autoComplete="country"
          autoFocus
          error={errors.country || (errors.root?.serverError as FieldError | undefined)}
          float
          label="Country"
          placeholder="Country"
          value={countryId}
          disabled={countryId !== undefined}
          withoutIcon
        />

        <StyledFloatingLabel
          {...register("address1", { required: "Address is required" })}
          ariaLabel="Address 1"
          autoComplete="street-address"
          errors={errors.address1 || (errors.root?.serverError as FieldError | undefined)}
          isValidated={errors.address1 || (errors.root?.serverError as FieldError | undefined)}
          label="Address 1"
          placeholder="Address 1"
          type="text"
          tabIndex={0}
        />

        <StyledFloatingLabel
          {...register("address2")}
          ariaLabel="Address 2"
          errors={errors.address2 || (errors.root?.serverError as FieldError | undefined)}
          isValidated={errors.address2 || (errors.root?.serverError as FieldError | undefined)}
          label="Address line 2 (optional)"
          placeholder="Address 2"
          type="text"
          tabIndex={0}
        />

        <StyledFloatingLabel
          {...register("city", {
            required: isUSSelected ? "City is required" : "Town/City is required",
          })}
          ariaLabel={isUSSelected ? "City" : "Town/City"}
          errors={errors.city || (errors.root?.serverError as FieldError | undefined)}
          isValidated={errors.city || (errors.root?.serverError as FieldError | undefined)}
          label={isUSSelected ? "City" : "Town/City"}
          placeholder={isUSSelected ? "City" : "Town/City"}
          type="text"
          tabIndex={0}
        />

        <div className="row">
          {isUSSelected && (
            <div className="col-6 col-lg-7">
              <StateSelect
                {...register("state.id", {
                  required: "State is required",
                  onChange: (e: SyntheticEvent<HTMLSelectElement>) => {
                    const select = e.nativeEvent.target as HTMLSelectElement;

                    const selectedIndex = select.selectedIndex;

                    setValue("state.id", select.options[selectedIndex].value);
                    setValue("state.name", select.options[selectedIndex].label);
                  },
                })}
                ariaLabel="State"
                defaultValue=""
                error={errors.state?.id || (errors.root?.serverError as FieldError | undefined)}
                float
                label="State"
                placeholder="State"
              />
            </div>
          )}

          <div className={classNames(isUSSelected && "col-6 col-lg-5")}>
            <StyledFloatingLabel
              {...register("zipCode", {
                required: isUSSelected ? "Zip code is required" : "Postcode is required",
              })}
              autoComplete="postal-code"
              errors={errors.zipCode || (errors.root?.serverError as FieldError | undefined)}
              isValidated={errors.zipCode || (errors.root?.serverError as FieldError | undefined)}
              ariaLabel={isUSSelected ? "Zip Code" : "Postcode"}
              label={isUSSelected ? "Zip Code" : "Postcode"}
              placeholder={isUSSelected ? "Zip Code" : "Postcode"}
              type="text"
              tabIndex={0}
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
}
