import { useQuery } from "@tanstack/react-query";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { OptionalReturnsCountQueryFilters } from ".";
import queryFactory from "../queryFactory";

export default (queryObject: OptionalReturnsCountQueryFilters) => {
  const partnerId = useCurrentPartner();

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(queryFactory.returnCountByCategoryFiltered({ partnerId, queryObject }));
  const categoryCounts =
    data?.map(({ count, id: value, name }) => ({
      name,
      value,
      count,
    })) ?? [];

  return {
    categoryCounts,
    isLoading,
    error,
  };
};
