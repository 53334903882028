import styled from "styled-components";
import { LightLabel } from "../../../components/typography";

export const Divider = styled.hr`
  border-top: 1px solid ${props => props.theme.colors.gray3};
  width: 100%;
  opactiy: 1;
`;

export const StyledLabel = styled(LightLabel)<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.gray3};
`;

export const Underline = styled.div`
  background-color: ${props => props.theme.colors.gray3};
  height: 6px;
  width: 100%;
  &.active {
    background-color: ${props => props.theme.colors.primaryBlue};
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;

export const TabsWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: -0.25rem;
  margin-bottom: 12px;
`;
