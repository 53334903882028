import React from "react";
import { SVGProps } from "./types";

export const CircleCheckIcon = ({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title id={titleId}>{accessibilityTitle}</title>
    {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}
    <path
      d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke={props.color ?? "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fillOpacity={0}
      fill="none"
    />
  </svg>
);
