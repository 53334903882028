import classNames from "classnames";
import React, { useRef, useState } from "react";
import { CircleCheckIcon } from "../../../../assets/ui-icons/CircleCheckIcon";
import { FileIcon } from "../../../../assets/ui-icons/FileIcon";
import { UserIcon } from "../../../../assets/ui-icons/UserIcon";
import useClickAway from "../../../../hooks/useClickAway";
import UserDetails from "../UserDetails";
import { DropdownButton, DropdownItem, DropdownMenu, LinksWrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function UserMenuDropdown({ className, ...props }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickAway(ref, () => setIsOpen(false));

  return (
    <div {...props} className={classNames("dropdown", isOpen && "show", className)} ref={ref}>
      <DropdownButton
        aria-expanded={isOpen}
        aria-haspopup="true"
        className={classNames("dropdown-toggle", isOpen && "open")}
        data-toggle="dropdown"
        onClick={() => setIsOpen(prev => !prev)}
        id="userMenuDropdown"
        role="button"
      >
        <UserIcon
          accessibilityTitle={`${isOpen ? "Close" : "Open"} profile dropdown`}
          className={classNames(isOpen ? "text-dark" : "text-primary")}
          height={36}
          titleId="ProfileDropdownIconTitle"
          width={36}
        />
      </DropdownButton>

      <DropdownMenu
        aria-labelledby="userMenuDropdown"
        className={classNames("dropdown-menu", isOpen && "show", "p-0")}
      >
        <LinksWrapper>
          <div className="d-flex flex-column py-3">
            <DropdownItem
              variant="gray"
              href="/claims"
              className="d-flex align-items-center gap-2 px-35 py-3"
            >
              <FileIcon accessibilityTitle="File icon" titleId="FileIcon" />
              Claims
            </DropdownItem>

            <DropdownItem
              variant="gray"
              href="/contact-details"
              className="d-flex align-items-center gap-2 px-35 py-3"
            >
              <UserIcon accessibilityTitle="User icon" titleId="UserIcon" />
              Contact details
            </DropdownItem>

            <DropdownItem
              variant="gray"
              href="/completed-returns"
              className="d-flex align-items-center gap-2 px-35 py-3"
            >
              <CircleCheckIcon accessibilityTitle="Circle check icon" titleId="CircleCheckIcon" />
              Completed returns
            </DropdownItem>
          </div>
        </LinksWrapper>

        <UserDetails className="px-35 pt-35 pb-425" />
      </DropdownMenu>
    </div>
  );
}
