import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useAuthState } from "../../context/auth";
import theme from "../../theme";
import { MenuItem, PartnerMenuItemNames } from "../../types/menuElements";
import { Divider, ItemWrapper, MenuWrapper } from "./styles";
import { SwitchAccountTab } from "./SwitchAccountTab";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { BigChevronRight } from "../../assets/icons/icons";
import { slugify } from "../../utils/stringUtils";
import { ActionIndicator } from "../MainHeader/Navigation/styles";

interface MobileMenuProps {
  headerHeight?: string;
  handleOnClick: () => void;
  menuItems: Array<MenuItem>;
  partnerId?: string;
  setShowSwitchAccount: (boolean) => void;
  potentialMatchCount?: number;
  showSwitchAccount: boolean;
}

// TODO: Move MenuMobileItem into a subfolder of this component
interface MobileMenuItemProps {
  color?: string;
  currentTab: boolean;
  icon: React.ReactNode;
  menuKey: string;
  onClick: () => void;
  showActionIndicator: boolean;
  text: string;
}

const MobileMenuItem = (props: MobileMenuItemProps) => {
  const { color, currentTab, icon, menuKey, onClick, showActionIndicator, text } = props;

  return (
    <ItemWrapper
      className={`p-35 align-items-center highlight ${currentTab ? "current" : ""}`}
      onClick={onClick}
      color={color || theme.colors.black}
      key={menuKey}
    >
      {!!icon && icon}
      {text}{" "}
      {text === PartnerMenuItemNames.POTENTIAL_MATCHES && showActionIndicator && (
        <ActionIndicator forMobile />
      )}
      {text !== "Logout" && (
        <BigChevronRight
          className="ms-auto"
          accessibilityTitle={`Go to ${text}`}
          ariaHidden={true}
          titleId={`MobileMenuChevron${menuKey}Title`}
        />
      )}
    </ItemWrapper>
  );
};

const MobileMenu = ({
  headerHeight,
  handleOnClick,
  menuItems,
  partnerId,
  potentialMatchCount,
  setShowSwitchAccount,
  showSwitchAccount,
}: MobileMenuProps) => {
  const location = useLocation();

  const { user } = useAuthState();
  const { isSearchModeOn, setIsSearchModeOn, setSearchTerm, setShowAllResults } = useContext(
    PartnerGlobalSearchContext,
  );

  let accountDividerUsed = false;
  return (
    <MenuWrapper role="menu" className="py-3 justify-content-center" headerHeight={headerHeight}>
      {showSwitchAccount && partnerId ? (
        <SwitchAccountTab
          handleOnClick={handleOnClick}
          onBack={() => {
            setShowSwitchAccount(false);
          }}
          partnerId={partnerId}
        />
      ) : (
        <>
          {menuItems.map(menuItem => {
            // Only show switch account if there is more than one partner they are assigned to
            if (menuItem.name !== "Switch account" || user?.partners?.length > 1) {
              const showDivider =
                !accountDividerUsed &&
                (menuItem.name === "Switch account" || menuItem.name === "Logout");
              accountDividerUsed = accountDividerUsed || showDivider;
              return (
                <React.Fragment key={slugify(menuItem.name)}>
                  {showDivider && <Divider className="m-35" />}
                  <MobileMenuItem
                    currentTab={menuItem.url === location.pathname}
                    menuKey={slugify(menuItem.name)}
                    showActionIndicator={
                      potentialMatchCount !== undefined && potentialMatchCount > 0
                    }
                    text={menuItem.name}
                    icon={<div className="me-3">{menuItem.icon}</div>}
                    color={menuItem.color}
                    onClick={() => {
                      if (menuItem.name === "Switch account") {
                        setShowSwitchAccount(true);
                      } else {
                        if (isSearchModeOn) {
                          setIsSearchModeOn(false);
                          setSearchTerm("");
                          setShowAllResults(false);
                        }
                        menuItem.onClick();
                        handleOnClick();
                      }
                    }}
                  />
                </React.Fragment>
              );
            }
          })}
        </>
      )}
    </MenuWrapper>
  );
};

export default MobileMenu;
