import { ChevronRight, MarkerPin } from "@assets/ui-icons";
import React, { useContext } from "react";
import Loader from "../../../../../components/Loader";
import { SubLocationSelectionContext } from "../../../../../context";
import theme from "../../../../../theme";
import { Text } from "../../../../../ui-components";
import { SubLocationItem } from "./styles";

export default function SubLocationList() {
  const { subLocations, isLoading, partnerSlug } = useContext(SubLocationSelectionContext);

  return (
    <div className="d-flex flex-column gap-25">
      {isLoading || subLocations.length === 0 ? (
        <Loader />
      ) : (
        subLocations.map((location, _index) => (
          <SubLocationItem
            key={location.name}
            className="d-flex flex-row gap-4 align-items-center p-35"
            data-testid={`location_${_index}`}
            href={`/lost/flow?p=${partnerSlug}&l=${location.slug}`}
          >
            <MarkerPin
              accessibilityTitle={`${location.name}-location-pin`}
              className="flex-shrink-0"
              titleId={`${location.name}-location-pin`}
              color={theme.ui.colors.gray600}
            />
            <div className="d-flex flex-column flex-grow-1">
              <Text as="p" fontWeight={700} className="m-0">
                {location.name}
              </Text>

              <Text
                lineHeight={1.5}
                color={theme.ui.colors.gray600}
                fontSize={theme.ui.fontSizes.sm}
                fontWeight={400}
              >
                {[
                  location.address.address1,
                  location.address.address2,
                  location.address.city,
                  [location.address.region_code, location.address.postal_code].join(" "),
                ]
                  .filter(Boolean)
                  .join(", ")}
              </Text>
            </div>

            <ChevronRight
              color={theme.ui.colors.gray600}
              accessibilityTitle="select-sub-location-icon"
              titleId="select-sub-location-icon"
              className="flex-shrink-0"
            />
          </SubLocationItem>
        ))
      )}
    </div>
  );
}
