import { format, parseISO } from "date-fns";

export function oneDayMore(date: Date) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
}

export const stringToDate = (date: string): Date => {
  const [year, month, day] = date.split("-");
  return new Date(new Date().setFullYear(Number(year), Number(month) - 1, Number(day)));
};
export const dateToISOString = (date: Date): string => {
  if (!date) return "";
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().split("T")[0];
};

export const dateToMMDDYYYY = (date: Date): string => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month < 10 ? "0" : ""}${month}/${day < 10 ? "0" : ""}${day}/${year}`;
};

export const dateToDDMMYYYY = (date: Date): string => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;
};

export const formatShortDate = (date: Date, isUS: boolean = true): string => {
  if (isUS) return dateToMMDDYYYY(date);
  return dateToDDMMYYYY(date);
};

export const formatLongDateInter = (date: Date) => {
  return format(date, "dd MMM yyyy");
};
export const formatLongDate = (date: Date) => {
  return format(date, "PP");
};

export const fullDateTime = (date: Date) => {
  return `${format(date, "yyyy-MM-dd, hh:mm:ss a")}`;
};

export const fullDateWithDay = (date: Date) => {
  return format(date, "PPPP");
};

export const convertToDateIgnoringTime = (date: string | Date): Date => {
  if (typeof date === "string") return parseISO(date.split("T")[0]);
  return parseISO(date.toISOString().split("T")[0]);
};

export const convertDateToUTC00 = (date: Date): Date => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  const UTCDate = `${year}-${month}-${day}T00:00:00.000Z`;
  return new Date(UTCDate);
};

const dateFormats = {
  short: {
    US: formatShortDate,
    UK: date => formatShortDate(date, false),
  },
  long: {
    US: formatLongDate,
    UK: formatLongDateInter,
  },
};

export function dateFormatter({
  date,
  isInternational = false,
  type = "short",
}: {
  date: Date;
  isInternational?: boolean;
  type?: "short" | "long";
}): string {
  const country = isInternational ? "UK" : "US";
  return dateFormats[type][country](date);
}
