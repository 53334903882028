import theme from "client/theme";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSendApproveUserMatch } from "../../../../../actions/userMatches";
import { useUserUpdateReturn } from "../../../../../actions/userReturns";
import { ClaimDetailsContext } from "../../../../../context/ClaimDetails";
import { isLgQuery } from "../../../../../helpers/mediaQuery";
import { ReturnModeEnum } from "../../../../../types";
import { Button, Checkbox } from "../../../../../ui-components";
import PickupAndShipping from "../../UserReturnProcess/PickupAndShipping";
import ConfirmRejectClaimModal from "./components/ConfirmRejectMatchedClaimModal";

type FormValues = {
  terms: boolean;
};

export default function MatchedActionBar(): JSX.Element {
  const isLg = isLgQuery();
  const history = useHistory();
  const { partnerRecoveryMethods, partnerDefaultRecoveryMethod, claim } =
    useContext(ClaimDetailsContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRejectMatchModal, setShowRejectMatchModal] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [selectedRecoveryMethod, setSelectedRecoveryMethod] = useState(
    partnerDefaultRecoveryMethod ?? ReturnModeEnum.PICKUP,
  );
  const {
    mutateAsync: updateUserReturn,
    data: updateUserReturnRes,
    isPending: updateUserReturnLoading,
    error: updateUserReturnError,
  } = useUserUpdateReturn();
  const [
    approveUserMatch,
    { data: approveUserMatchRes, loading: approveUserMatchLoading, error: approveUserMatchError },
  ] = useSendApproveUserMatch();

  const approveMatch = useCallback(() => {
    if (claim) {
      approveUserMatch(claim.match, true);
    }
  }, [claim]);

  useEffect(() => {
    if (
      approveUserMatchLoading ||
      approveUserMatchRes === null ||
      approveUserMatchRes === undefined
    )
      return;

    if (approveUserMatchError || claim === undefined || claim === null) {
      toast.error("There was an error approving the match. Please try again.");
    } else {
      updateUserReturn({
        mode: selectedRecoveryMethod,
        returnId: approveUserMatchRes?.return || "",
      }).then(() => {
        if (selectedRecoveryMethod === ReturnModeEnum.SHIPPING) {
          history.push(
            `/shippingcheckout?return_object=${
              approveUserMatchRes?.return || ""
            }&claim_id=${claim.id}`,
          );
        } else {
          history.go(0);
        }
      });
    }
  }, [approveUserMatchRes, approveUserMatchLoading, approveUserMatchError]);

  useEffect(() => {
    if (
      updateUserReturnLoading ||
      updateUserReturnRes === null ||
      updateUserReturnRes === undefined ||
      claim === null
    )
      return;

    if (updateUserReturnError) {
      toast.error("There was an error approving the match. Please try again.");
    } else {
      if (claim) {
        if (selectedRecoveryMethod === ReturnModeEnum.SHIPPING) {
          history.push(
            `/shippingcheckout?return_object=${
              approveUserMatchRes?.return || ""
            }&claim_id=${claim.id}`,
          );
        } else {
          history.go(0);
        }
      }
    }
  }, [claim, updateUserReturnRes, updateUserReturnLoading, updateUserReturnError]);

  const onSubmit = useCallback(() => {
    if (
      partnerRecoveryMethods?.includes(ReturnModeEnum.SHIPPING) &&
      partnerRecoveryMethods?.includes(ReturnModeEnum.PICKUP)
    ) {
      /* pickup and shipping */
      setShowModal(true);
    } else {
      /* pickup only */
      approveMatch();
    }
  }, [approveMatch, partnerRecoveryMethods]);

  return (
    <>
      <PickupAndShipping
        show={showModal}
        setShow={setShowModal}
        changeRecoveryMethod={setSelectedRecoveryMethod}
        selectedRecoveryMethod={selectedRecoveryMethod}
        recoveryMethods={partnerRecoveryMethods}
        handleApprove={approveMatch}
        approveLoading={approveUserMatchLoading}
      />
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex flex-column flex-lg-row mb-25 mb-lg-0 align-items-lg-center gap-lg-35"
        >
          <Checkbox
            {...register("terms", {
              required: {
                value: true,
                message: "Please review & check this box if you want to proceed",
              },
            })}
            error={errors?.terms?.message}
            squareSize="lg"
            label="I have reviewed the item described above and agree that the item matches my claim."
            id="matched-claim"
            name="terms"
            wrapperProps={{
              className: "mb-35 mb-lg-0 align-self-center",
              "data-testid": "matchApproval",
            }}
          />

          <Button
            aria-label="start return process"
            className="flex-shrink-0"
            size="2xl"
            fullWidth={!isLg}
            type="submit"
          >
            Start return process
          </Button>
        </form>

        <Button
          style={{ color: theme.ui.colors.error500 }}
          aria-label="Not my item"
          className="flex-shrink-0"
          variant="outline"
          onClick={() => setShowRejectMatchModal(prevState => !prevState)}
          size="2xl"
          fullWidth={!isLg}
        >
          This is not my item
        </Button>
      </>
      <ConfirmRejectClaimModal
        isOpened={showRejectMatchModal}
        setIsOpened={setShowRejectMatchModal}
      />
    </>
  );
}
