import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import imagePlaceholder from "../assets/imagePlaceholder.svg";
import imagePlaceholderPortrait from "../assets/imagePlaceholderPortrait.svg";
import imagePlaceholderPortrait3x4 from "../assets/imagePlaceholderPortrait3x4.svg";

export const NoPhotoPlaceholder = styled.div<{
  height?: string;
  width?: string;
}>`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  background-color: ${props => props.theme.colors.gray8};
  border-radius: ${props => props.theme.borderRadius.lg};
  color: ${props => props.theme.colors.gray3};
  height: ${props => props.height || props.theme.heights.uploadThumbLg};
  ${props => (props.width ? "width: " + props.width + ";" : "")}
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: 1.5;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.default};
`;

export const NoPhotoImagePlaceholder = styled.div<{
  aspectRatio?: string; // TODO: make this universal
  square?: boolean;
  width?: string;
  height?: string;
}>`
  border-radius: ${props => props.theme.borderRadius.lg};
  aspect-ratio: ${props => (props.square ? "1 / 1" : props.aspectRatio ?? "53/83")};
  width: ${props => props.width || "100%"} !important;
  max-width: ${props => props.width || "179px"} !important;
  height: ${props =>
    props.square && props.width ? props.width : props.height ? props.height : "auto"};
  background: url("${props =>
      props.square
        ? imagePlaceholder
        : props.aspectRatio
          ? imagePlaceholderPortrait3x4
          : imagePlaceholderPortrait}")
    no-repeat center;
  background-size: cover;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props => props.width || "219px"} !important;
  }
`;

export enum CarouselItemBackgroundSize {
  Contain = "contain",
  Cover = "cover",
  Auto = "auto",
}

export const StyledCarousel = styled(Carousel)<{
  $fullHeight?: boolean;
  $itemDimensions?: { height: number; width?: number };
  $useImg?: boolean;
  $backgroundSize?: CarouselItemBackgroundSize;
}>`
  ${props =>
    props.$itemDimensions?.width !== undefined &&
    `
  width: ${props.$itemDimensions.width}px; 
  margin: auto;
  `}

  .carousel-item {
    border-radius: ${props => props.theme.borderRadius.lg};
    height: ${props =>
      props.$itemDimensions?.height
        ? `${props.$itemDimensions.height}px`
        : props.$useImg
          ? "auto"
          : props.$fullHeight
            ? "400px"
            : "190px"};
    width: 100%;
    background-position: center;
    background-size: ${props => props.$backgroundSize ?? "cover"};
    background-repeat: no-repeat;
  }
  .carousel-indicators [data-bs-target] {
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 60px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.35));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.35));
  }
`;
