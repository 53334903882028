import { CircleCheckIcon } from "@assets/icons/icons";
import theme from "client/theme";
import { Link, Text } from "client/ui-components";
import pluralize from "pluralize";
import React from "react";
import { Divider } from "./styles";

interface Props {
  editedItemCount?: number;
  loading?: boolean;
  onApplyEdits?: () => void;
}

export function EditToolbar({ loading, editedItemCount = 0, onApplyEdits }: Props) {
  if (editedItemCount < 1) {
    return null;
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <Text fontWeight={theme.ui.fontWeights.bold}>
        {editedItemCount} {pluralize("items", editedItemCount)} edited{" "}
      </Text>
      <Divider />
      <Link aria-disabled={loading} onClick={loading ? undefined : onApplyEdits}>
        <span className="d-flex flex-row align-items-center gap-2">
          <CircleCheckIcon
            accessibilityTitle="Apply Edits"
            ariaHidden={true}
            titleId="ApplyEditsIcon"
            color={loading ? theme.ui.colors.gray600 : theme.ui.colors.primary500}
          />
          <Text
            color={loading ? theme.ui.colors.gray600 : theme.ui.colors.primary500}
            fontSize={theme.ui.fontSizes.sm}
            fontWeight={theme.ui.fontWeights.bold}
          >
            {loading
              ? pluralize("Applying edits", editedItemCount)
              : pluralize("Apply edits", editedItemCount)}
          </Text>
        </span>
      </Link>
    </div>
  );
}
