import styled from "styled-components";
import BoomerangSignEnd from "./boomerang-sign-end.svg";
import BoomerangSignStart from "./boomerang-sign-start.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 160px 190px;
  background-color: ${props => props.theme.ui.colors.primary300};
  background-image: url(${BoomerangSignStart}), url(${BoomerangSignEnd});
  background-position:
    left bottom,
    right top;
  background-repeat: no-repeat, no-repeat;
  background-size:
    139px 136px,
    124px 131px;
  transition:
    padding,
    background-size 200ms ease-in;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xs}) {
    padding-block: 180px 176px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-block: 160px 180px;
  }
  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-block: 124px;
    background-size:
      112px 127px,
      116px 113px;
  }
  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-block: 96px;
    background-size:
      149px 146px,
      134px 171px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${props => props.theme.ui.breakpoints.md};
`;
