import styled from "styled-components";

export const StyledLi = styled.li`
  height: ${props => props.theme.heights.li};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    &:hover {
      background-color: ${props => props.theme.colors.gray6};
    }
  }
`;
