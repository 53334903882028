import { usePagination, useSearchParam } from "client/hooks";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import useScrollToTop from "client/hooks/useScrollToTop";
import { ItemStatus, UnclaimedStatus } from "client/types";
import React from "react";
import { useParams } from "react-router-dom";
import { toUnclaimedStatus, UnclaimedSuggestionStatus } from "../helpers";

interface Props {
  count?: number;
  limit?: number;
}

export function useUnclaimedSuggestionFilter({ count = 0, limit }: Props) {
  const { partnerUserLocation } = usePartnerLocationStore();
  const { status } = useParams<{ status: UnclaimedSuggestionStatus }>();
  const unclaimedStatus = toUnclaimedStatus(status);
  const [category] = useSearchParam<string>("category", "ALL");
  const [dateAfter] = useSearchParam<string>("date_after", "ALL");
  const [dateBefore] = useSearchParam<string>("date_before", "ALL");
  const { data: categories } = useCategories();
  const { page, pageSize } = usePagination({ count, pageSize: limit });

  const filters = React.useMemo(
    () => ({
      limit,
      offset: (page - 1) * pageSize,
      status: [ItemStatus.UNCLAIMED],
      lost_location: partnerUserLocation?.id,
      unclaimed_status: [UnclaimedStatus.NULL],
      suggested_unclaimed_status: unclaimedStatus ? [unclaimedStatus] : undefined,
      category: categories?.find(({ name }) => name === category)?.id ?? [],
      collected_at_after: dateAfter == "ALL" ? "" : dateAfter,
      collected_at_before: dateBefore == "ALL" ? "" : dateBefore,
    }),
    [
      limit,
      page,
      pageSize,
      partnerUserLocation?.id,
      unclaimedStatus,
      categories,
      dateAfter,
      dateBefore,
      category,
    ],
  );

  useScrollToTop([page]);

  return { filters };
}
