import { PartnerReturnsCountByModeRequest, PartnerReturnsCountByModeResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";

export default (
  request: PartnerReturnsCountByModeRequest,
): Promise<PartnerReturnsCountByModeResponse> => {
  const { partnerId, filters = {} } = request;
  const query = objectToQueryString({ ...filters, mode: ["PICKUP", "SHIPPING"] });
  const queryString = query.toString();

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/returns/count-by-mode/?${queryString}`,
  });
};
