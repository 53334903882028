import { useQuery } from "@tanstack/react-query";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { OptionalReturnsCountQueryFilters } from ".";
import queryFactory from "../queryFactory";

export default (queryObject: OptionalReturnsCountQueryFilters) => {
  const partnerId = useCurrentPartner();

  const { data = [] } = useQuery(
    queryFactory.returnCountByStorageLocationFiltered({ partnerId, queryObject }),
  );
  const storageLocationCounts =
    data?.map(({ count, id: value, name }) => ({
      name,
      value,
      count,
    })) ?? [];
  return {
    storageLocationCounts,
  };
};
