import React from "react";
import { SvgProps } from "../../../../../assets/icons/icons";
import { colors } from "../../../../../theme/uiTheme";
import { Heading, Text } from "../../../../../ui-components";

interface BenefitsColumnProps {
  heading: string;
  image: string;
  imageAlt: string;
  list: {
    text: string;
    icon: {
      icon: React.FC<SvgProps>;
      accessibilityTitle: string;
      titleId: string;
    };
  }[];
}
const BenefitsColumn: React.FC<BenefitsColumnProps> = ({ heading, image, imageAlt, list }) => {
  return (
    <>
      <img className="mb-35 mb-md-45" src={image} alt={imageAlt} />
      <Heading variant="h4" as="h3" textAlign="center">
        {heading}
      </Heading>
      <ul className="list-unstyled m-0">
        {list.map((listItem, index) => {
          const Icon = listItem.icon.icon;
          return (
            <li className="text-start row mx-0 mt-35" key={index}>
              <Icon
                className="col-auto px-0 me-15"
                color={colors.primary500}
                accessibilityTitle={listItem.icon.accessibilityTitle}
                titleId={listItem.icon.titleId}
              />
              <Text className="col px-0 ms-2" as="span">
                {listItem.text}
              </Text>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default BenefitsColumn;
