import styled from "styled-components";
// import { Typography as Typ1 } from "../../../../components";
// import { Typography } from "../../../../components";
// import { Text } from "../../../../ui-components";
// import { Typography as Typ2 } from "../../../../ui-components";
import { default as Typography } from "../../../../ui-components/Typography";

// export const WrapT2 = styled(Typ2)`
//   display: flex;
// `;

// export const WrapT1 = styled(Typ1)`
//   display: flex;
// `;

export const InfoWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  width: calc(100vw - 40px);
`;

export const ResultWrapper = styled(Typography)<{
  backgroundColor: string;
}>`
  background-color: ${props => props.backgroundColor};
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &:hover {
    background-color: #eaf3fe;
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};
  &.move-up {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      position: relative;
      top: -24px;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const ReturnDetailsLink = styled.a`
  font-weight: ${props => props.theme.fontWeights.semiBold};
  line-height: 24px;
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.primaryBlue};
  font-family: Lato;
`;

export const StyledTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
`;
