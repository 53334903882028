import styled from "styled-components";

export const ExternalContainer = styled.div`
  min-height: 52px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTab = styled.button<{ isSelected: boolean }>`
  background-color: transparent;
  border: none;
  margin: 0;
  color: ${props =>
    props.isSelected ? props.theme.ui.colors.primary700 : props.theme.ui.colors.gray600};
  font-weight: 700;
  font-size: 16px;
  font-family: ${props => props.theme.ui.fontFamilies.default};
  text-align: center;
  padding: 14px 20px;
  text-wrap: nowrap;
  white-space: nowrap;
  line-height: 24px;
  cursor: pointer;
  margin-right: auto;
  outline-color: ${props => props.theme.ui.colors.primary500};

  box-shadow: ${props => (props.isSelected ? "inset 0 -4px 0 0 #0772e4" : "none")};

  transition: all 0.2s ease-in-out;
  margin: 0px;

  &:hover {
    color: ${props => props.theme.ui.colors.primary700};
  }

  &:focus-visible {
    outline: 1px ${props => props.theme.ui.colors.primary500} auto !important;
  }
`;

export const EndTab = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: ${props => props.theme.ui.fontFamilies.default};
  line-height: 20px;
  color: ${props => props.theme.ui.colors.gray600};
  margin-left: auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-wrap: nowrap;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
  }
`;
