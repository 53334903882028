import React from "react";
import { LocalStorageConsts } from "../../types/localStorage";
type User = {
  id: string;
  email: string;
  full_name: string;
  display_name: string;
  job_title: string;
  partners: string[];
  phone_number: string;
};

const user = localStorage.getItem(LocalStorageConsts.CURRENT_USER)
  ? JSON.parse(localStorage.getItem(LocalStorageConsts.CURRENT_USER) || "{}")
  : "";

type AuthContextType = {
  user: User;
  partner: User["partners"][0] | null;
  loading: boolean;
  errorMessage: string | null;
};

export const initialAuthState: AuthContextType = {
  user: user || {},
  partner: user && user.partners?.length > 0 ? user?.partners[0] : null,
  loading: false,
  errorMessage: null,
};

export const AuthContext = React.createContext<AuthContextType>(initialAuthState);
