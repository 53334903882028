import styled from "styled-components";

export const RadioInput = styled.input`
  width: 2rem;
  height: 2rem;

  &:checked {
    background: none;
    position: relative;

    &:after {
      height: 1rem;
      width: 1rem;
      content: "";
      background: ${props => props.theme.colors.primaryBlue};
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
`;
