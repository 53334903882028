import React, { useEffect } from "react";

import Button from "../../components/Button";
import { ButtonModes } from "../../components/Button/Button.types";
import StyledFloatingLabel from "../../components/StyledFloatingLabel";

import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledHeader, StyledSubHeader } from "./StyledComponentsAuthPages";

import { useCsrfToken, usePartnerResetPassword } from "../../actions/users";
import { PartnerRoutesEnum } from "../../components/Routes";

const ResetPassword = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    watch,
  } = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const password = watch("password");
  const { isSubmitted, isSubmitting } = useFormState({
    control,
  });

  let history = useHistory();

  // check for token_id
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const tokenId = parameters.get("token");
  const uid = parameters.get("uidb64");

  const [resetPassword, { data: resetRes }] = usePartnerResetPassword();
  const [useCsrf] = useCsrfToken();

  const onSubmit = async data => {
    let payload = {
      password: data.password,
      token: tokenId,
      uidb64: uid,
    };

    await useCsrf();
    resetPassword(payload);
  };

  useEffect(() => {
    if (!resetRes) {
      return;
    }

    if (!resetRes?.success) {
      toast.error("Error resetting password.");
      return;
    }

    toast.success(resetRes.message);
    history.push(PartnerRoutesEnum.SIGN_IN);
  }, [resetRes]);

  if (!tokenId) {
    history.push("/partner/forgot-password");
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className={`pt-5`}>
      <Row className="justify-content-center mt-4 mx-0">
        <Col xs="12" className="mt-5 mw520">
          <StyledSubHeader className="mt-5 mb-2">Forgot password?</StyledSubHeader>
          <StyledHeader className="mb-3 mt-1">Create new password</StyledHeader>
        </Col>
        <div className="w-100" />
        <Col xs="12" className="mw520">
          <Controller
            render={({ field }) => (
              <StyledFloatingLabel
                {...field}
                ariaLabel="New password"
                label="New password"
                placeholder="New password"
                className="mb-3"
                errors={errors.password}
                type="password"
                title="Enter an ID consisting of 4-8 hexadecimal digits"
                autoComplete="new-password"
                autoFocus
                isValidated={isSubmitted}
                onKeyUp={() => {
                  isSubmitted && trigger("passwordConfirm");
                }}
                required
              />
            )}
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "New password is required",
              minLength: {
                value: 8,
                message: "New password must be at least 8 characters",
              },
              validate: {
                hasNumber: value => /[\d]/.test(value) || "New password must contain a number",
                hasSpecialChar: value =>
                  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value) ||
                  "New password must contain a special character",
              },
            }}
          />
          <Controller
            render={({ field }) => (
              <StyledFloatingLabel
                {...field}
                errors={errors.passwordConfirm}
                ariaLabel="Confirm new password"
                label="Re-type new password"
                placeholder="Re-type new password"
                className={`mb-2`}
                type="password"
                isValidated={isSubmitted}
                required
              />
            )}
            name="passwordConfirm"
            control={control}
            defaultValue=""
            rules={{
              required: "Re-typing password is required",
              validate: {
                passwordMatch: value => value === password || "Passwords must match",
              },
            }}
          />
        </Col>
        <div className="w-100" />
        <Col xs="12" className="mw520">
          <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
        </Col>
        <div className="w-100" />
        <Col xs="12" className=" pt-3 mw520">
          <p className="note-color">
            Your new password must be at least 8 characters, contain a number and a special
            character ( ! # $ % ? )
          </p>
        </Col>
        <div className="w-100" />
        <Col xs="12" className="row mx-0 justify-content-end  mw520">
          <Button
            ariaLabel="Submit button"
            mode={ButtonModes.primary}
            className="fw700 mt-1 col-auto px-4"
            type="submit"
            disabled={isSubmitting}
            text="Save and login"
            loading={isSubmitting}
            loadingText="Saving"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ResetPassword;
