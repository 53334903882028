import classNames from "classnames";
import React from "react";
import { colors, fontWeights, ui } from "../../../../theme/uiTheme";
import { Heading, Text } from "../../../../ui-components";

interface CopySectionProps {
  body: string;
  heading: string;
  mediaSectionRight?: boolean;
  title: string;
  textAlignment?: "end" | "start";
}

const CopySection: React.FC<CopySectionProps> = ({
  body,
  heading,
  mediaSectionRight,
  title,
  textAlignment = "start",
}) => {
  return (
    <div
      className={classNames(
        { "pe-0 pe-md-45 pe-lg-7": mediaSectionRight },
        { "ps-0 ps-md-45 ps-lg-7": !mediaSectionRight },
      )}
    >
      <Text
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        className={classNames(textAlignment === "end" && "text-end text-md-start")}
        textAlign={textAlignment === "end" ? "right" : "left"}
      >
        {title}
      </Text>
      <Heading
        className={classNames(textAlignment === "end" && "text-end text-md-start", "mt-25 mt-md-3")}
        textAlign={textAlignment === "end" ? "right" : "left"}
        style={{ maxWidth: "768px" }}
        as="h3"
      >
        {heading}
      </Heading>
      <Text
        className={classNames(textAlignment === "end" && "text-end text-md-start", "mt-25 mt-md-4")}
        textAlign={textAlignment === "end" ? "right" : "left"}
        fontSize={ui.description.responsive.fontSize}
        color={colors.gray700}
        lineHeight={1.5}
        fontWeight={fontWeights.normal}
      >
        {body}
      </Text>
    </div>
  );
};

export default CopySection;
