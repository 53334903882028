import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    margin-top: ${props => props.theme.ui.heights.navBase};
    @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
      margin-top: ${props => props.theme.ui.heights.navLg};
    }
  }
`;
