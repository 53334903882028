import React, { useEffect, useState } from "react";

import { ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { LargeMatchIcon } from "../../../../assets/icons/icons";
import history from "../../../../context/history";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { Heading, Text } from "../../../../ui-components";
import { useMatchingStore } from "../matchingStore";
import SideOrTopPanel from "./SideOrTopPanel";
import { ContentWrapper, MainWrapper, MatchingCard, StyledContainer } from "./styles";

const FinishedReviewing = ({ matchCount }) => {
  const location = useLocation<{ returnPath: string }>();
  const [progress, setProgress] = useState(0);
  const nextClaimToMatch = useMatchingStore(state => state.nextClaimToMatch);

  const isLg = isLgQuery();

  useEffect(() => {
    const timer = window.setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(timer);
          window.setTimeout(() => {
            const { returnPath } = location?.state || {};
            if (returnPath) {
              history.replace(returnPath);
            } else {
              if (nextClaimToMatch) {
                history.replace(`/partner/matches/matching/?claimId=${nextClaimToMatch.id}`);
              } else {
                toast.error("Could not find next claim to match.");
              }
            }
          }, 1000);
          return 100;
        }
      });
    }, 20);
    return () => clearInterval(timer);
  }, []);

  return (
    <StyledContainer className={`${isLg ? "" : "row"} mx-0 d-flex`}>
      <SideOrTopPanel isFinishedReviewing />
      <MainWrapper>
        <MatchingCard>
          <ContentWrapper widerContent>
            <LargeMatchIcon />
            <Heading variant="h3" size="md">
              Nice! You've reviewed all{" "}
              {matchCount > 1 && matchCount < 51 ? matchCount : "the matches"}!
            </Heading>
            <Text textAlign="center" className="mt-25" data-testid="noMoreMatchesParagraph">
              Great job reviewing. There are no more strong matches for this claim. We'll keep
              searching behind the scenes. <br />
              <br />
              We're pulling up the next claim with potential matches.
            </Text>
            <ProgressBar now={progress} style={{ marginTop: 20 }} />
          </ContentWrapper>
        </MatchingCard>
      </MainWrapper>
    </StyledContainer>
  );
};

export default FinishedReviewing;
