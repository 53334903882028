import React from "react";
import {
  AwaitingPayment,
  InTransit,
  Packaged,
  PickupReady,
  ReadyToPack,
} from "../../assets/icons/icons";
import { ResultType } from "../../pages/PartnerGlobalSearch";
import { ReturnObject } from "../../pages/PartnerGlobalSearch/components/ResultsSection";
import theme from "../../theme";
import {
  ClaimStatus,
  ItemStatus,
  PartnerClaimStatusEnum,
  PartnerItemStatusEnum,
  PartnerItemUnclaimedStatusEnum,
  PartnerReturn,
  PartnerReturnPickupStatusStringEnum,
  PartnerReturnShippingStatusStringEnum,
  ReturnModeEnum,
  ReturnStatus,
  ReturnStatusStrings,
  ShipmentStatus,
  UnclaimedStatus,
} from "../../types";
import Text from "../Text";
import { Badge } from "./styles";

type StatusBadgeProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  objectType: ResultType;
  objectStatus: ClaimStatus | ItemStatus | ReturnStatus | UnclaimedStatus;
  returnObject?: ReturnObject | PartnerReturn;
  showReturnsIcon?: boolean;
  style?: React.CSSProperties;
};

export default function StatusBadge({
  objectStatus,
  objectType,
  returnObject,
  showReturnsIcon,
  className,
  style,
}: StatusBadgeProps) {
  let borderColor: string = theme.ui.colors.gray500;
  let backgroundColor: string = theme.ui.colors.gray300;
  let textColor: string = theme.ui.colors.primary700;
  let label: string = objectStatus ?? "n/a";
  let returnsIcon = <></>;

  const getReturnStatusIcon = (returnStatus: string) => {
    switch (returnStatus) {
      case PartnerReturnShippingStatusStringEnum.AWAITING_PAYMENT:
        return (
          <AwaitingPayment
            accessibilityTitle={`${returnStatus} icon`}
            titleId="AwaitingPaymentIcon"
          />
        );
      case PartnerReturnPickupStatusStringEnum.READY_FOR_PICKUP:
        return (
          <PickupReady
            accessibilityTitle={`${returnStatus} icon`}
            titleId="PersonCheckMarkIcon"
            color={theme.colors.white}
          />
        );
      case PartnerReturnShippingStatusStringEnum.READY_TO_PACK:
        return (
          <ReadyToPack accessibilityTitle={`${returnStatus} icon`} titleId="ReadyToPackIcon" />
        );
      case PartnerReturnShippingStatusStringEnum.PACKAGED:
        return <Packaged accessibilityTitle={`${returnStatus} icon`} titleId="PackagedIcon" />;
      case PartnerReturnShippingStatusStringEnum.IN_TRANSIT:
        return <InTransit accessibilityTitle={`${returnStatus} icon`} titleId="InTransitIcon" />;
      default:
        return <></>;
    }
  };

  if (objectType === ResultType.CLAIMS) {
    switch (objectStatus) {
      case ClaimStatus.NEW:
        label = PartnerClaimStatusEnum.NEW;
        borderColor = theme.ui.colors.primary400;
        backgroundColor = theme.ui.colors.primary400;
        break;
      case ClaimStatus.CANCELED:
        label = PartnerClaimStatusEnum.CANCELED;
        break;
      case ClaimStatus.EXPIRED:
        label = PartnerClaimStatusEnum.EXPIRED;
        break;
      case ClaimStatus.COMPLETED:
        if (returnObject?.status === ReturnStatus.PENDING) {
          borderColor = theme.ui.colors.yellow500;
          backgroundColor = theme.ui.colors.yellow500;
          label = PartnerClaimStatusEnum.ITEM_IN_RETURN;
        } else {
          label = PartnerClaimStatusEnum.RESOLVED;
        }
        break;
      case ClaimStatus.HAS_MATCHES:
        borderColor = theme.ui.colors.primary500;
        backgroundColor = theme.ui.colors.primary500;
        textColor = theme.ui.colors.white;
        label = PartnerClaimStatusEnum.HAS_MATCHES;
        break;
      case ClaimStatus.NO_MATCHES:
        borderColor = theme.ui.colors.error500;
        backgroundColor = theme.ui.colors.error500;
        textColor = theme.ui.colors.white;
        label = PartnerClaimStatusEnum.NO_MATCHES;
        break;
      case ClaimStatus.MATCHED:
        borderColor = theme.ui.colors.yellow500;
        backgroundColor = theme.ui.colors.yellow500;
        label = PartnerClaimStatusEnum.PENDING_APPROVAL;
    }
  } else if (objectType === ResultType.ITEMS) {
    switch (objectStatus) {
      case ItemStatus.NO_MATCHES:
        borderColor = theme.ui.colors.error500;
        backgroundColor = theme.ui.colors.error500;
        textColor = theme.ui.colors.white;
        label = PartnerItemStatusEnum.NO_MATCHES;
        break;
      case ItemStatus.HAS_MATCHES:
        borderColor = theme.ui.colors.primary500;
        backgroundColor = theme.ui.colors.primary500;
        textColor = theme.ui.colors.white;
        label = PartnerItemStatusEnum.HAS_MATCHES;
        break;
      case ItemStatus.MATCHED:
        borderColor = theme.ui.colors.yellow500;
        backgroundColor = theme.ui.colors.yellow500;
        label = PartnerItemStatusEnum.PENDING_APPROVAL;
        break;
      case ItemStatus.COMPLETED:
        if (returnObject?.status === ReturnStatus.PENDING) {
          borderColor = theme.ui.colors.yellow500;
          backgroundColor = theme.ui.colors.yellow500;
          label = PartnerItemStatusEnum.IN_RETURN;
        } else {
          label = PartnerItemStatusEnum.RETURNED;
        }
        break;
      case ItemStatus.UNCLAIMED:
        label = PartnerItemStatusEnum.UNCLAIMED;
        break;
      default:
        label = PartnerItemUnclaimedStatusEnum[objectStatus];
    }
  } else if (objectType === ResultType.RETURNS) {
    returnObject = returnObject as ReturnObject;
    switch (returnObject.status) {
      case ReturnStatus.CANCELED:
        label = ReturnStatusStrings.CANCELED;
        break;
      case ReturnStatus.COMPLETED:
        label = PartnerReturnPickupStatusStringEnum.RETURNED;
        break;
      case ReturnStatus.PENDING:
        if (returnObject.mode === ReturnModeEnum.PICKUP) {
          borderColor = theme.ui.colors.secondary500;
          backgroundColor = theme.ui.colors.secondary500;
          textColor = theme.ui.colors.white;
          label = PartnerReturnPickupStatusStringEnum.READY_FOR_PICKUP;
        } else if (
          returnObject.shipment &&
          (returnObject.shipment.transactions || [])?.find(
            transaction => transaction.status === "SUCCESS",
          )
        ) {
          switch (returnObject.shipment.status) {
            case ShipmentStatus.PACKAGED:
              borderColor = theme.ui.colors.yellow500;
              backgroundColor = theme.ui.colors.yellow500;
              label = PartnerReturnShippingStatusStringEnum.PACKAGED;
              break;
            case ShipmentStatus.SHIPPED:
              borderColor = theme.ui.colors.yellow500;
              backgroundColor = theme.ui.colors.yellow500;
              label = PartnerReturnShippingStatusStringEnum.IN_TRANSIT;
              break;
            default:
              borderColor = theme.ui.colors.secondary500;
              backgroundColor = theme.ui.colors.secondary500;
              textColor = theme.ui.colors.white;
              label = PartnerReturnShippingStatusStringEnum.READY_TO_PACK;
          }
        } else {
          borderColor = theme.ui.colors.error500;
          backgroundColor = theme.ui.colors.error500;
          textColor = theme.ui.colors.white;
          label = PartnerReturnShippingStatusStringEnum.AWAITING_PAYMENT;
        }
        if (showReturnsIcon) {
          returnsIcon = getReturnStatusIcon(label);
        }
    }
  }

  return (
    <Badge
      className={className}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      style={style}
    >
      {returnsIcon}
      <Text as="span" fontWeight={700} color={textColor}>
        {label}
      </Text>
    </Badge>
  );
}
