import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabInfo } from "../components/FilterTabs/types";

type PaginatedTabInfo = {
  setPage: (page: number) => void;
  setTabAndPage: (tab: number, page: number) => void;
  isPageOutOfRange: (page: number, tabInfo: TabInfo[]) => boolean;
  currentTab: number;
  currentPage: number;
  itemsPerPage: number;
  firstPageIndex: number;
};

const usePaginatedTabs = (tab: number, page: number): PaginatedTabInfo => {
  // useState only initializes with tab / page when the state is first created.
  // On subsequent renders, the currentTab / currentPage values are used.
  const [currentTab, setCurrentTab] = useState(tab);
  const [currentPage, setCurrentPage] = useState(page);
  const history = useHistory();

  // Accept default value as argument if/when we need this to be configurable.
  const [itemsPerPage] = useState<number>(12);
  const firstPageIndex = (currentPage - 1) * itemsPerPage;

  const isPageOutOfRange = (page: number, tabInfo: TabInfo[]): boolean => {
    const tabCount = Number(tabInfo[currentTab]?.count);
    const lastPage = Math.ceil(tabCount / itemsPerPage);

    return (tabCount > 0 && page > lastPage) || page < 1;
  };

  useEffect(() => {
    const queryPage = history.location.search.match(/page=(\d+)/);
    const page = Number(queryPage?.[1]) || 1;
    setCurrentPage(page);
  }, [history.location.search]);

  const result: PaginatedTabInfo = {
    setPage: page => setCurrentPage(page),
    setTabAndPage: (tab, page) => {
      setCurrentTab(tab);
      setCurrentPage(page);
    },
    isPageOutOfRange,
    currentTab,
    currentPage,
    itemsPerPage,
    firstPageIndex,
  };

  return result;
};

export default usePaginatedTabs;
