interface IHeaders {
  [key: string]: string;
}

const getCookieValue = name =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const getCommonHeaders = (formBody?: boolean, ignoreContentType?: boolean): IHeaders => {
  let headers = {};

  headers = {
    Accept: "application/json, text/plain, */*",
    "cache-control": "no-cache",
  };

  if (!ignoreContentType) {
    headers["Content-Type"] = formBody ? "application/x-www-form-urlencoded" : "application/json";
  }

  if (getCookieValue("csrftoken")) {
    headers["X-CSRFToken"] = getCookieValue("csrftoken");
  }

  return headers;
};

export { getCommonHeaders };
