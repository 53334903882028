import styled from "styled-components";

interface FlexProps {
  flex?: number;
}

export const BlankTableWrapper = styled.div`
  background: ${props => props.theme.colors.white} !important;
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 555px;
`;

export const IconMessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
`;

export const HeaderRowWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;

export const SeeAll = styled.div`
  color: ${props => props.theme.colors.primaryBlue};
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 1.5;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledCol = styled.div<FlexProps>`
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 500;
  display: ${props => (props.flex ? "flex" : "flex")};
  flex-grow: ${props => props.flex};
`;

export const StyledRow = styled.div<FlexProps>`
  border-bottom: 1px solid ${props => props.theme.colors.gray6};
  &.no-border {
    border-bottom: none;
  }
  &.hover-bg:hover {
    background-color: ${props => props.theme.colors.lightBlue}80;
  }
  display: flex;
`;

export const TableWrapper = styled.div`
  height: fit-content;
  background: ${props => props.theme.colors.white} !important;
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
`;
