import React from "react";

interface Props {
  condition?: any;
  children: React.ReactNode;
}

export function If({ condition, children }: Props): any {
  if (!condition) return null;
  return children;
}
