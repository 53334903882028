export const passwordValidationRules = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password must be at least 8 characters",
  },
  maxLength: {
    value: 20,
    message: "Password must be at most 20 characters",
  },
};
