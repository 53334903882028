import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useGetPartnerClaimFromId,
  useGetPartnerClaims,
  useMultipleGetPartnerClaimsFromId,
} from "../../../../actions/partnerClaims";
import {
  useGetPartnerItemFromId,
  useMultipleGetPartnerItemsFromId,
} from "../../../../actions/partnerItems";
import { useGetMatches, useSendDeclineMatch } from "../../../../actions/partnerMatches";
import MatchingCard from "../../../../components/Cards/MatchingCard";
import Loader from "../../../../components/Loader";
import { isLgQuery, isMdQuery } from "../../../../helpers/mediaQuery";
import {
  ClaimStatus,
  MatchOrder,
  MatchStatus,
  PartnerClaim,
  PartnerItem,
  ReturnModeEnum,
} from "../../../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../../../utils/bodyStyle";
import useCurrentPartner from "../../../../utils/useCurrentPartner";
import useQueryString from "../../../../utils/useQueryString";
import { useMatchingStore } from "../matchingStore";
import AllCaughtUp from "./AllCaughtUp";
import FinishedReviewing from "./FinishedReviewing";
import LookingForMatches from "./LookingForMatches";
import ConfirmationModal from "./Modals/ConfirmationModal";
import ShippingModal from "./Modals/ShippingModal";
import SuccessModal from "./Modals/SuccessModal";
import NoMatchesFound from "./NoMatchesFound";
import SideOrTopPanel from "./SideOrTopPanel";
import { StyledContainer } from "./styles";

const Matching: React.FC = () => {
  const claimIdFromUrl = useQueryString().get("claimId");
  const itemIdFromUrl = useQueryString().get("itemId");
  const matchingClaimToItems = !!claimIdFromUrl;
  const isLookingForMatches = useQueryString().has("lookingForMatches");
  const [isFinishedReviewing, setFinishedReviewing] = useState<boolean>(
    useQueryString().has("finishedReviewing"),
  );

  const { partnerUserLocation } = usePartnerLocationStore();
  const partnerId = useCurrentPartner();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const isLg = isLgQuery();
  const isMd = isMdQuery();

  const resetMatchingState = useMatchingStore(state => state.resetMatchingState);
  const currentCandidate = useMatchingStore(state => state.currentCandidate);
  const currentCandidateIndex = useMatchingStore(state => state.currentCandidateIndex);
  const setCurrentCandidateIndex = useMatchingStore(state => state.setCurrentCandidateIndex);
  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);
  const setArtifactToMatch = useMatchingStore(state => state.setArtifactToMatch);
  const candidates = useMatchingStore(state => state.candidates);
  const setCandidates = useMatchingStore(state => state.setCandidates);
  const setShowConfirmModal = useMatchingStore(state => state.setShowConfirmModal);
  const showAllCaughtUp = useMatchingStore(state => state.showAllCaughtUp);
  const setShowAllCaughtUp = useMatchingStore(state => state.setShowAllCaughtUp);
  const nextClaimToMatch = useMatchingStore(state => state.nextClaimToMatch);
  const setNextClaimToMatch = useMatchingStore(state => state.setNextClaimToMatch);

  const [getSingleItem, { data: singleItem }] = useGetPartnerItemFromId();
  const [getSingleClaim, { data: singleClaim }] = useGetPartnerClaimFromId();
  const [getMatches, { data: matches }] = useGetMatches();
  const [getItemList, { data: itemList }] = useMultipleGetPartnerItemsFromId();
  const [getClaimList, { data: claimList }] = useMultipleGetPartnerClaimsFromId();
  const declineMatch = useSendDeclineMatch(partnerId);
  const [getClaims, { data: claims, loading: isClaimsLoading }] = useGetPartnerClaims();
  const location = useLocation<{ returnPath: string }>();

  // prefetch recovery methods for use in child modals
  const { partner } = usePartner(partnerId);
  const isShippingEnabled = partner?.return_modes.includes(ReturnModeEnum.SHIPPING);

  useEffect(() => {
    if (isMd) {
      mountBodyGray6();
      return function cleanup() {
        unmountBodyGray6();
      };
    }
  }, [isMd]);

  // Step 1: Fetch the artifact to match and its matches
  useEffect(() => {
    if (isLookingForMatches) return;

    resetMatchingState();
    setFinishedReviewing(false);
    // TODO: we can probably pull showAllCaughtUp out of the store and manage it the same as we do isFinishedReviewing
    // ... e.g. call this here: setShowAllCaughtUp(false);

    let artifactId = claimIdFromUrl!;
    if (matchingClaimToItems) {
      getSingleClaim({ partnerId, claimId: artifactId });
    } else {
      artifactId = itemIdFromUrl!;
      getSingleItem({ partnerId, itemId: artifactId });
    }
    getMatches({
      partnerId,
      filters: {
        status: MatchStatus.PENDING,
        ordering: [MatchOrder.MATCH_SCORE_DESCENDING],
        [matchingClaimToItems ? "claim" : "item"]: artifactId,
      },
    });
  }, [claimIdFromUrl, itemIdFromUrl, isLookingForMatches]);

  // Step 2a: Save the artifact to match to the store
  useEffect(() => {
    matchingClaimToItems && singleClaim && setArtifactToMatch(singleClaim);
    !matchingClaimToItems && singleItem && setArtifactToMatch(singleItem);
  }, [singleItem, singleClaim]);

  // Step 2b: Fetch the matches' items or claims
  useEffect(() => {
    if (matches && matches.count > 0) {
      if (matchingClaimToItems) {
        getItemList(
          matches.results.map(match => {
            return {
              partnerId: partnerId,
              itemId: match.item,
            };
          }),
        );
      } else {
        getClaimList(
          matches.results.map(match => {
            return {
              partnerId: partnerId,
              claimId: match.claim,
            };
          }),
        );
      }
    }
  }, [matches]);

  // Step 3: Save the matches' items or claims as "candidates" to the store
  useEffect(() => {
    if (itemList) {
      setCandidates(itemList as PartnerItem[]);
      setCurrentCandidateIndex(0);
    } else if (claimList) {
      setCandidates(claimList as PartnerClaim[]);
      setCurrentCandidateIndex(0);
    }
  }, [itemList, claimList]);

  // This useEffect gets hit afer the user is done matching
  // Here, we check if there are any more claims to match
  useEffect(() => {
    if (!claims || isClaimsLoading) {
      return;
    }

    const filteredClaims = claims.results.filter(claim => claim.id !== artifactToMatch?.id);

    const nextClaimToMatch = filteredClaims[0];

    if (nextClaimToMatch) {
      setNextClaimToMatch(nextClaimToMatch);
      setFinishedReviewing(true);
    } else {
      // No more claims to match (from Potential Matches or Overview)
      setShowAllCaughtUp(true);
    }
  }, [claims]);

  // If both are placeholders, use the square form instead of portrait
  const useSquarePlaceholders =
    artifactToMatch && candidates.length > 0
      ? artifactToMatch.images.length === 0 && currentCandidate?.images.length === 0
      : false;

  if (!nextClaimToMatch && showAllCaughtUp) {
    return <AllCaughtUp />;
  } else if (isLookingForMatches) {
    return <LookingForMatches />;
  } else if (matches?.count === 0) {
    return <NoMatchesFound />;
  } else if (isFinishedReviewing) {
    return <FinishedReviewing matchCount={matches && matches.count} />;
  } else if (
    !artifactToMatch ||
    !partnerLocations ||
    !matches ||
    (matchingClaimToItems && !itemList) ||
    (!matchingClaimToItems && !claimList) ||
    !currentCandidate ||
    !candidates.length
  )
    return <Loader />;

  return (
    <StyledContainer
      className={`${isLg ? "" : "row"} mx-0 ${artifactToMatch ? "d-flex" : "d-block"}`}
    >
      <SideOrTopPanel
        isLookingForMatches={isLookingForMatches}
        isFinishedReviewing={isFinishedReviewing}
        matchingClaimToItems={matchingClaimToItems}
      />
      {
        <MatchingCard
          useSquarePlaceholders={useSquarePlaceholders}
          claim={(matchingClaimToItems ? artifactToMatch : currentCandidate) as PartnerClaim}
          item={(matchingClaimToItems ? currentCandidate : artifactToMatch) as PartnerItem}
          claimLocation={partnerLocations.find(location => {
            const claim = artifactToMatch;
            return location.id === claim.lost_location;
          })}
          itemLocation={partnerLocations.find(location => {
            return location.id === currentCandidate.lost_location;
          })}
          matchingClaimToItems={matchingClaimToItems}
          onAcceptMatch={() => {
            setShowConfirmModal(true);
          }}
          onDeclineMatch={() => {
            declineMatch.mutate({
              partnerId,
              matchId: matches.results[currentCandidateIndex].id,
            });
            matches.results.splice(currentCandidateIndex, 1);
            candidates.splice(currentCandidateIndex, 1);
            if (candidates.length === 0) {
              // This gets hit when the last match is declined

              if (location?.state?.returnPath) {
                // If the user came from Claim details (has returnPath),
                // show the AllCaughtUp screen and then the proper CTAs shown there
                setShowAllCaughtUp(true);
              } else {
                // Go to the server to see if we can find a new claim to match
                getClaims({
                  partnerId: partnerId,
                  filters: {
                    limit: 2,
                    status: [ClaimStatus.HAS_MATCHES],
                    lost_location: partnerUserLocation.id,
                  },
                });
              }
            } else {
              setCandidates(candidates);
              setCurrentCandidateIndex(Math.min(currentCandidateIndex, candidates.length - 1));
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}
        />
      }
      <ConfirmationModal
        matchingClaimToItems={matchingClaimToItems}
        matchId={matches.results[currentCandidateIndex]?.id}
        isShippingEnabled={isShippingEnabled}
      />
      <ShippingModal
        matchingClaimToItems={matchingClaimToItems}
        matchId={matches.results[currentCandidateIndex]?.id}
      />
      <SuccessModal
        matchingClaimToItems={matchingClaimToItems}
        isShippingEnabled={isShippingEnabled}
      />
    </StyledContainer>
  );
};

export default Matching;
