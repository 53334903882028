import styled from "styled-components";
import Heading from "../../../../../client/ui-components/Heading";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0px;
  background-color: ${({ theme }) => theme.ui.colors.primary300};

  @media (max-width: 768px) {
    padding: 64px 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  padding: 16px;
  padding-top: 20px;
  align-items: stretch;
  flex-direction: column;
  width: min(100%, 400px);
  gap: 16px;

  & > * {
    width: 100% !important;
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.ui.colors.primary700};
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 476px;
  margin: 16px auto 0 auto;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledHeading = styled(Heading)`
  max-width: 768px;
  @media (max-width: 768px) {
    line-height: 32px !important;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    line-height: 40px !important;
  }
`;
