import { fetchApiData } from "client/utils/fetchApiData";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ArrowRight, CircleCheck } from "../../../assets/icons/icons";
import { useAuthState } from "../../../context/auth";
import { isMdQuery } from "../../../helpers/mediaQuery";
import { colors } from "../../../theme/uiTheme";
import { Heading, Text } from "../../../ui-components/";
import UnauthorizedPageLayout from "../../../ui-components/Layouts/UserPage/index";
import { getCommonHeaders } from "../../../utils/headers";
import { emailNotificationInterval } from "./content";
import { BackgroundWrapper, ContentWrapper, StyledButton } from "./styles";

enum EmailInterval {
  BIWEEKLY = "biweekly",
  WEEKLY = "weekly",
  NONE = "none",
}

function isIntervalValid(interval: EmailInterval | null): interval is EmailInterval {
  return Object.keys(EmailInterval).includes((interval ?? "").toUpperCase());
}

const ClaimEmailIntervalPage = () => {
  const { id } = useParams<{ id: string }>();
  const isMd = isMdQuery();
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthState();
  const searchParams = new URLSearchParams(location.search);
  const interval = searchParams.get("interval") as EmailInterval | null;

  useEffect(() => {
    if (!isIntervalValid(interval)) {
      history.push(`/claim/${id}`);
    } else {
      fetchApiData({
        url: `/api/user/claims/${id}/notifications/`,
        options: {
          method: "PATCH",
          headers: getCommonHeaders(),
          body: JSON.stringify({
            notification_interval: interval.toUpperCase(),
          }),
        },
      });
    }
  }, [interval]);

  return (
    <UnauthorizedPageLayout>
      <BackgroundWrapper className="px-35">
        <ContentWrapper>
          <CircleCheck
            color={colors.white}
            className="mx-auto mb-3"
            backgroundColor={colors.success500}
            height={48}
            width={48}
            accessibilityTitle="Circle Check Icon"
            titleId="circleCheckIcon"
          />
          <Heading variant="h2" as="h1" textAlign="center" className="mb-25">
            {emailNotificationInterval.heading}
          </Heading>

          <Text textAlign="center" fontWeight={400}>
            {interval && emailNotificationInterval[interval.toUpperCase()]}
          </Text>

          {user.id ? (
            <StyledButton
              aria-label="Sign in button"
              className="mt-3 mx-auto"
              fullWidth
              size={isMd ? "xl" : "2xl"}
              variant="secondary-outline"
              href="/claims"
            >
              Go to claims
              <ArrowRight
                className="ms-2"
                accessibilityTitle="Go to claims"
                ariaHidden={true}
                titleId="GoToClaimsArrowRightTitle"
              />
            </StyledButton>
          ) : (
            <StyledButton
              size={isMd ? "xl" : "2xl"}
              aria-label="Sign in button"
              className="mt-3 mx-auto"
              fullWidth
              href="/sign-in"
            >
              Sign in
            </StyledButton>
          )}
        </ContentWrapper>
      </BackgroundWrapper>
    </UnauthorizedPageLayout>
  );
};

export default ClaimEmailIntervalPage;
