import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { BigChevronRight, JobTitleIcon, LogoutIcon } from "../../assets/icons/icons";
import { DropdownMenuWrapper, IconButton, StyledSpan, StyledUserIcon } from "./styles";

import usePartner from "client/hooks/data/partner/usePartner";
import { useAuthDispatch, useAuthState } from "../../context/auth";
import { logout } from "../../context/auth/actions";
import theme from "../../theme";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { DropdownAlignment } from "./types";

interface DropdownProps {
  onSwitchAccount?: () => void;
  partnerId: string;
}

const PartnerProfileDropdown = (props: DropdownProps) => {
  const dispatch = useAuthDispatch();
  const { onSwitchAccount, partnerId } = props;
  const [dropdownOpen, toggleDropdown] = useState(false);
  const dropdownRef = useRef<any>();
  const { user } = useAuthState();
  const { partner } = usePartner(partnerId);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleDropdownClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  const handleDropdownClick = e => {
    if (e.target.classList.contains("dropdown-open")) {
      toggleDropdown(false);
      return;
    }
    if (dropdownRef?.current?.contains(e.target)) {
      toggleDropdown(true);
      return;
    }
    // outside click
    toggleDropdown(false);
  };

  return (
    <div data-testid="partnerProfileDropdown" className="dropdown" ref={dropdownRef}>
      <div style={{ cursor: "pointer" }}>
        <StyledUserIcon
          width={36}
          height={36}
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === KEYBOARD_KEY.ENTER) {
              handleDropdownClick(e);
            }
          }}
          className={dropdownOpen && "dropdown-open"}
          accessibilityTitle={`${dropdownOpen ? "Close" : "Open"} profile dropdown`}
          titleId="ProfileDropdownIconTitle"
        />
      </div>
      <DropdownMenuWrapper
        dropdownAlignment={DropdownAlignment.BOTTOM_END}
        className={classNames("dropdown-menu mt-2 p-3", { show: dropdownOpen })}
        width="375px"
      >
        <li className="px-0">
          <StyledSpan fontSize={theme.fontSizes.sm}>Logged in to: </StyledSpan>
          <StyledSpan bold>{partner?.name}</StyledSpan>
        </li>
        {user?.partners?.length > 1 && (
          <li className="px-0 pt-3">
            <IconButton
              className="btn no-decorate p-0 d-flex align-items-center w-100"
              data-testid="switchAccountButton"
              color={theme.colors.primaryBlue}
              onClick={onSwitchAccount}
            >
              <JobTitleIcon
                className="my-2 me-2"
                color={theme.colors.primaryBlue}
                accessibilityTitle="Switch account"
                ariaHidden={true}
                titleId="ProfileDropdownSwitchAccountIconTitle"
              />
              Switch account
              <BigChevronRight
                className="ms-auto"
                color={theme.colors.gray3}
                accessibilityTitle="Switch account"
                ariaHidden={true}
                titleId="ProfileDropdownSwitchAccountChevronRightTitle"
              />
            </IconButton>
          </li>
        )}
        <li>
          <hr className="dropdown-divider my-3" />
        </li>
        <li className="px-0">
          <StyledSpan bold>{user.full_name}</StyledSpan>
          <StyledSpan>{user.email}</StyledSpan>
        </li>
        <li className="px-0 pt-3">
          <IconButton
            data-testid="logoutButton"
            className="btn no-decorate p-0 d-flex align-items-center"
            color={theme.colors.red}
            onClick={() => logout(dispatch)}
          >
            <LogoutIcon
              className="my-2 me-2"
              color={theme.colors.red}
              accessibilityTitle="Logout"
              ariaHidden={true}
              titleId="ProfileDropdownLogoutIconTitle"
              data-testid="logoutIcon"
            />
            Logout
          </IconButton>
        </li>
      </DropdownMenuWrapper>
    </div>
  );
};

export default PartnerProfileDropdown;
