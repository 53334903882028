import { useMutation, useQueryClient } from "@tanstack/react-query";
import createClaim from "./fetchFunctions/createClaim";
import queryFactory from "./queryFactory";

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createClaim,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryFactory.claims() });
    },
  });
};
