import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import emptyBoxImg from "../../../assets/empty_box.svg";
import { getArchiveReturnsTabs } from "../../../components/FilterTabs/constants";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination/index";
import { PartnerRoutesEnum } from "../../../components/Routes";
import Table from "../../../components/Table";
import { isLgQuery } from "../../../helpers/mediaQuery";
import usePaginatedTabs from "../../../hooks/usePaginatedTabs";
import { ResultType } from "../../../pages/PartnerGlobalSearch";
import { ReturnObject } from "../../../pages/PartnerGlobalSearch/components/ResultsSection";
import {
  PartnerReturnFilters,
  PartnerReturnsCountByModeResponse,
  ReturnModeEnum,
  ReturnStatus,
} from "../../../types";
import StatusBadge from "../../../ui-components/StatusBadge";
import { Tabs } from "../../../ui-components/Tabs";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import getUrlString from "../../../utils/getUrlString";
import { getCategoryName } from "../../../utils/mappers";
import useQueryString from "../../../utils/useQueryString";

import BlankMessage from "client/components/BlankMessage";
import ReturnsCard from "client/components/Cards/ReturnsCard";
import { ReturnsColumns } from "client/components/Table/TableColumns";
import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import DefaultError from "client/ui-components/DefaultError";
import ContactDetailsCell from "./components/ContactDetailsCell";
import ItemDetailsCell from "./components/ItemDetailsCell";
import { CustomReturn, populateItems } from "./helpers";
import { TabsWrapper, Wrapper } from "./styles";

interface ReturnsProps {
  className?: string;
  count?: number;
  limit?: number;
  onReturnArtifact?: () => void;
  partnerId: string;
  title?: string;
  showPagination?: boolean;
}

const NoReturnsMessage = () => (
  <BlankMessage alt="Boomerang empty box" image={String(emptyBoxImg)} title={"No returns yet"}>
    There are no returns for this location.
    <br />
    Once there are, they will show up here.
  </BlankMessage>
);

const ReturnsArchive = (props: ReturnsProps) => {
  const { limit, partnerId, showPagination = true } = props;
  const history = useHistory();
  const query = useQueryString();
  const initialQuery = useRef<string | null>(null);
  const { pathname } = useLocation();
  const isLg = isLgQuery();
  const queryRecoveryMethod = query.get("recoveryMethod");
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [returns, setReturns] = useState<Array<CustomReturn> | null>(null);
  const { partnerUserLocation } = usePartnerLocationStore();
  const [pendingReturnsCount, setPendingReturnsCount] =
    useState<PartnerReturnsCountByModeResponse | null>(null);
  const { partner } = usePartner(partnerId);
  const returnModes = partner?.return_modes;

  const tabs = useMemo(() => {
    return getArchiveReturnsTabs(returnModes || []);
  }, [returnModes]);

  const tabIndex = useMemo<number>(() => {
    if (queryRecoveryMethod === "all" || !queryRecoveryMethod) {
      return 0;
    }
    const recovery_method = tabs.slice(1).find(({ recoveryMethods }) => {
      return recoveryMethods.includes(queryRecoveryMethod as ReturnModeEnum);
    });
    if (recovery_method) {
      return tabs.indexOf(recovery_method) || 0;
    }

    return 0;
  }, [tabs, queryRecoveryMethod]);

  const {
    setPage,
    setTabAndPage,
    isPageOutOfRange,
    currentTab,
    currentPage,
    itemsPerPage,
    firstPageIndex,
  } = usePaginatedTabs(tabIndex === -1 ? 0 : tabIndex, Number(query.get("page")) || 1);

  const tabInfo = useMemo(() => {
    return tabs.map((tab, i) => {
      const isAllActiveTab = i === 0;

      const tabInfo = {
        name: tab.name,
        count: 0,
        url: {
          search: getUrlString({
            recoveryMethod: isAllActiveTab ? "all" : tabs[i].recoveryMethods,
            status: tabs[i].statuses,
          }),
        },
        isSelected: i === currentTab,
        tabNumber: i,
      };

      if (!pendingReturnsCount) {
        return tabInfo;
      }
      const totalCount = (pendingReturnsCount || [])?.reduce((acc, cur) => {
        acc += cur.count;
        return acc;
      }, 0);

      if (totalCount === 0) {
        return tabInfo;
      } else {
        tabInfo.count = totalCount;
      }
      return tabInfo;
    });
  }, [tabs, pendingReturnsCount]);

  const returnFilters = useMemo<PartnerReturnFilters>(() => {
    return {
      limit: limit || itemsPerPage,
      offset: firstPageIndex,
      ...(queryRecoveryMethod === null || queryRecoveryMethod === "all"
        ? {}
        : {
            mode: queryRecoveryMethod.split(",") as ReturnModeEnum[],
          }),
      status: ReturnStatus.COMPLETED,
      ...(partnerUserLocation.id !== "" ? { lost_location: [partnerUserLocation.id] } : {}),
    };
  }, [limit, itemsPerPage, firstPageIndex, queryRecoveryMethod, partnerUserLocation.id]);

  const categories = useCategories();

  const rows = useMemo(() => {
    if (categories.data === undefined || returns == null) return [];

    return (returns || []).map((returnItem, i) => {
      /*
       * We have to convert the item.return, which is a PartnerReturn type, to a ReturnObject here.
       * The important difference is that PartnerReturn's shipment and recovery_methods are string IDs
       */
      const returnObject: ReturnObject = {
        ...returnItem.return,
        match: "",
        status: returnItem.return?.status ?? "-",
        id: returnItem.return?.id ?? "",
        shipment: returnItem.shipment!,
        mode: returnItem.return?.mode,
      };

      return [
        <ItemDetailsCell
          category={getCategoryName(categories.data, returnItem.item?.category ?? "")}
          item={returnItem.item}
          index={i}
        />,
        <ContactDetailsCell user={returnItem.claim?.user} />,
        <StatusBadge
          objectType={ResultType.RETURNS}
          objectStatus={returnObject.status as ReturnStatus}
          returnObject={returnObject}
          showReturnsIcon
        />,
      ];
    });
  }, [categories.data, returns]);

  useEffect(() => {
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    if (initialQuery.current === null) {
      if (query.has("page")) {
        const pageFromQuery = Number(query.get("page"));
        setPage(pageFromQuery);
      }
      initialQuery.current = query.toString();
    }
  }, []);

  useEffect(() => {
    if (tabIndex >= 0 && tabs.length > 0) {
      setTabAndPage(tabIndex, currentPage);
    }
  }, [tabIndex, tabs.length]);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);

    populateItems({ partnerId, filters: returnFilters })
      .then(fetchedData => {
        if (fetchedData == null) return;

        setReturns(fetchedData[0]);

        setPendingReturnsCount(fetchedData[1]);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [returnFilters]);

  useEffect(() => {
    if (!returns || !query.has("page")) return;
    const pageFromQuery = Number(query.get("page"));

    if (isPageOutOfRange(pageFromQuery, tabInfo)) {
      setPage(1);
    }
  }, [currentTab, itemsPerPage, returns, pendingReturnsCount]);

  useEffect(() => {
    if (initialQuery.current !== null) {
      query.delete("page");
      query.append("page", String(currentPage));
      history.push(`${pathname}?${query}`);
    }
  }, [currentPage]);

  if ((!limit && isLoading) || returns === null) {
    return <Loader />;
  }

  if (hasError) {
    return <DefaultError />;
  }

  return (
    <>
      <TabsWrapper>
        <Tabs
          tabs={tabInfo}
          endAlignedUrl={""}
          endAlignedText=""
          selectedTabIndex={currentTab}
          hasEndLink={false}
          style={{ maxWidth: 1072 }}
        />
      </TabsWrapper>
      <Wrapper>
        <Pagination
          currentPage={currentPage}
          totalCount={Number(tabInfo[currentTab]?.count)}
          pageSize={itemsPerPage}
          onPageChange={page => {
            setPage(page);
          }}
          showPagination={showPagination && tabInfo && tabInfo[currentTab].count > 0}
        >
          {tabInfo && tabInfo[currentTab].count < 1 ? (
            <NoReturnsMessage />
          ) : isLg ? (
            <Table
              artifactIds={(returns || []).map(({ match }) =>
                match !== null ? match.id : undefined,
              )}
              columns={ReturnsColumns}
              className={limit ? "pb-25" : ""}
              rows={rows}
              justifyEnd
              seeAllLink={limit !== undefined ? PartnerRoutesEnum.RETURNS : undefined}
              onRowClick={async id => {
                if (id) {
                  await history.push(`/partner/match/${id}/return/`);
                }
              }}
              tableLoading={isLoading}
              totalCount={returns.length}
              title={limit !== undefined ? "Returns" : undefined}
            />
          ) : isLoading ? (
            <Loader />
          ) : (
            returns.length > 0 &&
            returns.map(({ item, match }, i) => {
              if (!item) return;

              return (
                <ReturnsCard
                  category={categories.data ? getCategoryName(categories.data, item.category) : ""}
                  item={item}
                  key={i}
                  onClick={() => {
                    if (match?.id) {
                      history.push(`/partner/match/${match.id}/return/`);
                    }
                  }}
                />
              );
            })
          )}
        </Pagination>
      </Wrapper>
    </>
  );
};

export default ReturnsArchive;
