import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const { Group } = Form;

const StyledGroup = styled(Group)`
.form-select { 
  color: ${props => props.theme.colors.gray1};
  font-weight: 500;
}
  position: relative;
  .form__input:not(.is-invalid, .is-valid) {
  border: 1px solid ${props => props.theme.colors.gray4};
  }
  .form__input {
    position: relative;
    top: 0;
    left: 0;
    width:100%;
    border-radius:  ${props => props.theme.borderRadius.sm};
    outline: none;
    padding-left: 54px;
    &:hover {
      border-color:  ${props => props.theme.colors.primaryBlue};
    }
    &[data-selected="0"] {
      color:  ${props => props.theme.colors.gray3};
    }
    /* Change border when input focus*/

    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.primaryBlue} !important;
      outline-style: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.primaryBlue};
    }
    &:not(:placeholder-shown){
      border-color: ${props => props.theme.colors.gray3};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.gray3}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg { 
      color: ${props => props.theme.colors.primaryBlue} !important;
      fill: ${props => props.theme.colors.primaryBlue} !important;
    }
  }
  &.form-floating > .form-control.is-invalid {
    border-color:${props => props.theme.colors.red};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
    color:${props => props.theme.colors.red};
    ~ label, ~ svg { 
      color:${props => props.theme.colors.red};
    }
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.red} !important;
      outline-style: none;
      box-shadow: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.red} !important;
    }
    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.red};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.danger}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.red} !important;
      fill: ${props => props.theme.colors.red} !important;
    }
  }

  &.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: ${props => (props.value ? "1.625rem" : 0)};
    padding-bottom: ${props => (props.value ? "0.625rem" : 0)};
  }
  .form__label {
    position: absolute;
    top: 0px;
    left: 37px;
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.default};
    font-weight: ${props => props.theme.fontWeights.default};
    color:  ${props => props.theme.colors.gray3};
    cursor: text;
    pointer-events:none;
  }
} 

svg {
    position: absolute;
    left: 21px;
    top: 31px; // Not happy with this. Error messages expanding height of the input led me to this hardcoded value
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.default};
    cursor: text;
    color:  ${props => props.theme.colors.gray3};
}
`;

function StyledSelect(props) {
  const {
    name,
    icon,
    className,
    errors,
    isValidated,
    value,
    onChange,
    options,
    label,
    required,
    tabIndex,
    dataTestId,
  } = props;

  return (
    <StyledGroup value={value} className={className + " form-floating"} controlId={`form${name}`}>
      <Form.Select
        className={`form__input form-control ${
          !isValidated ? "" : errors?.types ? "is-invalid" : "is-valid"
        }`}
        aria-label={label}
        onChange={onChange}
        required={required}
        value={value}
        data-selected={value ? value : 0}
        tabIndex={tabIndex}
        data-testid={dataTestId}
      >
        <option className="form__label" value="">
          {label}
        </option>
        {options?.map((option, index) => {
          return (
            <option aria-label={option.name} value={option.id} key={index}>
              {option.name}
            </option>
          );
        })}
      </Form.Select>
      {icon}
      {value && (
        <label htmlFor={`form${name}`} className="form__label">
          {label}
        </label>
      )}
      {errors?.types &&
        Object.entries(errors?.types).map(([type, message]) => (
          <div className="text-danger invalid-feedback d-block fw500" key={type}>
            {message}
          </div>
        ))}
    </StyledGroup>
  );
}
export default StyledSelect;
