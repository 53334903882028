import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import StyledFloatingLabel from "../../components/StyledFloatingLabel";

import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";

import { useCsrfToken, usePartnerRequestPasswordReset } from "../../actions/users";

import { Envelope } from "@styled-icons/boxicons-regular";
import { StyledHeader } from "./StyledComponentsAuthPages";

import reset from "../../assets/reset.svg";
import Button from "../../components/Button";
import { ButtonModes } from "../../components/Button/Button.types";

const ForgotPassword = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { isSubmitted, isSubmitting } = useFormState({
    control,
  });

  const [isLinkSent, setIsLinkSent] = useState(false);

  const [resetPasswordRequest, { data: passwordRes, loading: isSubmittingPasswordReset }] =
    usePartnerRequestPasswordReset();
  const [useCsrf] = useCsrfToken();

  const onSubmit = async data => {
    let variables = {
      email: data.email,
    };

    await useCsrf();
    resetPasswordRequest(variables);
  };

  useEffect(() => {
    if (!passwordRes) {
      return;
    }

    if (!passwordRes?.success) {
      toast.error("Error sending password reset.");
      return;
    }

    setIsLinkSent(true);
  }, [passwordRes]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className={`d-grid pt-5`}>
      {!isLinkSent ? (
        <Row className="justify-content-center mt-4 mx-0 align-items-center">
          <Col xs="12" className="mt-5 mw520">
            <StyledHeader>Forgot Password?</StyledHeader>
          </Col>
          <div className="w-100" />
          <Col xs="12" className="mw520">
            <p style={{ color: "#666666" }}>
              Please type your business email that is associated with your Boomerang account.
            </p>
          </Col>
          <div className="w-100 mt-1" />
          <Col xs="12" className="mw520" data-testid="forgotEmailSection">
            <Controller
              render={({ field }) => (
                <StyledFloatingLabel
                  {...field}
                  ariaLabel="Business Email"
                  data-testid="businessEmailField"
                  label="Business Email"
                  placeholder="Business Email"
                  errors={errors.email}
                  className="mb-3"
                  type="email"
                  icon={<Envelope size="20" />}
                  isValidated={isSubmitted}
                  required
                  autoComplete="email"
                  autoFocus
                />
              )}
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
          </Col>
          <div className="w-100" />
          <Col xs="12" className="mw520 d-flex justify-content-end">
            <Button
              ariaLabel="Submit button"
              className="fw700 mt-1 col-auto px-4"
              data-testid="sendResetLink"
              disabled={isSubmitting || isSubmittingPasswordReset}
              loading={isSubmitting || isSubmittingPasswordReset}
              loadingText="Sending link"
              mode={ButtonModes.primary}
              text="Send reset link"
              type="submit"
            />
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center mt-4 mx-0 align-items-center">
          <Col xs="12" className="mt-3 mw520">
            <img
              className="mt-5 pt-4"
              src={reset}
              style={{ maxWidth: "238px" }}
              alt="reset password paper plane svg"
            />
          </Col>
          <div className="w-100" />
          <Col xs="12" className="mt-3 mw520">
            <StyledHeader>Reset Link Sent</StyledHeader>
          </Col>
          <div className="w-100" />
          <Col xs="12" className="mw520">
            <p style={{ color: "#666666" }}>
              Please check your email. If we have an account associated with this email, you should
              receive instructions on resetting your password.
            </p>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default ForgotPassword;
