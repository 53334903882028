import styled from "styled-components";

export const Slide = styled.li<{
  slideWidth: number;
}>`
  z-index: 10;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => props.slideWidth}px;
  margin-inline-start: 12px;

  &:first-child {
    margin-inline-start: 0;
  }

  > * {
    width: 100%;
    height: 100%;
  }
`;

export const Carousel = styled.ul`
  appearance: none;
  padding: 2px;
  margin: 0;
  position: relative;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.not-scrollable {
    cursor: inherit;
    overflow: inherit;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2px 0;
    justify-content: center;
  }
`;
