import React from "react";
import { PartnerLayout } from "../../ui-components";

const Pricing = () => (
  <PartnerLayout>
    <h1>pricing</h1>
  </PartnerLayout>
);

export default Pricing;
