import { ArrowNarrowLeft } from "@assets/ui-icons";
import React from "react";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Heading } from "../../../../ui-components";
import { Container, GoBackButton, HeaderWrapper } from "./styles";

export default function Header() {
  const isLg = isLgQuery();

  return (
    <HeaderWrapper>
      <GoBackButton className="btn btn-link text-white px-0" href="/claims">
        <ArrowNarrowLeft
          accessibilityTitle="go-back"
          titleId="go-back"
          className="flex-shrink-0"
          data-testid="goBackArrow"
        />
      </GoBackButton>

      <Container>
        <Heading variant="h3" size={isLg ? "lg" : "md"} color={theme.ui.colors.white}>
          Claim details
        </Heading>
      </Container>
    </HeaderWrapper>
  );
}
