import React from "react";
import theme from "../../theme";

import { ArrowLeft } from "../../assets/icons/icons";
import history from "../../context/history";
import { Divider, ItemWrapper, StatusBox } from "./styles";

import { useAuthDispatch, useAuthState } from "../../context/auth";
import { updateCurrentPartner } from "../../context/auth/actions";

import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import usePartners from "../../hooks/data/partner/usePartners";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import Loader from "../Loader";

interface AccountListItemProps {
  className?: string;
  fontSize?: string;
  highlightColor?: string;
  isCurrent?: boolean;
  itemKey: number;
  onClick: () => void;
  name: string;
}

export const AccountListItem = (props: AccountListItemProps) => {
  const { className, fontSize, highlightColor, isCurrent, itemKey, onClick, name } = props;
  return (
    <ItemWrapper
      aria-label={name}
      className={`p-35 align-items-center justify-content-between ${
        highlightColor ? "highlight" : ""
      } ${className}`}
      fontSize={fontSize}
      highlightColor={highlightColor}
      key={itemKey}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === KEYBOARD_KEY.ENTER) {
          onClick();
        }
      }}
      tabIndex={0}
    >
      {name}
      <StatusBox className={`py-1 px-2 ${isCurrent ? "current" : ""}`}>
        {isCurrent ? "Current" : "Select"}
      </StatusBox>
    </ItemWrapper>
  );
};

interface SwitchAccountTabProps {
  handleOnClick: () => void;
  onBack: () => void;
  partnerId: string;
}

export const SwitchAccountTab = (props: SwitchAccountTabProps) => {
  const { handleOnClick, onBack, partnerId } = props;
  const { user } = useAuthState();

  const dispatch = useAuthDispatch();
  const { resetPartnerUserLocation } = usePartnerLocationStore();

  const { partners: allPartners, isLoading } = usePartners(user.partners);

  return (
    <>
      <ItemWrapper
        className="p-35 pb-2 align-items-center"
        color={theme.colors.primaryBlue}
        onClick={onBack}
      >
        <ArrowLeft
          className="me-25"
          color={theme.colors.primaryBlue}
          accessibilityTitle="Go back"
          titleId="ArrowLeftSwitchAccountTitle"
        />
        {"Go back"}
      </ItemWrapper>
      <Divider className="m-35" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allPartners.map((partner, i) => {
            if (!partner) {
              return;
            }

            return (
              <AccountListItem
                key={i}
                isCurrent={partner.id === partnerId}
                itemKey={i}
                onClick={() => {
                  if (partner.id !== partnerId) {
                    updateCurrentPartner(dispatch, partner.id);
                    resetPartnerUserLocation();
                  }
                  history.push("/partner/overview");
                  handleOnClick();
                }}
                name={partner.name}
              />
            );
          })}
        </>
      )}
    </>
  );
};
