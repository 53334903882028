import { PartnerItemUnclaimedStatusEnum, UnclaimedStatus } from "client/types";
import { Select, SelectItem } from "client/ui-components/Select";
import React from "react";

interface Props {
  selected?: UnclaimedStatus;
  allowedStatuses?: UnclaimedStatus[];
  onChange?: (value: UnclaimedStatus) => void;
}

export function StatusPicker({ selected, allowedStatuses = [], onChange }: Props) {
  return (
    <Select
      key={Math.random()}
      onValueChange={onChange}
      placeholder="Select option"
      theme="secondary"
      value={selected ?? ""}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        width: "100%",
        minWidth: 62,
      }}
    >
      {Object.keys(UnclaimedStatus)
        .filter(key => allowedStatuses.includes(key as UnclaimedStatus))
        .sort(a => (a === "NULL" ? -1 : 1))
        .map(key => (
          <SelectItem value={key} key={key}>
            {PartnerItemUnclaimedStatusEnum[key]}
          </SelectItem>
        ))}
    </Select>
  );
}
