import React from "react";

import { NoMatchesFoundIcon } from "../../../../assets/icons/icons";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { Button, Heading, Text } from "../../../../ui-components";
import useQueryString from "../../../../utils/useQueryString";
import SideOrTopPanel from "./SideOrTopPanel";
import { ContentWrapper, MainWrapper, MatchingCard, StyledContainer } from "./styles";

const NoMatchesFound = () => {
  const isLg = isLgQuery();
  const claimIdFromUrl = useQueryString().get("claimId");

  return (
    <StyledContainer className={`${isLg ? "" : "row"} mx-0 d-flex`}>
      <SideOrTopPanel isLookingForMatches={false} />
      <MainWrapper>
        <MatchingCard>
          <ContentWrapper>
            <NoMatchesFoundIcon />
            <Heading variant="h3" size="md">
              No matches found
            </Heading>
            <Text textAlign="center" className="mt-15">
              At the moment, we can't find any potential matches for your claim. Please check back
              later as new items are added to your inventory.
            </Text>
            <Button
              aria-label="claim details button"
              variant="outline"
              href={`/partner/claim/${claimIdFromUrl}?back=/partner/claims`}
            >
              Go to Claim details
            </Button>
          </ContentWrapper>
        </MatchingCard>
      </MainWrapper>
    </StyledContainer>
  );
};

export default NoMatchesFound;
