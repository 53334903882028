import { LocationRequest, LocationSearchResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import getQueryString from "client/utils/getQueryString";

export default (request: LocationRequest): Promise<LocationSearchResponse> => {
  const queryString = request ? getQueryString(request) : "";
  return fetchApiData({
    url: `/api/user/locations/${queryString}`,
  });
};
