import {
  CreatePartnerClaimRequest,
  GetPartnerClaimsFromIdRequest,
  GetPartnerClaimsRequest,
  ImageSearchResponse,
  PartnerClaim,
  PartnerClaimsSearchResponse,
} from "../types";
import fetchData from "../utils/fetchData";

import { ApiHandlerRes, MultiApiHandlerRes, useApiHandler, useMultiApiHandler } from ".";
import getQueryString from "../utils/getQueryString";
import { getCommonHeaders } from "../utils/headers";

const getPartnerClaims = (
  partnerClaimsReq: GetPartnerClaimsRequest,
): Promise<PartnerClaimsSearchResponse | null> => {
  const queryString = partnerClaimsReq.filters ? getQueryString(partnerClaimsReq.filters) : "";
  return fetchData(`/api/partner/partners/${partnerClaimsReq.partnerId}/claims/${queryString}`);
};

export const getPartnerClaimFromId = (
  partnerClaimIdReq: GetPartnerClaimsFromIdRequest,
): Promise<PartnerClaim | null> => {
  return fetchData(
    `/api/partner/partners/${partnerClaimIdReq.partnerId}/claims/${partnerClaimIdReq.claimId}/?include_return=true`,
  );
};

const createPartnerClaim = async (
  partnerClaimReq: CreatePartnerClaimRequest,
): Promise<PartnerClaim | null> => {
  return fetchData(`/api/partner/partners/${partnerClaimReq.partnerId}/claims/`, {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(partnerClaimReq.claim),
  });
};

export const useCreatePartnerClaim = (): ApiHandlerRes<
  CreatePartnerClaimRequest,
  PartnerClaim | null
> => useApiHandler(createPartnerClaim);

export const useGetPartnerClaims = (): ApiHandlerRes<
  GetPartnerClaimsRequest,
  PartnerClaimsSearchResponse | null
> => useApiHandler(getPartnerClaims);

export const useGetPartnerClaimFromId = (): ApiHandlerRes<
  GetPartnerClaimsFromIdRequest,
  PartnerClaim | null
> => useApiHandler(getPartnerClaimFromId);

export const useMultipleGetPartnerClaimsFromId = (): MultiApiHandlerRes<
  GetPartnerClaimsFromIdRequest,
  PartnerClaim | null
> => useMultiApiHandler(getPartnerClaimFromId);

// Queries for Images
const getPartnerImagesFromClaimId = (
  partnerId: string,
  claimId: string,
): Promise<ImageSearchResponse | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/claims/${claimId}/images/`);
};

export const useGetPartnerImagesFromClaimId = (): ApiHandlerRes<
  string,
  ImageSearchResponse | null
> => useApiHandler(getPartnerImagesFromClaimId);
