import { usePagination, useSearchParam } from "client/hooks";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import useScrollToTop from "client/hooks/useScrollToTop";
import { ItemStatus, UnclaimedStatus } from "client/types";
import { useMemo } from "react";

export const ITEMS_PER_PAGE = 4;

export function useUnclaimedPartnerItemsFilter({ count }: { count: number }) {
  const { data: categories } = useCategories();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { page, pageSize } = usePagination({ count, pageSize: ITEMS_PER_PAGE });
  const [category] = useSearchParam<string>("category", "ALL");
  const [dateAfter] = useSearchParam<string>("date_after", "ALL");
  const [dateBefore] = useSearchParam<string>("date_before", "ALL");
  const filters = useMemo(
    () => ({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * pageSize,
      status: [ItemStatus.UNCLAIMED],
      unclaimed_status: [UnclaimedStatus.NULL],
      lost_location: partnerUserLocation.id,
      suggested_unclaimed_status: [UnclaimedStatus.NULL],
      category: categories?.find(({ name }) => name === category)?.id ?? [],
      collected_at_after: dateAfter == "ALL" ? "" : dateAfter,
      collected_at_before: dateBefore == "ALL" ? "" : dateBefore,
    }),
    [page, pageSize, categories, dateAfter, dateBefore, category, partnerUserLocation?.id],
  );

  useScrollToTop([page]);

  return filters;
}
