import { ArrowNarrowRight } from "@assets/ui-icons";
import UserPageLayout from "client/ui-components/Layouts/UserPage";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import { isMdQuery } from "../../helpers/mediaQuery";
import { Heading, Typography } from "../../ui-components";
import { CoBrandedPageContext } from "./context";
import { Content, CustomButton, Wrapper } from "./styles";

export default function CoBrandedWelcomePage() {
  const isMd = isMdQuery();
  const { location } = useHistory();
  const { partner, urlPartnerSlug, urlLocationSlug, isLoading, partnerLocations } =
    useContext(CoBrandedPageContext);

  const isMultiLocation = partnerLocations && partnerLocations.length > 1;
  const locationSlug = isMultiLocation ? urlLocationSlug : partnerLocations?.[0].slug;
  const selectedLocationTitle = partnerLocations?.find(loc => loc.slug === locationSlug)?.name;
  const multilocationTitle = selectedLocationTitle ? ` - ${selectedLocationTitle}` : "";

  const partnerLocationTitle = isMultiLocation
    ? `${partner?.name}${multilocationTitle}`
    : partner?.name;

  const href = (
    locationSlug
      ? `/lost/flow?p=${urlPartnerSlug}&l=${locationSlug}`
      : `${location.pathname}/select-location`
  ).replace("//", "/");

  return (
    <UserPageLayout coBrandNavbarLogoUrl={partner !== null ? partner.logo : undefined} showFooter>
      <Wrapper withCityView>
        {isLoading || !partner ? (
          <Loader className="mx-auto" />
        ) : (
          <Content className="d-flex flex-column align-content-md-center gap-3">
            <Heading variant="h2" as="h1" textAlign={isMd ? "center" : "start"}>
              Lost something at {partnerLocationTitle}?
            </Heading>

            <Typography
              as="p"
              className="m-0"
              fontSize={isMd ? "1.25rem" : "1rem"}
              fontWeight={400}
              textAlign={isMd ? "center" : "start"}
            >
              Don't worry!{" "}
              <Typography as="span" fontWeight={700} fontSize={isMd ? "1.25rem" : "1rem"}>
                {partnerLocationTitle}
              </Typography>{" "}
              has partnered with Boomerang to make lost & found quick and easy. Submitting a claim
              takes less than 60 seconds.
            </Typography>

            <CustomButton
              aria-label="report-lost-item"
              data-testid="reportLostItemButton"
              href={href}
              size="2xl"
              icon={
                <ArrowNarrowRight
                  accessibilityTitle="report-lost-item-icon"
                  titleId="report-lost-item"
                />
              }
              iconPosition="end"
            >
              Report a lost item
            </CustomButton>
          </Content>
        )}
      </Wrapper>
    </UserPageLayout>
  );
}
