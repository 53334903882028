import styled from "styled-components";
import cityBanner from "../../../assets/ui-icons/CityBanner/city-banner.svg";

export const Row = styled.div`
  min-height: inherit;
`;

export const Col = styled.div`
  margin: 0;

  .sign-in-form {
    flex: 1;
    padding-inline: 20px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-inline: 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-inline: 40px;

      min-height: inherit;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      padding-inline: 160px;
    }
  }

  &.left-panel {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      background-image: url(${cityBanner});
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto 180px;
    }
  }

  &.right-panel {
    padding-inline: 6rem;
    background-color: ${props => props.theme.ui.colors.primary300};
  }
`;
