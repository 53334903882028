import { ArrowRightLight } from "@assets/icons/icons";
import React from "react";
import { TwoColumnAsymmetricalLayout } from "../";
import { UserRoutesEnum } from "../../../../components/Routes";
import { fontSizes } from "../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../ui-components";
import { MediaWrapper } from "../../..//Partner/components/Features/styles";
import heroSectionImg from "./match-found-with-user.png";
import { Content, Wrapper } from "./styles";

export default function Hero() {
  return (
    <Wrapper>
      <Content>
        <TwoColumnAsymmetricalLayout
          mediaSectionRight
          copySection={
            <div className="d-md-flex flex-md-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
              <div className="text-content">
                <Heading variant="h1" className="mb-3 mb-md-4" data-testid="lostSomethingHeader">
                  Lost something?
                </Heading>
                <Text
                  fontSize={{ base: fontSizes.base, xs: fontSizes.lg }}
                  fontWeight="normal"
                  lineHeight={{ base: 1.5 }}
                  className="body mb-425 mb-md-45"
                >
                  Don't worry! Boomerang's technology will search for a match to help you get your
                  lost item back. It's easy to report your lost item, so don't hesitate to submit a
                  claim.
                </Text>
                <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-275">
                  <Button
                    aria-label="Report lost item"
                    href="/lost"
                    size="2xl"
                    data-testid="reportLostItemButton"
                  >
                    Report a lost item
                  </Button>
                  <Button
                    aria-label="Check an existing claim"
                    href={UserRoutesEnum.CLAIMS}
                    size="2xl"
                    variant="text-primary"
                    data-testid="checkAnExistingClaimButton"
                  >
                    Check an existing claim
                    <ArrowRightLight
                      className="ms-2"
                      accessibilityTitle="Check an existing claim"
                      ariaHidden={true}
                      titleId="GoToClaimsArrowRightTitle"
                      color="#0772E4"
                    />
                  </Button>
                </div>
              </div>
            </div>
          }
          mediaSection={
            <MediaWrapper>
              <img
                width={600}
                height={600}
                src={heroSectionImg}
                alt="screenshot of our matching screen"
              />
            </MediaWrapper>
          }
        />
      </Content>
    </Wrapper>
  );
}
