import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { EmailIcon, LockIcon } from "../../../../../assets/ui-icons";
import { loginPartner, useAuthDispatch } from "../../../../../context";
import { Button, Form } from "../../../../../ui-components";
import useQueryString from "../../../../../utils/useQueryString";
import { emailValidationRules, passwordValidationRules } from "../../../../../validators";
import { ForgotPasswordLink } from "./styles";

type LogInFormData = {
  email: string;
  password: string;
};

export default function SignInForm() {
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const query = useQueryString();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    clearErrors,
  } = useForm<LogInFormData>({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    if (query.has("email")) {
      setValue("email", query.get("email") as string, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, []);

  const onSubmit = useCallback(async data => {
    const payload = {
      email: data.email,
      password: data.password,
    };

    const response = await loginPartner(dispatch, payload);

    if (!response?.id) {
      toast.error(response.detail);
      return;
    }

    if (query.has("returnUrl")) {
      history.push(query.get("returnUrl") as string);
    }

    history.push("/partner/overview");
  }, []);

  return (
    <Form className="d-flex flex-column mt-1 mb-45" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group disabled={isSubmitting} error={errors.email} className="mb-3">
        <Form.Label>Email address</Form.Label>

        <Form.Control
          {...register("email", emailValidationRules)}
          icon={{
            position: "start",
            element: <EmailIcon titleId="partner-email" accessibilityTitle="partner-email" />,
          }}
          placeholder="Work email"
          name="email"
          type="text"
          onChange={e => {
            setValue("email", e.target.value);

            clearErrors("email");
          }}
          data-testid="emailInput"
        />
      </Form.Group>

      <Form.Group disabled={isSubmitting} error={errors.password} className="mb-25">
        <Form.Label>Password</Form.Label>

        <Form.Control
          {...register("password", passwordValidationRules)}
          icon={{
            position: "start",
            element: <LockIcon titleId="partner-password" accessibilityTitle="partner-password" />,
          }}
          name="password"
          placeholder="Password"
          type="password"
          onChange={e => {
            setValue("password", e.target.value);

            clearErrors("password");
          }}
          data-testid="passwordInput"
        />
      </Form.Group>

      <ForgotPasswordLink
        href="/partner/forgot-password"
        variant="gray"
        data-testid="forgotPasswordLink"
      >
        Forgot password?
      </ForgotPasswordLink>

      <Button type="submit" aria-label="submit sign-in form" data-testid="loginButton">
        Sign in
      </Button>
    </Form>
  );
}
