import styled from "styled-components";
import { LegacyTextComponent } from "../../../../../components/typography";
import theme from "../../../../../theme";

export const PickupWrapper = styled.div`
  .address {
    border: 1px solid ${theme.colors.gray8};
    border-radius: ${theme.borderRadius.lg};
  }

  h3 {
    font-size: ${theme.fontSizes.default};
    line-height: 1.25;

    &.modal-version {
      font-size: ${theme.fontSizes.lg};
      line-height: 1;
    }
  }

  p {
    line-height: 1.5;
  }
`;

export const AddressText = styled(LegacyTextComponent)`
  text-align: left;
`;
