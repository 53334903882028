import { PartnerRoutesEnum } from "client/components/Routes";
import theme from "client/theme";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { ClaimStatus, PartnerClaim } from "../../../../types";
import { GoBackLink, Heading } from "../../../../ui-components";
import LookForMatchesCTA from "./LookForMatchesCTA";
import ReviewMatchesCTA from "./ReviewMatchCTA";
import { Wrapper } from "./styles";

export default function Header({ claim }: { claim: PartnerClaim }) {
  let headerCTA: ReactElement | null = null;
  const history = useHistory();
  switch (claim.status) {
    case ClaimStatus.HAS_MATCHES:
      headerCTA = <ReviewMatchesCTA claim={claim} />;
      break;
    case ClaimStatus.NEW:
    case ClaimStatus.NO_MATCHES:
      headerCTA = <LookForMatchesCTA claim={claim} />;
  }

  return (
    <Wrapper>
      <div>
        <GoBackLink
          variant="legacy"
          linkText={
            [PartnerRoutesEnum.SEARCH_ALL, PartnerRoutesEnum.SEARCH].includes(
              (history.location.state as any)?.pathname,
            )
              ? "Back to search results"
              : claim.status === ClaimStatus.CANCELED
                ? "Archived"
                : "Claims"
          }
          dataTestId="claims-back-link"
        />
        <Heading variant="h3" color={theme.ui.colors.white}>
          Claim details
        </Heading>
      </div>

      {headerCTA}
    </Wrapper>
  );
}
