import React from "react";

import theme from "client/theme";
import { PartnerClaim, PartnerItem } from "client/types";
import { Typography } from "..";
import { NoPhotoImagePlaceholder } from "../../components/ImageCarousel";
import { BorderWrapper, ClaimsImageThumbnail, InfoWrapper, Wrapper } from "./styles";

export type Props = {
  artifactToMatch: PartnerClaim | PartnerItem;
  matchingClaimToItems?: boolean;
};

export default function ArtifactToMatchSnapshot({ artifactToMatch, matchingClaimToItems }: Props) {
  return (
    <Wrapper className="mb-0 mt-lg-2">
      <BorderWrapper className="pb-3">
        <div className="px-0 ">
          {artifactToMatch?.images && artifactToMatch.images.length > 0 ? (
            <ClaimsImageThumbnail url={`${artifactToMatch.images[0]?.image}`} />
          ) : (
            <NoPhotoImagePlaceholder
              className="w-100 d-flex align-items-stretch"
              width="60px"
              height="60px"
              square
            >
              <span role="img" aria-label={`No image placeholder image`} />
            </NoPhotoImagePlaceholder>
          )}
        </div>
        <InfoWrapper
          className={`mx-0 w-100 d-flex flex-column justify-content-center pe-0`}
          data-testid="Snapshot"
        >
          <Typography
            style={{ maxWidth: "176px" }}
            className="ms-25 mb-0"
            as="p"
            data-testid="snapshotType"
            fontSize={theme.ui.fontSizes.sm}
            color={theme.ui.colors.gray600}
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.35}
          >
            {matchingClaimToItems ? "Claim" : "Item"}
          </Typography>
          <Typography
            className="ms-25 mb-0"
            as="p"
            data-testid="snapshotName"
            fontSize={theme.ui.fontSizes.base}
            color={theme.ui.colors.primary700}
            fontWeight={theme.ui.fontWeights.bold}
            lineHeight={1.35}
          >
            {artifactToMatch.name}
          </Typography>
        </InfoWrapper>
      </BorderWrapper>
    </Wrapper>
  );
}
