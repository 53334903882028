import { DropdownAlignment } from "client/components/Dropdowns/types";
import styled from "styled-components";
import Button from "../../../../ui-components/Button";

export const Divider = styled.hr`
  color: ${({ theme }) => theme.colors.gray3};
  margin: 0;
`;

export const DropdownWrapper = styled.div<{
  isSearchModeOn?: boolean;
}>`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: ${props => props.isSearchModeOn && "150px"};
  }

  &.mobile {
    position: unset;
    width: calc(100vw - 40px);
    &.large-mb {
      margin-bottom: 20px;
    }
    > ul {
      left: 16px;
      right: 16px;
      > div {
        width: 100%;
      }
    }
  }
`;

export const DropdownMenuWrapper = styled.ul<{
  dropdownAlignment: DropdownAlignment;
  top?: string;
  width?: string;
}>`
  ${props => (props.width ? `width: ${props.width};` : "")}
  ${props => (props.top ? `top: ${props.top};` : "")}
  border-radius: ${props => props.theme.borderRadius.lg};
  box-shadow: ${props => props.theme.shadows.lg};
  border: none;
  ${props =>
    (props.dropdownAlignment === DropdownAlignment.BOTTOM_END ||
      props.dropdownAlignment === DropdownAlignment.TOP_END) &&
    "right: 0;"}
  left: auto;
  ${props =>
    (props.dropdownAlignment === DropdownAlignment.TOP_START ||
      props.dropdownAlignment === DropdownAlignment.TOP_END) &&
    "inset: auto auto 75px 0;"}
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
`;

export const IconButton = styled.button<{ color: string }>`
  font-weight: 500;
  ${props => props.color && `color: ${props.color};`}

  &:hover {
    ${props => props.color && `color: ${props.color};`}
  }
`;

export const ListItem = styled.div<{
  darkHover?: boolean;
  textColor?: string;
  tabIndex?: number;
  width: string;
}>`
  font-size: ${props => props.theme.fontSizes.default};
  color: ${props => props.textColor || props.theme.colors.gray1};
  width: ${props => props.width};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  cursor: pointer;
  svg {
    color: ${props => props.theme.colors.gray3};
  }
  &:focus {
    background-color: ${props => props.theme.colors.lightBlue}80;
    outline: none;
  }
  &:hover {
    background-color: ${props =>
      props.darkHover ? props.theme.colors.gray6 : `${props.theme.colors.lightBlue}80`};
  }
  &.active {
    &:focus {
      background-color: ${props => props.theme.colors.lightBlue}80;
    }
    ${props => (props.darkHover ? "font-weight: 600;" : "")}
    ${props =>
      props.darkHover
        ? `background-color: ${props.theme.colors.gray6}`
        : `box-shadow: inset 4px 0 0 0 ${props.theme.ui.colors.primary700}`};
  }
  &.title {
    font-weight: 700;
    cursor: default;
    border-bottom: 1px solid ${props => props.theme.colors.gray8};
    &:hover {
      background: none;
    }
  }
`;

export const StyledFilterDropdownButton = styled.button`
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.gray3};
  font-weight: ${props => props.theme.fontWeights.default};
  width: ${props => props.theme.widths.dropdownButton};
  pointer-events: none;
  text-align: left;
  &&:focus,
  &[aria-expanded="true"] {
    box-shadow: none;
    outline: solid;
    outline-color: ${props => props.theme.ui.colors.primary700};
  }
`;
export const StyledBasicDropdownButton = styled.div`
  border-radius: 8px;
  &&:focus,
  &[aria-expanded="true"] {
    box-shadow: none;
    outline: solid;
    outline-color: ${props => props.theme.colors.primaryBlue};
  }
`;
export const UserDropdownItemWrapper = styled.div`
  color: ${props => props.color || props.theme.colors.gray1};
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  padding-top: 22px;
  padding-bottom: 22px;
  svg {
    fill: ${props => props.theme.colors.gray2};
  }
  &.active {
    box-shadow: inset 4px 0 0 0 ${props => props.theme.ui.colors.primary700};
  }
  &:hover {
    background-color: ${props => props.theme.colors.lightBlue};
  }
  &:focus {
    background-color: ${props => props.theme.colors.lightBlue};
  }
`;

export const StyledSelect = styled.div<{
  dropdownOpen?: boolean;
  isSearchModeOn?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.ui.colors.primary700};
  border-radius: 50px;
  color: ${props => props.theme.colors.gray1};
  padding: 6px 12px;
  cursor: default;
  font-size: ${props => props.theme.fontSizes.default};
  font-family: ${props => props.isSearchModeOn && props.theme.ui.fontFamilies.default};
  font-weight: ${props => props.isSearchModeOn && "400"};
  line-height: ${props => props.isSearchModeOn && "24px"};

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: ${props => (props.isSearchModeOn ? "16px" : props.theme.fontSizes.sm)};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: ${props => (props.isSearchModeOn ? "52px" : "")};
    border-top-right-radius: ${props => props.isSearchModeOn && "0"};
    border-bottom-right-radius: ${props => props.isSearchModeOn && "0"};
  }

  &.cursor-pointer {
    cursor: pointer;
  }
  > div {
    max-width: 214px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > svg {
    transition: 0.2s ease-in-out;
    ${props => props.dropdownOpen && "transform: rotate(180deg)"};
  }
`;

export const StyledButton = styled(Button)`
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid #dce1e8;
  background: #fff;
  width: 156px;
  height: 60px;
  color: #00174a;
  font-family: lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  &:hover,
  &:focus,
  &:active {
    background-color: #fff !important;
    color: #00174a !important;
    border: 1px solid #00174a !important;
    box-shadow: none !important;
  }

  & > svg {
    margin: 0 !important;
  }
`;

export const ButtonAction = styled.button`
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid #dce1e8;
  background: #fff;
  width: 156px;
  height: 60px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #00174a;
  font-family: lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

export const ContainerMatches = styled.div`
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background-color: white;
  margin-top: 10px;
  border-radius: 16px;
  z-index: 1;
  margin-left: -45px;
`;

export const CardMatch = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  align-self: stretch;
  color: var(--primary-700-for-text, #00174a);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  gap: 12px;
`;

export const ContainerMatchesMobile = styled.div`
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background-color: white;
  margin-top: -88px;
  border-radius: 16px;
  z-index: 1;
  margin-left: -45px;
`;

export const IconLabel = styled.span`
  color: #00174a;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
