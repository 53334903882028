import classNames from "classnames";
import React from "react";
import { isMdQuery } from "../../../../helpers/mediaQuery";
import { CopySection, MediaSection, StyledRow } from "./styles";

interface Props {
  copySection: React.ReactNode;
  mediaSection: React.ReactNode;
  mediaSectionRight?: boolean;
}

const TwoColumnLayout: React.FC<Props> = ({ copySection, mediaSection, mediaSectionRight }) => {
  const isMd = isMdQuery();
  return (
    <StyledRow className="align-items-center">
      {!mediaSectionRight || !isMd ? (
        <>
          <MediaSection className="mb-35">{mediaSection}</MediaSection>

          <CopySection className={classNames({ "text-end": !mediaSectionRight && !isMd })}>
            {copySection}
          </CopySection>
        </>
      ) : (
        <>
          <CopySection>{copySection}</CopySection>

          <MediaSection>{mediaSection}</MediaSection>
        </>
      )}
    </StyledRow>
  );
};

export default TwoColumnLayout;
