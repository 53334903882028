import styled from "styled-components";

export const LeftSliderWrapper = styled.div`
  min-height: 100vh;
  max-width: 420px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.colors.white};
  padding: 40px 40px 0 40px;
  margin: 0;
`;

export const Main = styled.main<{
  subMenuHeight: number;
}>`
  background-color: ${({ theme }) => theme.ui.colors.white};
  padding-bottom: calc(64px + ${({ subMenuHeight }) => subMenuHeight}px);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background-color: ${({ theme }) => theme.ui.colors.gray300};
    padding-bottom: 0;
  }
`;
