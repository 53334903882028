import { PartnerItem, UnclaimedStatus } from "client/types";
import { Button } from "client/ui-components";
import React from "react";
import { toUnclaimedAction } from "../../helpers/index";
import { StyledLink } from "./styles";

export interface SetUnclaimedStatusButtonProps {
  variant?: "large" | "regular";
  selectedItems: PartnerItem[];
  isLoading?: boolean;
  disabled?: boolean;
  unclaimedStatus: UnclaimedStatus | null;
  onClick?: (items: PartnerItem[]) => any;
}

export default function SetUnclaimedStatusButton({
  variant,
  selectedItems,
  isLoading,
  disabled,
  unclaimedStatus,
  onClick,
}: SetUnclaimedStatusButtonProps) {
  const action = toUnclaimedAction(unclaimedStatus);
  const label = `Set to ${action}`;
  if (variant === "large") {
    return (
      <Button
        aria-label={label}
        onClick={() => onClick?.(selectedItems)}
        disabled={disabled}
        loading={isLoading}
        loadingText="Saving..."
      >
        {label}
      </Button>
    );
  }
  return (
    <StyledLink
      onClick={!disabled ? () => onClick?.(selectedItems) : undefined}
      isButton
      dataTestId="setToArchived"
      variant={disabled || isLoading ? "dark" : undefined}
    >
      {/* <img src={isLoading ? donateDisabledIcon : donateIcon} alt={label} /> */}
      <span>{label}</span>
    </StyledLink>
  );
}
