import React, { Dispatch, useReducer } from "react";
import { UserAction } from "./actions";
import userReducer from "./reducer";
import { initialUserState, UserState } from "./state";

export type UserContextType = [UserState, Dispatch<UserAction>];

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userState, dispatchUserAction] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={[userState, dispatchUserAction]}>{children}</UserContext.Provider>
  );
};
