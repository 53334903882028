import { isMdQuery } from "client/helpers/mediaQuery";
import React, { useState } from "react";
import { Button, Text } from "../../../../../ui-components";
import lookingForMatchesIcon from "./lookingForMatchesIcon.svg";
import Modal from "./Modal";

export default function NoMatchesActionBar() {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const isMd = isMdQuery();

  return (
    <>
      <Modal isOpened={isOpened} setIsOpened={setIsOpened} />

      <div className="d-flex flex-row justify-content-between gap-4 flex-grow-1 align-items-center">
        <div className="d-flex flex-row gap-35 align-items-center">
          <img src={lookingForMatchesIcon} alt="Looking for matches" />

          <Text fontWeight={700}>
            We’re still looking <br /> for your item!
          </Text>
        </div>

        <div className="d-flex flex-row justify-content-end">
          <Button
            onClick={() => setIsOpened(true)}
            aria-label="claim options"
            className="flex-shrink-0"
            size="2xl"
          >
            {!isMd ? "Options" : "Claim options"}
          </Button>
        </div>
      </div>
    </>
  );
}
