import React from "react";
import { Heading } from "../../../../ui-components";
import { HeroSection, Subtitle } from "./styles";

export default function Hero() {
  return (
    <HeroSection className="d-flex flex-column align-items-center">
      <Heading variant="h2" as="h1">
        Our mission
      </Heading>

      <Subtitle textAlign="center">
        Boomerang is a lost and found technology platform that connects people with their lost
        items.
      </Subtitle>
    </HeroSection>
  );
}
