import React from "react";
import theme from "../../theme";
import { Typography } from "../../ui-components";
import { CenterSuccessWrapper, StyledMatchCreatedPartyIcon } from "./styles";

interface SuccessModalInteriorProps {
  description: React.ReactNode;
  header: string;
  showIcon?: boolean;
}

const SuccessModalInterior: React.FC<SuccessModalInteriorProps> = props => {
  const { description, header, showIcon = true } = props;

  return (
    <div className="d-flex flex-column gap-25 m-auto">
      <CenterSuccessWrapper>
        {showIcon ? (
          <StyledMatchCreatedPartyIcon
            accessibilityTitle="Success match created"
            titleId="SuccessModalMatchCreated"
          />
        ) : null}
        <Typography
          className="text-center"
          data-testid="successModalTitle"
          heading
          as="h1"
          fontSize="34px"
          color={theme.ui.colors.primary700}
          fontWeight={theme.ui.fontWeights.bold}
          lineHeight={1.47}
        >
          {header}
        </Typography>
        {description}
      </CenterSuccessWrapper>
    </div>
  );
};

export default SuccessModalInterior;
