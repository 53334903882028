import React from "react";
import { CircleCheckIcon } from "../../../assets/ui-icons/CircleCheckIcon";
import { FileIcon } from "../../../assets/ui-icons/FileIcon";
import { UserIcon } from "../../../assets/ui-icons/UserIcon";
import Button from "../../Button";
import Link from "../../Link";
import { UserDetails, UserMenuDropdown } from "../components";
import { NavbarContent } from "./types";

const UserDashboard: NavbarContent = {
  leftMenuItems: [],
  leftMenuItemsMobile: [
    <Link variant="gray" href="/claims">
      <div className="d-flex align-items-center">
        <FileIcon className="me-225" accessibilityTitle="File icon" titleId="FileIcon" /> Claims
      </div>
    </Link>,
    <Link variant="gray" href="/contact-details">
      <div className="d-flex align-items-center">
        <UserIcon className="me-225" accessibilityTitle="User icon" titleId="UserIcon" /> Contact
        details
      </div>
    </Link>,
    <Link variant="gray" href="/completed-returns">
      <div className="d-flex align-items-center">
        <CircleCheckIcon
          className="me-225"
          accessibilityTitle="Circle check icon"
          titleId="CircleCheckIcon"
        />{" "}
        Completed returns
      </div>
    </Link>,
  ],
  rightMenuItems: [
    <Button aria-label="Lost something?" size="lg" fullWidth href="/lost">
      Report lost item
    </Button>,
    <UserMenuDropdown />,
  ],
  rightMenuItemsMobile: [<UserDetails className="px-0 px-md-35 mb-35" />],
};

export default UserDashboard;
