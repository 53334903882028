import { InlineDatePicker } from "client/components/InlineDatePicker";
import { Button, Modal } from "client/ui-components";
import React from "react";
import { ButtonContainer, HeaderContainer, Heading, Subheading } from "./styles";

type Props = {
  isOpened?: boolean;
  isLoading?: boolean;
  onNext?: (selectedDate: Date) => any;
  onDismiss: (isOpen: boolean) => any;
};

export function UnclaimedDatePickerModal({
  isOpened = false,
  isLoading,
  onNext,
  onDismiss,
}: Props) {
  const [date, setDate] = React.useState(new Date());
  return (
    <Modal
      isOpened={isOpened}
      hasCloseButton
      heading={
        <HeaderContainer>
          <Heading>Choose a donation date</Heading>
          <Subheading>Choose the date to mark the items as donated.</Subheading>
        </HeaderContainer>
      }
      description={<InlineDatePicker maxDate={new Date()} onChange={setDate} />}
      setIsOpened={onDismiss}
      buttons={
        <ButtonContainer>
          <Button
            className="mb-1"
            aria-label="Next"
            data-testid="next"
            size="2xl"
            onClick={() => onNext?.(date)}
            loading={isLoading}
            loadingText="Next"
          >
            Next
          </Button>
          <Button
            aria-label="Go back"
            data-testid="goback"
            onClick={() => onDismiss?.(false)}
            size="2xl"
            variant="text"
          >
            Go back
          </Button>
        </ButtonContainer>
      }
    />
  );
}
