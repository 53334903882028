import styled from "styled-components";

export const ModalWrapper = styled.div<{
  isOpened: boolean;
}>`
  display: ${props => (props.isOpened ? "flex" : "none")};
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  background-color: ${props => props.theme.ui.colors.modalOverlay};
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    align-items: center;
  }
`;

export const ModalDialog = styled.div`
  max-width: none;
  width: 100%;
  margin-block: 0;
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const ModalContent = styled.div<{
  hasIcon?: boolean;
  hasCloseButton?: boolean;
}>`
  border: 0px;
  border-radius: 16px 16px 0 0;
  padding: 20px 20px 32px 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    border-radius: 16px;
    padding: 28px ${props => (props.hasCloseButton ? "28px" : "64px")}
      ${props => (props.hasIcon ? "68px" : "40px")}
      ${props => (props.hasCloseButton ? "28px" : "64px")};
  }
`;

export const ModalHeader = styled.div`
  padding: 0;
  border: none;
`;

export const CloseButton = styled.button`
  background: none;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const ButtonWrapper = styled.div`
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 400px;
    margin-inline: auto;
  }
`;
