import debounce from "lodash.debounce";
import { useMemo } from "react";

/**
 * A hook that returns a memoized version of the function that is debounced, this is to prevent a function from being triggered too many times in a short period of time
 * @param handlerFunction function that you want to debounce
 * @param delay number in ms to debounce the function in between invocations (e.g. don't allow this function to be called again in the next x ms)
 * @param dependencies array of dependencies that will trigger the function to refresh
 * @returns a memoized version of the function that is debounced
 */
export const useDebounce = (handlerFunction: any, delay: number, dependencies: any[]) =>
  useMemo(() => debounce(handlerFunction, delay), [...dependencies]);
