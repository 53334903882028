import React, { createContext, useMemo, useState } from "react";

type CancelClaimContextType = {
  step: "reason" | "otherReason" | "confirmation" | "success";
  setStep: React.Dispatch<React.SetStateAction<CancelClaimContextType["step"]>>;
};

export const CancelClaimContext = createContext<CancelClaimContextType>({
  step: "confirmation",
  setStep: () => undefined,
});

export default function CancelClaimProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [step, setStep] = useState<CancelClaimContextType["step"]>("confirmation");

  const values = useMemo<CancelClaimContextType>(
    () => ({
      step,
      setStep,
    }),
    [step],
  );

  return <CancelClaimContext.Provider value={values}>{children}</CancelClaimContext.Provider>;
}
