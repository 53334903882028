import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;

export const ButtonLink = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
