import React from "react";
import { CardBody, CardContainer, CardHeader, SpanTitle, TitleHeader } from "./styles";

interface CardProps {
  icon: string;
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function DetailsCard({ icon, title, children, style }: CardProps) {
  return (
    <CardContainer>
      <CardHeader>
        <TitleHeader>
          <img src={icon} alt="." />
          <SpanTitle>{title}</SpanTitle>
        </TitleHeader>
      </CardHeader>
      <CardBody style={style}>{children}</CardBody>
    </CardContainer>
  );
}
