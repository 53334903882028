import styled from "styled-components";

export const Divider = styled.hr`
  color: ${props => props.theme.colors.gray8};
  opacity: 1;
`;

export const ItemWrapper = styled.div<{
  highlightColor?: string;
  fontSize?: string;
}>`
  color: ${props => props.color || props.theme.colors.gray1};
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  font-size: ${props => props.fontSize || props.theme.fontSizes.lg};
  font-weight: ${props => props.theme.fontWeights.button};
  svg {
    color: ${props => props.theme.colors.black};
    path {
      fill: ${props => props.theme.colors.black};
    }
  }
  &.highlight:hover {
    background-color: ${props => props.highlightColor || props.theme.colors.lightBlue + "80"};
  }
  &.current {
    box-shadow: inset 4px 0 0 0 ${props => props.theme.colors.primaryBlue};
  }
`;

interface MenuWrapperProps {
  headerHeight?: string;
}

export const MenuWrapper = styled.div<MenuWrapperProps>`
  width: 100%;
  height: calc(
    100vh - ${props => props.headerHeight || props.theme.heights.mobileDefaultHeader}
  ); //per figma height of header + margin
  background-color: ${props => props.theme.colors.white};
`;

export const StatusBox = styled.div`
  color: ${props => props.theme.colors.gray1};
  width: 70px;
  text-align: center;
  background-color: ${props => props.theme.colors.gray6};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.default};
  border-radius: 4px;
  &.current {
    background-color: ${props => props.theme.colors.primaryBlue};
    color: ${props => props.theme.colors.white};
  }
`;
