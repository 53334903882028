import classNames from "classnames";
import React from "react";
import { isLgQuery } from "../helpers/mediaQuery";
import Button from "./Button";
import { ButtonModes, ButtonTypes } from "./Button/Button.types";

export interface FooterProps {
  className?: string;
  leftBtnClassName?: string;
  leftBtnDisabled?: boolean;
  leftBtnIcon?: React.ReactNode;
  leftBtnOnClick?: () => void;
  leftBtnTabIndex?: number;
  leftBtnText?: string;
  leftBtnType?: ButtonTypes;
  loading?: boolean;
  loadingText?: string;
  rightBtnClassName?: string;
  rightBtnDisabled?: boolean;
  rightBtnForm?: string;
  rightBtnMode?: ButtonModes;
  rightBtnOnClick?: () => void | Promise<void>;
  rightBtnTabIndex?: number;
  rightBtnText?: string;
  rightBtnType?: ButtonTypes;
}

function CardFormFooter(props) {
  const {
    className,
    leftBtnDisabled,
    leftBtnText,
    leftBtnOnClick,
    leftBtnClassName,
    leftBtnIcon,
    leftBtnType,
    leftBtnTabIndex,
    loading,
    loadingText,
    rightBtnClassName,
    rightBtnDisabled,
    rightBtnOnClick,
    rightBtnForm,
    rightBtnText,
    rightBtnTabIndex,
  }: FooterProps = props;

  const isLg = isLgQuery();

  return (
    <div className={`border-0 pt-0 pb-lg-3 mb-lg-4 ${className ? className : "px-1 px-lg-4"}`}>
      <div className="row justify-content-between w-100 mx-0 my-0">
        <div
          className={`${
            !!rightBtnText && "justify-content-center row mx-0"
          } col-12 col-lg-6 mb-2 mt-1 my-lg-0 px-0 pe-lg-225`}
        >
          {isLg && leftBtnText && (
            <Button
              ariaLabel={leftBtnText}
              text={leftBtnText}
              icon={leftBtnIcon}
              onClick={leftBtnOnClick !== undefined ? leftBtnOnClick : undefined}
              disabled={leftBtnDisabled}
              mode={ButtonModes.open}
              type={leftBtnType ?? ButtonTypes.button}
              className={leftBtnClassName}
            />
          )}
        </div>
        <div className="order-1 order-lg-2 col-12 col-lg-6 px-0 ps-lg-225 mb-3 mb-lg-0">
          {rightBtnText && (
            <Button
              ariaLabel={rightBtnText}
              mode={ButtonModes.primary}
              type={ButtonTypes.submit}
              text={rightBtnText}
              className={classNames("w-100", rightBtnClassName)}
              disabled={rightBtnDisabled}
              form={rightBtnForm}
              loading={loading}
              loadingText={loadingText}
              onClick={rightBtnOnClick !== undefined ? rightBtnOnClick : undefined}
              tabIndex={rightBtnTabIndex}
            />
          )}
        </div>
        <div
          className={`${
            !!rightBtnText && "justify-content-center row mx-0"
          } order-2 order-lg-1 d-lg-none col-12 col-lg-6 mb-2 mt-1 my-lg-0 px-0 pe-lg-225`}
        >
          {!isLg && leftBtnText && (
            <Button
              ariaLabel={leftBtnText}
              text={leftBtnText}
              icon={leftBtnIcon}
              onClick={leftBtnOnClick !== undefined ? leftBtnOnClick : undefined}
              disabled={leftBtnDisabled}
              mode={ButtonModes.open}
              type={leftBtnType ?? ButtonTypes.button}
              className={leftBtnClassName}
              tabIndex={leftBtnTabIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CardFormFooter;
