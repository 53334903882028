import React from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import imagePlaceholder from "../../assets/imagePlaceholder.svg";

export enum CarouselItemBackgroundSize {
  Contain = "contain",
  Cover = "cover",
  Auto = "auto",
}

export const StyledCarousel = styled(Carousel)<{
  $dimensions: { height: number; width?: number };
  backgroundSize?: CarouselItemBackgroundSize;
}>`
  ${props =>
    props.$dimensions?.width !== undefined &&
    `
  width: ${props.$dimensions.width}px;
  margin: auto;
  `}

  .carousel-item {
    border-radius: ${props => props.theme.borderRadius.lg};
    height: ${props => (props.$dimensions?.height ? props.$dimensions.height + "px" : "100%")};
    width: 100%;
    background-position: center;
    background-size: ${props => props.backgroundSize ?? "cover"};
    background-repeat: no-repeat;
  }
  .carousel-indicators [data-bs-target] {
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 60px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;

    .bg {
      transition: all 0.3s;
    }

    .focus {
      transition: all 0.3s;
      stroke-opacity: 0;
    }
  }
  .carousel-control-next:hover,
  .carousel-control-prev:hover {
    .bg {
      fill-opacity: 0.7;
    }
  }

  .carousel-control-next:focus,
  .carousel-control-prev:focus {
    .focus {
      stroke-opacity: 1;
    }
    .bg {
      fill-opacity: 0.9;
      stroke-opacity: 1;
    }
  }
`;

export const StyledCarouselItem = styled(Carousel.Item)<{
  url: string;
  $dimensions: { height: number; width: number };
}>`
  background-image: url(${props => props.url});
  &&& {
    height: 100%;
    max-width: 100%;
    width: ${props =>
      props.$dimensions.width ? props.$dimensions.width + "px" : "100%"} !important;
    min-height: ${props =>
      props.$dimensions.height ? props.$dimensions.height + "px" : "100%"} !important;
  }
`;

export const NoPhotoImagePlaceholder = styled.div<{
  $dimensions: { height: number; width?: number };
}>`
  border-radius: ${props => props.theme.borderRadius.lg};
  width: ${props => (props.$dimensions.width ? props.$dimensions.width + "px" : "100%")} !important;
  max-width: ${props =>
    props.$dimensions.width ? props.$dimensions.width + "px" : "100%"} !important;
  height: ${props =>
    props.$dimensions.height ? props.$dimensions.height + "px" : "100%"} !important;
  background: url("${imagePlaceholder}") no-repeat center;
  background-size: cover;
`;

// export const NoPhotoPlaceholder = styled.div<{
//   height?: string;
//   width?: string;
// }>`
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   flex-direction: column;
//   text-align: center;
//   background-color: ${(props) => props.theme.colors.gray8};
//   border-radius: ${(props) => props.theme.borderRadius.lg};
//   color: ${(props) => props.theme.colors.gray3};
//   height: ${(props) => props.height || props.theme.heights.uploadThumbLg};
//   ${(props) => (props.width ? "width: " + props.width + ";" : "")}
//   font-family: ${(props) => props.theme.fontFamilies.display};
//   font-style: normal;
//   line-height: 1.5;
//   font-weight: 600;
//   font-size: ${(props) => props.theme.fontSizes.default};
// `;

export const FilledArrowRight: React.FC = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="46"
      height="46"
      rx="23"
      fill="none"
      fillOpacity="0.4"
      stroke="#0772E4"
      strokeWidth="2"
      strokeOpacity="0"
      className="focus"
      transform="matrix(1 8.74228e-08 8.74228e-08 -1 2 48)"
      x="-1"
      y="1"
    />
    <rect
      width="42"
      height="42"
      rx="21"
      transform="matrix(1 8.74228e-08 8.74228e-08 -1 2 44)"
      fill="black"
      fillOpacity="0.4"
      className="bg"
      stroke="white"
      strokeWidth="2"
      strokeOpacity="0"
      x="1"
      y="-1"
    />
    <path
      d="M21 18L27 24L21 30"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const FilledArrowLeft: React.FC = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="46"
      height="46"
      rx="23"
      fill="none"
      fillOpacity="0.4"
      stroke="#0772E4"
      strokeWidth="2"
      strokeOpacity="0"
      className="focus"
      transform="matrix(1 8.74228e-08 8.74228e-08 -1 2 48)"
      x="-1"
      y="1"
    />
    <rect
      width="42"
      height="42"
      rx="21"
      transform="matrix(1 8.74228e-08 8.74228e-08 -1 2 44)"
      fill="black"
      fillOpacity="0.4"
      className="bg"
      stroke="white"
      strokeWidth="2"
      strokeOpacity="0"
      x="1"
      y="-1"
    />
    <path
      d="M27 18L21 24L27 30"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
