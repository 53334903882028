import DefaultError from "client/ui-components/DefaultError";
import React, { useEffect } from "react";
import ReturnsTable from "./components/ReturnsTable";
import ReturnsToolbar from "./components/ReturnsToolbar";
import useReturns from "./helpers/hooks/useReturns";
import { useReturnsStore } from "./returnStore";
import { Wrapper } from "./styles";

const Returns = () => {
  const { error, formattedReturns, isLoading, count } = useReturns();

  const { setHasBulkOptions } = useReturnsStore();

  useEffect(() => {
    setHasBulkOptions(false);
  }, []);

  if (error) return <DefaultError />;

  return (
    <>
      <ReturnsToolbar />
      <Wrapper>
        <ReturnsTable returns={formattedReturns} isLoading={isLoading} count={count} />
      </Wrapper>
    </>
  );
};

export default Returns;
