import { Link } from "react-router-dom";
import styled from "styled-components";

export const ArchivedLink = styled(Link)`
  color: ${props => props.theme.ui.colors.gray600};
  font-size: ${props => props.theme.ui.fontSizes.sm};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-weight: ${props => props.theme.ui.fontWeights.bold};

  :hover {
    text-decoration: none;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;
