import React from "react";
import FormControl from "./FormControl";
import FormGroup from "./FormGroup";
import FormHelp from "./FormHelp";
import FormLabel from "./FormLabel";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["form"]>;

function Form({ children, ...props }: Props) {
  return <form {...props}>{children}</form>;
}

Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Control = FormControl;
Form.Help = FormHelp;

export default Form;
