import { useQuery } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default (claimId: string | null) => {
  const { data, isLoading, error } = useQuery(queryFactory.claim(claimId));

  return {
    claim: data,
    isLoading,
    error,
  };
};
