import { fetchApiData } from "client/utils/fetchApiData";
import { DeleteClaimImageRequest } from "../../../../types";
import { getCommonHeaders } from "../../../../utils/headers";

export const deleteClaimImage = async (
  deleteClaimImageRequest: DeleteClaimImageRequest,
): Promise<string> => {
  await fetchApiData({
    url: `/api/user/claims/${deleteClaimImageRequest.claim_id}/images/${deleteClaimImageRequest.image_id}/`,
    options: {
      method: "DELETE",
      headers: getCommonHeaders(),
    },
  });
  return deleteClaimImageRequest.image_id;
};
