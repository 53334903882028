import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { ReCAPTCHAContainer } from "../../../../../../scenes/public/ClaimForm/steps/ReviewContactDetails";
import Text from "../../../../../../ui-components/Text";

export const Content = styled.div`
  text-align: left;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 0 auto;
    max-width: 400px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
    width: 576px;
  }

  .submit-button {
    margin-top: 28px;
  }
`;

export const ReviewFieldHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${props => props.theme.ui.colors.primary500};
  font-size: ${props => props.theme.ui.fontSizes.base};
  font-weight: ${props => props.theme.ui.fontWeights.bold};
  text-align: left;
  font-family: Lato;
  line-height: 1.5;
`;

export const StyledReCAPTCHAContainer = styled(ReCAPTCHAContainer)`
  transform-origin: 0 0;
`;

export const StyledReCAPTCHA = styled(ReCAPTCHA)<{
  hasError: boolean;
}>`
  iframe {
    border: ${props => props.hasError && `${props.theme.ui.colors.error500} solid 2px`};
    border-radius: 12px;
  }
`;

export const VerificationHeading = styled(Text)`
  margin-top: 40px;
`;

export const VerificationText = styled(Text)`
  margin-top: 20px;
`;

export const ErrorMessage = styled(Text)`
  margin-top: 8px;
`;
