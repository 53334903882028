import React, { useContext, useMemo } from "react";
import { ClaimDetailsContext } from "../../../../context/ClaimDetails";
import theme from "../../../../theme";
import { ClaimStatus, ReturnModeEnum, ReturnStatus, ShipmentStatus } from "../../../../types";
import { Text } from "../../../../ui-components";
import { StatusBarWrapper } from "./styles";

export default function StatusBar() {
  const { status, returnResponse, partnerRecoveryMethods } = useContext(ClaimDetailsContext);

  const currentRecoveryMethod = returnResponse?.mode;

  const copy = useMemo(() => {
    if (partnerRecoveryMethods === undefined) return "";

    switch (status) {
      case ClaimStatus.NEW:
      case ClaimStatus.NO_MATCHES:
      case ClaimStatus.HAS_MATCHES:
        return "Looking for matches";
      case ClaimStatus.MATCHED:
        return "Matched";
      case ClaimStatus.CANCELED:
        return "Canceled";
      case ClaimStatus.EXPIRED:
        return "Expired";
      case ShipmentStatus.PACKAGED:
        return "Packaged";
      case ReturnStatus.PENDING:
      case ShipmentStatus.PENDING:
        switch (currentRecoveryMethod) {
          case ReturnModeEnum.PICKUP:
            return "Ready for pickup";
          case ReturnModeEnum.SHIPPING:
            return "Processing";
          default:
            return;
        }
      case ShipmentStatus.AWAITING_PAYMENT:
        return "Awaiting payment";
      case ShipmentStatus.SHIPPED:
        return "In transit";
      case ShipmentStatus.DELIVERED:
        return "Delivered";
      case ReturnStatus.COMPLETED:
        return "Returned";
    }
  }, [status]);

  const bgColor = useMemo(() => {
    switch (status) {
      case ClaimStatus.NEW:
      case ClaimStatus.NO_MATCHES:
      case ClaimStatus.HAS_MATCHES:
        return theme.ui.colors.primary500;
      case ReturnStatus.PENDING:
      case ShipmentStatus.PENDING:
      case ShipmentStatus.PACKAGED:
        switch (currentRecoveryMethod) {
          case ReturnModeEnum.PICKUP:
            return theme.ui.colors.secondary500;
          case ReturnModeEnum.SHIPPING:
            return theme.ui.colors.yellow500;
          default:
            return theme.ui.colors.primary500;
        }
      case ClaimStatus.MATCHED:
      case ShipmentStatus.DELIVERED:
      case ReturnStatus.COMPLETED:
        return theme.ui.colors.secondary500;
      case ShipmentStatus.AWAITING_PAYMENT:
        return theme.ui.colors.error500;
      case ClaimStatus.CANCELED:
      case ClaimStatus.EXPIRED:
        return theme.ui.colors.gray500;
      case ShipmentStatus.PROCESSING:
      case ShipmentStatus.SHIPPED:
        return theme.ui.colors.yellow500;
      default:
        return theme.ui.colors.primary500;
    }
  }, [status]);

  const textColor = useMemo(() => {
    switch (status) {
      case ShipmentStatus.PENDING:
      case ShipmentStatus.PACKAGED:
        switch (currentRecoveryMethod) {
          case ReturnModeEnum.PICKUP:
            return theme.ui.colors.white;
          case ReturnModeEnum.SHIPPING:
            return theme.ui.colors.primary700;
          default:
            return theme.ui.colors.white;
        }
      case ShipmentStatus.PROCESSING:
      case ShipmentStatus.SHIPPED:
        return theme.ui.colors.primary700;
      case ClaimStatus.CANCELED:
      case ClaimStatus.EXPIRED:
        return theme.ui.colors.primary700;
      default:
        return theme.ui.colors.white;
    }
  }, [status]);

  return status === undefined ? null : (
    <StatusBarWrapper
      backgroundColor={bgColor}
      className="d-flex flex-wrap gap-1 justify-content-between align-items-center"
    >
      <Text as="span" color={textColor} fontWeight={400}>
        Status:
      </Text>

      <Text data-testid="itemStatus" as="span" color={textColor} fontWeight={700}>
        {copy}
      </Text>
    </StatusBarWrapper>
  );
}
