import classNames from "classnames";
import useGetImagesWithClaimId from "client/hooks/data/user/useGetImagesWithClaimId";
import React, { useContext } from "react";
import { ClaimDetailsContext } from "../../../../context/ClaimDetails";
import { Heading, ImageCarousel, Text } from "../../../../ui-components";
import { CardContent } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function SecondaryDisplay({ className, ...rest }: Props) {
  const { claim, item } = useContext(ClaimDetailsContext);

  if (!claim || !item) return null;
  const { data: claimImages } = useGetImagesWithClaimId(claim.id);

  return (
    <CardContent {...rest} className={classNames("d-flex flex-column bg-white", className)}>
      <hr className="mb-45 mt-0" />

      <Heading variant="h4" as="h3" size="lg" className="mb-35">
        Lost item
      </Heading>

      <div className="d-flex flex-row">
        {claimImages?.results && claimImages?.results?.length > 0 && (
          <ImageCarousel
            images={claimImages.results.map(image => image.image)}
            indicators={claimImages.count > 1}
            dimensions={{ height: 108, width: 82 }}
            className="flex-grow-0 m-0 me-35"
          />
        )}

        <div className="d-flex flex-column">
          <Heading variant="h5" as="h5" size="sm" className="mb-1">
            {claim.name}
          </Heading>

          <Text>{claim.description}</Text>
        </div>
      </div>
    </CardContent>
  );
}
