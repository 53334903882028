import { BadgeVariantsEnum } from "../components/Badge/index";
import theme from "../theme";
import {
  ClaimStatus,
  ClaimStatusStrings,
  PartnerClaimStatusEnum,
  PartnerItemStatusEnum,
  ReturnStatus,
  ShipmentStatus,
} from "../types";
import {
  PartnerReturnPickupStatusStringEnum,
  PartnerReturnShippingStatusStringEnum,
} from "../types/theme";

/* TODO: BadgeVariantsEnum.Text is no longer used; Remove it */

export const getBadgeCss = (
  status?:
    | ClaimStatusStrings
    | PartnerClaimStatusEnum
    | PartnerItemStatusEnum
    | ShipmentStatus
    | string,
  variant?: BadgeVariantsEnum,
  isUser?: boolean,
) => {
  if (!status) return ``;
  switch (status) {
    case PartnerClaimStatusEnum.NEW:
      return `
      color: ${theme.ui.colors.primary700};
      background-color: ${theme.ui.colors.primary400};
      `;
    case ClaimStatus.NO_MATCHES:
    case ClaimStatus.HAS_MATCHES:
    case PartnerItemStatusEnum.HAS_MATCHES:
    case PartnerClaimStatusEnum.HAS_MATCHES:
      return `
      color: ${variant === BadgeVariantsEnum.Text ? theme.colors.primaryBlue : theme.colors.white};
      background-color: ${
        variant === BadgeVariantsEnum.Text ? theme.colors.transparent : theme.colors.primaryBlue
      };
      `;
    case ClaimStatusStrings.STILL_LOOKING:
      return `
        color: ${theme.colors.gray1};
        background-color: ${theme.colors.lightBlue};
      `;
    case ClaimStatusStrings.MORE_INFO_NEEDED:
    case PartnerClaimStatusEnum.NO_MATCHES:
    case PartnerItemStatusEnum.NO_MATCHES:
    case PartnerReturnShippingStatusStringEnum.AWAITING_PAYMENT:
      return `
        color: ${variant === BadgeVariantsEnum.Text ? theme.colors.red : theme.colors.white};
        background-color: ${
          variant === BadgeVariantsEnum.Text ? theme.colors.transparent : theme.colors.red
        };
        `;
    // TODO: Enums have duplicate values across Enum types
    // eslint-disable-next-line no-duplicate-case
    case ClaimStatus.HAS_MATCHES:
    case PartnerClaimStatusEnum.PENDING_APPROVAL:
    case PartnerItemStatusEnum.MATCHED:
    case !isUser && PartnerReturnShippingStatusStringEnum.IN_TRANSIT:
      return `
        color: ${variant === BadgeVariantsEnum.Text ? theme.colors.gold : theme.colors.white};
        background-color: ${
          variant === BadgeVariantsEnum.Text ? theme.colors.transparent : theme.colors.gold
        };
      `;

    case ClaimStatusStrings.MATCHED:
    case ClaimStatus.MATCHED:
    // eslint-disable-next-line no-duplicate-case, no-fallthrough
    case PartnerClaimStatusEnum.PENDING_APPROVAL:
    // eslint-disable-next-line no-duplicate-case, no-fallthrough
    case PartnerItemStatusEnum.MATCHED:
    case PartnerReturnPickupStatusStringEnum.READY_FOR_PICKUP:
    case PartnerReturnShippingStatusStringEnum.READY_TO_PACK:
    case !isUser && PartnerReturnShippingStatusStringEnum.PACKAGED:
    case ReturnStatus.PENDING:
    case isUser && ClaimStatus.COMPLETED:
    case isUser && ClaimStatusStrings.RETURNED:
    case isUser && PartnerReturnShippingStatusStringEnum.DELIVERED:
      return `
        color: ${
          variant === BadgeVariantsEnum.Text ? theme.colors.successGreen : theme.colors.white
        };
        background-color: ${
          variant === BadgeVariantsEnum.Text ? theme.colors.transparent : theme.colors.successGreen
        };
      `;
    case ClaimStatusStrings.RETURNED:
    case PartnerClaimStatusEnum.RETURNED:
    case PartnerItemStatusEnum.UNCLAIMED:
    case PartnerItemStatusEnum.RETURNED:
    case PartnerReturnPickupStatusStringEnum.RETURNED:
    case PartnerReturnShippingStatusStringEnum.DELIVERED:
      return `
        color: ${
          variant === BadgeVariantsEnum.Text || variant === BadgeVariantsEnum.Outlined
            ? theme.colors.gray1
            : theme.colors.white
        };
        background-color: ${
          variant === BadgeVariantsEnum.Text || variant === BadgeVariantsEnum.Outlined
            ? theme.colors.transparent
            : theme.colors.gray1
        };
        border: ${
          variant === BadgeVariantsEnum.Outlined ? "1px solid " + theme.colors.gray8 : "none"
        };
      `;
    case ShipmentStatus.PROCESSING:
    // eslint-disable-next-line no-duplicate-case, no-fallthrough
    case PartnerItemStatusEnum.MATCHED:
    case PartnerReturnShippingStatusStringEnum.PROCESSING:
    case PartnerReturnShippingStatusStringEnum.PACKAGED:
    case PartnerReturnShippingStatusStringEnum.IN_TRANSIT:
    case PartnerReturnShippingStatusStringEnum.IN_RETURN:
      return `
        color: ${variant === BadgeVariantsEnum.Outlined ? theme.colors.yellow : theme.colors.gray1};
        background-color: ${
          variant === BadgeVariantsEnum.Outlined ? theme.colors.transparent : theme.colors.yellow
        };
      `;
    case ClaimStatus.CANCELED:
    case PartnerClaimStatusEnum.CANCELED:
      return `
          color: ${
            variant === BadgeVariantsEnum.Outlined ? theme.colors.gray1 : theme.colors.white
          };
          background-color: ${
            variant === BadgeVariantsEnum.Outlined
              ? theme.colors.transparent
              : theme.ui.colors.gray300
          };
          border: ${
            variant === BadgeVariantsEnum.Outlined ? "1px solid " + theme.colors.gray8 : "none"
          };
        `;
    case ClaimStatusStrings.EXPIRED:
    case ClaimStatus.EXPIRED:
    case PartnerClaimStatusEnum.EXPIRED:
      return `
          color: ${
            variant === BadgeVariantsEnum.Outlined ? theme.colors.gray1 : theme.colors.white
          };
          background-color: ${theme.ui.colors.gray300};
          border: ${
            variant === BadgeVariantsEnum.Outlined ? "1px solid " + theme.colors.gray8 : "none"
          };
        `;
    default:
      return `
      color: ${theme.colors.gray1};
      background-color: ${theme.colors.gray6};
    `;
  }
};
