import { format, formatISO, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

// Context
import { useAuthState } from "../../context/auth";

// Components
import BlankMessage from "../../components/BlankMessage";

// Queries
// import queryShipmentsByPartnerSlug from "../shipmentsByPartnerSlugQuery";

// Assets
import astronautImg from "../../assets/astronaut.svg";
import caretDownImg from "../../assets/caret-down-white.png";
import shippingImg from "../../assets/shipping.svg";

// Helpers
import { dateOptionsFromDate } from "../../helpers/date";

const { Select } = Form;

const ITEM_IMAGE_SIZE = "s70x70";

const formatCurrency = amount => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

// Date utilities
const displayMonth = date => format(date, "LLLL yyyy");
const dateValue = date => formatISO(date, { representation: "date" });
const itemDisplayDate = date => format(date, "MMM dd yyyy");

const StyledSearchFilterRow = styled(Row)`
  background-color: ${props => props.theme.colors.deltaBlue};
`;

const StyledSelect = styled(Select)`
  background-color: ${props => props.theme.colors.deltaBlueSecondary};
  border-color: ${props => props.theme.colors.deltaBlueSecondary};
  border-radius: 48px;
  color: ${props => props.theme.colors.white};
  background-image: url(${caretDownImg});
`;

const StyledItemRow = styled(Row)`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  }
`;

const LightLabel = styled.label`
  display: block;
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 500;
  line-height: 2.571;
`;

const DarkLabel = styled.div`
  font-weight: 600;
  color: ${props => props.color || props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.sm};
  text-transform: capitalize;
  line-height: 2.571;
`;

const StyledImage = styled.div`
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center;
  object-fit: cover;
`;

const Header = styled.div`
  background-color: ${props => props.theme.colors.gray6};
`;

// I was not sure how to handle the colspan having "auto sizing columns"
const HeaderTitleRow = styled(Row)`
  background-color: ${props => props.theme.colors.gray6};
  align-self: end;
`;

const TotalAmountLabel = styled.label`
  display: block;
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.xxxl};
  font-weight: 600;
  text-align: right;
`;

const TableTitleLabel = styled.label`
  display: block;
  color: ${props => props.theme.colors.gray1};
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes.lg};
`;

const ShippingTable = ({ date, total, items }) => {
  return (
    <>
      <Header className="mx-0">
        <Row className="px-5 py-5 align-items-center">
          <Col>
            <TableTitleLabel>{date}</TableTitleLabel>
          </Col>
          <Col>
            <TotalAmountLabel>{total}</TotalAmountLabel>
          </Col>
        </Row>
        <HeaderTitleRow className="ps-1 mx-0">
          <Col lg={3} className="border-end">
            <LightLabel>Lost item details</LightLabel>
          </Col>
          <Col className="row mx-0">
            <Col className="border-end">
              <LightLabel>Category</LightLabel>
            </Col>
            <Col className="border-end">
              <LightLabel>Shipped date</LightLabel>
            </Col>
            <Col className="border-end">
              <LightLabel>Cost</LightLabel>
            </Col>
            <Col className="border-end">
              <LightLabel>Claim Number</LightLabel>
            </Col>
          </Col>
          <Col lg={3}>
            <LightLabel>Shipped to</LightLabel>
          </Col>
        </HeaderTitleRow>
      </Header>

      {items.map((item, i) => (
        <ShippingRow {...item} key={i} />
      ))}
    </>
  );
};

const ShippingRow = ({ image, name, category, shippedDate, cost, claimId, shippingAddress }) => {
  return (
    <StyledItemRow className="py-3 ps-1 mx-0 align-items-center mx-0">
      <Col lg={3}>
        <Row className="align-items-center">
          <Col sm={"auto"}>
            <StyledImage
              style={{ backgroundImage: `url(${image})` }}
              className="border rounded-1 shadow-sm"
            >
              <span role="img" aria-label={`Image ${name}`} />
            </StyledImage>
          </Col>
          <Col className="border-end">
            <DarkLabel>{name}</DarkLabel>
          </Col>
        </Row>
      </Col>
      <Col className="row mx-0">
        <Col className="border-end">
          <DarkLabel>{category}</DarkLabel>
        </Col>
        <Col className="border-end">
          <DarkLabel>{shippedDate}</DarkLabel>
        </Col>
        <Col className="border-end">
          <DarkLabel>{cost}</DarkLabel>
        </Col>
        <Col className="border-end">
          <LightLabel>{claimId}</LightLabel>
        </Col>
      </Col>
      <Col lg={3}>
        <DarkLabel>{shippingAddress}</DarkLabel>
      </Col>
    </StyledItemRow>
  );
};

const ErrorPlaceholder = () => {
  return (
    <BlankMessage alt="folder Boomerang documents" image={shippingImg} title="No data yet">
      We haven’t picked any items from your location. Once we do, they will show up here.
    </BlankMessage>
  );
};

const NoResultsPlaceholder = () => {
  return (
    <BlankMessage alt="Boomerang astronaut" image={astronautImg} title="No data">
      Oops, something went wrong
      <br />
      Try resetting filters.
    </BlankMessage>
  );
};

const DateFilterRow = ({ selectedDate, dateOptions, onChange }) => {
  return (
    <StyledSearchFilterRow className="mx-0 p-4 pt-0">
      <Col sm="auto">
        <StyledSelect
          aria-label={displayMonth(selectedDate)}
          onChange={e => {
            onChange(e.target.value);
          }}
          value={displayMonth(selectedDate)}
        >
          <option value={dateValue(selectedDate)}>{displayMonth(selectedDate)}</option>
          {dateOptions.map(option => {
            return (
              <option key={displayMonth(option)} value={dateValue(option)}>
                {displayMonth(option)}
              </option>
            );
          })}
        </StyledSelect>
      </Col>
    </StyledSearchFilterRow>
  );
};

const DEFAULT_FILTERS = {
  dates: [new Date()],
  selectedDate: new Date(),
  sinceDate: new Date(),
};

const ShippingCosts = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [shipments, setShipments] = useState(null);

  const { user } = useAuthState();
  const queryResults = undefined; /*useQuery(queryShipmentsByPartnerSlug, {
    variables: {
      partnerSlug: user?.currentPartner,
      startDate: dateValue(startOfMonth(filters.selectedDate)),
      endDate: dateValue(endOfMonth(filters.selectedDate)),
    },
  });*/

  const { loading, error, data } = queryResults;

  useEffect(() => {
    const createdDate = data?.partner.createdDt || filters.sinceDate;
    const dates = dateOptionsFromDate(parseISO(createdDate));
    setFilters({ ...filters, dates: dates, sinceDate: createdDate });
    setShipments(data?.partner.shipments);
  }, [user?.currentPartner, data?.partner?.createdDt, filters.selectedDate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <ErrorPlaceholder />;
  }

  const filterDates = filters.dates;
  const amount = formatCurrency(shipments?.totalCost || 0);
  const edges = shipments?.edges || [];
  const hasItems = edges.length > 0;

  const handleDateChange = updatedValue => {
    let newDate = parseISO(updatedValue);
    setFilters({ ...filters, selectedDate: newDate });
  };

  if (hasItems === false) {
    return (
      <>
        <DateFilterRow
          selectedDate={filters.selectedDate}
          dateOptions={filterDates}
          onChange={handleDateChange}
        />
        <NoResultsPlaceholder />
      </>
    );
  }

  // Map to View Model
  const shippedItems = edges.map(edge => {
    const claim = edge.node.match.claim;
    const address = claim.mailingAddress;
    return {
      image: claim.images[0]?.sizes[ITEM_IMAGE_SIZE]?.url,
      name: claim.name,
      category: claim.primaryCategory.name,
      shippedDate: itemDisplayDate(parseISO(edge.node.sentDt)),
      cost: formatCurrency(edge.node.cost),
      claimId: claim.id,
      shippingAddress: address ? `${address?.city}, ${address?.countryCode}` : "",
    };
  });

  return (
    <>
      <DateFilterRow
        selectedDate={filters.selectedDate}
        dateOptions={filterDates}
        onChange={handleDateChange}
      />
      <ShippingTable
        items={shippedItems}
        date={displayMonth(filters.selectedDate)}
        total={amount}
      />
    </>
  );
};

export default ShippingCosts;
