import { PartnerItemsCountByStatusResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";
import { OptionalReturnsCountQueryFilters, removeAllFromFilters } from "../partnerReturnCounts";

export default ({
  partnerId,
  queryObject,
}: {
  partnerId: string;
  queryObject: OptionalReturnsCountQueryFilters;
}): Promise<PartnerItemsCountByStatusResponse> => {
  const formattedQuery = removeAllFromFilters(queryObject);
  const urlSearchParams = objectToQueryString(formattedQuery);
  urlSearchParams.delete("custom_status");

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/returns/count-by-custom-status/?${urlSearchParams.toString()}`,
  });
};
