import React from "react";
import { ArrowRight } from "../../../assets/icons/icons";
import theme from "../../../theme";
import { Category as CategoryType, PartnerItem } from "../../../types";
import { NoPhotoImagePlaceholder } from "../../ImageCarousel";
import { Category, ItemLink, Name, ProductImage, Wrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  item: PartnerItem & { match: string };
  categories: CategoryType[];
};

export default function ReturnsItem({ item, categories, ...props }: Props) {
  return (
    <Wrapper {...props}>
      <div className="d-flex flex-row gap-25" data-testid="itemRow">
        {item.images.length === 0 ? (
          <NoPhotoImagePlaceholder square width="100px" height="100px" />
        ) : (
          <ProductImage src={item.images[0].image} alt={item.name} width="100px" height="100px" />
        )}

        <div className="d-flex flex-column gap-25">
          {categories.length > 0 && (
            <Category>{categories.find(({ id }) => id === item.category)?.name}</Category>
          )}

          <Name>{item.name}</Name>
        </div>
      </div>

      <ItemLink href={`/partner/match/${item.match}/return`}>
        <ArrowRight
          color={theme.colors.gray3}
          className="ms-auto"
          accessibilityTitle={`Go to ${item.name}`}
          titleId={`ArrowRight${item.name.replace(/\s/g, "")}Title`}
        />
      </ItemLink>
    </Wrapper>
  );
}
