import styled from "styled-components";

export const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  border: 1px solid ${props => props.theme.ui.colors.gray500};
  outline: 1px solid transparent;
  border-radius: 16px;
  padding-block: 20px;
  height: 100%;
  user-select: none;
`;

export const Wrapper = styled.div`
  position: relative;

  ${RadioInput}:checked + ${Content} {
    border-color: ${props => props.theme.ui.colors.primary500};
    background-color: ${props => props.theme.ui.colors.primary300};
    border-width: 3px;
    border-radius: 16px;
  }

  ${RadioInput}:hover + ${Content} {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      border-color: ${props => props.theme.ui.colors.primary500};
      background-color: ${props => props.theme.ui.colors.primary300};
      border-width: 1px;
    }
  }

  ${RadioInput}:checked:hover + ${Content} {
    border-width: 3px;
  }

  &.active ${Content}, ${RadioInput}:active {
    transform: scale(0.925);
  }

  ${RadioInput}:focus-visible + ${Content} {
    outline: none;
  }

  ${RadioInput}:focus-visible + ${Content}::after {
    content: "";
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    background-color: transparent;
    position: absolute;
    top: -7px;
    left: -7px;
    border-color: ${props => props.theme.colors.primaryBlue};
    border-style: solid;
    border-width: 2px;
    border-radius: inherit;
  }
`;
