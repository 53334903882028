import React from "react";
import { LocationPin } from "../../assets/icons/icons";
import { NameWrapper, SubTextWrapper } from "./styled";

const LocationElement = (props: { location: string }) => {
  return (
    <SubTextWrapper className="mb-1">
      <LocationPin accessibilityTitle="Lost location" titleId="ClaimPillLocationPinTitle" />
      {props.location}
    </SubTextWrapper>
  );
};

export const LocationNameDateSnapshot = (props: {
  location: string;
  name: string;
  lost_date: string;
  className?: string;
}) => {
  return (
    <div className={props.className ? props.className : "ms-1"}>
      <LocationElement location={props.location} />
      <NameWrapper>{props.name}</NameWrapper>
      <SubTextWrapper>{props.lost_date}</SubTextWrapper>
    </div>
  );
};
