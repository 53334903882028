import { ItemStatus, ReturnModeEnum, ReturnStatus } from "../../types";
import { InventoryTab, InventoryTabsName, ReturnsTab, ReturnsTabsName } from "./types";

export const InventoryArchiveTabs: InventoryTab[] = [
  {
    name: InventoryTabsName.UNCLAIMED,
    statuses: [ItemStatus.UNCLAIMED],
  },
];

export const getReturnsTabs = (returnModes: ReturnModeEnum[]): ReturnsTab[] => {
  const recoveryMethods: ReturnModeEnum[] = [ReturnModeEnum.SHIPPING, ReturnModeEnum.PICKUP];

  const tabs: ReturnsTab[] = [
    {
      name: ReturnsTabsName.ALL,
      recoveryMethods,
      statuses: [ReturnStatus.PENDING],
    },
  ];
  if (returnModes.includes(ReturnModeEnum.SHIPPING)) {
    tabs.push({
      name: ReturnsTabsName.RETURN_BY_MAIL,
      recoveryMethods: [ReturnModeEnum.SHIPPING],
      statuses: [ReturnStatus.PENDING],
    });
  }
  if (returnModes.includes(ReturnModeEnum.PICKUP)) {
    tabs.push({
      name: ReturnsTabsName.RETURN_IN_PERSON,
      recoveryMethods: [ReturnModeEnum.PICKUP],
      statuses: [ReturnStatus.PENDING],
    });
  }
  return tabs;
};

export const getArchiveReturnsTabs = (returnModes: ReturnModeEnum[]): ReturnsTab[] => {
  const tabs: ReturnsTab[] = [
    {
      name: ReturnsTabsName.ARCHIVE,
      recoveryMethods: returnModes,
      statuses: [ReturnStatus.COMPLETED],
    },
  ];
  return tabs;
};
