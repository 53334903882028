import styled from "styled-components";
import theme from "../../theme";

export const StyledNav = styled.div<{ hamburgerOpen: boolean }>`
  position: fixed;
  z-index: ${props => props.theme.zIndexes.header};
  width: 100%;
  transition: all 0.4s ease-in-out;
  height: ${props => (props.hamburgerOpen ? "100vh" : "auto")};
  background-color: ${props =>
    props.hamburgerOpen ? "rgba(0, 0, 0, 0.4)" : props.theme.ui.colors.transparent};
`;

export const NavigationWrapper = styled.nav`
  border-bottom: 1px solid ${theme.ui.colors.gray500};
  background-color: ${props => props.theme.ui.colors.white};
`;

export const NavigationBar = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndexes.header};
  max-width: ${props => props.theme.ui.widths.contentMax};
  width: 100%;
  margin: 0 auto;
  height: ${props => props.theme.ui.heights.navBase};
  background-color: ${props => props.theme.ui.colors.white};

  > div:first-child {
    margin: 0 auto;
    width: 100%;
    max-width: ${props => props.theme.ui.widths.contentMax};
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    height: ${props => props.theme.ui.heights.navLg};
  }
`;

export const DashboardNavItems = styled.ul`
  margin-bottom: 0 !important;
  gap: 16px;

  li {
    display: flex;
    align-items: center;

    a {
      border-radius: ${props => props.theme.ui.borderRadius.navItem};

      &.primary-text {
        color: ${props => props.theme.ui.colors.primary500};

        &:hover {
          color: ${props => props.theme.ui.colors.primary600};
        }
      }

      color: ${props => props.theme.ui.colors.gray600};
      font-weight: ${props => props.theme.ui.fontWeights.bold};
      font-family: ${props => props.theme.ui.fontFamilies.default};

      &:hover {
        color: ${props => props.theme.ui.colors.primary700};
        text-decoration: none;
      }
    }
  }
`;

export const NavBarDropdown = styled.div`
  transform: translateY(-100%);
  transition: all 0.4s ease;
  background-color: ${props => props.theme.ui.colors.white};

  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: ${props => props.theme.zIndexes.header - 10};

  &.open {
    opacity: 1;
    transform: translateY(0);
  }

  > div:first-child {
    border-bottom: 1px solid ${theme.ui.colors.gray500};
  }

  ul {
    margin-bottom: 0;
    font-family: ${props => props.theme.ui.fontFamilies.default};

    li > a {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }
      display: flex;
      color: ${props => props.theme.ui.colors.gray600};
      font-weight: ${props => props.theme.ui.fontWeights.bold};
      font-family: ${props => props.theme.ui.fontFamilies.default};
      line-height: 1.5;

      &:hover {
        background-color: ${props => props.theme.ui.colors.primary300};
      }

      span {
        width: 100%;
      }
    }
  }
`;

export const VerticalDivider = styled.div`
  color: ${props => props.theme.ui.colors.gray500};
  width: 1.5px;
`;

export const CoBrandLogo = styled.img`
  height: 24px;
  width: auto;
  transition:
    height,
    width 200ms ease-in;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xs}) {
    height: 28px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    height: 32px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xxl}) {
    height: 42px;
  }
`;
