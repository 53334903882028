import { isMdQuery } from "client/helpers/mediaQuery";
import theme from "client/theme";
import { Button, Typography } from "client/ui-components";
import React from "react";
import { PartnerClaim } from "../../types";
import { NoPhotoImagePlaceholder } from "../ImageCarousel";
import { InfoWrapper } from "../ItemSnapshot/styles";
import { ClaimsImageThumbnail, MatchesContainer, StyledChevronRight, Wrapper } from "./styles";

interface MatchesCardProps {
  claim: PartnerClaim;
  cardKey: number;
  categoryName: string;
  onClick: () => void;
  text?: string;
}

const MatchesCard: React.FC<MatchesCardProps> = props => {
  const { claim, cardKey, onClick, text } = props;
  const isMd = isMdQuery();

  return (
    <MatchesContainer
      className={`${isMd ? "" : "mb-2 mx-2 card rounded-3 border-1"}`}
      key={cardKey}
      onClick={() => {
        !isMd && onClick();
      }}
      data-testid={`matches-row-${cardKey}`}
    >
      <Wrapper
        className={`g-0 d-flex align-items-stretch p-35 pb-md-3 ${isMd ? "px-45" : ""}`}
        data-testid="matchRows"
      >
        <div className="px-0 col-auto">
          {claim.images && claim.images.length > 0 ? (
            <ClaimsImageThumbnail url={`${claim.images[0]?.image}`} />
          ) : (
            <NoPhotoImagePlaceholder
              className="w-100 d-flex align-items-stretch"
              width="100px"
              height="100px"
              square
            >
              <span role="img" aria-label={`No image placeholder image`} />
            </NoPhotoImagePlaceholder>
          )}
        </div>
        <InfoWrapper className={`mx-0 w-100 align-items-stretch pe-0`}>
          <div className="px-0 d-flex align-self-center">
            <Typography
              className="ms-25 mb-0"
              as="p"
              fontSize="16px"
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              lineHeight={1.5}
            >
              {claim.name}
            </Typography>
          </div>
          {isMd ? (
            <Button
              style={{ minWidth: "164px" }}
              className="align-self-center justify-self-end ms-auto"
              data-testid="reviewMatchButton"
              size="xl"
              onClick={onClick}
              aria-label="Review match"
            >
              {text ? text : "Review matches"}
            </Button>
          ) : (
            <StyledChevronRight
              className="justify-self-end ms-auto"
              accessibilityTitle="Active found item or claim"
              titleId="ActiveItemSnapshotTitle"
              width={24}
              height={24}
              fontWeight={700}
            />
          )}
        </InfoWrapper>
      </Wrapper>
    </MatchesContainer>
  );
};

export default MatchesCard;
