import styled from "styled-components";

export const LogosWrapper = styled.div`
  align-items: center;
  gap: 0.5rem !important;
  @media (min-width: 528px) and (max-width: ${props => props.theme.breakpoints.sm}) {
    ${/* Corrects the gap from div with w-100 class*/ ""}
    gap: .25rem !important;
  }
  img {
    width: 115.83px;
    ${/* Value from Figma*/ ""}
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 172.57px;
      ${/* Value from Figma*/ ""}
    }
  }
  div.w-100 {
    display: block;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
    @media (min-width: 528px) {
      ${/* Where the logos break to a new line*/ ""}
      display:block;
    }
    @media (min-width: 988px) {
      ${/* Where the logos break to a new line*/ ""}
      display: none;
    }
  }
`;
