import LockIcon from "client/assets/lock_icon.svg";
import { isXsQuery } from "client/helpers/mediaQuery";
import theme from "client/theme";
import React from "react";
import Button from "../Button";
import Heading from "../Heading";
import Text from "../Text";
import Tooltip from "../Tooltip";
import {
  ContentContainer,
  IconContainer,
  SuggestedItemWrapper,
  TextContainer,
  TitleContainer,
} from "./styles";

type SuggestedListItemProps = {
  count?: number;
  name: string;
  link: string;
  subtitle?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  tooltip?: React.ReactNode;
};

export default function SuggestedItem({
  count,
  name,
  link,
  subtitle,
  icon,
  disabled,
  tooltip,
}: SuggestedListItemProps) {
  const isXs = isXsQuery();

  if (!isXs)
    return (
      <SuggestedItemWrapper>
        <TitleContainer>
          <Heading variant="h5" as="h5">
            {name}
          </Heading>
          {count !== undefined && (
            <Text color={theme.ui.colors.gray600} style={{ textWrap: "nowrap" }}>
              ({count} Total)
            </Text>
          )}
          {tooltip && <Tooltip content={tooltip} />}
        </TitleContainer>
        <Button
          href={link}
          aria-label="View list"
          size={isXs ? "md" : "sm"}
          disabled={!count || disabled}
          variant={disabled ? "outline" : "primary"}
          style={{ width: 80, flexShrink: 0 }}
        >
          {disabled ? <img src={LockIcon} alt="Locked" /> : "View list"}
        </Button>
      </SuggestedItemWrapper>
    );

  return (
    <SuggestedItemWrapper>
      <ContentContainer>
        <IconContainer>{icon}</IconContainer>
        <TextContainer>
          <TitleContainer>
            <Heading variant="h5" as="h5">
              {name}
            </Heading>
            {count !== undefined && (
              <Text color={theme.ui.colors.gray600} style={{ textWrap: "nowrap" }}>
                ({count} Total)
              </Text>
            )}
            {tooltip && <Tooltip content={tooltip} />}
          </TitleContainer>
          {subtitle && <Text color={theme.ui.colors.gray600}>{subtitle}</Text>}
        </TextContainer>
      </ContentContainer>
      <Button
        href={link}
        aria-label="View list"
        size={isXs ? "md" : "sm"}
        disabled={!count || disabled}
        variant={disabled ? "outline" : "primary"}
        style={{ width: 100, flexShrink: 0 }}
      >
        {disabled ? <img src={LockIcon} alt="Locked" /> : "View list"}
      </Button>
    </SuggestedItemWrapper>
  );
}
