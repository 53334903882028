import React from "react";
import { useAuthState } from "../../../context/auth";
import theme from "../../../theme";
import Heading from "../../Heading";
import { Link } from "../../index";
import { FooterSection as FooterSectionProps } from "../constants";

export default function FooterSection({ title, links }: FooterSectionProps) {
  const { user } = useAuthState();

  return (
    <div className="col d-flex flex-column">
      <Heading variant="h6" className="mt-0 text-uppercase mb-3" color={theme.ui.colors.gray500}>
        {title}
      </Heading>

      {links.map(
        ({ text, href, blank, requiresSession, partnersOnly }) =>
          ((requiresSession === true &&
            partnersOnly === false &&
            user?.id !== undefined &&
            user?.partners === undefined) ||
            (requiresSession === true &&
              partnersOnly === true &&
              user?.id !== undefined &&
              user?.partners !== undefined) ||
            (requiresSession === false && partnersOnly === true && user?.id === undefined) ||
            (requiresSession === false && user?.id === undefined) ||
            requiresSession === undefined) && (
            <Link
              key={text}
              {...(blank ? { target: "_blank" } : {})}
              className="fw-bold"
              variant="dark"
              href={href}
              data-testid={`${text.toLowerCase().replace(/\s/g, "_")}_link`}
            >
              {text}
            </Link>
          ),
      )}
    </div>
  );
}
