import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin: 64px 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 64px 40px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    margin: 0 90.97px 96px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.xl}) {
    margin: 0 40px 96px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.xxl}) {
    margin: 96px 84px;
  }
`;

export const FeatureCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  gap: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.xl}) {
    justify-content: flex-start;
  }
`;

export const MediaWrapper = styled.div`
  display: flex;
  height: 250px !important;
  width: 250px;
  align-items: center;
  justify-content: center;

  img {
    width: 66%;
    height: auto;
  }
`;

export const CopyWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;
