import React from "react";

import { HamburgerLine, StyledButton } from "./styles";

type HamburgerProps = React.PropsWithoutRef<JSX.IntrinsicElements["button"]> & {
  isOpen: boolean;
};

const Hamburger: React.FC<HamburgerProps> = ({ isOpen, ...props }) => {
  return (
    <StyledButton
      {...props}
      onClick={props.onClick}
      aria-label={isOpen ? "Close menu" : "Open menu"}
      aria-pressed={isOpen}
    >
      <div className={`row mx-0 align-content-center ${isOpen && "open"}`}>
        <HamburgerLine />
      </div>
    </StyledButton>
  );
};

export default Hamburger;
