import styled from "styled-components";

export const InfoWrapper = styled.div`
  min-width: 0;
  display: flex;
  text-align: left;
  max-width: 150px;
  padding-left: 12px;
  flex-direction: column;
  height: 100px;
  gap: 4px;
  justify-content: flex-start;

  & .badge {
    line-height: 20px;
    font-size: 14px;
    font-family: ${props => props.theme.fontFamilies.paragraph} !important;
    font-weight: 400;
    color: ${props => props.theme.ui.colors.primary700};
  }
`;

export const StyledLabel = styled.div`
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 1.33;
`;

export const StyledListItem = styled.div<{
  cursorPointer?: boolean;
  highlight?: boolean;
}>`
  cursor: ${props => (props.cursorPointer ? "pointer" : "inherit")};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  overflow: auto;
  &.active {
    background-color: ${props => props.theme.colors.lightBlue}80;
    box-shadow: inset 7px 0 0 0 ${props => props.theme.colors.primaryBlue};
    cursor: default;
  }
  &:hover {
    ${props => (props.highlight ? "background-color: " + props.theme.colors.lightBlue + "80" : "")};
  }
  font-family: ${props => props.theme.fontFamilies.paragraph} !important;
`;

export const LargeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 16px 0;
  font-family: ${props => props.theme.fontFamilies.paragraph} !important;
  @media (max-width: 500px) {
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;
  font-family: ${props => props.theme.fontFamilies.paragraph} !important;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const Subtitle = styled.div`
  color: ${props => props.theme.ui.colors.primary700};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 16.8px;
`;

export const SubtitleDescription = styled.div`
  color: ${props => props.theme.ui.colors.gray600};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 20px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes.sm};
  min-height: 256px;

  & .badge {
    line-height: 20px;
    font-size: 14px;
    font-family: ${props => props.theme.fontFamilies.paragraph} !important;
    font-weight: 400;
    color: ${props => props.theme.ui.colors.primary700};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 700;
  line-height: "24px";
  color: ${props => props.theme.ui.colors.primary700};

  @media (max-width: 768px) {
    & .badge {
      margin-bottom: -6px;
    }
  }
`;
