import styled from "styled-components";
import Link from "../../../Link";

export const DropdownButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 2px solid transparent;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-color: #f8f9fa;
  transition:
    background-color,
    border-color 200ms ease-in;

  &.open {
    background-color: #f3f9ff;
  }

  &:hover {
    border-color: #0772e4;
  }

  &:after {
    content: none;
  }
`;

export const DropdownMenu = styled.div`
  right: 0;
  left: auto;
  border: none;
  width: 375px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 8px;
`;

export const LinksWrapper = styled.div`
  border-bottom: 1px solid #dce1e8;
`;

export const DropdownItem = styled(Link)`
  &:hover {
    background-color: ${props => props.theme.ui.colors.primary300};
  }

  svg {
    margin-right: 0.5rem;
  }
`;
