import React, { forwardRef } from "react";
import { Stage, StyledButtonComponent } from "./styles";

import classNames from "classnames";
import { BoomerangArrowRight } from "../../assets/icons/icons";
import theme from "../../theme/index";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { ButtonModes, ButtonProps } from "./Button.types";

const Button = forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const {
    ariaControls,
    ariaLabel,
    ariaSelected,
    as,
    className,
    disabled,
    form,
    icon,
    loading,
    loadingText = "Loading",
    mode,
    onClick,
    role,
    tabIndex,
    text,
    textColor,
    type,
    ...rest
  } = props;

  return (
    <StyledButtonComponent
      {...rest}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      aria-selected={ariaSelected}
      as={as}
      className={classNames(
        className,
        `d-flex align-items-center justify-content-center no-decorate btn btn-${mode}`,
        {
          [`btn-color-${textColor}`]: textColor,
          disabled: !!disabled,
        },
      )}
      data-testid={props["data-testid"] || "button"}
      disabled={disabled || loading}
      form={form}
      loading={loading ? "true" : undefined}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === KEYBOARD_KEY.ENTER) {
          e.preventDefault();
          onClick && onClick();
        }
      }}
      ref={ref}
      role={role}
      tabIndex={tabIndex ? tabIndex : 0}
      type={type}
    >
      {!loading && !!icon && icon} {!loading ? text : loadingText}
      {mode !== ButtonModes.open && !!loading && (
        <Stage color={disabled ? theme.colors.primaryBlue : theme.colors.white} className="ms-4">
          <div className="dot-fire" />
        </Stage>
      )}
      {mode !== ButtonModes.open && !!loading && (
        <BoomerangArrowRight
          className="ms-3 tremble"
          color="currentColor"
          accessibilityTitle="Loading"
          ariaHidden={true}
          titleId="ButtonLoadingStateIconTitle"
        />
      )}
    </StyledButtonComponent>
  );
});

export default Button;
