import { fetchApiData } from "client/utils/fetchApiData";
import { CancelClaimRequest } from "../../../../types";
import { getCommonHeaders } from "../../../../utils/headers";

export default (claimId: string, cancelClaimReq: CancelClaimRequest): Promise<null> =>
  fetchApiData({
    url: `/api/user/claims/${claimId}/cancel/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(cancelClaimReq),
    },
  });
