import { ApiHandlerRes, useApiHandler } from ".";
import { GetItemCountByCategoryRequest, PartnerCountResponse } from "../types";
import fetchData from "../utils/fetchData";
import getQueryString from "../utils/getQueryString";

const getItemCountByCategory = async (
  itemCountReq: GetItemCountByCategoryRequest,
): Promise<PartnerCountResponse | null> => {
  const queryString = itemCountReq.filters ? getQueryString(itemCountReq.filters) : "";
  return fetchData(
    `/api/partner/partners/${itemCountReq.partnerId}/items/count-by-category/${queryString}`,
  );
};

export const useGetItemCountByCategory = (): ApiHandlerRes<
  GetItemCountByCategoryRequest,
  PartnerCountResponse | null
> => useApiHandler(getItemCountByCategory);
