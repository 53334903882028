import { Text } from "client/ui-components";
import React, { useState } from "react";
import { DownChevronSolid, UpChevronSolid } from "../../assets/icons/icons";
import { DetailsWrapper, Summary } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["details"]> & {
  title: string;
  titleDetail?: React.ReactNode;
};

export default function Details({ className, children, title, titleDetail, ...props }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <DetailsWrapper
      {...props}
      open={isOpen}
      onToggle={() => setIsOpen(prev => !prev)}
      className={`${className ? className : ""} p-35 px-sm-45`}
    >
      <Summary className="d-flex align-items-center justify-content-between gap-2 flex-row flex-wrap">
        <div className="d-flex align-items-center">
          <Text fontWeight={700}>
            {title}

            {isOpen ? (
              <UpChevronSolid accessibilityTitle="details" titleId="details" />
            ) : (
              <DownChevronSolid accessibilityTitle="details" titleId="details" />
            )}
          </Text>
        </div>

        <Text fontWeight={700}>{titleDetail}</Text>
      </Summary>

      {children}
    </DetailsWrapper>
  );
}
