import { AcceptInviteRequest, AcceptInviteResponse, InviteSearchResponse } from "../types/actions";
import fetchData from "../utils/fetchData";
import { getCommonHeaders } from "../utils/headers";
import { ApiHandlerRes, useApiHandler } from "./";

const getInviteForPartner = async (external_id: string): Promise<InviteSearchResponse | null> => {
  return fetchData(`/api/partner/invites/${external_id}/`);
};

const acceptInvite = (
  acceptInviteReq: AcceptInviteRequest,
): Promise<AcceptInviteResponse | null> => {
  return fetchData(`/api/partner/invites/${String(acceptInviteReq.external_id)}/accept/`, {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(acceptInviteReq.invite),
  });
};

export const useGetInviteForPartner = (): ApiHandlerRes<string, InviteSearchResponse | null> =>
  useApiHandler(getInviteForPartner);

export const useAcceptPartnerInvite = (): ApiHandlerRes<
  AcceptInviteRequest,
  AcceptInviteResponse | null
> => useApiHandler<AcceptInviteRequest, AcceptInviteResponse | null>(acceptInvite);
