import { ButtonTypes } from "client/components/Button/Button.types";
import { DropdownAlignment } from "client/components/Dropdowns/types";
import { useClickAway } from "client/hooks";
import React, { useRef, useState } from "react";
import { Divider, DropdownMenuWrapper, ListItem, StyledButton } from "./styles";

export type DropdownListItem = {
  color?: string;
  divider?: boolean;
  label: React.ReactNode;
  onClick: () => void;
};

interface ActionDropdownProps {
  options: DropdownListItem[];
  isHeader?: boolean;
}

const DropdownSvg = ({ show }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      style={{ rotate: show ? "180deg" : "0deg", transition: "all 0.3s" }}
    >
      <path
        d="M6.5 9L12.5 15L18.5 9"
        stroke="#00174A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ActionDropdown = (props: ActionDropdownProps) => {
  const { options, isHeader } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<any>();
  useClickAway(dropdownRef, () => {
    setDropdownOpen(false);
  });

  return (
    <div
      className={`dropdown ${dropdownOpen ? " open" : ""} ${isHeader ? "" : "w-100"}`}
      ref={dropdownRef}
    >
      <StyledButton
        type={ButtonTypes.submit}
        disabled={options.length === 0}
        loading={false}
        aria-label="Actions"
        variant="outline"
        icon={isHeader && <DropdownSvg show={dropdownOpen} />}
        iconPosition="end"
        size="2xl"
        style={{ width: "100%" }}
        className="toggler"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        Actions
      </StyledButton>

      <DropdownMenuWrapper
        dropdownAlignment={isHeader ? DropdownAlignment.BOTTOM_END : DropdownAlignment.TOP_START}
        className={`dropdown-menu ${dropdownOpen ? " d-flex" : ""} py-25 `}
      >
        {options.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.divider && i !== 0 && <Divider className="mx-35" />}
              <ListItem
                className="p-35 m-0 dropdown-item"
                onClick={() => {
                  setDropdownOpen(false);
                  item.onClick();
                }}
                textColor={item.color}
                width={"255px"}
                data-testid={item.label}
              >
                {item.label}
              </ListItem>
            </React.Fragment>
          );
        })}
      </DropdownMenuWrapper>
    </div>
  );
};

export default ActionDropdown;
