// Query key factory for all partner-related queries.
//
// Inspired by:
//
// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
// https://tkdodo.eu/blog/the-query-options-api#query-factories

import baseQueries from "./baseQueries";
import claimQueries from "./claimQueries";
import itemQueries from "./itemQueries";
import returnQueries from "./returnQueries";

export default {
  ...baseQueries,
  ...claimQueries,
  ...itemQueries,
  ...returnQueries,
};
