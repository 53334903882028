import { useMediaQuery } from "react-responsive";
import theme from "../theme";

export const isXsQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xs})`,
  });

export const isSmQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.sm})`,
  });

export const isMdQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.md})`,
  });

export const isLgQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`,
  });

export const isXlQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xl})`,
  });

export const isXxlQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xxl})`,
  });

export const isXxlUIQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.ui.breakpoints.xxl})`,
  });

export const isXxxlQuery = () =>
  useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xxxl})`,
  });
