import classNames from "classnames";
import React from "react";

import { Wrapper } from "./styles";

interface BoxProps {
  children: React.ReactNode;
  isClickable?: boolean;
  onClick?: () => void;
  rmvDefaultPaddingMargin?: boolean;
  className?: string;
}

const Box: React.FC<BoxProps> = ({
  children,
  isClickable,
  onClick,
  rmvDefaultPaddingMargin,
  className,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={classNames({
        "cursor-pointer": isClickable,
        "p-35 px-lg-45 mb-35": !rmvDefaultPaddingMargin,
        [`${className}`]: className,
      })}
    >
      {children}
    </Wrapper>
  );
};

export default Box;
