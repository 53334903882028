import ArrowRightTab from "@assets/ui-icons/ArrowRightTab";
import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import { ArchivedLink } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function GoToArchiveLink({ className, ...props }: Props) {
  const { pathname } = useLocation();
  return (
    <div {...props} className={classNames(className)}>
      <ArchivedLink
        className="ms-auto w-auto col-12 col-sm-6 px-0 py-sm-0 d-flex align-items-center justify-content-end gap-2"
        to={{
          pathname: `${pathname}/archive`,
          state: { from: pathname },
        }}
        data-testid="archivedLink"
      >
        See archived
        <ArrowRightTab />
      </ArchivedLink>
    </div>
  );
}
