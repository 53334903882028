import { Text } from "client/ui-components";
import { regionCodeToName } from "client/utils/countries";
import React, { useContext, useEffect, useMemo } from "react";
import { useGetMailingAddress } from "../../../../actions/shippingCheckout";
import Button from "../../../../components/Button";
import { ButtonModes } from "../../../../components/Button/Button.types";
import StripeCreditCardForm from "../../../../components/StripeCreditCardForm";
import { TabContext } from "../../../../components/Tabs";
import { useAuthState } from "../../../../context/auth";
import { ShippingCheckoutContext } from "../../../../context/ShippingCheckout";
import DeliveryOption from "../DeliveryDetail/DeliveryOption";
import { DetailWrapper } from "./styles";

export default function ConfirmationDetail() {
  const { user } = useAuthState();
  const { setCurrentIndex, setIsEditing } = useContext(TabContext);
  const { checkoutData, isLoading, setIsLoading, claim } = useContext(ShippingCheckoutContext);
  const country = claim?.lost_location.address.country_code;

  const [getMailingAddress, { data: shippingAddress, loading: shippingAddressLoading }] =
    useGetMailingAddress();

  useEffect(() => {
    if (
      shippingAddress === null &&
      shippingAddressLoading === false &&
      checkoutData.shippingAddressTo !== null
    ) {
      getMailingAddress(checkoutData.shippingAddressTo);
    }
  }, [shippingAddress, shippingAddressLoading, checkoutData.shippingAddressTo]);

  const hydratedAddress = useMemo<string[]>(() => {
    if (shippingAddress === null) return [];

    const { address1, address2, city, postal_code, region_code } = shippingAddress;

    return [
      [address1, address2].join(" "),
      [`${city},`, regionCodeToName(region_code), postal_code].join(" "),
    ];
  }, [shippingAddress]);

  useEffect(() => {
    setIsLoading(shippingAddressLoading);
  }, [shippingAddressLoading]);

  return (
    <div className="row w-100 mx-0">
      <div className="col-12 mb-45 px-0">
        <div className="d-flex flex-row align-items-center justify-content-between gap-2 mb-35">
          <Text fontWeight={700} fontFamily="Poppins">
            Shipping address
          </Text>

          <Button
            ariaLabel="Edit payment method"
            mode={ButtonModes.open}
            className="p-0 h-auto"
            text="Edit"
            onClick={() => {
              setIsEditing(true);
              setCurrentIndex(0);
            }}
            disabled={isLoading}
          />
        </div>

        <DetailWrapper className="row mx-0">
          <div className="col-12 col-lg-6 p-35">
            <Text fontWeight={600} lineHeight="20px">
              {user.full_name}
            </Text>
            <Text fontWeight={600} lineHeight="20px">
              {hydratedAddress[0]}
            </Text>
            <Text fontWeight={600} lineHeight="20px">
              {hydratedAddress[1]}
            </Text>
          </div>
        </DetailWrapper>
      </div>

      <div className="col-12 mb-45 px-0">
        <div className="d-flex flex-row align-items-center justify-content-between gap-2 mb-35">
          <Text fontWeight={700} fontFamily="Poppins">
            Delivery option
          </Text>

          <Button
            ariaLabel="Edit payment method"
            mode={ButtonModes.open}
            className="p-0 h-auto"
            text="Edit"
            onClick={() => {
              setIsEditing(true);
              setCurrentIndex(1);
            }}
            disabled={isLoading}
          />
        </div>

        {checkoutData.selectedDeliveryMethod !== null && (
          <DeliveryOption rate={checkoutData.selectedDeliveryMethod} country={country} />
        )}
      </div>

      <div className="col-12 px-0">
        <Text fontWeight={700} fontFamily="Poppins">
          Card details
        </Text>

        <StripeCreditCardForm clientSecret={checkoutData.clientSecret} />
      </div>
    </div>
  );
}
