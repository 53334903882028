import React from "react";
import { SVGProps } from "./types";

export default function XSmallCircle({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) {
  return (
    <svg
      {...props}
      aria-hidden={props["aria-hidden"] || false}
      aria-labelledby={`${titleId} ${accessibilityDesc ? accessibilityDesc.descId : ""}`}
      fill="none"
      height={props.height || 24}
      viewBox="0 0 24 24"
      width={props.width || 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={titleId}>{accessibilityTitle}</title>

      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}

      <rect width="24" height="24" rx="12" fill="#F2F4F5" />

      <path
        d="M15 9L9 15M9 9L15 15"
        stroke="#00174A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
