export enum ItemSteps {
  LOCATION_SELECTION = 0,
  ITEM_DETAILS = 1,
  REVIEW_DETAILS = 2,
  SUBMITTED = 3,
}

export type ItemDetails = {
  lostDate: Date;
  itemCategoryIdValue: string;
  detailedDescription: string;
  itemName: string;
  lostLocation: string;
};
