import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";

import { Router } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { hotjar } from "react-hotjar";
import Routes from "./components/Routes";
import { StyledToastContainer } from "./components/Toast";
import { ToastIcon } from "./components/Toast/ToastIcon";
import { ContextProviders } from "./context";
import history from "./context/history";
import FallbackComponent from "./FallbackComponent";
import theme from "./theme";
import { ScrollToTop } from "./ui-components";
import { useTracking } from "./utils/useTracking";

const myFallback = <FallbackComponent />;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnMount: false },
  },
});

const App = ({ config }) => {
  const googleStream = config.googleStreamId;
  googleStream && useTracking(googleStream);

  const hotjarId = config.hotjarId;
  const hotjarSV = config.hotjarSV;
  hotjarId && hotjar.initialize(hotjarId, hotjarSV);

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={myFallback}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ContextProviders>
            <HelmetProvider>
              <Helmet>
                <title>Boomerang</title>
                <html lang="en" />
                <meta
                  name="description"
                  content="Boomerang is a lost and found technology platform that reconnects people with their lost items."
                />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, user-scalable=1.0"
                />
                {googleStream && (
                  <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${googleStream}`}
                  ></script>
                )}
                {googleStream && (
                  <script>{`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${googleStream}');
                `}</script>
                )}
                <link
                  rel="icon"
                  href="/assets/img/favicon-dark.ico"
                  type="image/ico"
                  media="(prefers-color-scheme:no-preference)"
                />

                <link
                  rel="icon"
                  href="/assets/img/favicon-dark.ico"
                  type="image/ico"
                  media="(prefers-color-scheme: light)"
                />

                <link
                  rel="icon"
                  href="/assets/img/favicon.ico"
                  type="image/ico"
                  media="(prefers-color-scheme: dark)"
                />
              </Helmet>
            </HelmetProvider>
            <Router history={history}>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
              <StyledToastContainer
                icon={ToastIcon}
                limit={3}
                autoClose={8000}
                hideProgressBar={true}
                closeButton={false}
              />
            </Router>
          </ContextProviders>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
