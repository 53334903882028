import ReactCodeInput from "react-code-input";
import styled from "styled-components";

export const StyledReactCodeInput = styled(ReactCodeInput)`
  display: inline-flex !important;
  align-items: center;
  column-gap: 4px;
  font-family: ${props => props.theme.ui.fontFamilies.heading};

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    justify-content: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    height: 44px;
    width: 40px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.ui.colors.gray500};
    outline: 1px solid transparent;
    transition:
      width,
      height 0.2s ease-in;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: ${props => props.theme.ui.colors.primary500};
    text-align: center;

    &:not(:disabled) {
      &:focus-visible,
      &:active,
      &.filled {
        border-color: ${props => props.theme.ui.colors.primary500};
        outline-color: ${props => props.theme.ui.colors.primary500};
      }

      &:not([value]),
      &:not([value=""]) {
        border-color: ${props => props.theme.ui.colors.primary500};
        outline-color: ${props => props.theme.ui.colors.primary500};
      }

      &:invalid {
        color: ${props => props.theme.ui.colors.error500};
        border-color: ${props => props.theme.ui.colors.error500};
        outline-color: ${props => props.theme.ui.colors.error500};
      }
    }

    @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
      font-size: 36px;
      line-height: 48px;
      height: 60px;
      width: 60px;
    }
  }
`;

export const Input = styled.input<{
  isFilled: boolean;
}>`
  height: 44px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid #dce1e8;
  outline: 1px solid transparent;
  transition:
    width,
    height 0.2s ease-in;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #0772e4;
  text-align: center;

  ${props =>
    props.isFilled &&
    `
      outline-color: #0772e4;
      border-color: #0772e4;
  `}
  &:focus-visible,
  &:active {
    border-color: #0772e4;
    outline-color: #0772e4;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    height: 60px;
    width: 60px;
  }
`;
