export enum Els {
  div = "div",
  span = "span",
}

export enum ButtonModes {
  danger = "danger",
  dangerOutline = "danger-outline",
  dark = "dark",
  light = "light",
  lightBlue = "light-blue",
  lightOutline = "light-outline",
  open = "open",
  primary = "primary",
  primaryOutline = "primary-outline",
  boomerang = "boomerang",
  secondary = "secondary",
  secondaryOutline = "secondary-outline",
  success = "success",
  outline = "outline",
}

export enum ButtonTextColors {
  danger = "danger",
  gray3 = "gray3",
  white = "white",
}

export enum ButtonTypes {
  button = "button",
  reset = "reset",
  submit = "submit",
}

export enum ButtonSizes {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
}

export interface ButtonProps {
  ariaLabel: string;
  ariaSelected?: JSX.IntrinsicElements["button"]["aria-selected"];
  ariaControls?: JSX.IntrinsicElements["button"]["aria-controls"];
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  className?: string;
  size?: ButtonSizes;
  disabled?: boolean;
  form?: JSX.IntrinsicElements["button"]["form"];
  icon?: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
  mode: ButtonModes;
  onClick?: () => void;
  ref: React.MutableRefObject<HTMLButtonElement>;
  role?: JSX.IntrinsicElements["button"]["role"];
  style?: React.CSSProperties;
  tabIndex?: number;
  text: string | JSX.Element;
  textColor?: ButtonTextColors;
  type?: ButtonTypes;
}
