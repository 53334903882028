import { PartnerStorageLocation } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";

type StorageLocationsResponse = {
  results: PartnerStorageLocation[];
  count: number;
};

export default ({ partnerId, locationId }): Promise<StorageLocationsResponse> => {
  let requestUrl = `/api/partner/partners/${partnerId}/storage-location/`;
  if (locationId) {
    requestUrl += `?location_id=${locationId}`;
  }
  return fetchApiData({ url: requestUrl });
};
