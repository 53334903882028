import styled from "styled-components";

export const StyledButton = styled.button`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  background-color: ${props => props.theme.ui.colors.white};

  > div:first-child {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
    &.open > span {
      transform: rotate(135deg);
      background-color: ${props => props.theme.ui.colors.gray600};
    }

    &.open > span:before,
    &.open > span:after {
      top: 0;
      transform: rotate(90deg);
      background-color: ${props => props.theme.ui.colors.gray600};
    }
  }
`;

export const HamburgerLine = styled.span`
  position: relative;
  width: inherit;
  height: 0.125rem;
  background-color: ${props => props.theme.ui.colors.gray700};

  transition: all 0.4s ease;
  padding: 0;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.375rem;
    height: 0.125rem;
    right: 0;
    background-color: ${props => props.theme.ui.colors.gray700};
    transition: all 0.4s ease;
  }
  &::before {
    top: -0.375rem;
  }
`;
