import { useQueries } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default (ids: string[]) => {
  const allPartners = useQueries({
    queries: ids.map(id => queryFactory.info(id)),
    combine: results => {
      return {
        partners: results.map(result => result.data),
        isLoading: results.some(result => result.isLoading),
        isError: results.some(result => result.isError),
      };
    },
  });

  return allPartners;
};
