import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "lato";
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: 100%;
  margin: 0 auto;
  transition:
    max-width,
    padding-bottom 0.3s ease-in-out;
  padding-bottom: 30px;
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const HeaderWrapper = styled.div`
  color: ${props => props.theme.ui.colors.white};
  padding: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const GoBackButton = styled.a`
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  color: black;
`;

export const BannerContainer = styled.div`
  flex: auto;
  max-width: 400px;
  width: 100%;
`;
