import React from "react";

import { Heading, SectionContainer, Text } from "../../../../ui-components";
import BenefitsColumn from "./BenefitsColumn";

import circleBoomerang from "../../../../assets/ui-icons/circle-boomerang.svg";
import stars from "../../../../assets/ui-icons/stars.svg";
import { benefitsCopy } from "../../content";

import { colors, fontWeights, ui } from "../../../../theme/uiTheme";
import { TitleHeadingDescriptionWrapper } from "../../styles";
import { BenefitsColumnWrapper } from "./styles";

const Benefits = () => {
  return (
    <SectionContainer className="text-center">
      <TitleHeadingDescriptionWrapper>
        <Text
          color={colors.primary500}
          lineHeight={1.5}
          fontWeight={fontWeights.bold}
          textAlign="center"
        >
          {benefitsCopy.title}
        </Text>
        <Heading className="mt-1 mt-md-25 mb-25 mb-md-3 mb-lg-35" as="h2" textAlign="center">
          {benefitsCopy.heading}
        </Heading>
        <Text
          className="m-auto mb-45"
          fontSize={ui.description.responsive.fontSize}
          textAlign="center"
        >
          {benefitsCopy.body}
        </Text>
      </TitleHeadingDescriptionWrapper>
      <div className="row mx-0 justify-content-center">
        <BenefitsColumnWrapper className="col-sm-12 col-md-6 px-35 px-md-45 mb-45 mb-lg-0">
          <BenefitsColumn
            heading={benefitsCopy.sectionOne.heading}
            image={stars}
            imageAlt="Boomerang stadium graphic"
            list={benefitsCopy.sectionOne.list}
          />
        </BenefitsColumnWrapper>
        <BenefitsColumnWrapper className="col-sm-12 col-md-6 px-35 px-md-45">
          <BenefitsColumn
            heading={benefitsCopy.sectionTwo.heading}
            image={circleBoomerang}
            imageAlt="Boomerang stadium graphic"
            list={benefitsCopy.sectionTwo.list}
          />
        </BenefitsColumnWrapper>
      </div>
    </SectionContainer>
  );
};

export default Benefits;
