import React from "react";
import CoBrandedPageContextProvider from "./context";
import CoBrandedWelcomePage from "./page";

export default function CoBrandedWelcome() {
  return (
    <CoBrandedPageContextProvider>
      <CoBrandedWelcomePage />
    </CoBrandedPageContextProvider>
  );
}
