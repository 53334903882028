import styled from "styled-components";
import Text from "../../../ui-components/Text";

export const ShipmentNumber = styled(Text)`
  color: ${props => props.theme.colors.primaryBlue};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

export const StatusDisplay = styled.div<{
  delivered?: boolean;
}>`
  --line-bg-color: ${({ delivered, ...props }) =>
    delivered ? props.theme.colors.successGreen : props.theme.colors.yellow};
`;

export const StatusStep = styled.div<{
  order?: "first" | "last";
  filled?: boolean;
}>`
  .line {
    height: 14px;
    border-radius: ${({ order }) =>
      order === "first" ? "150px 0 0 150px" : order === "last" ? "0 150px 150px 0" : "0"};
    background: ${({ filled, ...props }) =>
      filled ? "var(--line-bg-color)" : props.theme.colors.gray8};
  }
`;
