import styled from "styled-components";

export const DatePickerContainer = styled.div`
  padding: 20px 0px;
  width: 350px;

  .react-datepicker {
    border: none;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-center;

    color: var(--primary-700-for-text, #00174a);
    /* P Text Regular/16px Bold 700 */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .react-datepicker__header {
    border-radius: 16px;
    background-color: white;
    border-bottom: 1px solid transparent;
    text-align: center;
    padding: 0;
  }

  .react-datepicker__current-month {
    color: var(--primary-700-for-text, #00174a);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    padding: 18px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--primary-700-for-text, #00174a);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* P Text Regular/16px Bold 700 */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    border: 1px solid transparent;
  }

  .react-datepicker__day--today {
    background: var(--gray-500-for-lines, #dce1e8);
  }

  .react-datepicker__day--keyboard-selected {
    background: var(--Primary-500-Main, #0772e4);
    color: var(--Base-White, #fff);
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: var(--gray-500-for-lines, #dce1e8);
  }

  .react-datepicker__navigation {
    padding: 28px;
  }
`;
