import { getReturnsTabs } from "client/components/FilterTabs/constants";
import { useSearchParam } from "client/hooks";
import useReturnsCountByRecoveryMethod from "client/hooks/data/partner/partnerReturnCounts/useReturnsCountByMode";
import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import usePaginatedTabs from "client/hooks/usePaginatedTabs";
import { ReturnModeEnum, ReturnStatus } from "client/types";
import getUrlString from "client/utils/getUrlString";
import useCurrentPartner from "client/utils/useCurrentPartner";
import useQueryString from "client/utils/useQueryString";
import { useEffect, useMemo } from "react";

export default function useReturnTabs() {
  const partnerId = useCurrentPartner();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partner } = usePartner(partnerId);
  const returnModes = partner?.return_modes;
  const [queryRecoveryMethod] = useSearchParam<ReturnModeEnum | "all">("recoveryMethod", "all");
  const query = useQueryString();
  const { returnsCountByRecoveryMethod } = useReturnsCountByRecoveryMethod({
    partnerId,
    filters: {
      ...(partnerUserLocation.id !== "" && {
        lost_locations: [partnerUserLocation.id],
      }),
      status: ReturnStatus.PENDING,
    },
  });

  const tabs = useMemo(() => {
    if (!returnModes) return [];

    return getReturnsTabs(returnModes);
  }, [returnModes]);

  const tabIndex = useMemo<number>(() => {
    if (queryRecoveryMethod === "all" || !queryRecoveryMethod) {
      return 0;
    }
    const recoveryMethod = tabs.slice(1).find(({ recoveryMethods }) => {
      return recoveryMethods.includes(queryRecoveryMethod as ReturnModeEnum);
    });

    if (recoveryMethod) {
      return tabs.indexOf(recoveryMethod) || 0;
    }

    return 0;
  }, [tabs, queryRecoveryMethod]);

  const { setTabAndPage, currentTab } = usePaginatedTabs(
    tabIndex === -1 ? 0 : tabIndex,
    Number(query.get("page")) || 1,
  );

  const tabInfo = useMemo(() => {
    return tabs.map((tab, i) => {
      const isAllActiveTab = i === 0;

      const tabInfo = {
        name: tab.name,
        count: 0,
        url: {
          search: getUrlString({
            recoveryMethod: isAllActiveTab ? "all" : tabs[i].recoveryMethods,
            status: tabs[i].statuses,
            page: "1",
          }),
        },
        isSelected: i === currentTab,
        tabNumber: i,
      };

      if (!returnsCountByRecoveryMethod) {
        return tabInfo;
      }
      const totalCount = (returnsCountByRecoveryMethod || [])?.reduce((acc, cur) => {
        acc += cur.count;
        return acc;
      }, 0);

      if (totalCount === 0) {
        return tabInfo;
      } else if (isAllActiveTab) {
        tabInfo.count = totalCount;
      } else {
        const tabCount = returnsCountByRecoveryMethod?.find(({ name }) => {
          return name === tab.recoveryMethods[0];
        })?.count;

        tabInfo.count = tabCount ? Number(tabCount) : 0;
      }
      return tabInfo;
    });
  }, [tabs, returnsCountByRecoveryMethod]);

  useEffect(() => {
    if (tabIndex >= 0 && tabs.length > 0) {
      setTabAndPage(tabIndex, 1);
    }
  }, [tabIndex, tabs.length]);

  return { tabs, tabInfo, currentTab };
}
