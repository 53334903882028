import classNames from "classnames";
import React from "react";
import { Facebook, Instagram, LinkedIn, XLogo } from "../../assets/ui-icons";
import theme from "../../theme";
import Link from "../Link";
import { Logo, LogoVariants } from "../Logo";
import { FooterSection } from "./components";
import footerSections from "./constants";
import { BottomSection, FirstSection, FooterWrapper, LinksDiv, StyledSpan } from "./styles";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["footer"]>;

export default function Footer({ className, ...props }: Props) {
  return (
    <FooterWrapper
      {...props}
      className={classNames(
        "px-35 px-md-45 pt-5 pt-lg-6 pb-45 pb-lg-5 d-flex flex-column justify-content-center align-items-center",
        className,
      )}
    >
      <div>
        <FirstSection className="d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column align-items-start logo me-lg-6">
            <Logo name={LogoVariants.BoomerangWhite} />
            <StyledSpan className="mt-25 mt-lg-425 mb-5 d-block text-white">
              Lost & found reinvented.
            </StyledSpan>
          </div>
          <LinksDiv className="text-start flex-grow-1">
            <div className="row row-cols-2 gx-35 gx-lg-425 row-cols-lg-5">
              {footerSections.map((section, index) => (
                <FooterSection key={index} {...section} />
              ))}
            </div>
          </LinksDiv>
        </FirstSection>

        <BottomSection className="pt-45 pt-lg-425 mt-5 mt-lg-6 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
          <div className="order-lg-1 mb-4 mb-lg-0">
            <Link
              target="_blank"
              className="logo"
              href="https://www.instagram.com/thanksboomerang/"
            >
              <Instagram
                accessibilityTitle="Visit Boomerang Instagram"
                color={theme.ui.colors.gray500}
                titleId="InstagramIcon"
              />
            </Link>
            <Link target="_blank" className="mx-4 logo" href="https://twitter.com/thanksboomerang/">
              <XLogo
                accessibilityTitle="Visit Boomerang X"
                color={theme.ui.colors.gray500}
                titleId="XIcon"
              />
            </Link>
            <Link
              target="_blank"
              className="logo"
              href="https://www.linkedin.com/company/thanks-boomerang"
            >
              <LinkedIn
                accessibilityTitle="Visit Boomerang LinkedIn"
                color={theme.ui.colors.gray500}
                titleId="LinkedInIcon"
              />
            </Link>
            <Link
              target="_blank"
              className="mx-4 me-0 logo"
              href="https://www.facebook.com/Thanks-Boomerang-106774534604876"
            >
              <Facebook
                accessibilityTitle="Visit Boomerang Facebook"
                color={theme.ui.colors.gray500}
                titleId="FacebookIcon"
              />
            </Link>
          </div>
          <StyledSpan className="order-lg-0">
            © {new Date().getFullYear()} Thanks Boomerang. All rights reserved.
          </StyledSpan>
        </BottomSection>
      </div>
    </FooterWrapper>
  );
}
