import { isMdQuery } from "client/helpers/mediaQuery";
import { Link } from "client/ui-components";
import React from "react";
import { useReturnsFiltersFromQuery } from "./ReturnsFilters";

export default function ClearFilters() {
  const { removeFilter, hasFilters } = useReturnsFiltersFromQuery();

  const isMd = isMdQuery();

  const onClick = () => {
    removeFilter("custom_status");
    removeFilter("storage_location");
    removeFilter("category");
  };
  if (!hasFilters) return null;
  return (
    <Link
      isButton
      style={{
        fontSize: 14,
        lineHeight: "20px",
        padding: 0,
        background: "transparent",
      }}
      onClick={onClick}
      aria-label="Clear all filters"
    >
      {isMd ? "Clear all" : "Clear all filters"}
    </Link>
  );
}
