import classNames from "classnames";
import theme from "client/theme";
import React, { useMemo, useRef, useState } from "react";
import { BigChevronDown, BigChevronUp, SmallChevron } from "../../assets/icons/icons";
import { SmallText, TextLabel } from "../../components/typography";
import useClickAway from "../../hooks/useClickAway";
import Text from "../Text";
import { ContainerRounded, DropdownButton, DropdownMenu, MenuItem, Wrapper } from "./styles";
import { FilterItem } from "./types";

type Props<T = string> = {
  activeOption: FilterItem;
  label: string;
  options: FilterItem<T>[];
  onSelect: (_: FilterItem<T>) => void;
  isLoading?: boolean;
  isNew?: boolean;
  style?: React.CSSProperties;
  rounded?: boolean;
};

export default function FilterDropdown<T>({
  activeOption,
  label,
  onSelect,
  options,
  isLoading = false,
  isNew = false,
  rounded,
  ...props
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickAway(ref, () => setIsOpen(false));

  const title = useMemo<string>(() => {
    return `${activeOption.name} (${
      options.find(item => item.name === activeOption.name)?.count || 0
    })`;
  }, [activeOption, options]);
  const Container = isNew ? Wrapper : "div";

  return (
    <Container className={isNew ? "d-flex flex-column gap-2 p-0 hidden" : ""} {...props}>
      {rounded ? <TextLabel>{label}</TextLabel> : <SmallText>{label}</SmallText>}
      <div style={{ height: 4 }} />
      <div className="dropdown p-0 d-flex align-self-start w-100" ref={ref}>
        {rounded ? (
          <ContainerRounded
            aria-expanded={isOpen}
            aria-haspopup="true"
            data-toggle="dropdown"
            onClick={() => {
              setIsOpen(prev => !prev);
            }}
          >
            <Text fontSize={theme.ui.fontSizes.sm} fontWeight={theme.ui.fontWeights.normal}>
              {title}
            </Text>
            <span>
              {isOpen ? (
                <SmallChevron
                  accessibilityTitle={`close ${label} dropdown icon`}
                  titleId="Big chevron pointing up"
                  style={{
                    transform: "rotate(180deg)",
                    transition: "transform 0.2s ease-in-out",
                  }}
                  key="chevron"
                />
              ) : (
                <SmallChevron
                  accessibilityTitle={`open ${label} dropdown icon`}
                  titleId="Big chevron pointing down"
                  key="chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                  }}
                />
              )}
            </span>
          </ContainerRounded>
        ) : (
          <DropdownButton
            aria-expanded={isOpen}
            aria-haspopup="true"
            className="dropdown-toggle mb-2"
            data-toggle="dropdown"
            onClick={() => {
              setIsOpen(prev => !prev);
            }}
            disabled={isLoading}
          >
            <span>{title}</span>

            <span>
              {isOpen ? (
                <BigChevronUp
                  accessibilityTitle={`close ${label} dropdown icon`}
                  titleId="Big chevron pointing up"
                />
              ) : (
                <BigChevronDown
                  accessibilityTitle={`open ${label} dropdown icon`}
                  titleId="Big chevron pointing down"
                />
              )}
            </span>
          </DropdownButton>
        )}

        <DropdownMenu
          className={classNames("dropdown-menu", isOpen && "show", "overflow-auto")}
          aria-labelledby="dropdownMenuLink"
          data-testid="dropdownMenu"
          // TODO: x-placement attribute requires new react-dom version
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          xPlacement="bottom-start"
        >
          {options.map(item => (
            <MenuItem
              active={activeOption.name === item.name}
              className="dropdown-item"
              onClick={() => {
                onSelect(item);

                setIsOpen(false);
              }}
              key={item.name ?? 0}
              role="button"
              data-testid={`${item.name}`}
            >
              {item.name} {`(${item.count})`}
            </MenuItem>
          ))}
        </DropdownMenu>
      </div>
    </Container>
  );
}
