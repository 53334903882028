import {
  ImageSearchResponse,
  PartnerClaim,
  PartnerItem,
  PartnerStorageLocation,
} from "../../types";
import { PartnerUserStateLegacy } from "./state";

export enum PartnerUserActionEnum {
  UPDATE_CURRENT_CLAIM = "UPDATE_CURRENT_CLAIM",
  UPDATE_CURRENT_ITEM = "UPDATE_CURRENT_ITEM",
  UPDATE_CURRENT_ITEM_AND_IMAGES = "UPDATE_CURRENT_ITEM_AND_IMAGES",
  UPDATE_LOADING = "UPDATE_LOADING",
  UPDATE_STORAGE_LOCATIONS = "UPDATE_STORAGE_LOCATIONS",
}

export interface PartnerItemAction {
  type: PartnerUserActionEnum;
  payload: PartnerUserStateLegacy;
}

export function updateCurrentClaim(dispatch, newClaim: PartnerClaim) {
  dispatch({ type: PartnerUserActionEnum.UPDATE_CURRENT_CLAIM, newClaim });
}

export function updateCurrentItem(dispatch, newItem: PartnerItem) {
  dispatch({ type: PartnerUserActionEnum.UPDATE_CURRENT_ITEM, newItem });
}

export function updateCurrentItemAndImages(
  dispatch,
  newItem: PartnerItem | undefined,
  newItemImages: ImageSearchResponse | null,
) {
  dispatch({
    type: PartnerUserActionEnum.UPDATE_CURRENT_ITEM_AND_IMAGES,
    newItem,
    newItemImages,
  });
}

export function updatePartnerStorageLocations(
  dispatch,
  newStorageLocations: PartnerStorageLocation[],
) {
  dispatch({
    type: PartnerUserActionEnum.UPDATE_STORAGE_LOCATIONS,
    newStorageLocations,
  });
}

export function updatePartnerUserLoading(dispatch, loading: boolean) {
  dispatch({ type: PartnerUserActionEnum.UPDATE_LOADING, loading });
}
