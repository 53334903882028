import React from "react";

import { Button, Text, Typography } from "..";
import history from "../../context/history";
import { isLgQuery, isMdQuery, isXsQuery } from "../../helpers/mediaQuery";
import theme from "../../theme";
import { Wrapper } from "./styles";

export type NoMatchesProps = {
  icon: React.ReactNode;
  heading: string;
  description: string;
};

export default function NoMatches({ icon, heading, description }: NoMatchesProps) {
  const isSm = isXsQuery();
  const isMd = isMdQuery();
  const isLg = isLgQuery();

  const addItemToInventoryButton = (
    <Button
      style={{ minWidth: "197px" }}
      aria-label="AddItemToInventoryButton"
      data-testid="addItemToInventoryButton"
      fullWidth={!isMd}
      size={"xl"}
      className="mt-25"
      variant="primary"
      onClick={() => history.push("/additem")}
    >
      <Text fontWeight={theme.fontWeights.bold} color={theme.colors.white}>
        Add item to inventory
      </Text>
    </Button>
  );

  const checkReturnsButton = (
    <Button
      style={{ minWidth: "197px" }}
      aria-label="CheckReturnsButton"
      data-testid="checkReturnsButton"
      fullWidth={!isMd}
      size={"xl"}
      className="mt-25"
      variant="outline"
      onClick={() => history.push("/partner/returns")}
    >
      <Text fontWeight={theme.fontWeights.bold}>Check returns</Text>
    </Button>
  );

  const ctas = !isLg ? (
    <>
      {addItemToInventoryButton}
      {checkReturnsButton}
    </>
  ) : (
    <div className="d-flex gap-25">
      {checkReturnsButton}
      {addItemToInventoryButton}
    </div>
  );

  return (
    <Wrapper>
      {icon}
      <Typography
        className="mt-1"
        heading
        as="h3"
        fontWeight={theme.fontWeights.bold}
        fontSize={isSm ? "32px" : "24px"}
        lineHeight={isSm ? 1.25 : 1.35}
        color={theme.ui.colors.primary700}
        textAlign="center"
      >
        {heading}
      </Typography>
      <Typography
        className="mt-1"
        as="p"
        fontWeight={theme.fontWeights.light}
        fontSize="16px"
        color={theme.ui.colors.primary700}
        lineHeight={1.5}
        textAlign="center"
      >
        {description}
      </Typography>
      {ctas}
    </Wrapper>
  );
}
