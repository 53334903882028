import { isMdQuery } from "client/helpers/mediaQuery";
import { useHasStorageLocations } from "client/hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { usePartnerDateFormat } from "client/hooks/usePartnerDateFormat";
import useSearchParams from "client/hooks/useSearchParams";
import { Link } from "client/ui-components";
import DateRangePicker from "client/ui-components/DateRangePicker";
import { FilterSelect } from "client/ui-components/Select";
import React from "react";
import { dateToISOString } from "../../../../../utils/dateUtils";
import { useFilters } from "../../helpers/hooks";
import {
  Actions,
  FiltersContainer,
  HorizontalDivider,
  Toolbar,
  ToolbarActionsContainer,
} from "./styles";
import ToggleBulkOptions from "./ToggleBulkOptions";

const StatusFilter = () => {
  const {
    filters: { status },
    setFilter,
    statuses,
  } = useFilters();

  return (
    <FilterSelect
      label="Status"
      value={status.value}
      onValueChange={value => setFilter("status", value)}
      options={statuses}
      style={{ gridArea: "status" }}
      data-testid="statusFilter"
    />
  );
};

const CategoryFilter = () => {
  const {
    filters: { category },
    setFilter,
    categories,
  } = useFilters();

  return (
    <FilterSelect
      label="Category"
      value={category.name}
      onValueChange={value => setFilter("category", value)}
      options={categories.map(({ name, count }) => ({ name, count, value: name }))}
      style={{ gridArea: "category" }}
      data-testid="categoryFilter"
    />
  );
};

const StorageLocationFilter = ({ count }) => {
  const {
    filters: { storageLocation },
    setFilter,
    storageLocations,
  } = useFilters();
  const hasStorageLocations = useHasStorageLocations();

  if (!hasStorageLocations) return null;

  const storageLocationsWithCount = [...storageLocations];
  if (storageLocation.value === "ALL") {
    storageLocationsWithCount[0] = {
      ...storageLocationsWithCount[0],
      count,
    };
  }

  return (
    <FilterSelect
      label="Storage location"
      value={storageLocation.value}
      onValueChange={value => setFilter("storageLocation", value)}
      options={storageLocationsWithCount}
      style={{ gridArea: "storageLocation" }}
      data-testid="storageLocationFilter"
    />
  );
};

const DateFilter = () => {
  const {
    filters: { date_after, date_before },
  } = useFilters();
  const [search, setSearch] = useSearchParams();
  const { short } = usePartnerDateFormat();

  return (
    <DateRangePicker
      label="Date found"
      data-testid="dateFoundFilter"
      onSelect={(date_after, date_before) => {
        search.set("date_before", date_before ? dateToISOString(date_before) : "ALL");
        search.set("date_after", date_after ? dateToISOString(date_after) : "ALL");
        const obj = Object.fromEntries(search.entries());
        setSearch(obj);
      }}
      startDate={date_after}
      endDate={date_before}
      dateFormat={short}
    />
  );
};

// it clears filters by going to the same page with no params
function ClearFilters() {
  const { hasFilters } = useFilters();
  const isMd = isMdQuery();
  if (!hasFilters) return null;
  return (
    <Link style={{ height: 28 }} onClick={() => {}}>
      {isMd ? "Clear all" : "Clear all filters"}
    </Link>
  );
}

export default function ItemFilters({ count }: { count: number }) {
  return (
    <>
      <Toolbar>
        <FiltersContainer>
          <DateFilter />
          <StatusFilter />
          <CategoryFilter />
          <StorageLocationFilter count={count} />
        </FiltersContainer>
        <ToolbarActionsContainer>
          <ClearFilters />
          <Actions>
            <ToggleBulkOptions />
          </Actions>
        </ToolbarActionsContainer>
      </Toolbar>
      <HorizontalDivider />
    </>
  );
}
