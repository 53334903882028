import React from "react";
import { PartnerItem } from "../../types";
import Button from "../Button";
import { ButtonModes, ButtonTypes } from "../Button/Button.types";
import ItemSnapshot from "../ItemSnapshot";
import { Divider } from "./styles";

export interface ReturnsCardProps {
  category: string;
  item: PartnerItem;
  contactDetails?: React.ReactNode;
  key: number;
  onClick: () => void;
}

const ReturnsCard: React.FC<ReturnsCardProps> = props => {
  const { category, item, contactDetails, key, onClick } = props;
  const label = item?.inventoryId ? `ID: ${item?.inventoryId}` : "";
  return (
    <div className="card mb-3 rounded-3 border-0 d-flex flex-column p-35">
      <ItemSnapshot
        {...item}
        categoryName={category}
        snapshotKey={key}
        label={label}
        useLgCarousel
      />
      {contactDetails}
      <Divider className="my-35" />
      <Button
        ariaLabel="See details"
        mode={ButtonModes.primary}
        type={ButtonTypes.submit}
        text={"See details"}
        className={"w-100"}
        disabled={false}
        loading={false}
        onClick={onClick}
      />
    </div>
  );
};

export default ReturnsCard;
