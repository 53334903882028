import React from "react";
import PartnerLayout from "../../ui-components/Layouts/Partner";
import { Hero, Team } from "./components";

export default function AboutPage() {
  return (
    <PartnerLayout>
      <Hero />

      <Team />
    </PartnerLayout>
  );
}
