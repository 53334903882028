import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import React, { useContext } from "react";

import { ResultType } from "../..";
import { PartnerGlobalSearchContext } from "../../../../context/PartnerGlobalSearch";
import theme from "../../../../theme";
import { Typography } from "../../../../ui-components";
import NoResultsFound from "../NoResultsFound";
import ResultsSection from "../ResultsSection";

export default function SearchResults() {
  const { searchTerm, searchResults } = useContext(PartnerGlobalSearchContext);
  const { globalSearchLocation } = usePartnerLocationStore();

  const heading = searchResults && (
    <div className="mt-3 mx-3">
      <Typography
        as="h4"
        heading
        fontWeight={theme.fontWeights.bold}
        fontSize={theme.fontSizes.lg}
        color={theme.ui.colors.primary700}
      >
        Search results
      </Typography>
      <Typography
        as="p"
        fontWeight={theme.fontWeights.light}
        fontSize="16px"
        lineHeight={1.5}
        color={theme.ui.colors.primary700}
      >
        {"Look what we found! There are "}
        <Typography
          as={"span"}
          fontWeight={theme.fontWeights.bold}
          fontSize="16px"
          color={theme.ui.colors.primary700}
        >
          {searchResults.counts.items + searchResults.counts.claims}
        </Typography>
        {" results for "}
        <Typography
          whiteSpace="pre"
          as={"span"}
          fontWeight={theme.fontWeights.bold}
          fontSize="16px"
          color={theme.ui.colors.primary700}
        >{`"${searchTerm}"`}</Typography>
        <Typography
          as={"span"}
          fontWeight={theme.fontWeights.default}
          fontSize="16px"
          color={theme.ui.colors.primary700}
        >
          {" in "}
        </Typography>
        {
          <Typography
            as={"span"}
            fontWeight={theme.fontWeights.bold}
            fontSize="16px"
            color={theme.ui.colors.primary700}
          >
            {globalSearchLocation.name === "" ? "All locations" : `${globalSearchLocation.name}`}
          </Typography>
        }
        {"."}
      </Typography>
    </div>
  );

  const itemsResults = searchResults &&
    searchResults.items !== undefined &&
    searchResults.items.length > 0 && (
      <ResultsSection
        dataTestId="inventoryResultsTable"
        type={ResultType.ITEMS}
        results={searchResults.items as any[]}
        header="Inventory"
        subheader={`We found ${searchResults.counts.items} item${
          searchResults.counts.items > 1 ? "s" : ""
        }!`}
        seeAllLinkText={`View all ${searchResults.counts.items} inventory results`}
        showSeeAll={searchResults.counts.items > 3}
      />
    );

  const claimsResults = searchResults &&
    searchResults.claims !== undefined &&
    searchResults?.claims.length > 0 && (
      <ResultsSection
        dataTestId="claimsResultsTable"
        type={ResultType.CLAIMS}
        results={searchResults?.claims as any[]}
        header={"Claims"}
        subheader={`We found ${searchResults.counts.claims} claim${
          searchResults.counts.claims > 1 ? "s" : ""
        }!`}
        seeAllLinkText={`View all ${searchResults.counts.claims} claims results`}
        showSeeAll={searchResults.counts.claims > 3}
      />
    );

  return (
    <>
      {searchResults && searchResults?.counts.claims + searchResults?.counts.items === 0 ? (
        <NoResultsFound />
      ) : (
        <>
          {heading}
          {itemsResults}
          {claimsResults}
        </>
      )}
    </>
  );
}
