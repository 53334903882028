import React, { FocusEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { EmailIcon, UserIcon } from "../../../../assets/icons/icons";
import Modal from "../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../components/Modal/Modal.types";
import StyledFloatingLabel from "../../../../components/StyledFloatingLabel";
import StyledPhoneInput from "../../../../components/StyledPhoneInput";
import { ContactDetailsRequest } from "../../../../types";

interface ContactDetailsModalProps {
  closeModal: () => void;
  showModal: boolean;
  topBtnLoading: boolean;
  onNextStep?: (userDetails: ContactDetailsRequest) => void;
}

const ContactDetailsModal: React.FC<ContactDetailsModalProps> = props => {
  const { closeModal, onNextStep, showModal, topBtnLoading } = props;

  // Form to create user on Return Now
  const {
    control: createUserFormControl,
    formState: { errors: createUserFormErrors, isValid: createUserFormIsValid },
    handleSubmit,
    setValue: createUserFormSetValue,
    trigger: createUserFormTrigger,
    watch: watchCreateUserForm,
  } = useForm<ContactDetailsRequest>({
    criteriaMode: "all",
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const contactDetailsForm = (
    <div className="p-2">
      <Controller
        render={({ field }) => (
          <StyledFloatingLabel
            {...field}
            errors={createUserFormErrors.first_name}
            isValidated={createUserFormErrors.first_name}
            label="First name"
            placeholder="First name"
            className="mb-3"
            data-testid="firstNameInput"
            type="text"
            icon={
              <UserIcon
                accessibilityTitle="First name"
                ariaHidden={true}
                titleId="FirstNameSAVIconTitle"
              />
            }
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              createUserFormSetValue("first_name", e.target.value.trim());
              void createUserFormTrigger("first_name");
            }}
            required
            lpIgnore="true"
            tabIndex={0}
            ariaLabel="Enter first name"
          />
        )}
        name="first_name"
        control={createUserFormControl}
        defaultValue=""
        rules={{
          required: "Full name is required",
        }}
      />
      <Controller
        render={({ field }) => (
          <StyledFloatingLabel
            {...field}
            errors={createUserFormErrors.last_name}
            isValidated={createUserFormErrors.last_name}
            label="Last name"
            placeholder="Last name"
            className="mb-3"
            data-testid="lastNameInput"
            type="text"
            icon={
              <UserIcon
                accessibilityTitle="Last name"
                ariaHidden={true}
                titleId="LastNameSAVIconTitle"
              />
            }
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              createUserFormSetValue("last_name", e.target.value.trim());
              void createUserFormTrigger("last_name");
            }}
            required
            lpIgnore="true"
            tabIndex={0}
            ariaLabel="Enter last name"
          />
        )}
        name="last_name"
        control={createUserFormControl}
        defaultValue=""
        rules={{
          required: "Last name is required",
        }}
      />
      <Controller
        render={({ field }) => (
          <StyledFloatingLabel
            {...field}
            errors={createUserFormErrors.email}
            isValidated={createUserFormErrors.email}
            label="Email"
            placeholder="Email"
            className="mb-3"
            data-testid="emailInput"
            type="text"
            icon={
              <EmailIcon accessibilityTitle="Email" ariaHidden={true} titleId="EmailSAVIconTitle" />
            }
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              createUserFormSetValue("email", e.target.value.trim());
              void createUserFormTrigger("email");
            }}
            required
            lpIgnore="true"
            tabIndex={0}
            ariaLabel="Enter email"
          />
        )}
        name="email"
        control={createUserFormControl}
        defaultValue=""
        rules={{
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        }}
      />
      <Controller
        name="phone_number"
        control={createUserFormControl}
        render={({ field: { onChange, value } }) => (
          <StyledPhoneInput
            value={value}
            onChange={onChange}
            onBlur={() => createUserFormTrigger("phone_number")}
            label="Mobile number"
            isValidated={createUserFormErrors.phone_number}
            defaultCountry="US"
            className={createUserFormErrors?.phone_number?.types ? "is-invalid" : "is-valid"}
          />
        )}
        rules={{
          required: "Phone number is required",
          validate: value => (value && isValidPhoneNumber(value)) || "Phone number is invalid",
        }}
      />
      {createUserFormErrors?.phone_number?.types &&
        Object.entries(createUserFormErrors?.phone_number?.types || {}).map(([type, message]) => (
          <div className="text-danger invalid-feedback d-block" key={type}>
            {message as string}
          </div>
        ))}
    </div>
  );

  return (
    <Modal
      bottomBtnText={ModalButtonTextEnum.CANCEL}
      bottomBtnOnClick={closeModal}
      header={"Enter details to return"}
      children={contactDetailsForm}
      show={showModal}
      showXButton
      subHeader={"For liability reasons, please collect name, email, phone and check photo ID."}
      topBtnOnClick={handleSubmit(() => {
        /**
         * Save user details
         * This triggers the next modal to show in ModalFlow.tsx
         *
         */
        const req: ContactDetailsRequest = {
          first_name: watchCreateUserForm("first_name"),
          last_name: watchCreateUserForm("last_name"),
          email: watchCreateUserForm("email"),
          phone_number: watchCreateUserForm("phone_number"),
          full_name: `${watchCreateUserForm("first_name")} ${watchCreateUserForm("last_name")}`,
        };
        onNextStep?.(req);
      })}
      topBtnDisabled={!createUserFormIsValid}
      topBtnLoading={topBtnLoading}
      topBtnText={"Next"}
      data-testid="nextButton"
    />
  );
};

export default ContactDetailsModal;
