export enum PartnerClaimStatusEnum {
  NEW = "New claim",
  NO_MATCHES = "No matches",
  HAS_MATCHES = "Potential matches",
  UNMATCHED = "Unmatched",
  MATCHED = "Pending approval", // Todo: remove
  // eslint-disable-next-line
  PENDING_APPROVAL = "Pending approval",
  ITEM_IN_RETURN = "Item in return",
  RETURNED = "Returned",
  RESOLVED = "Resolved",
  CANCELED = "Canceled",
  EXPIRED = "Expired",
}

export enum PartnerItemStatusEnum {
  NO_MATCHES = "No matches",
  HAS_MATCHES = "Potential matches",
  UNMATCHED = "Unmatched",
  PENDING_APPROVAL = "Pending approval",
  MATCHED = "Pending", // Todo: remove
  IN_RETURN = "In return",
  RETURNED = "Returned",
  UNCLAIMED = "Unclaimed",
}

export enum PartnerItemUnclaimedStatusEnum {
  DONATED = "Donated",
  STORED = "Stored",
  DESTROYED = "Destroyed",
  OTHER = "Other",
  SOLD = "Sold",
  GIVEN_TO_STAFF = "Given to Staff",
  RESTOCKED = "Restocked",
  MORE_LIFE = "More Life",
  NULL = "Unclaimed",
}

export enum PartnerClaimCanceledReasonEnum {
  "I_HAD_IT_THE_WHOLE_TIME" = "I had it the whole time",
  "I_FOUND_IT_SOMEWHERE_ELSE" = "I found it somewhere else",
  "I_REPLACED_IT" = "I replaced it",
  "I_DONT_WANT_IT_ANYMORE" = "I don't want it anymore",
  "OTHER" = "Other",
}

export enum PartnerReturnPickupStatusStringEnum { // Grammar using pickup as a noun opposed to verb which would be pick up.
  READY_FOR_PICKUP = "Ready for pickup",
  RETURNED = "Returned",
}

export enum PartnerReturnShippingStatusStringEnum {
  AWAITING_PAYMENT = "Awaiting payment",
  READY_TO_PACK = "Ready to pack",
  PACKAGED = "Packaged",
  PROCESSING = "Processing",
  IN_TRANSIT = "In transit",
  DELIVERED = "Delivered",
  IN_RETURN = "In return",
}

export enum PartnerTextButtonEnum {
  ARIA_LABEL_REVIEW_MATCHES = "Click to review matches",
  NO_MATCHES = "No matches",
  MATCHED = "Matched",
  MATCH_NOW = "Review matches",
  PRINT_LABEL = "Print label",
  RETURN_NOW = "Return now",
  PACKAGED = "Mark as packaged",
  SWITCH_TO_PICKUP = "Switch to pickup",
  SWITCH_TO_SHIPPING = "Switch to shipping",
}

export enum ClaimStatusStrings {
  NEW = "New claim",
  NO_MATCHES = "Still looking", // Used dynamically by Badge in ClaimPill, unfortunately
  // eslint-disable-next-line
  HAS_MATCHES = "Still looking", // Used dynamically by Badge in ClaimPill, unfortunately
  // eslint-disable-next-line
  STILL_LOOKING = "Still looking",
  MORE_INFO_NEEDED = "More info needed",
  MATCHED = "Matched!",
  COMPLETED = "Ready for pickup",
  CANCELED = "Canceled",
  RETURNED = "Returned",
  EXPIRED = "Expired",
}

export enum ReturnStatusStrings {
  READY_FOR_PICKUP = "Ready for pickup",
  READY_TO_PACK = "Ready to pack", // Currently unused
  CANCELED = "Canceled",
}

export type UserClaimStatus =
  | ClaimStatusStrings
  | ReturnStatusStrings
  | PartnerReturnPickupStatusStringEnum
  | PartnerReturnShippingStatusStringEnum;
