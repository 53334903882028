import { fetchApiData } from "client/utils/fetchApiData";
import Cookies from "js-cookie";
import { ApiHandlerRes, useApiHandler } from ".";
import {
  GenericApiResponse,
  PartnerRequestPasswordResetRequest,
  PartnerResetPasswordRequest,
  RegisterRequest,
  SendOtpRequest,
  SuccessErrorResponse,
  User,
  VerifyOtpRequest,
  VerifyOtpResponse,
} from "../types";
import fetchData from "../utils/fetchData";
import { getCommonHeaders } from "../utils/headers";

const sendVerifyOtp = (verifyOtpReq: VerifyOtpRequest): Promise<VerifyOtpResponse> => {
  return fetchApiData({
    url: "/api/user/verify-passcode",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(verifyOtpReq),
    },
  });
};

export const useVerifyOtp = (): ApiHandlerRes<VerifyOtpRequest, VerifyOtpResponse> =>
  useApiHandler<VerifyOtpRequest, VerifyOtpResponse>(sendVerifyOtp);

const sendOtp = (otpReq: SendOtpRequest): Promise<GenericApiResponse> => {
  return fetchApiData({
    url: "/api/user/send-passcode",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(otpReq),
    },
  });
};

export const useSendOtp = (): ApiHandlerRes<SendOtpRequest, GenericApiResponse> =>
  useApiHandler<SendOtpRequest, GenericApiResponse>(sendOtp);

const sendRegister = (registerReq: RegisterRequest): Promise<User> => {
  return fetchApiData({
    url: "/api/user/register/",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(registerReq),
    },
  });
};

export const useRegister = (): ApiHandlerRes<RegisterRequest, User> =>
  useApiHandler<RegisterRequest, User>(sendRegister);

const setCSRF = (): Promise<null> => {
  Cookies.remove("csrftoken", { path: "" });
  return fetchApiData({ url: "/api/user/csrf-token/" });
};

export const useCsrfToken = (): ApiHandlerRes<null, null> => useApiHandler<null, null>(setCSRF);

const getMe = (): Promise<User> => {
  return fetchApiData({ url: "/api/user/me/" });
};

export const useGetMe = (): ApiHandlerRes<null, User> => useApiHandler<null, User>(getMe);

const sendPartnerRequestPasswordReset = (
  partnerRequestPasswordResetReq: PartnerRequestPasswordResetRequest,
): Promise<SuccessErrorResponse | null> => {
  return fetchData("/api/partner/request-password-reset/", {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(partnerRequestPasswordResetReq),
  });
};

const sendPartnerResetPassword = (
  partnerResetPassswordReq: PartnerResetPasswordRequest,
): Promise<SuccessErrorResponse | null> => {
  return fetchData("/api/partner/set-password/", {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(partnerResetPassswordReq),
  });
};

export const usePartnerRequestPasswordReset = (): ApiHandlerRes<
  PartnerRequestPasswordResetRequest,
  SuccessErrorResponse | null
> =>
  useApiHandler<PartnerRequestPasswordResetRequest, SuccessErrorResponse | null>(
    sendPartnerRequestPasswordReset,
  );

export const usePartnerResetPassword = (): ApiHandlerRes<
  PartnerResetPasswordRequest,
  SuccessErrorResponse | null
> =>
  useApiHandler<PartnerResetPasswordRequest, SuccessErrorResponse | null>(sendPartnerResetPassword);
