import { UnclaimedStatus } from "client/types/models";
import React from "react";
import { toSuggestedUnclaimedStatus } from "../../helpers";
import { Status, StatusText } from "./styles";

interface Props {
  status: UnclaimedStatus | undefined;
  align?: "left" | "right";
}

export function SuggestedStatus({ align, status }: Props) {
  if (!status) return null;
  return (
    <Status style={{ justifyContent: align === "right" ? "end" : "start" }}>
      <StatusText>{toSuggestedUnclaimedStatus(status)}</StatusText>
    </Status>
  );
}
