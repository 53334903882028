import Link from "client/ui-components/Link";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  & > * {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  padding: 0;
  &:hover * {
    stroke: #0254ad;
  }
`;
