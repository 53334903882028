import React from "react";
import styled from "styled-components";
import { getBadgeCss } from "../../utils/badgeStyle";

export interface BadgeProps {
  backgroundColor?: string;
  className?: string;
  color?: string;
  label: string;
  size?: BadgeSizesEnum;
  variant: BadgeVariantsEnum;
  icon?: React.ReactElement;
  iconPosition?: BadgeIconPositionsEnum;
  isUser?: boolean;
  style?: React.CSSProperties;
}

export enum BadgeSizesEnum {
  Small = "small",
  Regular = "regular",
}

export enum BadgeVariantsEnum {
  Text = "text",
  Contained = "contained",
  Outlined = "outlined",
}

export enum BadgeIconPositionsEnum {
  Inside = "inside",
  Outside = "outside",
}

export const StyledBadge = styled.span<{
  backgroundColor?: string;
  color?: string;
  label: string;
  size?: BadgeSizesEnum;
  variant: BadgeVariantsEnum;
  isUser: boolean;
}>`
  ${props => getBadgeCss(props.label, props.variant, props.isUser)}
  ${props => (props.color ? `color: ${props.color};` : "")}
  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : "")}
  font-size: ${props =>
    props.size === BadgeSizesEnum.Small ? props.theme.fontSizes.sm : props.theme.fontSizes.default};
  border-radius: ${props => props.theme.borderRadius.xs};
  display: inline-block;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  vertical-align: baseline;
  width: fit-content;
  &:hover {
    pointer: none;
  }
`;

const Badge = (props: BadgeProps): React.ReactElement | null => {
  const {
    label,
    className,
    color,
    backgroundColor,
    size,
    variant,
    icon,
    iconPosition,
    isUser,
    style,
  } = props;
  if (!label) return null;
  return (
    <>
      {iconPosition === BadgeIconPositionsEnum.Outside && icon}
      {
        <StyledBadge
          className={`${variant !== BadgeVariantsEnum.Text && "px-2 py-1"} ${
            icon !== undefined && "d-flex gap-2"
          } align-items-center ${className}`}
          color={color}
          backgroundColor={backgroundColor}
          label={label}
          size={size}
          variant={variant}
          isUser={isUser ?? false}
          style={style}
        >
          {iconPosition === BadgeIconPositionsEnum.Inside && icon} {label}
        </StyledBadge>
      }
    </>
  );
};

export default Badge;
