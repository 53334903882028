import { dateToISOString } from "client/utils/dateUtils";

export type SearchParamsObject = Record<
  string,
  string | string[] | Date | number | boolean | undefined | null
>;

export default function objectToQueryString(obj: SearchParamsObject) {
  const query = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined || value === null) {
      continue;
    } else if (Array.isArray(value)) {
      value.forEach(v => query.append(key, v));
    } else if (typeof value === "number" || typeof value === "boolean") {
      query.append(key, value.toString());
    } else if (value instanceof Date) {
      query.append(key, dateToISOString(value));
    } else {
      query.append(key, value);
    }
  }
  return query;
}
