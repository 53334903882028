import classNames from "classnames";
import React from "react";
import { BigChevronRight } from "../../../assets/icons/icons";
import Badge, {
  BadgeIconPositionsEnum,
  BadgeSizesEnum,
  BadgeVariantsEnum,
} from "../../../components/Badge";
import Button from "../../../components/Button";
import { ButtonModes } from "../../../components/Button/Button.types";
import { LocationNameDateSnapshot } from "../../../components/LocationNameDateSnapshot";
import { Typography } from "../../../components/typography";
import theme from "../../../theme";
import { ClaimStatus, ClaimStatusStrings, UserClaimStatus } from "../../../types";
import { ChevronWrapper, InfoWrapper, PillWrapper } from "./styles";

interface ClaimPillProps {
  id: string;
  location: string;
  name: string;
  buttonText?: string;
  lostDate: string;
  status?: ClaimStatus | UserClaimStatus;
  onClick: () => void;
  returnDate?: string;
}

const ClaimPill = (props: ClaimPillProps) => {
  // const testId = `claimRow-${props.id}`;
  return (
    <PillWrapper
      className={classNames("styled-content-container p-35 mb-25", {
        "align-items-center": props.buttonText,
      })}
      onClick={props.onClick}
      // data-testid={testId}
      data-testid="claimRow"
    >
      <InfoWrapper>
        <LocationNameDateSnapshot
          location={props.location}
          name={props.name}
          lost_date={props.lostDate}
          className="ms-0" // override default margin as not to effect other instances
        />
        {props.status && (
          <div className="d-flex align-items-center mt-4">
            <Badge
              className="py-1 px-2"
              iconPosition={BadgeIconPositionsEnum.Inside}
              label={props.status}
              size={BadgeSizesEnum.Small}
              variant={
                props.status !== ClaimStatusStrings.EXPIRED &&
                props.status !== ClaimStatusStrings.RETURNED &&
                props.status !== ClaimStatusStrings.CANCELED
                  ? BadgeVariantsEnum.Contained
                  : BadgeVariantsEnum.Outlined
              }
              isUser
            />
            {props.returnDate && (
              <div className="ms-35">
                <Typography as={"span"} fontSize={theme.fontSizes.sm}>
                  Delivered
                </Typography>{" "}
                <Typography
                  as={"span"}
                  fontSize={theme.fontSizes.sm}
                  fontWeight={theme.fontWeights.bold}
                >
                  {props.returnDate}
                </Typography>
              </div>
            )}
          </div>
        )}
      </InfoWrapper>
      {props.buttonText ? (
        <Button
          text={props.buttonText}
          mode={ButtonModes.primaryOutline}
          ariaLabel={props.buttonText}
        />
      ) : (
        <ChevronWrapper className="ms-1">
          <BigChevronRight accessibilityTitle="See claim details" titleId="ClaimPillChevronTitle" />
        </ChevronWrapper>
      )}
    </PillWrapper>
  );
};

export default ClaimPill;
