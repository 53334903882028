import { UserMatch, UserMatchApprove, UserMatchFromIdResponse } from "../types";

import { fetchApiData } from "client/utils/fetchApiData";
import { ApiHandlerRes, useApiHandler } from ".";
import { GetUserMatchesRequest, MatchesSearchResponse } from "../types/actions";
import getQueryString from "../utils/getQueryString";
import { getCommonHeaders } from "../utils/headers";

export const getUserMatches = (
  userMatchesReq: GetUserMatchesRequest,
): Promise<MatchesSearchResponse> => {
  const queryString = userMatchesReq.filters ? getQueryString(userMatchesReq.filters) : "";
  return fetchApiData({ url: `/api/user/returns/${queryString}` });
};

export const getUserMatchFromId = async (userMatchId: string): Promise<UserMatchFromIdResponse> => {
  return fetchApiData({ url: `/api/user/matches/${userMatchId}/` });
};

const sendApproveUserMatch = (
  matchId: string,
  isLegalAccepted: boolean,
): Promise<UserMatchApprove> => {
  return fetchApiData({
    url: `/api/user/matches/${matchId}/approve/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify({
        is_legal_accepted: isLegalAccepted,
      }),
    },
  });
};

const sendRejectUserMatch = (matchId: string): Promise<UserMatch> => {
  return fetchApiData({
    url: `/api/user/matches/${matchId}/reject/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
    },
  });
};

export const useGetUserMatchFromId = (): ApiHandlerRes<string, UserMatchFromIdResponse> =>
  useApiHandler(getUserMatchFromId);

export const useSendApproveUserMatch = (): ApiHandlerRes<string, UserMatchApprove> =>
  useApiHandler(sendApproveUserMatch);

export const useSendRejectUserMatch = (): ApiHandlerRes<string, UserMatch> =>
  useApiHandler(sendRejectUserMatch);
