import { useQuery } from "@tanstack/react-query";
import { ItemStatus, PartnerItemsCountByStatusResponse, PartnerItemStatusEnum } from "client/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useMemo } from "react";
import { OptionalCountQueryFilters } from ".";
import { FilterItem } from "../../../../ui-components/FilterDropdown/types";
import queryFactory from "../queryFactory";

export const defaultStatusFilter: FilterItem<ItemStatus | "ALL"> = {
  name: "Showing all",
  count: 0,
  value: "ALL",
} as const;

function formatStatusFilters({
  statusCounts,
}: {
  statusCounts: PartnerItemsCountByStatusResponse;
}): FilterItem<ItemStatus | "ALL">[] {
  return [
    {
      ...defaultStatusFilter,
      count: statusCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...[ItemStatus.HAS_MATCHES, ItemStatus.NO_MATCHES, ItemStatus.MATCHED].map(value => {
      const item = statusCounts.find(item => item.value === value);

      return item === undefined
        ? {
            name: PartnerItemStatusEnum[value],
            value,
            count: 0,
          }
        : {
            ...item,
            name: PartnerItemStatusEnum[value],
          };
    }),
  ];
}

export const useStatusCounts = (queryObject: OptionalCountQueryFilters) => {
  const partnerId = useCurrentPartner();
  const { data: statusCounts } = useQuery(
    queryFactory.itemCountByStatusFiltered({ partnerId, queryObject }),
  );

  const statuses = useMemo(() => {
    if (!statusCounts) return [];

    return formatStatusFilters({
      statusCounts,
    });
  }, [statusCounts]);

  return {
    statuses,
  };
};

export default useStatusCounts;
