import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryFactory from "client/hooks/data/user/queryFactory";
import useCreateClaimImages from "client/hooks/data/user/useCreateClaimImages";
import { convertDateToUTC00 } from "client/utils/dateUtils";
import useQueryString from "client/utils/useQueryString";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Image } from "../../../typings/endpoints/image";
import useCreateClaim from "../../hooks/data/user/useCreateClaim";
import { Claim, CreateClaimRequest, Location, Partner } from "../../types";

type LostItemCreationContextType = {
  step:
    | "name"
    | "nameUpdate"
    | "date"
    | "dateUpdate"
    | "category"
    | "categoryUpdate"
    | "additionalCategory"
    | "additionalCategoryUpdate"
    | "photos"
    | "photosUpdate"
    | "description"
    | "descriptionUpdate"
    | "contact"
    | "contactUpdate"
    | "review"
    | "otp"
    | "submitted";
  setStep: React.Dispatch<React.SetStateAction<LostItemCreationContextType["step"]>>;
  partner: Partner | null;
  dateLost: Date;
  setDateLost: React.Dispatch<React.SetStateAction<LostItemCreationContextType["dateLost"]>>;
  isLoading: boolean;
  partnerLocationSlug: string | null;
  convertPartnerToPath: (str: string) => string;
  itemName: string;
  setItemName: React.Dispatch<React.SetStateAction<LostItemCreationContextType["itemName"]>>;
  partnerLocation: Location | null;
  partnerHasMultipleLocations: boolean;
  category: string[];
  setCategory: React.Dispatch<React.SetStateAction<LostItemCreationContextType["category"]>>;
  prevCategory: string[];
  setPrevCategory: React.Dispatch<
    React.SetStateAction<LostItemCreationContextType["prevCategory"]>
  >;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<LostItemCreationContextType["firstName"]>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<LostItemCreationContextType["lastName"]>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<LostItemCreationContextType["email"]>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<LostItemCreationContextType["phoneNumber"]>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<LostItemCreationContextType["description"]>>;
  images: Image[];
  setImages: React.Dispatch<React.SetStateAction<LostItemCreationContextType["images"]>>;
  submitClaim: React.DispatchWithoutAction;
  submittedClaim: Claim | null;
  resetClaimDetailsState: React.DispatchWithoutAction;
};

function convertPartnerToPath(str: string) {
  return str.toLowerCase().replace(/ /g, "-");
}

const today = new Date();

export const LostItemCreationContext = createContext<LostItemCreationContextType>({
  step: "name",
  setStep: () => undefined,
  partner: null,
  isLoading: false,
  partnerLocationSlug: null,
  dateLost: today,
  setDateLost: () => undefined,
  convertPartnerToPath,
  itemName: "",
  setItemName: () => undefined,
  partnerLocation: null,
  partnerHasMultipleLocations: false,
  category: ["", ""], // [key, value]
  setCategory: () => undefined,
  prevCategory: ["", ""], // [key, value]
  setPrevCategory: () => undefined,
  firstName: "",
  setFirstName: () => undefined,
  lastName: "",
  setLastName: () => undefined,
  email: "",
  setEmail: () => undefined,
  phoneNumber: "",
  setPhoneNumber: () => undefined,
  images: [],
  setImages: () => undefined,
  description: "",
  setDescription: () => undefined,
  submitClaim: () => undefined,
  submittedClaim: null,
  resetClaimDetailsState: () => undefined,
});

type URLParams = {
  urlPartnerSlug: string;
  urlLocationSlug: string;
};

export default function LostItemCreationProvider({ children }: { children: React.ReactNode }) {
  const query = useQueryString();
  let partnerSlug, locationSlug;
  const { urlPartnerSlug, urlLocationSlug } = useParams<URLParams>();
  if (query.has("p")) {
    partnerSlug = query.get("p") as string;
  } else {
    partnerSlug = urlPartnerSlug;
  }
  if (query.has("l")) {
    locationSlug = query.get("l") as string;
  } else {
    locationSlug = urlLocationSlug;
  }

  const {
    refetch: getPartnerWithSlug,
    data: partner,
    isLoading: isPartnerLoading,
  } = useQuery({
    ...queryFactory.partnerBySlug(partnerSlug),
    enabled: false,
  });
  const { data: partnerLocation } = useQuery(queryFactory.locationWithSlug(locationSlug));
  const [itemName, setItemName] = useState<string>("");
  const [step, setStep] = useState<LostItemCreationContextType["step"]>("name");
  const [dateLost, setDateLost] = useState<Date>(today);
  const [category, setCategory] = useState<string[]>(["", ""]);
  const [prevCategory, setPrevCategory] = useState<string[]>(category);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [images, setImages] = useState<Image[]>([]);
  const [description, setDescription] = useState<string>("");
  const createClaim = useCreateClaim();
  const { data: partnerLocations } = useQuery(
    queryFactory.locationsForPartner((partner && partner.id) ?? ""),
  );
  const [partnerHasMultipleLocations, setPartnerHasMultipleLocations] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();

  const createClaimImagesMutation = useCreateClaimImages();

  const submitClaim = () => {
    const newClaimData = {
      lost_at: convertDateToUTC00(dateLost),
      category: category[0],
      description,
      lost_location: partnerLocation?.id,
      name: itemName,
      images,
    } as CreateClaimRequest;
    createClaim.mutate(newClaimData, {
      onSuccess: submittedClaim => {
        if (submittedClaim !== null && images.length > 0) {
          createClaimImagesMutation.mutate({
            claimId: submittedClaim.id,
            images,
          });
        }
        queryClient.invalidateQueries({
          queryKey: queryFactory.claims(),
        });
        setStep("submitted");
      },
    });
  };

  const resetClaimDetailsState = () => {
    setStep("name");
    setItemName("");
    setDateLost(today);
    setItemName("");
    setCategory(["", ""]);
    setPrevCategory(["", ""]);
    setImages([]);
    setDescription("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
  };

  useEffect(() => {
    setStep("name");
  }, []);

  useEffect(() => {
    if (partnerSlug) {
      getPartnerWithSlug(partnerSlug);
    } else {
      history.replace("/lost");
    }
  }, [partnerSlug]);

  useEffect(() => {
    if (partnerLocations && partnerLocations?.length > 1) {
      setPartnerHasMultipleLocations(true);
    }
  }, [partnerLocations]);

  const values = useMemo<LostItemCreationContextType>(
    () => ({
      step,
      setStep,
      dateLost,
      setDateLost,
      partner: partner ?? null,
      convertPartnerToPath,
      isLoading: isPartnerLoading || createClaim.isPending,
      partnerLocationSlug: locationSlug,
      itemName,
      setItemName,
      partnerLocation: partnerLocation ?? null,
      partnerHasMultipleLocations,
      category,
      setCategory,
      prevCategory,
      setPrevCategory,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      email,
      setEmail,
      phoneNumber,
      setPhoneNumber,
      images,
      setImages,
      description,
      setDescription,
      submitClaim,
      submittedClaim: createClaim.data ?? null,
      resetClaimDetailsState,
    }),
    [
      partner,
      step,
      itemName,
      isPartnerLoading,
      dateLost,
      partnerLocation,
      partnerHasMultipleLocations,
      category,
      prevCategory,
      firstName,
      lastName,
      email,
      phoneNumber,
      images,
      description,
      createClaim.isPending,
      createClaim,
    ],
  );

  return (
    <LostItemCreationContext.Provider value={values}>{children}</LostItemCreationContext.Provider>
  );
}
