import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import { toUpperCase } from "client/utils/stringUtils";
import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetPartnerClaims } from "../../actions/partnerClaims";
import history from "../../context/history";
import { formatClaims, getClaimsParams } from "../../scenes/private/Claims/helpers";
import {
  ClaimStatus,
  FormattedClaim,
  PartnerClaimsCountByCategoryResponse,
  PartnerClaimsCountByStatusResponse,
} from "../../types";
import { FilterItem } from "../../ui-components/FilterDropdown/types";
import useCurrentPartner from "../../utils/useCurrentPartner";
import useQueryString from "../../utils/useQueryString";
import {
  ClaimFilters,
  getCategoryFilters,
  getCounts,
  getStatusFilters,
  initialCategoryFilter,
  initialStatusFilter,
} from "./helpers";

type FilterClaimContextType = {
  formattedClaims: FormattedClaim[];
  claimsCount: number;
  categoryFilters: FilterItem[];
  statusFilters: FilterItem<ClaimStatus | "ALL">[];
  filters: ClaimFilters;
  setFilters: React.Dispatch<React.SetStateAction<ClaimFilters>>;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const FilterClaimContext = createContext<FilterClaimContextType>({
  formattedClaims: [],
  claimsCount: 0,
  categoryFilters: [],
  statusFilters: [],
  filters: {
    status: initialStatusFilter,
    category: initialCategoryFilter,
  },
  setFilters: () => undefined,
  isLoading: false,
  page: 0,
  setPage: () => undefined,
});

export default function FilterClaimContextProvider({
  isArchive = false,
  children,
}: {
  isArchive?: boolean;
  children: React.ReactNode;
}) {
  const partnerId = useCurrentPartner();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const categories = useCategories();
  const [categoryCounts, setCategoryCounts] = useState<PartnerClaimsCountByCategoryResponse | null>(
    null,
  );
  const [statusCounts, setStatusCounts] = useState<PartnerClaimsCountByStatusResponse | null>(null);
  const [filters, setFilters] = useState<ClaimFilters>({
    status: initialStatusFilter,
    category: initialCategoryFilter,
  });
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const query = useQueryString();
  const initialQuery = useRef<string | null>(null);
  const { pathname } = useLocation();
  const [getClaims, { data: claims, loading: claimsLoading }] = useGetPartnerClaims();

  /* 
  Use Memos
  */
  const formattedClaims = useMemo(() => {
    if (claimsLoading || claims === null || claims.count === 0) return [];

    return formatClaims({
      claims: claims.results,
      categories: categories.data,
      partnerLocations,
    });
  }, [claims, categories.data]);

  const claimsCount = useMemo(() => {
    return claims ? claims.count : 0;
  }, [claims]);

  const categoryFilters = useMemo<FilterItem[]>(() => {
    if (categoryCounts === null || categories.data === undefined) return [];

    return getCategoryFilters({
      categories: categories.data,
      categoryCounts,
    });
  }, [categoryCounts, categories.data]);

  const statusFilters = useMemo<FilterItem<ClaimStatus | "ALL">[]>(() => {
    if (statusCounts === null) return [];

    return getStatusFilters({ statusCounts }, isArchive);
  }, [statusCounts]);

  /* 
  Use Effects
  */
  useEffect(() => {
    getCounts({
      partnerId,
      filters,
      partnerUserLocation,
      isArchive,
    }).then(res => {
      if (res) {
        setStatusCounts(res[0]);
        setCategoryCounts(res[1]);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setPage(1);
    setFilters({
      status: initialStatusFilter,
      category: initialCategoryFilter,
    });
  }, [partnerUserLocation]);

  useEffect(() => {
    /* When the page first loads and after the filters are loaded,
      grab the filters/page values from the url and set them on the page
    */
    if (initialQuery.current === null) {
      if (categoryFilters.length < 1 || statusFilters.length < 1) return;

      setIsLoading(true);

      const categoryFromQuery = query.get("category");
      const statusFromQuery = query.get("status");
      const selectedFilters = {
        status: statusFilters.find(({ value }) => value === statusFromQuery) || initialStatusFilter,
        category:
          categoryFilters.find(({ name, value }) =>
            categoryFromQuery === "ALL"
              ? toUpperCase(value) === categoryFromQuery
              : toUpperCase(name) === categoryFromQuery,
          ) || initialCategoryFilter,
      };
      setFilters(selectedFilters);

      if (query.has("page")) {
        const pageFromQuery = query.get("page");
        setPage(pageFromQuery && isNaN(Number(pageFromQuery)) ? 1 : Number(pageFromQuery));
      }

      initialQuery.current = query.toString();
    }
  }, [statusFilters, categoryFilters]);

  /* Update the url to match the filters/page values */
  function updateUrlFromPageState() {
    query.delete("category");
    query.set(
      "category",
      filters.category.value === "ALL"
        ? toUpperCase(filters.category.value)
        : toUpperCase(filters.category.name),
    );

    query.delete("status");
    query.set("status", filters.status.value);

    query.delete("page");
    query.append("page", String(page));

    history.push(`${pathname}?${query}`);
  }

  function fetchData() {
    getClaims(
      getClaimsParams({
        partnerUserLocation,
        filters,
        partnerId,
        firstPageIndex: (page - 1) * 12,
        isArchive,
      }),
    ).finally(() => setIsLoading(false));

    getCounts({
      partnerId,
      filters,
      partnerUserLocation,
      isArchive,
    }).then(res => {
      if (res) {
        setStatusCounts(res[0]);
        setCategoryCounts(res[1]);
      }
    });
  }

  useEffect(() => {
    /* Whenever one of the filters or page value changes, fetch claims */
    if (initialQuery.current !== null) {
      setIsLoading(true);
      updateUrlFromPageState();
      fetchData();
    }
  }, [filters, page]);

  const values = useMemo<FilterClaimContextType>(
    () => ({
      formattedClaims,
      claimsCount,
      categoryFilters,
      statusFilters,
      setFilters,
      filters,
      isLoading,
      page,
      setPage,
    }),
    [formattedClaims, claimsCount, categoryFilters, statusFilters, filters, isLoading, page],
  );

  return <FilterClaimContext.Provider value={values}>{children}</FilterClaimContext.Provider>;
}
