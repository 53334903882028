import usePartnerReturns from "client/hooks/data/partner/usePartnerReturns";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useEffect } from "react";
import { formatDetailedReturns } from "..";
import { useReturnsStore } from "../../returnStore";
import useReturnFilters from "./useReturnsFilters";

export default function useReturns() {
  const partnerId = useCurrentPartner();
  const { setFilterCounts, setTotalCount, totalCount } = useReturnsStore();
  const returnFilters = useReturnFilters({ count: totalCount });

  const { partnerReturns, isLoading, error } = usePartnerReturns({
    partnerId,
    filters: returnFilters,
  });

  useEffect(() => {
    if (partnerReturns?.count) {
      setTotalCount(partnerReturns.count);
    }
  }, [partnerReturns?.count]);

  const formattedReturns = formatDetailedReturns(partnerReturns?.results || []);
  const { counts_by_item_category, counts_by_item_storage_location, counts_by_custom_status } =
    partnerReturns || {};

  useEffect(() => {
    setFilterCounts({
      categoryCounts: counts_by_item_category?.map(({ count, category_id, name }) => ({
        name,
        value: category_id,
        count,
      })),
      storageLocationCounts: counts_by_item_storage_location?.map(
        ({ count, storage_location_id, name }) => ({
          name,
          value: storage_location_id,
          count,
        }),
      ),
      statusCounts: counts_by_custom_status,
    });
  }, [counts_by_item_category, counts_by_item_storage_location, counts_by_custom_status]);

  return { formattedReturns, isLoading, error, count: totalCount };
}
