import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

const StyledPhoneInputWrapper = styled.div`
  position: relative;
  .PhoneInputInput::placeholder {
    color: ${props => props.theme.colors.gray3};
  }
  .PhoneInputInput {
    border-radius: ${props => props.theme.borderRadius.sm};
    border: 1px solid ${props => props.theme.colors.gray3};
    color: ${props => props.theme.colors.gray1};
    font-weight: ${props => props.theme.fontWeights.default};
    outline: none;
    padding-left: 5.6rem;
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    height: ${props => props.theme.heights.input};
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.primaryBlue} !important;
      outline-style: none;
      box-shadow: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.primaryBlue};
    }
    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.gray3};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.gray3}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.primaryBlue} !important;
      fill: ${props => props.theme.colors.primaryBlue} !important;
    }
  }
  .is-valid {
    .PhoneInputInput {
      background-image: none !important;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  .is-invalid {
    .PhoneInputInput {
      border: 1px solid ${props => props.theme.colors.red};
      background-image: none !important;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: ${props => props.theme.colors.red};
      box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};
      ~ label,
      ~ svg {
        color: ${props => props.theme.colors.red};
      }
      &:hover,
      &:focus,
      &:active {
        border-color: ${props => props.theme.colors.red} !important;
        outline-style: none;
        box-shadow: none;
        box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
      }
      &:not(:placeholder-shown) {
        border-color: ${props => props.theme.colors.red};
      }
      &:placeholder-shown {
        border-color: ${props =>
          props.theme.colors.danger}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
      }
      &:focus ~ svg {
        color: ${props => props.theme.colors.red} !important;
        fill: ${props => props.theme.colors.red} !important;
      }
    }
    + .form__label {
      color: ${props => props.theme.colors.red} !important;
    }
  }
  .PhoneInputCountry {
    position: absolute;
    top: 19px;
    left: 31px;
    height: 21px;
    border-right: 1px solid ${props => props.theme.colors.gray4};
    padding-right: 0.9rem;
  }

  .form__label {
    position: absolute;
    top: 50%;
    left: 90px;
    font-size: ${props => props.theme.fontSizes.default};
    line-height: 1.75;
    font-weight: ${props => props.theme.fontWeights.default};
    color: ${props => props.theme.colors.gray3};
    cursor: text;
    pointer-events: none;
    opacity: 1;
    transform: ${props =>
      props.isFloat ? "scale(0.75) translateY(-2rem) translateX(-1.15rem)" : " translateY(-50%)"};
  }
`;

const StyledPhoneInput = props => {
  const [isFloat, setIsFloat] = useState(false);
  return (
    <StyledPhoneInputWrapper isFloat={isFloat || props.value}>
      <PhoneInput
        onBlur={() => {
          props.onBlur();
          !props.value ? setIsFloat(false) : null;
        }}
        aria-label={props.ariaLabel}
        onFocus={() => setIsFloat(true)}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultCountry={props.defaultCountry}
        className={props.className}
      />
      <label className="form__label">{props.label}</label>
    </StyledPhoneInputWrapper>
  );
};

export default StyledPhoneInput;
