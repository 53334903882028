import React, { useMemo } from "react";
import { Location } from "../../types";

interface AddressLocationSnapshotProps {
  showName: boolean;
  address: Location;
}

export const AddressLocationSnapshot: React.FC<AddressLocationSnapshotProps> = props => {
  const { address, showName } = props;
  const addressDetails = useMemo(() => address.address, [address]);

  return (
    <>
      {showName && <div>{address?.name}</div>}
      <div>{addressDetails?.address1}</div>
      {addressDetails.address2 && <div>{addressDetails.address2}</div>}
      <div>
        {addressDetails.city}, {addressDetails.region_code} {addressDetails.postal_code}
      </div>
    </>
  );
};
