import useCountries from "client/hooks/data/user/useCountries";
import React, { Ref } from "react";
import Select, { Props as SelectProps } from "../Inputs/Select";

type Props = SelectProps;

function CountrySelect({ disabled, ...props }: Props, ref: Ref<HTMLSelectElement>) {
  const { countries, isLoading: countriesLoading } = useCountries();

  return (
    <Select ref={ref} {...props} disabled={countries === null || countriesLoading || disabled}>
      {countries !== null &&
        (countries?.results || []).map(({ name, iso_code }) => (
          <option key={iso_code} value={iso_code}>
            {name}
          </option>
        ))}
    </Select>
  );
}

export default React.forwardRef(CountrySelect);
