import React, { useContext, useMemo } from "react";
import { ArrowNarrowLeft } from "../../../../assets/ui-icons";
import { CancelClaimContext } from "../../../../context/CancelClaim";
import history from "../../../../context/history";
import { Container } from "../../../../ui-components";
import Confirmation from "./components/Confirmation";
import { GoBackButton, HeaderWrapper, Wrapper } from "./styles";

export default function CancelClaimForm(): JSX.Element {
  const { step } = useContext(CancelClaimContext);

  const content = useMemo<React.ReactNode>(() => {
    switch (step) {
      case "confirmation":
        return <Confirmation />;
      default:
        alert(`Error: "${step}" step not implemented`);
    }
  }, [step]);

  return (
    <>
      <HeaderWrapper>
        <GoBackButton
          className="btn btn-link text-white px-0"
          onClick={() => history.push("/claims")}
        >
          <ArrowNarrowLeft
            accessibilityTitle="go-back"
            titleId="go-back"
            className="flex-shrink-0"
            color="black"
            data-testid="goBackArrow"
          />
        </GoBackButton>
      </HeaderWrapper>
      <Container>
        <Wrapper>{content}</Wrapper>
      </Container>
    </>
  );
}
