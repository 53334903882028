import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.ui.colors.primary700};
  color: ${props => props.theme.ui.colors.white};
  padding: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const Container = styled.div`
  margin: 0 auto;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 800px;
  }
`;
export const GoBackButton = styled.a`
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
`;
