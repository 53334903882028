import classNames from "classnames";
import React, { Ref } from "react";

import { useLocation } from "react-router-dom";
import { BoomerangArrowRight } from "../../assets/icons/icons";
import theme from "../../theme";
import { AnchorExternalWrapper, AnchorInternalWrapper, ButtonWrapper, Stage } from "./styles";
import { AnchorProps, ButtonProps, UIButtonProps } from "./types";

// * Pass an href prop in order to return an anchor element in this component
function isAnchorProps(props: AnchorProps | ButtonProps): props is AnchorProps {
  return (props as AnchorProps).href !== undefined;
}

function isButtonProps(props: AnchorProps | ButtonProps): props is ButtonProps {
  return ["button", "reset", "submit", undefined].includes((props as ButtonProps).type);
}

const Button = (
  {
    children,
    className,
    disabled = false,
    fullWidth = false,
    icon,
    iconPosition = "start",
    loading,
    loadingText,
    size = "lg",
    variant = "primary",
    ...props
  }: UIButtonProps,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
) => {
  const iconElement = React.isValidElement(icon) && React.cloneElement(icon);

  const commonProps = {
    className: classNames(
      `d-inline-flex align-items-center justify-content-center no-decorate btn btn-${variant} btn-${size}`,
      iconElement ? `icon icon-${iconPosition}` : "",
      {
        disabled: !!disabled,
        "full-width": !!fullWidth,
      },
      className,
    ),
    ...(disabled ? { "aria-disabled": disabled } : undefined),
  };

  const content = (
    <>
      {iconElement && iconPosition === "start" ? iconElement : null}

      {loading ? (
        <>
          <span>{loadingText || "Loading"}</span>

          <Stage
            color={disabled ? theme.ui.colors.primary500 : theme.colors.white}
            className="ms-4"
          >
            <div className="dot-fire" />
          </Stage>

          <BoomerangArrowRight
            className="ms-3 tremble"
            color="currentColor"
            accessibilityTitle="Loading"
            ariaHidden={true}
            titleId="ButtonLoadingStateIconTitle"
          />
        </>
      ) : (
        children
      )}

      {iconElement && iconPosition === "end" ? iconElement : null}
    </>
  );

  if (isAnchorProps(props)) {
    const location = useLocation();
    const isExternalLink = props.href?.startsWith("http");
    const handleAnchorClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (props.href === location.pathname) {
        event.preventDefault();
        window.scrollTo(0, 0);
      }
    };

    if (isExternalLink) {
      return (
        <AnchorExternalWrapper
          {...props}
          {...commonProps}
          href={props.href || ""}
          ref={ref as Ref<HTMLAnchorElement>}
        >
          {content}
        </AnchorExternalWrapper>
      );
    }
    return (
      <AnchorInternalWrapper
        {...props}
        {...commonProps}
        onClick={handleAnchorClick}
        to={props.href || "#"}
        ref={ref as Ref<HTMLAnchorElement>}
      >
        {content}
      </AnchorInternalWrapper>
    );
  }

  if (isButtonProps(props)) {
    return (
      <ButtonWrapper
        {...props}
        {...commonProps}
        role="button"
        type={props.type === undefined ? "button" : props.type}
        ref={ref as Ref<HTMLButtonElement>}
        disabled={disabled || loading}
      >
        {content}
      </ButtonWrapper>
    );
  }

  return null;
};

export default React.forwardRef(Button);
