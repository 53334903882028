import React from "react";
import emptyBoxImg from "../../../../../assets/empty_box.svg";
import BlankMessage from "../../../../../components/BlankMessage";

export default function NoItems() {
  return (
    <BlankMessage alt="Boomerang empty box" image={String(emptyBoxImg)} title={"No claims yet"}>
      There are no claims with this status for this location.
      <br />
      Once claims with this status are reported, they will show up here.
    </BlankMessage>
  );
}
