import styled from "styled-components";

export const BasicInfoDetailWrapper = styled.div`
  flex: 1;

  p {
    margin: 0;
  }
`;

export const UserInfo = styled.p`
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.ui.colors.primary700};
  line-height: 24px;
`;

export const AddressError = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.default};
  line-height: 16px;
  max-width: 450px;
  margin: 24px auto -20px auto !important;
`;

export const StyledParagraph = styled.p`
  color: ${props => props.theme.ui.colors.gray700};
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
