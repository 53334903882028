import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  transition:
    max-width,
    padding-bottom 0.3s ease-in-out;
  padding-bottom: 64px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const HeaderWrapper = styled.div`
  color: ${props => props.theme.ui.colors.white};
  padding: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const GoBackButton = styled.a`
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  color: black;
`;
