import classNames from "classnames";
import React, { useContext } from "react";
import { AlertIcon } from "../../../assets/ui-icons/AlertIcon";
import theme from "../../../theme";
import Text from "../../Text";
import { FormGroupContext } from "../FormGroup";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function FormError({ className, ...props }: Props) {
  const { error, variant } = useContext(FormGroupContext);

  return error ? (
    <div
      {...props}
      className={classNames(
        "d-flex",
        "flex-row",
        "align-items-center",
        variant === "rounded" ? "mt-15" : "mt-2",
        className,
      )}
    >
      {variant === "rounded" ? null : (
        <AlertIcon
          accessibilityTitle="form-error-icon"
          className="me-1"
          titleId={error.ref?.name || "form-error"}
        />
      )}

      <Text
        as="span"
        color={theme.ui.colors.error500}
        fontSize={theme.ui.fontSizes.sm}
        lineHeight={variant === "rounded" ? "1.43" : "1.25"}
        fontWeight={variant === "rounded" ? 400 : 600}
      >
        {error.message}
      </Text>
    </div>
  ) : null;
}
