import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import { LostItemCreationContext } from "../../../../../../context";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Form, Heading, Text } from "../../../../../../ui-components";
import { lostItemNameCopy } from "./content";
import { Content } from "./styles";

type LostItemNameForm = {
  itemName: string;
};

type NameInputArgs = {
  reviewUpdate?: boolean;
};

export default function NameInput({ reviewUpdate = false }: NameInputArgs) {
  const isMd = isMdQuery();

  const { setItemName, itemName, setStep, partner, partnerLocation, partnerHasMultipleLocations } =
    useContext(LostItemCreationContext);

  const nextStep = reviewUpdate ? "review" : "date";

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    clearErrors,
  } = useForm<LostItemNameForm>();

  scrollToTop();

  useEffect(() => {
    if (itemName) {
      setValue("itemName", itemName);
    }
  }, [itemName]);

  const history = useHistory();

  const onSubmit = data => {
    setItemName(data.itemName);
    setStep(nextStep);
  };

  const onBack = () => {
    reviewUpdate ? setStep("review") : history.goBack();
  };

  return (
    <Content>
      <Text
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemNameCopy.subheading}
      </Text>
      <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
        {lostItemNameCopy.heading}
      </Heading>
      <div className="text-md-center">
        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
        >
          {lostItemNameCopy.supportingText}
        </Text>
        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.bold}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
        >
          {`${partner?.name}${partnerHasMultipleLocations ? " - " + partnerLocation?.name : ""}?`}
        </Text>
      </div>
      <Form className="justify-content-center mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group
          disabled={isSubmitting}
          error={errors.itemName}
          className="mb-3"
          data-testid="itemNameInputContainer"
        >
          <Form.Control
            {...register("itemName", {
              required: lostItemNameCopy.inputErrorMessage,
            })}
            autoFocus={isMd}
            autoComplete="off"
            fullWidth
            type="text"
            id="item-name"
            data-testid="itemName"
            placeholder={lostItemNameCopy.inputPlaceholder}
            onChange={e => {
              setValue("itemName", e.target.value);
              clearErrors("itemName");
            }}
          />
        </Form.Group>
        <Button
          aria-label="next button"
          fullWidth={true}
          size={isMd ? "xl" : "2xl"}
          className="submit-button"
          type="submit"
        >
          {reviewUpdate ? lostItemNameCopy.nextButtonUpdate : lostItemNameCopy.nextButton}
        </Button>
      </Form>
      <Button
        aria-label="back button"
        data-testid="backButton"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        variant="outline"
        onClick={onBack}
      >
        {!reviewUpdate && (
          <ArrowLeft
            className="m-2"
            accessibilityTitle={lostItemNameCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
        )}
        <Text fontWeight={fontWeights.bold}>
          {reviewUpdate ? lostItemNameCopy.previousButtonUpdate : lostItemNameCopy.previousButton}
        </Text>
      </Button>
    </Content>
  );
}
