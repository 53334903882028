import { useAuthState } from "../context/auth";
import { LocalStorageConsts } from "../types/localStorage";

const useCurrentPartner = (): string => {
  const { partner, user } = useAuthState();
  let partnerId;
  if (
    JSON.parse(String(localStorage.getItem(LocalStorageConsts.CURRENT_PARTNER))) &&
    user?.partners?.includes(
      JSON.parse(String(localStorage.getItem(LocalStorageConsts.CURRENT_PARTNER))),
    )
  ) {
    partnerId = JSON.parse(String(localStorage.getItem(LocalStorageConsts.CURRENT_PARTNER)));
  } else {
    partnerId = partner;
  }
  return partnerId;
};

export default useCurrentPartner;
