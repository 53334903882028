import { UserState } from "./state";

export enum UserActionType {
  UPDATE_CURRENT_RETURN = "UPDATE_CURRENT_RETURN",
}

export interface UserAction {
  type: UserActionType;
  payload: UserState;
}

export function updateUserCurrentReturn(dispatch, currentReturn) {
  dispatch({
    type: UserActionType.UPDATE_CURRENT_RETURN,
    payload: { currentReturn },
  });
}
