import { UserItem, UserItemFromIdRequest } from "../types";

import { fetchApiData } from "client/utils/fetchApiData";
import { ApiHandlerRes, useApiHandler } from ".";

export const getUserItemFromId = async (
  userItemIdReq: UserItemFromIdRequest,
): Promise<UserItem> => {
  return fetchApiData({ url: `/api/user/items/${userItemIdReq.id}/` });
};

export const useGetUserItemFromId = (): ApiHandlerRes<UserItemFromIdRequest, UserItem> =>
  useApiHandler(getUserItemFromId);
