import React from "react";
import { ClaimStatus, ItemStatus } from "../../../../../types";
import FilterDropdown from "../../../../../ui-components/FilterDropdown";
import { FilterItem } from "../../../../../ui-components/FilterDropdown/types";
import { initialCategoryFilter, initialStatusFilter } from "../../../Inventory/helpers";
import GoToArchiveLink from "../GoToArchiveLink";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  statusFilters: Array<FilterItem<ItemStatus | ClaimStatus | "ALL">>;
  categoryFilters: Array<FilterItem>;
  filters: {
    status: FilterItem;
    category: FilterItem;
  };
  setFilters: React.Dispatch<React.SetStateAction<Props["filters"]>>;
  showGoToArchiveLink?: boolean;
};

export default function Filters({
  categoryFilters,
  filters,
  statusFilters,
  setFilters,
  showGoToArchiveLink = true,
  ...props
}: Props) {
  return (
    <div {...props} className="row mb-35 mt-35">
      <div className="col-12 d-flex flex-column-reverse flex-md-row flex-wrap justify-content-between gap-25">
        <div className="d-flex flex-grow-1 flex-column flex-md-row flex-wrap gap-25 gap-lg-35">
          <div className="d-flex flex-column flex-md-row gap-25 gap-lg-35">
            <FilterDropdown<ItemStatus | ClaimStatus | "ALL">
              activeOption={filters.status}
              label="Filter by status"
              data-testid="statusFilter"
              onSelect={item => setFilters(prev => ({ ...prev, status: item }))}
              options={statusFilters}
            />

            <FilterDropdown
              activeOption={filters.category}
              label="Filter by category"
              data-testid="categoryFilter"
              onSelect={item => setFilters(prev => ({ ...prev, category: item }))}
              options={categoryFilters}
            />
          </div>

          {(filters.category.value !== "ALL" || filters.status.value !== "ALL") && (
            <button
              className="btn btn-link mt-md-4 text-decoration-none fw-bold"
              data-testid="clearAllButton"
              onClick={() =>
                setFilters({
                  category: initialCategoryFilter,
                  status: initialStatusFilter,
                })
              }
            >
              Clear All
            </button>
          )}
        </div>

        {showGoToArchiveLink && <GoToArchiveLink style={{ marginTop: "auto" }} />}
      </div>
    </div>
  );
}
