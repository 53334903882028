import React from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import TextLabelValue from "../TextLabelValue/TextLabelValue";

import { useDateFormatter } from "client/hooks/useDateFormatter";
import { parseISO } from "date-fns";
import { ResultType } from "../../pages/PartnerGlobalSearch";
import { FormattedArtifact, FormattedItem } from "../../types";
import StatusBadge from "../../ui-components/StatusBadge";
import Badge, { BadgeSizesEnum } from "../Badge";
import { BadgeVariantsEnum } from "../Badge/index";
import { ClickableWrapper } from "../ClickableWrapper";
import { NoPhotoImagePlaceholder, StyledCarousel } from "../ImageCarousel";
import { Subheader2 } from "../typography";

export interface CardProps {
  artifact: FormattedArtifact;
  className?: string;
  lostOrFoundLabel: "lost" | "found";
  onClick?: () => void;
  isClaim?: boolean;
}

const { Item } = Carousel;
const StyledCarouselItem = styled(Item)`
  &&& {
    width: 192px;
    height: 256px;
    border: 1px solid ${props => props.theme.colors.gray8};
  }
`;

const StyledCarouselMobile = styled(StyledCarousel)`
  .carousel-inner {
    width: 192px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const ArtifactCard: React.FC<CardProps> = props => {
  const { artifact, className, lostOrFoundLabel, onClick, isClaim } = props;

  const dateFormatter = useDateFormatter();
  return (
    <ClickableWrapper
      clickable={typeof onClick !== "undefined"}
      className={`${String(className)} card mb-3  rounded-3 border-0 `}
      onClick={() => onClick && onClick()}
    >
      <CardWrapper className="p-35 pt-45 pt-sm-35">
        <div className="align-self-center">
          {artifact.images?.length !== 0 ? (
            <StyledCarouselMobile
              touch
              interval={null}
              indicators={false}
              controls={false}
              $showSingleImage
            >
              {artifact.images &&
                (artifact.images || []).map((file, index) => (
                  <StyledCarouselItem
                    key={index}
                    style={{ backgroundImage: `url(${file?.image})` }}
                  >
                    <span
                      role="img"
                      aria-label={`image ${index + 1} of ${artifact.images.length}`}
                    />
                  </StyledCarouselItem>
                ))}
            </StyledCarouselMobile>
          ) : (
            <NoPhotoImagePlaceholder width="192px" aspectRatio="3/4">
              <span role="img" aria-label="No image placeholder image" />
            </NoPhotoImagePlaceholder>
          )}
        </div>
        <div className="pt-4 pt-sm-0 ps-sm-35">
          <div className="d-flex flex-column h-100">
            <Badge
              size={BadgeSizesEnum.Small}
              className="mb-25 fw500"
              label={artifact.categoryName}
              variant={BadgeVariantsEnum.Contained}
            />
            <Subheader2 className="mb-25 word-break-break-all">{artifact.name}</Subheader2>
            <TextLabelValue
              label={`Date ${lostOrFoundLabel}`}
              value={
                artifact.date
                  ? dateFormatter(parseISO(String(artifact.date).split("T")[0]), "long")
                  : "-"
              }
              className="mb-25"
            />
            {Object.prototype.hasOwnProperty.call(artifact, "inventoryId") && (
              <TextLabelValue
                label="ID"
                value={(artifact as FormattedItem).inventoryId}
                className="mb-25"
              />
            )}
            <TextLabelValue
              className="mt-auto"
              label="Status"
              value={
                <div className="d-flex">
                  <StatusBadge
                    objectType={isClaim ? ResultType.CLAIMS : ResultType.ITEMS}
                    objectStatus={artifact.status}
                  />
                </div>
              }
            />
          </div>
        </div>
      </CardWrapper>
    </ClickableWrapper>
  );
};

export default ArtifactCard;
