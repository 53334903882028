import React from "react";
import { Col, Modal } from "react-bootstrap";
import styled from "styled-components";

const StyledModal = styled(Modal)<{ modalFooterColor: string }>`
  .modal-content {
    border: none;
  }
  .modal-footer {
    background: ${props =>
      props.modalFooterColor ? props.modalFooterColor : props.theme.colors.white};
    p {
      line-height: 1.5;
    }
  }
`;

interface BootstrapModal {
  show: boolean;
  subtitle: string;
  title: string;
  onHide: () => void;
  children: React.ReactNode;
  footerComponent: React.ReactNode;
  backdrop: string;
  modalFooterColor: string;
  size: string;
}

export enum ModalSize {
  Medium = "md",
}

export enum ModalBackdrop {
  Static = "static",
}

const BootstrapModal = (props: BootstrapModal) => {
  const {
    backdrop,
    children,
    footerComponent,
    show,
    modalFooterColor,
    onHide,
    size,
    subtitle,
    title,
  } = props;
  if (!children) return null;

  return (
    <StyledModal
      show={show}
      onHide={onHide}
      size={size}
      centered
      backdrop={backdrop}
      keyboard={false}
      modalFooterColor={modalFooterColor}
    >
      <Modal.Header className="row mx-0 align-items-center px-4 pt-45 border-bottom-0">
        <Col className="px-0">
          <h3 className="fs24 mb-0">{title}</h3>
        </Col>
        <Col xs="auto" className="px-0">
          <button type="button" className="btn-close m-0" onClick={onHide} aria-label="Close" />
        </Col>
        <p className="px-0 m-0">{subtitle}</p>
      </Modal.Header>
      <Modal.Body className="p-4">{children}</Modal.Body>
      <Modal.Footer className="py-3 px-4">{footerComponent}</Modal.Footer>
    </StyledModal>
  );
};

export default BootstrapModal;
