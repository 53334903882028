import { PartnerItemsSearchResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";

export default ({
  partnerId,
  query,
}: {
  partnerId: string;
  query: Record<string, any>;
}): Promise<PartnerItemsSearchResponse> => {
  const queryStr = objectToQueryString(query).toString();

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/?${queryStr}`,
  });
};
