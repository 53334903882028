import React, { useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";
import DonutChart, { DonutChartDataPoint } from "../../../components/DonutChart";
import DonutChartLegend from "./DonutChartLegend";

const width = 280;

const Container = styled.div`
  width: ${width}px;
  margin: 0 auto;
`;
const Title = styled.div`
  font-size: ${props => props.theme.fontSizes.lg};
  font-weight: bold;
  text-align: center;
  font-family: Raleway, opensans, sans-serif;
`;
const Subtitle = styled.div`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 500;
  text-align: center;
  font-family: Raleway, opensans, sans-serif;
`;
const DonutBox = styled.div`
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  width: ${width}px;
  height: ${width}px;
  display: flex;
  align-items: center;
`;
const DonutPositioner = styled.div`
  position: absolute;
`;
const DonutTextPositioner = styled.div`
  position: relative;
  width: calc(100% - 50px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TotalValue = styled.div`
  font-size: ${props => props.theme.fontSizes.biggest};
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  margin-bottom: 12px;
`;
const Unit = styled.div`
  font-size: ${props => props.theme.fontSizes.lg};
  font-weight: ${props => props.theme.fontWeights.default};
  font-family: Montserrat, sans-serif;
`;

interface Props {
  accessibilityTitle: string;
  data: DonutChartDataPoint[];
  displayLimit?: number;
  highlightHoveredData: boolean;
  percentageTotal?: string;
  subtitle: string;
  title: string;
  titleId: string;
  unit?: string;
}

const DonutChartWithLegend = (props: Props) => {
  const {
    accessibilityTitle,
    data,
    displayLimit,
    highlightHoveredData,
    percentageTotal,
    subtitle,
    title,
    titleId,
    unit,
  } = props;
  const totalValue = data.reduce((total, d) => total + d.value, 0);
  const theme = useTheme();
  const [selectedDataPoint, setSelectedDataPoint] = useState<DonutChartDataPoint>();
  const handleDataPointHighlighted = useCallback(dataPoint => {
    setSelectedDataPoint(dataPoint);
  }, []);

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <DonutBox>
        <DonutPositioner>
          <DonutChart
            accessibilityTitle={accessibilityTitle}
            height={width}
            shadow
            thickness={25}
            titleId={titleId}
            width={width}
            data={
              data.length > 0 ? data : [{ label: "None", value: 1, color: theme.chartColors.other }]
            }
            onDataPointHighlighted={highlightHoveredData ? handleDataPointHighlighted : undefined}
          />
        </DonutPositioner>
        <DonutTextPositioner>
          <TotalValue>
            {!percentageTotal ? selectedDataPoint?.value ?? totalValue : percentageTotal + "%"}
          </TotalValue>
          {unit && <Unit>{unit}</Unit>}
        </DonutTextPositioner>
      </DonutBox>
      <DonutChartLegend data={data} subtitle={subtitle} title={title} displayLimit={displayLimit} />
    </Container>
  );
};

export default DonutChartWithLegend;
