import BlankMessage from "client/components/BlankMessage";
import React from "react";
import astronautImg from "../../assets/astronaut.svg";

export default function DefaultError() {
  return (
    <BlankMessage alt="Boomerang astronaut" title={"Error"} image={String(astronautImg)}>
      We encountered an error. Please try again.
    </BlankMessage>
  );
}
