import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { GetPartnerReturnsRequest, PartnerReturnsCountByModeRequest } from "client/types";
import fetchReturnCategoryCount from "../fetchFunctions/fetchReturnCategoryCount";
import fetchReturnRecoveryMethodCount from "../fetchFunctions/fetchReturnRecoveryMethodCount";
import fetchReturns from "../fetchFunctions/fetchReturns";
import fetchReturnStatusCount from "../fetchFunctions/fetchReturnStatusCount";
import fetchReturnStorageLocationCount from "../fetchFunctions/fetchReturnStorageLocationCount";
import { OptionalReturnsCountQueryFilters } from "../partnerReturnCounts";
import baseQueries from "./baseQueries";

const returnQueries = {
  returns: (partnerId: string) => [...baseQueries.partner(partnerId), "returns"],
  returnsFiltered: ({ partnerId, filters }: GetPartnerReturnsRequest) =>
    queryOptions({
      queryKey: [...returnQueries.returns(partnerId), filters],
      queryFn: () => fetchReturns({ partnerId, filters }),
      enabled: !!partnerId && !!filters,
    }),
  returnCounts: (partnerId: string) => [...returnQueries.returns(partnerId), "count"],
  returnCountByCategory: (partnerId: string) => [
    ...returnQueries.returnCounts(partnerId),
    "category",
  ],
  returnCountByCategoryFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalReturnsCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...returnQueries.returnCountByCategory(partnerId), queryObject],
      queryFn: () =>
        fetchReturnCategoryCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  returnCountByStatus: (partnerId: string) => [...returnQueries.returnCounts(partnerId), "status"],
  returnCountByStatusFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalReturnsCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...returnQueries.returnCountByStatus(partnerId), queryObject],
      queryFn: () =>
        fetchReturnStatusCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  returnCountByRecoveryMethod: (partnerId: string) => [
    ...returnQueries.returnCounts(partnerId),
    "recoveryMethod",
  ],
  returnCountByRecoveryMethodFiltered: ({ partnerId, filters }: PartnerReturnsCountByModeRequest) =>
    queryOptions({
      queryKey: [...returnQueries.returnCountByRecoveryMethod(partnerId), filters],
      queryFn: () => fetchReturnRecoveryMethodCount({ partnerId, filters }),
      enabled: !!partnerId,
    }),
  returnCountByStorageLocation: (partnerId: string) => [
    ...returnQueries.returnCounts(partnerId),
    "storage",
  ],
  returnCountByStorageLocationFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalReturnsCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...returnQueries.returnCountByStorageLocation(partnerId), queryObject],
      queryFn: () =>
        fetchReturnStorageLocationCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
};

export default returnQueries;
