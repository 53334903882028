import classNames from "classnames";
import emptyBoxIcon from "client/assets/empty_box.svg";
import { PartnerRoutesEnum } from "client/components/Routes";
import { Button } from "client/ui-components";
import React from "react";
import { useHistory } from "react-router-dom";
import { UnclaimedSuggestionStatus } from "../../helpers";
import { Container, Description, TextContainer, Title, Wrapper } from "./styles";

interface Props {
  status: UnclaimedSuggestionStatus;
}

function toTitleAndDescription(status: UnclaimedSuggestionStatus) {
  switch (status) {
    case "donate":
      return {
        title: "No items left in the Donate category.",
        description: "Great work! All items tagged for donation have been reviewed.",
      };
    case "destroy":
      return {
        title: "No items left in the Destroy category.",
        description: "Great work! All items tagged for destruction have been reviewed.",
      };
    case "sell":
      return {
        title: "No items left in the Sell category.",
        description: "Great work! All items tagged for sale have been reviewed.",
      };
    case "store":
      return {
        title: "No items left in the Store category.",
        description: "Great work! All items tagged for storage have been reviewed.",
      };
    case "more-life":
      return {
        title: "No items left in the More Life category.",
        description:
          "Great work! All items tagged for sale via Boomerang’s More Life program have been reviewed.",
      };
    case "other":
      return {
        title: "No items left in the Other category.",
        description: "Great work! All items tagged as Other have been reviewed.",
      };
    case "give-to-staff":
      return {
        title: "No items left in the Give to Staff category.",
        description: "Great work! All items tagged to be offered to staff have been reviewed.",
      };
    case "restock":
      return {
        title: "No items left in the Restock category.",
        description: "Great work! All items tagged for restock have been reviewed.",
      };
    default:
      return {
        title: "No items left",
        description: "Great work! All items have been reviewed",
      };
  }
}

export default function EmptyState({ status }: Props) {
  const { title, description } = toTitleAndDescription(status);
  const history = useHistory();
  const onClick = () => history.push(PartnerRoutesEnum.INVENTORY_UNCLAIMED);
  return (
    <Wrapper data-testid="emptyState">
      <img src={emptyBoxIcon} alt={title} />
      <Container className={classNames("mw520")}>
        <TextContainer>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
        </TextContainer>
        <Button
          onClick={onClick}
          aria-label="Go back to Unclaimed"
          data-testid="backToUnclaimed"
          style={{
            padding: "12px 20px",
            fontSize: "16px",
          }}
        >
          Go back to Unclaimed
        </Button>
      </Container>
    </Wrapper>
  );
}
