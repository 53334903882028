import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";
import styled from "styled-components";

import PartnerProfileDropdown from "../../components/Dropdowns/PartnerProfileDropdown";
import MainHeader from "../../components/MainHeader";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import SimpleHeader from "../../components/SimpleHeader";
import SwitchAccountDesktop from "../../components/SwitchAccountDesktop";

import { updateCurrentPartner } from "../../context/auth/actions";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";
import useCurrentPartner from "../../utils/useCurrentPartner";

import { useAuthDispatch, useAuthState } from "../../context/auth";

import { isLgQuery } from "../../helpers/mediaQuery";
import { ClaimStatus, ReturnStatus, UserRoles } from "../../types/models";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import useClaimsCountByStatus from "client/hooks/data/partner/partnerClaimCounts/useClaimsCountByStatus";
import useReturnsCountByRecoveryMethod from "client/hooks/data/partner/partnerReturnCounts/useReturnsCountByMode";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import PartnerGlobalSearch from "../../pages/PartnerGlobalSearch";
import { PartnerMenuItems, PartnerMenuItemsNoLogout } from "../../types/menuElements";

const StyledContainer = styled(Container)`
  background-color: ${props => props.theme.colors.gray6};
`;

interface PortalViewProps {
  children: React.ReactElement;
  heading: string;
  leftArrowText?: string;
  leftArrowLink?: string;
  mountBodyWhite?: boolean;
  removeMobilePadding?: boolean;
  isOverview?: boolean;
  noMargin?: boolean;
}

const PortalView = (props: PortalViewProps) => {
  const {
    children,
    heading,
    leftArrowText,
    leftArrowLink,
    mountBodyWhite,
    removeMobilePadding,
    isOverview,
    noMargin,
  } = props;
  const { user } = useAuthState();
  const partnerId = useCurrentPartner();
  const history = useHistory<{ from?: string }>();

  const authDispatch = useAuthDispatch();
  const { partnerLocations } = usePartnerLocations(partnerId);

  const { resetPartnerUserLocation, setPartnerUserLocation, partnerUserLocation } =
    usePartnerLocationStore();

  const { returnsCountByRecoveryMethod } = useReturnsCountByRecoveryMethod({
    partnerId,
    filters: {
      status: ReturnStatus.PENDING,
      ...(partnerUserLocation.id !== "" && {
        lost_locations: [partnerUserLocation.id],
      }),
    },
  });

  const { data: claimsCountByStatus } = useClaimsCountByStatus({
    partnerId,
    partnerLocationId: partnerUserLocation.id,
  });

  const totalPendingReturnsCount = (returnsCountByRecoveryMethod || [])?.reduce((acc, cur) => {
    acc += cur.count;
    return acc;
  }, 0);
  const location = useLocation();

  const isLg = isLgQuery();
  // On no margin we do negative margin to cancel the margin from the header
  const marginTop = noMargin ? "mt-n1" : removeMobilePadding ? "mt-1" : "mt-4";

  const { isSearchModeOn } = useContext(PartnerGlobalSearchContext);

  const onAddItemClick = () => {
    history.push(`/additem`);
  };

  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
  const [showSwitchAccount, setShowSwitchAccount] = useState<boolean>(false);
  const resetFilters = () => {
    history.push({
      pathname: history.location.pathname,
      search: "",
    });
  };

  useEffect(() => {
    if (mountBodyWhite) {
      return;
    }
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  return (
    <StyledContainer fluid className="px-0">
      {isLg && showSwitchAccount ? (
        <SwitchAccountDesktop
          onLeftArrowClick={() => {
            setShowSwitchAccount(false);
            setHamburgerOpen(false);
          }}
          onPartnerSelect={newPartnerId => {
            history.push("/partner/overview");
            setShowSwitchAccount(false);
            setHamburgerOpen(false);
            if (newPartnerId !== partnerId) {
              updateCurrentPartner(authDispatch, newPartnerId);
              resetPartnerUserLocation();
            }
          }}
          partnerId={partnerId}
        />
      ) : leftArrowText && leftArrowLink ? (
        <>
          <SimpleHeader
            currentPartnerLocation={partnerUserLocation}
            leftArrowText={leftArrowText}
            maxWidth="1072px"
            onLeftArrowClick={() => {
              const lastPath = history.location.state?.from;
              if (lastPath === leftArrowLink) {
                history.goBack();
              } else {
                history.push(leftArrowLink);
              }
            }}
            onLocationSelection={newLocation => {
              setPartnerUserLocation(newLocation);
              resetFilters();
            }}
            partnerLocations={partnerLocations}
            title={heading}
          />
          <main className={`${marginTop} mb-4 pb-3`}>
            {React.cloneElement(children, {
              partnerId: partnerId,
            })}
          </main>
        </>
      ) : (
        <>
          <MainHeader
            isEndUser={false}
            title={isOverview ? `Hi, ${user.full_name?.split(" ")[0]}!` : heading}
            logoOverride
            hamburgerOpen={hamburgerOpen}
            onHamburgerClick={() => {
              setHamburgerOpen(!hamburgerOpen);
              setShowSwitchAccount(false);
            }}
            handlePrimaryButtonClick={isOverview ? onAddItemClick : undefined}
            primaryButtonText={isOverview ? "Add found item" : undefined}
            dropdown={
              <PartnerProfileDropdown
                onSwitchAccount={() => {
                  setShowSwitchAccount(true);
                  setHamburgerOpen(true);
                }}
                partnerId={partnerId}
              />
            }
            menuItems={PartnerMenuItemsNoLogout}
            pathname={location.pathname}
            pendingReturnsCount={totalPendingReturnsCount}
            potentialMatchCount={Number(
              (claimsCountByStatus && claimsCountByStatus[ClaimStatus.HAS_MATCHES]) ?? 0,
            )}
            currentPartnerLocation={partnerUserLocation}
            partnerLocations={partnerLocations}
            onLocationSelection={newLocation => {
              setPartnerUserLocation(newLocation);
              resetFilters();
            }}
            isOverviewUserRole={isOverview ? UserRoles.PARTNER_USER : undefined}
          />
          {hamburgerOpen && !isLg ? (
            <MobileMenu
              handleOnClick={() => {
                setHamburgerOpen(!hamburgerOpen);
                setShowSwitchAccount(false);
              }}
              menuItems={PartnerMenuItems}
              partnerId={partnerId}
              potentialMatchCount={Number(
                claimsCountByStatus && claimsCountByStatus[ClaimStatus.HAS_MATCHES],
              )}
              showSwitchAccount={showSwitchAccount}
              setShowSwitchAccount={setShowSwitchAccount}
            />
          ) : isSearchModeOn ? (
            <PartnerGlobalSearch />
          ) : (
            <main className={`${marginTop} mb-4 pb-3`}>
              {React.cloneElement(children, {
                partnerId: partnerId,
              })}
            </main>
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default PortalView;
