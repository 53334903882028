import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useBulkItemUpdate } from "client/actions/partnerItems";
import { PartnerRoutesEnum } from "client/components/Routes";
import { useCategoryCounts } from "client/hooks/data/partner/partnerItemCounts";
import queryFactory from "client/hooks/data/partner/queryFactory";
import useCategories from "client/hooks/data/user/useCategories";
import PortalView from "client/scenes/views/PortalView";
import { PartnerItem } from "client/types";
import DefaultError from "client/ui-components/DefaultError";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { CategoryFilter } from "./components/CategoryFilter";
import { ClearFilterButton } from "./components/ClearFilterButton";
import { DateFilter } from "./components/DateFilter";
import RemoveFromListButton from "./components/RemoveFromListButton";
import SetUnclaimedStatusButton, {
  SetUnclaimedStatusButtonProps,
} from "./components/SetUnclaimedStatusButton";
import SuggestionTable from "./components/SuggestionTable";
import { UnclaimedDatePickerModal } from "./components/UnclaimedDatePicker";
import { UnclaimedSuggestionTitle } from "./components/UnclaimedSuggestionTitle";
import {
  toastResetFailure,
  toastResetSuccess,
  toastUpdateFailure,
  toastUpdateSuccess,
} from "./components/UnclaimedToast";
import { useUnclaimedSuggestionFilter } from "./data/useUnclaimedSuggestionFilters";
import {
  toSuggestionListName,
  toUnclaimedStatus,
  unclaimedSuggestionHeading,
  UnclaimedSuggestionStatus,
  unclaimedSuggestionStatuses,
} from "./helpers";
import { FilterContainer, TableContainer, Wrapper } from "./styles";

type UnclaimedSuggestionProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

const PAGE_SIZE = 12;

const UnclaimedSuggestion = ({ className, ...props }: UnclaimedSuggestionProps) => {
  const partnerId = useCurrentPartner();
  const [tableKey, setTableKey] = React.useState(`${Math.random()}`);
  const [showDateSelector, setShowDateSelector] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<PartnerItem[]>([]);
  const { status } = useParams<{ status: UnclaimedSuggestionStatus }>();
  const { data: categories } = useCategories();
  const [count, setCount] = React.useState(0);
  const { mutateAsync: updateItems, isPending: isUpdating } = useBulkItemUpdate();
  const { mutateAsync: removeItems, isPending: isRemoving } = useBulkItemUpdate();
  const queryClient = useQueryClient();

  const unclaimedStatus = toUnclaimedStatus(status);
  const heading = unclaimedSuggestionHeading[status];
  const listName = toSuggestionListName(status);
  const { filters } = useUnclaimedSuggestionFilter({ count, limit: PAGE_SIZE });
  const { categories: categoryFilters } = useCategoryCounts({
    ...filters,
    lost_locations: filters.lost_location ? [filters.lost_location] : undefined,
  });
  const { data, error, isLoading } = useQuery(
    queryFactory.itemsFiltered({ partnerId, query: filters }),
  );

  const claims = React.useMemo(() => {
    return (data?.results ?? []).map(item => ({
      ...item,
      category: categories?.find(i => i.id === item.category)?.name ?? item.category,
    }));
  }, [data?.results, categories]);

  React.useEffect(() => {
    setCount(data?.count ?? 0);
  }, [data?.count]);

  const onSetUnclaimed = React.useCallback(
    async (selectedItems: PartnerItem[]) => {
      const unclaimedStatus = toUnclaimedStatus(status);
      const count = selectedItems.length ?? 0;
      if (!unclaimedStatus || !count) return;
      setSelectedItems(selectedItems);
      setShowDateSelector(true);
    },
    [status],
  );

  const onSetDate = React.useCallback(
    async (date: Date) => {
      const unclaimedStatus = toUnclaimedStatus(status);
      const count = selectedItems.length ?? 0;
      if (!unclaimedStatus || !count) return;
      try {
        const response: any = await updateItems({
          partner_id: partnerId,
          item_ids: selectedItems.map(item => item.id),
          unclaimed_status: unclaimedStatus,
          unclaimed_status_set_at: date,
        });
        setShowDateSelector(false);
        if (!response?.count) throw new Error("Failed to update");
        toastUpdateSuccess(count);
        setTableKey(`${Math.random()}`);
        queryClient.invalidateQueries({
          queryKey: queryFactory.items(partnerId),
          refetchType: "all",
        });
      } catch (e) {
        console.error(e);
        toastUpdateFailure(count);
      }
    },
    [partnerId, selectedItems, status, updateItems, queryClient],
  );

  const onRemove = React.useCallback(
    async (items: PartnerItem[]) => {
      const count = items.length ?? 0;
      if (!count) return;
      try {
        const response: any = await removeItems({
          partner_id: partnerId,
          item_ids: items.map(item => item.id),
          suggested_unclaimed_status: "",
        });
        if (!response?.count) throw new Error("Failed to update");
        setShowDateSelector(false);
        toastResetSuccess(count, listName);
        setTableKey(`${Math.random()}`);
        queryClient.invalidateQueries({
          queryKey: queryFactory.items(partnerId),
          refetchType: "all",
        });
      } catch (e) {
        console.error(e);
        toastResetFailure(count, listName);
      }
    },
    [listName, partnerId, queryClient, removeItems],
  );

  if (error) return <DefaultError />;
  if (!unclaimedSuggestionStatuses.includes(status)) {
    return <Redirect to={PartnerRoutesEnum.INVENTORY_UNCLAIMED} />;
  }
  const hasFilters =
    filters.category.length > 0 ||
    (filters.collected_at_after != "" && filters.collected_at_before != "");

  return (
    <PortalView
      children={
        <Wrapper {...props} className={classNames("mb-5 pt-4 px-0", className)}>
          <TableContainer>
            <UnclaimedSuggestionTitle status={status} />
            <FilterContainer className="d-flex flex-column flex-lg-row align-items-lg-center">
              <DateFilter />
              <CategoryFilter categories={categoryFilters} />
              <ClearFilterButton />
            </FilterContainer>
            <SuggestionTable
              key={tableKey}
              status={status}
              items={claims}
              count={count}
              hasFilters={hasFilters}
              pageSize={PAGE_SIZE}
              isLoading={isLoading}
              bulkActions={[
                ({ selectedItems }) => (
                  <RemoveFromListButton selectedItems={selectedItems} onClick={onRemove} />
                ),
                ({ selectedItems }: SetUnclaimedStatusButtonProps) => (
                  <SetUnclaimedStatusButton
                    selectedItems={selectedItems}
                    unclaimedStatus={unclaimedStatus}
                    onClick={onSetUnclaimed}
                  />
                ),
              ]}
              bulkBottomActions={[
                ({ selectedItems }) => (
                  <RemoveFromListButton
                    variant="large"
                    disabled={isUpdating}
                    isLoading={isRemoving}
                    selectedItems={selectedItems}
                    onClick={onRemove}
                  />
                ),
                ({ selectedItems }: SetUnclaimedStatusButtonProps) => (
                  <SetUnclaimedStatusButton
                    variant="large"
                    disabled={isRemoving}
                    isLoading={isUpdating}
                    selectedItems={selectedItems}
                    unclaimedStatus={unclaimedStatus}
                    onClick={onSetUnclaimed}
                  />
                ),
              ]}
            />
          </TableContainer>
          <UnclaimedDatePickerModal
            isOpened={showDateSelector}
            isLoading={isUpdating}
            onDismiss={setShowDateSelector}
            onNext={onSetDate}
          />
        </Wrapper>
      }
      heading={heading}
      leftArrowText={`Inventory > Unclaimed > ${heading}`}
      leftArrowLink={PartnerRoutesEnum.INVENTORY_UNCLAIMED}
      removeMobilePadding
    />
  );
};

export default UnclaimedSuggestion;
