import React from "react";
import theme from "../../../../theme";
import { Heading, Text } from "../../../../ui-components";
import EmailForm from "./EmailForm";
import { Wrapper } from "./styles";

export default function EmailView() {
  return (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Sign in
      </Text>

      <Heading
        variant="h2"
        as="h1"
        className="mb-25 mb-md-3 text-md-center"
        data-testid="header-title"
      >
        Check an existing claim
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center">
        Provide the email you used to submit the claim and we’ll send you a verification code to
        sign in.
      </Text>

      <EmailForm className="mt-3" />
    </Wrapper>
  );
}
