import { ShipmentResponse, TransactionStatus } from "../types/actions";
import {
  Claim,
  ClaimStatus,
  ReturnModeEnum,
  ReturnStatus,
  ShipmentStatus,
  UserReturn,
} from "../types/models";
import {
  ClaimStatusStrings,
  PartnerReturnPickupStatusStringEnum,
  PartnerReturnShippingStatusStringEnum,
  ReturnStatusStrings,
} from "../types/theme";

export const getUserClaimStatusString = ({
  recoveryMethod,
  claim,
  returnObject,
  shipment: shipmentObject,
}: {
  recoveryMethod: ReturnModeEnum | undefined;
  claim: Claim | null;
  returnObject?: UserReturn | null;
  shipment?:
    | ShipmentResponse
    | {
        status: ShipmentStatus;
        transactions: Array<{ status: TransactionStatus }>;
      }
    | null;
}) => {
  const returnPending = returnObject?.status === ReturnStatus.PENDING;

  const isAwaitingPayment =
    returnPending &&
    recoveryMethod !== ReturnModeEnum.PICKUP &&
    (returnObject.shipment === null ||
      shipmentObject === null ||
      !shipmentObject?.transactions?.some(
        transaction => transaction.status === TransactionStatus.SUCCESS,
      ));

  switch (true) {
    case claim && claim?.status && claim?.status !== ClaimStatus.COMPLETED:
      return claim ? ClaimStatusStrings[claim?.status] : undefined;

    case returnPending && recoveryMethod === ReturnModeEnum.PICKUP:
      return ReturnStatusStrings.READY_FOR_PICKUP;

    case isAwaitingPayment:
      return PartnerReturnShippingStatusStringEnum.AWAITING_PAYMENT;

    case returnPending &&
      recoveryMethod !== ReturnModeEnum.PICKUP &&
      (!returnObject?.shipment || ShipmentStatus.PENDING === shipmentObject?.status):
      return PartnerReturnShippingStatusStringEnum.PROCESSING;

    case returnPending && ShipmentStatus.PACKAGED === shipmentObject?.status:
      return PartnerReturnShippingStatusStringEnum.PACKAGED;

    case returnPending &&
      recoveryMethod !== ReturnModeEnum.PICKUP &&
      ShipmentStatus.SHIPPED === shipmentObject?.status:
      return PartnerReturnShippingStatusStringEnum.IN_TRANSIT;

    case !returnPending && recoveryMethod === ReturnModeEnum.PICKUP:
      return PartnerReturnPickupStatusStringEnum.RETURNED;

    case !returnPending && recoveryMethod !== ReturnModeEnum.PICKUP:
      return PartnerReturnShippingStatusStringEnum.DELIVERED;

    default:
  }
};
