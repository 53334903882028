import styled from "styled-components";
import { BigChevronDown } from "../../../assets/icons/icons";

export const Input = styled.select`
  color: ${props => props.theme.colors.gray1};
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.sm};
  border: 1px solid ${props => props.theme.colors.gray3};
  font-weight: ${props => props.theme.fontWeights.default};
  padding-top: 14px;
  padding-bottom: 14px;
  appearance: none;
  height: calc(3.5rem + 2px);

  &.floating {
    padding-top: 24px;
    padding-bottom: 4px;
  }

  &&&:disabled,
  &&&:disabled:hover,
  &&&:disabled:focus,
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    cursor: not-allowed;
    background: ${props => props.theme.ui.colors.gray400};
  }

  &:not(:disabled) {
    &:invalid {
      color: ${props => props.theme.colors.gray3};
    }

    &:not(:invalid):not(.hasError) {
      &.withPlaceholder {
        font-size: ${props => props.theme.fontSizes.sm};
        color: ${props => props.theme.colors.gray3};

        &:hover,
        &:focus,
        &:active {
          color: ${props => props.theme.colors.gray3};
        }
      }

      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.colors.gray1};
        border-color: ${props => props.theme.colors.primaryBlue} !important;
        outline-style: none;
        box-shadow: none;
        box-shadow: 0 0 0 1px ${props => props.theme.colors.primaryBlue};
      }
    }

    //&:not(:focus):not(:hover):not(:disabled) {
    &.hasError {
      border-color: ${props => props.theme.colors.red};
      box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};
    }

    //}
  }
`;

export const Carat = styled(BigChevronDown)`
  display: inline-flex;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const Label = styled.label`
  position: absolute;
  top: 0.625rem;
  left: 1.25rem;
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.default};
  color: ${props => props.theme.colors.gray3};

  transform-origin: 0 0;
  transform: scale(0.75);
`;
