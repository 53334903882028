import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import SectionContainer from "../../../../ui-components/SectionContainer/index";

export const StyledSectionContainer = styled(SectionContainer)`
  padding: 0 20px !important;

  height: 758px;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    height: 958px;
  }

  @media (min-width: 390px) {
    height: 854px;
  }

  @media (min-width: 428px) {
    height: 774px;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 654px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 726px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: 678px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    height: 630px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    height: 582px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 40px !important;
  }
`;

export const StyledCarousel = styled(Carousel)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: flex;
  }

  .carousel-inner {
    display: flex;
  }
`;

export const Quote = styled.p`
  margin: 0;
  margin-top: 64px;
  text-align: center;
  font-family: ${props => props.theme.fontFamilies.heading};
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes.xl};
  line-height: 32px;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 36px;
    line-height: 48px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 96px;
  }
`;

export const StyledLogo = styled.img`
  height: 75px;
`;

export const StyledCircleButton = styled.div<{ position?: string }>`
  background-color: ${props => props.theme.colors.white};
  width: 56px;
  height: 56px;
  padding: 16px 21px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.gray4};
  backdrop-filter: blur(4px);
  margin: ${props => (props.position === "left" ? "0 16px 0 0" : "0 0 0 16px")};
  opacity: 1;
`;

export const Separator = styled.div`
  border: 1.5px solid ${props => props.theme.colors.primaryBlue};
  width: 80px;
  height: 0;
  margin: 32px 0;
`;

export const StyledControlsContainer = styled.div`
  display: flex;
  margin-top: 48px;
  margin-bottom: 64px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 96px;
  }
`;
