import React, { forwardRef, Ref } from "react";
import { RadioInput } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;

const Radio = forwardRef(({ children, className, ...props }: Props, ref: Ref<HTMLInputElement>) => {
  return (
    <div
      className={`${
        className ? className : ""
      } form-check d-flex flex-row align-items-center gap-35 ms-4`}
    >
      <RadioInput {...props} className="form-check-input flex-shrink-0" ref={ref} type="radio" />

      <label className="form-check-label"> {children}</label>
    </div>
  );
});

export default Radio;
