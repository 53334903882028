import React, { useContext, useEffect, useRef, useState } from "react";

import useCategories from "client/hooks/data/user/useCategories";
import scrollToTop from "client/utils/scrollToTop";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import Loader from "../../../../../../components/Loader";
import { LostItemCreationContext } from "../../../../../../context";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import { StyledIncorrectMessage } from "../OTP/styles";
import cardWalletPurseImg from "./cardWalletPurse.png";
import clothingImg from "./clothing.png";
import { lostItemCategoryCopy } from "./content";
import idImg from "./id.png";
import luggageImg from "./luggage.png";
import otherImg from "./other.png";
import phoneImg from "./phone.png";
import { CategoryOption, CategoryOptionsContainer, Content } from "./styles";

const categoryImages = {
  phone: phoneImg,
  clothing: clothingImg,
  id: idImg,
  luggage: luggageImg,
  cardWalletPurse: cardWalletPurseImg,
  other: otherImg,
};
const OTHER_CATEGORY_ID = "other";

type CategorySelectionArgs = {
  reviewUpdate?: boolean;
};

export default function CategorySelection({ reviewUpdate = false }: CategorySelectionArgs) {
  const isMd = isMdQuery();

  const { category, setCategory, setStep, prevCategory, setPrevCategory } =
    useContext(LostItemCreationContext);

  const [hasError, setHasError] = useState<boolean>(false);
  const categories = useCategories();
  const [activeStates, setActiveStates] = useState(new Array(categories.data?.length).fill(false));
  const radioRefs = useRef<HTMLInputElement[]>([]);
  const labelRefs = useRef<HTMLLabelElement[]>([]);

  const handleKeyDown = (event, currentIndex) => {
    const prevIndex = currentIndex === 0 ? labelRefs.current.length - 1 : currentIndex - 1;
    const nextIndex = currentIndex === labelRefs.current.length - 1 ? 0 : currentIndex + 1;

    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      event.preventDefault();
      labelRefs.current[prevIndex].focus();
    } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      event.preventDefault();
      labelRefs.current[nextIndex].focus();
    }
  };

  const nextStep = reviewUpdate ? "review" : "photos";

  scrollToTop();

  useEffect(() => {
    setPrevCategory(category);
  }, []);

  const onSelect = category => {
    setHasError(false);
    setCategory(category);
  };

  const handleOptionClick = index => {
    setActiveStates(prevActiveStates => {
      const newActiveStates = [...prevActiveStates];
      newActiveStates[index] = true;
      return newActiveStates;
    });
    setTimeout(() => {
      setActiveStates(prevActiveStates => {
        const newActiveStates = [...prevActiveStates];
        newActiveStates[index] = false;
        return newActiveStates;
      });
    }, 100);
  };

  const categoryOptions = categories.isPending ? (
    <Loader className="mx-auto" />
  ) : (
    [...(categories.data ?? []), { id: OTHER_CATEGORY_ID, name: "Other", icon: "other" }]
      .filter(({ icon }) => icon)
      .map(({ id, name, icon }, index) => {
        return (
          <>
            <input
              ref={el => (radioRefs.current[index] = el!)}
              type="radio"
              name="category"
              className={`form-check-input`}
              id={`category-${id}`}
              onChange={() => onSelect([id, name])}
              checked={category[0] === id}
            />
            <CategoryOption
              className={activeStates[index] ? "active" : ""}
              autoFocus={index === 0}
              ref={el => (labelRefs.current[index] = el!)}
              htmlFor={`category-${id}`}
              data-testid={`category-${id}`}
              tabIndex={index === 0 ? 0 : -1}
              onFocus={() => {
                onSelect([id, name]);
                handleOptionClick(index);
              }}
              onKeyDown={e => handleKeyDown(e, index)}
              onClick={() => handleOptionClick(index)}
            >
              <img
                src={categoryImages[icon as string]}
                alt={`image of ${name.toLowerCase()} graphic`}
              />
              <Text textAlign={"center"} fontWeight={fontWeights.normal}>
                {name}
              </Text>
            </CategoryOption>
          </>
        );
      })
  );

  return (
    <Content>
      <Text
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.subheading}
      </Text>
      <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
        {lostItemCategoryCopy.heading}
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight={fontWeights.normal}
        lineHeight={1.5}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.supportingText}
      </Text>
      <CategoryOptionsContainer
        className="mt-3"
        data-testid="categoryOptionsContainer"
        hasError={hasError}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (category[0] === OTHER_CATEGORY_ID) {
              reviewUpdate ? setStep("additionalCategoryUpdate") : setStep("additionalCategory");
            } else {
              setStep(nextStep);
            }
          }
        }}
      >
        {categoryOptions}
      </CategoryOptionsContainer>

      {hasError && (
        <StyledIncorrectMessage data-testid="requiredErrorMessage">
          {lostItemCategoryCopy.categoryRequiredErrorMessage}
        </StyledIncorrectMessage>
      )}

      <Button
        data-testid="nextButton"
        aria-label="next button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        type="submit"
        onClick={() => {
          if (category[0] === "") return setHasError(true);
          if (category[0] === OTHER_CATEGORY_ID) {
            reviewUpdate ? setStep("additionalCategoryUpdate") : setStep("additionalCategory");
          } else {
            setStep(nextStep);
          }
        }}
      >
        {reviewUpdate ? lostItemCategoryCopy.nextButtonUpdate : lostItemCategoryCopy.nextButton}
      </Button>
      <Button
        aria-label="back button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        data-testid="backButton"
        variant="outline"
        onClick={() => {
          if (reviewUpdate) {
            if (prevCategory[0] === "other") return setHasError(true);
            setCategory(prevCategory);
            setStep("review");
          } else {
            setStep("date");
          }
        }}
      >
        {!reviewUpdate && (
          <ArrowLeft
            className="m-2"
            accessibilityTitle={lostItemCategoryCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
        )}
        <Text fontWeight={fontWeights.bold}>
          {reviewUpdate
            ? lostItemCategoryCopy.previousButtonUpdate
            : lostItemCategoryCopy.previousButton}
        </Text>
      </Button>
    </Content>
  );
}
