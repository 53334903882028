import React, { useContext, useEffect, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { useTriggerMatchingForClaim } from "../../../../actions/partnerMatches";
import { PartnerClaimContext } from "../../../../context";
import history from "../../../../context/history";
import { ClaimStatus, PartnerClaim } from "../../../../types";
import { Button } from "../../../../ui-components";
import useCurrentPartner from "../../../../utils/useCurrentPartner";
import { Wrapper } from "./styles";

export default function SubMenu({ claim }: { claim: PartnerClaim }) {
  const ref = useRef<HTMLDivElement>(null);
  const { setSubMenuHeight } = useContext(PartnerClaimContext);

  useEffect(() => {
    function callback() {
      if (ref.current === null) return;

      setSubMenuHeight(ref.current.clientHeight);
    }

    callback();

    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [ref.current]);

  const partnerId = useCurrentPartner();
  const [triggerMatching, { data, error }, reset] = useTriggerMatchingForClaim();

  useEffect(() => {
    if (error) {
      toast.error(`Error from server: ${error}`);
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (data && data.status.includes("success")) {
      history.push(`/partner/matches/matching/?claimId=${claim.id}&lookingForMatches`, {
        returnPath: `${location.pathname}?back=/partner/claims`,
      });
    }
  }, [data]);

  const content = useMemo<React.ReactNode>(() => {
    if (claim === null) return null;

    switch (claim.status) {
      case ClaimStatus.HAS_MATCHES:
        return (
          <Button
            size="2xl"
            onClick={() => {
              history.push(`/partner/matches/matching/?claimId=${claim.id}`, {
                returnPath: "/partner/claims",
              });
            }}
            aria-label="Review match"
          >
            Review match
          </Button>
        );
      case ClaimStatus.NEW:
      case ClaimStatus.NO_MATCHES:
        return (
          <Button
            size="2xl"
            onClick={async () => {
              triggerMatching(partnerId, claim.id);
            }}
            aria-label="Look for matches"
          >
            Look for matches
          </Button>
        );
      default:
        return null;
    }
  }, [claim]);

  return content === null ? null : (
    <Wrapper ref={ref} className="d-flex d-lg-none flex-column gap-35">
      {content}
    </Wrapper>
  );
}
