import styled from "styled-components";
import Loader from "../../components/Loader";

export const StyledLoader = styled(Loader)<{
  bannerHeight: number;
}>`
  min-height: calc(100vh - ${props => props.bannerHeight}px);
`;

export const Container = styled.div<{
  bannerHeight: number;
}>`
  min-height: calc(100vh - ${props => props.bannerHeight}px);
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 max(16px, calc(50% - 1280px / 2));
`;
