import * as SelectPrimitive from "@radix-ui/react-select";
import { SelectItemProps, SelectProps } from "@radix-ui/react-select";
import { SmallText } from "client/components/typography";
import { ALL_OPTION } from "client/hooks";
import { RETURNS_ALL } from "client/hooks/data/partner/partnerReturnCounts";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Check, SmallChevron } from "../../assets/icons/icons";
import Text from "../Text";
import {
  StyledContainer,
  StyledContent,
  StyledHint,
  StyledItem,
  StyledItemIndicator,
  StyledLabel,
  StyledTrigger,
  StyledViewport,
} from "./styles";

type Props = {
  label?: string;
  isLoading?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
  hasError?: boolean;
  hint?: string;
  theme?: "primary" | "secondary";
  style?: React.CSSProperties;
  triggerStyle?: React.CSSProperties;
  size?: "xs" | "sm" | "md";
  showFullOptions?: boolean;
} & SelectProps;

type ItemProps = {
  size?: "xs" | "sm" | "md";
} & SelectItemProps;

type FilterSelectProps = {
  options: { name: string; count: number; value: string }[];
} & Props;

export const Select = ({
  children,
  label,
  placeholder,
  hasError,
  hint,
  isLoading,
  size = "md",
  theme = "primary",
  style,
  showFullOptions,
  ...props
}: Props) => {
  const isDisabled = isLoading || !children;

  return (
    <SelectPrimitive.Root disabled={isDisabled} {...props}>
      <StyledContainer style={style}>
        <StyledLabel data-testid="filterOption" data-size={size}>
          {label}
        </StyledLabel>
        <StyledTrigger
          data-error={hasError}
          data-size={size}
          disabled={isDisabled}
          data-testid="dropdown-trigger"
          data-theme={theme}
        >
          <SelectPrimitive.Value placeholder={placeholder} />
          <SmallChevron
            accessibilityTitle="Open"
            titleId="Open"
            stroke={hasError ? "rgba(176, 17, 33, 1)" : "#525860"}
          />
        </StyledTrigger>
        {hint && <StyledHint data-error={hasError}>{hint}</StyledHint>}
        {children && (
          <StyledContent
            position="popper"
            collisionPadding={15}
            showFullOptions={showFullOptions}
            data-testid="dropdown-content"
          >
            <StyledViewport data-size={size}>{children}</StyledViewport>
          </StyledContent>
        )}
      </StyledContainer>
    </SelectPrimitive.Root>
  );
};

export const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ children, size, ...props }, forwardedRef) => (
    <StyledItem {...props} ref={forwardedRef} data-size={size}>
      <SelectPrimitive.ItemText>
        {size == "md" || size == "sm" ? (
          <Text className="text-truncate text-nowrap">{children}</Text>
        ) : (
          <SmallText className="text-truncate text-nowrap">{children}</SmallText>
        )}
      </SelectPrimitive.ItemText>
      <StyledItemIndicator>
        <Check accessibilityTitle="Selected" titleId="Selected" color="rgba(7, 114, 228, 1)" />
      </StyledItemIndicator>
    </StyledItem>
  ),
);

export const FilterSelect = ({
  label,
  value,
  onValueChange,
  options,
  style,
  ...props
}: FilterSelectProps) => {
  const isMd = !useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <Select
      label={label}
      size="sm"
      defaultValue={ALL_OPTION}
      value={value || RETURNS_ALL}
      onValueChange={onValueChange}
      showFullOptions={isMd}
      style={style}
      {...props}
    >
      {options.map(option => (
        <SelectItem key={option.value} value={option.value} size="sm">
          {option.name} ({option.count})
        </SelectItem>
      ))}
    </Select>
  );
};
