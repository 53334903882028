import React from "react";
import SuperMatchIcon from "../../../../assets/ui-icons/SuperMatchIcon";
import history from "../../../../context/history";
import theme from "../../../../theme";
import { PartnerClaim } from "../../../../types";
import { Button, Text } from "../../../../ui-components";

function ReviewMatchCTA({ claim }: { claim: PartnerClaim }) {
  return (
    <div className="d-flex flex-grow-1 justify-content-end align-items-center gap-35">
      <div className="d-none d-lg-flex flex-row align-items-center gap-35">
        <div className="d-flex flex-row align-items-center gap-25">
          <SuperMatchIcon
            accessibilityTitle="review-match-icon"
            titleId="review-match-icon"
            color={theme.ui.colors.primary500}
          />

          <Text color={theme.ui.colors.white}>Potential matches for this claim!</Text>
        </div>

        <Button
          aria-label="review matches"
          data-testid="reviewMatchesButton"
          size="2xl"
          onClick={() => {
            history.push(`/partner/matches/matching/?claimId=${claim.id}`, {
              returnPath: "/partner/claims",
            });
          }}
          icon={
            <SuperMatchIcon accessibilityTitle="review-match-icon" titleId="review-match-icon" />
          }
        >
          Review match
        </Button>
      </div>
    </div>
  );
}

export default ReviewMatchCTA;
