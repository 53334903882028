import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "lato";
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
  transition:
    max-width,
    padding-bottom 0.3s ease-in-out;
  padding-bottom: 30px;
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const HeaderWrapper = styled.div`
  color: ${props => props.theme.ui.colors.white};
  padding: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }
`;

export const GoBackButton = styled.a`
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  color: black;
`;

export const SubLocationItem = styled.a`
  width: 100%;
  background: transparent;
  border: 1px solid ${props => props.theme.ui.colors.gray500};
  outline: 1px solid transparent;
  border-radius: 8px;
  transition:
    background-color,
    border-color,
    outline-color 0.2s ease-in-out;
`;

export const CardContent = styled.div`
  overflow: auto;
  padding: 0px 0px 0 0px;

  padding-inline: 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-bottom: 10px;
  }
`;

export const CardContainer = styled.div`
  margin-top: 16px;
  border-radius: 10px;
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  overflow: hidden;
  background: #fff;

  & > * {
    overflow: hidden;
  }
`;
