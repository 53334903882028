import classNames from "classnames";
import React from "react";
import { ArrowRight } from "../../assets/icons/icons";
import Loader from "../../components/Loader";
import history from "../../context/history";
import theme from "../../theme";
import { BigNumber, PillLink, PillWrapper, SubText, TitleWrapper } from "./styles";

export type StatPillProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  count: number;
  icon: React.ReactNode;
  loading: boolean;
  href: string;
  title: string;
  total?: {
    count: number;
    label: string;
  };
};

function capitalize(str: string) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase().concat(word.slice(1)))
    .join("");
}

const StatPill: React.FC<StatPillProps> = ({
  count,
  icon,
  loading,
  href,
  title,
  total,
  ...props
}: StatPillProps) => {
  const countIsZero = count === undefined || count < 1;

  return (
    <PillWrapper
      {...props}
      className={classNames("gap-25 py-35 px-35 px-lg-45", countIsZero ? "cursor-default" : "")}
    >
      <TitleWrapper className="gap-25 fw500 justify-content-between">
        <div className="d-flex flex-row gap-25">
          {icon}

          {title}
        </div>

        <PillLink onClick={() => history.push(href)}>
          <ArrowRight
            color={theme.colors.gray3}
            className="ms-auto"
            accessibilityTitle={`Go to ${title}`}
            titleId={`ArrowRight${capitalize(title).replace(/\s/g, "")}Title`}
          />
        </PillLink>
      </TitleWrapper>

      {loading ? (
        <Loader className="h-100" />
      ) : (
        <>
          <BigNumber>{!countIsZero ? count : "-"}</BigNumber>

          {total !== undefined && (
            <SubText className="fw500">
              {total.count > 0 ? `of ${total.count} ${total.label}` : ""}
            </SubText>
          )}
        </>
      )}
    </PillWrapper>
  );
};

export default StatPill;
