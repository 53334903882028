import ArrowLeft from "@assets/ui-icons/ArrowLeft";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SubLocationSelectionContext } from "../../../../../context";
import theme from "../../../../../theme";
import { Button, Heading, Text } from "../../../../../ui-components";
import SubLocationList from "../SubLocationList";
import { Wrapper } from "./styles";

export default function SubLocationSelectionView() {
  const { partner } = useContext(SubLocationSelectionContext);
  const history = useHistory();

  return partner === null ? null : (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Lost something?
      </Text>

      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-md-center">
        Where exactly?
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
        Let’s get a bit more specific on the location within
        <Text as="span" fontWeight={theme.ui.fontWeights.bold}>
          {" "}
          {partner.name}.
        </Text>
      </Text>

      <SubLocationList />

      <Button
        onClick={() => history.goBack()}
        aria-label="go-back"
        variant="outline"
        className="mt-3"
        data-testid="backButton"
        fullWidth
        icon={<ArrowLeft accessibilityTitle="go-back" titleId="sublocation-selection-go-back" />}
      >
        Go back
      </Button>
    </Wrapper>
  );
}
