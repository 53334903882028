import classNames from "classnames";
import React, { forwardRef, Ref, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { Carat, Input, Label } from "./styles";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["select"]> & {
  ariaLabel: string;
  error?: FieldError;
  float?: boolean;
  wrapText?: boolean;
  label: string;
  withoutIcon?: boolean;
};

const Select = forwardRef(
  (
    {
      ariaLabel,
      children,
      className,
      disabled,
      error,
      float = false,
      id,
      label,
      onChange,
      placeholder,
      required,
      value,
      withoutIcon,
      ...props
    }: Props,
    ref: Ref<HTMLSelectElement>,
  ) => {
    const [placeholderSelected, setPlaceholderSelected] = useState<boolean>(
      placeholder !== undefined,
    );

    useEffect(() => {
      setPlaceholderSelected(!value);
    }, [value]);

    return (
      <>
        <div className="position-relative">
          <Input
            ref={ref}
            {...props}
            {...(error !== undefined && { "aria-invalid": true })}
            {...(disabled && { "aria-disabled": true })}
            {...(required && { "aria-required": true })}
            aria-label={ariaLabel}
            className={classNames(
              "px-35 w-100",
              error && "hasError",
              float && !placeholderSelected && "floating",
              placeholderSelected && "withPlaceholder",
              className,
            )}
            disabled={disabled}
            id={id}
            required={required}
            onChange={args => {
              if (placeholder !== undefined) setPlaceholderSelected(false);

              onChange && onChange(args);
            }}
            value={value}
          >
            {placeholder && (
              <option disabled value="" hidden>
                {placeholder}
              </option>
            )}

            {children}
          </Input>

          {!withoutIcon && <Carat accessibilityTitle="open" titleId="select-open" />}

          {float && !placeholderSelected && <Label htmlFor={id}>{label}</Label>}
        </div>

        {error?.message !== undefined && (
          <div className="text-danger invalid-feedback d-block fw500">{error.message}</div>
        )}
      </>
    );
  },
);

export default Select;
