import Modal from "client/components/Modal";
import SuccessModalInterior from "client/components/SuccessModalInterior";
import theme from "client/theme";
import { Link } from "client/ui-components";
import Typography from "client/ui-components/Typography";
import React from "react";

interface SuccessModalProps {
  isLg: boolean;
  closeModal: () => void;
  showModal: boolean;
}

const RefundNeededModal: React.FC<SuccessModalProps> = props => {
  const { closeModal, showModal } = props;
  return (
    <Modal
      topBtnText={"Okay, I understand"}
      topBtnOnClick={closeModal}
      children={
        <SuccessModalInterior
          header="Refund Needed!"
          showIcon={false}
          description={
            <Typography
              className="text-center"
              data-testid="successModalDescription"
              as="p"
              fontSize={theme.ui.fontSizes.base}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.normal}
              lineHeight={1.75}
            >
              This item cannot be manually archived because a shipping label has already been
              purchased. Please contact{" "}
              <Link href="mailto:support@thanksboomerang.com">support@thanksboomerang.com</Link>.
            </Typography>
          }
        />
      }
      onXButton={closeModal}
      show={showModal}
      showXButton
    />
  );
};

export default RefundNeededModal;
