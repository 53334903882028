import React from "react";
import CarouselGallery, { GallerySides } from "../../../../components/CarouselGallery";
import { PartnerItem } from "../../../../types/models";

interface SingleReturnLeftPanelProps {
  itemData: PartnerItem;
}
const SingleReturnLeftPanel: React.FC<SingleReturnLeftPanelProps> = props => {
  const { itemData } = props;
  return (
    <div className="bg-white h-100 p-45">
      <CarouselGallery gallerySide={GallerySides.Bottom} images={itemData?.images} />
    </div>
  );
};

export default SingleReturnLeftPanel;
