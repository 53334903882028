import React from "react";

import Badge, { BadgeSizesEnum, BadgeVariantsEnum } from "client/components/Badge";
import { isXsQuery } from "client/helpers/mediaQuery";
import { useHasStorageLocations } from "client/hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import useFetchStorageLocations from "client/hooks/data/partner/useFetchStorageLocations";
import useCategories from "client/hooks/data/user/useCategories";
import { ResultType } from "client/pages/PartnerGlobalSearch";
import { ReturnStatus } from "client/types";
import { ImageCarousel, StatusBadge } from "client/ui-components";
import { createReturnObject, CustomReturn } from "../../helpers";
import ContactDetailsCell from "../ContactDetailsCell";
import {
  DescriptionContainer,
  LargeContainer,
  Split,
  Subtitle,
  SubtitleContainer,
  SubtitleDescription,
  TitleContainer,
  TopContainer,
} from "./styles";

interface ReturnSnapshotProps {
  customReturn: CustomReturn;
}

const ReturnSnapshot: React.FC<ReturnSnapshotProps> = props => {
  const hasStorageLocations = useHasStorageLocations();
  const { storageLocations } = useFetchStorageLocations();
  const { customReturn } = props;
  const { item, claim } = customReturn;

  const categories = useCategories();
  const returnObject = createReturnObject({
    returnItem: customReturn,
  });
  const isXs = isXsQuery();
  const carouselDimensions = isXs ? { width: 192, height: 256 } : { width: 100, height: 100 };

  const { category, images, name, storage_location: storageLocation } = item || {};

  const storageLocationId =
    typeof storageLocation === "string" ? storageLocation : storageLocation?.id;
  const storageLocationName =
    storageLocations?.find(location => location.id === storageLocationId)?.name || "-";

  const categoryName = categories.data?.find(c => c.id === category)?.name || "Unknown";

  if (isXs)
    return (
      <LargeContainer>
        <ImageCarousel
          images={images?.map(image => image.image) || []}
          interval={null}
          touch
          dimensions={carouselDimensions}
          showSingleImage
          controls={false}
          indicators={false}
          style={{
            margin: "auto",
            flexShrink: 0,
          }}
        />
        <DescriptionContainer>
          <TitleContainer>
            <Badge
              variant={BadgeVariantsEnum.Contained}
              className="text-truncate text-nowrap badge"
              size={BadgeSizesEnum.Small}
              label={categoryName}
            />
            <div>{name}</div>
            <Split>
              <SubtitleContainer>
                <SubtitleDescription>Contact details</SubtitleDescription>
                <ContactDetailsCell user={claim?.user} />
              </SubtitleContainer>
              {hasStorageLocations && (
                <SubtitleContainer>
                  <SubtitleDescription>Storage location</SubtitleDescription>
                  <Subtitle>{storageLocationName}</Subtitle>
                </SubtitleContainer>
              )}
            </Split>
          </TitleContainer>
          <SubtitleContainer>
            <SubtitleDescription>Status</SubtitleDescription>
            <StatusBadge
              objectType={ResultType.RETURNS}
              objectStatus={customReturn.return?.status as ReturnStatus}
              returnObject={returnObject}
              showReturnsIcon
            />
          </SubtitleContainer>
        </DescriptionContainer>
      </LargeContainer>
    );

  return (
    <LargeContainer>
      <TopContainer>
        <ImageCarousel
          images={images?.map(image => image.image) || []}
          interval={null}
          touch
          dimensions={carouselDimensions}
          showSingleImage
          controls={false}
          indicators={false}
          style={{
            margin: "auto",
          }}
        />
        <TitleContainer>
          <Badge
            variant={BadgeVariantsEnum.Contained}
            className="text-truncate text-nowrap badge"
            size={BadgeSizesEnum.Small}
            label={categoryName}
          />
          <div>{name}</div>
        </TitleContainer>
      </TopContainer>
      <DescriptionContainer>
        <Split>
          <SubtitleContainer>
            <SubtitleDescription>Contact Details</SubtitleDescription>
            <ContactDetailsCell user={claim?.user} />
          </SubtitleContainer>
          {hasStorageLocations && (
            <SubtitleContainer>
              <SubtitleDescription>Storage location</SubtitleDescription>
              <Subtitle>{storageLocationName}</Subtitle>
            </SubtitleContainer>
          )}
        </Split>
        <SubtitleContainer>
          <SubtitleDescription>Status</SubtitleDescription>
          <StatusBadge
            objectType={ResultType.RETURNS}
            objectStatus={customReturn.return?.status as ReturnStatus}
            returnObject={returnObject}
            showReturnsIcon
          />
        </SubtitleContainer>
      </DescriptionContainer>
    </LargeContainer>
  );
};

export default ReturnSnapshot;
