import styled from "styled-components";

export const DetailsWrapper = styled.details`
  background: ${props => props.theme.colors.gray6};
  border-bottom: 1px solid ${props => props.theme.colors.gray8};
`;

export const Summary = styled.summary`
  list-style: none;
`;
