import styled from "styled-components";

export const StyledAnchor = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  &:hover {
    text-decoration: none;
  }
`;
