import { create } from "zustand";
import { PartnerClaim, PartnerItem } from "../../../types";

interface MatchingState {
  claims: Array<PartnerClaim>;
  candidates: Array<PartnerItem | PartnerClaim>;
  artifactToMatch: PartnerClaim | PartnerItem | null;
  currentCandidateIndex: number;
  currentCandidate: PartnerItem | PartnerClaim | null;
  nextClaimToMatch: PartnerClaim | null;
  showConfirmModal: boolean;
  showShippingModal: boolean;
  showSuccessModal: boolean;
  showAllCaughtUp: boolean;

  resetMatchingState: () => void;
  setClaims: (_: Array<PartnerClaim>, __?: string) => void;
  setArtifactToMatch: (_: PartnerClaim | PartnerItem) => void;
  setNextClaimToMatch: (_: PartnerClaim | null) => void;
  setCurrentCandidateIndex: (_: number) => void;
  setCandidates: (_: Array<PartnerItem | PartnerClaim>) => void;
  setShowConfirmModal: (_: boolean) => void;
  setShowShippingModal: (_: boolean) => void;
  setShowSuccessModal: (_: boolean) => void;
  setShowAllCaughtUp: (_: boolean) => void;
}

export const useMatchingStore = create<MatchingState>()(set => ({
  claims: [],
  candidates: [],
  artifactToMatch: null,
  currentCandidateIndex: -1,
  currentCandidate: null,
  nextClaimToMatch: null,
  showConfirmModal: false,
  showShippingModal: false,
  showSuccessModal: false,
  showAllCaughtUp: false,

  resetMatchingState: () => {
    set(() => ({
      claims: [],
      candidates: [],
      artifactToMatch: null,
      currentCandidateIndex: -1,
      currentCandidate: null,
      nextClaimToMatch: null,
      showConfirmModal: false,
      showShippingModal: false,
      showSuccessModal: false,
    }));
  },
  setClaims: claims => {
    set(state => ({
      claims,
      nextClaimToMatch: claims.find(claim => claim.id !== state.artifactToMatch?.id) || null,
    }));
  },
  setNextClaimToMatch: nextClaimToMatch => set(() => ({ nextClaimToMatch })),
  setArtifactToMatch: artifactToMatch =>
    set(state => ({
      artifactToMatch,
      nextClaimToMatch: state.claims.find(claim => claim.id !== artifactToMatch.id) || null,
    })),
  setCurrentCandidateIndex: index =>
    set(state => ({
      currentCandidateIndex: index,
      currentCandidate: index > -1 && state.candidates.length ? state.candidates[index] : null,
    })),
  setCandidates: candidates => set(() => ({ candidates })),
  setShowConfirmModal: show => set(() => ({ showConfirmModal: show })),
  setShowShippingModal: show => set(() => ({ showShippingModal: show })),
  setShowSuccessModal: show => set(() => ({ showSuccessModal: show })),
  setShowAllCaughtUp: show => set(() => ({ showAllCaughtUp: show })),
}));

(window as any).matchingStore = useMatchingStore;
