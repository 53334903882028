import { ApiHandlerRes, MultiApiHandlerRes, useApiHandler, useMultiApiHandler } from ".";
import {
  AddItemImageRequest,
  ArtifactImage,
  GetPartnerItemsFromIdRequest,
  ImageSearchResponse,
  PartnerItem,
} from "../types";
import {
  AddItemRequest,
  BulkItemUpdateRequest,
  DeleteItemImageRequest,
  EditItemRequest,
  PartnerImagesRequest,
} from "../types/actions";
import fetchData from "../utils/fetchData";
import getQueryString from "../utils/getQueryString";

import { useMutation, useQuery } from "@tanstack/react-query";
import { getCommonHeaders } from "../utils/headers";
import { formattedInventoryId } from "../utils/stringUtils";

export const getPartnerItemFromId = async (
  partnerItemIdReq: GetPartnerItemsFromIdRequest,
): Promise<PartnerItem | null> => {
  const queryString = partnerItemIdReq.filters ? getQueryString(partnerItemIdReq.filters) : "";
  return fetchData(
    `/api/partner/partners/${partnerItemIdReq.partnerId}/items/${
      partnerItemIdReq.itemId
    }/${queryString}${queryString ? "&" : "?"}include_return=true&detailed_fields=storage_location`,
    {},
    (json: PartnerItem) => {
      // compute inventoryId and add it to the PartnerItem response
      json.inventoryId = formattedInventoryId(json.id);
    },
  );
};

export const useGetPartnerItemFromId = (): ApiHandlerRes<
  GetPartnerItemsFromIdRequest,
  PartnerItem | null
> => useApiHandler(getPartnerItemFromId);

export const useGetPartnerItemById = (request: GetPartnerItemsFromIdRequest) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["item", request?.partnerId, request?.itemId],
    queryFn: () => getPartnerItemFromId(request),
    enabled: !!request?.itemId && !!request?.partnerId,
  });
};

export const useMultipleGetPartnerItemsFromId = (): MultiApiHandlerRes<
  GetPartnerItemsFromIdRequest,
  PartnerItem | null
> => useMultiApiHandler(getPartnerItemFromId);

// Queries for Images
const getPartnerImagesFromItemId = (
  partnerImagesRequest: PartnerImagesRequest,
): Promise<ImageSearchResponse | null> => {
  return fetchData(
    `/api/partner/partners/${partnerImagesRequest.partnerId}/items/${partnerImagesRequest.itemId}/images/`,
  );
};

export const useGetPartnerImagesFromItemId = (): ApiHandlerRes<
  PartnerImagesRequest,
  ImageSearchResponse | null
> => useApiHandler(getPartnerImagesFromItemId);

// actions from items.ts
export const sendAddItem = (addItemReq: AddItemRequest): Promise<PartnerItem | null> => {
  return fetchData(`/api/partner/partners/${addItemReq.partner_id}/items/`, {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(addItemReq.item),
  });
};

export const sendEditItem = (editItemReq: EditItemRequest): Promise<PartnerItem | null> => {
  return fetchData(
    `/api/partner/partners/${editItemReq.partner_id}/items/${editItemReq.item_id}/`,
    {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify(editItemReq.item),
    },
  );
};

export const sendBulkItemUpdate = (request: BulkItemUpdateRequest): Promise<PartnerItem | null> => {
  return fetchData(`/api/partner/partners/${request.partner_id}/items/bulk-update/`, {
    method: "PATCH",
    headers: getCommonHeaders(),
    body: JSON.stringify({
      item_ids: request.item_ids,
      ...(request.set_to_unclaimed ? { set_to_unclaimed: request.set_to_unclaimed } : {}),
      unclaimed_status: request.unclaimed_status,
      unclaimed_status_set_at: request.unclaimed_status_set_at,
      suggested_unclaimed_status: request.suggested_unclaimed_status,
    }),
  });
};

export const useAddItem = (): ApiHandlerRes<AddItemRequest, PartnerItem | null> =>
  useApiHandler<AddItemRequest, PartnerItem | null>(sendAddItem);

export const useUpdateItemDeprecated = (): ApiHandlerRes<EditItemRequest, PartnerItem | null> =>
  useApiHandler<EditItemRequest, PartnerItem | null>(sendEditItem);

export const useUpdateItem = () => {
  return useMutation({
    mutationFn: (request: EditItemRequest) => sendEditItem(request),
  });
};

export const useNewUpdateItem = () => {
  return useMutation({
    mutationFn: (request: EditItemRequest) => sendEditItem(request),
  });
};
export const useBulkItemUpdate = () => {
  return useMutation({
    mutationFn: (request: BulkItemUpdateRequest) => sendBulkItemUpdate(request),
  });
};

export const sendAddItemImage = (itemReq: AddItemImageRequest): Promise<ArtifactImage | null> => {
  const formData = new FormData();
  formData.append("image", itemReq.image, itemReq.image.name);
  formData.append("index", itemReq.index.toString());
  return fetchData(`/api/partner/partners/${itemReq.partner_id}/items/${itemReq.item_id}/images/`, {
    method: "POST",
    headers: getCommonHeaders(true, true),
    body: formData,
  });
};

const deleteItemImage = (deleteItemImageRequest: DeleteItemImageRequest): Promise<null> => {
  return fetchData(
    `/api/partner/partners/${deleteItemImageRequest.partner_id}/items/${deleteItemImageRequest.item_id}/images/${deleteItemImageRequest.image_id}/`,
    {
      method: "DELETE",
      headers: getCommonHeaders(),
    },
  );
};

export const useAddItemImage = (): ApiHandlerRes<AddItemImageRequest, ArtifactImage | null> =>
  useApiHandler(sendAddItemImage);

export const useDeleteItemImage = () => useApiHandler(deleteItemImage);
