import React from "react";
import { ArrowNarrowLeft } from "../../assets/ui-icons";
import history from "../../context/history";
import theme from "../../theme";
import useQueryString from "../../utils/useQueryString";
import Typography from "../Typography";
import { StyledAnchor } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["a"]> & {
  variant?: string;
  linkText?: string;
  dataTestId?: string;
};

export default function GoBackLink({
  variant,
  linkText = "Go back",
  color = theme.ui.colors.primary500,
  onClick = () => history.goBack(),
  dataTestId,
}: Props) {
  /*
   * If there a querystring param called "back" in the url and it is
   * an approved destination, use that as the back link's href.
   */
  let href: string | undefined;
  const allowedBackLocations = ["/partner/claims", "/partner/matches"];
  const backLocation = useQueryString().get("back");
  if (backLocation && allowedBackLocations.includes(backLocation)) {
    href = backLocation;
    onClick = () => {};
  }

  switch (variant) {
    case "legacy":
      color = theme.ui.colors.white;
  }

  return (
    <StyledAnchor className="cursor-pointer" data-testid={dataTestId} href={href} onClick={onClick}>
      <ArrowNarrowLeft accessibilityTitle="Go back" titleId="go-back-link" color={color} />
      <Typography fontWeight={700} color={color}>
        {linkText}
      </Typography>
    </StyledAnchor>
  );
}
