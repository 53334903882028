import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "lato";
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
  gap: 20px;
  padding-bottom: 32px;
`;

export const Divider = styled.div`
  border-color: ${props => props.theme.colors.border};
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  height: 34px;
`;

export const TruncatedText = styled.span`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const DatePickerWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: 0;
  }
`;

export const ContainerRounded = styled.button`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  width: 100%;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 150px;
  border: ${props => `1px solid ${props.theme.ui.colors.gray500}`};
  background: ${props => props.theme.ui.colors.gray300};
  height: 36px;
  color: ${props => props.theme.ui.colors.primary700};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  transition: all 0.2s ease-in-out;
  outline: none;

  &:focus {
    border: 2px solid rgba(7, 114, 228, 1) !important;
    box-shadow: 0px 0px 0px 4px rgba(3, 70, 245, 0.2) !important;
  }

  &:hover {
    border: 1px solid rgba(7, 114, 228, 1);
    box-shadow: 0px 0px 0px 2px rgba(3, 70, 245, 0.2);
  }
`;
