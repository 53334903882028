import styled, { keyframes } from "styled-components";
import { ButtonModes, ButtonProps } from "./Button.types";

export const dotFire = color => keyframes`
  1% {
    box-shadow: 9999px 22.5px 0 -5px ${color};
  }
  50% {
    box-shadow: 9999px -5.625px 0 2px ${color};
  }
  100% {
    box-shadow: 9999px -22.5px 0 -5px ${color};
  }
`;

export const tremble = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(180deg) scale(1);
  }
  11.1111% {
    transform: translate(-0.58028px, 0.97328px) rotate(180deg) scale(1.00143);
  }
  22.2222% {
    transform: translate(0.94518px, 0.44698px) rotate(180deg) scale(0.98261);
  }
  33.3333% {
    transform: translate(-0.95926px, -0.768px) rotate(180deg) scale(0.98359);
  }
  44.4444% {
    transform: translate(0.61729px, -0.79969px) rotate(180deg) scale(1.00324);
  }
  55.5556% {
    transform: translate(-0.78082px, 0.91796px) rotate(180deg) scale(1.01918);
  }
  66.6667% {
    transform: translate(0.27337px, -0.17528px) rotate(180deg) scale(1.01337);
  }
  77.7778% {
    transform: translate(0.33554px, -0.99846px) rotate(180deg) scale(0.99234);
  }
  88.8889% {
    transform: translate(-0.81991px, -0.28327px) rotate(180deg) scale(0.98003);
  }
  100% {
    transform: translate(0, 0) rotate(180deg) scale(1);
  }
`;

export const StyledButtonComponent = styled.button<ButtonProps>`
  height: ${props => props.theme.heights.button};
  font-weight: ${props => props.theme.fontWeights.button};
  overflow: hidden;
  border: 2px solid;
  border-color: inherit;
  border-radius: ${props => props.theme.borderRadius.sm};

  &.btn-open {
    border-color: ${props => props.theme.colors.transparent};
    background-color: ${props => props.theme.colors.transparent};
    color: ${props => props.theme.colors.primaryBlue};

    &.btn-color-danger {
      color: ${props => props.theme.colors.red};
    }

    &.btn-color-gray3 {
      color: ${props => props.theme.colors.gray3};
    }

    &.btn-color-white {
      color: ${props => props.theme.colors.white};
    }
  }

  &.lg {
    width: 350px;
  }

  &.disabled {
    color: ${props => props.theme.colors.gray4};
    background-color: ${props =>
      props.mode === ButtonModes.open ? "transparent" : props.theme.colors.gray6};
    border-color: ${props =>
      props.mode === ButtonModes.open ? "transparent" : props.theme.colors.gray6};
    opacity: 1;
  }

  .tremble {
    transform: matrix(1, 0, 0, 1, 0, 0);
    animation: 1.11111s linear 0s infinite normal forwards running ${tremble};
  }

  &.btn-secondary-outline {
    color: ${props => props.theme.colors.gray3};
    border: 2px solid ${props => props.theme.colors.gray6} !important;
  }

  &.btn-light-blue {
    color: ${props => props.theme.colors.gray1};
    background-color: ${props => props.theme.colors.lightBlue};
    border: 2px solid ${props => props.theme.colors.primaryBlue} !important;
  }

  &.btn-light-outline {
    color: ${props => props.theme.colors.gray1};
    background-color: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.primaryBlue} !important;
  }

  &.btn-light {
    color: ${props => props.theme.colors.gray3};
    background-color: ${props => props.theme.colors.white};
  }

  &.btn-primary {
    border-color: ${props => props.theme.colors.primaryBlue};

    &:hover {
      border-color: ${props => props.theme.colors.primaryBlueDark};
    }

    &.disabled {
      border-color: ${props => props.theme.colors.gray6};
    }
  }
  &.btn-primary-outline {
    border-color: ${props => props.theme.colors.primaryBlue};
    color: ${props => props.theme.colors.primaryBlue};

    &:hover {
      border-color: ${props => props.theme.colors.primaryBlueDark};
    }

    &.disabled {
      border-color: ${props => props.theme.colors.gray6};
    }
  }

  &.btn-success {
    &&&:disabled,
    &&&:disabled:hover,
    &&&:disabled:focus,
    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      color: ${props => props.theme.colors.gray4};
      background-color: ${props => props.theme.colors.gray6};
    }

    &:not(.disabled) {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.successGreen};

      &:hover {
        background-color: ${props => props.theme.colors.lightSuccessGreen};
        border-color: ${props => props.theme.colors.lightSuccessGreen};
      }

      &:active {
        background-color: ${props => props.theme.colors.darkSuccessGreen};
        border-color: ${props => props.theme.colors.darkSuccessGreen};
      }
    }
  }
`;

export const Stage = styled.div`
  transform: rotate(270deg) translate(-3px, 7px);

  .dot-fire {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${props => props.color};
    color: ${props => props.color};
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -0.85s;
  }

  .dot-fire::before,
  .dot-fire::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${props => props.color};
    color: ${props => props.color};
  }

  .dot-fire::before {
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -1.85s;
  }

  .dot-fire::after {
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -2.85s;
  }
`;
