import { useQuery } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default () => {
  const { data, isLoading, error } = useQuery(queryFactory.countries());

  return {
    countries: data,
    isLoading,
    error,
  };
};
