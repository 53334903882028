import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { isLgQuery } from "../helpers/mediaQuery";

import { FloatingLabel, Form } from "react-bootstrap";
import { KEYBOARD_KEY } from "../types/keyboardKey";

const { Group } = Form;

const StyledGroup = styled(Group)`
.form-control { 
  color: ${props => props.theme.colors.gray1};
  font-weight: 500;
  &:hover,
  &:focus,
  &:active {
    border-color: ${props => props.theme.colors.primaryBlue} !important;
    outline-style: none;
    box-shadow: none;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.primaryBlue};
  }
  &:not(:placeholder-shown) {
    border-color: ${props => props.theme.colors.gray3};
  }
  &:placeholder-shown {
    border-color: ${props =>
      props.theme.colors.gray3}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
  }
  &:focus ~ svg {
    color: ${props => props.theme.colors.primaryBlue} !important;
    fill: ${props => props.theme.colors.primaryBlue} !important;
  }
  }
  &.form-floating > .form-control.is-invalid {
    border-color:${props => props.theme.colors.red};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
    color:${props => props.theme.colors.red};
    ~ label, ~ svg { 
      color:${props => props.theme.colors.red};
    }
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.red} !important;
      outline-style: none;
      box-shadow: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
    }
    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.red};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.danger}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.red} !important;
      fill: ${props => props.theme.colors.red} !important;
    }
  }
  position: relative;
  .form__input {
    position: relative;
    top: 0;
    left: 0;
    width:100%;
    border-radius:  ${props => props.theme.borderRadius.sm};
    border-color:  ${props => props.theme.colors.gray4};
    border-style: solid;
    outline: none;
    padding-left: 54px;
    background: none;
  }

  .form__label {
    position: absolute;
    left: 55px;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.paragraph};
    color:  ${props => props.theme.colors.gray3};
    cursor: text;
    pointer-events:none;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  }
}

.form__input:focus ~ .form__label,
.form__input:focus-within ~ .form__label,
.form__input:focus-visible ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus-within) ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus-visible) ~ .form__label
{
  
  top: 11px;
  transform: translateY(0);
  font-size: ${props => props.theme.fontSizes.xs};
  left: 55px;
  color:  ${props => props.theme.colors.black};
}
svg {
  position: absolute;
  left: 21px;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: ${props => props.theme.fontSizes.default};
  cursor: text;
  color:  ${props => props.theme.colors.gray3};
}
.react-datepicker {
  box-shadow: 0 9px 30px rgb(0 0 0 / 8%);
  border: none;
  font-family: ${props => props.theme.fontFamilies.display};
  font-size: ${props => props.theme.fontSizes.default};
  padding: 0 12px;
  border-radius:  ${props => props.theme.borderRadius.lg};
  display: flex;
  flex-direction: column;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__navigation {
  top: 16px;
}
.react-datepicker__month-container {
  width: fit-content;
}
.react-datepicker__header {
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid  ${props => props.theme.colors.white};
  padding: 22px 0 0 0;
  text-align:start;
}
.react-datepicker__navigation--previous {
  left: 19px;
}
.react-datepicker__navigation--next {
  right: 19px;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-names {
  margin-top:40px;
  text-align:center;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  font-weight: 600;
}
.react-datepicker__day-name {
  color:  ${props => props.theme.colors.gray3};
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover { 
  background-color: ${props => props.theme.colors.lightBlue};
  opacity:.85;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: inherit;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: ${props => props.theme.colors.lightBlue};
  color: ${props => props.theme.colors.primaryBlue};
  outline: 2px solid ${props => props.theme.colors.primaryBlue};
}
.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: ${props => props.theme.fontSizes.lg};
  font-style: normal;
    font-weight: 600;
    line-height: 1;
    padding-left: 64px;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: ${props => props.theme.colors.gray1};
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: block;
  height: 11px;
  position: absolute;
  top: 13px;
  width: 11px;
  border-radius: 2px 0;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.lg};
  padding-left: 64px;
  color: ${props => props.theme.colors.gray1};
}
.react-datepicker__day {
  color: ${props => props.theme.colors.gray1};
  &.react-datepicker__day--outside-month, &.react-datepicker__day--disabled {
    color:  ${props => props.theme.colors.disabled};
    &.react-datepicker__day--selected, &.react-datepicker__day--in-selecting-range, &.react-datepicker__day--in-range {
      color: ${props => props.theme.colors.gray1};
    }
  }
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  display: inline-block;
  width: 2.75rem;
  line-height: 2.75;
  text-align: center;
  margin: 0 min(calc((1vw - 3.5px) * 8), 4px);
}
.react-datepicker__day.react-datepicker__day--selected:focus {
  outline-width: 4px;
}
`;
const StyledFloatingLabelInput = styled(FloatingLabel)`
  &.form-floating > label {
    padding-left: 56px;
    font-size: ${props => props.theme.fontSizes.paragraph};
    font-weight: ${props => props.theme.fontWeights.default};
    color: ${props => props.theme.colors.gray3};
  }
  &.form-floating > .form-control {
    cursor: pointer;
    padding-left: 50px;
    border-radius: ${props => props.theme.borderRadius.sm};
  }

  svg {
    position: absolute;
    left: 21px;
    top: 31px; // Not happy with this. Error messages expanding height of the input led me to this hardcoded value
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.default};
    cursor: text;
    color: ${props => props.theme.colors.gray3};
  }
`;

function StyledDatepicker(props) {
  const {
    ariaLabel,
    label,
    name,
    icon,
    className,
    isValidated,
    errors,
    selected,
    onChange,
    maxDate,
    minDate,
    tabIndex,
  } = props;

  const isLg = isLgQuery();
  const todayDate = new Date();
  const CustomInput = forwardRef(
    //TODO Used to be ExampleCustomInput
    ({
      value,
      onClick,
      onChange,
      controlId,
      label,
      className,
      type,
      placeholder,
      style,
      required,
      tabIndex,
    }) => (
      <StyledFloatingLabelInput
        controlId={controlId}
        label={label}
        className={className}
        data-testid={props["data-testid"]}
      >
        <Form.Control
          aria-label={ariaLabel}
          type={type}
          placeholder={placeholder}
          className={!isValidated ? "" : errors?.types ? "is-invalid" : "is-valid"}
          value={value}
          onClick={onClick}
          onKeyDown={e => {
            if (e.key === KEYBOARD_KEY.ENTER) {
              e.preventDefault();
              onClick();
            }
          }}
          onChange={onChange}
          style={{ backgroundColor: "inherit", ...style }}
          required={required}
          readOnly
          tabIndex={tabIndex}
        />
        {icon}
      </StyledFloatingLabelInput>
    ),
  );
  return (
    <StyledGroup className={className} controlId={`form${name}`}>
      <DatePicker
        dateFormat={
          selected?.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)
            ? "'Today'"
            : "MMM, dd yyyy"
        }
        useWeekdaysShort={true}
        selected={selected}
        onChange={onChange}
        customInput={<CustomInput tabIndex={tabIndex} label={label} />}
        maxDate={maxDate}
        minDate={minDate}
        withPortal={!isLg}
        tabIndex={tabIndex}
      />
      {errors?.types &&
        Object.entries(errors?.types).map(([type, message]) => (
          <div className="text-danger invalid-feedback d-block" key={type}>
            {message}
          </div>
        ))}
    </StyledGroup>
  );
}
export default StyledDatepicker;
