import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import fetchItemSuggestedUnclaimedCounts from "client/hooks/data/partner/fetchFunctions/fetchItemSuggestedUnclaimedCounts";
import { formattedInventoryId } from "client/utils/stringUtils";
import fetchItemCategoryCount from "../fetchFunctions/fetchItemCategoryCount";
import fetchItemStatusCount from "../fetchFunctions/fetchItemStatusCount";
import fetchItemStorageLocationCount from "../fetchFunctions/fetchItemStorageLocationCount";
import fetchItemUnclaimedCount from "../fetchFunctions/fetchItemUnclaimedCount";
import fetchPartnerItems from "../fetchFunctions/fetchPartnerItems";
import { OptionalCountQueryFilters } from "../partnerItemCounts";
import baseQueries from "./baseQueries";

const itemQueries = {
  items: (partnerId: string) => [...baseQueries.partner(partnerId), "items"],
  itemsFiltered: ({ partnerId, query }: { partnerId: string; query: Record<string, any> }) =>
    queryOptions({
      queryKey: [...itemQueries.items(partnerId), query],
      queryFn: () => fetchPartnerItems({ partnerId, query }),
      enabled: !!partnerId,
      select: data => {
        const results = data?.results.map(item => {
          item.inventoryId = formattedInventoryId(item.id);
          return item;
        });

        return {
          ...data,
          results,
        };
      },
    }),
  itemCounts: (partnerId: string) => [...itemQueries.items(partnerId), "count"],
  itemCountByCategory: (partnerId: string) => [...itemQueries.itemCounts(partnerId), "category"],
  itemCountByCategoryFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...itemQueries.itemCountByCategory(partnerId), queryObject],
      queryFn: () =>
        fetchItemCategoryCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  itemCountByStatus: (partnerId: string) => [...itemQueries.itemCounts(partnerId), "status"],
  itemCountByStatusFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...itemQueries.itemCountByStatus(partnerId), queryObject],
      queryFn: () =>
        fetchItemStatusCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  itemCountByStorage: (partnerId: string) => [...itemQueries.itemCounts(partnerId), "storage"],
  itemCountByStorageFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...itemQueries.itemCountByStorage(partnerId), queryObject],
      queryFn: () =>
        fetchItemStorageLocationCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  itemCountByUnclaimed: (partnerId: string) => [...itemQueries.itemCounts(partnerId), "unclaimed"],
  itemCountByUnclaimedFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...itemQueries.itemCountByUnclaimed(partnerId), queryObject],
      queryFn: () =>
        fetchItemUnclaimedCount({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
  itemCountBySuggestedUnclaimed: (partnerId: string) => [
    ...itemQueries.itemCounts(partnerId),
    "suggested",
  ],
  itemCountBySuggestedUnclaimedFiltered: ({
    partnerId,
    queryObject,
  }: {
    partnerId: string;
    queryObject: OptionalCountQueryFilters;
  }) =>
    queryOptions({
      queryKey: [...itemQueries.itemCountBySuggestedUnclaimed(partnerId), queryObject],
      queryFn: () =>
        fetchItemSuggestedUnclaimedCounts({
          partnerId,
          queryObject,
        }),
      placeholderData: keepPreviousData,
    }),
};

export default itemQueries;
