import closeIcon from "client/assets/x_circle.svg";
import { PartnerItem } from "client/types";
import { Button } from "client/ui-components";
import React from "react";
import { ButtonWrapper, LinkText, StyledLink } from "./styles";

export interface RemoveFromListButtonProps {
  variant?: "large" | "regular";
  isLoading?: boolean;
  disabled?: boolean;
  selectedItems: PartnerItem[];
  onClick?: (items: PartnerItem[]) => any;
}

export default function RemoveFromListButton({
  selectedItems,
  variant,
  isLoading,
  disabled,
  onClick,
}: RemoveFromListButtonProps) {
  const label = `Remove from list`;
  if (variant === "large") {
    return (
      <ButtonWrapper>
        <Button
          aria-label={"Remove"}
          variant="outline"
          onClick={() => onClick?.(selectedItems)}
          loading={isLoading}
          loadingText={label}
          disabled={disabled}
        >
          {label}
        </Button>
      </ButtonWrapper>
    );
  }
  return (
    <StyledLink
      onClick={!disabled ? () => onClick?.(selectedItems) : undefined}
      isButton
      dataTestId="remove"
    >
      <img src={closeIcon} alt={label} />
      <LinkText style={disabled || isLoading ? { color: "#DCE1E8" } : undefined}>{label}</LinkText>
    </StyledLink>
  );
}
