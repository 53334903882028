import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 64px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 800px;
    margin-inline: auto;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #dce1e8;
    border-radius: 16px;
  }
`;
