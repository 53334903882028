import styled from "styled-components";

import { SearchResultMagnifyingGlass } from "../../../../../assets/icons/icons";

export const MessageWrapper = styled.div`
  color: ${props => props.theme.colors.primaryBlue};
  display: flex;
  align-items: center;
`;

export const StyledSearchResultMagnifyingGlass = styled(SearchResultMagnifyingGlass)`
  margin-top: 40px;
  height: 58px;
  width: 58px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 98px;
    width: 98px;
    margin-top: 68px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 72px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.ui.colors.white};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-bottom: 48px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 97px;
    max-width: 1072px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 97px;
    max-width: 1072px;
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;
