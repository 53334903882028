import styled from "styled-components";

export const OptionWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.gray8};
  border-radius: ${props => props.theme.borderRadius.lg};
`;

export const Price = styled.div`
  min-width: 84px;
  border-left: 1px solid ${props => props.theme.colors.gray8};
`;

export const ErrorMessage = styled.div`
  display: flex;
  max-width: 580px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  margin: 0 auto;
`;
