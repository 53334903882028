import React, { useContext } from "react";
import { PartnerSelectionContext } from "../../../../context";
import { isMdQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Heading, Text } from "../../../../ui-components";
import PartnerSelectInput from "../PartnerSelectInput";
import PartnerSelectMenu from "../PartnerSelectMenu";
import { MenuWrapper, Wrapper } from "./styles";

export default function PartnerSelectView() {
  const isMd = isMdQuery();
  const { searchText } = useContext(PartnerSelectionContext);

  return (
    <Wrapper
      searchMenuOpen={searchText.length >= 3}
      className="d-flex flex-column align-content-md-center"
    >
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Lost something?
      </Text>

      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-md-center">
        Where did you lose your item?
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
        Start by telling us where you lost your item. This will help narrow down our search.
      </Text>

      <PartnerSelectInput />

      {searchText && searchText.length >= 3 && isMd && (
        <MenuWrapper>
          <PartnerSelectMenu />
        </MenuWrapper>
      )}
    </Wrapper>
  );
}
