import ArrowRightTab from "@assets/ui-icons/ArrowRightTab";
import { LocationDescriptor } from "history";
import React from "react";
import { useHistory } from "react-router-dom";
import { EndTab, ExternalContainer, StyledTab, TabsContainer } from "./styles";

interface TabProps {
  name: string;
  url?: LocationDescriptor<unknown>;
  count?: number;
  isSelected: boolean;
  tabNumber: number;
  onClick?: () => void;
}

interface BaseTabsProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs?: TabProps[];
  selectedTabIndex?: number;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

interface EndLinkProps {
  hasEndLink: true;
  endAlignedUrl: string;
  endAlignedText: string;
}

interface NoEndLinkProps {
  hasEndLink?: false;
  endAlignedUrl?: string;
  endAlignedText?: string;
}

type TabsProps = BaseTabsProps & (EndLinkProps | NoEndLinkProps);

export const Tabs = ({
  tabs = [],
  endAlignedUrl,
  endAlignedText,
  selectedTabIndex,
  hasEndLink,
  children,
  containerStyle,
  ...props
}: TabsProps) => {
  const history = useHistory();

  return (
    <ExternalContainer {...props}>
      <TabsContainer style={containerStyle}>
        {tabs.map((tab, i) => (
          <Tab
            name={tab.name}
            url={tab.url}
            count={tab.count}
            isSelected={i === selectedTabIndex}
            key={i}
            tabNumber={i}
            onClick={tab.onClick}
          />
        ))}
        {children}
      </TabsContainer>
      {hasEndLink && (
        <EndTab
          onClick={() => {
            history.push(endAlignedUrl);
          }}
          data-testid="seeCompletedReturns"
        >
          {endAlignedText}
          <ArrowRightTab />
        </EndTab>
      )}
    </ExternalContainer>
  );
};

export const Tab = ({ name: text, url, count, isSelected, onClick, tabNumber }: TabProps) => {
  const history = useHistory();

  return (
    <StyledTab
      onClick={() => {
        url ? history.push(url) : null;
        onClick && onClick();
      }}
      isSelected={isSelected}
      tabIndex={0}
      data-testid={`tab-${tabNumber}`}
      onKeyDown={e => {
        if (e.key === "Enter") {
          url ? history.push(url) : null;
          onClick && onClick();
        }
      }}
    >
      {text} {count !== undefined ? `(${count})` : ""}
    </StyledTab>
  );
};
