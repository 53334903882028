import React, { useEffect } from "react";
import styled from "styled-components";

import emptyBoxImg from "../../../assets/empty_box.svg";
import BlankMessage from "../../../components/BlankMessage";

import { useQuery } from "@tanstack/react-query";
import useClaimsCountByStatus from "client/hooks/data/partner/partnerClaimCounts/useClaimsCountByStatus";
import queryFactory from "client/hooks/data/partner/queryFactory";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import DefaultError from "client/ui-components/DefaultError";
import { useGetClaimCountByCategory } from "../../../actions/partnerClaimsByCategoryCount";
import { useGetItemCountByCategory } from "../../../actions/partnerItemsByCategoryCount";
import { DonutChartDataPoint } from "../../../components/DonutChart";
import Loader from "../../../components/Loader";
import { usePartnerUserState } from "../../../context/PartnerUser";
import theme from "../../../theme";
import {
  ClaimStatus,
  ItemStatus,
  PartnerClaimStatusEnum,
  PartnerItemStatusEnum,
} from "../../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import DonutChartWithLegend from "./DonutChartWithLegend";

const Content = styled.div`
  margin: 0 auto;
  display: flex;
`;

interface Props {
  className?: string;
  partnerId: string;
}

function Metrics(props: Props) {
  const { className, partnerId } = props;

  const { partnerUserStateLoading } = usePartnerUserState();

  const { partnerUserLocation } = usePartnerLocationStore();

  const { data: claimsCountByStatus } = useClaimsCountByStatus({
    partnerId,
    partnerLocationId: partnerUserLocation.id,
  });

  const { data: itemsByStatusCount } = useQuery(
    queryFactory.itemCountByStatusFiltered({
      partnerId,
      queryObject: {
        ...(partnerUserLocation.id !== "" && {
          lost_locations: [partnerUserLocation.id],
        }),
        status: [],
      },
    }),
  );

  const [
    getItemCountByCategory,
    { data: itemsByCategoryCount, loading: itemCountLoading, error: itemCountError },
  ] = useGetItemCountByCategory();

  const [getClaimCountByCategory, { data: claimsByCategoryCount, loading: claimCountLoading }] =
    useGetClaimCountByCategory();

  useEffect(() => {
    getItemCountByCategory({
      partnerId: partnerId,
      filters: {
        ...(partnerUserLocation?.id !== "" && {
          lost_locations: [partnerUserLocation.id],
        }),
      },
    });
    getClaimCountByCategory({
      partnerId: partnerId,
      filters: {
        ...(partnerUserLocation.id !== "" && {
          lost_locations: [partnerUserLocation.id],
        }),
      },
    });
  }, [partnerUserLocation]);

  useEffect(() => {
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  if (itemCountError) {
    return <DefaultError />;
  }

  if (
    itemsByCategoryCount &&
    itemsByCategoryCount.length < 0 &&
    claimsByCategoryCount &&
    claimsByCategoryCount.length < 1
  ) {
    return (
      <BlankMessage alt="Boomerang empty box" image={emptyBoxImg} title={"No items yet"}>
        There are no items for this location.
        <br />
        Once items are added, they will show up here.
      </BlankMessage>
    );
  }

  const palette = theme.chartColors.palette;
  const itemsIngested: DonutChartDataPoint[] = itemsByCategoryCount
    ? (itemsByCategoryCount || []).map((d, i) => ({
        label: d.name,
        value: d.count,
        color: palette[i % palette.length],
      }))
    : [];

  const matchRateByClaimChartData: DonutChartDataPoint[] = [
    {
      label: PartnerClaimStatusEnum.RETURNED,
      value: claimsCountByStatus ? claimsCountByStatus[ClaimStatus.COMPLETED] : 0,
      color: palette[4],
    },
    {
      label: PartnerClaimStatusEnum.MATCHED,
      value: claimsCountByStatus ? claimsCountByStatus[ClaimStatus.MATCHED] : 0,
      color: palette[1],
    },
    {
      label: PartnerClaimStatusEnum.UNMATCHED,
      value: claimsCountByStatus
        ? claimsCountByStatus[ClaimStatus.NEW] +
          claimsCountByStatus[ClaimStatus.NO_MATCHES] +
          claimsCountByStatus[ClaimStatus.HAS_MATCHES]
        : 0,
      color: palette[2],
    },
    {
      label: PartnerClaimStatusEnum.EXPIRED,
      value: claimsCountByStatus ? claimsCountByStatus[ClaimStatus.EXPIRED] : 0,
      color: palette[0],
    },
  ];

  const totalMatchesByClaimValue = matchRateByClaimChartData.reduce(
    (total, d) => total + d?.value,
    0,
  );
  const percentageTotalByClaim = (
    ((matchRateByClaimChartData?.find(o => o?.label === PartnerClaimStatusEnum.RETURNED)?.value ??
      0) /
      totalMatchesByClaimValue) *
    100
  ).toFixed();

  // Matched Rate by Item
  const matchesCountByItems =
    itemsByStatusCount &&
    (itemsByStatusCount || []).reduce(function (map, obj) {
      map[obj.value] = obj.count;
      return map;
    }, {});

  const matchRateByItemChartData: DonutChartDataPoint[] = [
    {
      label: PartnerItemStatusEnum.RETURNED,
      value: Number((matchesCountByItems && matchesCountByItems[ItemStatus.COMPLETED]) ?? 0),
      color: palette[4],
    },
    {
      label: PartnerItemStatusEnum.PENDING_APPROVAL,
      value: Number((matchesCountByItems && matchesCountByItems[ItemStatus.MATCHED]) ?? 0),
      color: palette[1],
    },
    {
      label: PartnerItemStatusEnum.UNMATCHED,
      value: Number(
        matchesCountByItems &&
          Number(matchesCountByItems[ItemStatus.NO_MATCHES] ?? 0) +
            Number(matchesCountByItems[ItemStatus.HAS_MATCHES] ?? 0),
      ),
      color: palette[2],
    },
    {
      label: PartnerItemStatusEnum.UNCLAIMED,
      value: Number(matchesCountByItems && Number(matchesCountByItems[ItemStatus.UNCLAIMED] ?? 0)),
      color: palette[0],
    },
  ];

  const totalMatchesByItemValue = (matchRateByItemChartData || []).reduce(
    (total, d) => total + d?.value,
    0,
  );
  const percentageTotalByItem = (
    ((matchRateByItemChartData?.find(o => o.label === PartnerItemStatusEnum.RETURNED)?.value ?? 0) /
      totalMatchesByItemValue) *
    100
  ).toFixed();

  const newClaims: DonutChartDataPoint[] = claimsByCategoryCount
    ? (claimsByCategoryCount || []).map((d, i) => ({
        label: d.name,
        value: d.count,
        color: palette[i % palette.length],
      }))
    : [];

  return (
    <Content className={`row mx-0 justify-content-around pb-5 ${className ?? ""}`}>
      {!itemCountLoading && !claimCountLoading && !partnerUserStateLoading ? (
        <>
          <div className="col-12 col-lg-6 mt-5">
            <DonutChartWithLegend
              accessibilityTitle="Inventory items by category"
              titleId="InventoryByCategoryDonutChartTitle"
              title="Inventory items"
              subtitle={""}
              unit=""
              highlightHoveredData={false}
              data={itemsIngested}
            />
          </div>
          <div className="col-12 col-lg-6 mt-5">
            <DonutChartWithLegend
              accessibilityTitle="Return rate by item"
              displayLimit={4}
              titleId="ReturnRateItemsDonutChartTitle"
              title="Return rate by item"
              subtitle={""}
              unit=""
              highlightHoveredData={false}
              data={totalMatchesByItemValue > 0 ? matchRateByItemChartData : []}
              percentageTotal={(Number(percentageTotalByItem) || 0).toString()}
            />
          </div>
          <div className="col-12 col-lg-6 mt-5">
            <DonutChartWithLegend
              accessibilityTitle="Claims by category"
              titleId="ClaimsByCategoryDonutChartTitle"
              title="Submitted claims"
              subtitle={""}
              unit=""
              highlightHoveredData={false}
              data={newClaims}
            />
          </div>
          <div className="col-12 col-lg-6 mt-5">
            <DonutChartWithLegend
              accessibilityTitle="Return rate by claim"
              displayLimit={4}
              titleId="ReturnRateClaimsDonutChartTitle"
              title="Return rate by claim"
              subtitle={""}
              unit=""
              highlightHoveredData={false}
              data={totalMatchesByClaimValue > 0 ? matchRateByClaimChartData : []}
              percentageTotal={(Number(percentageTotalByClaim) || 0).toString()}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Content>
  );
}

export default Metrics;
