/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { KEYBOARD_KEY } from "client/types/keyboardKey";
import Errors from "client/utils/errors";
import useQueryString from "client/utils/useQueryString";
import { useHistory } from "react-router-dom";
import { ResultType } from "../..";
import { getGlobalSearch } from "../../../../actions/partners";
import { ArrowLeft } from "../../../../assets/icons/icons";
import Loader from "../../../../components/Loader";
import {
  PartnerGlobalSearchContext,
  PartnerGlobalSearchResult,
} from "../../../../context/PartnerGlobalSearch";
import theme from "../../../../theme";
import Typography from "../../../../ui-components/Typography";
import scrollToTop from "../../../../utils/scrollToTop";
import useCurrentPartner from "../../../../utils/useCurrentPartner";
import ResultsSection, { Result } from "../ResultsSection";
import { ArrowUp, BackToTopButton, LinkWrapper } from "./styles";

export default function AllResults() {
  scrollToTop();
  const { lastVisitedPage } = useContext(PartnerGlobalSearchContext);
  const [page, setPage] = useState<number>(1);
  const [isAtTop, setIsAtTop] = useState<boolean>(true);
  const [localResults, setLocalResults] = useState<PartnerGlobalSearchResult>({
    items: [],
    claims: [],
    counts: { items: 0, claims: 0 },
  });
  const {
    setResultSetType,
    setShowAllResults,
    searchTerm,
    locationFilter,
    setIsSearchLoading,
    setSearchResults,
    resultSetType,
    setSearchTerm,
    setLocationFilter,
  } = useContext(PartnerGlobalSearchContext);
  const history = useHistory();
  const partnerId = useCurrentPartner();
  const { globalSearchLocation } = usePartnerLocationStore();
  const queryParams = useQueryString();

  useEffect(() => {
    const query = queryParams.get("query");
    const locationId = queryParams.get("location_id");
    const resultSetType = queryParams.get("result_set_type");

    if (query !== null) setSearchTerm(query!);
    if (locationId !== null) setLocationFilter(locationId!);
    if (resultSetType !== null) setResultSetType(resultSetType! as "items" | "claims");
  }, [queryParams, setLocationFilter, setResultSetType, setSearchTerm]);

  useEffect(() => {
    if (searchTerm === "") return;

    setShowAllResults(true);
    setIsSearchLoading(true);
    getGlobalSearch({
      partnerId,
      search: searchTerm,
      location: locationFilter,
      resultSet: resultSetType || undefined,
      limit: 12,
      offset: (Math.max(page - 1), 0) * 12,
    })
      .then(results => {
        setIsSearchLoading(false);
        setSearchResults(results);
        if (results) setLocalResults(results);
        setPage(page + 1);
      })
      .catch(e => {
        Errors.captureException(e, {
          partnerId,
          searchTerm,
          locationFilter,
          resultSetType,
          page,
        });
      });

    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getMoreData = (newPage: number) => {
    getGlobalSearch({
      partnerId,
      search: searchTerm,
      location: locationFilter,
      resultSet: resultSetType || undefined,
      limit: 12,
      offset: (newPage - 1) * 12,
    }).then(results => {
      if (results) {
        setLocalResults({
          items: [...localResults.items, ...results.items],
          claims: [...localResults.claims, ...results.claims],
          counts: localResults.counts,
        });
        setPage(page + 1);
      }
    });
  };

  const itemsOnScreen = (page - 1) * 12;

  const onClick = () => {
    const location = locationFilter === "" ? "" : `&location_id=${locationFilter}`;
    const resultSet = resultSetType === "" ? "" : `&result_set_type=${resultSetType}`;

    history.push(
      `/partner/search/q?query=${encodeURIComponent(searchTerm)}${location}${resultSet}`,
      {
        query: history.location.search,
        pathname: history.location.pathname,
        searchInitiator: lastVisitedPage,
      },
    );
    setResultSetType("");
    setShowAllResults(false);
    setPage(0);
  };

  const heading = localResults && (
    <>
      <LinkWrapper
        tabIndex={0}
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === KEYBOARD_KEY.ENTER) {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <ArrowLeft
          className="m-2"
          accessibilityTitle="Go back"
          titleId="GoBackArrowLeftTitle"
          height="20px"
          color={theme.colors.primaryBlue}
        />
        <Typography
          className="cursor-pointer"
          as="a"
          data-testid="backArrowButton"
          fontWeight={theme.fontWeights.bold}
          lineHeight={1.5}
          fontSize="16px"
          color={theme.colors.primaryBlue}
        >
          Back to search results
        </Typography>
      </LinkWrapper>
      <div className="mt-25 mx-3">
        <Typography
          as="h4"
          heading
          fontWeight={theme.fontWeights.bold}
          fontSize={theme.fontSizes.lg}
          color={theme.ui.colors.primary700}
        >
          {resultSetType === "items" ? "Inventory" : "Claim"}
          {" results"}
        </Typography>
        <Typography
          as="p"
          fontWeight={theme.fontWeights.light}
          fontSize="16px"
          lineHeight={1.5}
          color={theme.ui.colors.primary700}
        >
          {"Here are all "}
          <Typography
            as={"span"}
            fontWeight={theme.fontWeights.bold}
            fontSize="16px"
            color={theme.ui.colors.primary700}
          >
            {resultSetType === "items" ? localResults.counts.items : localResults.counts.claims}
          </Typography>
          {" results for "}
          <Typography
            whiteSpace="pre"
            as={"span"}
            fontWeight={theme.fontWeights.bold}
            fontSize="16px"
            color={theme.ui.colors.primary700}
          >{`"${searchTerm}"`}</Typography>
          <Typography
            as={"span"}
            fontWeight={theme.fontWeights.default}
            fontSize="16px"
            color={theme.ui.colors.primary700}
          >
            {" in "}
          </Typography>
          {
            <Typography
              as={"span"}
              fontWeight={theme.fontWeights.bold}
              fontSize="16px"
              color={theme.ui.colors.primary700}
            >
              {globalSearchLocation.name === "" ? "All locations" : `${globalSearchLocation.name}`}
            </Typography>
          }
          {` in ${resultSetType === "items" ? "Inventory" : "Claims"}.`}
        </Typography>
      </div>
    </>
  );

  const results = localResults && (
    <>
      <BackToTopButton
        show={!isAtTop}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <ArrowUp />
        Back to top
      </BackToTopButton>
      {localResults.items.length > 0 && resultSetType === ResultType.ITEMS && (
        <InfiniteScroll
          dataLength={localResults.items.length}
          next={() => getMoreData(page)}
          hasMore={itemsOnScreen < localResults.counts.items}
          loader={<Loader />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <ResultsSection
            className="min-vh-100"
            type={ResultType.ITEMS}
            results={localResults.items as Result[]}
            header="Inventory"
            subheader={`We found ${localResults.counts.items} items!`}
          />
        </InfiniteScroll>
      )}
      {localResults.claims.length > 0 && resultSetType === ResultType.CLAIMS && (
        <InfiniteScroll
          dataLength={localResults.claims.length}
          next={() => getMoreData(page)}
          hasMore={itemsOnScreen < localResults.counts.claims}
          loader={<Loader />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <ResultsSection
            className="min-vh-100"
            type={ResultType.CLAIMS}
            results={localResults.claims as Result[]}
            header="Claims"
            subheader={`We found ${localResults.counts.claims} items!`}
          />
        </InfiniteScroll>
      )}
    </>
  );

  return (
    <>
      {heading}
      {results}
    </>
  );
}
