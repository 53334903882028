import { useSearchParam } from "client/hooks";
import { usePartnerDateFormat } from "client/hooks/usePartnerDateFormat";
import useSearchParams from "client/hooks/useSearchParams";
import DateRangePicker from "client/ui-components/DateRangePicker";
import { stringToDate } from "client/utils/dateUtils";
import React from "react";

export function DateFilter() {
  const [, setSearch] = useSearchParams();
  const [dateAfter] = useSearchParam<string>("date_after", "ALL");
  const [dateBefore] = useSearchParam<string>("date_before", "ALL");
  const { short } = usePartnerDateFormat();

  return (
    <DateRangePicker
      dateFormat={short}
      label="Date found"
      startDate={dateAfter === "ALL" ? null : stringToDate(dateAfter)}
      endDate={dateBefore === "ALL" ? null : stringToDate(dateBefore)}
      onSelect={(date_after, date_before) => setSearch({ date_before, date_after })}
      style={{ gridArea: "date" }}
    />
  );
}
