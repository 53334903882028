import React, { useState } from "react";
import { Content, RadioInput, Wrapper } from "./styles";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  radioProps: React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;
};

function RadioCard({ children, radioProps, ...props }: Props, ref) {
  const [active, setActive] = useState(false);
  const handleRadioClick = () => {
    setActive(true);
    setTimeout(() => setActive(false), 100);
  };

  return (
    <Wrapper className={active ? "active" : ""} onClick={handleRadioClick} {...props}>
      <RadioInput ref={ref} {...radioProps} type="radio" />

      <Content>{children}</Content>
    </Wrapper>
  );
}

export default React.forwardRef(RadioCard);
