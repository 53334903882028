import Text from "client/ui-components/Text";
import formatCurrency from "client/utils/formatCurrency";
import React, { useContext } from "react";
import Details from "../../../../components/Details";
import { ShippingCheckoutContext } from "../../../../context/ShippingCheckout";

export default function ConfirmationSummary() {
  const { checkoutData, claim } = useContext(ShippingCheckoutContext);
  const country = claim?.lost_location.address.country_code;
  const total = +(checkoutData?.selectedDeliveryMethod?.total ?? 0);

  const handlingFees = +(
    checkoutData?.cost_components?.find(({ cost_type }) => cost_type === "handling_fee")?.amount ??
    0
  );
  const shippingRate = +(
    checkoutData?.cost_components?.find(({ cost_type }) => cost_type === "shipping_fee")?.amount ??
    0
  );

  if (!checkoutData.selectedDeliveryMethod) return null;
  return (
    <Details
      title="Payment details"
      titleDetail={formatCurrency({
        amount: total,
        country,
      })}
    >
      <div className="d-flex flex-column gap-25 mt-4">
        <div className="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
          <Text className="mb-0">
            {checkoutData.selectedDeliveryMethod?.provider} -{" "}
            {checkoutData.selectedDeliveryMethod?.servicelevel.name}
          </Text>
          <p className="mb-0">
            {formatCurrency({
              amount: shippingRate,
              country: country,
            })}
          </p>
        </div>
        {handlingFees > 0 && (
          <div className="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
            <Text className="mb-0">Handling fee</Text>
            <p className="mb-0">
              {formatCurrency({
                amount: handlingFees,
                country: country,
              })}
            </p>
          </div>
        )}
      </div>
    </Details>
  );
}
