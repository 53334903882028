import React from "react";
import { SVGProps } from "./types";

export default function LockIcon({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) {
  return (
    <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <title id={titleId}>{accessibilityTitle}</title>
      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52246 11.3976V8.50602C5.52246 4.91285 8.54454 2 12.2725 2C16.0004 2 19.0225 4.91285 19.0225 8.50602V11.3976H20.2725V19.1084C20.2725 20.7054 18.9293 22 17.2725 22H7.27246C5.61561 22 4.27246 20.7054 4.27246 19.1084V11.3976H5.52246ZM7.02246 11.3976H8.52246V8.59413C8.52246 6.59792 10.2014 4.97967 12.2725 4.97967C14.3435 4.97967 16.0225 6.59792 16.0225 8.59413V11.3976H17.5225V8.506C17.5225 5.71131 15.172 3.44576 12.2725 3.44576C9.37297 3.44576 7.02246 5.71131 7.02246 8.506V11.3976ZM14.5225 8.59417V11.3976H10.0225V8.59417C10.0225 7.39645 11.0298 6.4255 12.2725 6.4255C13.5151 6.4255 14.5225 7.39645 14.5225 8.59417ZM5.77246 12.8434V19.1084C5.77246 19.9069 6.44403 20.5542 7.27246 20.5542H17.2725C18.1009 20.5542 18.7725 19.9069 18.7725 19.1084V12.8434H5.77246ZM12.2725 15.0121C11.8582 15.0121 11.5225 15.3357 11.5225 15.7349V17.6627C11.5225 18.0619 11.8582 18.3855 12.2725 18.3855C12.6867 18.3855 13.0225 18.0619 13.0225 17.6627V15.7349C13.0225 15.3357 12.6867 15.0121 12.2725 15.0121Z"
        fill="currentColor"
      />
    </svg>
  );
}
