import * as Select from "@radix-ui/react-select";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  align-self: stretch;
`;

export const StyledTrigger = styled(Select.Trigger)`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 150px;
  border: 1px solid #d0d5dd;
  font-weight: ${props => props.theme.fontWeights.default};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  text-align: left;
  height: 44px;
  padding: 10px 14px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  line-height: 24px;
  font-size: ${props => props.theme.fontSizes.default};
  outline: none !important;

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &[data-placeholder] {
    color: rgba(0, 23, 74, 0.5);
  }

  &[data-size="xs"] {
    height: 32px;
    padding: 6px 12px;
    line-height: 20px;
    font-size: ${props => props.theme.fontSizes.sm};
  }

  &[data-size="sm"] {
    height: 36px;
    padding: 6px 12px;
  }

  &[data-theme="secondary"] {
    background-color: ${props => props.theme.ui.colors.gray300};
    border-color: ${props => props.theme.ui.colors.gray500};
  }

  &[data-state="open"],
  &:focus {
    &:not([data-error="true"]):not([data-disabled]) {
      border: 2px solid rgba(7, 114, 228, 1) !important;
      box-shadow: 0px 0px 0px 4px rgba(3, 70, 245, 0.2) !important;
    }
  }

  &&:hover {
    &:not([data-state="open"]):not([data-error="true"]):not([data-disabled]) {
      border: 1px solid rgba(7, 114, 228, 1);
      box-shadow: 0px 0px 0px 2px rgba(3, 70, 245, 0.2);
    }
  }

  &[data-error="true"] {
    box-shadow: 0px 0px 0px 4px rgba(176, 17, 33, 0.2);
    border: 2px solid ${props => props.theme.ui.colors.error500};
  }
`;

export const StyledContent = styled(Select.Content)<{
  showFullOptions?: boolean;
}>`
  width: ${props => (props.showFullOptions ? "auto" : "var(--radix-select-trigger-width)")};
  max-height: var(--radix-select-content-available-height);
  z-index: 1000;
  margin-top: 8px;
  margin-bottom: 8px;
  box-shadow: ${props => props.theme.shadows.lg};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.ui.colors.gray200};
  background-color: ${props => props.theme.colors.white};
  padding: 6px 8px 2px 8px;
  min-width: 180px;
`;

export const StyledViewport = styled(Select.Viewport)`
  border-radius: inherit;
  max-height: 386px;

  &[data-radix-select-viewport] {
    scrollbar-width: thin !important;
  }

  &[data-size="xs"] > * {
    font-size: ${props => props.theme.fontSizes.sm};
    line-height: 20px;
    height: 40px;
  }
`;

export const StyledItem = styled(Select.Item)`
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.default};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  background-color: ${props => props.theme.colors.white};
  height: 44px;
  padding: 10px 8px;
  transition: background-color 200ms ease-in;
  display: flex;
  margin-bottom: 2px;
  border-radius: 8px;
  cursor: pointer;
  line-height: 24px;
  outline: none !important;

  &:hover,
  &:focus-within {
    background: ${props => props.theme.colors.gray6};
  }
  &[data-highlighted] {
    font-weight: 600;
    background-color: ${props => props.theme.colors.gray6};
  }
`;

export const StyledItemIndicator = styled(Select.ItemIndicator)`
  margin-left: auto;
`;

export const StyledLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.default};
  color: ${props => props.theme.ui.colors.primary700};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-weight: 400;
  line-height: 24px;

  &[data-size="xs"] {
    font-size: ${props => props.theme.fontSizes.sm};
    line-height: 20px;
  }
`;

export const StyledHint = styled.span`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.ui.colors.gray600};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  &[data-error="true"] {
    color: ${props => props.theme.ui.colors.error500};
  }
`;
