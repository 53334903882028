import React, { useContext } from "react";
import { ButtonModes } from "../../Button/Button.types";
import { TabContext } from "../index";
import { ClickableTabTitle, TabTitle, TabTitleList } from "./styles";

export default function TabsList() {
  const { currentIndex, tabs, clickable, setCurrentIndex } = useContext(TabContext);

  return (
    <TabTitleList className="d-flex flex-1  mb-0 p-0" role="tablist">
      {tabs.map(({ title, index }) =>
        clickable ? (
          <ClickableTabTitle
            ariaControls={`tabpanel-${index}`}
            ariaLabel={title}
            ariaSelected={currentIndex === index}
            className={`${
              currentIndex === index ? "active" : ""
            }  h-auto pb-1 pb-sm-35 px-1 px-sm-35 pt-0`}
            key={`title-${index}`}
            mode={ButtonModes.open}
            onClick={() => setCurrentIndex(index)}
            role="tab"
            tabIndex={index !== currentIndex ? -1 : undefined}
            text={title}
          />
        ) : (
          <TabTitle
            key={`title-${index}`}
            className={`${
              currentIndex === index ? "active" : ""
            } pb-1 pb-sm-35 px-1 px-sm-35 d-flex align-items-center justify-content-center`}
          >
            {title}
          </TabTitle>
        ),
      )}
    </TabTitleList>
  );
}
