import styled from "styled-components";

export const StyledPhoneInputWrapper = styled.div`
  flex: 1;

  font-family: ${({ theme }) => theme.ui.fontFamilies.default};
  font-size: ${({ theme }) => theme.ui.fontSizes.base};

  width: 100%;

  .PhoneInputInput::placeholder {
    color: ${({ theme }) => theme.ui.colors.gray600};
    opacity: 0.5;
  }

  .PhoneInputInput {
    border-radius: ${props => props.theme.borderRadius.rounded};
    border: 1px solid ${({ theme }) => theme.ui.colors.gray500};
    color: ${({ theme }) => theme.ui.colors.gray700};
    font-weight: ${props => props.theme.fontWeights.default};
    outline: none;
    padding-inline-start: 70px;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    height: ${props => props.theme.heights.input};
    &:hover,
    &:focus,
    &:active {
      border-width: 1px;
      border-color: ${({ theme }) => theme.ui.colors.primary500};
      outline: 1px solid ${({ theme }) => theme.ui.colors.primary500};
      box-shadow:
        0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px rgba(3, 70, 245, 0.2);
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.primaryBlue} !important;
      fill: ${props => props.theme.colors.primaryBlue} !important;
    }
  }

  .PhoneInput {
    position: relative;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    &::after {
      content: "";
      width: calc(100% + 18px);
      height: calc(100% + 18px);
      background-color: transparent;
      position: absolute;
      top: -9px;
      left: -9px;
      border-color: ${props => props.theme.colors.primaryBlue};
      border-style: solid;
      border-width: 2px;
      border-radius: inherit;
    }
  }

  .is-valid {
    .PhoneInputInput {
      background-image: none !important;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  .is-invalid {
    .PhoneInputInput {
      border: 1px solid ${props => props.theme.colors.red};
      outline: 1px solid ${({ theme }) => theme.ui.colors.error500};
      box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.error400};
      border-color: ${({ theme }) => theme.ui.colors.error500};
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      &:hover,
      &:focus,
      &:active {
        outline: 1px solid ${({ theme }) => theme.ui.colors.error500};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.error400};
        border-color: ${({ theme }) => theme.ui.colors.error500};
        box-shadow:
          0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px #fee4e2;
      }
      &:focus ~ svg {
        color: ${props => props.theme.colors.red} !important;
        fill: ${props => props.theme.colors.red} !important;
      }
    }
  }
  .PhoneInputCountry {
    position: absolute;
    top: 19px;
    left: 20px;
    height: 21px;
  }

  .PhoneInputCountrySelectArrow {
    margin-left: 13px;
  }
`;
