import React, { useContext } from "react";
import { ClaimDetailsContext } from "../../../context/ClaimDetails";
import { ShipmentStatus } from "../../../types";
import Text from "../../../ui-components/Text";
import Details from "./Details";
import { StatusDisplay, StatusStep } from "./styles";

export default function TrackingDetails() {
  const { shipment } = useContext(ClaimDetailsContext);

  return shipment === null ? null : (
    <>
      <Details />

      {shipment.status !== undefined && (
        <StatusDisplay
          className="row m-0"
          data-testid="statusBar"
          delivered={shipment.status === ShipmentStatus.DELIVERED}
        >
          <StatusStep
            className="col-3 px-1"
            order="first"
            data-testid="processing"
            filled={[
              ShipmentStatus.PENDING,
              ShipmentStatus.PACKAGED,
              ShipmentStatus.SHIPPED,
              ShipmentStatus.DELIVERED,
            ].includes(shipment.status)}
          >
            <div className="line mb-25" />

            <Text
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              style={{ whiteSpace: "nowrap" }}
            >
              Processing
            </Text>
          </StatusStep>

          <StatusStep
            className="col-3 px-1"
            data-testid="packaged"
            filled={[
              ShipmentStatus.PACKAGED,
              ShipmentStatus.SHIPPED,
              ShipmentStatus.DELIVERED,
            ].includes(shipment.status)}
          >
            <div className="line mb-25" />

            <Text fontSize="14px" lineHeight="20px" textAlign="center">
              Packaged
            </Text>
          </StatusStep>

          <StatusStep
            className="col-3 px-1"
            data-testid="inTransit"
            filled={[ShipmentStatus.SHIPPED, ShipmentStatus.DELIVERED].includes(shipment.status)}
          >
            <div className="line mb-25" />

            <Text fontSize="14px" lineHeight="20px" textAlign="center">
              In transit
            </Text>
          </StatusStep>

          <StatusStep
            className="col-3 px-1"
            data-testid="delivered"
            order="last"
            filled={shipment.status === ShipmentStatus.DELIVERED}
          >
            <div className="line mb-25" />

            <Text fontSize="14px" lineHeight="20px" textAlign="center">
              {" "}
              Delivered
            </Text>
          </StatusStep>
        </StatusDisplay>
      )}
    </>
  );
}
