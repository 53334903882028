import { useQuery } from "@tanstack/react-query";
import {
  PartnerItemsCountByUnclaimedStatusResponse,
  PartnerItemUnclaimedStatusEnum,
} from "client/types";
import { FilterItem } from "client/ui-components/FilterDropdown/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { OptionalCountQueryFilters } from ".";
import queryFactory from "../queryFactory";

export const initialUnclaimedStatusFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
} as const;

function formatUnclaimedStatus({
  unclaimedStatusCounts: unclaimedCounts,
}: {
  unclaimedStatusCounts: PartnerItemsCountByUnclaimedStatusResponse;
}): FilterItem[] {
  return [
    {
      ...initialUnclaimedStatusFilter,
      count: unclaimedCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...unclaimedCounts.map(status => ({
      name: PartnerItemUnclaimedStatusEnum[status.value],
      count: status.count,
      value: status.value,
    })),
  ];
}

export const useUnclaimedStatusCounts = (queryObj: OptionalCountQueryFilters) => {
  const partnerId = useCurrentPartner();

  const { data: unclaimedStatusCounts } = useQuery(
    queryFactory.itemCountByUnclaimedFiltered({
      partnerId,
      queryObject: queryObj,
    }),
  );

  if (!unclaimedStatusCounts) {
    return {
      unclaimedStatusCounts: [],
    };
  }

  return {
    unclaimedStatusCounts: formatUnclaimedStatus({
      unclaimedStatusCounts,
    }),
  };
};

export default useUnclaimedStatusCounts;
