import styled from "styled-components";

export const CardContent = styled.div`
  overflow: auto;
  padding: 20px 20px 0 20px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-inline: 40px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-bottom: 40px;
  }
`;

export const CardImage = styled.img`
  max-height: 108px;
  height: 100%;
  width: auto;
`;
