import { isLgQuery } from "client/helpers/mediaQuery";
import { ALL_OPTION, usePagination } from "client/hooks";
import { usePartnerDateFormat } from "client/hooks/usePartnerDateFormat";
import useSearchParams from "client/hooks/useSearchParams";
import { Link } from "client/ui-components";
import DateRangePicker from "client/ui-components/DateRangePicker";
import { FilterSelect } from "client/ui-components/Select";
import { dateToISOString } from "client/utils/dateUtils";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useArchiveFilters } from "../../helpers/hooks";
import {
  FiltersContainer,
  HorizontalDivider,
  SmallText,
  Toolbar,
  ToolbarActionsContainer,
} from "./styles";

const UnclaimedStatusFilter = ({ count }: { count: number }) => {
  const {
    filters: { unclaimed_status },
    setFilter,
    unclaimedStatuses,
  } = useArchiveFilters();

  const unclaimedStatusesWithCount = [...unclaimedStatuses];
  if (unclaimed_status.value === ALL_OPTION) {
    unclaimedStatusesWithCount[0] = {
      ...unclaimedStatusesWithCount[0],
      count,
    };
  }

  return (
    <FilterSelect
      label="Status"
      value={unclaimed_status.value}
      onValueChange={value => setFilter("unclaimed_status", value)}
      options={unclaimedStatusesWithCount}
      style={{ gridArea: "status" }}
      data-testid="statusFilter"
    />
  );
};

const CategoryFilter = () => {
  const {
    filters: { category },
    setFilter,
    categories,
  } = useArchiveFilters();

  return (
    <FilterSelect
      label="Category"
      value={category.name}
      onValueChange={value => setFilter("category", value)}
      options={categories.map(({ name, count }) => ({ name, count, value: name }))}
      style={{ gridArea: "category" }}
      data-testid="categoryFilter"
    />
  );
};

const DateFilter = () => {
  const {
    filters: { date_after, date_before },
  } = useArchiveFilters();
  const [search, setSearch] = useSearchParams();
  const { short } = usePartnerDateFormat();

  return (
    <DateRangePicker
      label="Date found"
      data-testid="dateFoundFilter"
      onSelect={(date_after, date_before) => {
        search.set("date_before", date_before ? dateToISOString(date_before) : "ALL");
        search.set("date_after", date_after ? dateToISOString(date_after) : "ALL");
        const obj = Object.fromEntries(search.entries());
        setSearch(obj);
      }}
      startDate={date_after}
      endDate={date_before}
      dateFormat={short}
    />
  );
};

// it clears filters by going to the same page with no params
function ClearFilters() {
  const { hasFilters } = useArchiveFilters();
  const isMd = !useMediaQuery({ query: "(max-width: 768px)" });
  if (!hasFilters) return null;
  return (
    <Link
      style={{
        textAlign: "center",
        alignSelf: "flex-end",
        margin: isMd ? "0 0 6px 0" : "0 auto 8px auto",
        flexGrow: 0,
      }}
      onClick={() => {}}
    >
      {isMd ? "Clear all" : "Clear all filters"}
    </Link>
  );
}

function ShowingItems({ count }: { count: number }) {
  const { page, pageSize } = usePagination({ count });

  if (count === 0) return null;
  return (
    <SmallText>
      Showing {(page - 1) * pageSize + 1}–{page * pageSize >= count ? count : page * pageSize} of{" "}
      {count}
    </SmallText>
  );
}

export default function ArchiveToolbar({ count }: { count: number }) {
  const isMd = isLgQuery();
  return (
    <>
      <Toolbar>
        <FiltersContainer>
          <DateFilter />
          <UnclaimedStatusFilter count={count} />
          <CategoryFilter />
          <ClearFilters />
        </FiltersContainer>
        {!isMd && (
          <ToolbarActionsContainer>
            <ShowingItems count={count} />
          </ToolbarActionsContainer>
        )}
      </Toolbar>
      <HorizontalDivider />
    </>
  );
}
