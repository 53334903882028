import React, { Ref, useEffect } from "react";
import { useGetStates } from "../../actions/shippingCheckout";
import Select, { Props as SelectProps } from "../Inputs/Select";

type Props = SelectProps;

function StateSelect({ ...props }: Props, ref: Ref<HTMLSelectElement>) {
  const [getStates, { data: states, loading: statesLoading }] = useGetStates();

  useEffect(() => {
    if (!states && !statesLoading) {
      getStates();
    }
  }, [states, statesLoading]);

  return (
    <Select ref={ref} {...props} disabled={states === null || statesLoading}>
      <option value="" hidden>
        State
      </option>

      {states !== null &&
        (states.results || []).map(({ name, iso_code }) => (
          <option key={iso_code} value={iso_code}>
            {name}
          </option>
        ))}
    </Select>
  );
}

export default React.forwardRef(StateSelect);
