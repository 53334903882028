import React, { useEffect, useRef } from "react";
import Button from "../Button";
import { ButtonModes } from "../Button/Button.types";

import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { BackgroundOverlay, ModalWrapper, StyledHeader } from "./styled";

interface SmallConfirmationModalProps {
  description?: React.ReactNode | string;
  header: string;
  leftBtnOnClick?: () => void;
  leftBtnText?: string;
  rightBtnOnClick?: () => void;
  rightBtnText?: string;
  show: boolean;
}

const SmallConfirmationModal: React.FC<SmallConfirmationModalProps> = (
  props: SmallConfirmationModalProps,
) => {
  const { description, header, leftBtnOnClick, leftBtnText, rightBtnOnClick, rightBtnText, show } =
    props;

  const showHideClass = show ? "d-flex" : "d-none";
  const bothButtons = leftBtnOnClick && leftBtnText && rightBtnOnClick && rightBtnText;

  const modalFocusTrapRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const modalFirstInputRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  useEffect(() => {
    modalFocusTrapRef.current?.focus();
    modalFocusTrapRef.current?.addEventListener("focusout", ev => {
      if (!modalFocusTrapRef.current?.contains(ev.relatedTarget as Node))
        modalFocusTrapRef.current?.focus();
    });
    return function cleanup() {
      modalFocusTrapRef.current?.removeEventListener("focusout", () => {});
    };
  }, [show]);

  return (
    <BackgroundOverlay
      className={`${showHideClass} justify-content-center flex-column`}
      onClick={leftBtnOnClick}
      data-testid="popup-modal"
    >
      <ModalWrapper
        ref={modalFocusTrapRef}
        className={`p-4 border-0 ${bothButtons ? "pb-lg-3" : ""} mx-4 popup-modal`}
        tabIndex={-1}
        onFocus={() => {
          document.onkeyup = function (event) {
            if (event.key === KEYBOARD_KEY.TAB) {
              !modalFocusTrapRef.current.matches(":focus-within:not(:focus)") &&
                modalFirstInputRef.current?.focus();
            }
          };
        }}
      >
        <StyledHeader
          className={`mb-45 row ${bothButtons ? "justify-content-lg-start ps-lg-3" : ""}`}
          data-testid="modalTitle"
        >
          {header}
        </StyledHeader>
        {description && <div className="mb-45">{description}</div>}
        <div className="row justify-content-between w-100 mx-0 my-0">
          {leftBtnText && leftBtnOnClick && (
            <div
              className={`text-center ${
                bothButtons ? "col-12 col-lg-6 ps-lg-3 pb-lg-3" : ""
              } px-0 mb-3 mb-lg-0`}
            >
              <Button
                ariaLabel={leftBtnText}
                className="w-100"
                mode={ButtonModes.open}
                onClick={leftBtnOnClick}
                ref={modalFirstInputRef}
                tabIndex={0}
                text={leftBtnText}
              />
            </div>
          )}
          {rightBtnText && rightBtnOnClick && (
            <div
              className={`text-center ${
                bothButtons ? "col-12 col-lg-6 pe-lg-3 pb-lg-3" : ""
              } px-0 mt-1 my-lg-0`}
            >
              <Button
                ref={!leftBtnText ? modalFirstInputRef : undefined}
                ariaLabel={rightBtnText}
                text={rightBtnText}
                onClick={rightBtnOnClick}
                mode={ButtonModes.primary}
                className="w-100"
                tabIndex={0}
              />
            </div>
          )}
        </div>
      </ModalWrapper>
    </BackgroundOverlay>
  );
};

export default SmallConfirmationModal;
