import React, { createContext, useEffect, useMemo, useRef, useState } from "react";

import { getGlobalSearch } from "client/actions/partners";
import { PartnerRoutesEnum } from "client/components/Routes";
import { Result } from "client/pages/PartnerGlobalSearch/components/ResultsSection";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useLocation } from "react-router-dom";
import history from "../../context/history";
import useQueryString from "../../utils/useQueryString";

export type PartnerGlobalSearchResult = {
  items: Result[];
  claims: Result[];
  counts: { items: number; claims: number };
};

type PartnerGlobalSearchContextType = {
  isSearchModeOn: boolean;
  setIsSearchModeOn: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  showAllResults: boolean;
  setShowAllResults: React.Dispatch<React.SetStateAction<boolean>>;
  searchResults: PartnerGlobalSearchResult | null;
  isLoading: boolean;
  locationFilter: string;
  setLocationFilter: React.Dispatch<React.SetStateAction<string>>;
  setIsSearchLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchResults: React.Dispatch<React.SetStateAction<PartnerGlobalSearchResult | null>>;
  resultSetType: "items" | "claims" | "";
  setResultSetType: React.Dispatch<React.SetStateAction<string>>;
  restoreGlobalSearchBar: boolean;
  setRestoreGlobalSearchBar: React.Dispatch<React.SetStateAction<boolean>>;
  lastVisitedPage: string;
  setLastVisitedPage: React.Dispatch<React.SetStateAction<string>>;
};

export const PartnerGlobalSearchContext = createContext<PartnerGlobalSearchContextType>({
  isSearchModeOn: false,
  setIsSearchModeOn: () => undefined,
  searchTerm: "",
  setSearchTerm: () => undefined,
  showAllResults: false,
  setShowAllResults: () => undefined,
  searchResults: null,
  isLoading: false,
  locationFilter: "",
  setLocationFilter: () => undefined,
  setIsSearchLoading: () => undefined,
  setSearchResults: () => undefined,
  resultSetType: "",
  setResultSetType: () => undefined,
  restoreGlobalSearchBar: false,
  setRestoreGlobalSearchBar: () => undefined,
  lastVisitedPage: "",
  setLastVisitedPage: () => undefined,
});

export default function PartnerGlobalSearchProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { pathname } = useLocation();
  const [isSearchModeOn, setIsSearchModeOn] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showAllResults, setShowAllResults] = useState<boolean>(
    pathname == PartnerRoutesEnum.SEARCH_ALL ? true : false,
  );
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<PartnerGlobalSearchResult | null>(null);
  const [locationFilter, setLocationFilter] = useState<string>("");
  const [resultSetType, setResultSetType] = useState<"items" | "claims" | "">("");
  const [restoreGlobalSearchBar, setRestoreGlobalSearchBar] = useState<boolean>(false);
  const [lastVisitedPage, setLastVisitedPage] = useState<string>(window.location.pathname);

  const partnerId = useCurrentPartner();
  const queryParams = useQueryString();
  const timeoutId = useRef<any>(0);
  useEffect(() => {
    if (searchTerm.length < 3) return;
    if (showAllResults) return;

    clearTimeout(timeoutId.current);

    setIsSearchLoading(true);

    timeoutId.current = setTimeout(() => {
      const location = locationFilter === "" ? "" : `&location_id=${locationFilter}`;
      const resultSet = resultSetType === "" ? "" : `&result_set_type=${resultSetType}`;
      if (pathname !== PartnerRoutesEnum.SEARCH_ALL) {
        history.push(
          `/partner/search/q?query=${encodeURIComponent(searchTerm)}${location}${resultSet}`,
        );
      }
      getGlobalSearch({ partnerId, search: searchTerm, location: locationFilter, limit: 3 }).then(
        results => {
          setIsSearchLoading(false);
          setSearchResults(results);
        },
      );
    }, 500);

    return () => clearTimeout(timeoutId.current);
  }, [searchTerm, locationFilter, showAllResults, resultSetType, pathname, partnerId]);

  useEffect(() => {
    const query = queryParams.get("query");
    const locationId = queryParams.get("location_id");
    const resultSetType = queryParams.get("result_set_type");

    if (query !== null) setSearchTerm(query!);
    if (locationId !== null) setLocationFilter(locationId!);
    if (resultSetType !== null) setResultSetType(resultSetType! as "items" | "claims");
  }, [queryParams]);

  const results = useMemo<PartnerGlobalSearchResult | null>(() => {
    if (isSearchLoading || searchResults === null) return null;

    return searchTerm.length < 3 ? null : searchResults;
  }, [searchTerm, isSearchLoading, searchResults]);

  const values = useMemo<PartnerGlobalSearchContextType>(
    () => ({
      isSearchModeOn,
      setIsSearchModeOn,
      searchTerm,
      setSearchTerm,
      showAllResults,
      setShowAllResults,
      searchResults: results,
      isLoading: isSearchLoading,
      locationFilter,
      setLocationFilter,
      setIsSearchLoading,
      setSearchResults,
      resultSetType,
      setResultSetType,
      restoreGlobalSearchBar,
      setRestoreGlobalSearchBar,
      lastVisitedPage,
      setLastVisitedPage,
    }),
    [
      searchTerm,
      isSearchModeOn,
      showAllResults,
      locationFilter,
      results,
      isSearchLoading,
      resultSetType,
      restoreGlobalSearchBar,
      lastVisitedPage,
    ],
  );

  return (
    <PartnerGlobalSearchContext.Provider value={values}>
      {children}
    </PartnerGlobalSearchContext.Provider>
  );
}
