import React from "react";
import screenshotMobile from "../../../../assets/ui-icons/matching-screenshot-mobile.png";
import screenshot from "../../../../assets/ui-icons/matching-screenshot.png";
import { isMdQuery } from "../../../../helpers/mediaQuery";
import { colors, fontWeights, ui } from "../../../../theme/uiTheme";
import { Heading, Text } from "../../../../ui-components/";
import SectionContainer from "../../../../ui-components/SectionContainer";
import { matchingSectionCopy } from "../../content";
import { Img } from "./styles";

const MatchingSection = () => {
  const isMd = isMdQuery();
  return (
    <SectionContainer className="text-center">
      <Heading as="h2" textAlign="center">
        {matchingSectionCopy.heading}
      </Heading>
      <Text
        className="mt-25 mt-md-3 mt-lg-35"
        color={colors.primary700}
        fontSize={ui.description.responsive.fontSize}
        lineHeight={ui.description.responsive}
        fontWeight={fontWeights.normal}
        textAlign="center"
      >
        {matchingSectionCopy.body}
      </Text>
      <Img
        loading="lazy"
        height={isMd ? 1246 : 845}
        width={isMd ? 2252 : 768}
        src={isMd ? screenshot : screenshotMobile}
        alt="screenshot of our side by side matching screen"
      />
    </SectionContainer>
  );
};
export default MatchingSection;
