import { ArrowDropRight } from "@styled-icons/remix-line/ArrowDropRight";
import React, { useState } from "react";
import styled from "styled-components";
import BootstrapModal, { ModalBackdrop, ModalSize } from "../../../components/BootstrapModal";
import { DonutChartDataPoint } from "../../../components/DonutChart";
import { isEmpty } from "../../../utils/object";

// Icons
import support from "../../../assets/support.svg";

import theme from "../../../theme";

const Legend = styled.div`
  display: grid;
  grid-template-columns: 7px minmax(20px, 5fr) 1fr 1fr;
  grid-auto-rows: 44px;
  column-gap: 8px;
  row-gap: 8px;
`;
const ColorKey = styled.div`
  height: 100%;
  width: 7px;
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: Raleway, opensans, sans-serif;
`;
const Value = styled(Label)`
  justify-content: right;
  font-weight: 700;
  font-family: opensans, sans-serif;
`;
const Percentage = styled(Value)`
  font-weight: 500;
`;
const MoreInfo = styled.button`
  grid-column-start: 1;
  grid-column-end: span col2-start;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => props.theme.colors.primaryBlue};
`;
const StyledArrowDropRight = styled(ArrowDropRight)`
  color: ${props => props.theme.colors.primaryBlue};
`;

const ModalFooterComponent = () => {
  return (
    <>
      <div className="icon text-center px-0 pe-1">
        <img src={support} alt="Support icon svg" />
      </div>
      <p className="col ps-2 m-0 fw500">
        Boomerang sends detailed reports of this data to you every quarter.
      </p>
    </>
  );
};

interface Props {
  data: DonutChartDataPoint[];
  displayLimit?: number;
  subtitle: string;
  title: string;
}

const DonutChartLegend = (props: Props): JSX.Element | null => {
  const { data, displayLimit = 3, subtitle, title } = props;
  const slicedData = data.slice(0, displayLimit);
  const numberAboveLimit = data.length - displayLimit;
  const totalValue = data.reduce((total, d) => total + d.value, 0);
  const fmt = Intl.NumberFormat(undefined, { style: "percent" });
  const [showAllCategoriesModal, setShowAllCategoriesModal] = useState(false);

  if (isEmpty(data)) return null;

  return (
    <>
      <Legend>
        {(slicedData || []).map(d => (
          <React.Fragment key={d.label}>
            <ColorKey style={{ backgroundColor: d.color }} />
            <Label>{d.label}</Label>
            <Value>{isNaN(d.value) ? 0 : d.value}</Value>
            <Percentage>
              {isNaN(d.value / totalValue) ? 0 : fmt.format(d.value / totalValue)}
            </Percentage>
          </React.Fragment>
        ))}
      </Legend>
      {numberAboveLimit > 0 && (
        <>
          <MoreInfo className="px-0 py-2 fw500" onClick={() => setShowAllCategoriesModal(true)}>
            {`${numberAboveLimit} more categories`}
          </MoreInfo>
          <StyledArrowDropRight size={20} />
        </>
      )}
      <BootstrapModal
        backdrop={ModalBackdrop.Static}
        footerComponent={<ModalFooterComponent />}
        onHide={() => {
          setShowAllCategoriesModal(false);
        }}
        show={showAllCategoriesModal}
        size={ModalSize.Medium}
        subtitle={subtitle}
        title={title}
        modalFooterColor={theme.colors.gray6}
      >
        <Legend>
          {(data || [])?.map(d => (
            <React.Fragment key={d.label}>
              <ColorKey style={{ backgroundColor: d.color }} />
              <Label>{d.label}</Label>
              <Value>{d.value}</Value>
              <Percentage>{fmt.format(d.value / totalValue)}</Percentage>
            </React.Fragment>
          ))}
        </Legend>
      </BootstrapModal>
    </>
  );
};

export default DonutChartLegend;
