import { queryOptions } from "@tanstack/react-query";
import { PartnerClaimsCountFilters } from "client/types";
import fetchClaimStatusCount from "../fetchFunctions/fetchClaimStatusCount";
import baseQueries from "./baseQueries";

const claimQueries = {
  claims: (partnerId: string) => [...baseQueries.partner(partnerId), "claims"],
  claimCounts: (partnerId: string) => [...claimQueries.claims(partnerId), "count"],
  claimCountByStatus: (partnerId: string) => [...claimQueries.claimCounts(partnerId), "status"],
  claimCountByStatusFiltered: ({
    partnerId,
    filters,
  }: {
    partnerId: string;
    filters?: PartnerClaimsCountFilters;
  }) =>
    queryOptions({
      queryKey: [...claimQueries.claimCountByStatus(partnerId), filters],
      queryFn: () =>
        fetchClaimStatusCount({
          partnerId,
          filters,
        }),
    }),
};

export default claimQueries;
