import { PartnerGenericCountResponse } from "client/types";
import { create } from "zustand";

interface ReturnState {
  hasBulkOptions: boolean;
  setHasBulkOptions: (_: boolean) => void;
  totalCount: number;
  setTotalCount: (_: number) => void;
  filterCounts: {
    [key: string]: PartnerGenericCountResponse | undefined;
  };
  setFilterCounts: (_: { [key: string]: PartnerGenericCountResponse | undefined }) => void;
}

export const useReturnsStore = create<ReturnState>()(set => ({
  hasBulkOptions: false,
  setHasBulkOptions: hasBulkOptions => set(() => ({ hasBulkOptions })),
  totalCount: 0,
  setTotalCount: totalCount => set(() => ({ totalCount })),
  filterCounts: {},
  setFilterCounts: filterCounts => set(() => ({ filterCounts })),
}));
