import { ArrowNarrowLeft, ArrowNarrowRight } from "@assets/ui-icons";
import { Button } from "client/ui-components";
import DateFilterInput from "client/ui-components/DateFilterInput";
import { default as React, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledLabel } from "../Select/styles";
import { ActionsContainer, DatePickerContainer, HeaderContainer, StyledArrow } from "./styles";

type Props = {
  onSelect: (date: Date | null) => void;
  selected: Date | null;
  style?: React.CSSProperties;
  Input?: React.FC<DatePickerInputProps>;
  dataTestId?: string;
  label?: string;
  dateFormat?: string;
};
export type DatePickerInputProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  value?: string;
  dataTestid?: string;
};

function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <HeaderContainer>
      {prevMonthButtonDisabled ? (
        <span style={{ width: "30px" }}></span>
      ) : (
        <StyledArrow onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <ArrowNarrowLeft
            titleId="Arrow pointing left"
            accessibilityTitle="previous month icon"
            data-testid="arrowLeft"
          />
        </StyledArrow>
      )}
      <span className="text-center" style={{ lineHeight: "24px" }} data-testid="month">
        {date.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
      {nextMonthButtonDisabled ? (
        <span style={{ width: "30px" }}></span>
      ) : (
        <StyledArrow onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <ArrowNarrowRight
            titleId="Arrow pointing right"
            accessibilityTitle="next month icon"
            data-testid="arrowRight"
          />
        </StyledArrow>
      )}
    </HeaderContainer>
  );
}

export default function DatePicker({
  label,
  dateFormat = "MM/dd/yyyy",
  onSelect,
  Input = DateFilterInput,
  selected,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const [key, setKey] = useState("");

  const onChange = date => {
    if (!date) return;
    setDate(date);
    setIsOpen(false);
  };

  useEffect(() => {
    setDate(selected);
  }, [selected]);

  const onCancel = () => {
    setIsOpen(false);
  };

  const onClear = () => {
    setDate(null);
    onSelect(null);
    setIsOpen(false);
    setKey(Math.random().toString());
  };

  useEffect(() => {
    if (!date || date === selected) return;
    onSelect(date);
  }, [date, onSelect, selected]);

  return (
    <DatePickerContainer>
      {label ? <StyledLabel>{label}</StyledLabel> : null}
      <ReactDatePicker
        data-testid="datePicker"
        useWeekdaysShort={true}
        dateFormat={dateFormat}
        renderCustomHeader={CustomHeader}
        onClickOutside={onCancel}
        selected={date}
        open={isOpen}
        maxDate={new Date()}
        highlightDates={[new Date()]}
        onChange={onChange}
        customInput={<Input isOpen={isOpen} setIsOpen={setIsOpen} handleClose={onCancel} />}
        key={key}
      >
        <ActionsContainer>
          <div>
            <Button
              onClick={onClear}
              aria-label="Reset date filter"
              data-testid="resetCalendarButton"
              style={{
                width: "100%",
                padding: "8px 14px",
                lineHeight: "20px",
                fontSize: "14px",
                height: "36px",
              }}
              variant="text"
            >
              Reset
            </Button>
          </div>
        </ActionsContainer>
      </ReactDatePicker>
    </DatePickerContainer>
  );
}
