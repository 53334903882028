import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const dotFire = color => keyframes`
  1% {
    box-shadow: 9999px 22.5px 0 -5px ${color};
  }
  50% {
    box-shadow: 9999px -5.625px 0 2px ${color};
  }
  100% {
    box-shadow: 9999px -22.5px 0 -5px ${color};
  }
`;

export const tremble = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(180deg) scale(1);
  }
  11.1111% {
    transform: translate(-0.58028px, 0.97328px) rotate(180deg) scale(1.00143);
  }
  22.2222% {
    transform: translate(0.94518px, 0.44698px) rotate(180deg) scale(0.98261);
  }
  33.3333% {
    transform: translate(-0.95926px, -0.768px) rotate(180deg) scale(0.98359);
  }
  44.4444% {
    transform: translate(0.61729px, -0.79969px) rotate(180deg) scale(1.00324);
  }
  55.5556% {
    transform: translate(-0.78082px, 0.91796px) rotate(180deg) scale(1.01918);
  }
  66.6667% {
    transform: translate(0.27337px, -0.17528px) rotate(180deg) scale(1.01337);
  }
  77.7778% {
    transform: translate(0.33554px, -0.99846px) rotate(180deg) scale(0.99234);
  }
  88.8889% {
    transform: translate(-0.81991px, -0.28327px) rotate(180deg) scale(0.98003);
  }
  100% {
    transform: translate(0, 0) rotate(180deg) scale(1);
  }
`;

const buttonStyles = css<{
  maxWidth?: number;
}>`
  line-height: 1.5;
  font-weight: ${props => props.theme.ui.fontWeights.bold};
  font-size: ${props => props.theme.ui.fontSizes.base};
  overflow: hidden;
  border: 1px solid;
  border-radius: ${props => props.theme.ui.borderRadius.rounded};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  box-shadow: ${props => props.theme.ui.shadows.button};
  white-space: nowrap;
  text-align: center;

  .tremble {
    transform: matrix(1, 0, 0, 1, 0, 0);
    animation: 1.11111s linear 0s infinite normal forwards running ${tremble};
  }

  &.btn {
    &.icon {
      svg {
        transition:
          height,
          width,
          margin 200ms ease-in;

        height: 24px;
        width: 24px;
      }

      &.icon-end {
        svg {
          margin-inline-start: 8px;
        }
      }

      &.icon-start {
        svg {
          margin-inline-end: 8px;
        }
      }
    }

    &-sm {
      height: 36px;
      line-height: 1.42;
      font-size: ${props => props.theme.ui.fontSizes.sm};
      padding: 8px 14px;
    }

    &-md {
      height: 40px;
      line-height: 1.25;
      padding: 10px 16px;
    }

    &-lg {
      height: 44px;
      padding: 10px 18px;
    }

    &-xl {
      height: 48px;
      padding: 12px 20px;
    }

    &-2xl {
      height: 60px;
      font-size: ${props => props.theme.ui.fontSizes.md};
      line-height: 1.55556;
      padding: 16px 28px;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        line-height: 1.55;
      }

      &.icon {
        &.icon-end {
          svg {
            margin-inline-start: 12px;
          }
        }

        &.icon-start {
          svg {
            margin-inline-end: 12px;
          }
        }
      }
    }

    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "none")};

    &.full-width {
      width: 100%;
    }

    &-primary {
      color: ${props => props.theme.ui.colors.white};
      background-color: ${props => props.theme.ui.colors.primary500};
      border-color: #076ad5;

      &:hover {
        background-color: ${props => props.theme.ui.colors.primary600};
        border-color: ${props => props.theme.ui.colors.primary600};
      }

      &:disabled,
      .disabled {
        background-color: ${props => props.theme.ui.colors.primary400};
        border-color: ${props => props.theme.ui.colors.gray500};
      }
    }

    &-outline {
      color: ${props => props.theme.ui.colors.primary700};
      background-color: ${props => props.theme.ui.colors.white};
      border-color: ${props => props.theme.ui.colors.gray500};

      &:hover {
        background-color: ${props => props.theme.ui.colors.gray400};
      }

      &:focus {
        background-color: ${props => props.theme.ui.colors.white};
        box-shadow:
          ${props => props.theme.ui.shadows.button},
          0px 0px 0px 4px #f2f4f7;
      }

      &:disabled,
      .disabled {
        color: ${props => props.theme.ui.colors.gray500};
      }
    }

    &-text-primary {
      color: ${props => props.theme.ui.colors.primary500};
      background-color: ${props => props.theme.ui.colors.white};
      border: 2px solid transparent;
      box-shadow: none;
      font-weight: ${props => props.theme.ui.fontWeights.semi};

      &:hover {
        color: ${props => props.theme.ui.colors.primary700};
      }

      &:focus {
        border: 2px solid ${props => props.theme.ui.colors.primary500};
        background-color: ${props => props.theme.ui.colors.white};
        box-shadow: none;
      }

      &:disabled,
      .disabled {
        color: ${props => props.theme.ui.colors.gray500};
      }
    }

    &-text {
      color: ${props => props.theme.ui.colors.gray600};
      background-color: transparent;
      border: 2px solid transparent;
      box-shadow: none;

      &:hover {
        color: ${props => props.theme.ui.colors.primary700};
      }

      &:focus {
        border: 2px solid ${props => props.theme.ui.colors.primary500};
        background-color: ${props => props.theme.ui.colors.white};
        box-shadow: none;
      }

      &:disabled,
      .disabled {
        color: ${props => props.theme.ui.colors.gray500};
      }
    }

    &-secondary {
      background-color: ${props => props.theme.ui.colors.success500};
      border-color: #097b4b;

      &:hover {
        background-color: ${props => props.theme.ui.colors.success600};
        border-color: ${props => props.theme.ui.colors.success600};
      }

      &:disabled,
      .disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.ui.colors.success400};
        border-color: ${props => props.theme.ui.colors.gray500};
      }
    }
  }
`;

export const AnchorInternalWrapper = styled(Link)`
  ${buttonStyles}
`;

export const AnchorExternalWrapper = styled.a`
  ${buttonStyles}
`;

export const ButtonWrapper = styled.button`
  ${buttonStyles}
`;

export const Stage = styled.div`
  transform: rotate(270deg) translate(-3px, 7px);

  .dot-fire {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${props => props.color};
    color: ${props => props.color};
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -0.85s;
  }

  .dot-fire::before,
  .dot-fire::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${props => props.color};
    color: ${props => props.color};
  }

  .dot-fire::before {
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -1.85s;
  }

  .dot-fire::after {
    box-shadow: 9999px 22.5px 0 -5px ${props => props.color};
    animation: ${props => dotFire(props.color)} 1.5s infinite linear;
    animation-delay: -2.85s;
  }
`;
