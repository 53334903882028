import { fetchApiData } from "client/utils/fetchApiData";
import { Claim, CreateClaimRequest } from "../../../../types";
import { getCommonHeaders } from "../../../../utils/headers";

export default (claimReq: CreateClaimRequest): Promise<Claim | null> => {
  return fetchApiData({
    url: `/api/user/claims/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(claimReq),
    },
  });
};
