import { PartnerLocationFragment } from "client/hooks/data/partner/usePartnerLocationStore";
import { fetchApiData } from "client/utils/fetchApiData";
import {
  PartnerClaimsCountByCategoryResponse,
  PartnerClaimsCountByStatusResponse,
} from "../../../types/actions";
import { Category, ClaimStatus } from "../../../types/models";
import { PartnerClaimStatusEnum } from "../../../types/theme";
import { FilterItem } from "../../../ui-components/FilterDropdown/types";
import getQueryString from "../../../utils/getQueryString";

export type ClaimFilters = {
  status: FilterItem<ClaimStatus | "ALL">;
  category: FilterItem;
};

export const initialCategoryFilter: FilterItem<"ALL"> = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export const initialStatusFilter: FilterItem<"ALL"> = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export function getStatusFilters(
  {
    statusCounts,
  }: {
    statusCounts: PartnerClaimsCountByStatusResponse;
  },
  isArchive: boolean,
): FilterItem<ClaimStatus | "ALL">[] {
  const statuses = isArchive
    ? [ClaimStatus.EXPIRED, ClaimStatus.CANCELED]
    : [ClaimStatus.NEW, ClaimStatus.MATCHED, ClaimStatus.HAS_MATCHES, ClaimStatus.NO_MATCHES];
  return [
    {
      ...initialStatusFilter,
      count: statusCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...statuses.map(value => {
      const claim = statusCounts.find(claim => claim.value === value);

      return claim === undefined
        ? {
            name: PartnerClaimStatusEnum[value],
            value,
            count: 0,
          }
        : {
            ...claim,
            name: PartnerClaimStatusEnum[value],
          };
    }),
  ];
}

export function getCategoryFilters({
  categories,
  categoryCounts,
}: {
  categories: Category[];
  categoryCounts: PartnerClaimsCountByCategoryResponse;
}): FilterItem[] {
  return [
    {
      ...initialCategoryFilter,
      count: categoryCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...categories.map(({ id, name }) => {
      const category = categoryCounts.find(claim => claim.id === id);

      return {
        name,
        count: category?.count || 0,
        value: id,
      };
    }),
  ];
}

function getCountQuery({ partnerUserLocation, filters }, isArchive: boolean) {
  return {
    ...(filters.status.value === "ALL"
      ? {
          status: isArchive
            ? [ClaimStatus.EXPIRED, ClaimStatus.CANCELED]
            : [
                ClaimStatus.NEW,
                ClaimStatus.MATCHED,
                ClaimStatus.NO_MATCHES,
                ClaimStatus.HAS_MATCHES,
              ],
        }
      : { status: [filters.status.value] }),
    ...(filters.category.value !== "ALL" && {
      category: [filters.category.value],
    }),
    ...(partnerUserLocation.id !== "" && {
      lost_locations: [partnerUserLocation.id],
    }),
  };
}

export async function getStatusCount(
  {
    partnerId,
    partnerUserLocation,
    filters,
  }: {
    partnerId: string;
    partnerUserLocation: PartnerLocationFragment;
    filters: ClaimFilters;
  },
  isArchive: boolean,
): Promise<PartnerClaimsCountByStatusResponse | null> {
  const query = getCountQuery(
    {
      partnerUserLocation,
      filters: {
        ...filters,
        status: {
          value: "ALL",
        },
      },
    },
    isArchive,
  );

  return await fetchApiData({
    url: `/api/partner/partners/${partnerId}/claims/count-by-status/${getQueryString(query)}`,
  });
}

export async function getCategoryCount(
  { partnerId, partnerUserLocation, filters },
  isArchive: boolean,
): Promise<PartnerClaimsCountByCategoryResponse | null> {
  const query = getCountQuery(
    {
      partnerUserLocation,
      filters: {
        ...filters,
        category: {
          value: "ALL",
        },
      },
    },
    isArchive,
  );

  return await fetchApiData({
    url: `/api/partner/partners/${partnerId}/claims/count-by-category/${getQueryString(query)}`,
  });
}

export async function getCounts({
  partnerId,
  partnerUserLocation,
  filters,
  isArchive,
}: {
  partnerId: string;
  partnerUserLocation: PartnerLocationFragment;
  filters: ClaimFilters;
  isArchive: boolean;
}): Promise<
  [PartnerClaimsCountByStatusResponse | null, PartnerClaimsCountByCategoryResponse | null] | void
> {
  return Promise.all([
    getStatusCount({ partnerId, partnerUserLocation, filters }, isArchive),
    getCategoryCount({ partnerId, partnerUserLocation, filters }, isArchive),
  ]).then(res => res);
}
