import { getMatches } from "client/actions/partnerMatches";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import DefaultError from "client/ui-components/DefaultError";
import React, { useEffect, useMemo, useState } from "react";
import { useGetPartnerClaims } from "../../../actions/partnerClaims";
import { MatchIcon } from "../../../assets/icons/icons";
import MatchesCard from "../../../components/Cards/MatchesCard";
import Loader from "../../../components/Loader";
import history from "../../../context/history";
import { usePartnerUserState } from "../../../context/PartnerUser";
import { isMdQuery } from "../../../helpers/mediaQuery";
import theme from "../../../theme/index";
import { ClaimStatus, Match, MatchStatus, PartnerClaim } from "../../../types";
import NoMatches from "../../../ui-components/NoMatches";
import Typography from "../../../ui-components/Typography";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import { getCategoryName, getLocationName } from "../../../utils/mappers";
import { useMatchingStore } from "./matchingStore";
import { CardsContainer, Header, StyledSearchResultMagnifyingGlass } from "./styles";

export interface MatchesProps {
  className?: string;
  count?: number;
  limit?: number;
  partnerId: string;
  title?: string;
}

export type FilteredClaim = PartnerClaim & {
  status: ClaimStatus;
  category: string;
  lostLocation?: string;
  potentialMatches?: Match[];
};

const Matches = (props: MatchesProps) => {
  const { limit, partnerId } = props;
  const [isMatchesLoading, setIsMatchesLoading] = useState<boolean>(false);
  const [filteredClaims, setFilteredClaims] = useState<FilteredClaim[]>([]);
  const setClaims = useMatchingStore(state => state.setClaims);

  const { partnerUserStateLoading } = usePartnerUserState();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const categories = useCategories();

  const isMd = isMdQuery();

  const [getClaims, { data: claims, loading: claimsLoading, error: claimsError }] =
    useGetPartnerClaims();

  useEffect(() => {
    getClaims({
      partnerId: partnerId,
      filters: {
        ...(limit && { limit: limit }),
        status: [ClaimStatus.HAS_MATCHES],
        lost_location: partnerUserLocation.id,
      },
    });
  }, [partnerId, partnerUserLocation.id]);

  useEffect(() => {
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    if (claims !== null) {
      setIsMatchesLoading(true);
      setClaims(claims.results);
      Promise.all(
        claims?.results.map(async claim => {
          const pm = await getMatches({
            partnerId: partnerId,
            filters: {
              status: MatchStatus.PENDING,
              claim: claim.id,
            },
          });
          return {
            ...claim,
            status: claim.status,
            category:
              categories.data && claim.category
                ? getCategoryName(categories.data, claim.category)
                : "",
            lostLocation:
              partnerLocations && claim.lost_location
                ? getLocationName(partnerLocations, claim.lost_location)
                : "",
            potentialMatches: pm?.results,
          };
        }),
      ).then(filteredClaims => {
        setIsMatchesLoading(false);
        setFilteredClaims(filteredClaims);
      });
    }
  }, [partnerId, location, claims]);

  const rows = useMemo(() => {
    if (
      filteredClaims === null ||
      filteredClaims === undefined ||
      claimsLoading ||
      isMatchesLoading
    ) {
      return [];
    }

    return filteredClaims.map((claim, i) => {
      return (
        <MatchesCard
          key={claim.id}
          claim={claim}
          categoryName={categories.data ? claim.category : ""} // TODO: find out why we are checking categories here
          cardKey={i}
          onClick={() => {
            onClaimClick(claim);
          }}
          text={`Review match${claim.potentialMatches!.length > 1 ? "es" : ""}`}
        />
      );
    });
  }, [filteredClaims]);

  if (!limit && partnerUserStateLoading) {
    return <Loader />;
  }

  if (claimsError) {
    return <DefaultError />;
  }

  if (claims && claims?.count < 1 && !claimsLoading) {
    return (
      <NoMatches
        icon={
          <StyledSearchResultMagnifyingGlass
            accessibilityTitle="SearchResultsMagnifyingGlassIcon"
            titleId="SearchResultsMagnifyingGlassIcon"
            className="mb-3"
          />
        }
        heading="Searching for potential matches!"
        description={`Our engine will keep searching for matches for 
          ${
            partnerUserLocation.name || "All locations"
          }. Rest assured that your team will be notified
          as soon as we find anything.`}
      />
    );
  }

  const onClaimClick = (claim: PartnerClaim) => {
    history.push(`/partner/matches/matching/?claimId=${claim.id}`);
  };

  const header = (
    <Header className="bg-white">
      {isMd ? (
        <>
          <div className="d-flex mt-35">
            <MatchIcon
              className="mb-2 ms-45"
              width={80}
              height={80}
              accessibilityTitle="PotentialMatchIcon"
              titleId="PotentialMatchIcon"
            />
            <div className="d-flex flex-column justify-content-center ms-35 me-45 px-2">
              <Typography
                className="mb-1"
                as="h4"
                heading
                fontSize={theme.ui.fontSizes.md}
                color={theme.ui.colors.primary700}
                lineHeight={1.35}
              >
                You have{" "}
                <Typography
                  as="span"
                  heading
                  fontSize={theme.ui.fontSizes.md}
                  color={theme.ui.colors.primary700}
                  fontWeight={theme.ui.fontWeights.bold}
                  lineHeight={1.35}
                >
                  {`${claims?.count} claim${
                    claims?.count && claims.count > 1 ? "s" : ""
                  } with potential matches`}
                </Typography>
              </Typography>
              <Typography
                className="mb-0"
                as="p"
                fontSize="16px"
                color={theme.ui.colors.gray600}
                fontWeight={theme.ui.fontWeights.normal}
                lineHeight={1.5}
              >
                Dive in and accept or decline the suggested matches. The sooner you review, the
                faster we can start the return process.
              </Typography>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 px-5" style={{ maxWidth: "1072px" }}>
            <Typography
              className="mb-25 mt-35"
              as="p"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              lineHeight={1.2}
            >
              Claims
            </Typography>
            <Typography
              className="mb-25 mt-35"
              as="p"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              lineHeight={1.2}
            >
              Action
            </Typography>
          </div>
        </>
      ) : (
        <>
          <MatchIcon
            className="mt-35 mb-2"
            width={48}
            height={48}
            accessibilityTitle="PotentialMatchIcon"
            titleId="PotentialMatchIcon"
          />
          <Typography
            className="mb-1  px-3"
            as="h4"
            heading
            fontSize={theme.ui.fontSizes.md}
            color={theme.ui.colors.primary700}
            textAlign="center"
            lineHeight={1.35}
          >
            You have{" "}
            <Typography
              as="span"
              heading
              fontSize={theme.ui.fontSizes.md}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              lineHeight={1.35}
            >
              {`${claims?.count} claim${
                claims && claims.count > 1 ? "s" : ""
              } with potential matches`}
            </Typography>
          </Typography>
          <Typography
            className="mb-25"
            as="p"
            fontSize="16px"
            color={theme.ui.colors.gray600}
            textAlign="center"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
          >
            Dive in and accept or decline.
          </Typography>
        </>
      )}
    </Header>
  );

  return claimsLoading || isMatchesLoading || !filteredClaims.length ? (
    <Loader />
  ) : (
    <>
      {header}
      <CardsContainer className="mx-35 mx-lg-0">{rows}</CardsContainer>
    </>
  );
};

export default Matches;
