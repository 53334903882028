import React from "react";
import { SVGProps } from "./types";

export default function ArrowRight({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) {
  return (
    <svg
      {...props}
      aria-hidden={props["aria-hidden"] || false}
      aria-labelledby={`${titleId} ${accessibilityDesc ? accessibilityDesc.descId : ""}`}
      fill="none"
      height={props.height || 24}
      viewBox="0 0 24 24"
      width={props.width || 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={titleId}>{accessibilityTitle}</title>

      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}

      <path
        clipRule="evenodd"
        d="M4.49609 12.4334C4.49609 12.8476 4.83188 13.1834 5.24609 13.1834L17.8864 13.1834L11.4774 19.5924C11.1845 19.8853 11.1845 20.3602 11.4774 20.6531C11.7703 20.946 12.2452 20.946 12.5381 20.6531L19.9627 13.2285C19.9974 13.1937 20.028 13.1565 20.0545 13.1173C20.3149 12.9997 20.4961 12.7377 20.4961 12.4334C20.4961 12.1448 20.333 11.8942 20.094 11.7688C20.0601 11.7054 20.0164 11.6459 19.9629 11.5924L12.5383 4.16779C12.2454 3.8749 11.7705 3.8749 11.4776 4.16779C11.1847 4.46068 11.1847 4.93556 11.4776 5.22845L17.9326 11.6834L5.24609 11.6834C4.83188 11.6834 4.49609 12.0192 4.49609 12.4334Z"
        fill={props.color || "currentcolor"}
        fillRule="evenodd"
      />
    </svg>
  );
}
