import React from "react";
import { SVGProps } from "./types";

export default function ChevronRight({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) {
  return (
    <svg
      {...props}
      aria-hidden={props["aria-hidden"] || false}
      aria-labelledby={`${titleId} ${accessibilityDesc ? accessibilityDesc.descId : ""}`}
      fill="none"
      height={props.height || 24}
      viewBox="0 0 24 24"
      width={props.width || 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={titleId}>{accessibilityTitle}</title>

      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}

      <path
        d="M9 18L15 12L9 6"
        stroke={props.color || "currentcolor"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
