import { useSearchParam } from "client/hooks";
import { useCategoryCounts } from "client/hooks/data/partner/partnerItemCounts";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { ItemStatus, UnclaimedStatus } from "client/types";
import { FilterSelect } from "client/ui-components/Select";
import { stringToDate } from "client/utils/dateUtils";
import React, { useMemo } from "react";

const DEFAULT_OPTION = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export function CategoryFilter() {
  const { partnerUserLocation } = usePartnerLocationStore();
  const [category, setCategory] = useSearchParam<string>("category", "ALL");
  const [dateAfter] = useSearchParam<string>("date_after", "ALL");
  const [dateBefore] = useSearchParam<string>("date_before", "ALL");
  const filters = useMemo(
    () => ({
      status: ItemStatus.UNCLAIMED,
      lost_locations: partnerUserLocation.id ? [partnerUserLocation.id] : undefined,
      unclaimed_status: [UnclaimedStatus.NULL],
      suggested_unclaimed_status: [UnclaimedStatus.NULL],
      date_after: dateAfter == "ALL" ? undefined : stringToDate(dateAfter),
      date_before: dateBefore == "ALL" ? undefined : stringToDate(dateBefore),
    }),
    [dateAfter, dateBefore, partnerUserLocation?.id],
  );
  const { categories } = useCategoryCounts(filters);
  const selectedCategory = categories?.find(({ name }) => name == category) ?? DEFAULT_OPTION;

  return (
    <FilterSelect
      label="Category"
      value={selectedCategory.name}
      onValueChange={value => setCategory(value)}
      options={categories.map(({ name, count }) => ({ name, count, value: name }))}
      data-testid="categoryFilter"
    />
  );
}
