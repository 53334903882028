import React from "react";
import ClevelandBrownsStadiumLogo from "../../../../assets/logos/partnerLogos/cleveland-browns-stadium.png";
import SavannahHiltonHeadLogo from "../../../../assets/logos/partnerLogos/savannah-hilton-head.svg";
import StateFarmArenaLogo from "../../../../assets/logos/partnerLogos/state-farm-arena.svg";
import SyracuseHancockLogo from "../../../../assets/logos/partnerLogos/syracuse-hancock.svg";
import UniversalLogo from "../../../../assets/logos/partnerLogos/universalstudioslogo.png";
import { fontSizes } from "../../../../theme/uiTheme";
import { Text } from "../../../../ui-components/";
import SectionContainer from "../../../../ui-components/SectionContainer/index";
import { partnerLogosCopy } from "../../content";
import { LogosWrapper } from "./styles";

const PartnerLogos = () => {
  return (
    <SectionContainer className="mt-6" noPaddingY>
      <Text className="text-center mb-45" fontSize={fontSizes.lg} lineHeight="1.5">
        {partnerLogosCopy.body}
      </Text>

      <LogosWrapper className="d-flex flex-wrap justify-content-center gap-2 gap-md-225">
        <div>
          <img src={UniversalLogo} alt="Universal logo" />
        </div>

        <div>
          <img src={SavannahHiltonHeadLogo} alt="Savannah Hilton Head logo" />
        </div>

        <div>
          <img src={SyracuseHancockLogo} alt="Syracuse Hancock logo" />
        </div>

        <div className="w-100" />

        <div className="d-flex align-items-center">
          <img src={ClevelandBrownsStadiumLogo} alt="Cleveland Browns Stadium logo" />
        </div>

        <div>
          <img src={StateFarmArenaLogo} alt="State Farm Arena logo" />
        </div>
      </LogosWrapper>
    </SectionContainer>
  );
};

export default PartnerLogos;
