import classNames from "classnames";
import React, { useContext } from "react";
import { FormGroupContext } from "../FormGroup";
import { Input, InputWrapper } from "./styles";

type InputProps = React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;
type TextAreaProps = React.PropsWithoutRef<JSX.IntrinsicElements["textarea"]>;

export type Props = InputProps &
  TextAreaProps & {
    as?: "input" | "textarea";
    icon?: {
      position: "start" | "end";
      element: React.ReactNode;
    };
    fullWidth?: boolean;
  };

function FormControl(
  { as, className, disabled, icon, fullWidth, ...props }: Props,
  ref: React.Ref<HTMLInputElement>,
) {
  const { disabled: groupDisabled, hasError, variant } = useContext(FormGroupContext);

  const iconElement =
    icon &&
    React.cloneElement(icon.element as React.ReactElement, {
      className: classNames("input-icon", icon.position, `${variant}-input-icon`),
    });

  return (
    <InputWrapper>
      {icon && icon.position === "start" && iconElement}

      <Input
        {...props}
        as={as || "input"}
        ref={ref}
        className={classNames(
          hasError && "has-error",
          `${variant}-input`,
          fullWidth && "full-width",
          icon && "has-icon",
          icon && icon.position === "start" ? "icon-start" : "icon-end",
          as === "textarea" && "textarea",
          className,
        )}
        disabled={groupDisabled || disabled}
      />

      {icon && icon.position === "end" && iconElement}
    </InputWrapper>
  );
}

export default React.forwardRef(FormControl);
