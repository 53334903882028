import React from "react";
import PhoneInput from "react-phone-number-input";

import { StyledPhoneInputWrapper } from "./styles";

const StyledPhoneInputV2 = props => {
  return (
    <StyledPhoneInputWrapper>
      <PhoneInput
        onBlur={() => {
          props.onBlur();
        }}
        aria-label={props.ariaLabel}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultCountry={props.defaultCountry}
        className={props.className}
        tabIndex={0}
      />
    </StyledPhoneInputWrapper>
  );
};

export default StyledPhoneInputV2;
