import { useQuery } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default (search?: string) => {
  const { data, isLoading, error } = useQuery(queryFactory.statesFiltered(search));

  return {
    states: data?.results,
    isLoading,
    error,
  };
};
