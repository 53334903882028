import classNames from "classnames";
import React, { useEffect, useRef } from "react";

import { CatchClickOutside } from "../../ui-components";

import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { DropdownAlignment } from "./types";

import { DropdownMenuWrapper, StyledBasicDropdownButton } from "./styles";

interface BasicDropdownProps {
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
  closeOnTab?: boolean;
  dropdownAlignment: DropdownAlignment;
  dropdownTriggerElement: React.ReactNode;
  focusFirstElementOnShow?: boolean;
  showDropdown: boolean;
  setShowDropdown: (showDropdown: boolean) => void;
  width?: string;
}

const BasicDropdown: React.FC<BasicDropdownProps> = props => {
  const {
    ariaLabel,
    children,
    className,
    closeOnTab,
    dropdownAlignment,
    dropdownTriggerElement,
    focusFirstElementOnShow,
    showDropdown,
    setShowDropdown,
    width,
  } = props;

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownTriggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDropdown && focusFirstElementOnShow) {
      (dropdownWrapperRef.current?.firstChild as HTMLElement)?.focus();
    }
  }, [showDropdown]);

  function onTriggerKeyDown(e) {
    if (e.key === KEYBOARD_KEY.ENTER || e.key === KEYBOARD_KEY.SPACE) {
      e.preventDefault();
      setShowDropdown(!showDropdown);
    }
    if (e.key === KEYBOARD_KEY.TAB && closeOnTab) {
      setShowDropdown(false);
    }
    if (e.key === KEYBOARD_KEY.DOWN) {
      e.preventDefault();
      showDropdown === false && setShowDropdown(true);
      (dropdownWrapperRef.current?.firstChild as HTMLElement)?.focus();
    }
    if (e.key === KEYBOARD_KEY.ESCAPE) {
      setShowDropdown(false);
    }
  }

  function onDropdownKeyDown(e) {
    const activeElement = document.activeElement;
    if (e.key === KEYBOARD_KEY.ENTER || e.key === KEYBOARD_KEY.SPACE) {
      e.preventDefault();
      setShowDropdown(!showDropdown);
    }
    if (e.key === KEYBOARD_KEY.TAB && closeOnTab) {
      setShowDropdown(false);
    }
    if (e.key === KEYBOARD_KEY.DOWN) {
      e.preventDefault();
      (activeElement?.nextElementSibling as HTMLElement).focus();
    }
    if (e.key === KEYBOARD_KEY.UP) {
      e.preventDefault();
      (activeElement?.previousElementSibling as HTMLElement).focus();
    }
    if (e.key === KEYBOARD_KEY.ESCAPE) {
      setShowDropdown(false);
      dropdownTriggerRef.current?.focus();
    }
  }

  return (
    <CatchClickOutside
      className={classNames("dropdown d-block", {
        [`${className}`]: className !== undefined,
      })}
      onClickOutside={() => setShowDropdown(false)}
      aria-labelledby="dropdownMenuLink"
    >
      <StyledBasicDropdownButton
        aria-expanded={showDropdown}
        aria-label={ariaLabel}
        className="w-fit-content"
        onClick={() => setShowDropdown(!showDropdown)}
        onKeyDown={onTriggerKeyDown}
        ref={dropdownTriggerRef}
        role="button"
        tabIndex={0}
        data-testid="filterDropdownButton"
      >
        {dropdownTriggerElement}
      </StyledBasicDropdownButton>
      <DropdownMenuWrapper
        as={"div"}
        dropdownAlignment={dropdownAlignment}
        className={classNames("dropdown-menu mt-2", {
          show: showDropdown,
        })}
        onKeyDown={onDropdownKeyDown}
        ref={dropdownWrapperRef}
        width={width}
        data-testid="filterDropdownMenu"
      >
        {children}
      </DropdownMenuWrapper>
    </CatchClickOutside>
  );
};

export default BasicDropdown;
