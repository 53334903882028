import React from "react";

export type SvgProps = React.PropsWithoutRef<JSX.IntrinsicElements["svg"]> & {
  accessibilityTitle: string;
  accessibilityDesc?: {
    descId: string;
    desc: string;
  };
  titleId: string;
};

export const Svg = ({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  children,
  ...props
}: SvgProps) => {
  return (
    <svg
      {...props}
      height={props.height || 24}
      viewBox={props.viewBox || "0 0 24 24"}
      width={props.width || 24}
      aria-labelledby={`${titleId} ${accessibilityDesc ? accessibilityDesc.descId : ""}`}
      aria-hidden={props["ariaHidden"] || false}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.fill || "none"}
    >
      <title id={titleId}>{accessibilityTitle}</title>
      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}
      {children}
    </svg>
  );
};
