import styled from "styled-components";

export const TabsContainer = styled.div`
  background: ${props => props.theme.colors.white} !important;
  border-radius: ${props => props.theme.borderRadius.lg};
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.widths.claimMax};
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: calc(100% - 40px);
  }
`;
