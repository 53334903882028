import styled from "styled-components";
import Button from "../../../Button";
import Form from "../../../Form";

export const ResetButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const StyledForm = styled(Form)<{
  $hasMultipleLocations?: boolean;
}>`
  max-width: calc(100vw - 40px);

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 52px !important;

    [class*="InputWrapper"] {
      height: 52px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 52px !important;
    max-width: ${props =>
      props.$hasMultipleLocations ? "calc(100vw - 333px)" : "calc(100vw - 200px)"};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 52px !important;
    max-width: ${props => (props.$hasMultipleLocations ? "426px" : "576px")};
  }
`;

export const StyledControl = styled(Form.Control)<{
  $hasMultipleLocations?: boolean;
}>`
  opacity: 0;
  transition:
    opacity 0.5s ease-in-out,
    width 0.3s ease-in-out;
  height: 40px !important;
  margin: 12px 0;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 52px !important;
    border-top-left-radius: ${props => props.$hasMultipleLocations && "0 !important"};
    border-bottom-left-radius: ${props => props.$hasMultipleLocations && "0 !important"};
    border: none;
    margin: 0;
  }
`;

export const CloseSearchButton = styled(Button)`
  padding: 4px;
  width: 0;
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  background-color: ${props => props.theme.ui.colors.primary700} !important;
  color: ${props => props.theme.ui.colors.white} !important;
  border-color: rgba(209, 231, 255, 20%) !important;
  opacity: 0;
  transition:
    opacity 0.5s ease-in-out,
    width 0.5s ease-in-out;
  height: 28px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 36px !important;
    font-size: 14px;
    margin-left: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    position: absolute;
    right: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxxl}) {
    position: relative;
    right: 0;
    margin-left: 40px;
  }
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 32px;
  }
`;
