import { UnclaimedStatus } from "client/types";

export const unclaimedSuggestionStatuses = [
  "donate",
  "destroy",
  "store",
  "sell",
  "other",
  "more-life",
  "give-to-staff",
  "restock",
] as const;

export type UnclaimedSuggestionStatus = (typeof unclaimedSuggestionStatuses)[number];

export const unclaimedSuggestionHeading: Record<UnclaimedSuggestionStatus, string> = {
  donate: "Donation list",
  destroy: "Destruction list",
  store: "Store list",
  sell: "Sell list",
  other: "Other list",
  "more-life": "More Life list",
  "give-to-staff": "Give to Staff list",
  restock: "Restock list",
};

export function toSuggestionListName(status: UnclaimedSuggestionStatus) {
  switch (status) {
    case "donate":
      return "donation list";
    case "destroy":
      return "destruction list";
    case "sell":
      return "sell list";
    case "store":
      return "store list";
    case "more-life":
      return "More Life list";
    case "other":
      return "other list";
    case "give-to-staff":
      return "give to staff list";
    case "restock":
      return "restock list";
    default:
      return "list";
  }
}

export function toUnclaimedStatus(status: UnclaimedSuggestionStatus) {
  switch (status) {
    case "donate":
      return UnclaimedStatus.DONATED;
    case "destroy":
      return UnclaimedStatus.DESTROYED;
    case "sell":
      return UnclaimedStatus.SOLD;
    case "store":
      return UnclaimedStatus.STORED;
    case "more-life":
      return UnclaimedStatus.MORE_LIFE;
    case "other":
      return UnclaimedStatus.OTHER;
    case "give-to-staff":
      return UnclaimedStatus.GIVEN_TO_STAFF;
    case "restock":
      return UnclaimedStatus.RESTOCKED;
    default:
      return null;
  }
}

export function toSuggestedUnclaimedStatus(status: UnclaimedStatus) {
  switch (status) {
    case UnclaimedStatus.DONATED:
      return "Donate";
    case UnclaimedStatus.DESTROYED:
      return "Destroy";
    case UnclaimedStatus.SOLD:
      return "Sell";
    case UnclaimedStatus.STORED:
      return "Store";
    case UnclaimedStatus.MORE_LIFE:
      return "More Life";
    case UnclaimedStatus.OTHER:
      return "Other";
    case UnclaimedStatus.GIVEN_TO_STAFF:
      return "Give to Staff";
    case UnclaimedStatus.RESTOCKED:
      return "Restock";
    default:
      return "";
  }
}

export function toUnclaimedAction(status: UnclaimedStatus | null) {
  switch (status) {
    case UnclaimedStatus.DONATED:
      return "donated";
    case UnclaimedStatus.DESTROYED:
      return "destroyed";
    case UnclaimedStatus.SOLD:
      return "sold";
    case UnclaimedStatus.STORED:
      return "stored";
    case UnclaimedStatus.MORE_LIFE:
      return "More Life";
    case UnclaimedStatus.OTHER:
      return "other";
    case UnclaimedStatus.GIVEN_TO_STAFF:
      return "give to Staff";
    case UnclaimedStatus.RESTOCKED:
      return "restocked";
    default:
      return "";
  }
}
