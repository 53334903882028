import React from "react";

import { NoPhotoImagePlaceholder } from "../../components/ImageCarousel";
import theme from "../../theme";
import { PartnerClaim, PartnerItem } from "../../types";
import { Typography } from "../../ui-components";
import {
  ClaimsImageThumbnail,
  InfoWrapper,
  ItemWrapper,
  StyledBoomerangArrowRight,
} from "./styles";

export type PotentialMatchProps = {
  candidate: PartnerItem | PartnerClaim;
  active: boolean;
  onClick: () => void;
};

export default function PotentialMatch({ candidate, active, onClick }: PotentialMatchProps) {
  return (
    <ItemWrapper className={`g-0 d-flex p-35 ${active ? "active" : ""}`} onClick={onClick}>
      <div className="px-0 col-auto">
        {candidate?.images && candidate.images.length > 0 ? (
          <ClaimsImageThumbnail url={`${candidate.images[0]?.image}`} />
        ) : (
          <NoPhotoImagePlaceholder
            className="w-100 d-flex align-items-stretch"
            width="100px"
            height="100px"
            square
          >
            <span role="img" aria-label={`No image placeholder image`} />
          </NoPhotoImagePlaceholder>
        )}
      </div>
      <InfoWrapper className={`mx-0 w-100 d-flex flex-column justify-content-center pe-0`}>
        <div className="position-relative d-flex justify-content-end">
          {active && (
            <StyledBoomerangArrowRight
              accessibilityTitle="Active found item or claim"
              titleId="ActiveItemSnapshotTitle"
            />
          )}
        </div>
        <Typography
          style={{ maxWidth: "176px" }}
          className="ms-25 mb-0 pe-md-3"
          as="p"
          fontSize="16px"
          color={theme.ui.colors.primary700}
          fontWeight={theme.ui.fontWeights.bold}
          lineHeight={1.35}
        >
          {candidate.name}
        </Typography>
      </InfoWrapper>
    </ItemWrapper>
  );
}
