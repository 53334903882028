import { useQuery } from "@tanstack/react-query";
import fetchClaim from "client/hooks/data/user/fetchFunctions/fetchClaim";
import queryFactory from "client/hooks/data/user/queryFactory";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUserMatchFromId } from "../../../actions/userMatches";
import { getUserReturns } from "../../../actions/userReturns";
import { getShipmentById } from "../../../actions/userShipments";
import Loader from "../../../components/Loader";
import { UserRoutesEnum } from "../../../components/Routes";
import { useUserState } from "../../../hooks/useUserState";
import { ClaimsSearchResponse, GetUserReturnsRequest } from "../../../types/actions";
import { ClaimStatus, ReturnStatus } from "../../../types/models";
import { ClaimStatusStrings } from "../../../types/theme";
import { getUserClaimStatusString } from "../../../utils/getUserClaimStatusString";
import UserView from "../../views/UserView";
import ClaimPill from "./ClaimPill";

interface AllClaimsByStatusProps {
  title: string;
  subheader: string;
}

export const ClaimReturnsRequest: GetUserReturnsRequest = {
  filters: {
    status: [ReturnStatus.PENDING],
  },
};

export const setClaimsWithStatus = (
  responseCount,
  returnObj,
  shipment,
  claimsRes,
  setClaimsRes,
) => {
  getUserMatchFromId(returnObj.match).then(match => {
    if (match) {
      fetchClaim(match.claim).then(claim => {
        if (claim) {
          const recoveryMethod = returnObj.mode;
          setClaimsRes(prev => {
            // To avoid duplicates we check if the claim already exists in the list, if it does we update it
            const claimExists = prev.results.findIndex(result => result.id === claim.id);
            const newClaim = {
              ...claim,
              status: getUserClaimStatusString({
                recoveryMethod,
                claim,
                returnObject: returnObj,
                shipment,
              }),
              recoveryMethod: recoveryMethod,
            };
            if (claimExists > -1) {
              prev.results[claimExists] = newClaim;
              return prev;
            }
            return {
              ...prev,
              count: responseCount,
              results: [...prev.results, newClaim],
              loading: responseCount === claimsRes.results.length && false,
            };
          });
        }
      });
    }
  });
};

export const AllClaimsByStatus: React.FC<AllClaimsByStatusProps> = ({ title, subheader }) => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useUserState();
  const history = useHistory();
  const isReturns = location.pathname === UserRoutesEnum.CLAIMS_RETURNS;
  const [pendingReturnClaims, setPendingReturnClaims] = useState<
    ClaimsSearchResponse & { loading: boolean }
  >({
    count: 0,
    results: [],
    loading: false,
  });

  const getStatus = () => {
    switch (location.pathname) {
      case UserRoutesEnum.CLAIMS_STILL_LOOKING:
        return [ClaimStatus.NEW, ClaimStatus.NO_MATCHES, ClaimStatus.HAS_MATCHES];
      case UserRoutesEnum.CLAIMS_MATCHED:
        return [ClaimStatus.MATCHED];
      case UserRoutesEnum.CLAIMS_CANCELED:
        return [ClaimStatus.CANCELED];
      case UserRoutesEnum.CLAIMS_EXPIRED:
        return [ClaimStatus.EXPIRED];
    }
  };

  const { data: getClaimsRes, isLoading: getClaimsLoading } = useQuery({
    ...queryFactory.claimsFiltered({ filters: { status: getStatus() } }),
    enabled: !isReturns,
  });

  const loading = pendingReturnClaims.loading || getClaimsLoading;

  useEffect(() => {
    isReturns && setPendingReturnClaims({ ...pendingReturnClaims, loading: true });
    isReturns &&
      getUserReturns(ClaimReturnsRequest).then(response => {
        if (response) {
          (response.results || []).map(returnObj => {
            if (returnObj.shipment) {
              getShipmentById(returnObj.shipment).then(shipment => {
                if (shipment) {
                  setClaimsWithStatus(
                    response.count,
                    returnObj,
                    shipment,
                    pendingReturnClaims,
                    setPendingReturnClaims,
                  );
                }
              });
            } else {
              setClaimsWithStatus(
                response.count,
                returnObj,
                null,
                pendingReturnClaims,
                setPendingReturnClaims,
              );
            }
          });
        }
      });
  }, []);

  return (
    <UserView heading={title} subHeaderText={subheader} contentOverlapsHeader={true} hideMenu>
      <div className="mt-n35 mt-lg-0">
        {loading ? (
          <Loader />
        ) : (
          (getClaimsRes || pendingReturnClaims) &&
          ((getClaimsRes || pendingReturnClaims).results || []).map((claim, i) => {
            return (
              <ClaimPill
                key={i}
                id={claim.id}
                location={claim.lost_location.name}
                name={claim.name}
                lostDate={format(convertToDateIgnoringTime(claim.lost_at), "PP")}
                status={isReturns ? claim.status : ClaimStatusStrings[claim.status]}
                onClick={() => {
                  history.push({
                    pathname: `/claim/${claim.id}`,
                    state: { from: location.pathname },
                  });
                }}
              />
            );
          })
        )}
      </div>
    </UserView>
  );
};
