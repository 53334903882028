export default function queryParamsToObject(
  params: URLSearchParams,
): Record<string, string | string[]> {
  const map: Record<string, string | string[]> = {};

  params.forEach((value, name) => {
    const mapValue = map[name];
    if (Array.isArray(mapValue)) {
      return mapValue.push(value);
    }
    if (mapValue !== undefined) {
      return (map[name] = [mapValue, value]);
    }
    if (mapValue === undefined) {
      return (map[name] = value);
    }
  });

  return map;
}
