import React, { useContext, useEffect, useRef } from "react";

import { isSmQuery } from "client/helpers/mediaQuery";
import { Close, MagnifyingGlass } from "../../../../assets/icons/icons";
import { XSmallCircle } from "../../../../assets/ui-icons";
import history from "../../../../context/history";
import { PartnerGlobalSearchContext } from "../../../../context/PartnerGlobalSearch";
import theme from "../../../../theme";
import {
  CloseSearchButton,
  IconContainer,
  ResetButton,
  StyledControl,
  StyledForm,
  Wrapper,
} from "./styles";

export default function WideSearch({
  locationDropdown,
  hasMultipleLocations,
}: {
  locationDropdown?: React.JSX.Element;
  hasMultipleLocations: boolean;
}) {
  const {
    searchTerm,
    isSearchModeOn,
    setSearchTerm,
    showAllResults,
    setRestoreGlobalSearchBar,
    setShowAllResults,
    lastVisitedPage,
    setIsSearchModeOn,
  } = useContext(PartnerGlobalSearchContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const isSm = isSmQuery();

  useEffect(() => {
    if (inputRef.current && closeButtonRef.current) {
      inputRef.current.style.width = "calc(100vw - 102px)";
      inputRef.current.style.opacity = "1";
      closeButtonRef.current.style.width = isSm ? "126px" : "100px";
      closeButtonRef.current.style.opacity = "1";
    }
  }, []);

  const searchForm = (
    <StyledForm $hasMultipleLocations={hasMultipleLocations} onSubmit={e => e.preventDefault()}>
      <StyledForm.Group>
        <StyledControl
          $hasMultipleLocations={hasMultipleLocations}
          ref={inputRef}
          style={{
            paddingInlineStart: "40px",
            width: "0",
          }}
          disabled={showAllResults}
          autoFocus={isSearchModeOn}
          autoComplete="off"
          fullWidth
          type="text"
          id="search-boomerang"
          data-testid="searchBar"
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          icon={{
            position: "start",
            element: (
              <MagnifyingGlass
                style={{ left: "-11px" }}
                accessibilityTitle="Search Icon"
                titleId="Search Icon"
                color={theme.ui.colors.gray600}
              />
            ),
          }}
        />
        {searchTerm && (
          <ResetButton
            style={{ right: "15px" }}
            variant="text"
            className="p-0"
            aria-label="reset-inventory-search"
            onClick={() => {
              setSearchTerm("");
              if (showAllResults) setShowAllResults(false);
            }}
          >
            <XSmallCircle
              accessibilityTitle="reset-inventory-search-icon"
              titleId="reset-inventory-search-icon"
              data-testid="resetInventorySearchIcon"
            />
          </ResetButton>
        )}
      </StyledForm.Group>
    </StyledForm>
  );
  const closeButton = (
    <CloseSearchButton
      ref={closeButtonRef}
      style={{ padding: "4px", width: "0" }}
      className={!isSm ? "mb-3" : ""}
      aria-label="close search"
      data-testid="closeSearchButton"
      onClick={() => {
        setSearchTerm("");
        setRestoreGlobalSearchBar(true);
        setShowAllResults(false);
        setIsSearchModeOn(false);
        history.push(lastVisitedPage);
      }}
    >
      <IconContainer>
        <Close
          color={theme.ui.colors.white}
          height={20}
          width={20}
          accessibilityTitle="plus"
          ariaHidden={true}
          titleId="PrimaryHeaderButtonPlusSignTitle"
        />
      </IconContainer>
      Close search
    </CloseSearchButton>
  );

  return (
    <Wrapper
      className={`
        d-flex
        flex-column
        flex-sm-row
        justify-content-center
        align-items-center
        align-items-sm-center
        px-0
        justify-content-xxl-center
        pb-sm-4
        pb-md-0
      `}
    >
      {locationDropdown}
      {searchForm}
      {closeButton}
    </Wrapper>
  );
}
