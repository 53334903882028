import React from "react";
import styled from "styled-components";
import logo from "./../../assets/thanks-boomerang-logo.svg";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Brand = Navbar.Brand;

const BrandContainer = styled(Nav)`
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavbarBrand = styled(Brand)`
  max-width: 160px;
`;

const StyledNav = styled(Nav)`
  max-width: 238px;
  min-width: 238px;
  background-color: ${props => props.theme.ui.colors.primary700};
  display: flex;
  flex-direction: column;
`;

const SideBarNav = ({ className }) => (
  <StyledNav role="navigation" className={className}>
    <BrandContainer className={"h-100"}>
      <NavbarBrand className={`mx-0 text-center`} href="/">
        <img src={logo} alt="ThanksBoomerang Logo" className="w-100 px-2" />
      </NavbarBrand>
    </BrandContainer>
  </StyledNav>
);

export default SideBarNav;
