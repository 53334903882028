import React from "react";

import Badge, { BadgeSizesEnum, BadgeVariantsEnum } from "client/components/Badge";
import { isMdQuery } from "client/helpers/mediaQuery";
import { ItemStatus, UnclaimedStatus } from "client/types";
import ImageCarousel from "client/ui-components/ImageCarousel";
import { SuggestedStatus } from "../SuggestedStatus";
import {
  DescriptionContainer,
  InfoWrapper,
  LargeContainer,
  StyledLabel,
  StyledListItem,
  Subtitle,
  SubtitleContainer,
  SubtitleDescription,
  TitleContainer,
} from "./styles";

interface ItemSnapshotProps {
  active?: boolean;
  additionalComponent?: React.ReactNode;
  additionalMessage?: React.ReactNode;
  categoryName: string;
  className?: string;
  highlight?: boolean;
  icon?: React.ReactNode;
  images?: {
    image: string;
  }[];
  snapshotKey: number;
  label?: string;
  name: string;
  onClick?: () => void;
  $showSingleImage?: boolean;
  dateFound?: string;
  status: ItemStatus | UnclaimedStatus;
}

const ItemSnapshot: React.FC<ItemSnapshotProps> = props => {
  const {
    active,
    categoryName,
    className,
    highlight,
    images,
    snapshotKey,
    label,
    name,
    onClick,
    $showSingleImage,
    dateFound,
  } = props;
  const isMd = isMdQuery();
  if (!isMd)
    return (
      <LargeContainer>
        <ImageCarousel
          images={images?.map(image => image.image) || []}
          interval={null}
          touch
          dimensions={{ width: 192, height: 256 }}
          showSingleImage={$showSingleImage}
          controls={false}
          indicators={false}
          style={{
            margin: "auto",
          }}
        />
        <DescriptionContainer>
          <TitleContainer>
            <Badge
              variant={BadgeVariantsEnum.Contained}
              className="text-truncate text-nowrap badge"
              size={BadgeSizesEnum.Small}
              label={categoryName}
            />
            <div>{name}</div>
          </TitleContainer>
          <SubtitleContainer>
            <SubtitleDescription>Date found</SubtitleDescription>
            <Subtitle>{dateFound}</Subtitle>
          </SubtitleContainer>
          <SubtitleContainer>
            <SubtitleDescription>ID</SubtitleDescription>
            <Subtitle>{label}</Subtitle>
          </SubtitleContainer>{" "}
          <SubtitleContainer>
            <SubtitleDescription>Suggested status</SubtitleDescription>
            <SuggestedStatus status={props.status as UnclaimedStatus} />
          </SubtitleContainer>
        </DescriptionContainer>
      </LargeContainer>
    );

  return (
    <StyledListItem
      className={`${className} ${active ? "active" : ""}`}
      key={snapshotKey}
      cursorPointer={typeof onClick === "function"}
      highlight={highlight}
      onClick={typeof onClick === "function" ? onClick : () => {}}
    >
      <div className="d-flex p-0 m-0 align-items-center">
        <div
          style={{
            width: 100,
            height: 100,
            flexShrink: 0,
          }}
        >
          <ImageCarousel
            images={images?.map(image => image.image) || []}
            interval={null}
            touch
            dimensions={{ width: 100, height: 100 }}
            showSingleImage={$showSingleImage}
            controls={false}
            indicators={false}
          />
        </div>
        <InfoWrapper>
          <div className="px-0 d-flex align-items-start w-100 justify-content-between">
            <Badge
              variant={BadgeVariantsEnum.Contained}
              className="text-truncate text-nowrap badge"
              size={BadgeSizesEnum.Small}
              label={categoryName}
            />
          </div>
          <div
            className="px-0"
            style={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {name}
          </div>

          <StyledLabel
            className="p-0"
            data-testid="itemInventoryId"
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
              marginTop: "auto",
              verticalAlign: "bottom",
              color: "#525860",
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </StyledLabel>
        </InfoWrapper>
      </div>
    </StyledListItem>
  );
};

export default ItemSnapshot;
