import styled from "styled-components";

export const CheckboxInput = styled.input`
  width: 2rem;
  height: 2rem;
  border-width: 2px;
  border-color: ${props => props.theme.colors.gray3};

  &:checked {
    background-color: ${props => props.theme.ui.colors.primary500};
    border-color: ${props => props.theme.ui.colors.primary500};
  }
`;

export const CheckboxLabel = styled.label`
  line-height: ${props => props.theme.fontSizes.lg};
`;

export const ErrorText = styled.div`
  line-height: 1.3;
`;
