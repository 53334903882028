import styled from "styled-components";

export const StyledPositioningDiv = styled.div`
  margin: auto;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }
`;

export const StyledImage = styled.div`
  width: 180px;
  height: 264px;
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  border: 1px solid ${props => props.theme.ui.colors.gray500};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledClose = styled.div`
  border-radius: 100%;
  background: ${props => props.theme.ui.colors.gray300};
  position: absolute;
  padding: 8px;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;
