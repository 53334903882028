import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;

export const ArchivedLink = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  svg {
    width: 18px;
    height: 18px;
  }
`;
