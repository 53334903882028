import React from "react";
import styled from "styled-components";

import history from "../context/history";
import { PartnerRoutesEnum } from "./Routes";
import { LegacyTextComponent } from "./typography";

const Link = styled.a`
  cursor: pointer;
`;

export interface OverviewMessageProps {
  loading?: boolean;
  pendingReturnsCount: number;
  matchesCount: number;
  totalClaims: number;
}

const OverviewMessage: React.FC<OverviewMessageProps> = (props: OverviewMessageProps) => {
  const { loading = false, pendingReturnsCount, matchesCount, totalClaims } = props;

  // If loading show a blank line
  if (loading) {
    return <LegacyTextComponent className="pt-35 pt-lg-0">&nbsp;</LegacyTextComponent>;
  }

  // If there are no claims at all in the system
  const noClaims = totalClaims === 0;
  const noClaimsMessage = (
    <>
      Lost and Found is about to get a whole lot simpler.{" "}
      <Link className="fw700" onClick={() => history.push(PartnerRoutesEnum.ADD_ITEM)}>
        Start adding
      </Link>{" "}
      items to your inventory!
    </>
  );

  // Case when there are items / claims in inventory but no matches
  const noTasks = pendingReturnsCount === 0 && matchesCount === 0;
  const noTasksMessage = (
    <>
      There is nothing to do. Just chill. If you've found items, you can{" "}
      <Link className="fw700" onClick={() => history.push(PartnerRoutesEnum.ADD_ITEM)}>
        start adding to your inventory.
      </Link>{" "}
    </>
  );

  // If there are claims and matches
  const claimsMatchesCountMessage = (
    <>
      You have{" "}
      {matchesCount === 0 ? (
        <span className="fw700 text-primary">{pendingReturnsCount} returns</span>
      ) : (
        <Link className="fw700" onClick={() => history.push(PartnerRoutesEnum.RETURNS)}>
          {pendingReturnsCount} returns
        </Link>
      )}{" "}
      and{" "}
      {matchesCount === 0 ? (
        <span className="fw700 text-gray1">{matchesCount} potential match!</span>
      ) : (
        <Link className="fw700 text-gray1" onClick={() => history.push(PartnerRoutesEnum.MATCHES)}>
          {matchesCount} potential match{matchesCount !== 1 ? "es" : ""}!
        </Link>
      )}{" "}
    </>
  );

  return (
    <LegacyTextComponent className="pt-35 pt-lg-0" data-testid="overviewMessage">
      {noClaims ? noClaimsMessage : noTasks ? noTasksMessage : claimsMatchesCountMessage}
    </LegacyTextComponent>
  );
};

export default OverviewMessage;
