import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import animationData from "../../../../assets/animations/LookingForMatches.json";
import history from "../../../../context/history";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { Heading, Text } from "../../../../ui-components";
import useQueryString from "../../../../utils/useQueryString";
import SideOrTopPanel from "./SideOrTopPanel";
import { ContentWrapper, MainWrapper, MatchingCard, StyledContainer } from "./styles";

const LookingForMatches = () => {
  const [progress, setProgress] = useState(0);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [animationLoaded, setAnimationLoaded] = useState(false);

  const [title, setTitle] = useState("Taking a look!");
  const [subTitle, setSubTitle] = useState("Hang tight!");
  const claimIdFromUrl = useQueryString().get("claimId");
  const location = useLocation<{ returnPath: string }>();
  const isLg = isLgQuery();

  useEffect(() => {
    const timer = window.setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          if (prev > 80) {
            setTitle("Final touches being applied.");
            setSubTitle("Almost there!");
          } else if (prev > 40) {
            setTitle("Making progress.");
            setSubTitle("Thanks for hanging in there!");
          }
          return prev + 1;
        } else {
          clearInterval(timer);
          setTimeout(() => {
            history.replace(`${location.pathname}?claimId=${claimIdFromUrl}`, {
              returnPath: location?.state?.returnPath,
            });
          }, 500);
          return 100;
        }
      });
    }, 100);
    return () => clearInterval(timer);
  }, []);

  if (animationLoaded) {
    lottieRef?.current?.play();
  }

  return (
    <StyledContainer className={`${isLg ? "" : "row"} mx-0 d-flex`}>
      <SideOrTopPanel isLookingForMatches={true} />
      <MainWrapper>
        <MatchingCard>
          <ContentWrapper>
            <Lottie
              lottieRef={lottieRef}
              animationData={animationData}
              style={{ width: 98, height: 98 }}
              autoplay={false}
              onDOMLoaded={() => setAnimationLoaded(true)}
              loop={true}
            />
            <Heading variant="h3" size="md" data-testid="titleHeading">
              {title}
            </Heading>
            <Text textAlign="center" className="mt-15" data-testid="subTitle">
              {subTitle}
            </Text>
            <ProgressBar now={progress} />
          </ContentWrapper>
        </MatchingCard>
      </MainWrapper>
    </StyledContainer>
  );
};

export default LookingForMatches;
