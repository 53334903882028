import React from "react";
import DatePicker from "react-datepicker";
import { DatePickerContainer } from "./styles";

interface InlineDatePickerProps {
  maxDate?: Date;
  onChange: (date: Date) => any;
}

export function InlineDatePicker({ maxDate, onChange }: InlineDatePickerProps) {
  return (
    <DatePickerContainer>
      <DatePicker
        inline
        maxDate={maxDate}
        onChange={onChange}
        formatWeekDay={dayName => dayName.substring(0, 3)}
      />
    </DatePickerContainer>
  );
}
