import styled from "styled-components";
import cityBanner from "../../../../assets/ui-icons/CityBanner/city-banner.svg";
import theme from "../../../../theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - ${props => props.theme.ui.heights.navBase});
  font-family: ${props => props.theme.ui.fontFamilies.default};
  text-align: center;
  background-image: url(${cityBanner});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 117px;
  transition: background-size 0.3s ease-in-out;

  @media (max-height: 720px) and (max-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-top: 64px;
    padding-bottom: 164px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    background-position: center bottom;
    background-size: auto 177px;

    @media (max-height: 1000px) {
      padding-top: 64px;
      padding-bottom: 164px;
    }
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    min-height: calc(100vh - ${props => props.theme.ui.heights.navLg});
  }

  .title-section {
    .submit-button {
      margin-top: 13px;
      @media screen and (min-width: ${theme.breakpoints.md}) {
        margin-top: 0;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      .text-content {
        width: 55%;
      }
    }
  }
`;
