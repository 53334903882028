import { useSearchParam } from "client/hooks";
import { FilterItem } from "client/ui-components/FilterDropdown/types";
import { FilterSelect } from "client/ui-components/Select";
import React from "react";

const DEFAULT_OPTION = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

interface Props {
  categories?: FilterItem[];
}

export function CategoryFilter({ categories = [] }: Props) {
  const [category, setCategory] = useSearchParam<string>("category", "ALL");
  const selectedCategory = categories.find(({ name }) => name == category) ?? DEFAULT_OPTION;
  const onSelect = (name: string) => setCategory(name);

  return (
    <FilterSelect
      value={selectedCategory.name}
      label="Category"
      data-testid="categoryFilter"
      onValueChange={onSelect}
      options={categories.map(({ name, count }) => ({ name, count, value: name }))}
      isLoading={categories.length === 0}
      style={{ gridArea: "category" }}
    />
  );
}
