import React, { createContext, Fragment, useEffect, useMemo } from "react";
import { FooterProps } from "../CardFormFooter";
import Panel from "./Panel";
import { TabsContainer } from "./styles";
import TabsFooter from "./TabsFooter";
import TabsList from "./TabsList";

type Tab = {
  title: string;
  content: React.ReactNode;
  contentBeforePanel?: React.ReactNode;
  contentAfterPanel?: React.ReactNode;
  footerProps?: FooterProps;
};

type ExtendedTab = Tab & {
  index: number;
};

export type TabProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  tabs: Tab[];
  onTabChange?: (index: number) => void;
  clickable?: boolean;
  currentIndex: number;
  isEditing?: boolean;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<TabProps["currentIndex"]>>;
};

type TabContextType = {
  currentTab: ExtendedTab;
  currentIndex: number;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<TabContextType["currentIndex"]>>;
  tabs: ExtendedTab[];
  clickable: boolean;
};

export const TabContext = createContext<TabContextType>({
  currentTab: {} as TabContextType["currentTab"],
  currentIndex: 0,
  isEditing: false,
  setIsEditing: () => {},
  setCurrentIndex: () => {},
  tabs: [],
  clickable: true,
});

export default function Tabs({
  currentIndex,
  setCurrentIndex,
  isEditing,
  setIsEditing,
  tabs,
  onTabChange,
  clickable = true,
  ...props
}: TabProps) {
  const hydratedTabs = useMemo(() => tabs.map((tab, i) => ({ ...tab, index: i })), [tabs]);

  const currentTab = useMemo<ExtendedTab>(
    () => hydratedTabs[currentIndex],
    [hydratedTabs, currentIndex],
  );

  useEffect(() => {
    if (!onTabChange) return;

    onTabChange(currentIndex);
  }, [currentIndex, onTabChange]);

  const values = useMemo<TabContextType>(
    () => ({
      currentTab,
      tabs: hydratedTabs,
      currentIndex,
      isEditing: isEditing || false,
      setIsEditing: setIsEditing || (() => {}),
      setCurrentIndex,
      clickable,
    }),
    [hydratedTabs, currentTab, currentIndex, clickable, isEditing],
  );

  return (
    <TabContext.Provider value={values}>
      <TabsContainer {...props}>
        <TabsList />

        {hydratedTabs.map(({ index, content, contentBeforePanel, contentAfterPanel }) => (
          <Fragment key={`fragment-${index}`}>
            {contentBeforePanel}

            <Panel key={`panel-${index}`} index={index}>
              {content}
            </Panel>
            {contentAfterPanel}
          </Fragment>
        ))}

        {currentTab.footerProps !== undefined && (
          <TabsFooter {...currentTab.footerProps} className="px-35 px-sm-45" />
        )}
      </TabsContainer>
    </TabContext.Provider>
  );
}
