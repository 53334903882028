import React, { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { LostItemCreationContext, useAuthState } from "../../../../../../../context";
import { Form } from "../../../../../../../ui-components";

type FormData = {
  description: string;
};

type DescriptionFormArgs = {
  reviewUpdate?: boolean;
};

export default function DescriptionForm({ reviewUpdate = false }: DescriptionFormArgs) {
  const { setStep, description, setDescription } = useContext(LostItemCreationContext);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      description: description,
    },
    reValidateMode: "onSubmit",
  });

  const { user } = useAuthState();
  const nextStep = reviewUpdate || user.id ? "review" : "contact";

  const descriptionLength = watch("description").length;

  useEffect(() => {
    if (errors.description?.ref?.value?.length < 50) {
      setError("description", {
        type: "manual",
        message:
          descriptionLength > 0
            ? `${descriptionLength}/50 characters (Minimum 50 characters needed)`
            : "Minimum 50 characters needed",
      });
    } else {
      clearErrors("description");
    }
  }, [descriptionLength, errors]);

  const message = useMemo(
    () =>
      descriptionLength > 0
        ? `${descriptionLength}/50 characters (Minimum 50 characters needed)`
        : "Minimum 50 characters needed",
    [descriptionLength],
  );

  return (
    <Form
      id="description-form"
      data-testid="descriptionForm"
      onSubmit={handleSubmit(data => {
        setDescription(data.description);
        setStep(nextStep);
      })}
      onBlur={() => {
        trigger();
      }}
    >
      <Form.Group error={errors.description}>
        <Form.Control
          {...register("description", {
            required: message,
            minLength: {
              value: 50,
              message: message,
            },
          })}
          as="textarea"
          placeholder="Enter unique characteristics like color, model, stickers, etc."
          aria-label="description"
          data-testid="descriptionInput"
          autoFocus
        />
      </Form.Group>

      {descriptionLength < 50 && Object.keys(errors).length <= 0 && (
        <Form.Help fontWeight={descriptionLength > 0 ? 700 : 400}>{message}</Form.Help>
      )}
    </Form>
  );
}
