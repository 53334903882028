import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 155px auto;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.primaryBlue};
`;
const Title = styled.h2`
  margin: 20px auto 12px;
  color: ${props => props.theme.ui.colors.primary700};
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

const Text = styled.div`
  font-family: "Lato";
  font-weight: ${props => props.theme.fontWeights.default};
  color: ${props => props.theme.ui.colors.gray700};
`;

type BlankMessageProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  alt: string;
  image: string;
  title: string;
  dataTestId?: string;
};

const BlankMessage: React.FC<BlankMessageProps> = ({
  alt,
  children,
  className,
  image,
  title,
  ...props
}) => {
  return (
    <Container {...props} className={classNames("mw520", className)} data-testid="blankMessage">
      {image && <img src={image} alt={alt} />}

      {title && <Title>{title}</Title>}

      {children && <Text>{children}</Text>}
    </Container>
  );
};

export default BlankMessage;
