import { useContext } from "react";
import { UserContext, UserContextType } from "../context/User";

export function useUserState(): UserContextType {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserState must be used within a UserContext Provider");
  }

  return context;
}
