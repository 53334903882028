import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useRef, useState } from "react";
import lostItemAnimation from "../../../../assets/animations/LostItem.json";
import matchFoundAnimation from "../../../../assets/animations/MatchFound.json";
import { isLgQuery, isMdQuery } from "../../../../helpers/mediaQuery";
import { fontSizes } from "../../../../theme/uiTheme";
import { Button, Heading, SectionContainer, Text } from "../../../../ui-components";
import { aboveTheFoldCopy } from "../../content";
import { Wrapper } from "./styles";

const lostStyle = {
  height: 203,
  width: 250,
};

const matchStyleBase = {
  height: 250,
  width: 250,
};

const matchStyle = {
  height: 322,
  width: 250,
};

const AboveTheFold: React.FC = () => {
  const matchRef = useRef<LottieRefCurrentProps>(null);
  const lostRef = useRef<LottieRefCurrentProps>(null);
  const [matchAnimLoaded, setMatchAnimLoaded] = useState(false);
  const [lostAnimLoaded, setLostAnimLoaded] = useState(false);
  const isMd = isMdQuery();
  const isLg = isLgQuery();

  if (lostAnimLoaded && matchAnimLoaded) {
    lostRef?.current?.play();
    matchRef?.current?.play();
  }

  return (
    <Wrapper className="mb-425 mb-md-5">
      <SectionContainer noPaddingY className="title-section">
        <div className="d-md-flex flex-md-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
          <Lottie
            lottieRef={matchRef}
            animationData={matchFoundAnimation}
            style={isLg ? matchStyle : matchStyleBase}
            className="d-none d-md-block order-lg-3"
            autoplay={false}
            onDOMLoaded={() => setMatchAnimLoaded(true)}
            loop={true}
          />
          <div className="order-lg-2 text-content">
            <Heading variant="h1" className="mb-3 mb-md-4 text-center">
              {aboveTheFoldCopy.heading}
            </Heading>
            <Text
              fontSize={{ base: fontSizes.md, sm: fontSizes.lg }}
              lineHeight={{ base: 1.44, sm: 1.4, md: 1.5 }}
              className="body mb-425 mb-md-45"
              textAlign="center"
            >
              {aboveTheFoldCopy.body}
            </Text>
          </div>
          <Lottie
            lottieRef={lostRef}
            animationData={lostItemAnimation}
            style={lostStyle}
            autoplay={false}
            className="d-none d-lg-block order-lg-1"
            onDOMLoaded={() => setLostAnimLoaded(true)}
            loop={true}
          />
        </div>

        <Button
          aria-label="schedule-a-chat"
          href="https://share.hsforms.com/1olnAZ_DYS8amUoozJO7uaAc60y2"
          rel="noopener noreferrer"
          target="_blank"
          fullWidth={!isMd}
          size={isMd ? "xl" : "2xl"}
          className="px-md-35 ms-md-25 submit-button"
        >
          {aboveTheFoldCopy.ctaText}
        </Button>
      </SectionContainer>
    </Wrapper>
  );
};

export default AboveTheFold;
