const DEFAULT_US_PHONENUMBER = "+1 (555) 000-0000";
const DEFAULT_UK_PHONENUMBER = "+44 (0) 0000 000000";

const isoCodeToPhonenumber = {
  US: DEFAULT_US_PHONENUMBER,
  GB: DEFAULT_UK_PHONENUMBER,
};

export default function getCountryDefaultPhonenumber(country: string): string {
  return isoCodeToPhonenumber[country] || DEFAULT_US_PHONENUMBER;
}
