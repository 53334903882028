import styled, { keyframes } from "styled-components";

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItem = styled.div`
  font-size: ${props => props.theme.fontSizes.default};
  width: 196px;
  cursor: pointer;
  font-weight: 500;
  display: flex;

  svg {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background-color: ${props => props.theme.colors.lightBlue}80;
  }

  &.active {
    box-shadow: inset 4px 0 0 0 ${props => props.theme.colors.primaryBlue};
  }
`;

export const MenuWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
`;

const dropdownOpenAnimation = keyframes`
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
`;

export const MoreDropdownContainer = styled.ul`
  border-radius: ${props => props.theme.borderRadius.lg};
  box-shadow: ${props => props.theme.shadows.md};
  border: none;
  top: 80px;
  right: 0;
  left: auto;
  animation: ${dropdownOpenAnimation} 0.2s ease-in-out;
  transform-origin: top center;
`;

export const TextSVGWrapper = styled.div<{
  dropdownOpen?: boolean;
  tabIndex: number;
}>`
  color: ${props => props.theme.colors.white};
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  width: fit-content;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.default};
  svg {
    color: ${props => props.theme.colors.white};
  }
  &.more-wrapper {
    > div > svg {
      transition: 0.2s ease-in-out;
      ${props => (props.dropdownOpen ? "transform: rotate(180deg)" : "")};
    }
  }
  &.active,
  &:hover {
    color: ${props => props.theme.colors.white};
    svg {
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const Underline = styled.div`
  background-color: ${props => props.theme.ui.colors.primary700};
  height: 0.234rem;
  width: 80%;
  &.active {
    background-color: ${props => props.theme.colors.white};
  }
`;

export const ActionIndicator = styled.div<{
  forMobile?: boolean;
}>`
  background-color: ${props => props.theme.colors.white};
  height: 8px;
  width: 8px;
  border-radius: 50% !important;
  ${props =>
    !props.forMobile
      ? `position: absolute;
  right: 8px;
  bottom: 34px;`
      : `margin-left: 4px;
  margin-bottom: 12px;
  background-color: #000000`}
`;
