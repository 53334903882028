import styled from "styled-components";

import { BoomerangArrowRight } from "../../assets/icons/icons";

export const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  &.active {
    background-color: ${props => props.theme.colors.lightBlue}80;
    box-shadow: inset 4px 0 0 0 ${props => props.theme.ui.colors.primary500};
    cursor: default;
  }
  &:hover {
    background-color: ${props => props.theme.colors.lightBlue + "80"};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 12px 20px 15px 20px !important;
  }
`;
export const ClaimsImageThumbnail = styled.div<{ url: string }>`
  width: 96px;
  height: 96px;
  border-radius: 4px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;

export const InfoWrapper = styled.div`
  min-width: 0;
`;

export const StyledBoomerangArrowRight = styled(BoomerangArrowRight)`
  position: absolute;
  bottom: -8px;
  right: 3px;
`;
