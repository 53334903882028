import React, { useContext } from "react";
import CarouselGallery from "../../../../components/CarouselGallery";
import { PartnerClaimContext } from "../../../../context";
import ClaimStatus from "./ClaimStatus";
import ContactInfo from "./ContactInfo";
import LostItem from "./LostItem";
import { Wrapper } from "./styles";

export default function Details(props) {
  const { claim } = useContext(PartnerClaimContext);

  return claim === null ? null : (
    <Wrapper {...props}>
      <div className="d-lg-none d-flex justify-content-center w-100">
        <CarouselGallery images={claim.images} className="w-100" />
      </div>

      <ClaimStatus className="mt-35 mt-lg-0" />

      <hr className="my-45" />

      <ContactInfo />

      <hr className="my-45" />

      <LostItem />
    </Wrapper>
  );
}
