import { useQuery } from "@tanstack/react-query";
import { UnclaimedStatus } from "client/types";
import fetchData from "client/utils/fetchData";

async function getPartnerUnclaimedStatuses(partnerId: string): Promise<
  | {
      category: null | {
        id: string;
        name: string;
      };
      unclaimed_status: UnclaimedStatus;
      created_at: string;
      id: string;
      modified_at: string;
      location: string;
    }[]
  | null
> {
  return await fetchData(`/api/partner/partners/${partnerId}/location-category-unclaimed-statuses`);
}

export function usePartnerUnclaimedStatuses(partnerId: string) {
  return useQuery({
    queryKey: [partnerId, "unclaimed-statuses"],
    queryFn: () => getPartnerUnclaimedStatuses(partnerId),
  });
}
