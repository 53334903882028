const root = document.getElementsByTagName("html")[0];

export function mountBodyGray6() {
  document.body.classList.add("gray6");
  root.classList.add("gray6");
}
export function unmountBodyGray6() {
  document.body.classList.remove("gray6");
  root.classList.remove("gray6");
}

export function mountBodyNoOverflow() {
  document.body.classList.add("modal-open");
}
export function unmountBodyNoOverflow() {
  document.body.classList.remove("modal-open");
}
