import styled from "styled-components";
import Link from "../../../../../ui-components/Link";

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  & > * {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  padding: 0;

  &:hover * {
    stroke: #0254ad;
  }
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
