import React from "react";
import ContactDetailsSnapshot from "../../../components/ContactDetailsSnapshot";
import ItemSnapshot from "../../../components/ItemSnapshot";
import { Divider, InteriorModalWrapper } from "../../../components/Modal/styles";
import { isLgQuery } from "../../../helpers/mediaQuery";
import theme from "../../../theme";
import { ArtifactImage, Category, PartnerClaim, PartnerItem, User } from "../../../types";
import { getCategoryName } from "../../../utils/mappers";

interface PickupConfirmationInteriorProps {
  artifact: PartnerClaim | PartnerItem;
  categories: Category[];
  contactDetails?: Omit<User, "id" | "full_name">;
  images?: ArtifactImage[];
}

const PickupConfirmationInterior: React.FC<PickupConfirmationInteriorProps> = props => {
  const { artifact, categories, contactDetails, images } = props;

  const isLg = isLgQuery();

  return (
    <InteriorModalWrapper className="text-start d-flex flex-column gap-25 w-100">
      <ItemSnapshot
        {...artifact}
        additionalComponent={
          isLg ? (
            <ContactDetailsSnapshot
              className="mt-35"
              email={contactDetails?.email}
              header
              name={`${contactDetails?.first_name} ${contactDetails?.last_name}`}
              phoneNumber={contactDetails?.phone_number}
              textColor={theme.colors.gray3}
            />
          ) : undefined
        }
        categoryName={categories ? getCategoryName(categories, artifact.category) : ""}
        images={images || artifact.images}
        snapshotKey={0}
        label="Found item"
        useLgCarousel={!isLg}
      />
      {!isLg && (
        <>
          <Divider className="my-2" />
          <ContactDetailsSnapshot
            email={contactDetails?.email}
            header
            name={`${contactDetails?.first_name} ${contactDetails?.last_name}`}
            phoneNumber={contactDetails?.phone_number}
            textColor={theme.colors.gray3}
          />
        </>
      )}
    </InteriorModalWrapper>
  );
};

export default PickupConfirmationInterior;
