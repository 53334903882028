import styled from "styled-components";
import Text from "../../../../ui-components/Text";
import BoomerangSignEnd from "./boomerang-sign-end.svg";
import BoomerangSignStart from "./boomerang-sign-start.svg";

export const HeroSection = styled.section`
  background-color: #f3f9ff;
  padding: 64px 20px 72px 20px;
  text-align: center;

  background-color: ${props => props.theme.ui.colors.primary300};
  background-image: url(${BoomerangSignStart}), url(${BoomerangSignEnd});
  background-position:
    left bottom,
    right top;
  background-repeat: no-repeat, no-repeat;
  background-size:
    70px 68px,
    70px 68px;
  transition:
    padding,
    background-size 200ms ease-in;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-inline: 40px;
    padding-block: 64px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-block: 96px;
    padding-inline: 96px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    background-size:
      106px 102px,
      106px 102px;
  }
`;

export const Subtitle = styled(Text)`
  max-width: 654px;
  font-weight: 400;
  font-size: ${props => props.theme.ui.fontSizes.base};
  line-height: 1.5;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    font-size: ${props => props.theme.ui.fontSizes.lg};
  }
`;
