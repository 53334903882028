exports.env = process.env["BOOMERANG_FE_ENV"] || "local";
exports.backendUrl = undefined;
// Require all envs so they get bundled
const envs = {};
const possibleEnvs = ["local", "ci", "default", "dev", "production", "test", "uat"];

possibleEnvs.forEach(env => {
  try {
    envs[env] = require(`./${env}.config.json`);
  } catch (err) {
    console.warn(`Unable to load config file for environment ${env}`);
  }
});

function loadDefaults() {
  const defaults = envs["default"];
  Object.keys(defaults).forEach(k => (exports[k] = defaults[k]));
}

// Override defaults from environment config file
function loadEnvConfig(env) {
  const config = envs[env] || envs["default"];
  if (!envs[env]) {
    console.warn(
      `Unable to load config file for selected environment ${exports.env}, falling back to default`,
    );
  }

  Object.keys(config).forEach(k => (exports[k] = config[k]));
  return config;
}

// Override defaults from environment vars
function overrideWithEnvVars(defaults) {
  Object.keys(defaults).forEach(k => {
    // camelCase to YELLING_CASE
    const key = k.replace(/([A-Z])/g, "_$1").toUpperCase();

    if (key in process.env) {
      // Convert known types
      console.log(`Overriding ${k} by envar`);
      const previousValue = exports[k];
      if (typeof previousValue === "boolean") {
        exports[k] = process.env[key] === "true";
      } else if (typeof previousValue === "number") {
        exports[k] = parseInt(process.env[key], 10);
      } else {
        exports[k] = process.env[key];
      }
    }
  });
}

loadDefaults();
loadEnvConfig(exports.env);
overrideWithEnvVars(exports);
