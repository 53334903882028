import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 0 auto;
    max-width: 400px;
  }
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
    width: 576px;
  }
`;

export const StyledUpload = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed ${props => props.theme.ui.colors.primary500};
  &:focus {
    box-shadow: 0 0 0 4px ${props => props.theme.ui.colors.primary500};
  }
  border-radius: ${props => props.theme.borderRadius.sm};
  .drag-area {
    flex-direction: column;
    height: 264px;
    gap: 12px;
  }
  .addPhotos {
    color: ${props => props.theme.ui.colors.primary500};
    font-family: ${props => props.theme.ui.fontFamilies.heading};
    font-weight: ${props => props.theme.ui.fontWeights.bold};
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
  }
  .optionalText {
    color: ${props => props.theme.ui.colors.gray600};
    font-family: ${props => props.theme.ui.fontFamilies.default};
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
  &.dz-max-files-reached {
    pointer-events: none;
    cursor: default;
  }
`;

export const StyledUploadCloudContainer = styled.div`
  border: 6px solid ${props => props.theme.ui.colors.primary300};
  border-radius: 28px;
  height: 40px;
  width: 40px;
  background-color: ${props => props.theme.ui.colors.primary400};
  display: flex;
  justify-content: center;
  align-items: center;
`;
