import React from "react";
import styled from "styled-components";

interface LoaderProps {
  className?: string;
  height?: string;
}

const Wrapper = styled.div<{ height?: string }>`
  height: ${props => props.height || "300px"};
  width: auto;
`;

const Loader: React.FC<LoaderProps> = (props: { className?: string; height?: string }) => {
  const { className, height } = props;
  return (
    <Wrapper
      data-testid="loader"
      className={`d-flex justify-content-center align-items-center ${className}`}
      height={height}
    >
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Wrapper>
  );
};

export default Loader;
