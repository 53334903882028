import { useEffect } from "react";
import { logout, useAuthDispatch } from "../../context";

export default function SignOutPage() {
  const dispatch = useAuthDispatch();

  useEffect(() => {
    logout(dispatch);
  }, []);

  return null;
}
