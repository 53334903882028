import React from "react";

export const IconPerson = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_288_15646)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9974 3.41406C14.4661 3.41406 12.4141 5.46615 12.4141 7.9974C12.4141 10.5286 14.4661 12.5807 16.9974 12.5807C19.5286 12.5807 21.5807 10.5286 21.5807 7.9974C21.5807 5.46615 19.5286 3.41406 16.9974 3.41406ZM18.9841 7.2224C19.0206 7.18058 19.0485 7.13187 19.066 7.07913C19.0835 7.02639 19.0902 6.97069 19.0858 6.91531C19.0815 6.85992 19.0661 6.80596 19.0406 6.75662C19.015 6.70727 18.9799 6.66352 18.9372 6.62795C18.8945 6.59239 18.8452 6.56571 18.792 6.54951C18.7389 6.5333 18.683 6.52788 18.6278 6.53357C18.5725 6.53927 18.5189 6.55596 18.4702 6.58266C18.4215 6.60936 18.3786 6.64554 18.3441 6.68906L16.5524 8.83865L15.6253 7.91115C15.5467 7.83525 15.4415 7.79325 15.3322 7.7942C15.223 7.79515 15.1185 7.83897 15.0412 7.91622C14.964 7.99347 14.9201 8.09798 14.9192 8.20723C14.9182 8.31648 14.9602 8.42173 15.0361 8.50031L16.2861 9.75031C16.3271 9.79123 16.3761 9.82317 16.4301 9.84409C16.484 9.86501 16.5418 9.87446 16.5996 9.87183C16.6574 9.86921 16.7141 9.85456 16.7659 9.82883C16.8177 9.8031 16.8637 9.76685 16.9007 9.7224L18.9841 7.2224Z"
          fill="#06844F"
        />
      </g>
      <g clipPath="url(#clip1_288_15646)">
        <path
          d="M6.10938 14.7252L6.50637 12.2081L9.1357 10.9922L10.52 13.4241L12.48 13.8772"
          stroke="#00174A"
          strokeWidth="1.03926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.13983 10.9922L7.08833 17.1799L4.52344 19.7739"
          stroke="#00174A"
          strokeWidth="1.03926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1701 19.9337L9.78767 16.9302L7.83594 14.9141"
          stroke="#00174A"
          strokeWidth="1.03926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.6262 10.1118C10.2094 10.1118 10.6821 9.63904 10.6821 9.05589C10.6821 8.47274 10.2094 8 9.6262 8C9.04305 8 8.57031 8.47274 8.57031 9.05589C8.57031 9.63904 9.04305 10.1118 9.6262 10.1118Z"
          fill="#00174A"
        />
      </g>
      <defs>
        <clipPath id="clip0_288_15646">
          <rect width="10" height="10" fill="white" transform="translate(12 3)" />
        </clipPath>
        <clipPath id="clip1_288_15646">
          <rect width="9" height="12.4545" fill="white" transform="translate(4 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
