import classNames from "classnames";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { format } from "date-fns";
import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "../../../../components/typography";
import theme from "../../../../theme";
import { Claim } from "../../../../types/models";
import { ClaimStatusStrings } from "../../../../types/theme";
import ClaimPill from "../ClaimPill";

interface ClaimsSectionProps {
  claims: Claim[];
  header: string;
  isReturns?: boolean;
  seeAllLink?: string;
  showSeeAll?: boolean;
  subheader: string;
  className?: string;
}

const ClaimsSection: React.FC<ClaimsSectionProps> = ({
  claims,
  header,
  isReturns,
  seeAllLink,
  showSeeAll,
  subheader,
  className,
}) => {
  const history = useHistory();

  return (
    <section className={classNames("mb-45", className)} data-testid="claimSection">
      <Typography
        as={"h3"}
        fontWeight={theme.fontWeights.bold}
        fontSize={theme.fontSizes.xl}
        lineHeight={1.21}
        className="mb-1"
      >
        {header}
      </Typography>
      <Typography lineHeight={1.5} className="mb-25">
        {subheader}
      </Typography>
      {(claims || [])?.map((claim, i) => (
        <ClaimPill
          key={i}
          id={claim.id}
          location={claim.lost_location.name}
          name={claim.name}
          lostDate={format(convertToDateIgnoringTime(claim.lost_at), "PP")}
          status={isReturns ? claim.status : ClaimStatusStrings[claim.status]}
          onClick={() => {
            history.push(`/claim/${claim.id}`);
          }}
        />
      ))}
      {showSeeAll && seeAllLink && (
        <div className="d-flex justify-content-center my-35" data-testid="claimSectionSeeAllLink">
          <Typography
            className="cursor-pointer"
            as="a"
            fontWeight={theme.fontWeights.semiBold}
            lineHeight={1.5}
            fontSize={theme.fontSizes.sm}
            color={theme.colors.primaryBlue}
            onClick={() => {
              history.push(seeAllLink);
            }}
          >
            See all
          </Typography>
        </div>
      )}
    </section>
  );
};

export default ClaimsSection;
