import { useQuery } from "@tanstack/react-query";
import { ClaimStatus, PartnerClaimsCountByStatusResponse } from "client/types";
import queryFactory from "../queryFactory";

const getClaimsCountByStatus = (
  claimsCountByStatusResults?: PartnerClaimsCountByStatusResponse,
): { [key in ClaimStatus]: number } => {
  const toReturn = {
    [ClaimStatus.COMPLETED]: 0,
    [ClaimStatus.CANCELED]: 0,
    [ClaimStatus.EXPIRED]: 0,
    [ClaimStatus.HAS_MATCHES]: 0,
    [ClaimStatus.MATCHED]: 0,
    [ClaimStatus.NO_MATCHES]: 0,
    [ClaimStatus.NEW]: 0,
  };

  claimsCountByStatusResults &&
    (claimsCountByStatusResults || []).forEach(obj => {
      toReturn[obj.value] = obj.count;
    });

  return toReturn;
};

export default ({
  partnerId,
  partnerLocationId,
}: {
  partnerId: string;
  partnerLocationId: string;
}) => {
  const filters = {
    ...(partnerLocationId && {
      lost_locations: [partnerLocationId],
    }),
  };
  return useQuery({
    ...queryFactory.claimCountByStatusFiltered({
      partnerId,
      filters,
    }),
    select: data => getClaimsCountByStatus(data),
  });
};
