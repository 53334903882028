import styled from "styled-components";

export const Container = styled.section`
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-bottom: 28px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.ui.colors.white};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 20px;
  padding-bottom: 12px;
  margin-bottom: 1px;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.ui.colors.white};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  padding: 20px 0;
  margin-top: 1px;
`;

export const TableHeader = styled.div`
  background-color: ${props => props.theme.ui.colors.gray400};
  border-top: 1px solid ${props => props.theme.ui.colors.gray500};
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  width: calc(100vw - 40px);
`;
