import { fetchApiData } from "client/utils/fetchApiData";
import {
  PartnerClaimsCountByStatusRequest,
  PartnerClaimsCountByStatusResponse,
} from "../../../../types/actions";
import getQueryString from "../../../../utils/getQueryString";

export default async (
  partnerClaimsCountByStatusReq: PartnerClaimsCountByStatusRequest,
): Promise<PartnerClaimsCountByStatusResponse> => {
  const queryString = partnerClaimsCountByStatusReq.filters
    ? getQueryString(partnerClaimsCountByStatusReq.filters)
    : "";
  return fetchApiData({
    url: `/api/partner/partners/${partnerClaimsCountByStatusReq.partnerId}/claims/count-by-status/${queryString}`,
  });
};
