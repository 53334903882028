import { useHasStorageLocations } from "client/hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { Link } from "client/ui-components";
import React from "react";
import styled from "styled-components";
import { useReturnsStore } from "../returnStore";

export default function ToggleBulkOptions({ style }: { style?: React.CSSProperties }) {
  const { setHasBulkOptions, hasBulkOptions } = useReturnsStore();
  const hasStorageLocations = useHasStorageLocations();

  if (!hasStorageLocations) return null;

  return (
    <StyledLink
      isButton
      onClick={() => setHasBulkOptions(!hasBulkOptions)}
      dataTestId="bulkOptionsToggle"
      variant={hasBulkOptions ? "default" : "gray"}
      isSelected={hasBulkOptions}
      style={style}
    >
      {hasBulkOptions ? "Hide bulk options" : "Show bulk options"}
    </StyledLink>
  );
}

const StyledLink = styled(Link)<{ isSelected: boolean }>`
  & > * {
    color: ${props =>
      props.isSelected ? props.theme.ui.colors.primary500 : props.theme.ui.colors.gray600};
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    & > * {
      color: ${props =>
        props.isSelected ? props.theme.ui.colors.primary600 : props.theme.ui.colors.primary500};
      opacity: 1;
    }
    color: ${props => props.theme.ui.colors.primary700};
    opacity: 1;
  }
`;
