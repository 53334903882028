import styled from "styled-components";
import { colors } from "../../theme/uiTheme";
import Typography from "../Typography";

// An example a more specified component using Text. This removes the the `heading` prop.
// If we want to have a paragraph specific component we can use this use this same format.
// Make Paragraph DIR with index.tsx an remove the `as` and have it default to "p"
const Text = styled(Typography)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      ![
        "color",
        "display",
        "fontSize",
        "fontWeight",
        "lineHeight",
        "preLine",
        "textAlign",
      ].includes(prop) && defaultValidatorFn(prop),
  })
  .attrs(
    (props: {
      as?: JSX.IntrinsicElements; // Note: `as` is to change the element <tag>
      className: string;
      color?: string;
      display?: boolean;
      fontSize?: string | { [key: string]: string };
      fontWeight?: number;
      lineHeight?: number | { [key: string]: number };
      preLine?: boolean;
      textAlign?: string;
    }) => ({
      as: props.as || "div", // not a span as it is a block element that needs margin classes to work most the time.
      color: props.color || colors.primary700,
      heading: false,
      fontWeight: props.fontWeight || 400,
    }),
  )``;

export default Text;
