import { ReturnObject } from "client/pages/PartnerGlobalSearch/components/ResultsSection";
import { formattedInventoryId } from "client/utils/stringUtils";
import { getPartnerClaimFromId } from "../../../../actions/partnerClaims";
import { getPartnerItemFromId } from "../../../../actions/partnerItems";
import { getMatchFromId } from "../../../../actions/partnerMatches";
import { getReturns, getReturnsCountByModeFromPartnerId } from "../../../../actions/partnerReturns";
import { getPartnerShipmentById } from "../../../../actions/partnerShipments";
import {
  Match,
  PartnerClaim,
  PartnerItem,
  PartnerReturn,
  PartnerReturnFilters,
  PartnerReturnsCountByModeResponse,
  PartnerReturnWithDetails,
  PartnerShipment,
} from "../../../../types";

export type CustomReturn = {
  return: PartnerReturn | null;
  match: Match | null;
  item: PartnerItem | null;
  shipment: PartnerShipment | null;
  claim: PartnerClaim | null;
};

export async function populateItems({
  partnerId,
  filters,
}: {
  partnerId: string;
  filters: PartnerReturnFilters;
}): Promise<[Array<CustomReturn>, PartnerReturnsCountByModeResponse] | null> {
  const returnsPromise = getReturns({
    partnerId,
    filters,
  });

  const countsPromise = getReturnsCountByModeFromPartnerId({
    partnerId,
    filters: {
      ...(filters.status ? { status: filters.status } : {}),
      ...(filters.lost_location ? { lost_locations: filters.lost_location } : {}),
    },
  });

  const [returns, counts] = await Promise.all([returnsPromise, countsPromise]);

  if (returns === null || counts === null) {
    return null;
  }

  const allThePromises = returns.results.map(returnItem => {
    return Promise.all([
      returnItem.shipment === null
        ? null
        : getPartnerShipmentById({
            partnerId,
            shipmentId: returnItem.shipment,
          }),
      getMatchFromId({
        partnerId,
        matchId: returnItem.match,
      }),
    ]).then(([shipment, match]) => {
      if (match === null) {
        return {
          shipment,
          return: returnItem,
          match: null as CustomReturn["match"],
          item: null as CustomReturn["item"],
          claim: null as CustomReturn["claim"],
        };
      }

      return Promise.all([
        getPartnerItemFromId({
          partnerId,
          itemId: match.item,
        }),
        getPartnerClaimFromId({
          partnerId,
          claimId: match.claim,
        }),
      ]).then(([item, claim]) => {
        return { shipment, return: returnItem, match, item, claim };
      });
    });
  });

  const hydratedItems = await Promise.all(allThePromises);

  return [hydratedItems, counts];
}

export function createReturnObject({ returnItem }: { returnItem: CustomReturn }): ReturnObject {
  return {
    ...returnItem.return,
    match: "",
    status: returnItem.return?.status ?? "-",
    id: returnItem.return?.id ?? "",
    shipment: returnItem.shipment!,
    mode: returnItem.return?.mode,
  };
}
// This function converts the new detailed returns to the CustomReturns format
export function formatDetailedReturns(
  returns: Array<PartnerReturnWithDetails>,
): Array<CustomReturn> {
  return returns.map(returnItem => {
    const itemInventoryId = formattedInventoryId(returnItem.match.item.id);
    const formattedReturn: PartnerReturn = {
      id: returnItem.id,
      mode: returnItem.mode,
      status: returnItem.status,
      match: returnItem.match.id,
      shipment: returnItem?.shipment?.id ?? "",
      created_at: returnItem.created_at,
      modified_at: returnItem.modified_at,
      returned_at: returnItem.returned_at,
      returned_by: returnItem.returned_by?.email,
    };
    const formattedMatch: Match = {
      ...returnItem.match,
      item: returnItem.match.item.id,
      claim: returnItem.match.claim.id,
    };
    return {
      return: formattedReturn,
      match: formattedMatch,
      item: { ...returnItem.match.item, inventoryId: itemInventoryId },
      shipment: returnItem.shipment,
      claim: returnItem.match.claim,
    } as CustomReturn;
  });
}
