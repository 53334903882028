import React, { useContext, useEffect, useRef } from "react";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { isLgQuery, isXxxlQuery } from "client/helpers/mediaQuery";
import Search from "../..";

export default function GlobalSearch() {
  const { isSearchModeOn, searchTerm, restoreGlobalSearchBar, setRestoreGlobalSearchBar } =
    useContext(PartnerGlobalSearchContext);
  const searchRef = useRef<HTMLInputElement>(null);
  const isLg = isLgQuery();
  const isXxxl = isXxxlQuery();

  useEffect(() => setRestoreGlobalSearchBar(false), []);

  useEffect(() => {
    if (searchRef.current && !isSearchModeOn && searchTerm === "" && restoreGlobalSearchBar) {
      searchRef.current.style.transform = "translateY(0)";
      searchRef.current.style.opacity = "1";
      searchRef.current.style.display = "flex";
      return;
    }
    if (searchRef.current && isSearchModeOn) {
      searchRef.current.style.transform = isXxxl
        ? "translate(-1500px, 158px)"
        : isLg
          ? "translate(-300px, 158px)"
          : "translateY(158px)";
      searchRef.current.style.opacity = "0";

      const timeout = setTimeout(() => {
        searchRef.current!.style.display = "none";
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [isSearchModeOn, searchTerm, restoreGlobalSearchBar]);

  return <Search ref={searchRef} />;
}
