import { DefaultTheme } from "styled-components";
import uiTheme from "./uiTheme";

// https://styled-components.com/docs/api#create-a-declarations-file
declare module "styled-components" {
  // Declare the shape of the theme here.
  export interface DefaultTheme {
    ui: typeof uiTheme;
    borderRadius: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      rounded: string;
    };
    breakpoints: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      navFits: string;
      xl: string;
      xxl: string;
      xxxl: string;
      xsHeight: string;
      smHeight: string;
    };
    colors: {
      white: string;
      transparent: string;
      border: string;
      disabled: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray6: string;
      gray8: string;
      gray9: string;
      gray10: string;
      formControlBorder: string;
      black: string;
      lightBlue: string;
      primaryBlue: string;
      primaryBlueDark: string;
      deltaBlue: string;
      deltaBlueSecondary: string;
      red: string;
      lightRed: string;
      yellow: string;
      lightOrange: string;
      lightSuccessGreen: string;
      successGreen: string;
      darkSuccessGreen: string;
      lightGreen: string;
      gold: string;
    };
    chartColors: {
      palette: string[];
      other: string;
    };
    fontSizes: {
      xs: string;
      sm: string;
      default: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      xxxl: string;
      big: string;
      bigger: string;
      biggest: string;
    };
    fontFamilies: {
      display: string;
      paragraph: string;
      heading: string;
    };
    fontWeights: {
      light: number;
      default: number;
      button: number;
      semiBold: number;
      bold: number;
    };
    heights: {
      button: string;
      input: string;
      li: string;
      mobileMenuHeader: string;
      textarea: string;
      textareaLg: string;
      uploadThumb: string;
      mobileDefaultHeader: string;
      uploadThumbLg: string;
      singleViewHeader: string;
      singleViewHeaderLg: string;
      desktopHeader: string;
      mobileLocationDropdownHeader: string;
      mobileSearchModeOnHeader: string;
      checkboxHeight: string;
    };
    shadows: {
      md: string;
      lg: string;
    };
    toast: {
      width: string;
    };
    widths: {
      claimMax: string;
      dropdownButton: string;
      portalMax: string;
      iconLogo: string;
      logoMain: string;
      logoWhite: string;
    };
    zIndexes: {
      zero: number;
      one: number;
      tabs: number;
      footer: number;
      header: number;
      modal: number;
    };
  }
}

const ui = uiTheme;

const breakpoints = {
  xs: "372px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  navFits: "1360px",
  xl: "1200px",
  xxl: "1400px",
  xxxl: "1650px",
  xsHeight: "564px",
  smHeight: "680px",
};
const borderRadius = {
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "16px",
  rounded: "50px",
};
const colors = {
  white: "#fff",
  transparent: "transparent",
  border: "#ccc",
  disabled: "#E0E0E0",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#757575",
  gray4: "#BDBDBD",
  gray6: "#F2F2F2",
  gray8: "#E0E0E0",
  gray9: "#cccccc",
  gray10: "#FAFAFA",
  formControlBorder: "#ced4da",
  black: "#000",
  lightBlue: "#D1E7FF",
  primaryBlue: "#0772E4",
  primaryBlueDark: "#0b5ed7",
  deltaBlue: "#12172A",
  deltaBlueSecondary: "#414555",
  red: "#DA1629",
  lightRed: "#FFD0D0",
  yellow: "#ECB205",
  lightOrange: "#FFF4D4",
  lightSuccessGreen: "#259327",
  successGreen: "#008000",
  darkSuccessGreen: "#006d01",
  lightGreen: "#E2F9E0",
  gold: "#B38600",
};
const chartColors = {
  palette: ["#56CCf2", "#F2C94C", "#DA1629", "#ECB205", "#6FCF97", "#BB6BD9"],
  other: "#E0E0E0",
};
const fontSizes = {
  xs: ".75rem", // 12px
  sm: ".875rem", // 14px
  default: "1rem", // 16px
  md: "1.125rem", // 18px
  lg: "1.25rem", // 20px
  xl: "1.5rem", // 24px
  xxl: "2.125rem", // 34px
  xxxl: "2.5rem", // 40px
  big: "3rem", // 48px
  bigger: "3.25rem", // 52px
  biggest: "3.75rem", // 60px
};
const fontWeights = {
  light: 400,
  default: 500,
  button: 600,
  semiBold: 600,
  bold: 700,
};
const fontFamilies = {
  display: "Lato",
  paragraph: "Lato",
  heading: "Poppins",
};
const heights = {
  button: "58px",
  input: "58px",
  li: "64px",
  mobileMenuHeader: "100px",
  textarea: "130px",
  textareaLg: "136px",
  uploadThumb: "142px",
  mobileDefaultHeader: "177px",
  uploadThumbLg: "190px",
  singleViewHeader: "177px",
  singleViewHeaderLg: "124px",
  desktopHeader: "207px",
  mobileLocationDropdownHeader: "227px",
  mobileSearchModeOnHeader: "290px",
  checkboxHeight: "2rem",
};
const shadows = {
  md: "0px 2px 10px rgba(0, 0, 0, 0.1);",
  lg: "0px 5px 20px rgba(0, 0, 0, 0.15);",
};
const toast = {
  width: "320px",
};
const widths = {
  claimMax: "800px",
  dropdownButton: "280px",
  portalMax: "1072px",
  iconLogo: "59px",
  logoMain: "170px",
  logoWhite: "320px",
};

const zIndexes = {
  zero: 0,
  one: 1,
  tabs: 5,
  footer: 10,
  header: 99,
  modal: 100,
};

const theme: DefaultTheme = {
  ui,
  borderRadius,
  breakpoints,
  colors,
  chartColors,
  fontSizes,
  fontWeights,
  fontFamilies,
  heights,
  shadows,
  toast,
  widths,
  zIndexes,
} as const;

export default theme;
