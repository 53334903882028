import React from "react";
import { ItemStatus, Match, PartnerItem } from "../../../../types";
import ActionsToolbar from "../ActionsToolbar";
import { Wrapper } from "./styles";

type FooterProps = { item: PartnerItem; matches: Match[] };

export default function Footer({ item, matches }: FooterProps) {
  if (item.status === ItemStatus.COMPLETED || item.status === ItemStatus.UNCLAIMED) {
    return null;
  }
  return (
    <Wrapper className="no-print">
      <ActionsToolbar item={item} isHeader={false} matches={matches} />
    </Wrapper>
  );
}
