import React, { useContext } from "react";

import { StyledSearchResultMagnifyingGlass, Wrapper } from "./styles";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { isXsQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Typography } from "../../../../ui-components";

export default function NoResultsFound() {
  const { searchTerm } = useContext(PartnerGlobalSearchContext);
  const isSm = isXsQuery();

  return (
    <Wrapper>
      <StyledSearchResultMagnifyingGlass
        accessibilityTitle="SearchResultsMagnifyingGlassIcon"
        titleId="SearchResultsMagnifyingGlassIcon"
        className="mb-3"
      />

      <Typography
        heading
        as="h3"
        fontWeight={theme.fontWeights.bold}
        fontSize={isSm ? "32px" : "24px"}
        lineHeight={isSm ? 1.25 : 1.35}
        color={theme.ui.colors.primary700}
        textAlign="center"
      >
        No results found
      </Typography>
      <Typography
        as="p"
        fontWeight={theme.fontWeights.light}
        fontSize="16px"
        color={theme.ui.colors.primary700}
        lineHeight={1.5}
        textAlign="center"
      >
        Your search for "{searchTerm}" didn't yield any results. Don't hesitate to experiment with
        different keywords to broaden your search.
      </Typography>
    </Wrapper>
  );
}
