import { useState } from "react";
import Errors from "../utils/errors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiHandlerError = boolean | string | Record<string, any> | null;

export type ApiHandlerState<T> = {
  error: ApiHandlerError;
  data: T | null;
  loading: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiHandlerCall<T, K> = (data?: T, ...args: any) => Promise<K>;
export type ApiHandlerRes<T, K> = [ApiHandlerCall<T, void>, ApiHandlerState<K>, () => void];

export type MultiApiHandlerState<T> = {
  error: ApiHandlerError;
  data: T[] | null;
  loading: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MultiApiHandlerCall<T, K> = (data?: T[], ...args: any) => Promise<K>;
export type MultiApiHandlerRes<T, K> = [
  MultiApiHandlerCall<T, void>,
  MultiApiHandlerState<K>,
  () => void,
];

export const useApiHandler = <T, K>(apiCall: ApiHandlerCall<T, K>): ApiHandlerRes<T, K> => {
  const [reqState, setReqState] = useState<ApiHandlerState<K>>({
    error: null,
    data: null,
    loading: false,
  });
  const reset = (): void => setReqState({ error: null, data: null, loading: false });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handler = async (inputData: T, ...args: any) => {
    setReqState({ error: null, data: null, loading: true });
    try {
      const resJson = await apiCall(inputData, ...args);
      setReqState({ error: null, data: resJson, loading: false });
    } catch (e) {
      Errors.captureException(e, inputData);
      setReqState({
        error: e.message,
        data: null,
        loading: false,
      });
    }
  };

  return [handler, { ...reqState }, reset];
};

export const useMultiApiHandler = <T, K>(
  apiCall: ApiHandlerCall<T, K>,
): MultiApiHandlerRes<T, K> => {
  const [reqState, setReqState] = useState<MultiApiHandlerState<K>>({
    error: null,
    data: null,
    loading: false,
  });
  const reset = (): void => setReqState({ error: null, data: null, loading: false });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handler = async (inputData: T[], ...args: any) => {
    setReqState({ error: null, data: null, loading: true });
    return Promise.all(inputData.map(item => apiCall(item, ...args)))
      .then(dataList => setReqState({ error: null, data: dataList, loading: false }))
      .catch(e => {
        Errors.captureException(e, inputData);
        setReqState({ error: e.message as string, data: null, loading: false });
      });
  };

  return [handler, { ...reqState }, reset];
};
