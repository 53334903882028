import classNames from "classnames";
import React from "react";
import theme from "../../../theme";
import Text from "../../Text";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  description?: string;
  direction?: "horizontal" | "vertical";
};

export default function FormLabel({
  className,
  children,
  description,
  direction = "horizontal",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={classNames(
        "d-flex",
        "mb-1",
        direction === "horizontal" ? "flex-row" : "flex-column",
        direction === "horizontal" && "justify-content-between",
        direction === "horizontal" && "flex-wrap",
        direction === "horizontal" && "gap-2",
        direction === "horizontal" && "align-items-center",
        className,
      )}
    >
      <Text
        as="label"
        color={theme.ui.colors.primary700}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight="1.5"
        textAlign="inherit"
      >
        {children}
      </Text>

      {description && (
        <Text as="span" color={theme.ui.colors.gray600} fontSize={theme.ui.fontSizes.sm}>
          {description}
        </Text>
      )}
    </div>
  );
}
