import classNames from "classnames";
import React from "react";

import { StyledSection } from "./styles";

export enum ContainerBgColors {
  BLUE = "blue",
  TRANSPARENT = "transparent",
  DARKBLUE = "darkBlue",
}

type SectionContainerProps = React.PropsWithoutRef<JSX.IntrinsicElements["section"]> & {
  as?: React.ElementType;
  bgColor?: ContainerBgColors;
  isFullWidth?: boolean;
  noPaddingY?: boolean;
};

const SectionContainer: React.FC<SectionContainerProps> = ({
  as,
  bgColor = "transparent",
  children,
  className,
  isFullWidth = false,
  noPaddingY = false,
  ...props
}) => {
  return (
    <StyledSection
      {...props}
      as={as}
      fullWidth={isFullWidth}
      bgColor={bgColor}
      className={classNames(className, {
        "px-35 px-md-45 d-flex flex-column align-items-center": !isFullWidth,
        "mt-6 mt-lg-8": !noPaddingY,
        "py-6 py-lg-8": !noPaddingY && bgColor !== ContainerBgColors.TRANSPARENT,
      })}
    >
      <div>{children}</div>
    </StyledSection>
  );
};

export default SectionContainer;
