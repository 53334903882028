import { useForm } from "react-hook-form";

import { Image } from "../../../typings/endpoints/image";
import { Category, PartnerStorageLocation } from "../../types";

export type ArtifactDetails = {
  foundDate: Date;
  itemCategoryIdValue: string | Category;
  storageLocationIdValue?: string | PartnerStorageLocation | null;
  detailedDescription: string;
  itemName: string;
  images: Image[];
};

export const useArtifactDetailsForm = () => {
  return useForm<ArtifactDetails>({
    criteriaMode: "all",
    mode: "onSubmit",
    defaultValues: {
      detailedDescription: "",
      images: [],
      itemCategoryIdValue: "",
      storageLocationIdValue: "",
      foundDate: new Date(),
      itemName: "",
    },
  });
};
