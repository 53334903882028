import React from "react";
import ItemSnapshot from "../../../../../components/ItemSnapshot";
import { Category, PartnerItem } from "../../../../../types";

type Props = {
  category: Category["name"];
  item: PartnerItem | null;
  index: number;
};

export default function ItemDetailsCell({ category, item, index }: Props) {
  return (
    <ItemSnapshot
      categoryName={category}
      images={item?.images}
      snapshotKey={index}
      name={item?.name ?? ""}
      label={item?.inventoryId ? `ID: ${item?.inventoryId}` : ""}
      $showSingleImage
      maxHeight={100}
    />
  );
}
