import React from "react";
import theme from "../../../../theme";
import { Text } from "../../../../ui-components";
import { DescriptionContainer, TextDisplayContainer } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  title: string;
  content?: string;
  isLink?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  normal?: boolean;
};

export default function TextDisplay({ title, content, children, normal, ...props }: Props) {
  return (
    <TextDisplayContainer {...props}>
      <Text as="p" color={theme.ui.colors.gray700} fontWeight={400} className="m-0">
        {title}
      </Text>

      {content ? (
        <Text as="p" className="m-0" fontWeight={normal ? 400 : 700}>
          {content}
        </Text>
      ) : null}
      <DescriptionContainer>{children}</DescriptionContainer>
    </TextDisplayContainer>
  );
}
