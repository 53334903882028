import styled from "styled-components";
import cityBanner from "../../assets/ui-icons/CityBanner/city-banner.svg";

export const ContainerWrapper = styled.div`
  width: 100%;
  padding-inline: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: 40px;
  }

  &.city-background {
    padding-bottom: 90px;
    background-image: url(${cityBanner});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 90px;

    transition: all 0.2ms ease-in;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-bottom: 185px;
      background-size: auto 185px;
    }
  }
`;
