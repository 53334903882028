import classNames from "classnames";
import React, { forwardRef } from "react";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["input"]> & {
  wrapperProps?: React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  label?: string;
};

function Radio({ wrapperProps, id, label, ...props }: Props, ref: React.Ref<HTMLInputElement>) {
  return (
    <div {...wrapperProps} className={classNames("form-check", wrapperProps?.className)}>
      <input {...props} className="form-check-input" type="radio" id={id} ref={ref} />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default forwardRef<HTMLInputElement, Props>(Radio);
