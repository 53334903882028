import styled from "styled-components";
import { ClaimStatusStrings } from "../../../types";
import { ReturnStatus } from "../../../types/models";
import {
  PartnerReturnPickupStatusStringEnum,
  PartnerReturnShippingStatusStringEnum,
  ReturnStatusStrings,
} from "../../../types/theme";
import { getBadgeCss } from "../../../utils/badgeStyle";
import { BadgeVariantsEnum } from "../../Badge";

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const StatusHeader = styled.div<{
  status:
    | ReturnStatusStrings
    | ClaimStatusStrings
    | PartnerReturnPickupStatusStringEnum
    | PartnerReturnShippingStatusStringEnum
    | ReturnStatus
    | undefined;
}>`
  width: 100%;
  height: 48px;
  border-top-left-radius: ${props => props.theme.borderRadius.lg};
  border-top-right-radius: ${props => props.theme.borderRadius.lg};
  display: flex;
  justify-content: space-between;
  ${props => getBadgeCss(props.status, BadgeVariantsEnum.Contained, true)}
`;

export const StatusTextWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const LostItemDiv = styled.div`
  .description {
    &.full-width {
      width: 100%;
    }
  }

  h2 {
    padding-bottom: 8px !important;
  }
`;

export const FoundItemDiv = styled.div`
  h2 {
    padding-bottom: 8px !important;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    button {
      width: 100% !important;
    }

    flex-direction: row;
  }
`;
