import classNames from "classnames";
import React from "react";
import { ContainerWrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  withCityView?: boolean;
};
export default function Container({ children, className, withCityView = false, ...props }: Props) {
  return (
    <ContainerWrapper
      {...props}
      className={classNames(withCityView && "city-background", className)}
    >
      {children}
    </ContainerWrapper>
  );
}
