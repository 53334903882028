import "react-phone-number-input/style.css";
import styled from "styled-components";

export const AddButtonWrapper = styled.div`
  display: flex;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 221px;
    position: relative;
    top: 40px;
    display: block;
  }
`;

export const ChevronWrapper = styled.div`
  color: ${props => props.theme.colors.gray3};
  display: flex;
  align-items: center;
`;

export const ExpirationMessage = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fontFamilies.display};
  font-weight: 500;
  line-height: 1.71;
`;

export const InfoWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  line-height: 1.25;
`;

export const PillWrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius.lg};
  background-color: ${props => props.theme.colors.white};
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};
  &.move-up {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      position: relative;
      top: -24px;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 100%;
  }
`;
