import styled from "styled-components";
import ContactDetailsSnapshot from "./ContactDetailsSnapshot/index";
import { NoPhotoImagePlaceholder } from "./ImageCarousel";

export const NormalSubtext = styled.div`
  font-size: ${props => props.theme.fontSizes.default};
  line-height: 1.25;
  max-width: 350px;
  text-align: center;
`;

export const StyledImage = styled.div`
  margin: auto;
  border: none;
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid ${props => props.theme.colors.border};
  height: 424px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: ${props => props.theme.colors.white};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.theme.zIndexes.footer};
`;
export const StyledRightPanel = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 98px !important;
  }
`;
export const StyledNoPhotoImagePlaceholder = styled(NoPhotoImagePlaceholder)<{
  className?: string;
  square?: boolean;
  width?: string;
}>`
  background-position: right;
  max-width: none;
  height: 424px;
`;
export const StyledContactDetailsSnapshot = styled(ContactDetailsSnapshot)`
  .contact-details-snapshot-email,
  .contact-details-snapshot-phone {
    font-size: ${props => props.theme.fontSizes.default};
  }
`;
