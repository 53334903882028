import { useAuthState } from "client/context";
import { PartnerItem } from "client/types";
import { fullDateTime, fullDateWithDay } from "client/utils/dateUtils";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PlusSignBig, StyledCheckSolidIcon } from "../../../../../assets/icons/icons";
import Button from "../../../../../components/Button";
import { ButtonModes, ButtonTextColors } from "../../../../../components/Button/Button.types";
import { ModalButtonTextEnum } from "../../../../../components/Modal/Modal.types";
import { TopBackgroundAccent } from "../../../../../components/SimpleHeader/styles";
import { Step } from "../../../../../copy/artifactFormCopy";
import theme from "../../../../../theme";
import { mountBodyGray6, unmountBodyGray6 } from "../../../../../utils/bodyStyle";
import { formattedInventoryId } from "../../../../../utils/stringUtils";
import { ItemSteps } from "../../types";
import {
  BoldWord,
  CenterWrapper,
  InternalWrapper,
  LargeBackground,
  OuterWrapper,
  SmallBackground,
  StyledButtonWrapper,
  StyledDescription,
  StyledImage,
  StyledTitle,
  TopHalf,
} from "./styled";

interface PageBackgroundProps {
  isLarge: boolean;
}

const PageBackground: React.FC<React.PropsWithChildren<PageBackgroundProps>> = props => {
  return (
    <>
      {props.isLarge ? (
        <>
          <TopBackgroundAccent />
          <LargeBackground>{props.children}</LargeBackground>
        </>
      ) : (
        <SmallBackground>{props.children}</SmallBackground>
      )}
    </>
  );
};

interface SubmittedPageProps {
  isLarge: boolean;
  imageUrl?: string;
  selectedLocation?: string;
  stepCopy: Step;
  isEditingItem: boolean;
  itemId: string;
  editAgain: () => void;
  itemName: string;
  item?: PartnerItem;
  foundDate?: Date;
}

export const SubmittedPage: React.FC<SubmittedPageProps> = props => {
  const history = useHistory();
  useEffect(() => {
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    // cleanup once this page is left
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);
  const { user } = useAuthState();

  const addItemDescription = (
    <>
      <StyledDescription className="no-print">
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          {props.itemName}
        </BoldWord>{" "}
        has been logged and is now being checked across all existing claims for{" "}
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          {props.selectedLocation}
        </BoldWord>
        .
      </StyledDescription>
      <br />
      <div>
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          Item ID:
        </BoldWord>{" "}
        {formattedInventoryId(props.itemId)}
      </div>
      <div>
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          Item Name:
        </BoldWord>{" "}
        {props.itemName}
      </div>
      <div>
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          Collected on:
        </BoldWord>{" "}
        {fullDateWithDay(props.foundDate ?? new Date())}
      </div>
      <div>
        <BoldWord as="span" color={theme.ui.colors.primary700}>
          Logged By:
        </BoldWord>{" "}
        {user?.full_name}
      </div>
      <p>
        <BoldWord as="span" color={theme.ui.colors.primary700} style={{ fontSize: "0.8rem" }}>
          Logged at:
        </BoldWord>{" "}
        <span
          style={{
            color: theme.ui.colors.primary700,
            fontSize: "0.8rem",
            fontWeight: 500,
            lineHeight: "1.75",
          }}
        >
          {fullDateTime(new Date())}
        </span>
      </p>
      <a
        onClick={() => {
          window.print();
        }}
        className="no-print"
        style={{
          color: theme.ui.colors.primary700,
          fontWeight: 500,
          lineHeight: "1.75",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        Print item details
      </a>
    </>
  );

  const editItemDescription = (
    <StyledDescription>You've successfully updated item details!</StyledDescription>
  );

  const topButton = props.isEditingItem ? (
    <Button
      ariaLabel="See item details"
      text="See item details"
      mode={ButtonModes.primary}
      className="w-100 justify-content-center align-items-center border-primary mx-auto no-print"
      onClick={() => {
        history.push(`/partner/item/${props.itemId}`);
      }}
    />
  ) : (
    <Button
      ariaLabel="Add another item"
      icon={
        <PlusSignBig
          className="me-2"
          accessibilityTitle="Add another item"
          titleId="AddAnotherItemTitle"
        />
      }
      data-testid="addAnotherItemButton"
      text="Add another item"
      mode={ButtonModes.primary}
      className="w-100 justify-content-center align-items-center border-primary mx-auto no-print"
      onClick={() => {
        history.go(0);
      }}
    />
  );

  const bottomButton = props.isEditingItem ? (
    <Button
      ariaLabel="Edit details"
      text="Go to Inventory"
      mode={ButtonModes.open}
      textColor={ButtonTextColors.gray3}
      className="w-100 justify-content-center align-items-center mx-auto no-print"
      onClick={() => {
        history.push("/partner/inventory");
      }}
    />
  ) : (
    <Button
      ariaLabel={ModalButtonTextEnum.GO_BACK_HOME}
      data-testid="goBackHomeButton"
      text={ModalButtonTextEnum.GO_BACK_HOME}
      mode={ButtonModes.open}
      textColor={ButtonTextColors.gray3}
      className="w-100 justify-content-center align-items-center mx-auto no-print"
      onClick={() => {
        history.push("/");
      }}
    />
  );

  return (
    <PageBackground isLarge={props.isLarge}>
      <OuterWrapper className="px-45 px-lg-0">
        <InternalWrapper>
          <TopHalf>
            {props.imageUrl && (
              <StyledImage className="border-0 no-print" imageUrl={props.imageUrl}>
                <span role="img" aria-label="Image of the item you submitted" />
              </StyledImage>
            )}
            <CenterWrapper>
              <StyledCheckSolidIcon
                accessibilityTitle="Success checkmark"
                titleId="AddItemSuccessCheckmarkTitle"
                className="no-print"
              />
              <StyledTitle className="no-print">
                {props.stepCopy[ItemSteps.SUBMITTED].title!}
              </StyledTitle>
              {props.isEditingItem ? editItemDescription : addItemDescription}
            </CenterWrapper>
          </TopHalf>
          <StyledButtonWrapper>
            {topButton}
            {bottomButton}
          </StyledButtonWrapper>
        </InternalWrapper>
      </OuterWrapper>
    </PageBackground>
  );
};

export default SubmittedPage;
