import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import DefaultError from "client/ui-components/DefaultError";
import React, { useEffect } from "react";
import { useGetPartnerClaims } from "../../../../../actions/partnerClaims";
import { MatchingIcon, NoMatchesIcon } from "../../../../../assets/icons/icons";
import Button from "../../../../../components/Button";
import { ButtonModes, ButtonTypes } from "../../../../../components/Button/Button.types";
import MatchesCard from "../../../../../components/Cards/MatchesCard";
import ItemSnapshot from "../../../../../components/ItemSnapshot";
import Loader from "../../../../../components/Loader";
import Table from "../../../../../components/Table";
import TableBlankState from "../../../../../components/Table/TableBlankState";
import { MatchesColumns } from "../../../../../components/Table/TableColumns";
import history from "../../../../../context/history";
import { usePartnerUserDispatch, usePartnerUserState } from "../../../../../context/PartnerUser";
import { updateCurrentClaim } from "../../../../../context/PartnerUser/actions";
import { isLgQuery } from "../../../../../helpers/mediaQuery";
import theme from "../../../../../theme/index";
import { ClaimStatus, PartnerClaim } from "../../../../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../../../../utils/bodyStyle";
import { getCategoryName, getLocationName } from "../../../../../utils/mappers";
import { FilteredClaim, MatchesProps } from "../../../Matches";
import { CardsContainer, MessageWrapper, Wrapper } from "./styles";

export default function OldMatches(props: MatchesProps) {
  const { count, limit, partnerId, title, className } = props;

  const partnerUserDispatch = usePartnerUserDispatch();
  const { partnerUserStateLoading } = usePartnerUserState();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const categories = useCategories();

  const isLg = isLgQuery();

  const [getClaims, { data: claims, loading: claimsLoading, error: claimsError }] =
    useGetPartnerClaims();

  useEffect(() => {
    getClaims({
      partnerId: partnerId,
      filters: {
        ...(limit && { limit: limit }),
        status: [ClaimStatus.HAS_MATCHES],
        lost_location: partnerUserLocation.id,
      },
    });
  }, [partnerId, partnerUserLocation.id]);

  useEffect(() => {
    if (!claims) {
      getClaims({
        partnerId: partnerId,
        filters: {
          ...(limit && { limit: limit }),
          status: [ClaimStatus.HAS_MATCHES],
          lost_location: partnerUserLocation.id,
        },
      });
    }
  }, []);

  useEffect(() => {
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  if (!limit && partnerUserStateLoading) {
    return <Loader />;
  }

  if (claimsError) {
    return <DefaultError />;
  }

  if (claims && claims?.count < 1 && !claimsLoading) {
    return (
      <TableBlankState
        columns={MatchesColumns}
        icon={
          <NoMatchesIcon
            className="w-100"
            accessibilityTitle="No potential matches"
            ariaHidden={true}
            titleId="TableBlankStateNoMatchesTitle"
          />
        }
        loading={claimsLoading}
        message="You have no potential matches"
        title="Potential matches"
      />
    );
  }

  const filteredClaims: FilteredClaim[] = (claims?.results || []).map(claim => ({
    ...claim,
    status: claim.status,
    category:
      categories.data && claim.category ? getCategoryName(categories.data, claim.category) : "",
    lostLocation:
      partnerLocations && claim.lost_location
        ? getLocationName(partnerLocations, claim.lost_location)
        : "",
  }));

  const onClaimClick = (claim: PartnerClaim) => {
    updateCurrentClaim(partnerUserDispatch, claim);
    history.push(`/partner/matches/matching/?claimId=${claim.id}`);
  };

  const rows = (filteredClaims ?? []).map((claim, i) => {
    return [
      <ItemSnapshot
        key={i}
        className={`w-100`}
        categoryName={claim.category}
        additionalMessage={
          <MessageWrapper className="p-0">
            <MatchingIcon
              color1={theme.colors.lightBlue}
              color2={theme.colors.primaryBlue}
              className={"me-1"}
              accessibilityTitle="Matches found!"
              ariaHidden={true}
              titleId="MatchesFoundTableIconTitle"
            />
            Matches found!
          </MessageWrapper>
        }
        {...claim}
        snapshotKey={i}
        label="Claim"
      />,
      <Button
        ariaLabel="Review matches"
        key={i}
        mode={ButtonModes.primary}
        type={ButtonTypes.submit}
        text={"Review matches"}
        disabled={false}
        onClick={() => onClaimClick(claim)}
      />,
    ];
  });

  return isLg && history.location.pathname.includes("/partner/overview") ? (
    <Wrapper className={`${className ?? `mb-5 px-0 ${isLg ? "pt-35" : ""}`}`}>
      {isLg && !claimsLoading ? (
        <Table
          columns={MatchesColumns}
          className={limit ? "pb-25" : ""}
          flex
          justifyEnd
          rows={rows || []}
          seeAllLink={limit !== undefined ? `/partner/matches` : undefined}
          tableLoading={claimsLoading}
          title={title}
          totalCount={count}
        />
      ) : claimsLoading ? (
        <Loader />
      ) : (
        filteredClaims &&
        filteredClaims.length > 0 &&
        filteredClaims.map((claim, i) => {
          return (
            <MatchesCard
              claim={claim}
              categoryName={categories.data ? claim.category : ""} // TODO: find out why we are checking categories here
              cardKey={i}
              onClick={() => onClaimClick(claim)}
            />
          );
        })
      )}
    </Wrapper>
  ) : (
    <CardsContainer className="mx-25 mt-35">
      {isLg && history.location.pathname.includes("/partner/overview") && !claimsLoading ? (
        <Table
          columns={MatchesColumns}
          className={limit ? "pb-25" : ""}
          flex
          justifyEnd
          rows={rows || []}
          seeAllLink={limit !== undefined ? `/partner/matches` : undefined}
          tableLoading={claimsLoading}
          title={title}
          totalCount={count}
        />
      ) : claimsLoading ? (
        <Loader />
      ) : (
        filteredClaims &&
        filteredClaims.length > 0 &&
        filteredClaims.map((claim, i) => {
          return (
            <MatchesCard
              claim={claim}
              categoryName={categories.data ? claim.category : ""} // TODO: check logic
              cardKey={i}
              onClick={() => onClaimClick(claim)}
              text={`Review match${claim.potentialMatches!.length > 1 ? "es" : ""}`}
            />
          );
        })
      )}
    </CardsContainer>
  );
}
