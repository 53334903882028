import { useQuery } from "@tanstack/react-query";
import queries from "client/hooks/data/user/queryFactory";
import React, { createContext, useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Location, Partner } from "../../types";

type SubLocationSelectionContextType = {
  subLocations: Location[];
  isLoading: boolean;
  partner: Partner | null;
  partnerSlug: string;
};

type URLParams = {
  urlPartnerSlug: string;
};

export const SubLocationSelectionContext = createContext<SubLocationSelectionContextType>({
  subLocations: [],
  isLoading: false,
  partner: null,
  partnerSlug: "",
});

export default function SubLocationSelectionProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const isPartnerRequestSent = useRef<boolean>(false);
  const { urlPartnerSlug } = useParams<URLParams>();
  const { data: partner, isLoading: isPartnerLoading } = useQuery(
    queries.partnerBySlug(urlPartnerSlug),
  );
  const { data: partnerLocations, isLoading: partnerLocationsLoading } = useQuery(
    queries.locationsForPartner(partner?.id || ""),
  );

  useEffect(() => {
    if (partner === null && !isPartnerLoading && isPartnerRequestSent.current) {
      history.push("/lost");
    }
  }, [isPartnerLoading, partner, isPartnerRequestSent, history]);

  const values = useMemo<SubLocationSelectionContextType>(
    () => ({
      subLocations: partnerLocations || [],
      isLoading: isPartnerLoading || partnerLocationsLoading,
      partner: partner || null,
      partnerSlug: urlPartnerSlug,
    }),
    [partnerLocations, isPartnerLoading, partnerLocationsLoading, partner, urlPartnerSlug],
  );

  return (
    <SubLocationSelectionContext.Provider value={values}>
      {children}
    </SubLocationSelectionContext.Provider>
  );
}
