import { extraErrorDataIntegration, httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { config } from "./frontendConfig";

import reportWebVitals from "./reportWebVitals";
import "./theme/customBootstrap.css";
import "./theme/main.css";

const configContainer = document.getElementById("config");
if (configContainer) {
  configContainer.remove();
}
function render() {
  const container = document.getElementById("root");
  const root = createRoot(container);
  const App = require("./App").default;
  root.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
  );
}

if (config.env !== "local") {
  const backendUrlsPatterns = [
    "localhost:3000",
    /^https:\/\/dev\.thanksboomerang\.com/,
    /^https:\/\/thanksboomerang\.com/,
  ];

  Sentry.init({
    dsn: "https://18da05a7266d4ae8b87a848380614e3a@o1087366.ingest.sentry.io/6100445",
    environment: config.env,
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: config.env === "production" ? 0.5 : 0,
    integrations: [
      extraErrorDataIntegration(),
      new Sentry.BrowserTracing({
        tracePropagationTargets: backendUrlsPatterns,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      httpClientIntegration({
        failedRequestStatusCodes: [400, 500],
        failedRequestTargets: backendUrlsPatterns,
      }),
    ],
    sendDefaultPii: true,
    maxRequestBodySize: 10000,
    ignoreErrors: [
      // From https://adithya.dev/sentry-unhandled-failed-to-fetch/
      // Ignore these until this is resolved: https://github.com/getsentry/sentry-javascript/discussions/5334
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      // https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
      "TypeError: Load failed",
    ],
  });
}

if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
