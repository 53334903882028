import objectToQueryString, { SearchParamsObject } from "client/utils/objectToQueryString";
import { useHistory, useLocation } from "react-router-dom";

function useSearchParams(): [URLSearchParams, (params: SearchParamsObject) => void] {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParams = (params: SearchParamsObject) => {
    const newSearchParams = objectToQueryString(params);
    history.push({
      search: newSearchParams.toString(),
    });
  };

  return [searchParams, setSearchParams];
}

export default useSearchParams;
