import { Category, PartnerLocation } from "../types/models";

export const getCategoryName = (categories: Category[], categoryId: string): string => {
  const toReturn = categories.reduce((acc, category) => {
    if (category.id === categoryId || category.name === categoryId) {
      acc = category.name;
    }
    return acc;
  }, "");
  return toReturn;
};

export const getLocationName = (locations: PartnerLocation[], locationId: string) => {
  return locations?.find(location => location.id === locationId)?.name;
};
