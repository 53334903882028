export enum KEYBOARD_KEY {
  ENTER = "Enter",
  TAB = "Tab",
  SPACE = " ",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  ESCAPE = "Escape",
  FORWARD_SLASH = 191,
}
