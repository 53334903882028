import { CrossSolidIcon } from "@assets/icons/icons";
import styled from "styled-components";
import Button from "../../../../../../../ui-components/Button";
import Heading from "../../../../../../../ui-components/Heading";

export const ModalWrapper = styled.div<{
  isOpened: boolean;
}>`
  display: ${props => (props.isOpened ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: #00000099;
`;

export const ModalDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: none;
  width: 100%;
  margin-block: 0;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const ModalContent = styled.div`
  border-radius: 16px;
  padding: 20px 20px 40px 20px;
  max-width: 332px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    height: 421px;
    max-width: 586px;
    border-radius: 16px;
    padding: 28px 28px 60px 28px;
  }
`;

export const ModalHeader = styled.div`
  padding: 0;
  border: none;
`;

export const CloseButton = styled.button`
  background: none;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const ButtonWrapper = styled.div`
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 400px;
    margin-inline: auto;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: ${props => props.theme.ui.fontWeights.semi};
  font-size: ${props => props.theme.ui.fontSizes.base};
  line-height: 20px;
`;

export const StyledCrossSolidIcon = styled(CrossSolidIcon)`
  width: 34px;
  height: 34px;
  margin-bottom: 12px;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    width: 50px;
    height: 50px;
  }
`;

export const StyledHeading = styled(Heading)`
  font-family: "Montserrat", sans-serif;
  font-weight: ${props => props.theme.ui.fontWeights.semi};
  font-size: ${props => props.theme.ui.fontSizes.lg};
`;
