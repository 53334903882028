import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import React, { Fragment, useEffect, useState } from "react";

import Checkbox from "../Checkbox";
import PaginationWithParams from "./PaginationWithParams";
import {
  BottomActionContainer,
  CheckboxContainer,
  ListContainer,
  ListItem,
  SelectionContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  VerticalDivider,
} from "./styles";

interface DataTableProps<TData, TValue> {
  columns: (ColumnDef<TData, TValue> & {
    style?: React.CSSProperties;
  })[];
  data: TData[];
  filters?: ColumnFiltersState;
  hasBulkOptions?: boolean;
  pageCount?: number;
  pageSize?: number;
  searchParamsPrefix?: string;
  itemsCount?: number;
  bulkActions?: React.FC<{
    selectedItems: TData[];
  }>[];
  bulkBottomActions?: React.FC<{
    selectedItems: TData[];
  }>[];
  onRowClick?: (row: TData) => void;
  dataTestId?: string;
  isList?: boolean;
  style?: React.CSSProperties;
}

export function DataTable<TData, TValue>({
  data,
  columns,
  hasBulkOptions = false,
  itemsCount,
  searchParamsPrefix = "",
  bulkActions = [],
  bulkBottomActions = [],
  onRowClick = () => {},
  isList = false,
  style,
  pageSize,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const count = itemsCount ?? data.length;
  const numberOfItemsSelected = Object.values(rowSelection).filter(Boolean).length || 0;

  const checkboxColumn: ColumnDef<TData> = {
    id: "select",
    header: ({ table }) => (
      <CheckboxContainer>
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onChange={e => table.toggleAllPageRowsSelected(!!e.target.checked)}
          aria-label="Select all"
          data-testid="selectAllCheckbox"
          className="checkbox"
          wrapperProps={{
            style: {
              width: 20,
              minHeight: 0,
            },
          }}
          withoutLabel
          size={20}
          squareSize="lg"
        />
      </CheckboxContainer>
    ),
    cell: ({ row }) => (
      <CheckboxContainer>
        <Checkbox
          checked={row.getIsSelected()}
          onChange={e => row.toggleSelected(!!e.target.checked)}
          aria-label="Select row"
          data-testid="selectRowCheckbox"
          className="checkbox"
          wrapperProps={{
            style: {
              width: 20,
            },
          }}
          withoutLabel
          size={20}
          squareSize="lg"
        />
      </CheckboxContainer>
    ),
    enableSorting: false,
    enableHiding: false,
    size: 42,
    minSize: 42,
    maxSize: 42,
  };

  const columnsWithCheckbox = hasBulkOptions ? [checkboxColumn, ...columns] : columns;

  useEffect(() => {
    if (!hasBulkOptions) table.toggleAllPageRowsSelected(false);
  }, [hasBulkOptions]);

  const table = useReactTable({
    data,
    columns: columnsWithCheckbox,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    enableRowSelection: hasBulkOptions,
  });

  const ids = React.useMemo(() => {
    return data?.map((item: any) => item?.id as string).join(",") ?? "";
  }, [data]);

  React.useEffect(() => {
    table?.toggleAllPageRowsSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  const rowsData = table.getRowModel().rows;
  const rowsSelectedData = rowsData.filter(row => row.getIsSelected()).map(row => row.original);

  return (
    <div data-testid="data-table">
      {hasBulkOptions && numberOfItemsSelected > 0 ? (
        <SelectionContainer>
          <span style={{ fontWeight: 700 }} data-testid="itemsSelected">
            {numberOfItemsSelected} {numberOfItemsSelected === 1 ? "item" : "items"} selected
          </span>

          {bulkActions.map((option, i) => (
            <Fragment key={i}>
              <VerticalDivider />
              {React.createElement(option, {
                selectedItems: rowsSelectedData,
              })}
            </Fragment>
          ))}
        </SelectionContainer>
      ) : null}
      {isList ? (
        <ListContainer>
          {rowsData.map(row => (
            <ListItem
              onClick={() => (hasBulkOptions ? row.toggleSelected() : onRowClick(row.original))}
              key={row.id}
            >
              {row
                .getVisibleCells()
                .map(cell => flexRender(cell.column.columnDef.cell, cell.getContext()))}
            </ListItem>
          ))}
        </ListContainer>
      ) : (
        <Table style={style} data-testid="tableRows" className="w-100">
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead key={header.id} {...header.column.columnDef}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  data-testid={`table-row-${index}`}
                  data-state={row.getIsSelected() && "selected"}
                  className="hover-bg"
                  onClick={() => (hasBulkOptions ? row.toggleSelected() : onRowClick(row.original))}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>No results.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      <PaginationWithParams
        count={count}
        pageSize={pageSize}
        searchParamPrefix={searchParamsPrefix}
      />
      {hasBulkOptions && numberOfItemsSelected > 0 ? (
        <BottomActionContainer>
          {bulkBottomActions.map((option, i) =>
            React.createElement(option, {
              key: i,
              selectedItems: rowsSelectedData,
            }),
          )}
        </BottomActionContainer>
      ) : null}
    </div>
  );
}

export default DataTable;
