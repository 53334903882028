import React from "react";
import { PartnerUserActionEnum } from "./actions";
import { PartnerUserStateLegacy } from "./state";

export function PartnerUserReducer(
  initialState: PartnerUserStateLegacy,
  action,
): PartnerUserStateLegacy {
  switch (action.type) {
    case PartnerUserActionEnum.UPDATE_CURRENT_CLAIM:
      return {
        ...initialState,
        currentClaim: action.newClaim,
      };
    case PartnerUserActionEnum.UPDATE_CURRENT_ITEM:
      return {
        ...initialState,
        currentItem: action.newItem,
      };
    case PartnerUserActionEnum.UPDATE_CURRENT_ITEM_AND_IMAGES:
      return {
        ...initialState,
        currentItem: {
          ...action.newItem,
          images: action.newItemImages?.results,
        },
        currentItemImages: action.newItemImages,
      };
    case PartnerUserActionEnum.UPDATE_LOADING:
      return {
        ...initialState,
        partnerUserStateLoading: action.loading,
      };
    case PartnerUserActionEnum.UPDATE_STORAGE_LOCATIONS:
      return {
        ...initialState,
        partnerStorageLocations: action.newStorageLocations,
      };

    default:
      throw new Error(`Partner User Context: Unhandled action type: ${action.type}`);
  }
}

export const PartnerUserReducerContext = React.createContext<React.Dispatch<any> | undefined>(
  undefined,
);
