import { PartnerItemUnclaimedStatusEnum, UnclaimedStatus } from "client/types";
import { Select, SelectItem } from "client/ui-components/Select";
import React from "react";

interface Props {
  label?: string;
  selected?: string;
  allowedStatuses?: UnclaimedStatus[];
  onChange?: (value: string) => void;
}

export function StatusPicker({ label, selected = "NULL", allowedStatuses = [], onChange }: Props) {
  return (
    <Select
      label={label}
      key={Math.random()}
      onValueChange={onChange}
      placeholder="-"
      size="sm"
      defaultValue={selected}
      theme="secondary"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        width: "fit-content",
        minWidth: 62,
      }}
    >
      {Object.keys(UnclaimedStatus)
        .filter(key => key === "NULL" || allowedStatuses.includes(key as UnclaimedStatus))
        .sort(a => (a === "NULL" ? -1 : 1))
        .map(key => (
          <SelectItem value={key} key={key}>
            {key === "NULL" ? "-" : PartnerItemUnclaimedStatusEnum[key]}
          </SelectItem>
        ))}
    </Select>
  );
}
