import { PartnerRoutesEnum } from "client/components/Routes";
import { isLgQuery } from "client/helpers/mediaQuery";
import { GoBackLink } from "client/ui-components";
import React from "react";
import { useHistory } from "react-router-dom";
import { ItemStatus, Match, PartnerItem } from "../../../../types";
import ActionsToolbar from "../ActionsToolbar";
import { StyledHeading, Wrapper } from "./styles";

export default function Header({ item, matches }: { item: PartnerItem; matches: Match[] }) {
  const isLg = isLgQuery();
  const history = useHistory();

  const onGoBack = () => {
    const previousQueryParams = (history.location.state as any)?.query;
    const previousPathname = (history.location.state as any)?.pathname;
    const searchInitiator = (history.location.state as any)?.searchInitiator;
    const route = previousPathname
      ? previousPathname
      : item.status === ItemStatus.UNCLAIMED && (item?.unclaimed_status ?? "") === ""
        ? "/partner/inventory/unclaimed"
        : item.status === ItemStatus.UNCLAIMED
          ? "/partner/inventory/archive"
          : "/partner/inventory";
    history.push({
      pathname: route,
      search: previousQueryParams,
      state: { searchInitiator },
    });
  };

  return (
    <Wrapper className="no-print">
      <div>
        <GoBackLink
          variant="legacy"
          linkText={
            [PartnerRoutesEnum.SEARCH_ALL, PartnerRoutesEnum.SEARCH].includes(
              (history.location.state as any)?.pathname,
            )
              ? "Back to search results"
              : item.status === ItemStatus.UNCLAIMED && (item?.unclaimed_status ?? "") !== ""
                ? "Inventory > Archive"
                : item.status === ItemStatus.UNCLAIMED
                  ? "Inventory > Unclaimed"
                  : "Inventory > Active"
          }
          dataTestId="claims-back-link"
          onClick={onGoBack}
        />
        <StyledHeading>Found item</StyledHeading>
      </div>
      {isLg && <ActionsToolbar item={item} isHeader={true} matches={matches} />}
    </Wrapper>
  );
}
