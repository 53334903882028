import styled from "styled-components";
import { Title } from "../../../../../components/typography";
import Typography from "../../../../../ui-components/Typography";

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InternalWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr calc(46px + 14px + 2 * (${props => props.theme.heights.button}));
  justify-items: center;
  height: 100%;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 40px 0;
    margin: auto;
  }
`;

export const LargeBackground = styled.div`
  background: ${props => props.theme.colors.gray6};
  display: flex;
  justify-content: center;
`;

export const OuterWrapper = styled.div`
  height: 100%;
  background-color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 800px;
    margin-top: -1.2rem;
    border-radius: ${props => props.theme.borderRadius.lg};
  }
`;

export const SmallBackground = styled.div`
  background: ${props => props.theme.colors.black};
  height: 100%;
  width: 100%;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    align-self: end;
    max-width: 334px;
  }
`;

export const StyledDescription = styled.div`
  color: ${props => props.theme.colors.black};
  width: 250px;
  text-align: center;
  line-height: 1.75;
  font-weight: 500;
  margin-top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 334px;
  }
`;
interface StyledImageProps {
  imageUrl: string;
}

export const StyledImage = styled.div<StyledImageProps>`
  width: 100%;
  max-width: 800px;
  border: none;
  border-radius: ${props => props.theme.borderRadius.lg};
  height: 190px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: 225px;
  }
`;

export const StyledTitle = styled(Title)`
  color: ${props => props.theme.ui.colors.primary700};
  text-align: center;
`;

export const TopHalf = styled.div`
  grid-row: 1;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

export const BoldWord = styled(Typography)`
  font-weight: ${props => props.theme.ui.fontWeights.bold};
  font-family: "Montserrat";
  font-size: ${props => props.theme.ui.fontSizes.base};
  line-height: 28px;
`;
