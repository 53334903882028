import React from "react";
import { useSendAcceptMatch } from "../../../../../actions/partnerMatches";
import MatchedArtifactDetailsSnapshot from "../../../../../components/Cards/MatchedArtifactDetailsSnapshot";
import Modal from "../../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../../components/Modal/Modal.types";
import { isSmQuery } from "../../../../../helpers/mediaQuery";
import { PartnerClaim, PartnerItem } from "../../../../../types";
import useCurrentPartner from "../../../../../utils/useCurrentPartner";
import { useMatchingStore } from "../../matchingStore";
import { CardsWrapper } from "../styles";

const ConfirmationModal = ({ matchingClaimToItems, matchId, isShippingEnabled }) => {
  const partnerId = useCurrentPartner();
  const acceptMatch = useSendAcceptMatch(partnerId);

  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);
  const currentCandidate = useMatchingStore(state => state.currentCandidate);
  const showConfirmationModal = useMatchingStore(state => state.showConfirmModal);
  const setShowConfirmModal = useMatchingStore(state => state.setShowConfirmModal);
  const setShowShippingModal = useMatchingStore(state => state.setShowShippingModal);
  const setShowSuccessModal = useMatchingStore(state => state.setShowSuccessModal);

  const isSm = isSmQuery();

  if (!matchId || !currentCandidate || !artifactToMatch) return null;

  const foundItem = (matchingClaimToItems ? currentCandidate : artifactToMatch) as PartnerItem;
  const claim = (matchingClaimToItems ? artifactToMatch : currentCandidate) as PartnerClaim;

  return (
    <Modal
      topBtnClassName="mb-25 mt-45 mt-md-1 mt-xs-35"
      bottomBtnText={!isSm ? "Back" : "Cancel"}
      bottomBtnOnClick={() => {
        setShowConfirmModal(false);
      }}
      header={"Confirm match"}
      show={showConfirmationModal}
      showXButton
      topBtnOnClick={() => {
        if (!isShippingEnabled) {
          acceptMatch.mutate(
            { partnerId, matchId },
            {
              onSuccess: () => {
                setShowConfirmModal(false);
                setShowSuccessModal(true);
              },
            },
          );
        } else {
          setShowShippingModal(true);
          setShowConfirmModal(false);
        }
      }}
      topBtnLoading={acceptMatch.isPending}
      topBtnText={
        isShippingEnabled ? ModalButtonTextEnum.CONTINUE : ModalButtonTextEnum.YES_CONFIRM_MATCH
      }
      withoutTopMargin
    >
      <CardsWrapper>
        <MatchedArtifactDetailsSnapshot
          heading="Claim"
          data-testid="claimCard"
          images={claim.images}
          name={claim.name}
        />
        <MatchedArtifactDetailsSnapshot
          heading="Found item"
          data-testid="itemCard"
          images={foundItem.images}
          name={foundItem.name}
          id={foundItem.inventoryId}
          storageLocation={(foundItem as any).storage_location?.name}
        />
      </CardsWrapper>
    </Modal>
  );
};

export default ConfirmationModal;
