import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
`;
