import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding-block: 1.25rem;
`;

export const Category = styled.p`
  margin: 0;
  padding: 0.25rem 0.5rem;
  background-color: ${props => props.theme.colors.gray6};
  border-radius: 1rem;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 17px;
  align-self: flex-start;
`;

export const Name = styled.p`
  margin: 0;
  line-height: 20px;
  font-weight: 600;
`;

export const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 1rem;
  aspect-ratio: 1 / 1;
`;

export const ItemLink = styled.a`
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
  }
`;
