import React from "react";
import { Button, Col } from "react-bootstrap";
import astronautImg from "./assets/astronaut.svg";
import BlankMessage from "./components/BlankMessage";

const Message = () => (
  <>
    <Col className="text-center lh28" xs={12}>
      Contact <a href="mailto:support@thanksboomerang.com">support@thanksboomerang.com</a> if you
      think there is a problem.
    </Col>
    <Col className="text-center lh28 mt-3" xs={12}>
      <a href={`/`}>
        <Button variant="primary" className="mt-1">
          Take me home
        </Button>
      </a>
    </Col>
  </>
);

function FallbackComponent() {
  return (
    <BlankMessage
      alt="Boomerang astronaut"
      image={astronautImg}
      title="Sorry. Something went wrong"
      children={<Message />}
    />
  );
}
export default FallbackComponent;
