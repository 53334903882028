import React from "react";
import lostItemImg from "../../../../assets/lost-item.png";
import magnifyingGlassImg from "../../../../assets/magnifying-glass.png";
import matchFoundImg from "../../../../assets/match-found.png";
import { fontSizes } from "../../../../theme/uiTheme";
import Heading from "../../../../ui-components/Heading";
import Text from "../../../../ui-components/Text";
import ThreeColumnLayout from "../ThreeColumnLayout";
import { Content, CopyWrapper, FeatureCell, MediaWrapper, Wrapper } from "./styles";

const submitAClaim = (
  <FeatureCell>
    <MediaWrapper>
      <img src={lostItemImg} alt="image of lost item graphic" />
    </MediaWrapper>
    <CopyWrapper>
      <Heading as="h3" variant="h4" className="mb-2" textAlign="center">
        Submit a claim
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight="normal"
        lineHeight={{ base: 1.5 }}
        textAlign="center"
      >
        Enter your lost item details to start your claim. The more details, the better.
      </Text>
    </CopyWrapper>
  </FeatureCell>
);

const aiSearching = (
  <FeatureCell>
    <MediaWrapper>
      <img src={magnifyingGlassImg} alt="image of magnifying glass graphic" />
    </MediaWrapper>
    <CopyWrapper>
      <Heading as="h3" variant="h4" className="mb-2" textAlign="center">
        Allow Boomerang to do the searching
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight="normal"
        lineHeight={{ base: 1.5 }}
        textAlign="center"
      >
        Our technology continously searches for your item. We'll keep you updated daily on our
        progress.
      </Text>
    </CopyWrapper>
  </FeatureCell>
);

const notifiedWhenFound = (
  <FeatureCell>
    <MediaWrapper>
      <img src={matchFoundImg} alt="image of match found graphic" />
    </MediaWrapper>
    <CopyWrapper>
      <Heading as="h3" variant="h4" className="mb-2" textAlign="center">
        Get notified if we find your item!
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight="normal"
        lineHeight={{ base: 1.5 }}
        textAlign="center"
      >
        If there's a match, we'll help streamline the return process between you and our partner.
      </Text>
    </CopyWrapper>
  </FeatureCell>
);

const HowItWorksSection = () => {
  return (
    <Wrapper>
      <Content>
        <Heading as="h2" variant="h2" className="mb-35" textAlign="center">
          How it works
        </Heading>
        <Text
          fontSize={{ base: fontSizes.base, md: fontSizes.lg }}
          lineHeight={{ base: 1.5 }}
          fontWeight="normal"
          textAlign="center"
          className="body mb-425 mb-md-45"
        >
          Our 3 easy steps can help you recover your lost item
        </Text>
        <ThreeColumnLayout
          column1={submitAClaim}
          column2={aiSearching}
          column3={notifiedWhenFound}
        />
      </Content>
    </Wrapper>
  );
};

export default HowItWorksSection;
