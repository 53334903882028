import classNames from "classnames";
import { Button } from "client/ui-components";
import React, { ComponentProps } from "react";
import { isLgQuery } from "../../helpers/mediaQuery";

export interface FooterProps {
  className?: string;
  leftBtnText?: string;
  rightBtnText?: string;
  rightButtonProps?: ComponentProps<typeof Button>;
  leftButtonProps?: ComponentProps<typeof Button>;
}

function TabsFooter(props) {
  const { className, leftBtnText, rightBtnText, rightButtonProps, leftButtonProps }: FooterProps =
    props;

  const isLg = isLgQuery();

  return (
    <div className={`border-0  p-35  ${className}`}>
      <div className="row justify-content-between w-100 mx-0 my-0">
        <div
          className={`${
            !!rightBtnText && "justify-content-center row mx-0"
          } col-12 col-lg-6 mb-2 mt-1 my-lg-0 px-0 pe-lg-225`}
        >
          {isLg && leftBtnText && leftButtonProps && (
            <Button variant="outline" {...leftButtonProps}>
              {leftBtnText}
            </Button>
          )}
        </div>
        <div className="order-1 order-lg-2 col-12 col-lg-6 px-0 ps-lg-225 mb-3 mb-lg-0">
          {rightBtnText && rightButtonProps && (
            <Button
              className={classNames("w-100", rightButtonProps.className)}
              {...rightButtonProps}
            >
              {rightBtnText}
            </Button>
          )}
        </div>
        <div
          className={`${
            !!rightBtnText && "justify-content-center row mx-0"
          } order-2 order-lg-1 d-lg-none col-12 col-lg-6 mb-2 mt-1 my-lg-0 px-0 pe-lg-225`}
        >
          {!isLg && leftBtnText && leftButtonProps && (
            <Button variant="outline" {...leftButtonProps}>
              {leftBtnText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TabsFooter;
