import { ArrowNarrowLeft, ArrowNarrowRight } from "@assets/ui-icons";
import { Button } from "client/ui-components";
import DateFilterInput from "client/ui-components/DateFilterInput";
import { default as React, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledLabel } from "../Select/styles";
import { ActionsContainer, DatePickerContainer, HeaderContainer, StyledArrow } from "./styles";

type Props = {
  onSelect: (startDate: Date | null, endDate: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  style?: React.CSSProperties;
  Input?: React.FC<DatePickerInputProps>;
  dataTestId?: string;
  label?: string;
  dateFormat?: string;
};
export type DatePickerInputProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  value?: string;
  dataTestid?: string;
};

function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <HeaderContainer>
      {prevMonthButtonDisabled ? (
        <span style={{ width: "30px" }}></span>
      ) : (
        <StyledArrow onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <ArrowNarrowLeft
            titleId="Arrow pointing left"
            accessibilityTitle="previous month icon"
            data-testid="arrowLeft"
          />
        </StyledArrow>
      )}
      <span className="text-center" style={{ lineHeight: "24px" }} data-testid="month">
        {date.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
      {nextMonthButtonDisabled ? (
        <span style={{ width: "30px" }}></span>
      ) : (
        <StyledArrow onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <ArrowNarrowRight
            titleId="Arrow pointing right"
            accessibilityTitle="next month icon"
            data-testid="arrowRight"
          />
        </StyledArrow>
      )}
    </HeaderContainer>
  );
}

export default function DateRangePicker({
  label,
  onSelect,
  Input = DateFilterInput,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  style,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [key, setKey] = useState("");

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setStartDate(defaultStartDate);
  }, [defaultStartDate]);

  useEffect(() => {
    setEndDate(defaultEndDate);
  }, [defaultEndDate]);

  const onCancel = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setIsOpen(false);
  };

  const onApply = () => {
    if (!endDate) {
      onSelect(startDate, startDate);
    } else {
      onSelect(startDate, endDate);
    }
    setIsOpen(false);
  };

  const onClear = () => {
    setStartDate(null);
    setEndDate(null);
    onSelect(null, null);
    setIsOpen(false);
    setKey(Math.random().toString());
  };
  return (
    <DatePickerContainer style={style}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <DatePicker
        data-testid="datePicker"
        useWeekdaysShort={true}
        startDate={startDate}
        endDate={endDate}
        popperPlacement="bottom"
        renderCustomHeader={CustomHeader}
        onClickOutside={onCancel}
        open={isOpen}
        maxDate={new Date()}
        highlightDates={[new Date()]}
        onChange={onChange}
        selectsRange
        customInput={<Input isOpen={isOpen} setIsOpen={setIsOpen} handleClose={onCancel} />}
        key={key}
        {...props}
      >
        <ActionsContainer>
          <div>
            <Button
              onClick={onClear}
              aria-label="Reset date filter"
              data-testid="resetCalendarButton"
              style={{
                width: "100%",
                padding: "8px 14px",
                lineHeight: "20px",
                fontSize: "14px",
                height: "36px",
              }}
              variant="text"
            >
              Reset
            </Button>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              onClick={onCancel}
              aria-label="Clear date filter"
              data-testid="cancelCalendarButton"
              style={{
                width: "100%",
                padding: "8px 14px",
                lineHeight: "20px",
                fontSize: "14px",
                height: "36px",
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={onApply}
              aria-label="Apply date filter"
              data-testid="applyCalenderButton"
              style={{
                width: "100%",
                padding: "8px 14px",
                lineHeight: "20px",
                fontSize: "14px",
                height: "36px",
              }}
            >
              Apply
            </Button>
          </div>
        </ActionsContainer>
      </DatePicker>
    </DatePickerContainer>
  );
}
