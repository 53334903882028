import React from "react";
import { StyledArrowLeft } from "../../../../assets/icons/icons";
import { Back, HeaderContainer, HeaderWrapper, IconButton, Subtitle, Title } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  title: string;
  subTitle: string;
  onBack?: () => void;
};

export default function Header({ onBack, title, subTitle, ...props }: Props) {
  return (
    <HeaderWrapper {...props} className="d-flex justify-content-center">
      <HeaderContainer className="d-flex flex-row flex-sm-column w-100 p-4 pt-sm-45 gap-25 gap-sm-2">
        {onBack === undefined ? (
          <Back
            to="/claims"
            className="d-flex align-items-center align-self-start mt-sm-0 gap-0 gap-sm-2"
          >
            <StyledArrowLeft
              accessibilityTitle="Go back"
              aria-hidden="true"
              focusable="false"
              titleId="CheckoutHeaderGoBack"
            />

            <span>Go back</span>
          </Back>
        ) : (
          <IconButton
            className="d-flex align-items-center align-self-start mt-sm-0 gap-0 gap-sm-2 p-0"
            onClick={onBack}
          >
            <StyledArrowLeft
              accessibilityTitle="Go back"
              aria-hidden="true"
              focusable="false"
              titleId="CheckoutHeaderGoBack"
            />

            <span>Go back</span>
          </IconButton>
        )}

        <div className="d-flex flex-column gap-1">
          <Title>{title}</Title>

          <Subtitle>{subTitle}</Subtitle>
        </div>
      </HeaderContainer>
    </HeaderWrapper>
  );
}
