import { ItemStatus } from "client/types";
import { FilterItem } from "../../../../ui-components/FilterDropdown/types";

export type ArchiveFilters = {
  category: FilterItem;
  date_before: Date | null;
  date_after: Date | null;
  unclaimed_status: FilterItem;
};

export type ArchiveFilterValues = {
  status: ItemStatus;
  category: string;
  date_before: Date | null;
  date_after: Date | null;
  unclaimed_status: string | string[];
};

export const initialCategoryFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export const initialUnclaimedStatusFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export const defaultArchiveFilters: ArchiveFilters = {
  category: initialCategoryFilter,
  date_before: null,
  date_after: null,
  unclaimed_status: initialUnclaimedStatusFilter,
};

export const defaultFilterValues: ArchiveFilterValues = {
  status: ItemStatus.UNCLAIMED,
  category: initialCategoryFilter.value,
  date_before: null,
  date_after: null,
  unclaimed_status: initialUnclaimedStatusFilter.value,
};

export type FilterOptions = keyof ArchiveFilters;
