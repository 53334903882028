import React from "react";

import { Svg, SvgProps } from "./Icons.types";

export default function SuperMatchIcon({
  color = "currentcolor",
  accessibilityTitle,
  titleId,
  ...props
}: SvgProps) {
  return (
    <Svg accessibilityTitle={accessibilityTitle} titleId={titleId} viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.5H4C3.72386 8.5 3.5 8.72386 3.5 9V20C3.5 20.2761 3.72386 20.5 4 20.5H12C12.2761 20.5 12.5 20.2761 12.5 20V9C12.5 8.72386 12.2761 8.5 12 8.5ZM4 7H12C13.1046 7 14 7.89543 14 9V20C14 21.1046 13.1046 22 12 22H4C2.89543 22 2 21.1046 2 20V9C2 7.89543 2.89543 7 4 7Z"
        fill={color || "currentColor"}
      />

      <path d="M8 13L11 19H5L8 13Z" fill={color || "currentColor"} />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.5H12C11.7239 3.5 11.5 3.72386 11.5 4V15C11.5 15.2761 11.7239 15.5 12 15.5H20C20.2761 15.5 20.5 15.2761 20.5 15V4C20.5 3.72386 20.2761 3.5 20 3.5ZM12 2H20C21.1046 2 22 2.89543 22 4V15C22 16.1046 21.1046 17 20 17H12C10.8954 17 10 16.1046 10 15V4C10 2.89543 10.8954 2 12 2Z"
        fill={color || "currentColor"}
      />

      <path d="M16 8L19 14H13L16 8Z" fill={color || "currentColor"} />
    </Svg>
  );
}
