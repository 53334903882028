import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px 20px;
  box-shadow: 0px 2px 10px 0px #00000059;
  background-color: ${props => props.theme.ui.colors.white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-inline: 28px;
  }
`;
