import classNames from "classnames";
import useCategories from "client/hooks/data/user/useCategories";
import React from "react";
import Loader from "../../components/Loader";
import history from "../../context/history";
import { StyledBoomerangArrowLeft } from "../../scenes/private/Overview/styles";
import theme from "../../theme";
import { PartnerItem } from "../../types";
import ReturnsItem from "./ReturnsItem";
import { NoReturns, SeeAll, Wrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  blankStateMessage: string;
  count: number;
  isLoading: boolean;
  items: Array<PartnerItem & { match: string }>;
  seeAllLink: string;
  title: string;
  titleIcon?: React.ReactNode;
};

export default function ReturnSummary({
  blankStateMessage,
  count,
  items,
  isLoading,
  seeAllLink,
  title,
  titleIcon,
  ...props
}: Props) {
  const categories = useCategories();

  return (
    <Wrapper {...props}>
      <div className="d-flex align-items-center justify-content-between flex-row mb-35">
        <div className="d-flex gap-3 align-items-center">
          {titleIcon && titleIcon}

          <p className="m-0">{title}</p>
        </div>

        {isLoading ? null : (
          <SeeAll onClick={() => history.push(seeAllLink)} data-testid="seeAllLink">
            See all ({count})
          </SeeAll>
        )}
      </div>

      <div
        className={classNames(
          "d-flex flex-column flex-grow-1 justify-content-top",
          isLoading || (count === 0 && "align-items-center"),
        )}
      >
        {isLoading || categories.isPending ? (
          <Loader className="h-100" />
        ) : count === 0 ? (
          <NoReturns>
            <StyledBoomerangArrowLeft
              accessibilityTitle="No returns"
              ariaHidden={true}
              className="w-100"
              color={theme.colors.gray4}
              titleId={blankStateMessage}
            />

            {blankStateMessage}
          </NoReturns>
        ) : (
          items.map(item => (
            <ReturnsItem item={item} categories={categories.data ?? []} key={item.id} />
          ))
        )}
      </div>
    </Wrapper>
  );
}
