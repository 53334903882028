import styled from "styled-components";

import { MagnifyingGlass } from "../../../client/assets/icons/icons";

export const StyledMagnifyingGlass = styled(MagnifyingGlass)`
  height: 24px;
  width: 24px;
  cursor: pointer;

  z-index: 9;
`;

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
