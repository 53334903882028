import { PartnerSelectionContext } from "client/context";
import React, { useContext } from "react";
import { ArrowNarrowLeft } from "../../../../assets/ui-icons";
import { Button } from "../../../../ui-components";
import PartnerSelectInput from "../PartnerSelectInput";
import PartnerSelectMenu from "../PartnerSelectMenu";
import { Divider, InputWrapper } from "./styles";

export default function PartnerSelectSearchView() {
  const { searchText, setIsSearchViewVisible } = useContext(PartnerSelectionContext);

  return (
    <div>
      <InputWrapper>
        <Button
          aria-label="close-choose-partner-modal"
          data-testid="backButton"
          variant="text"
          className="p-0"
          onClick={() => {
            setIsSearchViewVisible(false);
          }}
        >
          <ArrowNarrowLeft
            accessibilityTitle="close-choose-partner-modal"
            titleId="close-choose-partner-modal"
            className="flex-shrink-0"
          />
        </Button>

        <PartnerSelectInput />
      </InputWrapper>

      <Divider />

      {searchText.length >= 3 && <PartnerSelectMenu />}
    </div>
  );
}
