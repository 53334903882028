import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  width: 100%;

  .input-icon {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    currentcolor: ${({ theme }) => theme.ui.colors.gray600};
    top: 50%;
    transform: translateY(-50%);

    &.start {
      left: 0;
    }

    &.end {
      right: 0;
    }
  }

  .rounded-input-icon {
    margin-inline: 1.25rem;
  }

  .square-input-icon {
    margin-inline: 0.75rem;
  }
`;

export const Input = styled.input`
  all: unset;
  flex: 1;

  background-color: ${({ theme }) => theme.ui.colors.white};
  border: 1px solid ${({ theme }) => theme.ui.colors.gray500};

  font-family: ${({ theme }) => theme.ui.fontFamilies.default};
  font-size: ${({ theme }) => theme.ui.fontSizes.base};

  width: 100%;

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.sm}) {
    width: 320px;
  }

  &.full-width {
    width: 100%;
  }

  &.rounded-input {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    outline: 1px solid transparent;
    color: ${({ theme }) => theme.ui.colors.primary700};
    line-height: 1.5;

    &.textarea {
      height: 154px;
      border-radius: 0.5rem;
      padding: 18px 20px;
    }

    &:not(.textarea) {
      border-radius: 50px;
      height: 48px;
      padding-inline: 1.25rem;
    }

    &.has-icon {
      height: 60px;

      &.icon-start {
        padding-inline-start: 52px;
      }

      &.icon-end {
        padding-inline-end: 52px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.ui.colors.gray300};
      border-color: ${({ theme }) => theme.ui.colors.gray500};
    }

    &:not(:disabled) {
      &:focus-visible {
        border-width: 1px;
        border-color: ${({ theme }) => theme.ui.colors.primary500};
        outline: 1px solid ${({ theme }) => theme.ui.colors.primary500};
        box-shadow:
          0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px rgba(3, 70, 245, 0.2);

        &.has-error {
          outline: 1px solid ${({ theme }) => theme.ui.colors.error500};
          box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.error400};
          border-color: ${({ theme }) => theme.ui.colors.error500};
          box-shadow:
            0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px #fee4e2;
        }
      }

      &:not(:focus-visible) {
        &.has-error {
          outline: 1px solid ${({ theme }) => theme.ui.colors.error500};
          box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.error400};
          border-color: ${({ theme }) => theme.ui.colors.error500};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
      }
    }
  }

  &.square-input {
    border-radius: 0.5rem;
    padding-inline: 0.875rem;
    color: ${({ theme }) => theme.ui.colors.gray700};
    line-height: 1.25;

    &.textarea {
      height: 154px;
      padding: 18px 20px;
    }

    &:not(.textarea) {
      height: 52px;
    }

    &.has-icon {
      &.icon-start {
        padding-inline-start: 46px;
      }

      &.icon-end {
        padding-inline-end: 46px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.ui.colors.gray300};
      border-color: ${({ theme }) => theme.ui.colors.gray500};
    }

    &:not(:disabled) {
      &:focus-visible {
        border-color: ${({ theme }) => theme.ui.colors.primary500};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.primary400};
      }

      &:not(:focus-visible) {
        &.has-error {
          box-shadow: 0 0 0 2px ${({ theme }) => theme.ui.colors.error400};
          border-color: ${({ theme }) => theme.ui.colors.error500};
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.ui.colors.gray300};
    border-color: ${({ theme }) => theme.ui.colors.gray500};
  }

  &::placeholder {
    color: ${({ theme }) => theme.ui.colors.gray600};
    opacity: 0.5;
  }
`;
