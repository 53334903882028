import { PartnerItemsCountByStatusResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import { formatFilters, OptionalCountQueryFilters } from "../partnerItemCounts";
import { defaultStatusFilter } from "../partnerItemCounts/useStatusCounts";

export default ({
  partnerId,
  queryObject,
}: {
  partnerId: string;
  queryObject: OptionalCountQueryFilters;
}): Promise<PartnerItemsCountByStatusResponse> => {
  const query = formatFilters({
    status: defaultStatusFilter.value,
    ...queryObject,
  });

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/count-by-status/${query ? "?" + query : ""}`,
  });
};
