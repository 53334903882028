import { ColumnDef } from "@tanstack/react-table";
import { isLgQuery } from "client/helpers/mediaQuery";
import { useHasStorageLocations } from "client/hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import useFetchStorageLocations from "client/hooks/data/partner/useFetchStorageLocations";
import useCategories from "client/hooks/data/user/useCategories";
import { ResultType } from "client/pages/PartnerGlobalSearch";
import { ReturnStatus } from "client/types";
import { DataTable, StatusBadge } from "client/ui-components";
import { getCategoryName } from "client/utils/mappers";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import Loader from "../../../../../components/Loader";
import { createReturnObject, CustomReturn } from "../../helpers";
import { useReturnsStore } from "../../returnStore";
import ContactDetailsCell from "../ContactDetailsCell";
import ItemDetailsCell from "../ItemDetailsCell";
import MoveReturnsStorageLocationButton from "../MoveReturnsStorageLocation";
import { NoReturnsMessage } from "../NoReturnsMessage";
import ReturnSnapshot from "../ReturnSnapshot";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  returns: CustomReturn[] | null;
  isLoading: boolean;
  count: number;
  dataTestId?: string;
};

type styledColumnDef = (ColumnDef<CustomReturn> & {
  style?: React.CSSProperties;
})[];

export default function ReturnsTable({ returns, isLoading, count }: Props) {
  const history = useHistory();
  const columns = useReturnsTableColumns();
  const isMd = isLgQuery();
  const { hasBulkOptions } = useReturnsStore();

  if (isLoading || !returns) return <Loader />;
  if (returns.length === 0) return <NoReturnsMessage />;

  return (
    <DataTable
      data={returns}
      columns={columns}
      itemsCount={count}
      onRowClick={({ match }) => {
        history.push({
          pathname: `/partner/match/${match?.id}/return`,
          state: {
            query: history.location.search,
          },
        });
      }}
      isList={!isMd}
      style={{
        width: "100%",
      }}
      hasBulkOptions={hasBulkOptions}
      bulkActions={[MoveReturnsStorageLocationButton]}
    />
  );
}

const useReturnsTableColumns = () => {
  const isLg = isLgQuery();

  const categories = useCategories();
  const { storageLocations } = useFetchStorageLocations();
  const hasStorageLocations = useHasStorageLocations();

  const columns: styledColumnDef = useMemo(() => {
    if (!isLg)
      return [
        {
          id: "singleColumn",
          header: "",
          cell: ({ row: { original } }) => {
            return <ReturnSnapshot customReturn={original} />;
          },
          style: {
            flexGrow: 1,
          },
        },
      ];
    return [
      {
        id: "item",
        header: "Item name & category",
        cell: ({
          row: {
            original: { item },
            index,
          },
        }) => {
          return (
            <div className="d-flex" style={{ maxWidth: 300 }}>
              <ItemDetailsCell
                category={
                  categories ? getCategoryName(categories.data ?? [], item?.category || "") : ""
                }
                item={item}
                index={index}
              />
            </div>
          );
        },
        style: {
          minWidth: "28%",
          width: hasStorageLocations ? "28%" : "33%",
        },
      },
      {
        accessorKey: "contactDetails",
        header: "Contact details",
        cell: ({ row }) => <ContactDetailsCell user={row.original.claim?.user} />,
        style: {
          minWidth: "26%",
          width: hasStorageLocations ? "28%" : "33%",
        },
      },
      ...((hasStorageLocations
        ? [
            {
              accessorKey: "storageLocation",
              header: "Storage location",
              style: {
                minWidth: "24%",
                width: "24%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              },
              cell: ({ row }) => {
                const storageLocationId =
                  typeof row.original.item?.storage_location === "string"
                    ? row.original.item?.storage_location
                    : row.original.item?.storage_location?.id;
                const storageLocation = storageLocations?.find(
                  location => location.id === storageLocationId,
                );
                return storageLocation?.name ?? "-";
              },
            },
          ]
        : []) as styledColumnDef),
      {
        accessorKey: "status",
        header: () => <div style={{ textAlign: "right", paddingRight: 28 }}>Status</div>,
        cell: ({ row: { original: returnItem } }) => {
          const returnObject = createReturnObject({
            returnItem,
          });
          return (
            <div
              className="d-flex justify-content-end"
              style={{
                paddingRight: 28,
              }}
            >
              <StatusBadge
                objectType={ResultType.RETURNS}
                objectStatus={returnObject.status as ReturnStatus}
                returnObject={returnObject}
                showReturnsIcon
              />
            </div>
          );
        },
        style: {
          minWidth: "20%",
          width: "20%",
        },
      },
    ];
  }, [isLg, hasStorageLocations, categories, storageLocations]);

  return columns;
};
