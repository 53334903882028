import React, { useEffect } from "react";
import { toast } from "react-toastify";
import theme from "../../../theme";
import { Heading, Link, Text } from "../../../ui-components";
import { Logo, LogoVariants } from "../../../ui-components/Logo";
import { SignInForm, SocialProof } from "./components";
import { Col, Row } from "./styles";

export default function PartnerSignIn() {
  useEffect(() => {
    const error = new URLSearchParams(window.location.search).get("error");
    if (error) {
      toast.error(error);
      // remove error param from url
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  return (
    <main className="d-flex flex-column min-vh-100">
      <div className="container-fluid flex-grow-1 d-flex">
        <Row className="row flex-grow-1">
          <Col className="col-12 col-lg-6 d-flex flex-column p-0 left-panel">
            <Link
              href="/for-partners"
              className="mt-45 mb-45 mx-35 mx-md-45 mb-lg-0 align-self-start"
            >
              <Logo name={LogoVariants.BoomerangPartner} />
            </Link>

            <div className="sign-in-form">
              <Text
                color={theme.ui.colors.primary500}
                fontSize={theme.ui.fontSizes.base}
                fontWeight={theme.ui.fontWeights.bold}
                className="mb-25"
              >
                Boomerang Partner Portal
              </Text>

              <Heading as="h1" size="xs" className="mb-4">
                Welcome back
              </Heading>

              <SignInForm />

              <Text as="p" className="text-center">
                <span className="fw-bold">New to Boomerang?</span>{" "}
                <Link
                  href="https://share.hsforms.com/1olnAZ_DYS8amUoozJO7uaAc60y2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Schedule a chat
                </Link>
              </Text>
            </div>
          </Col>

          <Col className="d-none d-lg-flex flex-column justify-content-center col-6 right-panel">
            <SocialProof />
          </Col>
        </Row>
      </div>
    </main>
  );
}
