import React from "react";
import theme from "../../../../theme";
import { Heading, Text } from "../../../../ui-components";
import augustine from "./augustine.png";
import philip from "./philip.png";
import skylar from "./skylar.png";
import { Image, Subtitle, TeamDetails, TitleWrapper, Wrapper } from "./styles";

export default function Team() {
  return (
    <Wrapper className="d-flex flex-column align-items-center">
      <TitleWrapper>
        <Heading variant="h3" as="h3" className="mb-3 text-center">
          Executive Team
        </Heading>

        <Subtitle textAlign="center">
          Boomerang’s founders were previously founders or leaders at successful companies e.g
          Shazam (acquired by Apple), Youtube (acquired by Google), TrueCar (NSDQ: TRUE), Tatari,
          and YourMechanic.
        </Subtitle>
      </TitleWrapper>

      <TeamDetails className="d-flex flex-column flex-md-row justify-content-md-center">
        <div className="d-flex flex-column align-items-center">
          <Image src={skylar} alt="Skylar Lodgeson" />

          <Heading variant="h4" as="p" className="mb-1">
            Skyler Logsdon
          </Heading>

          <Text color={theme.ui.colors.primary500} fontWeight={theme.ui.fontWeights.bold}>
            Co-founder, CEO
          </Text>
        </div>

        <div className="d-flex flex-column align-items-center">
          <Image src={augustine} alt="Augustine Diep-Tran" />

          <Heading variant="h4" as="p" className="mb-1">
            Augustine Diep-Tran
          </Heading>

          <Text color={theme.ui.colors.primary500} fontWeight={theme.ui.fontWeights.bold}>
            Co-founder, COO
          </Text>
        </div>

        <div className="d-flex flex-column align-items-center">
          <Image src={philip} alt="Philip Inghelbrecht" />

          <Heading variant="h4" as="p" className="mb-1">
            Philip Inghelbrecht
          </Heading>

          <Text color={theme.ui.colors.primary500} fontWeight={theme.ui.fontWeights.bold}>
            Co-founder, Executive Chairman
          </Text>
        </div>
      </TeamDetails>
    </Wrapper>
  );
}
