import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";
import { ApiHandlerRes, useApiHandler } from ".";
import { PartnerGlobalSearchResult } from "../context/PartnerGlobalSearch";
import { ContactDetailsRequest, User } from "../types";
import { getCommonHeaders } from "../utils/headers";

type GlobalSearchParams = {
  partnerId: string;
  search: string;
  location: string;
  resultSet?: "items" | "claims";
  limit?: number;
  offset?: number;
};

export const getGlobalSearch = async ({
  partnerId,
  search,
  location,
  resultSet,
  limit,
  offset,
}: GlobalSearchParams): Promise<PartnerGlobalSearchResult | null> => {
  const queryParams = objectToQueryString({
    query: search,
    location_id: location || undefined,
    result_set: resultSet,
    limit,
    offset,
  });
  const queryString = queryParams.toString();
  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/search/?${queryString}`,
  });
};

const createUser = async (contactDetails: ContactDetailsRequest): Promise<User | null> => {
  return fetchApiData({
    url: "/api/partner/users/",
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(contactDetails),
    },
  });
};

export const useCreateUser = (): ApiHandlerRes<ContactDetailsRequest, User | null> =>
  useApiHandler(createUser);
