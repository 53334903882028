import styled from "styled-components";

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const MediaSection = styled.div`
  text-align: center;
  margin-bottom: 32px;
  img {
    max-width: 280px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 40px;
    img {
      max-width: 350px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 0;
    img {
      width: 354px !important;
      max-width: 354px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    img {
      width: 480px !important;
      max-width: 480px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    img {
      width: 600px !important;
      max-width: 600px;
    }
  }
`;

export const CopySection = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-right: 40px;
  }
`;
