import classNames from "classnames";
import Pagination from "client/components/Pagination";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import React, { useContext, useRef } from "react";
import ArtifactCard from "../../../components/Cards/ArtifactCard";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { ClaimsColumns } from "../../../components/Table/TableColumns";
import { FilterClaimContext } from "../../../context/FilterClaimContext";
import { ClaimFilters } from "../../../context/FilterClaimContext/helpers";
import history from "../../../context/history";
import { usePartnerUserDispatch } from "../../../context/PartnerUser";
import { updateCurrentClaim } from "../../../context/PartnerUser/actions";
import { isLgQuery } from "../../../helpers/mediaQuery";
import { ArtifactType, FormattedClaim } from "../../../types";
import getItemClaimRow from "../../../utils/getItemClaimRow";
import { Filters, NoItems } from "../Claims/components";
import { Wrapper } from "./styles";

export default function ClaimsArchive() {
  const isLg = isLgQuery();
  const {
    formattedClaims,
    claimsCount,
    categoryFilters,
    statusFilters,
    filters,
    setFilters,
    isLoading,
    page,
    setPage,
  } = useContext(FilterClaimContext);

  const partnerUserDispatch = usePartnerUserDispatch();
  const itemsPerPage = useRef<number>(12);
  const dateFormatter = useDateFormatter();

  const rows = (formattedClaims || [])?.map((claim, i) =>
    getItemClaimRow(claim as FormattedClaim, ArtifactType.Claim, i, dateFormatter),
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Wrapper className={classNames("mb-5 pt-lg-35 px-0")}>
      <Filters
        categoryFilters={categoryFilters}
        filters={filters}
        setFilters={(filters: ClaimFilters) => {
          setPage(1);
          setFilters(filters);
        }}
        statusFilters={statusFilters}
        showGoToArchiveLink={false}
      />
      <Pagination
        currentPage={page}
        totalCount={claimsCount}
        pageSize={itemsPerPage.current}
        onPageChange={page => setPage(page)}
        showPagination={formattedClaims && formattedClaims.length > 0}
      >
        {rows.length === 0 ? (
          <NoItems />
        ) : isLg ? (
          <Table
            columns={ClaimsColumns}
            artifactIds={formattedClaims?.map(claim => claim.id)}
            rows={rows || []}
            justifyEnd
            onRowClick={async claimId => {
              const claimFromId = formattedClaims?.find(claim => claim.id === claimId);
              claimFromId !== undefined &&
                updateCurrentClaim(partnerUserDispatch, claimFromId.originalClaim);
              history.push(`/partner/claim/${String(claimId)}`);
            }}
          />
        ) : (
          <>
            {formattedClaims.map((claim, i) => {
              // Reduce the amount of info passed through
              const { originalClaim, ...formattedClaim } = claim;
              return (
                <ArtifactCard
                  artifact={formattedClaim}
                  key={i}
                  lostOrFoundLabel={"lost"}
                  onClick={() => {
                    updateCurrentClaim(partnerUserDispatch, originalClaim);
                    history.push(`/partner/claim/${claim?.id}`);
                  }}
                  isClaim
                />
              );
            })}
          </>
        )}
      </Pagination>
    </Wrapper>
  );
}
