import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import styled from "styled-components";

const StyledFloatingLabelInput = styled(FloatingLabel)`
  .form-control {
    color: ${props => props.theme.colors.gray1};
    font-weight: 500;
  }
  &.form-floating > label {
    padding-left: 56px;
    font-size: ${props => props.theme.fontSizes.paragraph};
    font-weight: ${props => props.theme.fontWeights.default};
    color: ${props => props.theme.colors.gray3};
  }
  &.form-floating > .form-control {
    padding-left: 50px;
    border-radius: ${props => props.theme.borderRadius.sm};
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.primaryBlue} !important;
      outline-style: none;
      box-shadow: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.primaryBlue};
    }
    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.gray3};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.gray3}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.primaryBlue} !important;
      fill: ${props => props.theme.colors.primaryBlue} !important;
    }
  }
  &.form-floating > .form-control.is-invalid {
    border-color: ${props => props.theme.colors.red};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
    ~ label,
    ~ svg {
      color: ${props => props.theme.colors.red};
    }
    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.colors.red} !important;
      outline-style: none;
      box-shadow: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.red} !important;
    }
    &:not(:placeholder-shown) {
      border-color: ${props => props.theme.colors.red};
    }
    &:placeholder-shown {
      border-color: ${props =>
        props.theme.colors.danger}; // TODO THIS IS FOR THE FUTURE FOR 3 PHASE COLORING
    }
    &:focus ~ svg {
      color: ${props => props.theme.colors.red} !important;
      fill: ${props => props.theme.colors.red} !important;
    }
  }
  textarea.form-control {
    height: ${props => props.theme.heights.textarea};
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      height: ${props => props.theme.heights.textareaLg};
    }
  }
  svg {
    position: absolute;
    left: 21px;
    top: 31px; // Not happy with this. Error messages expanding height of the input led me to this hardcoded value
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSizes.default};
    cursor: text;
    color: ${props => props.theme.colors.gray3};
  }
`;

const StyledHelperText = styled.div`
  position: relative;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: ${props => props.theme.colors.gray3};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    position: absolute;
  }
`;

function StyledTextarea(props) {
  const {
    ariaLabel,
    className,
    controlId,
    icon,
    errors,
    isValidated,
    label,
    onBlur,
    onChange,
    placeholder,
    helperText,
    required,
    style,
    type,
    value,
    maxLength,
    minLength,
    tabIndex,
  } = props;
  return (
    <StyledFloatingLabelInput controlId={controlId} label={label} className={className}>
      <Form.Control
        as="textarea"
        aria-label={ariaLabel}
        type={type}
        className={!isValidated ? "" : errors?.types ? "is-invalid" : "is-valid"}
        placeholder={placeholder}
        style={style}
        onBlur={onBlur}
        maxLength={maxLength}
        minLength={minLength}
        onChange={onChange}
        required={required}
        value={value}
        tabIndex={tabIndex}
      />
      {icon}
      {errors?.types ? (
        Object.entries(errors?.types).map(([type, message]) => (
          <StyledHelperText className="text-danger invalid-feedback d-block fw500" key={type}>
            {message}
          </StyledHelperText>
        ))
      ) : (
        <StyledHelperText className="d-block fw500">{helperText}</StyledHelperText>
      )}
    </StyledFloatingLabelInput>
  );
}
export default StyledTextarea;
