import styled from "styled-components";
import theme from "../../../../../theme";

export const ShippingWrapper = styled.div`
  .border.rounded-3 {
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      width: 386px;
      margin: auto;
    }
  }

  h3 {
    font-size: ${theme.fontSizes.lg};
    line-height: 1;
  }
  p {
    line-height: 1.5;
  }
`;
