import React, { useContext } from "react";

import { ReturnStatus as ReturnStatusIcon } from "@assets/icons/icons";
import classNames from "classnames";
import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { isLgQuery, isMdQuery, isXxxlQuery } from "client/helpers/mediaQuery";
import { useHistory } from "react-router-dom";
import { ResultType } from "../..";
import theme from "../../../../theme";
import { ItemStatus, User } from "../../../../types";
import { Typography } from "../../../../ui-components";
import StatusBadge from "../../../../ui-components/StatusBadge";
import { Result } from "../ResultsSection";
import { InfoWrapper, ResultWrapper, ReturnDetailsLink, StyledTypography } from "./styles";

type ResultProps = {
  type: ResultType;
  id: string;
  name: string;
  created_at?: string;
  lost_at?: string;
  collected_at?: string;
  description: string;
  user?: User;
  result: Result;
  backgroundColor: string;
  dataTestId?: string;
};

const ResultCard = ({
  type,
  id,
  name,
  created_at,
  lost_at,
  collected_at,
  description,
  user,
  result,
  backgroundColor,
  dataTestId,
}: ResultProps) => {
  const isMd = isMdQuery();
  const isLg = isLgQuery();
  const isXxxl = isXxxlQuery();
  const history = useHistory();
  const { lastVisitedPage } = useContext(PartnerGlobalSearchContext);

  const itemName = (
    <div className="mb-3">
      <Typography
        heading={!isLg}
        as={"div"}
        fontSize="16px"
        fontWeight={!isLg ? theme.fontWeights.bold : theme.fontWeights.light}
        whiteSpace="pre-wrap"
        color={theme.ui.colors.primary700}
      >
        {name}
      </Typography>
    </div>
  );

  const collectedAt = type === ResultType.ITEMS && collected_at && (
    <div className="mb-3">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Date found
        </Typography>
      )}
      <Typography
        as={"div"}
        fontSize="16px"
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight={1.5}
        color={theme.ui.colors.gray700}
      >
        {collected_at}
      </Typography>
    </div>
  );

  const createdAt = type === ResultType.CLAIMS && created_at && (
    <div className="mb-3">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Date submitted
        </Typography>
      )}
      <Typography
        as={"div"}
        fontSize="16px"
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight={1.5}
        color={theme.ui.colors.gray700}
      >
        {created_at}
      </Typography>
    </div>
  );

  const lostAtClaims = type === ResultType.CLAIMS && lost_at && (
    <div className="mb-3">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Date lost
        </Typography>
      )}
      <Typography
        as={"div"}
        fontSize="16px"
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight={1.5}
        color={theme.ui.colors.gray700}
      >
        {lost_at}
      </Typography>
    </div>
  );

  const inventoryId = type === ResultType.ITEMS && id && (
    <div className="mb-3">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          ID
        </Typography>
      )}
      <Typography
        as={"div"}
        fontSize="16px"
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight={1.5}
        color={theme.ui.colors.gray700}
      >
        {id.slice(0, 8).toUpperCase()}
      </Typography>
    </div>
  );

  const contactDetails = type === ResultType.CLAIMS && id && (
    <div className="mb-3">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Contact details
        </Typography>
      )}
      <Typography
        as={"div"}
        fontSize="16px"
        lineHeight={1.5}
        fontWeight={theme.fontWeights.bold}
        color={theme.ui.colors.gray700}
      >
        {user?.full_name}
      </Typography>
      {isMd ? (
        <>
          <Typography
            as={"span"}
            fontSize="16px"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
            color={theme.ui.colors.gray700}
          >
            {user?.email}
          </Typography>
          <Typography
            as={"span"}
            color={theme.ui.colors.gray700}
            fontSize="14px"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
          >
            {" | "}
          </Typography>
          <Typography
            as={"span"}
            color={theme.ui.colors.gray700}
            fontSize="16px"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
          >
            {user?.phone_number}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            as={"div"}
            fontSize="16px"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
            color={theme.ui.colors.gray700}
          >
            {user?.email}
          </Typography>
          <Typography
            as={"div"}
            color={theme.ui.colors.gray700}
            fontSize="16px"
            fontWeight={theme.ui.fontWeights.normal}
            lineHeight={1.5}
          >
            {user?.phone_number}
          </Typography>
        </>
      )}
    </div>
  );

  const { return_object, status, unclaimed_status } = result;
  const objectStatus =
    type === ResultType.ITEMS && status === ItemStatus.UNCLAIMED && unclaimed_status
      ? unclaimed_status
      : status;

  const statusField = status ? (
    <div className="mb-1 mb-lg-0">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Status
        </Typography>
      )}
      <StatusBadge
        className={isLg ? "" : "mt-25"}
        objectType={type}
        objectStatus={objectStatus}
        returnObject={return_object}
      />
    </div>
  ) : (
    <div>-</div>
  );

  const returnStatusField =
    return_object !== null ? (
      <div
        style={{ textDecoration: "none" }}
        className={`d-flex flex-column flex-md-row align-items-md-center mt-lg-0${
          !isMd && " mt-3"
        }`}
        onClick={e => {
          e.stopPropagation();
          const path = `/partner/match/${return_object.match}/return`;
          if (e.metaKey || e.ctrlKey) {
            window.open(path, "_blank");
          } else {
            history.push(path);
          }
        }}
      >
        {!isLg && (
          <Typography
            className="me-3"
            as={"div"}
            fontSize={theme.fontSizes.sm}
            fontWeight={theme.fontWeights.bold}
            color={theme.ui.colors.primary700}
            lineHeight={1.2}
          >
            <ReturnStatusIcon accessibilityTitle="returnStatusIcon" titleId="returnStatusIcon" />
            Return status
          </Typography>
        )}
        <StatusBadge
          className={isMd ? "me-3" : "mt-25 me-3 mb-1"}
          objectType={ResultType.RETURNS}
          objectStatus={status}
          returnObject={return_object}
        />
        {!isLg && (
          <div>
            <ReturnDetailsLink
              href={`/partner/match/${return_object.match}/return`}
              className="cursor-pointer me-3 mt-2"
            >
              See return details
            </ReturnDetailsLink>
          </div>
        )}
      </div>
    ) : (
      isLg && <div>-</div>
    );

  const descriptionField = description && (
    <div className="mb-35">
      {!isLg && (
        <Typography
          as={"div"}
          fontSize={theme.fontSizes.sm}
          fontWeight={theme.fontWeights.bold}
          color={theme.ui.colors.primary700}
          lineHeight={1.2}
        >
          Description
        </Typography>
      )}
      <StyledTypography
        as={"div"}
        fontSize="16px"
        fontWeight={theme.ui.fontWeights.normal}
        lineHeight={1.5}
        color={theme.ui.colors.gray700}
        whiteSpace="break-spaces"
      >
        {description}
      </StyledTypography>
    </div>
  );

  const smLayout = (
    <>
      <div className="row">
        <div className="col">{itemName}</div>
      </div>
      <div className="row">
        <div className="col">{contactDetails}</div>
      </div>
      <div className="row">
        <div className="col">{inventoryId}</div>
        <div className="col">{collectedAt}</div>
      </div>
      <div className="row">
        <div className="col">{lostAtClaims}</div>
      </div>
      <div className="row">
        <div className="col">{descriptionField}</div>
      </div>
      <div className="row">
        <div className="col d-flex">{statusField}</div>
      </div>
      <div className="row">
        <div className="col d-flex">{returnStatusField}</div>
      </div>
    </>
  );

  const mdLayout = (
    <>
      <div className="row">
        <div className="col-6">{itemName}</div>
      </div>
      <div className="row">
        <div className="col">
          {inventoryId}
          {contactDetails}
        </div>
        <div className="col">
          {createdAt}
          {collectedAt}
          {lostAtClaims}
        </div>
        <div className="col">{descriptionField}</div>
        <div className="col d-flex">{statusField}</div>
      </div>
      <div className="row">
        <div className="col d-flex">{returnStatusField}</div>
      </div>
    </>
  );

  const lgLayout = (
    <>
      <div className="row">
        <div
          className={`col${
            type === ResultType.ITEMS && isXxxl ? "-2" : type === ResultType.ITEMS ? "-3" : ""
          } align-self-center`}
        >
          {itemName}
        </div>
        <div className={`col${type === ResultType.ITEMS ? "" : "-3"} align-self-center`}>
          {inventoryId} {contactDetails}
        </div>
        <div className="col align-self-center">
          {createdAt}
          {collectedAt}
          {lostAtClaims}
        </div>
        <div className="col-2 align-self-center">{descriptionField}</div>
        <div className="d-flex col-2 align-self-center">{statusField}</div>
        <div className="d-flex col-3 align-self-center">{returnStatusField}</div>
      </div>
    </>
  );

  return (
    <ResultWrapper
      onClick={e => {
        const path = type === ResultType.ITEMS ? `/partner/item/${id}` : `/partner/claim/${id}`;
        if (e.metaKey || e.ctrlKey) {
          window.open(path, "_blank");
        } else {
          history.push({
            pathname: path,
            state: {
              query: history.location.search,
              pathname: history.location.pathname,
              searchInitiator: lastVisitedPage,
            },
          });
        }
      }}
      tabIndex={0}
      backgroundColor={backgroundColor}
      className={classNames("p-35", "cursor-pointer")}
    >
      <InfoWrapper data-testid={dataTestId}>
        {!isMd && !isLg && smLayout}
        {isMd && !isLg && mdLayout}
        {isLg && lgLayout}
      </InfoWrapper>
    </ResultWrapper>
  );
};

export default ResultCard;
