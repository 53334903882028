import { useQuery } from "@tanstack/react-query";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { OptionalReturnsCountQueryFilters } from ".";
import queryFactory from "../queryFactory";

export default (queryObject: OptionalReturnsCountQueryFilters) => {
  const partnerId = useCurrentPartner();

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(queryFactory.returnCountByStatusFiltered({ partnerId, queryObject }));
  const statusCounts = data ?? [];
  return { data: statusCounts, isLoading, error };
};
