import React, { useEffect, useRef, useState } from "react";
import { DotsThreeVertical } from "../../assets/icons/icons";
import { isLgQuery } from "../../helpers/mediaQuery";
import { ButtonModes, ButtonTypes } from "../Button/Button.types";
import { Divider, DropdownMenuWrapper, ListItem, VerticalDotsButton } from "./styles";
import { DropdownAlignment } from "./types";

export type DropdownListItem = {
  color?: string;
  divider?: boolean;
  label: React.ReactNode;
  onClick: () => void;
};

interface ThreeDotsDropdownProps {
  buttonMode: ButtonModes;
  listItems: DropdownListItem[];
  dropdownAlignment?: DropdownAlignment;
}

const ThreeDotsDropdown = (props: ThreeDotsDropdownProps) => {
  const { buttonMode, listItems, dropdownAlignment } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<any>();
  const isLg = isLgQuery();

  useEffect(() => {
    if (listItems.length > 0) {
      // add when mounted
      document.addEventListener("mousedown", handleDropdownClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleDropdownClick);
      };
    }
  }, []);

  const handleDropdownClick = e => {
    if (e.target.classList.contains("dropdown-option")) {
      return;
    }
    if (
      e.target.classList.contains("toggler") ||
      e.target.parentElement.classList.contains("toggler")
    ) {
      if (dropdownRef?.current?.classList.contains("open")) {
        setDropdownOpen(false);
      } else {
        setDropdownOpen(true);
      }
    } else if (dropdownRef?.current?.contains(e.target)) {
      setDropdownOpen(true);
      return;
    } else {
      // outside click
      setDropdownOpen(false);
    }
  };

  return (
    <div className={`dropdown ${dropdownOpen ? " open" : ""}`} ref={dropdownRef}>
      <VerticalDotsButton
        ariaLabel="Open dropdown menu"
        mode={dropdownOpen ? (isLg ? ButtonModes.lightOutline : ButtonModes.lightBlue) : buttonMode}
        type={ButtonTypes.submit}
        text={
          <DotsThreeVertical
            accessibilityTitle={`${dropdownOpen ? "Close" : "Open"} dropdown`}
            titleId="ThreeDotsDropdownIconTitle"
          />
        }
        className={"border-0 col-auto px-0 toggler"}
        disabled={listItems.length === 0}
        loading={false}
      />
      <DropdownMenuWrapper
        dropdownAlignment={
          dropdownAlignment ?? (isLg ? DropdownAlignment.BOTTOM_END : DropdownAlignment.TOP_START)
        }
        className={`dropdown-menu ${dropdownOpen ? " show" : ""} py-25 mt-4`}
      >
        {listItems.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.divider && <Divider className="my-25 mx-35" />}
              <ListItem
                className="p-35 m-0 dropdown-item"
                onClick={() => {
                  setDropdownOpen(false);
                  item.onClick();
                }}
                textColor={item.color}
                width={"255px"}
                data-testid={item.label}
              >
                {item.label}
              </ListItem>
            </React.Fragment>
          );
        })}
      </DropdownMenuWrapper>
    </div>
  );
};

export default ThreeDotsDropdown;
