import styled from "styled-components";

export const LeftPanel = styled.div`
  height: 100%;
  background: #fff;
  padding-top: 20px;
  @media (min-width: ${({ theme }) => theme.ui.breakpoints.lg}) {
    border-right: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    padding: 20px;
  }
  border-right: none;
  border: 0;
  background-color: transparent;
  box-shadow: none;
`;

// It's a grid with a header and 2 columns, the first column is the LeftPanel, with a max-width of 400px
// Below 1440px, the grid becomes a single column
export const Container = styled.div`
  background-color: ${({ theme }) => theme.ui.colors.gray300};
  display: grid;
  min-height: 100vh;

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.lg}) {
    grid-template-rows: auto 1fr;
    grid-template-columns: minmax(0, 420px) 1fr;
    /* make the first child of the grid take up the whole space */
    & > *:first-child {
      grid-column: 1 / 3 !important;
    }
  }

  grid-template-columns: 1fr;
  grid-template-rows: 120px 1fr;
  & > *:first-child {
    grid-column: 1;
  }

  @media print {
    display: block;
  }
`;
