import styled from "styled-components";

export const ContactName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.ui.colors.primary700};
  line-height: 1.2;
  margin: 0;
`;

export const ContactDetail = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.default};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.ui.colors.gray700};
  line-height: 1.2;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  overflow: hidden;
  min-width: 0;
`;
