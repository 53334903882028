import * as d3 from "d3-shape";
import React from "react";
import styled from "styled-components";

const Shadow = styled.path`
  fill: rgba(127, 127, 127, 0.1);
  filter: blur(5px);
`;

const Svg = styled.svg`
  overflow: visible;
`;

interface ArcProps {
  handleHover: boolean;
}

const Arc = styled.path<ArcProps>`
  transition: transform 0.3s;
  ${props =>
    props.handleHover
      ? `
  :hover {
    transform: scale(1.05);
  }`
      : ""}}
`;

export interface DonutChartProps {
  accessibilityTitle: string;
  data: DonutChartDataPoint[];
  height: number;
  onDataPointHighlighted?: (dataPoint?: DonutChartDataPoint) => void;
  shadow?: boolean;
  thickness: number;
  titleId: string;
  width: number;
}
export interface DonutChartDataPoint {
  label: string;
  value: number;
  color: string;
}

const DonutChart = (props: DonutChartProps) => {
  const { accessibilityTitle, height, onDataPointHighlighted, shadow, thickness, titleId, width } =
    props;
  const data = props.data || [];
  const radius = width / 2;

  // Arc generator
  const arc = d3.arc();

  // Pie generator to generate angles to be fed into the arc generator
  const pie = d3.pie<DonutChartDataPoint>().value(d => d.value);
  const arcData = pie(data);

  return (
    <Svg
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      aria-labelledby={`${titleId}`}
    >
      <title id={titleId}>{accessibilityTitle}</title>
      {shadow && (
        <Shadow
          d={
            arc({
              outerRadius: radius,
              innerRadius: radius - thickness - 10,
              startAngle: 0,
              endAngle: 2 * Math.PI,
            }) ?? undefined
          }
          style={{
            transform: `translate(${width / 2}px, ${height / 2}px)`,
          }}
        />
      )}
      {(arcData || []).map(d => (
        <g
          key={d.data.label}
          style={{
            transform: `translate(${width / 2}px, ${height / 2}px)`,
          }}
        >
          <Arc
            handleHover={!!onDataPointHighlighted}
            d={
              arc({
                startAngle: d.startAngle,
                endAngle: d.endAngle,
                outerRadius: radius,
                innerRadius: radius - thickness,
              }) ?? undefined
            }
            style={{
              fill: d.data.color,
            }}
            key={d.data.label}
            onMouseEnter={() => onDataPointHighlighted?.(d.data)}
            onMouseLeave={() => onDataPointHighlighted?.()}
          />
        </g>
      ))}
    </Svg>
  );
};

export default DonutChart;
