export function toUpperCase(str: string) {
  return str.toUpperCase().split(" ").join("_");
}

export const stringToYellingCase = (str: string) => {
  return str
    .split(" ")
    .map(word => word.toUpperCase())
    .join("_");
};
export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

export const formattedInventoryId = (itemId: string) => itemId.slice(0, 8).toUpperCase();
