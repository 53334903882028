export enum DropdownAlignment {
  BOTTOM_START,
  BOTTOM_END,
  TOP_START,
  TOP_END,
}
export type FilterListItem = {
  name: string;
  count: number;
  urlString: string;
};
