import React from "react";
import { Column } from "./";
import HeaderRow from "./HeaderRow";
import { BlankTableWrapper, IconMessageWrapper } from "./styles";

import Loader from "../Loader";
import { LegacyTextComponent } from "../typography";

interface BlankTableProps {
  className?: string;
  columns: Column[];
  icon: React.ReactNode;
  loading?: boolean;
  message: string;
  title: string;
}

const TableBlankState: React.FC<BlankTableProps> = ({
  className,
  columns,
  icon,
  loading,
  message,
  title,
}) => {
  return (
    <BlankTableWrapper className={`${className || ""} pt-45 pb-25 ${loading ? "h-100" : ""}`}>
      <LegacyTextComponent className={`align-self-start fw-500 ms-45 ${loading ? "mb-45" : ""}`}>
        {title}
      </LegacyTextComponent>
      {loading ? (
        <>
          <HeaderRow className="align-self-start w-100" columns={columns} justifyEnd={true} />
          <Loader height={"402px"} />
        </>
      ) : (
        <IconMessageWrapper>
          {icon}
          <LegacyTextComponent className="mt-25">{message}</LegacyTextComponent>
        </IconMessageWrapper>
      )}
    </BlankTableWrapper>
  );
};

export default TableBlankState;
