import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteClaimImage } from "./fetchFunctions/deleteClaimImage";
import queryFactory from "./queryFactory";

export type DeleteClaimImagesMutationResponse = { success: string[]; error: string[] };

// Return a mutation that will issue delete requests simultaneously
// and return any successfully deleted image ids. Throws an error
// if all delete requests failed.
export default () => {
  const queryClient = useQueryClient();

  const deleteImages = ({
    claimId,
    imageIds,
  }: {
    claimId: string;
    imageIds: string[];
  }): Promise<DeleteClaimImagesMutationResponse> => {
    const deleteRequests = imageIds.map(imageId =>
      deleteClaimImage({
        image_id: imageId,
        claim_id: claimId,
      }),
    );

    const successImageIds: string[] = [];

    return Promise.allSettled(deleteRequests)
      .then(results => {
        results.forEach(result => {
          if (result.status === "fulfilled") {
            successImageIds.push(result.value);
          }
        });

        return {
          success: successImageIds,
          error: imageIds.filter(id => !successImageIds.includes(id)),
        };
      })
      .finally(() => {
        if (successImageIds.length === 0) {
          throw new Error("error deleting all images");
        }
      });
  };

  const mutation = useMutation({
    mutationFn: deleteImages,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.claimImages(variables.claimId).queryKey,
      });
    },
  });

  return mutation;
};
