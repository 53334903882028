import styled from "styled-components";
import { getBadgeCss } from "../utils/badgeStyle";
import { BadgeVariantsEnum } from "./Badge";

export const Typography = styled.div<{
  // as?: string; Not needed, "as" is already a prop of styled components. Used to override div as another element like h1, h2 etc. https://styled-components.com/docs/api#as-polymorphic-prop
  className?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: number; // Unitless
  preLine?: boolean;
}>`
  ${props => props.preLine && "white-space: pre-line;"}
  color: ${props => props.color ?? props.theme.colors.gray1};
  font-family: ${props => props.theme.fontFamilies.display};
  font-size: ${props => props.fontSize ?? props.theme.fontSizes.default};
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 500};
  line-height: ${props => props.lineHeight ?? "inherit"};
  margin: 0;
  overflow-wrap: break-word;
  padding: 0;
  word-break: break-word;
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.xl};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: ${props => props.theme.fontSizes.xxl};
  }
`;

export const ModalTitle = styled.div`
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: 1.2;
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes.xl};
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fontSizes.xxl};
  }
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
`;

export const HeaderTitle = styled.span`
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.xl};
  color: ${props => props.theme.colors.white};
`;

export const Subheader = styled.h2`
  color: ${props => props.theme.colors.gray1};
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.lg};
  margin: 0;
  padding: 0;
  word-break: break-word;
`;

export const Subheader2 = styled.h3<{
  status?: string;
  variant?: BadgeVariantsEnum;
}>`
  color: ${props => props.theme.colors.gray1};
  ${props => getBadgeCss(props.status, props.variant)}
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: 1.5;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.default};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
`;

export const Subheader3 = styled.h3`
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: ${props => props.theme.fontWeights.default};
  font-size: ${props => props.theme.fontSizes.default};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const Label = styled.div<{ defaultLabelFontSize?: boolean }>`
  color: ${props => props.theme.colors.gray3};
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: 20px;
  font-weight: 500;
  font-size: ${props =>
    props.defaultLabelFontSize ? props.theme.fontSizes.default : props.theme.fontSizes.sm};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const SmallText = styled.div`
  color: ${props => props.theme.colors.gray1};
  font-family: lato;
  font-style: normal;
  line-height: 1.25;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const TextLabel = styled.div`
  color: #00174a;
  font-style: normal;
  line-height: 1.25;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const BoldText = styled.div`
  color: ${props => props.theme.colors.gray1};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 1.25;
  font-style: normal;
`;

export const LightLabel = styled(Label)`
  color: ${props => props.theme.colors.gray3};
  font-weight: 500;
`;

export const TableColumnValue = styled.div`
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 500;
  line-height: 1.71428571429;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const DarkLabel = styled(Label)`
  font-weight: 600;
  text-transform: capitalize;
`;

export const LegacyTextComponent = styled.div<{
  preLine?: boolean;
  rebrand?: boolean;
}>`
  font-family: ${props =>
    props.rebrand ? props.theme.fontFamilies.paragraph : props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.default};
  line-height: 1.5;
  margin: 0;
  padding: 0;
  word-break: break-word;
  ${props => props.preLine && "white-space: pre-line;"}
  > a {
    &.text-gray1 {
      color: ${props => props.theme.colors.gray1};
    }
  }
`;

export const TextBook = styled.div`
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.default};
  line-height: 1.5;
  margin: 0;
  overflow-wrap: break-word;
  padding: 0;
  word-break: break-word;
`;

export const TextBold = styled.div<{
  preLine?: boolean;
  status?: string;
  variant?: BadgeVariantsEnum;
}>`
  ${props => getBadgeCss(props.status, props.variant)}
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: inherit;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.default};
  line-height: 1.5;
  margin: 0;
  overflow-wrap: break-word;
  padding: 0;
  word-break: break-word;
  ${props => props.preLine && "white-space: pre-line;"}
`;

export const ErrMessage = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 1.3;
`;
