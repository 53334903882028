import React, { forwardRef, Ref } from "react";
import { FieldError } from "react-hook-form";
import { CheckboxInput, CheckboxLabel, ErrorText } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["input"]> & {
  error?: FieldError | string;
  label?: string;
};

const Checkbox = forwardRef(
  ({ className, children, id, error, label, ...props }: Props, ref: Ref<HTMLInputElement>) => {
    return (
      <>
        <div className={`form-check d-flex flex-row gap-25 ${className ? className : ""}`}>
          <CheckboxInput
            {...props}
            className={`form-check-input flex-shrink-0 mt-0 ${
              error !== undefined ? "is-invalid" : ""
            }`}
            id={id}
            ref={ref}
            type="checkbox"
          />

          <CheckboxLabel className="form-check-label" htmlFor={id}>
            {label ? label : children}
          </CheckboxLabel>
        </div>

        {error !== undefined && (
          <ErrorText className="text-danger invalid-feedback d-block fw500 mt-35">
            {typeof error === "string" ? error : error.message}
          </ErrorText>
        )}
      </>
    );
  },
);

export default Checkbox;
