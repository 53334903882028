import styled from "styled-components";

export const Wrapper = styled.footer`
  display: flex;
  padding: 20px;
  background-color: white;
  color: ${({ theme }) => theme.ui.colors.white};
  justify-content: space-between;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.35);

  padding: 16px 20px 28px 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray3};
`;
export const ButtonAction = styled.button`
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid #dce1e8;
  background: #fff;
  width: 100%;
  height: 60px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #00174a;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

export const ContainerMatches = styled.div`
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background-color: white;
  margin-top: -253px;
  border-radius: 16px;
  z-index: 1;
  margin-left: -45px;
`;

export const ContainerMatchesMobile = styled.div`
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background-color: white;
  margin-top: -88px;
  border-radius: 16px;
  z-index: 1;
  margin-left: -45px;
`;

export const CardMatch = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  align-self: stretch;
  color: var(--primary-700-for-text, #00174a);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  gap: 12px;
`;

export const IconLabel = styled.span`
  color: #00174a;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
