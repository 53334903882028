import React from "react";
import theme from "../../../theme";
import Text from "../../Text";
import { TextProps } from "../../Typography";

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["small"]> & TextProps;

export default function FormHelp({ children, ...props }: Props) {
  return (
    <Text
      as="small"
      fontSize="14px"
      lineHeight="20px"
      fontWeight="400"
      color={theme.ui.colors.gray600}
      {...props}
    >
      {children}
    </Text>
  );
}
