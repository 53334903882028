import { GetPartnerReturnsRequest, PartnerReturnsDetailedResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";

export default (
  partnerReturnsReq: GetPartnerReturnsRequest,
): Promise<PartnerReturnsDetailedResponse> => {
  const query = objectToQueryString(partnerReturnsReq.filters || {});
  query.set("detailed_fields", "shipment,returned_by,match,recovery_method");
  const queryString = query.toString();

  return fetchApiData({
    url: `/api/partner/partners/${partnerReturnsReq.partnerId}/returns/?${queryString}`,
  });
};
