import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ChevronRight, MarkerPin } from "../../../../assets/ui-icons";
import Loader from "../../../../components/Loader";
import { LostItemCreationContext, PartnerSelectionContext } from "../../../../context";
import theme from "../../../../theme";
import { Text } from "../../../../ui-components";
import { Divider, HighlightedText, ListItem } from "../PartnerSelectSearchView/styles";
import NotFound from "./NotFound";

export default function PartnerSelectMenu() {
  const history = useHistory();
  const { setSearchText, searchResults, setIsSearchViewVisible, isLoading } =
    useContext(PartnerSelectionContext);
  const { resetClaimDetailsState } = useContext(LostItemCreationContext);

  return (
    <div>
      {isLoading ? (
        <div className="px-35">
          <Loader />
          <Divider />
        </div>
      ) : searchResults.filter(result => result !== undefined).length === 0 ? (
        <div className="px-35">
          <NotFound />
          <Divider />
        </div>
      ) : (
        searchResults
          .filter(result => result !== undefined)
          .map((result, index) => (
            <>
              <ListItem
                onClick={() => {
                  if (
                    result!.locations !== undefined &&
                    result!.locations?.count &&
                    result!.locations?.count === 1
                  ) {
                    resetClaimDetailsState();
                    history.push(
                      `/lost/flow?p=${result!.slug}&l=${result!.locations.results[0].slug}`,
                    );
                  } else {
                    history.push(`/lost/p/${result!.slug}/select-location`);
                  }
                  setIsSearchViewVisible(false);
                  setSearchText("");
                }}
                key={result!.id}
                className="d-flex flex-row gap-4 align-items-center p-35"
                data-testid={`location_${index}`}
              >
                <MarkerPin
                  accessibilityTitle={`${result!.id}-location-pin`}
                  className="flex-shrink-0"
                  titleId={`${result!.id}-location-pin`}
                  color={theme.ui.colors.gray600}
                />

                <div className="d-flex flex-column flex-grow-1">
                  <Text as="p" fontWeight={700} className="m-0">
                    {result!.hydratedName.map((name, nameIndex) => (
                      <HighlightedText
                        as="span"
                        key={`${result!.id}-${index}-${nameIndex}`}
                        isMatch={name.isMatch}
                        className="m-0"
                      >
                        {name.text}
                      </HighlightedText>
                    ))}
                  </Text>

                  <Text
                    color={theme.ui.colors.gray600}
                    fontSize={theme.ui.fontSizes.sm}
                    fontWeight={400}
                  >
                    {result!.hydratedAddress.map((address, addressIndex) => (
                      <HighlightedText
                        as="span"
                        key={`${result!.id}-${index}-${addressIndex}`}
                        color={theme.ui.colors.gray600}
                        fontSize={theme.ui.fontSizes.sm}
                        fontWeight={400}
                        isMatch={address.isMatch}
                        className="m-0"
                        data-testid="partnerLocation"
                      >
                        {address.text}
                      </HighlightedText>
                    ))}
                  </Text>
                </div>

                <ChevronRight
                  color={theme.ui.colors.gray600}
                  accessibilityTitle="select-partner-icon"
                  titleId="select-partner-icon"
                  className="flex-shrink-0"
                />
              </ListItem>

              <Divider key={`${result!.id}-divider`} />
            </>
          ))
      )}
    </div>
  );
}
