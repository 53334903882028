import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import useCurrentPartner from "client/utils/useCurrentPartner";

export const usePartnerDateFormat = () => {
  const partnerId = useCurrentPartner();
  const { defaultPartnerLocation } = usePartnerLocations(partnerId || "");
  const country = defaultPartnerLocation?.address.country_code;
  return country === "US"
    ? {
        short: "MM/dd/yyyy",
        long: "PP",
      }
    : {
        short: "dd/MM/yyyy",
        long: "dd MMM yyyy",
      };
};
