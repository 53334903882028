import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { useLocationPreferredUnits } from "client/hooks/useLocationPreferredUnits";
import { ClaimStatus, PartnerItem, PartnerStorageLocation } from "client/types";
import { useGetPartnerClaims } from "../../../../../actions/partnerClaims";
import { sendEditItem } from "../../../../../actions/partnerItems";
import { useSendAcceptMatch } from "../../../../../actions/partnerMatches";
import MatchedArtifactDetailsSnapshot from "../../../../../components/Cards/MatchedArtifactDetailsSnapshot";
import Modal from "../../../../../components/Modal";
import StyledFloatingLabel from "../../../../../components/StyledFloatingLabel";
import { isMdQuery, isXsQuery } from "../../../../../helpers/mediaQuery";
import theme from "../../../../../theme";
import Typography from "../../../../../ui-components/Typography";
import useCurrentPartner from "../../../../../utils/useCurrentPartner";
import { useMatchingStore } from "../../matchingStore";

type ShippingDetailFormInputs = {
  parcelDimensions?: number;
  parcelHeight?: number;
  parcelLength?: number;
  parcelWeight?: number;
  parcelWidth?: number;
};

const ShippingFlowModal = ({ matchingClaimToItems, matchId }) => {
  const partnerId = useCurrentPartner();

  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);
  const setArtifactToMatch = useMatchingStore(state => state.setArtifactToMatch);
  const currentCandidate = useMatchingStore(state => state.currentCandidate);
  const showConfirmModal = useMatchingStore(state => state.showConfirmModal);
  const showShippingModal = useMatchingStore(state => state.showShippingModal);
  const showSuccessModal = useMatchingStore(state => state.showSuccessModal);
  const setShowConfirmModal = useMatchingStore(state => state.setShowConfirmModal);
  const setShowShippingModal = useMatchingStore(state => state.setShowShippingModal);
  const setShowSuccessModal = useMatchingStore(state => state.setShowSuccessModal);
  const setNextClaimToMatch = useMatchingStore(state => state.setNextClaimToMatch);

  const currentItem = (matchingClaimToItems ? currentCandidate : artifactToMatch) as PartnerItem;
  const isXs = isXsQuery();
  const isMd = isMdQuery();
  const { partnerUserLocation } = usePartnerLocationStore();
  const acceptMatch = useSendAcceptMatch(partnerId);
  const [getClaims, { data: claims, loading: isClaimsLoading }] = useGetPartnerClaims();
  const preferredUnits = useLocationPreferredUnits(partnerId);

  const {
    clearErrors,
    control,
    handleSubmit,
    reset: shippingDetailFormReset,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ShippingDetailFormInputs>({
    criteriaMode: "all",
    mode: "onSubmit",
    defaultValues: {
      parcelDimensions: undefined,
      parcelHeight: undefined,
      parcelLength: undefined,
      parcelWeight: undefined,
      parcelWidth: undefined,
    },
  });

  const onChangeParcelWeight = e => {
    setValue("parcelWeight", e.target.value);
    if (errors.parcelWeight) {
      trigger("parcelWeight");
    }
  };

  const onChangeParcelLength = e => {
    setValue("parcelLength", e.target.value);
    if (errors.parcelLength) {
      trigger("parcelLength");
    }
    if (errors.parcelDimensions) {
      clearErrors("parcelDimensions");
    }
  };

  const onChangeParcelWidth = e => {
    setValue("parcelWidth", e.target.value);
    if (errors.parcelWidth) {
      trigger("parcelWidth");
    }
    if (errors.parcelDimensions) {
      clearErrors("parcelDimensions");
    }
  };

  const onChangeParcelHeight = e => {
    setValue("parcelHeight", e.target.value);
    if (errors.parcelHeight) {
      trigger("parcelHeight");
    }
    if (errors.parcelDimensions) {
      clearErrors("parcelDimensions");
    }
  };

  useEffect(() => {
    if (!showConfirmModal && !showShippingModal && !showSuccessModal) {
      shippingDetailFormReset();
    }
  }, []);

  useEffect(() => {
    if (claims === null || isClaimsLoading) return;
    const filteredClaims = claims?.results.filter(claim => claim.id !== artifactToMatch?.id);
    if (filteredClaims !== undefined) {
      const nextClaimToMatch = filteredClaims[0];
      setNextClaimToMatch(nextClaimToMatch);
      setShowShippingModal(false);
      setShowSuccessModal(true);
    }
  }, [claims]);

  if (!matchId || !currentItem) return null;

  return (
    <Modal
      bottomBtnText={"Cancel"}
      bottomBtnOnClick={() => {
        setShowShippingModal(false);
        setShowConfirmModal(false);
      }}
      header="Add shipping details"
      subHeader={
        !isXs
          ? "Add item weight and size below. These details are used to calculate shipping rates."
          : "This enables your customer to have this item shipped at checkout."
      }
      show={showShippingModal}
      showXButton
      onXButton={() => {
        setShowShippingModal(false);
      }}
      topBtnOnClick={handleSubmit(shippingDetails => {
        sendEditItem({
          partner_id: partnerId,
          item_id: currentItem.id,
          item: {
            parcel_height: shippingDetails?.parcelHeight?.toString(),
            parcel_length: shippingDetails?.parcelLength?.toString(),
            parcel_weight: shippingDetails?.parcelWeight?.toString(),
            parcel_width: shippingDetails?.parcelWidth?.toString(),
            storage_location: (currentItem?.storage_location as PartnerStorageLocation)?.id,
          },
        })
          .then(() => {
            acceptMatch.mutate(
              { partnerId, matchId },
              {
                onSuccess: () => {
                  getClaims({
                    partnerId: partnerId,
                    filters: {
                      limit: 2,
                      status: [ClaimStatus.HAS_MATCHES],
                      lost_location: partnerUserLocation.id,
                    },
                  });
                },
              },
            );
          })
          .then(() => {
            // TODO: This is probably not right
            artifactToMatch && setArtifactToMatch(artifactToMatch);
          })
          .catch(error => {
            const message = JSON.parse(error.message);
            if (message.parcel_height?.length > 0) {
              setError("parcelHeight", {
                type: "custom",
                message: message.parcel_height,
              });
            }
            if (message.parcel_length?.length > 0) {
              setError("parcelLength", {
                type: "custom",
                message: message.parcel_length,
              });
            }
            if (message.parcel_weight?.length > 0) {
              setError("parcelWeight", {
                type: "custom",
                message: message.parcel_weight,
              });
            }
            if (message.parcel_width?.length > 0) {
              setError("parcelWidth", {
                type: "custom",
                message: message.parcel_width,
              });
            }
            if (message.parcel_dimensions?.length > 0) {
              setError("parcelDimensions", {
                type: "custom",
                message: message.parcel_dimensions,
              });
            }
          });
      })}
      topBtnLoading={acceptMatch.isPending}
      topBtnText="Confirm"
      maxWidth="386px"
      withoutTopMargin
    >
      <div
        className="d-flex flex-column m-auto pb-35"
        style={{
          fontFamily: theme.ui.fontFamilies.default,
        }}
      >
        <MatchedArtifactDetailsSnapshot
          heading="Found item"
          images={currentItem.images}
          name={currentItem.name}
          id={currentItem?.inventoryId}
          storageLocation={(currentItem?.storage_location as PartnerStorageLocation)?.name}
          style={{
            width: "100%",
            marginTop: isMd ? "calc(-2.5rem + 12px)" : "calc(-1.5rem + 12px)",
          }}
        />
        <form className="w-100 px-1 d-flex flex-column">
          <Typography
            className="mt-35 mb-25"
            data-testid="itemWeightLabel"
            as="label"
            fontSize={theme.ui.fontSizes.base}
            color={theme.ui.colors.primary700}
            fontWeight={theme.ui.fontWeights.bold}
            lineHeight={1.5}
          >
            Item weight
            <Typography
              as="span"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.primary700}
              fontWeight={500}
              lineHeight={1.7}
            >
              {" (including packaging)"}
            </Typography>
          </Typography>
          <Controller
            render={({ field }) => (
              <StyledFloatingLabel
                {...field}
                label={`Weight (${preferredUnits.weight})`}
                ariaLabel="Weight"
                placeholder={`Weight (${preferredUnits.weight})`}
                autoFocus
                errors={errors?.parcelWeight}
                isValidated={errors.parcelWeight}
                min={0}
                onChange={onChangeParcelWeight}
                required
                type="number"
                tabIndex={0}
                rounded
              />
            )}
            name="parcelWeight"
            control={control}
            defaultValue={undefined}
            rules={{
              required: "Please enter the item weight.",
              min: { value: 1, message: "Must be greater than 0" },
            }}
          />
          <Typography
            className="mt-35 mb-25"
            data-testid="itemSizeLabel"
            as="label"
            fontSize={theme.ui.fontSizes.base}
            color={theme.ui.colors.primary700}
            fontWeight={theme.ui.fontWeights.bold}
            lineHeight={1.5}
          >
            Item size
            <Typography
              as="span"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.primary700}
              fontWeight={500}
              lineHeight={1.7}
            >
              {" (including packaging)"}
            </Typography>
          </Typography>
          <div className="d-block d-sm-flex">
            <Controller
              render={({ field }) => (
                <StyledFloatingLabel
                  {...field}
                  className="flex-grow-1 col-sm-4 me-sm-2 mb-25 mb-sm-2"
                  label={`Length (${preferredUnits.dimensions})`}
                  ariaLabel="Length"
                  placeholder={`Length (${preferredUnits.dimensions})`}
                  errors={errors?.parcelLength}
                  isValidated={errors.parcelLength}
                  min={0}
                  onChange={onChangeParcelLength}
                  required
                  type="number"
                  tabIndex={0}
                  rounded
                />
              )}
              name="parcelLength"
              control={control}
              rules={{
                required: "Enter length",
                min: { value: 1, message: "Must be greater than 0" },
              }}
            />
            <Controller
              render={({ field }) => (
                <StyledFloatingLabel
                  {...field}
                  className="flex-grow-1 col-sm-4 me-sm-2 mb-25 mb-sm-2"
                  label={`Width (${preferredUnits.dimensions})`}
                  ariaLabel="Width"
                  placeholder={`Width (${preferredUnits.dimensions})`}
                  errors={errors?.parcelWidth}
                  isValidated={errors.parcelWidth}
                  min={0}
                  onChange={onChangeParcelWidth}
                  required
                  type="number"
                  tabIndex={0}
                  rounded
                />
              )}
              name="parcelWidth"
              control={control}
              rules={{
                required: "Enter width",
                min: { value: 1, message: "Must be greater than 0" },
              }}
            />
            <Controller
              render={({ field }) => (
                <StyledFloatingLabel
                  {...field}
                  className="flex-grow-1"
                  label={`Height (${preferredUnits.dimensions})`}
                  ariaLabel="Height"
                  placeholder={`Height (${preferredUnits.dimensions})`}
                  errors={errors?.parcelHeight}
                  isValidated={errors.parcelHeight}
                  min={0}
                  onChange={onChangeParcelHeight}
                  required
                  type="number"
                  tabIndex={0}
                  rounded
                />
              )}
              name="parcelHeight"
              control={control}
              rules={{
                required: "Enter height",
                min: { value: 1, message: "Must be greater than 0" },
              }}
            />
          </div>
          {errors.parcelDimensions && (
            <Typography
              className="text-center invalid-feedback d-block"
              as="span"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.error500}
              fontWeight={500}
              lineHeight={1.7}
            >
              {errors.parcelDimensions?.message}
            </Typography>
          )}
          <Typography
            className="text-center d-inline-block w-100 mt-25"
            as="span"
            fontSize={theme.ui.fontSizes.sm}
            color={theme.ui.colors.gray600}
            fontWeight={400}
            lineHeight="20px"
          >
            Length is the longest dimension.
          </Typography>
        </form>
      </div>
    </Modal>
  );
};

export default ShippingFlowModal;
