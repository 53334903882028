import { PartnerRoutesEnum } from "client/components/Routes";
import { Tab, Tabs } from "client/ui-components/Tabs";
import React from "react";
import { useLocation } from "react-router-dom";

export default function InventoryTabs() {
  const currentRoute = useLocation();

  return (
    <Tabs
      containerStyle={{
        maxWidth: 1072,
        margin: "auto",
        flexGrow: 1,
        padding: "0 16px",
        boxSizing: "content-box",
      }}
    >
      <Tab
        name="Active"
        isSelected={currentRoute.pathname === PartnerRoutesEnum.INVENTORY}
        tabNumber={0}
        url={PartnerRoutesEnum.INVENTORY}
      />
      <Tab
        name="Unclaimed"
        isSelected={currentRoute.pathname === PartnerRoutesEnum.INVENTORY_UNCLAIMED}
        tabNumber={1}
        url={PartnerRoutesEnum.INVENTORY_UNCLAIMED}
      />
      <Tab
        name="Archived"
        isSelected={currentRoute.pathname === PartnerRoutesEnum.INVENTORY_ARCHIVE}
        tabNumber={2}
        url={PartnerRoutesEnum.INVENTORY_ARCHIVE}
      />
    </Tabs>
  );
}
