import React from "react";
import history from "../context/history";
import theme from "../theme";

import {
  CircleCheckIcon,
  DownChevronSolid,
  FileSolidIcon,
  HomeIcon,
  InventoryIcon,
  JobTitleIcon,
  LogoutIcon,
  MatchingIcon,
  MetricsIcon,
  ReportIcon,
  UserIcon,
} from "../assets/icons/icons";

export interface MenuItem {
  name: string;
  icon: React.ReactNode;
  onClick: () => void;
  color?: string;
  url?: string;
  testId?: string;
}

export enum UserMenuItemNames {
  CLAIMS = "Claims",
  CONTACT_DETAILS = "Contact details",
  COMPLETED_RETURNS = "Completed returns",
  LOGOUT = "Logout",
}

export enum PartnerMenuItemNames {
  OVERVIEW = "Overview",
  POTENTIAL_MATCHES = "Potential matches",
  METRICS = "Metrics",
  INVENTORY = "Inventory",
  CLAIMS = "Claims",
  SWITCH_ACCOUNT = "Switch account",
  LOGOUT = "Logout",
}

// Menu items for the partner mobile menu
export const PartnerMenuItems: Array<MenuItem> = [
  {
    name: PartnerMenuItemNames.OVERVIEW,
    icon: (
      <HomeIcon
        accessibilityTitle="Go to Overview"
        ariaHidden={true}
        titleId="GoToOverviewTitle"
        data-testid="overviewIcon"
      />
    ),
    url: "/partner/overview",
    onClick: () => {
      history.push("/partner/overview");
    },
    testId: "overviewLink",
  },
  {
    name: PartnerMenuItemNames.POTENTIAL_MATCHES,
    icon: (
      <MatchingIcon
        color1={theme.colors.white}
        color2={theme.colors.white}
        accessibilityTitle="Go to Potential matches"
        ariaHidden={true}
        titleId="GoToPotentialMatchesTitle"
        data-testid="potentialMatchesIcon"
      />
    ),
    url: "/partner/matches",
    onClick: () => {
      history.push("/partner/matches");
    },
    testId: "potentialMatchesLink",
  },
  {
    name: PartnerMenuItemNames.METRICS,
    icon: (
      <MetricsIcon
        accessibilityTitle="Go to Metrics"
        ariaHidden={true}
        titleId="GoToMetricsTitle"
        data-testid="metricsIcon"
      />
    ),
    url: "/partner/metrics",
    onClick: () => {
      history.push("/partner/metrics");
    },
    testId: "metricsLink",
  },
  {
    name: PartnerMenuItemNames.INVENTORY,
    icon: (
      <InventoryIcon
        accessibilityTitle="Go to Inventory"
        ariaHidden={true}
        titleId="GoToInventoryTitle"
        data-testid="inventoryIcon"
      />
    ),
    url: "/partner/inventory",
    onClick: () => {
      history.push("/partner/inventory");
    },
    testId: "inventoryLink",
  },
  {
    name: PartnerMenuItemNames.CLAIMS,
    icon: (
      <ReportIcon
        accessibilityTitle="Go to Claims"
        ariaHidden={true}
        titleId="GoToClaimsTitle"
        data-testid="claimsIcon"
      />
    ),
    url: "/partner/claims",
    onClick: () => {
      history.push("/partner/claims");
    },
    testId: "claimsLink",
  },
  {
    name: PartnerMenuItemNames.SWITCH_ACCOUNT,
    icon: (
      <JobTitleIcon
        color={theme.colors.primaryBlue}
        accessibilityTitle="Switch account"
        ariaHidden={true}
        titleId="MobileMenuSwitchAccountTitle"
      />
    ),
    color: theme.colors.primaryBlue,
    onClick: () => {},
  },
  {
    name: PartnerMenuItemNames.LOGOUT,
    icon: (
      <LogoutIcon
        color={theme.colors.red}
        accessibilityTitle="Logout"
        ariaHidden={true}
        titleId="MobileMenuLogoutTitle"
      />
    ),
    color: theme.colors.red,
    url: "/sign-out",
    onClick: () => {
      history.push("/sign-out");
    },
  },
];

// For the top bar menu, exclude logout
export const PartnerMenuItemsNoLogout = [
  ...PartnerMenuItems.slice(0, 5),
  {
    name: "More",
    icon: (
      <DownChevronSolid
        accessibilityTitle="More"
        ariaHidden={true}
        titleId="MoreDropdownChevronTitle"
        data-testid="moreMenuIcon"
      />
    ),
    onClick: () => {},
  },
];

// Menu items for the customer mobile menu
export const CustomerMenuItems: Array<MenuItem> = [
  {
    name: UserMenuItemNames.CLAIMS,
    icon: (
      <FileSolidIcon
        accessibilityTitle="Go to Claims"
        ariaHidden={true}
        titleId="GoToAllClaimsTitle"
        color={theme.colors.gray3}
      />
    ),
    url: "/claims",
    onClick: () => {
      history.push("/claims");
    },
  },
  {
    name: UserMenuItemNames.CONTACT_DETAILS,
    icon: (
      <UserIcon
        accessibilityTitle="Go to Contact details"
        ariaHidden={true}
        titleId="GoToContactDetailsTitle"
        color={theme.colors.gray3}
      />
    ),
    url: "/contact-details",
    onClick: () => {
      history.push("/contact-details");
    },
  },
  {
    name: UserMenuItemNames.COMPLETED_RETURNS,
    icon: (
      <CircleCheckIcon
        accessibilityTitle="Go to completed returns"
        ariaHidden={true}
        titleId="GoToCompletedClaimsTitle"
        color={theme.colors.gray3}
      />
    ),
    url: "/completed-returns", //url: UserRoutesEnum.CLAIMS_RETURNED,  is crashing the app for some reason and I am not sure why
    onClick: () => {
      history.push("/completed-returns"); // history.push(UserRoutesEnum.CLAIMS_RETURNED);
    },
  },
  {
    name: UserMenuItemNames.LOGOUT,
    icon: (
      <LogoutIcon
        color={theme.colors.red}
        accessibilityTitle="Logout"
        ariaHidden={true}
        titleId="LogoutUserProfileTitle"
      />
    ),
    color: theme.colors.red,
    url: "/sign-out",
    onClick: () => {
      history.push("/sign-out");
    },
  },
];
