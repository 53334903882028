import classNames from "classnames";
import { useSendRejectUserMatch } from "client/actions/userMatches";
import { ClaimDetailsContext } from "client/context/ClaimDetails";
import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import history from "../../../../../../../context/history";
import { useClickAway } from "../../../../../../../hooks";
import {
  ButtonWrapper,
  CloseButton,
  ModalContent,
  ModalDialog,
  ModalHeader,
  ModalWrapper,
  StyledButton,
  StyledCrossSolidIcon,
  StyledHeading,
} from "./styles";

type Props = {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

export default function ConfirmRejectClaimModal({
  isOpened,
  setIsOpened,
}: Props): JSX.Element | null {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = useState<{ isReady: boolean }>({ isReady: false });
  const { claim } = useContext(ClaimDetailsContext);
  const [rejectUserMatch, { data: rejectUserMatchRes, error: rejectUserMatchError }] =
    useSendRejectUserMatch();

  useClickAway(modalRef, () => setIsOpened(false));

  useEffect(() => {
    setState(s => ({ ...s, isReady: true }));
  }, []);

  useEffect(() => {
    if (!rejectUserMatchRes) {
      return;
    }

    if (!rejectUserMatchError) {
      history.push("/claims");
    } else {
      toast.error("There was an error rejecting the match. Please try again.");
    }
  }, [rejectUserMatchRes]);

  return !state.isReady
    ? null
    : createPortal(
        <ModalWrapper
          className={classNames("modal fade", isOpened && "show", "modal-dialog-centered")}
          id="confirmRejectMatchModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="rejectMatchModal"
          aria-hidden={!isOpened}
          isOpened={isOpened}
        >
          <ModalDialog ref={modalRef} className="modal-dialog" role="document">
            <ModalContent className="modal-content">
              <ModalHeader className="modal-header justify-content-end">
                <CloseButton
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-testid="closeModalXButton"
                  onClick={() => setIsOpened(false)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8332 1.16675L1.1665 12.8334M1.1665 1.16675L12.8332 12.8334"
                      stroke="#00174A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </CloseButton>
              </ModalHeader>

              <div className="modal-body p-0">
                <div className="text-center">
                  <StyledCrossSolidIcon
                    accessibilityTitle="Reject red cross"
                    titleId="RejectMatchRedCross"
                  />
                </div>
                <StyledHeading
                  style={{ lineHeight: "28px" }}
                  variant="h3"
                  textAlign="center"
                  className="mb-4 mb-lg-45"
                >
                  Confirm it's not yours, please
                </StyledHeading>

                <ButtonWrapper className="d-flex flex-column gap-25">
                  <StyledButton
                    size="2xl"
                    aria-label="It's not mine"
                    variant="danger"
                    onClick={() => {
                      if (claim !== undefined && claim !== null) {
                        rejectUserMatch(claim.match);
                      }
                    }}
                  >
                    Yes, it's not mine
                  </StyledButton>
                  <StyledButton
                    size="2xl"
                    aria-label="Nevermind"
                    data-testid="nevermindButton"
                    variant="text"
                    onClick={() => setIsOpened(false)}
                  >
                    Nevermind
                  </StyledButton>
                </ButtonWrapper>
              </div>
            </ModalContent>
          </ModalDialog>
        </ModalWrapper>,
        document.body,
        "modal",
      );
}
