import styled from "styled-components";
import { Title } from "../../../../../components/typography";

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;

export const InternalWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr calc(70px + 14px + 2 * (${props => props.theme.heights.button}));
  justify-items: center;
  height: 100%;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 50px 0 40px 0;
    width: 334px;
    margin: auto;
  }
`;

export const LargeBackground = styled.div`
  background: ${props => props.theme.colors.gray6};
  display: flex;
  justify-content: center;
`;

export const OuterWrapper = styled.div`
  height: 100%;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    background-color: ${props => props.theme.colors.white};
    width: 800px;
    margin-top: -1.2rem;
    border-radius: ${props => props.theme.borderRadius.lg};
  }
`;

export const SmallBackground = styled.div`
  background: ${props => props.theme.colors.black};
  height: 100%;
  width: 100%;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    align-self: end;
  }
`;

export const StyledDescription = styled.div`
  color: ${props => props.theme.colors.white};
  width: 250px;
  text-align: center;
  line-height: 1.75;
  font-weight: 500;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    color: ${props => props.theme.colors.black};
  }
`;
interface StyledImageProps {
  imageUrl: string;
}

export const StyledImage = styled.div<StyledImageProps>`
  width: 100%;
  max-width: 800px;
  border: none;
  border-radius: ${props => props.theme.borderRadius.lg};
  height: 190px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: 225px;
  }
`;

export const StyledTitle = styled(Title)`
  color: ${props => props.theme.colors.white};
  text-align: center;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    color: ${props => props.theme.colors.black};
  }
`;

export const TopHalf = styled.div`
  grid-row: 1;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;
