import styled from "styled-components";

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  flex-wrap: wrap;
  gap: 12px;
`;
export const FiltersContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const ToolbarActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-wrap: nowrap;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  &:has(div > div:only-child) {
    margin-left: auto;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  margin-left: auto;

  & > * {
    background-color: transparent;
    padding: 0;
  }
  @media (max-width: 768px) {
    margin: auto;
  }
  &:has(div:only-child) {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const HorizontalDivider = styled.hr`
  margin: 0;
  margin-bottom: 12px;
  border: 1px solid ${props => props.theme.ui.colors.gray500} !important;
`;

export const SmallText = styled.span`
  color: ${props => props.theme.ui.colors.gray600};

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
