import React from "react";
import { Column } from "./";
import { HeaderRowWrapper, StyledCol } from "./styles";

interface HeaderRowProps {
  className?: string;
  columns: Column[];
  flex?: boolean;
  justifyEnd?: boolean;
}

const HeaderRow: React.FC<HeaderRowProps> = ({ className, columns, flex, justifyEnd }) => {
  return (
    <HeaderRowWrapper className={`${className ?? ""} mx-0 px-45 pb-2 pt-0 border-bottom`}>
      {columns.map((column, index, arr) => {
        const justifyContent = justifyEnd && index === arr.length - 1 ? "justify-content-end" : "";
        return (
          <StyledCol
            key={index}
            className={`ps-0 d-flex ${justifyContent} ${
              !flex && column.size ? `col-lg-${column.size}` : "col"
            }`}
            flex={flex ?? false ? column.size : 0}
          >
            {column.name}
          </StyledCol>
        );
      })}
    </HeaderRowWrapper>
  );
};

export default HeaderRow;
