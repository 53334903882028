import React from "react";
import { AuthProvider } from "./auth";
import { UserProvider } from "./User";

const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
};

export default ContextProviders;
