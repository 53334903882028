import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  padding-top: 28px;
  background-color: ${({ theme }) => theme.ui.colors.primary700};
  color: ${({ theme }) => theme.ui.colors.white};
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.xxl}) {
    padding: 28px 40px;
  }
`;

export const StyledHeading = styled.h3`
  color: #fff;
  font-family: ${({ theme }) => theme.ui.fontFamilies.heading};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.xxl}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const ButtonAction = styled.button`
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 1px solid #dce1e8;
  background: #fff;
  width: 156px;
  height: 60px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #00174a;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

export const ContainerMatches = styled.div`
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background-color: white;
  margin-top: 10px;
  border-radius: 16px;
  z-index: 1;
  margin-left: -45px;
`;

export const CardMatch = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  align-self: stretch;
  color: var(--primary-700-for-text, #00174a);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  gap: 12px;
`;
