import Loader from "client/components/Loader";
import { isMdQuery } from "client/helpers/mediaQuery";
import useGetImagesWithClaimId from "client/hooks/data/user/useGetImagesWithClaimId";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ClaimDetailsContext } from "../../../../../../context/ClaimDetails";
import history from "../../../../../../context/history";
import theme from "../../../../../../theme";
import { Heading, Text } from "../../../../../../ui-components";
import {
  ButtonContainer,
  FloatingCheckSolidIcon,
  StyledButton,
  StyledCheckSolidIcon,
  Thumbnail,
  ThumbnailContainer,
} from "./styles";

export default function Confirmation(): JSX.Element {
  const isMd = isMdQuery();
  const { isLoading: isClaimLoading } = useContext(ClaimDetailsContext);
  const { claimId } = useParams<{ claimId: string }>();
  const { data: claimImages, isLoading: claimImagesLoading } = useGetImagesWithClaimId(claimId);
  const isLoading = isClaimLoading || claimImagesLoading;

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="text-center">
        {claimImages !== undefined && claimImages?.results && claimImages?.results.length >= 1 ? (
          <ThumbnailContainer>
            <Thumbnail src={claimImages?.results[0].image} />
            <FloatingCheckSolidIcon
              accessibilityTitle="Success checkmark"
              titleId="SuccessModalCheckmarkTitle"
            />
          </ThumbnailContainer>
        ) : (
          <StyledCheckSolidIcon
            accessibilityTitle="Success checkmark"
            titleId="SuccessModalCheckmarkTitle"
          />
        )}
      </div>
      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-center">
        Your claim has been canceled!
      </Heading>
      <Text
        className="mb-25 mb-md-3 text-center"
        fontSize={theme.ui.fontSizes.base}
        color={theme.ui.colors.primary700}
        fontWeight={theme.ui.fontWeights.bold}
      >
        You've successfully canceled your claim!
      </Text>
      <ButtonContainer>
        <StyledButton
          aria-label="goToClaimsButton"
          size={isMd ? "xl" : "2xl"}
          className="mt-3"
          data-testid="backButton"
          onClick={() => history.push("/claims")}
        >
          Go to claims
        </StyledButton>
      </ButtonContainer>
    </>
  );
}
