import { isLgQuery, isMdQuery, isSmQuery, isXsQuery } from "../helpers/mediaQuery";

export function responsiveSizeValue(breakpoints: { [key: string]: string | number }) {
  const isXs = isXsQuery();
  const isSm = isSmQuery();
  const isMd = isMdQuery();
  const isLg = isLgQuery();

  if (isLg && breakpoints["lg"]) return breakpoints["lg"];
  if (isMd && breakpoints["md"]) return breakpoints["md"];
  if (isSm && breakpoints["sm"]) return breakpoints["sm"];
  if (isXs && breakpoints["xs"]) return breakpoints["xs"];

  return breakpoints["base"];
}
