import styled from "styled-components";
import { CloseBig, SvgProps } from "../../assets/icons/icons";
import { LegacyTextComponent, Title } from "../typography";

export const BackgroundOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  z-index: ${props => props.theme.zIndexes.modal};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  width: 100%;
  max-width: 334px;
  text-align: center;
  margin: 0 auto;
`;

export const Divider = styled.hr`
  border-top: 1px solid ${props => props.theme.colors.gray3};
  width: 100%;
  opactiy 1;
`;

export const InteriorModalWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div<{ color?: string }>`
  background-color: ${props => props.color || props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.lg};
  position: relative;
  line-height: 3.125;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 586px;
  }
`;

export const StyledCloseBig = styled(CloseBig)<SvgProps>`
  color: ${props => props.theme.colors.gray3};
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  align-self: flex-end;
`;

export const ScrollableContainer = styled.div<{ maxWidth?: string }>`
  overflow: auto;
  margin: 0 auto;
  text-align: center;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`}
`;

export const StyledText = styled(LegacyTextComponent)`
  text-align: center;
  line-height: 1.5;
  color: ${props => props.theme.colors.gray1};
`;

export const StyledTitle = styled(Title)`
  font-weight: ${props => props.theme.fontWeights.button};
  line-height: 1.33;
`;

export const MobileBackgroundOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  display: flex;
  z-index: ${props => props.theme.zIndexes.modal};
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -100vh;
  transition: background-color 0.5s;
  visibility: hidden;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: center;
  }

  &.visible {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    visibility: visible;
  }
`;

export const MobileModalWrapper = styled.div<{ color?: string }>`
  background-color: ${props => props.color || props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.lg} ${props => props.theme.borderRadius.lg} 0 0;
  position: fixed;
  bottom: -100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: bottom 0.5s;
  padding-top: 64px;

  &.visible {
    bottom: 0px;
    max-height: 90%;
  }
`;
