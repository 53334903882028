import styled from "styled-components";
import { LightLabel, TextBold } from "../typography";

export const StyledLabel = styled(LightLabel)<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.gray3};
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const StyledTextBold = styled(TextBold)<{ small?: boolean }>`
  font-size: ${props => (props.small ? props.theme.fontSizes.sm : props.theme.fontSizes.default)};
`;
