import React from "react";
import ArrowRight from "../../../../assets/ui-icons/ArrowRight";
import { colors, fontWeights, ui } from "../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../ui-components";
import { callToActionCopy } from "../../content";
import { Content, Wrapper } from "./styles";

export default function CallToAction() {
  return (
    <Wrapper className="mt-6 mt-lg-8">
      <Content className="px-35 px-md-45">
        <Heading className="mb-25 mb-md-3 text-center" as="h2">
          {callToActionCopy.title}
        </Heading>

        <Text
          className="mb-35 mb-sm-45 mb-md-4 text-center"
          color={colors.primary700}
          fontSize={ui.description.responsive.fontSize}
          fontWeight={fontWeights.normal}
          lineHeight={ui.description.responsive}
        >
          {callToActionCopy.body}
        </Text>

        <Button
          aria-label="schedule-a-chat"
          className="mt-lg-1"
          href="https://share.hsforms.com/1olnAZ_DYS8amUoozJO7uaAc60y2"
          rel="noopener noreferrer"
          target="_blank"
          icon={
            <ArrowRight
              accessibilityTitle="Schedule a chat"
              aria-hidden={true}
              role="graphics-symbol"
              titleId="schedule-a-chat"
            />
          }
          iconPosition="end"
          size="2xl"
        >
          {callToActionCopy.cta}
        </Button>
      </Content>
    </Wrapper>
  );
}
