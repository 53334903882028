import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthState } from "../context/auth";

function AnonRedirect({ ...rest }) {
  const { user, partner } = useAuthState();
  return (
    <Redirect
      {...rest}
      to={{
        pathname: user?.id ? (partner ? "/partner/overview" : "/claims") : "/",
        state: { from: location.href },
      }}
    />
  );
}

export default AnonRedirect;
