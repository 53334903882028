const getQueryString = (filters: object): string => {
  if (Object.keys(filters).length === 0) {
    return "";
  } else {
    let queryString = "?";
    queryString += Object.keys(filters)
      .map((key: string) => {
        if (filters[key] instanceof Array) {
          return filters[key].map((value: string) => `${key}=${value}`).join("&");
        }
        return key + "=" + filters[key];
      })
      .join("&");
    return queryString;
  }
};

export default getQueryString;
