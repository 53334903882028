import pluralize from "pluralize";
import { toast } from "react-toastify";

export const toastUpdateSuccess = (count: number) => {
  return toast.success(
    `${count} ${pluralize("item", count)} ${count > 1 ? "have" : "has"} been successfully archived.`,
  );
};

export const toastUpdateFailure = (count: number) => {
  return toast.error(`Failed to archive ${count} ${pluralize("item", count)}.`);
};

export const toastResetSuccess = (count: number, name: string) => {
  return toast.success(`${count} ${pluralize("item", count)} removed from the ${name}`);
};

export const toastResetFailure = (count: number, name: string) => {
  return toast.error(`Failed to remove ${count} ${pluralize("item", count)} from the ${name}`);
};
