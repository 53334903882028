import styled from "styled-components";

export const DetailWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.gray8};
  border-radius: ${props => props.theme.borderRadius.lg};

  p {
    line-height: ${props => props.theme.fontSizes.lg};
  }

  .name {
    font-weight: ${props => props.theme.fontWeights.semiBold};
  }
`;

export const Price = styled.div`
  border-left: 1px solid ${props => props.theme.colors.gray8};
`;
