import usePartnerCategory from "client/hooks/data/partner/usePartnerCategory";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPartnerClaimFromId } from "../../actions/partnerClaims";
import { Category, PartnerClaim } from "../../types";
import useCurrentPartner from "../../utils/useCurrentPartner";

type PartnerClaimContextType = {
  claim: PartnerClaim | null;
  subMenuHeight: number;
  setSubMenuHeight: React.Dispatch<React.SetStateAction<PartnerClaimContextType["subMenuHeight"]>>;
  category: Category | null;
};

export const PartnerClaimContext = createContext<PartnerClaimContextType>({
  claim: null,
  category: null,
  subMenuHeight: 0,
  setSubMenuHeight: () => undefined,
});

export default function PartnerClaimContextProvider({ children }: { children: React.ReactNode }) {
  const partnerId = useCurrentPartner();
  const { claimId } = useParams<{ claimId: string }>();
  const [getClaim, { data: claim, loading: isClaimLoading }] = useGetPartnerClaimFromId();
  const [subMenuHeight, setSubMenuHeight] = useState<PartnerClaimContextType["subMenuHeight"]>(0);

  useEffect(() => {
    if (!isClaimLoading && claim === null) {
      getClaim({ claimId, partnerId });
    }
  }, [claimId, isClaimLoading, claim, partnerId]);

  const categoryId = claim?.category;
  const category = usePartnerCategory(categoryId).data ?? null;

  const values = useMemo<PartnerClaimContextType>(
    () => ({
      claim,
      category,
      subMenuHeight,
      setSubMenuHeight,
    }),
    [claim, category, subMenuHeight],
  );

  return <PartnerClaimContext.Provider value={values}>{children}</PartnerClaimContext.Provider>;
}
