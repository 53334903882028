import styled from "styled-components";
import svg from "../../../assets/ui-icons/CityBanner/city-banner.svg";
import Button from "../../../ui-components/Button";

export const BackgroundWrapper = styled.div`
  background-image: url(${svg});
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto 90px;
  min-height: calc(100vh - ${props => props.theme.ui.heights.navBase});
  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    background-position: center bottom;
    background-size: auto 159px;
  }
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    min-height: calc(100vh - ${props => props.theme.ui.heights.navLg});
  }
`;
export const StyledButton = styled(Button)`
  max-width: 372px;
`;

export const ContentWrapper = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 128px;
  transition:
    max-width,
    padding-top 0.5s ease-in-out;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-top: 164px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 576px;
  }
`;
