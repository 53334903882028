import Compressor from "compressorjs";

const internalCompressImage = (image: any, success: any, error?: any): any => {
  new Compressor(image, {
    quality: 0.8,
    maxWidth: 1080,
    maxHeight: 1080,
    convertSize: 1500000,
    resize: "contain",
    success: success,
    error: error,
  });
};

export const compressImage = (image: any, success: any): any => {
  internalCompressImage(image, success);
};

export const compressImageAsync = (image: any): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    const onSuccess = (compressedImage: File | Blob) => resolve(compressedImage);
    const onError = (error: Error) => reject(error);
    internalCompressImage(image, onSuccess, onError);
  });
};
