import styled from "styled-components";

export const MediaWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 9px;
    @media (min-width: ${props => props.theme.ui.breakpoints.sm}) {
      border-radius: 11px;
    }
    @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
      border-radius: 19.2px;
    }
  }
`;
