import classNames from "classnames";
import DefaultError from "client/ui-components/DefaultError";
import React from "react";
import { ArchiveTable } from "./components";
import ArchiveToolbar from "./components/ArchiveToolbar";
import { useArchivedItems } from "./helpers/hooks";
import { Wrapper } from "./styles";

type InventoryProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

const Inventory = ({ className, ...props }: InventoryProps) => {
  const { error, isLoading, items, count } = useArchivedItems();
  if (error) return <DefaultError />;

  return (
    <Wrapper {...props} className={classNames("mb-5 px-0", className)}>
      <ArchiveToolbar count={count ?? 0} />
      <ArchiveTable items={items} isLoading={isLoading} count={count ?? 0} />
    </Wrapper>
  );
};

export default Inventory;
