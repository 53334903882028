import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.35);
  background-color: ${props => props.theme.ui.colors.white};
  padding-bottom: 32px;
`;

export const Container = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 800px;
  }
`;
