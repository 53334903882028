import React from "react";
import TextLabelValue from "../../../components/TextLabelValue/TextLabelValue";
import { useAuthState } from "../../../context/auth";
import { isLgQuery } from "../../../helpers/mediaQuery";
import { Wrapper } from "../AllClaims/styles";

export const ContactDetails: React.FC = () => {
  const isLg = isLgQuery();
  const { user } = useAuthState();

  return (
    <Wrapper className="zIndex1 mx-md-auto mx-lg-0 col styled-content-container move-up p-35">
      <TextLabelValue className="ps-3 mb-4" label="Name" value={user.full_name} />{" "}
      {/* might need mb-4 */}
      <TextLabelValue
        className={`ps-3 ${!user.phone_number && isLg ? "pb-0" : "mb-4"}`} // might need another class
        label="Email"
        value={user.email}
      />
      {user.phone_number && (
        <TextLabelValue
          className={`ps-3 ${isLg ? "pb-0" : "mb-4"}`} // might need another class
          label="Phone Number"
          value={user.phone_number}
        />
      )}
    </Wrapper>
  );
};

export default ContactDetails;
