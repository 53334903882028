import SavannahHiltonHeadLogo from "../../../client/assets/logos/partnerLogos/savannah-hilton-head-color-logo.svg";
import SyracuseHancockLogo from "../../../client/assets/logos/partnerLogos/syracuse-hancock-color-logo.svg";
import { CircleCheckIcon } from "../../assets/icons/icons";

export const aboveTheFoldCopy = {
  id: 0,
  heading: "Lost & Found Reinvented",
  body: "Lost & Found has been broken for way too long. We've modernized it. Our partners seamlessly return lost items to their customers in a smart, transparent, and user-friendly experience.",
  ctaText: "Schedule a chat",
  ctaPath: "",
};

export const benefitsCopy = {
  title: "Benefits",
  heading: "Why Boomerang?",
  body: "Designed to make your lost & found experience easier, faster and better—so you can focus on your core business.",
  sectionOne: {
    heading: "Deliver a standout lost & found customer experience",
    list: [
      {
        text: "Customers can submit claims on their preferred devices anytime, anywhere",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
      {
        text: "Customers can check on the status of their claim via their user profile, so they feel in control during the entire experience",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
      {
        text: "Customers receive frequent email updates about their open claim, so they're not left in the dark",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
    ],
  },
  sectionTwo: {
    heading: "Return lost & found items faster while saving time & money",
    list: [
      {
        text: "Your team members can log items to your online inventory in less than a minute on any device",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
      {
        text: "Boomerang will discover matches for you, boosting your claim-to-return rate significantly",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
      {
        text: "Boomerang will facilitate the return process, decreasing your workload spent on coordination",
        icon: {
          icon: CircleCheckIcon,
          accessibilityTitle: "Circle Check Icon",
          titleId: "CircleCheckIcon",
        },
      },
    ],
  },
};

export const callToActionCopy = {
  title: "Automate your lost & found",
  body: "Meet with a member of our team to learn how Boomerang can help you deliver a simplified lost & found experience.",
  cta: "Schedule a chat",
};

export const featuresCopy = {
  title: "Features",
  heading: "The Easiest Way To Manage Your Lost & Found",
  firstSection: {
    title: "Better Inventory Management",
    heading: "Simplify your lost & found inventory with better logging",
    body: "Record item details like the description, category, and date and upload up to three images. Our intuitive form allows you to capture these critical details quickly.",
  },
  secondSection: {
    title: "Easier Matching",
    heading: "Match items on autopilot with our smart engine",
    body: "Boomerang does the matching, so you no longer have to spend hours manually matching claims to found items. When there’s a match, your team will be alerted and asked to verify.",
  },
  thirdSection: {
    title: "A Friendly Experience",
    heading: "An experience your guests and team will enjoy",
    body: "Our user-friendly guest experience removes the lost & found black hole. Customers get regular updates from Boomerang, lowering your team's lost & found workload.",
  },
};

export const matchingSectionCopy = {
  heading: "Automate your lost & found",
  body: "Don't spend your time sifting through shelves. We'll do the searching for you.",
};

export const partnerLogosCopy = {
  body: "Our partners are saving time and returning lost & found items faster",
};

interface Testimonial {
  quote: string;
  logo: string;
  author?: string;
}

export const testimonialsCopy: Testimonial[] = [
  {
    quote:
      '"Boomerang saves time during the intake process, improves the communication with our passengers, and has created tremendous efficiency in reuniting passengers with their lost items."',
    logo: SavannahHiltonHeadLogo,
    author: "Savanahh/Hilton Head International Airport",
  },
  {
    quote:
      '"A previously laborious and time-consuming task for our Airport Security Officers has now been streamlined into a singular, well-organized portal, improving the efficiency of our operation."',
    logo: SyracuseHancockLogo,
    author: "Syracuse Hancock International Airport",
  },
];
