import React from "react";

import { StyledSearchResultMagnifyingGlass, Wrapper } from "../NoResultsFound/styles";

import { isXsQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Typography } from "../../../../ui-components";

export default function NoResultsFound() {
  const isSm = isXsQuery();

  return (
    <Wrapper>
      <StyledSearchResultMagnifyingGlass
        accessibilityTitle="SearchResultsMagnifyingGlassIcon"
        titleId="SearchResultsMagnifyingGlassIcon"
        className="mb-3"
      />

      <Typography
        heading
        as="h3"
        fontWeight={theme.fontWeights.bold}
        fontSize={isSm ? "32px" : "24px"}
        color={theme.ui.colors.primary700}
        lineHeight={isSm ? 1.25 : 1.35}
        textAlign="center"
      >
        Lost & found global search
      </Typography>
      <Typography
        as="p"
        fontWeight={theme.fontWeights.light}
        fontSize="16px"
        color={theme.ui.colors.primary700}
        lineHeight={1.5}
        textAlign="center"
      >
        Uncover what you're seeking by typing three or more characters. Our intuitive platform
        ensures your search is met with instant results, designed to save you time and effort.
      </Typography>
    </Wrapper>
  );
}
