export const lostItemCategoryCopy = {
  heading: "Which category does it fall under?",
  subheading: "Lost something?",
  supportingText: "Select the category your item matches most",
  categoryRequiredErrorMessage: "Category is required.",
  previousButton: "Go back",
  previousButtonUpdate: "Cancel",
  nextButton: "Next",
  nextButtonUpdate: "Update",
};
