import styled from "styled-components";
import cityBanner from "../../../../assets/ui-icons/CityBanner/city-banner.svg";
import Text from "../../../../ui-components/Text";

export const Image = styled.img`
  width: 150px;
  height: 150px;

  margin-bottom: 20px;

  transition: all 0.3s ease-in-out;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    width: 200px;
    height: 200px;
  }
`;

export const TeamDetails = styled.div`
  row-gap: 70px;
  padding-bottom: 64px;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-bottom: 48px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-bottom: 0px;
    row-gap: 40px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    row-gap: 0;
    padding-bottom: 64px;
    column-gap: 40px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-bottom: 75px;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 64px;

  transition: margin-bottom 0.3s ease-in-out;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xs}) {
    margin-bottom: 76px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 654px;
  }
`;

export const Wrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 90px;
  padding-inline: 20px;

  background-image: url(${cityBanner});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 90px;

  transition: padding 0.2s ease-in;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xs}) {
    padding-top: 76px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-top: 64px;
    padding-bottom: 88px;
    padding-inline: 40px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding-bottom: 185px;
    background-size: auto 185px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-top: 96px;
  }
`;

export const Subtitle = styled(Text)`
  max-width: 654px;
  font-weight: 400;
  font-size: ${props => props.theme.ui.fontSizes.base};
  line-height: 1.5;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    font-size: ${props => props.theme.ui.fontSizes.lg};
  }
`;
