import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCsrfToken, useSendOtp } from "../../../../actions/users";
import { EmailIcon } from "../../../../assets/icons/icons";
import { UserLoginContext } from "../../../../context";
import { Button, Form } from "../../../../ui-components";
import useQueryString from "../../../../utils/useQueryString";
import { emailValidationRules } from "../../../../validators";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["form"]>;

type SignInFormData = {
  email: string;
};

export default function EmailForm({ ...props }: Props) {
  const [setCsrf] = useCsrfToken();
  const [sendOtp] = useSendOtp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setStep, setEmail } = useContext(UserLoginContext);
  const query = useQueryString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<SignInFormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });

  useEffect(() => {
    if (query.has("email")) {
      setValue("email", query.get("email") as string, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, []);

  const onSubmit = useCallback(async data => {
    setIsLoading(true);

    try {
      await setCsrf();

      await sendOtp({
        email: data.email,
      });

      setEmail(data.email);

      setStep("code");
    } catch (e) {
      toast.error("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Form {...props} onSubmit={handleSubmit(onSubmit)}>
      <Form.Group error={errors.email}>
        <Form.Control
          {...register("email", emailValidationRules)}
          type="text"
          icon={{
            position: "start",
            element: (
              <EmailIcon
                titleId="partner-email"
                accessibilityTitle="partner-email"
                color="#828282"
              />
            ),
          }}
          onChange={e => {
            setValue("email", e.target.value);

            clearErrors("email");
          }}
          placeholder="Enter your email"
          data-testid="emailInput"
        />
      </Form.Group>

      <Button
        aria-label="submit sign-in form"
        className="mt-3"
        fullWidth
        loading={isLoading}
        loadingText="Loading..."
        size="2xl"
        type="submit"
        data-testid="signinButton"
      >
        Sign in
      </Button>
    </Form>
  );
}
