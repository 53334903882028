import { SmallChevron } from "@assets/icons/icons";
import { Calender } from "@assets/ui-icons";
import { SmallText } from "client/components/typography";
import { usePartnerDateFormat } from "client/hooks/usePartnerDateFormat";
import theme from "client/theme";
import { Text } from "client/ui-components";
import { If } from "client/utils/If";
import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker, { DatePickerInputProps } from "../../../ui-components/DatePicker";
import { ContainerRounded, DatePickerWrapper } from "./styles";

export const InputWrapper = styled.div`
  [data-state="open"],
  :focus {
    transition: all 0.2s ease-in-out;
    &:not([data-error="true"]):not([data-disabled]) {
      border: 2px solid rgba(7, 114, 228, 1) !important;
      box-shadow: 0px 0px 0px 4px rgba(3, 70, 245, 0.2) !important;
    }
  }

  [data-toggle="dropdown"]:hover {
    transition: all 0.2s ease-in-out;
    &:not([data-state="open"]):not([data-error="true"]):not([data-disabled]) {
      border: 1px solid rgba(7, 114, 228, 1);
      box-shadow: 0px 0px 0px 2px rgba(3, 70, 245, 0.2);
    }
  }

  [data-error="true"] {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 0px 4px rgba(176, 17, 33, 0.2);
    border: 2px solid ${props => props.theme.ui.colors.error500};
  }
`;

export const DateDropdownInput = forwardRef<
  HTMLButtonElement,
  DatePickerInputProps & React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const { isOpen, setIsOpen, value, handleClose, ...rest } = props;
  const [title, setTitle] = useState<string>("-");

  useEffect(() => {
    if (!isOpen) {
      setTitle(value?.length ? value : "-");
    }
  }, [value, isOpen]);

  const openOrClose = () => {
    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(prev => !prev);
    }
  };

  return (
    <InputWrapper
      className="dropdown p-0 d-flex align-self-start w-100"
      data-testid="dateFoundFilter"
    >
      <ContainerRounded
        aria-expanded={isOpen}
        aria-haspopup="true"
        className="dropdown-toggle"
        data-testid="dateFoundDropdownContainer"
        data-toggle="dropdown"
        data-state={isOpen ? "open" : "closed"}
        {...rest}
        onClick={openOrClose}
        onKeyDown={e => {
          if (e.key === "Enter") {
            openOrClose();
            e.preventDefault();
          } else if (e.key === "Escape") {
            handleClose();
          }
        }}
        ref={ref}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: "10px",
          }}
        >
          <Calender
            titleId="Calendar icon"
            width={20}
            height={20}
            accessibilityTitle="calendar icon"
          />
          <SmallText className="text-nowrap">{title}</SmallText>
        </span>
        {isOpen ? (
          <SmallChevron
            accessibilityTitle={`close dropdown icon`}
            titleId="Big chevron pointing up"
            data-testid="close"
            key={"chevron"}
            style={{
              transform: "rotate(180deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          />
        ) : (
          <SmallChevron
            accessibilityTitle={`open dropdown icon`}
            titleId="Big chevron pointing down"
            data-testid="open"
            key={"chevron"}
            style={{
              transition: "transform 0.2s ease-in-out",
            }}
          />
        )}
      </ContainerRounded>
    </InputWrapper>
  );
});

type Props = {
  label?: string;
  date: Date | null;
  onSelect?: (date: Date) => void;
  style?: React.CSSProperties;
  dataTestId?: string;
};

export function ConfirmedDatePicker({ label, date, onSelect = () => {}, ...rest }: Props) {
  const { long } = usePartnerDateFormat();

  return (
    <div className="d-flex">
      <DatePickerWrapper className="gap-1" style={rest.style} data-testid="dateDropdownPicker">
        <If condition={!!label}>
          <Text fontSize={theme.ui.fontSizes.sm} fontWeight={theme.ui.fontWeights.normal}>
            {label}
          </Text>
        </If>
        <DatePicker
          dateFormat={long}
          Input={DateDropdownInput}
          onSelect={onSelect}
          selected={date}
        />
      </DatePickerWrapper>
    </div>
  );
}
