import styled from "styled-components";
import { Subheader } from "../typography";

export const ModalWrapper = styled.div<{
  ref: React.MutableRefObject<HTMLDivElement>;
}>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.lg};
  position: relative;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 500px;
  }
  &:focus {
    outline: none;
  }
`;

export const BackgroundOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: ${props => props.theme.zIndexes.modal};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: center;
  }
`;

export const StyledHeader = styled(Subheader)`
  justify-content: center;
  text-align: center;
`;
