import Loader from "client/components/Loader";
import useFetchStorageLocations from "client/hooks/data/partner/useFetchStorageLocations";
import useMutateStorageLocationForItems from "client/hooks/data/partner/useMutateStorageLocationForItems";
import theme from "client/theme/uiTheme";
import { FormattedItem } from "client/types";
import { Button, Typography } from "client/ui-components";
import Modal from "client/ui-components/Modal";
import React, { useState } from "react";
import { MatchCreatedPartyIcon, WarningSign } from "../../../../../assets/icons/icons";
import { useInventoryStore } from "../../helpers/inventoryStore";
import StorageLocationSelect from "../StorageLocationSelect";

export default function NewStorageLocationModal({
  isOpened,
  setIsOpened,
  selectedItems,
}: {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  selectedItems: FormattedItem[];
}) {
  const [storageLocationId, setStorageLocationId] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const mutation = useMutateStorageLocationForItems();

  const { setHasBulkOptions } = useInventoryStore();
  const { storageLocations } = useFetchStorageLocations();
  const numberOfItems = selectedItems.length;

  const onStorageLocationChange = (storageLocationId: string) => {
    setStorageLocationId(storageLocationId);
    setHasError(false);
  };

  const makeBulkUpdate = () => {
    if (storageLocationId === "") return setHasError(true);

    const itemIds = selectedItems.map(item => item.id);
    mutation.mutate({
      item_ids: itemIds,
      storage_location: storageLocationId,
    });
  };

  const handleCompleteBulkUpdate = () => {
    setHasBulkOptions(false);
    mutation.reset();
  };

  const destinationStorageLocation = (
    <Typography as="span" fontWeight={theme.fontWeights.bold} fontSize={theme.fontSizes.base}>
      {storageLocations?.find(location => location.id === storageLocationId)?.name}
    </Typography>
  );

  const heading =
    mutation.isIdle || mutation.isPending
      ? `Where do you want to move them?`
      : mutation.isSuccess
        ? "Transfer successful!"
        : "Transfer attempt unsuccessful";

  const description =
    mutation.isIdle || mutation.isPending ? (
      `Choose a new destination for the ${numberOfItems} item${
        numberOfItems > 1 ? "s" : ""
      } you are moving from the dropdown below.`
    ) : mutation.isSuccess ? (
      <>
        {numberOfItems} item{numberOfItems > 1 ? "s have" : " has"} been successfully moved to{" "}
        {destinationStorageLocation}.
      </>
    ) : mutation.isError ? (
      <>
        We encountered a hiccup while moving the items to {destinationStorageLocation}. Please try
        again later.
      </>
    ) : null;

  const ctas =
    mutation.isIdle || mutation.isPending ? (
      <>
        {storageLocations && (
          <StorageLocationSelect
            hasError={hasError}
            onChangeStorageLocation={onStorageLocationChange}
            storageLocations={storageLocations}
          />
        )}
        <Button
          style={{ padding: "12px 20px" }}
          aria-label="ConfirmButton"
          data-testid="confirmButton"
          onClick={makeBulkUpdate}
          loading={mutation.isPending}
          className="mt-2"
        >
          Confirm
        </Button>
        <Button
          aria-label="CancelButton"
          data-testid="cancelButton"
          variant="outline"
          style={{ border: "0", boxShadow: "none" }}
          onClick={() => {
            setIsOpened(false);
          }}
          disabled={mutation.isPending}
        >
          Cancel
        </Button>
      </>
    ) : mutation.isSuccess || mutation.isError ? (
      <Button
        style={{ padding: "12px 20px", marginTop: "20px" }}
        aria-label="ConfirmButton"
        onClick={handleCompleteBulkUpdate}
      >
        Close
      </Button>
    ) : null;

  const icon = mutation.isSuccess ? (
    <MatchCreatedPartyIcon
      accessibilityTitle="MatchCreatedPartyIcon"
      titleId="MatchCreatedPartyIcon"
    />
  ) : mutation.isError ? (
    <WarningSign accessibilityTitle="WarningSign" titleId="WarningSign" />
  ) : null;

  return (
    <Modal
      icon={icon}
      isOpened={isOpened}
      heading={
        <Typography
          as="h4"
          heading
          fontSize={icon ? "34px" : theme.fontSizes.xxl}
          fontWeight={theme.fontWeights.bold}
          color={theme.colors.primary700}
          lineHeight={icon ? 1.47 : 1.5}
          className={`text-center mb-25 ${icon ? "" : "px-lg-5"}`}
          data-testid="selectStorageHeading"
        >
          {heading}
        </Typography>
      }
      description={
        <Typography
          as="p"
          fontSize={theme.fontSizes.base}
          fontWeight={theme.fontWeights.normal}
          color={theme.colors.primary700}
          className={`text-center mb-25 ${icon ? "" : "px-lg-5"}`}
          data-testid="selectStorageCopy"
          lineHeight={1.8}
        >
          {description}
        </Typography>
      }
      setIsOpened={setIsOpened}
      buttons={!storageLocations ? <Loader /> : ctas}
      hasCloseButton={mutation.isIdle || mutation.isPending}
      clickAwayCallback={mutation.reset}
    />
  );
}
