import { useForm } from "react-hook-form";

export type ContactDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export const useContactDetailsForm = () => {
  return useForm<ContactDetails>({
    criteriaMode: "all",
    mode: "onBlur",
    reValidateMode: "onChange",
  });
};
