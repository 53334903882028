import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

interface CatchClickOutsideProps {
  onClickOutside: () => void;
  className?: string;
  children: React.ReactNode;
}

const Wrapper = styled.span`
  width: fit-content;
`;

const CatchClickOutside: React.FC<CatchClickOutsideProps> = props => {
  const { onClickOutside, className, children } = props;
  const node = useRef<HTMLElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (node && node.current && node.current.contains(event.target as Node)) {
        return;
      }

      onClickOutside();
    },
    [node, onClickOutside],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <Wrapper ref={node} className={className}>
      {children}
    </Wrapper>
  );
};

export default CatchClickOutside;
