import ArrowLeft from "@assets/ui-icons/ArrowLeft";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect, useState } from "react";
import { LostItemCreationContext } from "../../../../../../context";
import theme from "../../../../../../theme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import DateForm from "./DateForm";
import { Wrapper } from "./styles";

type DateInputArgs = {
  reviewUpdate?: boolean;
};

export default function DateSelection({ reviewUpdate = false }: DateInputArgs) {
  const { partner, setStep, dateLost, setDateLost } = useContext(LostItemCreationContext);
  const [prevDateLost, setPrevDateLost] = useState<Date>(dateLost);

  useEffect(() => {
    setPrevDateLost(dateLost);
  }, []);

  scrollToTop();

  return partner === null ? null : (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Lost something?
      </Text>

      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-md-center">
        When did you lose it?
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
        Choose from the options below or use the date picker for other dates. If you don’t remember
        the exact date, don’t stress. Just tell us your best guess.
      </Text>

      <DateForm nextStep={reviewUpdate ? "review" : "category"} />

      <Button
        form="date-form"
        type="submit"
        aria-label="go-next"
        className="mt-3"
        size="2xl"
        fullWidth
      >
        {reviewUpdate ? "Update" : "Next"}
      </Button>

      <Button
        aria-label="go-back"
        onClick={() => {
          if (reviewUpdate) {
            setDateLost(prevDateLost);
            setStep("review");
          } else {
            setStep("name");
          }
        }}
        data-testid="backButton"
        variant="outline"
        className="mt-3"
        fullWidth
        icon={
          !reviewUpdate && (
            <ArrowLeft accessibilityTitle="go-back" titleId="sublocation-selection-go-back" />
          )
        }
      >
        {reviewUpdate ? "Cancel" : "Go back"}
      </Button>
    </Wrapper>
  );
}
