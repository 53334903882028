export enum STILL_LOOKING {
  title = "Still looking",
  subheader = "We’re doing our best to find matches!",
}
export enum CLAIMS_MATCHED {
  title = "Is this yours?",
  subheader = "Review & verify this is your item",
}
export enum CLAIMS_CANCELED {
  title = "Canceled",
  subheader = "These claims are no longer active.",
}

export enum CLAIMS_EXPIRED {
  title = "Expired claims",
  subheader = "We’re sorry your item was not found.",
}

export enum CLAIMS_RETURNS {
  title = "Get it back!",
  subheader = "Let's get these items back to you!",
}
