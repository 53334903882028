import React from "react";
import theme from "../../../../../theme";
import { Heading } from "../../../../../ui-components";
import SocialProofLogo from "./social-proof-logo.svg";

export default function SocialProof() {
  return (
    <>
      <Heading as="h2" className="mb-45" color={theme.ui.colors.primary500} size="xs" variant="h1">
        “We really like how organized Boomerang is. We now know exactly what is in our inventory
        without having to search the shelves.”
      </Heading>

      <Heading as="p" color={theme.ui.colors.primary500} fontWeight={400} size="xs" variant="h3">
        Cleveland Browns Stadium
      </Heading>

      <img src={SocialProofLogo} width={104} height="auto" className="mt-45" alt="BoomerangLogo" />
    </>
  );
}
