import React, { useEffect } from "react";
import history from "../../../../../context/history";

import Button from "../../../../../components/Button";
import { ButtonModes, ButtonTextColors } from "../../../../../components/Button/Button.types";
import { TopBackgroundAccent } from "../../../../../components/SimpleHeader/styles";

import { StyledCheckSolidIcon } from "../../../../../assets/icons/icons";
import { ClaimStepCopy, UpdateClaimStepCopy } from "../../../../../copy/artifactFormCopy";
import { mountBodyGray6, unmountBodyGray6 } from "../../../../../utils/bodyStyle";
import { ClaimSteps } from "../../types";

import {
  CenterWrapper,
  InternalWrapper,
  LargeBackground,
  OuterWrapper,
  SmallBackground,
  StyledButtonWrapper,
  StyledDescription,
  StyledImage,
  StyledTitle,
  TopHalf,
} from "./styled";

interface PageBackgroundProps {
  isLarge: boolean;
}

const PageBackground: React.FC<React.PropsWithChildren<PageBackgroundProps>> = props => {
  return (
    <>
      {props.isLarge ? (
        <>
          <TopBackgroundAccent />
          <LargeBackground>{props.children}</LargeBackground>
        </>
      ) : (
        <SmallBackground>{props.children}</SmallBackground>
      )}
    </>
  );
};

interface SubmittedPageProps {
  isLarge: boolean;
  claimId: string | undefined;
  imageUrl?: string;
  existingClaim: boolean;
  editAgain: () => void;
}

export const SubmittedPage: React.FC<SubmittedPageProps> = props => {
  const goToClaims = () => {
    history.push("/claims");
  };
  const editClaim = () => {
    props.editAgain();
  };

  const copy = props.existingClaim ? UpdateClaimStepCopy : ClaimStepCopy;
  const bottomBtnText = props.existingClaim ? "Edit details" : "Go to my Claims";
  const bottomBtnOnClick = props.existingClaim ? editClaim : goToClaims;

  useEffect(() => {
    mountBodyGray6();
    return () => {
      unmountBodyGray6();
    };
  }, []);

  return (
    <PageBackground isLarge={props.isLarge}>
      <OuterWrapper className="px-45 px-lg-0">
        <InternalWrapper>
          <TopHalf>
            {props.imageUrl && (
              <StyledImage className="border-0" imageUrl={props.imageUrl}>
                <span role="img" aria-label="Image of the item you submitted" />
              </StyledImage>
            )}
            <CenterWrapper>
              <StyledCheckSolidIcon
                accessibilityTitle="Success checkmark"
                titleId="ClaimCreationSuccessCheckmarkTitle"
              />
              <StyledTitle>{copy[ClaimSteps.SUBMITTED].title!}</StyledTitle>
              <StyledDescription>{copy[ClaimSteps.SUBMITTED].description!}</StyledDescription>
            </CenterWrapper>
          </TopHalf>
          <StyledButtonWrapper>
            <Button
              ariaLabel="Go to my claim details"
              data-testid="goToClaimDetailsButton"
              text="See claim details"
              mode={ButtonModes.primary}
              className="w-100 justify-content-center align-items-center border-primary mx-auto"
              onClick={() => {
                history.push(`/claim/${props.claimId}`);
              }}
            />
            <Button
              ariaLabel={bottomBtnText}
              text={bottomBtnText}
              mode={ButtonModes.open}
              textColor={props.isLarge ? ButtonTextColors.gray3 : ButtonTextColors.white}
              className="w-100 justify-content-center align-items-center mx-auto"
              onClick={bottomBtnOnClick}
            />
          </StyledButtonWrapper>
        </InternalWrapper>
      </OuterWrapper>
    </PageBackground>
  );
};

export default SubmittedPage;
