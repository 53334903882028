import { MagnifyingGlass } from "@assets/icons/icons";
import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { isSmQuery } from "client/helpers/mediaQuery";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import theme from "client/theme";
import { KEYBOARD_KEY } from "client/types/keyboardKey";
import React, { useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PartnerRoutesEnum } from "../../components/Routes";
import Form from "../Form";
import { IconContainer, StyledMagnifyingGlass } from "./styles";

function Search(props, ref) {
  const isSm = isSmQuery();
  const location = useLocation();
  const { partnerUserLocation, setGlobalSearchLocation } = usePartnerLocationStore();
  const pathname = location.pathname;
  const search = location.search;
  const { setIsSearchModeOn, searchTerm, isSearchModeOn, setSearchTerm, setLastVisitedPage } =
    useContext(PartnerGlobalSearchContext);

  const turnOnSearchMode = useCallback(() => {
    setGlobalSearchLocation(partnerUserLocation);
    setIsSearchModeOn(true);
  }, [partnerUserLocation, setGlobalSearchLocation, setIsSearchModeOn]);

  useEffect(() => {
    if (
      [
        PartnerRoutesEnum.OVERVIEW,
        PartnerRoutesEnum.MATCHES,
        PartnerRoutesEnum.METRICS,
        PartnerRoutesEnum.INVENTORY,
        PartnerRoutesEnum.INVENTORY_ARCHIVE,
        PartnerRoutesEnum.INVENTORY_UNCLAIMED,
        PartnerRoutesEnum.CLAIMS,
        PartnerRoutesEnum.RETURNS,
      ].includes(pathname as PartnerRoutesEnum)
    )
      setLastVisitedPage(pathname + search);
  }, [setLastVisitedPage, pathname, search]);

  useEffect(() => {
    if (searchTerm.length >= 1) turnOnSearchMode();
  }, [searchTerm, turnOnSearchMode]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === KEYBOARD_KEY.FORWARD_SLASH && !isSearchModeOn && ref.current) {
        event.preventDefault();
        ref.current.focus();
        turnOnSearchMode();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchModeOn, ref, turnOnSearchMode]);

  const style = {
    height: "36px",
    width: "200px",
    opacity: "1",
    transition: "opacity 0.1s ease-in-out, transform 0.1s ease-in-out",
  };

  if (isSearchModeOn) return null;

  return isSm ? (
    <Form onSubmit={e => e.preventDefault()}>
      <Form.Group>
        <Form.Control
          ref={ref}
          style={style}
          autoComplete="off"
          fullWidth
          type="text"
          id="search-boomerang"
          data-testid="searchBar"
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          icon={{
            position: "start",
            element: isSearchModeOn ? (
              <></>
            ) : (
              <MagnifyingGlass
                style={{ zIndex: "9" }}
                accessibilityTitle="Search Icon"
                titleId="Search Icon"
                color={theme.ui.colors.gray600}
              />
            ),
          }}
        />
      </Form.Group>
    </Form>
  ) : (
    <IconContainer ref={ref}>
      <StyledMagnifyingGlass
        accessibilityTitle="Search Icon"
        titleId="Search Icon"
        onClick={turnOnSearchMode}
      />
    </IconContainer>
  );
}

export default React.forwardRef(Search);
