import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.ui.colors.primary700};
  width: 100%;
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 200px;
  }
`;
export const HeaderContainer = styled.div`
  max-width: ${props => props.theme.widths.claimMax};

  p {
    margin: 0;
  }
`;

export const Title = styled.h1`
  font-weight: ${props => props.theme.fontWeights.semiBold};
  font-size: ${props => props.theme.fontSizes.lg};
  font-family: ${props => props.theme.fontFamilies.display};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => props.theme.fontSizes.xxl};
  }
`;

export const IconButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.white};

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }

  span {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    span {
      display: block;
    }
  }
`;

export const Back = styled(Link)`
  color: ${props => props.theme.colors.white};

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }

  span {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    span {
      display: block;
    }
  }
`;

export const Subtitle = styled.p`
  font-size: ${props => props.theme.fontSizes.sm};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => props.theme.fontSizes.default};
  }
`;
