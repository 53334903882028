import BlankMessage from "client/components/BlankMessage";
import React from "react";
import emptyBoxImg from "../../../../assets/empty_box.svg";

export const NoReturnsMessage = () => (
  <BlankMessage alt="Boomerang empty box" image={String(emptyBoxImg)} title={"No returns yet"}>
    There are no returns for this location.
    <br />
    Once there are, they will show up here.
  </BlankMessage>
);
