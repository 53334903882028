import { fetchApiData } from "client/utils/fetchApiData";
import { getCommonHeaders } from "client/utils/headers";

export default (partnerId: string, item_ids: string[], storage_location: string) =>
  fetchApiData({
    url: `/api/partner/partners/${partnerId}/storage-location/bulk_update/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify({ item_ids, storage_location }),
    },
  });
