import React from "react";

import styled from "styled-components";
import SideBarNav from "./SideBarNav";

const Body = styled.div`
  align-items: stretch;
  display: flex;
  min-height: 100%;
  width: 100%;
`;

const StyledSideBarNav = styled(SideBarNav)`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`;

function WelcomeView(props) {
  const { children } = props;
  return (
    <Body>
      <StyledSideBarNav />
      <main className="px-0 w-100">{children}</main>
    </Body>
  );
}

export default WelcomeView;
