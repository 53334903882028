import { Select, SelectItem } from "client/ui-components";
import React from "react";
import { PartnerStorageLocation } from "../../../../../types";

type StorageLocationSelectProps = {
  storageLocations: PartnerStorageLocation[];
  onChangeStorageLocation: (e: any) => void;
  hasError: boolean;
};

export default function StorageLocationSelect({
  storageLocations,
  onChangeStorageLocation,
  hasError,
}: StorageLocationSelectProps) {
  return (
    <Select
      placeholder="Select option"
      hasError={hasError}
      onValueChange={onChangeStorageLocation}
      hint={hasError ? "Please select a storage location" : undefined}
    >
      {storageLocations.map(storageLocation => (
        <SelectItem key={storageLocation.id} value={storageLocation.id}>
          {storageLocation.name}
        </SelectItem>
      ))}
    </Select>
  );
}
