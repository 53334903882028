import { ArrowNarrowLeft } from "@assets/ui-icons";
import { useUserUpdateReturn } from "client/actions/userReturns";
import Loader from "client/components/Loader";
import { ReadyForPickupAddressDisplay } from "client/components/ReturnStatusBanner/ReadyForPickupBanner";
import { ClaimDetailsContext } from "client/context/ClaimDetails";
import theme from "client/theme";
import { ReturnModeEnum } from "client/types";
import React, { useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import history from "../../../context/history";
import { Button, Container, Heading, Text } from "../../../ui-components";
import { BannerContainer, GoBackButton, HeaderWrapper, PageWrapper, Wrapper } from "./styles";

export default function ChangeToPickup(): JSX.Element {
  const { claimId } = useParams<{ claimId: string }>();
  const { claim, isLoading, returnResponse, shipment } = useContext(ClaimDetailsContext);
  const { mutateAsync, data, isPending } = useUserUpdateReturn();

  const changeToPickup = async () => {
    if (!returnResponse) return;
    await mutateAsync({
      returnId: returnResponse?.id,
      mode: ReturnModeEnum.PICKUP,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (data) {
    return <Redirect to={`/claim/${claimId}/pickup-confirmed`} />;
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <GoBackButton
          className="btn btn-link text-white px-0"
          onClick={() => history.push(`/claim/${claimId}`)}
        >
          <ArrowNarrowLeft
            accessibilityTitle="go-back"
            titleId="go-back"
            className="flex-shrink-0"
            color="black"
            data-testid="goBackArrow"
          />
        </GoBackButton>
      </HeaderWrapper>
      <Container className="h-100">
        <Wrapper>
          <Text
            color={theme.ui.colors.primary500}
            fontSize={theme.ui.fontSizes.base}
            fontWeight={theme.ui.fontWeights.bold}
            className="mb-1 text-md-center"
          >
            Change recovery method
          </Text>
          <Heading variant="h2" as="h1" className="mb-3 text-md-center">
            Change to pickup?
          </Heading>
          <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
            It looks like you originally chose our quick and easy shipping option.
          </Text>
          <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-2">
            Are you sure you want to change your recovery method to pickup?
          </Text>
          <div className="d-flex flex-column pt-4 gap-2 md-justify-content-center align-items-center">
            <Text fontWeight={theme.ui.fontWeights.bold} className="text-md-center mb-2">
              You can pick up your item at the following address:
            </Text>
            <BannerContainer>
              <ReadyForPickupAddressDisplay />
            </BannerContainer>
          </div>
          <div className="d-flex flex-column flex-grow-1 h-100 pt-275 gap-275 justify-content-end ">
            <Button
              aria-label="confirm pickup"
              data-testid="confirmPickupButton"
              size="2xl"
              onClick={changeToPickup}
              loading={isPending}
            >
              Yes, update to pickup
            </Button>
            <Button
              variant="outline"
              href={`/shippingcheckout?return_object=${
                returnResponse?.id || ""
              }&claim_id=${claim?.id}${shipment?.id ? `&shipment_id=${shipment?.id}` : ""}`}
              aria-label="Ship It"
              data-testid="shipIt"
              size="2xl"
            >
              No, I still want to ship it
            </Button>
          </div>
        </Wrapper>
      </Container>
    </PageWrapper>
  );
}
