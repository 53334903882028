import styled from "styled-components";
import Button from "../../Button";

export const TabTitleList = styled.ul`
  list-style-type: none;
  overflow: auto;

  margin-top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 28px;
  }
`;

export const TabTitle = styled.li`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.sm};
  user-select: none;
  flex: 1;
  border-bottom: 6px solid ${props => props.theme.colors.gray6};
  text-align: center;
  line-height: 16px;

  &.active {
    font-weight: ${props => props.theme.fontWeights.bold};
    border-color: ${props => props.theme.colors.primaryBlue};
  }
`;

export const ClickableTabTitle = styled(Button)`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.sm};
  width: 100%;
  font-weight: ${props => props.theme.fontWeights.default};
  color: ${props => props.theme.colors.gray1} !important;
  border: none;
  border-radius: 0;
  border-bottom: 6px solid ${props => props.theme.colors.gray6};

  &.active {
    font-weight: ${props => props.theme.fontWeights.bold};
    border-color: ${props => props.theme.colors.primaryBlue};
  }

  &:focus {
    font-weight: ${props => props.theme.fontWeights.bold};
    box-shadow: none;

    &:not(.active) {
      border-color: ${props => props.theme.colors.lightBlue};
    }
  }
`;
