import { StatesResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";

export default (search?: string): Promise<StatesResponse> => {
  let url = `/api/user/states/?ordering=name`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }
  return fetchApiData({ url });
};
