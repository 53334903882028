import { usePartnerUnclaimedStatuses } from "client/hooks/data/category-by-partner";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import theme from "client/theme";
import { Category, PartnerItem } from "client/types";
import { Text } from "client/ui-components";
import { notNull } from "client/utils/NotNull";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React, { useMemo } from "react";
import { ConfirmedDatePicker } from "./ConfirmedDatePicker";
import { ItemDetailsWithControls } from "./ItemDetailsWithControls";
import { StatusPicker } from "./StatusPicker";
import { TruncatedText } from "./styles";

export function useUnclaimedInventoryColumns({
  isMd,
  categories = [],
  onItemChange,
}: {
  isMd?: boolean;
  categories?: Category[];
  onItemChange?: (item: PartnerItem) => void;
}) {
  const partnerId = useCurrentPartner();
  const dateFormatter = useDateFormatter();
  const { data: unclaimedStatuses, isLoading: unclaimedStatusesAreLoading } =
    usePartnerUnclaimedStatuses(partnerId);
  const validUnclaimedStatuses = unclaimedStatuses?.map(({ unclaimed_status }) => unclaimed_status);
  return useMemo(
    () =>
      [
        {
          header: "Item name & category",
          cell: ({ row: { original } }) => (
            <ItemDetailsWithControls
              category={categories?.find(({ id }) => id === original.category)}
              unclaimedStatuses={validUnclaimedStatuses}
              item={original}
              onItemChange={onItemChange}
            />
          ),
          accessorKey: "name",
          width: "30%",
        },
        isMd
          ? {
              header: "Description",
              cell: props => (
                <Text fontSize={theme.ui.fontSizes.sm}>
                  <TruncatedText>{props.getValue()}</TruncatedText>
                </Text>
              ),
              accessorKey: "description",
            }
          : null,
        isMd
          ? {
              header: "Date found",
              cell: props => (
                <Text className="text-truncate text-nowrap" fontSize={theme.ui.fontSizes.sm}>
                  {dateFormatter(new Date(props.getValue()), "long")}
                </Text>
              ),
              accessorKey: "collected_at",
            }
          : null,
        isMd
          ? {
              header: "Status",
              cell: ({ row }) =>
                !unclaimedStatusesAreLoading && (
                  <StatusPicker
                    key={row.original.id}
                    selected={row.original.unclaimed_status}
                    allowedStatuses={validUnclaimedStatuses ?? []}
                    onChange={status =>
                      onItemChange?.({ ...row.original, unclaimed_status: status })
                    }
                  />
                ),
              accessorKey: "status",
              width: "145px",
            }
          : null,
        isMd
          ? {
              header: () => (
                <div style={{ textAlign: "right", paddingRight: 20 }}>Date updated</div>
              ),
              cell: ({ row }) => {
                return (
                  <div className="d-flex justify-content-end pe-35 flex-fill" key={row.original.id}>
                    <ConfirmedDatePicker
                      onSelect={date =>
                        onItemChange?.({
                          ...row.original,
                          unclaimed_status_set_at: date,
                        })
                      }
                      date={row.original.unclaimed_status_set_at}
                    />
                  </div>
                );
              },
              accessorKey: "unclaimed_status_set_at",
              width: "206px",
            }
          : null,
      ].filter(notNull),
    [isMd, categories, unclaimedStatuses, unclaimedStatuses, onItemChange],
  );
}
