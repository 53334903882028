import React from "react";

export const AuthAction = {
  REQUEST_LOGIN: "REQUEST_LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REQUEST_LOGOUT: "REQUEST_LOGOUT",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  LOGIN_ERROR: "LOGIN_ERROR",
  UPDATE_CURRENT_PARTNER: "UPDATE_CURRENT_PARTNER",
};

export function AuthReducer(initialState, action) {
  switch (action.type) {
    case AuthAction.REQUEST_LOGIN:
      return {
        ...initialState,
        loading: true,
      };
    case AuthAction.REQUEST_LOGOUT:
      return {
        ...initialState,
        loading: true,
      };
    case AuthAction.LOGIN_SUCCESS:
      return {
        ...initialState,
        user: {
          ...action.payload,
        },
        partner: action.payload?.partners ? action.payload?.partners[0] : null,
        loading: false,
      };
    case AuthAction.LOGOUT_ERROR:
      return {
        ...initialState,
        user: "",
        partner: null,
        loading: false,
        errorMessage: action.error,
      };
    case AuthAction.LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case AuthAction.UPDATE_CURRENT_PARTNER:
      return {
        ...initialState,
        user: {
          ...initialState.user,
        },
        partner: action.currentPartner,
      };

    default:
      throw new Error(`Auth Context: Unhandled action type: ${action.type}`);
  }
}

type AuthReducerContextType = React.Dispatch<any> | undefined;

export const AuthReducerContext = React.createContext<AuthReducerContextType>(undefined);
