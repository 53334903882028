import Pagination from "client/components/Pagination";
import { usePagination } from "client/hooks";
import React from "react";

interface Props {
  count: number;
  pageSize?: number;
  searchParamPrefix?: string;
}

export default function PaginationWithParams({ count, pageSize, searchParamPrefix = "" }: Props) {
  const {
    page,
    setPage,
    pageSize: currentPageSize,
  } = usePagination({
    count,
    pageSize,
    key: searchParamPrefix,
  });

  return (
    <Pagination
      currentPage={page}
      onPageChange={newPage => setPage(newPage)}
      pageSize={currentPageSize}
      totalCount={count || 0}
      showPagination
    />
  );
}
