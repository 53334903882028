import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: Lato;
  max-width: ${props => props.theme.widths.portalMax};
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;

export const ButtonLink = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const Title = styled.div`
  color: var(--primary-700-for-text, #00174a);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
`;

export const Description = styled.div`
  color: var(--Gray-600-Main, #525860);

  /* P Text Regular/16px Regular 400 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const FilterContainer = styled.div`
  gap: 8px;
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
