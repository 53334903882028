import { useQuery } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default (partnerId: string) => {
  const query = useQuery({
    ...queryFactory.locations(partnerId),
    select: data => {
      return {
        locations: data.results || [],
        defaultLocation: data.count > 0 ? data.results[0] : undefined,
      };
    },
    enabled: !!partnerId,
  });

  return {
    isSuccess: query.isSuccess,
    isError: query.isError,
    isLoading: query.isLoading,
    partnerLocations: query.data?.locations,
    defaultPartnerLocation: query.data?.defaultLocation,
  };
};
