import { useQuery } from "@tanstack/react-query";
import queryFactory from "./queryFactory";

export default (id: string) => {
  const query = useQuery(queryFactory.info(id));
  return {
    partner: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
