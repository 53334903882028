import React from "react";
import BoomerangLogo from "../../assets/logos/BoomerangLogo.svg";
import BoomerangPartner from "../../assets/logos/BoomerangPartner.svg";
import BoomerangWhite from "../../assets/logos/BoomerangWhite.svg";

export enum LogoVariants {
  Boomerang = "Boomerang",
  BoomerangWhite = "BoomerangWhite",
  BoomerangPartner = "BoomerangPartner",
}

const logos = {
  Boomerang: BoomerangLogo,
  BoomerangWhite: BoomerangWhite,
  BoomerangPartner: BoomerangPartner,
};

interface LogoProps {
  alt?: string;
  className?: string;
  name: LogoVariants;
}

export const Logo = (props: LogoProps): React.ReactElement | null => {
  const { alt = props.name, name, className } = props;
  if (!name || !logos[name]) return null;
  return <img className={className} src={logos[name]} alt={alt} data-testid={`${alt}Logo`} />;
};
