import styled from "styled-components";
import CheckboxCheckIcon from "../../assets/ui-icons/checkbox-check.svg";

export const Input = styled.input`
  &.size-md {
    width: 16px;
    height: 16px;
  }

  &.size-lg {
    width: 20px;
    height: 20px;
  }

  border-color: ${props => props.theme.ui.colors.gray600};
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  color: ${props => props.theme.ui.colors.primary500};

  &:disabled {
    border-color: ${props => props.theme.ui.colors.gray600};
    background-color: ${props => props.theme.ui.colors.gray300};
    border-color: ${props => props.theme.ui.colors.gray500};
  }

  &:not(:checked):not(:disabled):hover[type="checkbox"] {
    background-color: ${props => props.theme.ui.colors.primary300};
    border-color: ${props => props.theme.ui.colors.primary500};
  }

  :not(:disabled):focus-visible,
  :not(:disabled):focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px ${props => props.theme.ui.colors.primary500};

    &.is-invalid {
      box-shadow: 0px 0px 0px 4px ${props => props.theme.colors.red};
    }
  }

  &:checked[type="checkbox"] {
    background-color: ${props => props.theme.ui.colors.primary300};
    border-color: ${props => props.theme.ui.colors.primary500};
    background-image: url(${CheckboxCheckIcon});

    &.size-md {
      background-size: 8px auto;
    }

    &.size-lg {
      background-size: 9.33px auto;
    }
  }
`;
