import styled from "styled-components";

export const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease-in-out;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    background-color: ${props => props.theme.ui.colors.gray300};
  }
`;
