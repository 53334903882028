import * as RadixTooltip from "@radix-ui/react-tooltip";
import theme from "client/theme";
import React from "react";
import { TooltipButton, TooltipContent } from "./styles";

type TooltipProps = {
  children?: React.ReactNode;
  content?: React.ReactNode;
};

const TooltipIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    style={{ transition: "color 0.2s ease-in-out" }}
  >
    <g clipPath="url(#clip0_773_24561)">
      <path
        d="M6.0626 6.0026C6.21934 5.55705 6.52871 5.18134 6.93591 4.94203C7.34311 4.70271 7.82187 4.61523 8.28739 4.69508C8.75291 4.77493 9.17515 5.01695 9.47932 5.37829C9.7835 5.73963 9.94997 6.19695 9.94927 6.66927C9.94927 8.0026 7.94927 8.66927 7.94927 8.66927M8.0026 11.3359H8.00927M14.6693 8.0026C14.6693 11.6845 11.6845 14.6693 8.0026 14.6693C4.32071 14.6693 1.33594 11.6845 1.33594 8.0026C1.33594 4.32071 4.32071 1.33594 8.0026 1.33594C11.6845 1.33594 14.6693 4.32071 14.6693 8.0026Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_773_24561">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Tooltip = ({ children, content }: TooltipProps) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          <TooltipButton>{children || <TooltipIcon />}</TooltipButton>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <TooltipContent sideOffset={5}>
            {content}
            <RadixTooltip.Arrow style={{ fill: theme.ui.colors.primary300 }} />
          </TooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
