import { ClaimStatus, ItemStatus, MatchStatus, ReturnModeEnum, ReturnStatus } from "../../types";

export interface Tab {
  name: string;
  status: ItemStatus | ClaimStatus | MatchStatus | ReturnModeEnum;
  urlSuffix?: string;
}

export interface TabInfo {
  name: string;
  count: number;
  urlString?: string;
}

export interface ClaimTab {
  name: ClaimTabsName;
  statuses: ClaimStatus[];
}

export interface InventoryTab {
  name: InventoryTabsName;
  statuses: ItemStatus[];
}

export enum ClaimTabsName {
  ALL = "All active",
  POTENTIAL_MATCHES = "Potential matches",
  PENDING = "Pending",
  NO_MATCHES = "No matches",
  EXPIRED = "Expired",
  ARCHIVE = "All",
  RETURNED = "Returned",
}

export enum InventoryTabsName {
  ALL = "All items",
  POTENTIAL_MATCHES = "Potential matches",
  PENDING = "Pending",
  NO_MATCHES = "No matches",
  UNCLAIMED = "Unclaimed",
  ARCHIVE = "All",
  RETURNED = "Returned",
}

export const InventoryTabs: InventoryTab[] = [
  {
    name: InventoryTabsName.ALL,
    statuses: [ItemStatus.HAS_MATCHES, ItemStatus.MATCHED, ItemStatus.NO_MATCHES],
  },
  {
    name: InventoryTabsName.POTENTIAL_MATCHES,
    statuses: [ItemStatus.HAS_MATCHES],
  },
  {
    name: InventoryTabsName.PENDING,
    statuses: [ItemStatus.MATCHED],
  },
  {
    name: InventoryTabsName.NO_MATCHES,
    statuses: [ItemStatus.NO_MATCHES],
  },
];

export const InventoryArchiveTabs: InventoryTab[] = [
  {
    name: InventoryTabsName.UNCLAIMED,
    statuses: [ItemStatus.UNCLAIMED],
  },
];

export enum ReturnsTabsName {
  ALL = "All returns",
  RETURN_IN_PERSON = "Return in person",
  RETURN_BY_MAIL = "Return by mail",
  ARCHIVE = "All",
}

export interface ReturnsTab {
  name: ReturnsTabsName;
  recoveryMethods: ReturnModeEnum[];
  statuses: ReturnStatus[];
}
