import styled from "styled-components";

export const BigNumber = styled.div`
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.big};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  line-height: 1.25;
`;

export const PillWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.md};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.borderRadius.lg};
  width: 100%;
  height: 180px;

  &.cursor-default {
    cursor: default;
  }
`;

export const SubText = styled.div`
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.default};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PillLink = styled.a`
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
  }
`;
