import styled from "styled-components";

export const StyledUl = styled.ul`
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
  .page-item {
    cursor: pointer;
    .page-link {
      color: ${props => props.theme.colors.gray1};
      padding: 0.375rem 0.75rem;
    }
    &.active {
      .page-link {
        color: ${props => props.theme.colors.white};
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
      .page-link {
        color: ${props => props.theme.colors.gray3};
      }
    }
  }
`;
