import styled from "styled-components";

import { ArrowLeft } from "../../../../assets/icons/icons";

export const BackToTopButton = styled.button<{
  show: boolean;
}>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;
  opacity: ${props => (props.show ? "1" : "0")};
  transition: opacity 0.3s ease;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const ArrowUp = styled(ArrowLeft)`
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  margin-right: 8px;

  & path {
    stroke: ${props => props.theme.ui.colors.white};
    stroke-width: 1px;
  }
`;

export const LinkWrapper = styled.a`
  margin: 10px 0 2px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 18px 0 8px 0;
  }
`;
