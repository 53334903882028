import queryParamsToObject from "client/utils/queryParamsToObject";
import { useHistory, useLocation } from "react-router-dom";
import { z } from "zod";

type ReturnUseSearchParam<T extends z.AnyZodObject> = {
  searchParams: z.infer<T>;
  setSearchParams: (value: Partial<z.infer<T>>) => void;
  append: (name: string, value: string | number) => void;
  remove: (name: string) => void;
};

export function useSearchParamsWithZod<T extends Record<string, z.ZodType>>(
  recordSchema: T,
): ReturnUseSearchParam<z.ZodObject<T>> {
  const location = useLocation();
  const history = useHistory();
  const schema = z.object(recordSchema);
  const searchParams = new URLSearchParams(location.search);
  const values = schema.parse(queryParamsToObject(searchParams));

  const setSearchParams = (value: Partial<T>) => {
    Object.entries(value).forEach(([name, value]) => {
      searchParams.set(name, value);
    });
    searchParams.sort();
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const append = (name: string, value: string | number) => {
    searchParams.append(name, value.toString());
    searchParams.sort();
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const remove = (name: string) => {
    searchParams.delete(name);
    searchParams.sort();
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return { searchParams: values, setSearchParams, append, remove };
}
