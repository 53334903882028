import ReactCodeInput from "react-code-input";
import styled from "styled-components";
import { StyledReactCodeInputProps } from "./types";

export const StyledReactCodeInput = styled(ReactCodeInput)<StyledReactCodeInputProps>`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    color: ${({ theme, isIncorrectCode }) =>
      isIncorrectCode ? theme.colors.red : theme.colors.gray3};
    height: 56px;
    width: 44px;
    border-radius: ${props => props.theme.borderRadius.sm};
    border: solid
      ${({ theme, isIncorrectCode }) =>
        isIncorrectCode ? theme.colors.red + " 2px" : theme.colors.gray4 + " 1px"};
    background-image: none !important;
    text-align: center;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 50px;
      margin-right: 0.875rem;
    }
  }
`;

export const IncorrectMessage = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 1.143;
`;
