import React from "react";
import { UnclaimedSuggestionStatus } from "../../helpers";
import { Description, Title, TitleContainer } from "./styles";

interface Props {
  status?: UnclaimedSuggestionStatus;
}

export function UnclaimedSuggestionTitle({ status }: Props) {
  if (status === "donate") {
    return (
      <TitleContainer>
        <Title>Suggested donation list</Title>
        <Description>{`We've prepared this donation list for you. Select items and click 'Set to donated' to mark them as donated. If there are items that you do not wish to donate, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "store") {
    return (
      <TitleContainer>
        <Title>Suggested store list</Title>
        <Description>{`We've prepared this store list for you. Select items and click 'Set to stored' to mark them as stored. If there are items that you do not wish to store, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "sell") {
    return (
      <TitleContainer>
        <Title>Suggested sell list</Title>
        <Description>{`We've prepared this sell list for you. Select items and click 'Set to sold' to mark them as sold. If there are items that you do not wish to sell, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "destroy") {
    return (
      <TitleContainer>
        <Title>Suggested destruction list</Title>
        <Description>{`We've prepared this destruction list for you. Select items and click 'Set to destroyed' to mark them as destroyed. If there are items that you do not wish to destroy, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "more-life") {
    return (
      <TitleContainer>
        <Title>Suggested More Life list</Title>
        <Description>{`We've prepared this More Life list for you. Select items and click 'Set to More Life' to mark them for More Life Program. If there are items that you do not wish to use for the More Life Program, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "other") {
    return (
      <TitleContainer>
        <Title>Suggested other list</Title>
        <Description>{`We've prepared this other list for you. Select items and click 'Set to other' to mark them as other. If there are items that you do not wish to set for other, select them and click “Remove from list”.`}</Description>
      </TitleContainer>
    );
  }
  if (status === "give-to-staff") {
    return (
      <TitleContainer>
        <Title>Suggested Give to Staff list</Title>
        <Description>
          We've prepared this list of items to offer back to your staff. Select items and click 'Set
          to give to Staff' to mark them as given to staff. If there are items that you do not wish
          to give to staff, select them and click “Remove from list”.
        </Description>
      </TitleContainer>
    );
  }
  if (status === "restock") {
    return (
      <TitleContainer>
        <Title>Suggested restock list</Title>
        <Description>
          We've prepared this restocking list for you. Select items and click 'Set to restock' to
          mark them as restocked. If there are items that you do not wish to restock, select them
          and click “Remove from list”.
        </Description>
      </TitleContainer>
    );
  }
  return null;
}
