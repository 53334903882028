import ArrowLeft from "@assets/ui-icons/ArrowLeft";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext } from "react";
import { LostItemCreationContext } from "../../../../../../context";
import theme from "../../../../../../theme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import DescriptionForm from "./DescriptionForm";
import { Wrapper } from "./styles";

type DescriptionInputArgs = {
  reviewUpdate?: boolean;
};

export default function DescriptionInput({ reviewUpdate = false }: DescriptionInputArgs) {
  const { partner, setStep } = useContext(LostItemCreationContext);

  scrollToTop();

  return partner === null ? null : (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Lost something?
      </Text>

      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-md-center">
        Describe your item
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
        The more descriptive you are the better!
      </Text>

      <DescriptionForm reviewUpdate={reviewUpdate} />

      <Button
        form="description-form"
        type="submit"
        data-testid="descriptionInputNextButton"
        aria-label="go-next"
        className="mt-3"
        size="2xl"
        fullWidth
      >
        {reviewUpdate ? "Update" : "Next"}
      </Button>

      <Button
        aria-label="go-back"
        onClick={() => setStep(reviewUpdate ? "review" : "photos")}
        data-testid="backButton"
        variant="outline"
        className="mt-3"
        fullWidth
        icon={
          !reviewUpdate && (
            <ArrowLeft accessibilityTitle="go-back" titleId="description-input-go-back" />
          )
        }
      >
        {reviewUpdate ? "Cancel" : "Go back"}
      </Button>
    </Wrapper>
  );
}
