import styled from "styled-components";

export const ThreeColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 1232px;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  flex: 0 0 100%;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex: 0 0 calc(50% - 21px);
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    flex: 0 0 calc(33.3333% - 27px);
  }
`;
