import { usePagination } from "client/hooks";
import useFetchPartnerItems from "client/hooks/data/partner/useFetchPartnerItems";
import useFetchStorageLocations from "client/hooks/data/partner/useFetchStorageLocations";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import {
  Category,
  FormattedItem,
  ItemStatus,
  PartnerItem,
  PartnerLocation,
  PartnerStorageLocation,
} from "client/types";
import { getCategoryName, getLocationName } from "client/utils/mappers";
import { formattedInventoryId } from "client/utils/stringUtils";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useEffect, useState } from "react";
import { useFilters } from ".";
import { InventoryFilterValues } from "..";
import { dateToISOString } from "../../../../../utils/dateUtils";

export function getStorageLocationName({
  storageLocation,
  currentLocation,
  storageLocations = [],
}: {
  storageLocation: PartnerStorageLocation | string | undefined;
  currentLocation?: {
    id: string;
    name: string;
  };
  storageLocations?: PartnerStorageLocation[];
}) {
  if (!storageLocation) return "-";
  if (typeof storageLocation === "string") {
    if (storageLocation.toUpperCase() === "MOVED" && currentLocation) {
      return `Moved to ${currentLocation.name}`;
    }
    return storageLocations.find(location => location.id === storageLocation)?.name || "-";
  }
  return storageLocation.name;
}

function hydrateItems({
  items,
  categories,
  partnerLocations,
  storageLocations,
}: {
  items: PartnerItem[];
  categories: Category[];
  partnerLocations?: PartnerLocation[];
  storageLocations: PartnerStorageLocation[];
}): FormattedItem[] {
  return items.map(item => {
    return {
      originalItem: item,
      id: item.id,
      inventoryId: formattedInventoryId(item.id),
      name: item.name,
      description: item.description ?? "",
      date: item.collected_at,
      images: item.images.length > 0 ? [item.images[0]] : [],
      status: item.status,
      categoryName: categories && item.category ? getCategoryName(categories, item.category) : "",
      lostLocationName:
        partnerLocations && item.lost_location
          ? getLocationName(partnerLocations, item.lost_location) || ""
          : "",
      storageLocation: getStorageLocationName({
        storageLocation: item.storage_location,
        currentLocation: item.current_location,
        storageLocations,
      }),
    };
  });
}

function generateQuery({
  partnerUserLocation,
  filters,
  itemsPerPage,
  page,
}: {
  partnerUserLocation;
  filters: InventoryFilterValues;
  itemsPerPage: number;
  page: number;
}) {
  const query = {
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    ...(filters.status === "ALL"
      ? {
          status: [ItemStatus.MATCHED, ItemStatus.NO_MATCHES, ItemStatus.HAS_MATCHES],
        }
      : { status: filters.status }),
    category: filters.category === "ALL" ? [] : [filters.category],
    storage_location_id: filters.storageLocation === "ALL" ? [] : [filters.storageLocation],
    lost_location: partnerUserLocation.id || undefined,
    collected_at_before: filters.date_before ? dateToISOString(filters.date_before) : undefined,
    collected_at_after: filters.date_after ? dateToISOString(filters.date_after) : undefined,
    has_moved: filters.has_moved,
  };

  return query;
}

const useItems = () => {
  const [count, setCount] = useState<number | null>(null);
  const { page, pageSize } = usePagination({
    count,
  });
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partnerLocations } = usePartnerLocations(useCurrentPartner());
  const { storageLocations } = useFetchStorageLocations();
  const { filterValues } = useFilters();

  const {
    items,
    isLoading,
    error,
    count: totalCount,
  } = useFetchPartnerItems({
    query: generateQuery({
      partnerUserLocation,
      filters: filterValues,
      itemsPerPage: pageSize,
      page,
    }),
  });

  useEffect(() => {
    if (totalCount) {
      setCount(totalCount);
    }
  }, [totalCount]);

  const categories = useCategories();
  const formattedItems = hydrateItems({
    items: items ?? [],
    categories: categories.data ?? [],
    partnerLocations,
    storageLocations: storageLocations ?? [],
  });

  return {
    items: formattedItems,
    isLoading,
    error,
    count: totalCount ?? 0,
  };
};

export default useItems;
