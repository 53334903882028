import React from "react";
import { isMdQuery } from "../../helpers/mediaQuery";
import {
  CarouselItemBackgroundSize,
  FilledArrowLeft,
  FilledArrowRight,
  NoPhotoImagePlaceholder,
  StyledCarousel,
  StyledCarouselItem,
} from "./styles";

type Dimension = {
  height: number;
  width?: number;
  aspectRatio?: string;
};

type CarouselProps = {
  images: string[];
  touch?: boolean;
  interval?: number | null;
  controls?: boolean;
  indicators?: boolean;
  prevIcon?: React.ReactElement | null;
  nextIcon?: React.ReactElement | null;
  showSingleImage?: boolean;
  dimensions: Dimension & {
    large?: Dimension;
    portrait?: Dimension;
  };
  className?: string;
  itemClassName?: string;
  noIcons?: boolean;
  activeIndex?: number;
  onSelect?: (index: number) => void;
  backgroundSize?: CarouselItemBackgroundSize;
  noImagePlaceholder?: React.ReactElement | null;
  style?: React.CSSProperties;
  id?: string;
  dataTestId?: string;
};

export default function ImageCarousel({
  images,
  showSingleImage,
  dimensions,
  itemClassName,
  prevIcon = <FilledArrowLeft />,
  nextIcon = <FilledArrowRight />,
  interval = null,
  noIcons,
  noImagePlaceholder = <NoPhotoImagePlaceholder $dimensions={dimensions} />,
  id,
  dataTestId,
  ...props
}: CarouselProps) {
  const imagesToShow = showSingleImage ? images.slice(0, 1) : images;
  const isMd = isMdQuery();
  const showIcons = !noIcons && isMd && imagesToShow.length > 1 && props.controls;
  const hasContent = imagesToShow && imagesToShow.length > 0;
  if (!hasContent)
    return React.cloneElement(noImagePlaceholder || <></>, {
      className: props.className,
      style: props.style,
    });

  return (
    <StyledCarousel
      $dimensions={dimensions}
      prevIcon={showIcons ? prevIcon : null}
      nextIcon={showIcons ? nextIcon : null}
      interval={interval}
      key={id}
      data-testid={dataTestId}
      {...props}
    >
      {imagesToShow?.map((image, i) => (
        <StyledCarouselItem
          key={i}
          url={image}
          $dimensions={dimensions}
          className={itemClassName}
          data-testid={dataTestId}
        >
          <span
            role={`img`}
            aria-label={`image ${i + 1} of ${imagesToShow.length}`}
            data-testid={dataTestId}
          />
        </StyledCarouselItem>
      ))}
    </StyledCarousel>
  );
}
