import styled from "styled-components";
import theme from "../../theme";

import React from "react";
import Heading from "../Heading";
import Text from "../Text";

export const FirstSection = styled.div`
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    min-height: 202px;
  }
`;

export const FooterWrapper = styled.footer`
  background-color: ${theme.ui.colors.primary700};
  margin-top: auto;

  > div:first-child {
    width: 100%;
    max-width: ${props => props.theme.ui.widths.contentMax};
  }

  .logo {
    max-width: ${theme.widths.logoWhite};

    img {
      width: 100%;
      height: auto;
      max-width: 220px;
    }
  }

  a:not(.logo) {
    font-family: ${theme.ui.fontFamilies.default};
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 32px;
      @media screen and (min-width: ${theme.breakpoints.lg}) {
        margin-bottom: 0px;
      }
    }
  }
`;

export const LinksDiv = styled.div`
  > div > div:last-child > a:last-child {
    margin-bottom: 0px;
  }
`;

export const BottomSection = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;

  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: ${theme.breakpoints.xxxl}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FooterHeading = styled(props => <Heading {...props} as="h2" variant="h6" />)`
  color: ${theme.ui.colors.gray500};
  font-weight: ${theme.fontWeights.semiBold};
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.43;
  margin-bottom: 16px;
`;

export const StyledSpan = styled(Text).attrs(() => ({
  as: "span",
}))`
  font-weight: ${theme.ui.fontWeights.normal};
  color: ${theme.ui.colors.gray500};
  line-height: 1.5;
`;
