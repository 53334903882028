import React from "react";
import styled from "styled-components";

interface HamburgerProps {
  open: boolean;
  onClick: () => void;
  onKeyPress: (e: React.KeyboardEvent) => void;
}

const StyledDiv = styled.button`
  width: 1.4237rem;
  height: 1.0625rem;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  &.open > div {
    transform: rotate(135deg);
    background-color: ${props => props.theme.colors.primaryBlue};
  }

  &.open > div:before,
  &.open > div:after {
    top: 0;
    transform: rotate(90deg);
    background-color: ${props => props.theme.colors.primaryBlue};
  }
`;

export const HamburgerLine = styled.div`
  position: relative;
  width: inherit;
  height: 0.125rem;
  background-color: ${props => props.theme.colors.white};
  transition: all 0.4s ease;
  padding: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0.375rem;
    height: 0.125rem;
    right: 0;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.4s ease;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -0.375rem;
    height: 0.125rem;
    right: 0;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.4s ease;
  }
`;

export const Hamburger: React.FC<HamburgerProps> = props => {
  return (
    <StyledDiv
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      className={`row mx-0 align-content-center ${props.open && "open"}`}
      aria-label={props.open ? "Close menu" : "Open menu"}
      aria-pressed={props.open}
    >
      <HamburgerLine />
    </StyledDiv>
  );
};
