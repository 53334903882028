import React from "react";
import UserPageLayout from "../../../ui-components/Layouts/UserPage";
import { SubLocationSelectionView } from "./components";
import { Wrapper } from "./styles";

export default function SubLocationSelection() {
  return (
    <UserPageLayout showFooter>
      <Wrapper withCityView>
        <SubLocationSelectionView />
      </Wrapper>
    </UserPageLayout>
  );
}
