import { ApiHandlerRes, useApiHandler } from ".";
import { GetClaimCountByCategoryRequest, PartnerCountResponse } from "../types";
import fetchData from "../utils/fetchData";
import getQueryString from "../utils/getQueryString";

const getClaimCountByCategory = async (
  claimCountReq: GetClaimCountByCategoryRequest,
): Promise<PartnerCountResponse | null> => {
  const queryString = claimCountReq.filters ? getQueryString(claimCountReq.filters) : "";
  return fetchData(
    `/api/partner/partners/${claimCountReq.partnerId}/claims/count-by-category/${queryString}`,
  );
};

export const useGetClaimCountByCategory = (): ApiHandlerRes<
  GetClaimCountByCategoryRequest,
  PartnerCountResponse | null
> => useApiHandler(getClaimCountByCategory);
