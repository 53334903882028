import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${({ theme }) => theme.ui.colors.primary700};
  color: ${({ theme }) => theme.ui.colors.white};

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.md}) {
    padding-block: 28px;
  }

  @media (min-width: ${({ theme }) => theme.ui.breakpoints.lg}) {
    padding-inline: 40px;
  }
`;

export const StyledAnchor = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${({ theme }) => theme.ui.colors.white};
  gap: 12px;
  margin-bottom: 20px;

  &:hover {
    color: ${({ theme }) => theme.ui.colors.white};
    text-decoration: none;
  }
`;
