import { ChevronRight } from "@assets/ui-icons";
import Typography from "client/ui-components/Typography";
import styled from "styled-components";
import { NoPhotoImagePlaceholder } from "../ImageCarousel";

const FOOTER_HEIGHT = 3.75;

const Circle = styled.div`
  width: 80px;
  height: 80px;
  left: 0px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  svg {
    flex: 1;
    height: 40px;
  }
`;

export const Divider = styled.hr`
  border-top: 1px solid ${props => props.theme.colors.gray8};
  width: 100%;
  opacity: 1;
`;

export const MessageWrapper = styled.div`
  color: ${props => props.theme.colors.primaryBlue};
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: ${props => props.theme.fontWeights.default};
`;

export const StyledAccept = styled(Circle)`
  background: ${props => props.theme.colors.primaryBlue};
  box-shadow: 0px 10px 15px rgba(0, 116, 242, 0.3); //TODO add to theme
  svg {
    color: ${props => props.theme.colors.white};
  }
`;

export const StyledDecline = styled(Circle)`
  background: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.red};
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
  svg {
    color: ${props => props.theme.colors.red};
  }
`;

export const StyledFooterActions = styled.div`
  z-index: ${props => props.theme.zIndexes.footer};
  left: 0;
  right: 0;
  bottom: 0;
  height: ${FOOTER_HEIGHT}rem;
  box-shadow: 0px -5px 20px rgb(0 0 0 / 15%);
  border-radius: 200px 200px 0px 0px;
  position: fixed;
  background-color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    left: 35px;
    right: 35px;
    width: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    left: 380px;
    right: 40px;
    width: auto;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 14%;
    position: relative;
    top: -40px;
  }
`;

export const StyledHeader = styled.div`
  font-size: 16px;
  font-family: ${props => props.theme.fontFamilies.heading};
  font-weight: ${props => props.theme.fontWeights.button};
  color: ${props => props.theme.ui.colors.primary700};
  line-height: 30px;
`;

// TODO make this styled(Label), was throwing an error
export const StyledLabel = styled.div`
  color: ${props => props.theme.colors.gray3};
  font-family: ${props => props.theme.fontFamilies.display};
  font-style: normal;
  line-height: 1.25;
  font-weight: ${props => props.theme.fontWeights.default};
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const StyledMatchingCard = styled.div`
  padding-bottom: ${FOOTER_HEIGHT + 2.5}rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    border-radius: 16px;
    padding: 0;
    padding-bottom: 140px;
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    border-radiuµs: ${props => props.theme.borderRadius.lg};
    padding-bottom: 136px;
  }
`;

export const StyledName = styled.div`
  font-family: ${props => props.theme.fontFamilies.paragraph};
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: ${props => props.theme.fontWeights.button};
  color: ${props => props.theme.ui.colors.primary700};
  line-height: 1.5;
`;
export const StyledID = styled.div`
  color: ${props => props.theme.ui.colors.gray600};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
export const StyledStorageLocation = styled.div`
  color: ${props => props.theme.ui.colors.gray600};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export const MatchingCardWrapper = styled.div`
  background-color: white;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    background-color: ${props => props.theme.ui.colors.gray400};
    padding: 20px;
  }
`;

export const ClaimsImageThumbnail = styled.div<{ url: string }>`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;

export const MatchesContainer = styled.div``;

export const StyledChevronRight = styled(ChevronRight)`
  width: 24px;
  height: 24px;
  color: ${props => props.theme.ui.colors.primary700};
  stroke-width: 2;
`;

export const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    border-top: 1px solid ${props => props.theme.ui.colors.gray500};
  }
`;

export const MatchedClaimsImageThumbnail = styled.div<{ url: string }>`
  width: 120px;
  height: 165px;
  border-radius: 6px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 160px;
    height: 220px;
  }
`;

export const MatchedClaimContainer = styled.div`
  padding: 12px;
  display: flex;
  gap: 12px;
  border: 1px solid ${props => props.theme.ui.colors.gray500};
  border-radius: 16px;
  width: 426px;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 320px) {
    border: none;
    padding: 0;
  }
`;

export const StyledMatchName = styled(Typography)`
  max-width: 120px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 150px;
    align-self: center;
  }
`;

export const NewStyledNoPhotoImagePlaceholder = styled(NoPhotoImagePlaceholder)`
  & {
    width: 130px;
    aspect-ratio: 53/83;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 246px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      width: 246px;
      height: 338px;
    }
  }
`;

export const StyledClaimsImageThumbnail = styled(ClaimsImageThumbnail)`
  width: 130px;
  height: 178px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 147px;
    height: 202px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 246px;
    height: 338px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;
