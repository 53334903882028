import ArrowUpRight from "@assets/ui-icons/ArrowUpRight";
import React, { useState } from "react";
import { CustomReturn } from "../../helpers";
import NewStorageLocationModal from "../NewStorageLocationModal";
import { StyledLink } from "./styles";

const MoveReturnsStorageLocationButton = ({ selectedItems }: { selectedItems: CustomReturn[] }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <StyledLink onClick={() => setShowModal(true)} isButton dataTestId="moveReturnsLink">
        <ArrowUpRight />
        <span
          style={{
            verticalAlign: "middle",
          }}
        >
          Move item{selectedItems.length > 1 ? "s" : ""}
        </span>
      </StyledLink>

      <NewStorageLocationModal
        isOpened={showModal}
        setIsOpened={setShowModal}
        selectedItems={selectedItems}
      />
    </>
  );
};

export default MoveReturnsStorageLocationButton;
