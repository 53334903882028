import { ArchiveIcon } from "@assets/ui-icons/ArchiveIcon";
import { EditItemIcon } from "@assets/ui-icons/EditIcon";
import { IconPerson } from "@assets/ui-icons/IconPerson";
import { PrinterIcon } from "@assets/ui-icons/PrinterIcon";
import SuperMatchIcon from "@assets/ui-icons/SuperMatchIcon";
import { getPartnerShipmentById } from "client/actions/partnerShipments";
import { DropdownAlignment } from "client/components/Dropdowns/types";
import theme from "client/theme";
import Text from "client/ui-components/Text";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useShowModals } from "..";
import { ButtonModes } from "../../../../components/Button/Button.types";
import ThreeDotsDropdown from "../../../../components/Dropdowns/ThreeDotsDropdown";
import {
  ItemStatus,
  Match,
  PartnerItem,
  ReturnStatus,
  ShipmentStatus,
  TransactionStatus,
} from "../../../../types";
import Button from "../../../../ui-components/Button";
import ActionDropdown, { DropdownListItem } from "./ActionDropdown";
import { IconLabel } from "./styles";

type ActionsToolbarProps = {
  item: PartnerItem;
  isHeader?: boolean;
  matches?: Match[];
};

const useToolbarOptions = (item: PartnerItem) => {
  const history = useHistory();
  const partnerId = useCurrentPartner();
  const { showModals, setShowModals: setShowModals } = useShowModals();

  const handleActionButton = React.useCallback(() => {
    const statusToModalMapping = {
      [ItemStatus.MATCHED]: "confirmationModal",
      [ItemStatus.NO_MATCHES]: "noMatchesModal",
      [ItemStatus.HAS_MATCHES]: "unreviewedMatchesModal",
      [ItemStatus.COMPLETED]: "confirmationModal",
    } as const;

    if (item?.status && statusToModalMapping[item?.status]) {
      setShowModals({
        ...showModals,
        [statusToModalMapping[item.status]]: true,
      });
    }
  }, [item.status, setShowModals, showModals]);

  const handleArchiveItem = React.useCallback(async () => {
    if (item.return_object?.status === ReturnStatus.PENDING) {
      const shipment = item.return_object?.shipment
        ? await getPartnerShipmentById({
            partnerId: partnerId,
            shipmentId: item.return_object?.shipment,
          })
        : null;
      if (shipment?.status === ShipmentStatus.SHIPPED) {
        return setShowModals({ ...showModals, itemShippedModal: true });
      } else if (
        shipment?.transactions?.find(i => i.status === TransactionStatus.SUCCESS) != undefined
      ) {
        return setShowModals({ ...showModals, refundNeededModal: true });
      }
    }

    setShowModals({ ...showModals, archiveModal: true });
  }, [
    item.return_object?.shipment,
    item.return_object?.status,
    partnerId,
    setShowModals,
    showModals,
  ]);

  const ToolbarOptions = useMemo(() => {
    const options: DropdownListItem[] = [];

    if (
      item.status === ItemStatus.HAS_MATCHES ||
      item.status === ItemStatus.NO_MATCHES ||
      item.status === ItemStatus.MATCHED ||
      (item.status === ItemStatus.COMPLETED && item.return_object?.status === ReturnStatus.PENDING)
    ) {
      options.push({
        label: (
          <IconLabel>
            <IconPerson />
            <span data-testid="returnInPerson">Return in person</span>
          </IconLabel>
        ),
        onClick: () => {
          handleActionButton();
        },
      });
    }

    if (item.status === ItemStatus.HAS_MATCHES || item.status === ItemStatus.NO_MATCHES) {
      options.push({
        label: (
          <IconLabel>
            <EditItemIcon />
            <span data-testid="editDetails">Edit details</span>
          </IconLabel>
        ),
        onClick: () => history.push(`/partner/item/${item.id}/edit`, history.location.state),
        divider: true,
      });
    }
    if (
      item.status === ItemStatus.NO_MATCHES ||
      item.status === ItemStatus.HAS_MATCHES ||
      item.status === ItemStatus.MATCHED ||
      item.return_object?.status === ReturnStatus.PENDING
    ) {
      options.push({
        label: (
          <IconLabel>
            <PrinterIcon />
            <span data-testid="printDetails">Print details</span>
          </IconLabel>
        ),
        onClick: () => print(),
        divider: false,
      });
    }

    if (
      item.status === ItemStatus.NO_MATCHES ||
      item.status === ItemStatus.HAS_MATCHES ||
      item.status === ItemStatus.MATCHED ||
      item.return_object?.status === ReturnStatus.PENDING
    ) {
      options.push({
        label: (
          <IconLabel>
            <ArchiveIcon />
            <span data-testid="archiveItem">Archive item</span>
          </IconLabel>
        ),
        onClick: handleArchiveItem,
        divider: false,
      });
    }

    return options;
  }, [
    item.status,
    item.return_object?.status,
    item.id,
    handleActionButton,
    history,
    handleArchiveItem,
  ]);

  return ToolbarOptions;
};

export default function ActionsToolbar({ item, isHeader, matches }: ActionsToolbarProps) {
  const history = useHistory();
  const ToolbarOptions = useToolbarOptions(item);

  return (
    <>
      {item.status === ItemStatus.HAS_MATCHES ? (
        <div
          className={`d-flex  align-items-center ${isHeader ? "flex-row-reverse" : "flex-row"}`}
          style={
            isHeader
              ? {
                  gap: 20,
                }
              : { width: "100%", gap: 10 }
          }
        >
          <ThreeDotsDropdown
            buttonMode={ButtonModes.secondaryOutline}
            listItems={ToolbarOptions}
            dropdownAlignment={
              isHeader ? DropdownAlignment.BOTTOM_END : DropdownAlignment.TOP_START
            }
          />
          <Button
            style={{ width: "100%" }}
            aria-label="review matches"
            data-testid="reviewMatchesButton"
            size="2xl"
            icon={
              <SuperMatchIcon accessibilityTitle="review-match-icon" titleId="review-match-icon" />
            }
            onClick={() => {
              history.push(`/partner/matches/matching/?itemId=${item.id}`, history.location.state);
            }}
          >
            {(matches?.length || 0) === 1 ? "Review match" : "Review matches"}
          </Button>
          {isHeader && (
            <div className="d-flex flex-row align-items-center" style={{ gap: 12 }}>
              <SuperMatchIcon
                accessibilityTitle="review-match-icon"
                titleId="review-match-icon"
                color={theme.ui.colors.primary500}
                style={{
                  flexShrink: 0,
                }}
              />
              <Text
                as="p"
                fontFamily={"Lato"}
                fontSize={"16px"}
                fontWeight={400}
                color="white"
                style={{
                  whiteSpace: "nowrap",
                  margin: 0,
                }}
              >
                Potential matches for this item!
              </Text>
            </div>
          )}
        </div>
      ) : (
        ToolbarOptions.length > 0 && <ActionDropdown isHeader={isHeader} options={ToolbarOptions} />
      )}
    </>
  );
}
