import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import useCategories from "client/hooks/data/user/useCategories";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { create } from "zustand";
import { useGetPartnerClaimFromId } from "../../../actions/partnerClaims";
import { useGetPartnerItemById } from "../../../actions/partnerItems";
import { useGetMatches, useGetMatchFromId } from "../../../actions/partnerMatches";
import CarouselGallery, { GallerySides } from "../../../components/CarouselGallery";
import Loader from "../../../components/Loader";
import {} from "../../../components/singleViewStyles";
import { isLgQuery } from "../../../helpers/mediaQuery";
import { ItemStatus, MatchStatus, PartnerClaim, PartnerItem } from "../../../types";
import useCurrentPartner from "../../../utils/useCurrentPartner";
import Footer from "./Footer";
import Header from "./Header";
import ModalFlow from "./ModalFlow";
import RightPanel from "./RightPanel";
import { Container, LeftPanel } from "./styles";

/*
 * Note:
 * This component used to serve both Item and Claim details pages,
 * but now it is only used for Item details.
 * The Claims details page uses PartnerSingleClaimView.
 */

export type ShowModalsState = {
  confirmationModal: boolean;
  contactDetailsModal: boolean;
  noMatchesModal: boolean;
  successModal: boolean;
  itemShippedModal: boolean;
  archiveModal: boolean;
  refundNeededModal: boolean;
  unreviewedMatchesModal: boolean;
};

export const useShowModals = create<{
  showModals: ShowModalsState;
  setShowModals: (
    callbackOrState: ((showModals: ShowModalsState) => ShowModalsState) | ShowModalsState,
  ) => void;
}>(set => ({
  showModals: {
    confirmationModal: false,
    contactDetailsModal: false,
    noMatchesModal: false,
    successModal: false,
    itemShippedModal: false,
    refundNeededModal: false,
    archiveModal: false,
    unreviewedMatchesModal: false,
  },
  setShowModals: callbackOrState =>
    set(state => ({
      showModals:
        typeof callbackOrState === "function" ? callbackOrState(state.showModals) : callbackOrState,
    })),
}));

const ItemDetails: React.FC = () => {
  const partnerId = useCurrentPartner();
  const { itemId } = useParams<{ itemId: string }>();
  const categories = useCategories();
  const { partnerLocations } = usePartnerLocations(partnerId);

  const { showModals, setShowModals } = useShowModals();

  const [partnerClaimData, setPartnerClaimData] = useState<PartnerClaim>();
  const [partnerItemData, setPartnerItemData] = useState<PartnerItem>();

  const [getMatches, { data: matches, loading: matchesLoading }] = useGetMatches();
  const [getMatchById, { data: match }] = useGetMatchFromId();

  const [getPotentialMatches, { data: potentialMatches, loading: potentialMathcesLoading }] =
    useGetMatches();

  const [getPartnerClaimFromId, { data: partnerClaimDataResp, loading: partnerClaimDataLoading }] =
    useGetPartnerClaimFromId();
  const { data: partnerItemDataResp } = useGetPartnerItemById({
    partnerId: partnerId,
    itemId: itemId,
    filters: {
      detailed_fields: "storage_location",
    },
  });

  const artifactLocation = partnerLocations?.find(
    location => location.id === partnerItemData?.lost_location,
  );

  const isLg = isLgQuery();

  // Reset all modals when the component mounts
  useEffect(() => {
    setShowModals(state => ({
      ...state,
      confirmationModal: false,
      contactDetailsModal: false,
      noMatchesModal: false,
      successModal: false,
      unreviewedMatchesModal: false,
      inReturnModal: false,
    }));
  }, []);

  // If we do get a partnerClaimDataResp set that to partnerClaimData
  // This claim data is used in the case of "Return in person"
  useEffect(() => {
    if (partnerClaimDataResp) {
      setPartnerClaimData(partnerClaimDataResp);
    }
  }, [partnerClaimDataResp]);

  // If we do get a partnerItemDataResp set that to partnerItemData
  useEffect(() => {
    if (partnerItemDataResp) {
      setPartnerItemData(partnerItemDataResp);
    }
  }, [partnerItemDataResp]);

  useEffect(() => {
    if (
      partnerItemData &&
      partnerItemData.status === ItemStatus.MATCHED &&
      !matches &&
      !matchesLoading
    ) {
      getMatches({
        partnerId: partnerId,
        filters: {
          item: partnerItemData.id,
          status: MatchStatus.MATCHED,
        },
      });
    }
  }, [partnerItemData]);

  useEffect(() => {
    if (partnerItemData?.status === ItemStatus.COMPLETED && partnerItemData?.return_object?.match) {
      getMatchById({
        partnerId: partnerId,
        matchId: partnerItemData.return_object.match,
      });
    }
  }, [partnerItemData]);

  useEffect(() => {
    if (partnerItemData && !potentialMatches && !potentialMathcesLoading) {
      getPotentialMatches({
        partnerId: partnerId,
        filters: {
          item: partnerItemData.id,
          status: MatchStatus.PENDING,
        },
      });
    }
  }, [partnerItemData]);

  useEffect(() => {
    if (matches && matches.count > 0 && !partnerClaimData && !partnerClaimDataLoading) {
      void getPartnerClaimFromId({
        partnerId: partnerId,
        claimId: matches.results[0].claim,
      });
    } else if (match && !partnerClaimData && !partnerClaimDataLoading) {
      void getPartnerClaimFromId({
        partnerId: partnerId,
        claimId: match.claim,
      });
    }
  }, [matches, match]);

  return (
    <>
      {partnerItemData && artifactLocation && partnerItemData.collected_at ? (
        <Container>
          <Header item={partnerItemData} matches={potentialMatches?.results ?? []} />
          <LeftPanel className="no-print">
            <CarouselGallery
              gallerySide={GallerySides.Bottom}
              images={partnerItemData.images}
              hideGallery={!isLg}
              dimensions={
                isLg
                  ? {
                      height: 460,
                      width: 340,
                    }
                  : {
                      height: 380,
                      width: 280,
                    }
              }
            />
          </LeftPanel>
          <RightPanel
            partnerItemData={partnerItemData}
            artifactLocation={artifactLocation}
            partnerClaimData={partnerClaimData}
          />

          {/* Modal to confirm that the item should be returned now */}
          <ModalFlow
            artifactData={partnerItemData}
            categories={categories.data ?? []}
            dateLostFound={partnerItemData.collected_at}
            matchId={matches && matches.results.length > 0 ? matches.results[0].id : match?.id}
            partnerClaimData={partnerClaimData}
            partnerId={partnerId}
            setShowModals={setShowModals}
            showModals={showModals}
          />
          {!isLg && <Footer item={partnerItemData} matches={potentialMatches?.results ?? []} />}
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ItemDetails;
