import { ImageCarousel } from "client/ui-components";
import React from "react";
import { ArtifactImage } from "../../types/models";
import {
  DescriptionContainer,
  MatchedClaimContainer,
  StyledHeader,
  StyledID,
  StyledName,
  StyledStorageLocation,
} from "./styles";

interface MatchedArtifactDetailsSnapshotProps {
  className?: string;
  heading: string;
  images: ArtifactImage[];
  name: string;
  id?: string | null;
  storageLocation?: string | null;
  style?: React.CSSProperties;
  datatestid?: string;
}

const MatchedArtifactDetailsSnapshot: React.FC<MatchedArtifactDetailsSnapshotProps> = props => {
  const { heading, images, name, id, storageLocation, ...rest } = props;

  return (
    <MatchedClaimContainer {...rest}>
      <ImageCarousel
        images={images.map(i => i.image)}
        dimensions={{ height: 130, width: 96 }}
        style={{ flexShrink: 0 }}
        showSingleImage={true}
        indicators={false}
        interval={null}
      />
      <DescriptionContainer>
        <StyledHeader data-testid="heading">{heading}</StyledHeader>
        <StyledName>{name}</StyledName>
        {id ? <StyledID>ID: {id}</StyledID> : null}
        {storageLocation ? (
          <StyledStorageLocation>Storage location: {storageLocation}</StyledStorageLocation>
        ) : null}
      </DescriptionContainer>
    </MatchedClaimContainer>
  );
};

export default MatchedArtifactDetailsSnapshot;
