import { PartnerItemsCountByUnclaimedStatusResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import { formatFilters } from "../partnerItemCounts";
import { initialUnclaimedStatusFilter } from "../partnerItemCounts/useUnclaimedStatusCounts";

export default ({
  partnerId,
  queryObject,
}): Promise<PartnerItemsCountByUnclaimedStatusResponse> => {
  const query = formatFilters({
    ...queryObject,
    unclaimed_status: initialUnclaimedStatusFilter.value,
  });

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/count-by-unclaimed-status/?${query}`,
  });
};
