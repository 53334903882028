export const breakpoints = {
  xs: "372px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  navFits: "1081px",
  xl: "1200px",
  xxl: "1440px",
  xxxl: "1650px",
  xsHeight: "564px",
  smHeight: "680px",
} as const;

export const borderRadius = {
  sm: "6px", // Mocks have border radius of 6.1px and 6.53px, Not going to add them all to the theme.
  navItem: "8px",
  md: "12px",
  rounded: "50px",
} as const;

export const colors = {
  error300: "#FDA29B",
  error400: "#FECDCA",
  error500: "#B01121",
  error600: "#7D1923",
  gray200: "#EAECF0",
  gray300: "#F8F9FA",
  gray400: "#F2F4F5",
  gray500: "#DCE1E8",
  gray600: "#525860",
  gray700: "#2F3237",
  gray900: "#150F04",
  primary300: "#F3F9FF",
  primary400: "#D1E7FF",
  primary500: "#0772E4",
  primary600: "#0254AD",
  primary700: "#00174A",
  secondary300: "#F3FFF5",
  secondary400: "#9DD1BB",
  secondary500: "#06844F",
  secondary600: "#056B40",
  secondary700: "#03331F",
  success300: "#F3FFF5",
  success400: "#9DD1BB",
  success500: "#06844F",
  success600: "#056B40",
  success700: "#03331F",
  yellow400: "#FEF0C7",
  yellow500: "#ECB205",
  yellow600: "#5D4504",
  white: "#ffffff",
  transparent: "transparent",
  modalOverlay: "#00000099",
} as const;
export const fontFamilies = {
  default: "Lato",
  heading: "Poppins",
} as const;

export const fontSizes = {
  xs: ".75rem", // 12px
  sm: ".875rem", // 14px
  base: "1rem", // 16px
  md: "1.125rem", // 18px
  lg: "1.25rem", // 20px
  xl: "1.5rem", // 24px
  xxl: "2rem", // 32px
  big: "2.25rem", // 36px
  bigger: "3rem", // 48px
  biggest: "5.3125rem", // 85px
} as const;
export const fontWeights = {
  normal: 400,
  semi: 600,
  bold: 700,
  black: 800,
} as const;

export const heights = {
  navBase: "72px",
  navLg: "80px",
} as const;

export const shadows = {
  button: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  input: "0px 1px 2px rgba(16, 24, 40, 0.05)",
} as const;
export const transitions = {
  textSizeColor: "color, font-size 200ms ease-in",
} as const;

export const widths = {
  contentMax: "1280px",
} as const;

export const ui = {
  body: {
    fontSize: { base: fontSizes.base },
    lineHeight: { base: 1.5 },
  },
  description: {
    color: colors.primary700,
    fontSize: fontSizes.lg,
    lineHeight: 1.5,
    responsive: {
      fontSize: {
        base: fontSizes.base,
        sm: fontSizes.lg,
      },
      lineHeight: {
        base: 1.5,
      },
    },
  },
};

const uiTheme = {
  breakpoints,
  borderRadius,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  heights,
  shadows,
  transitions,
  widths,
} as const;

export default uiTheme;
