import React, { useState } from "react";

import ImageCarousel from "../../ui-components/ImageCarousel";
import ImageGallery from "../ImageGallery";

export enum GallerySides {
  Left,
  Bottom,
}

export enum GalleryImageSizes {
  LeftGalleryWidth = "80px",
  LeftGalleryHeight = "100px",
  BottomGalleryWidth = "103px",
  BottomGalleryHeight = "85px",
}

export interface CarouselGalleryProps {
  gallerySide?: GallerySides;
  hideCarousel?: boolean;
  hideGallery?: boolean;
  images?: any[];
  className?: string;
  dimensions?: {
    height: number;
    width: number;
  };
}

const CarouselGallery: React.FC<CarouselGalleryProps> = props => {
  const { className, gallerySide, hideCarousel, images, hideGallery, dimensions } = props;

  const [index, setIndex] = useState(0);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };

  return (
    <div className={className}>
      {!hideCarousel && (
        <>
          {!hideGallery && gallerySide === GallerySides.Left && (
            <div className="col-4">
              <ImageGallery
                images={images}
                imageWidth={GalleryImageSizes.LeftGalleryWidth}
                imageHeight={GalleryImageSizes.LeftGalleryHeight}
                verticalPadding={"8px"}
                currentIndex={index}
                onImageClick={index => {
                  handleSelect(index);
                }}
              />
            </div>
          )}
          <ImageCarousel
            touch
            indicators={(images?.length ?? 0) > 1}
            controls={(images?.length ?? 0) > 1}
            activeIndex={index}
            className={`${gallerySide === GallerySides.Left ? "mt-2" : "ml-2"} mx-auto`}
            dimensions={
              dimensions ?? {
                height: 378,
                width: 280,
              }
            }
            images={images?.map(image => image.image) ?? []}
            onSelect={handleSelect}
          />
        </>
      )}
      {!hideGallery && gallerySide === GallerySides.Bottom && (images?.length ?? 0) > 0 && (
        <div
          className="row mt-35"
          style={{
            width: `min(${dimensions?.width ?? 340}px, 100%)`,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="col-12" style={{ padding: 0 }}>
            <ImageGallery
              images={images}
              imageWidth={GalleryImageSizes.BottomGalleryWidth}
              imageHeight={GalleryImageSizes.BottomGalleryHeight}
              currentIndex={index}
              onImageClick={index => {
                handleSelect(index);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselGallery;
