import styled from "styled-components";

export const Table = styled.table`
  height: fit-content;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
  font-family: ${props => props.theme.fontFamilies.paragraph};
  font-size: 14px;
  font-weight: 400;
  border: none;
  max-width: 1070px;
  color: ${props => props.theme.ui.colors.primary700};
`;

export const TableHeader = styled.thead`
  line-height: 20px;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  &.hover-bg:hover {
    background-color: ${props => props.theme.colors.lightBlue}80;
  }
  &:last-child {
    border-bottom: none;
  }

  &.hover-bg {
    cursor: pointer;
  }

  & > td {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  /* If it is the checkbox */
  & > td:first-child > input {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const TableHead = styled.th`
  padding: 16px 0 8px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.gray6};
  font-weight: 400;
  line-height: 20px;

  &:first-child {
    padding-left: 20px;
  }
  &#select {
    padding-left: 0px;
  }
`;

export const TableCell = styled.td`
  color: ${props => props.theme.ui.colors.primary700};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 400;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  word-wrap: anywhere;
  line-height: 20px;
  &:first-child {
    padding-left: 20px;
  }
  &:has(.form-check) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 12px 0;
  font-weight: 700;
  gap: 14px;
  font-family: ${props => props.theme.fontFamilies.paragraph};
  line-height: 24px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 34px;
  background-color: ${props => props.theme.ui.colors.gray500} !important;
  flex-shrink: 0;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  background-color: ${props => props.theme.ui.colors.white};
  padding: 12px;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;

  & > div:first-child:has(.form-check) {
    padding-left: 0;
    width: 20px;
  }

  @media (max-width: 500px) {
    align-items: flex-start;
    gap: 0px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  padding: 0 0 0 20px;
  max-width: 42px;

  & > div > input {
    cursor: pointer;
  }
`;

export const BottomActionContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 40px 0px;
`;
