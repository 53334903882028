import EmailLink from "client/components/EmailLink";
import React from "react";
import theme from "../../../../../theme";
import { Text } from "../../../../../ui-components";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  title: string;
  content?: string;
  isLink?: boolean;
};

export default function TextDisplay({ title, content, isLink, ...props }: Props) {
  return (
    <div {...props}>
      <Text as="p" color={theme.ui.colors.gray700} fontWeight={400} className="m-0 mb-25">
        {title}
      </Text>

      {content === undefined ? null : isLink ? (
        <EmailLink email={content} fontFamily="Lato" fontWeight={700} />
      ) : (
        <Text as="p" className="m-0" fontWeight={700}>
          {content}
        </Text>
      )}
    </div>
  );
}
