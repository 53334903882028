import { PartnerItemsCountByCategoryResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import { formatFilters } from "../partnerItemCounts";
import { defaultCategoryFilter } from "../partnerItemCounts/useCategoryCounts";

export default ({
  partnerId,
  queryObject,
}: {
  partnerId: string;
  queryObject: Record<string, any>;
}): Promise<PartnerItemsCountByCategoryResponse> => {
  const query = formatFilters({
    ...queryObject,
    category: defaultCategoryFilter.value,
  });
  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/count-by-category/?${query}`,
  });
};
