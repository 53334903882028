import styled from "styled-components";

export const StatusBarWrapper = styled.div<{
  backgroundColor: string;
}>`
  padding: 12px 20px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.theme.ui.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-inline: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;
