import React, { useContext, useRef, useState } from "react";

import useCategories from "client/hooks/data/user/useCategories";
import scrollToTop from "client/utils/scrollToTop";
import { BigChevronRight } from "../../../../../../assets/icons/icons";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import Loader from "../../../../../../components/Loader";
import { LostItemCreationContext } from "../../../../../../context";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import { StyledIncorrectMessage } from "../OTP/styles";
import { lostItemCategoryCopy } from "./content";
import { AdditionalCategoryOption, AdditionalCategoryOptionsContainer, Content } from "./styles";

type AdditionalCategorySelectionArgs = {
  reviewUpdate?: boolean;
};

const OTHER_CATEGORY_ID = "other";

export default function AdditionalCategorySelection({
  reviewUpdate = false,
}: AdditionalCategorySelectionArgs) {
  const isMd = isMdQuery();
  const categories = useCategories();
  const { category, setCategory, setStep, prevCategory, setPrevCategory } =
    useContext(LostItemCreationContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const radioRefs = useRef<HTMLInputElement[]>([]);
  const labelRefs = useRef<HTMLLabelElement[]>([]);
  const [activeStates, setActiveStates] = useState(new Array(categories.data?.length).fill(false));

  const nextStep = reviewUpdate ? "review" : "photos";

  scrollToTop();

  const onSelect = category => {
    setHasError(false);
    setCategory(category);
  };

  const handleKeyDown = (event, currentIndex) => {
    const prevIndex = currentIndex === 0 ? labelRefs.current.length - 1 : currentIndex - 1;
    const nextIndex = currentIndex === labelRefs.current.length - 1 ? 0 : currentIndex + 1;

    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      event.preventDefault();
      labelRefs.current[prevIndex].focus();
    } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      event.preventDefault();
      labelRefs.current[nextIndex].focus();
    }
  };

  const handleOptionClick = index => {
    setActiveStates(prevActiveStates => {
      const newActiveStates = [...prevActiveStates];
      newActiveStates[index] = true;
      return newActiveStates;
    });
    setTimeout(() => {
      setActiveStates(prevActiveStates => {
        const newActiveStates = [...prevActiveStates];
        newActiveStates[index] = false;
        return newActiveStates;
      });
    }, 100);
  };

  const additionalCategoryOptions = categories.data ? (
    categories.data
      .filter(({ icon }) => !icon)
      .map(({ id, name }, index) => (
        <>
          <input
            ref={el => (radioRefs.current[index] = el!)}
            type="radio"
            name="category"
            className="form-check-input"
            id={`category-${id}`}
            onChange={() => onSelect([id, name])}
            checked={category[0] === id}
          />
          <AdditionalCategoryOption
            className={activeStates[index] ? "active" : ""}
            autoFocus={index === 0}
            ref={el => (labelRefs.current[index] = el!)}
            htmlFor={`category-${id}`}
            data-testid={`category-${id}`}
            tabIndex={index === 0 ? 0 : -1}
            onFocus={() => {
              onSelect([id, name]);
              handleOptionClick(index);
            }}
            onKeyDown={e => handleKeyDown(e, index)}
            onClick={() => handleOptionClick(index)}
          >
            <Text textAlign={"left"} fontWeight={fontWeights.normal}>
              {name}
            </Text>
            <BigChevronRight
              accessibilityTitle="Previous"
              titleId="PreviousChevronPaginationTitle"
            />
          </AdditionalCategoryOption>
        </>
      ))
  ) : (
    <Loader />
  );

  return (
    <Content>
      <Text
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.subheading}
      </Text>
      <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
        {lostItemCategoryCopy.heading}
      </Heading>
      <Text
        fontSize={{ base: fontSizes.base }}
        fontWeight={fontWeights.normal}
        lineHeight={1.5}
        textAlign={isMd ? "center" : "left"}
      >
        {lostItemCategoryCopy.supportingText}
      </Text>
      <AdditionalCategoryOptionsContainer
        hasError={hasError}
        className="mt-3"
        data-testid="additionalCategoryOptionsContainer"
        onKeyDown={e => {
          if (e.key === "Enter") {
            if (!categories.data?.map(({ id }) => id).includes(category[0])) setHasError(true);
            else {
              setStep(nextStep);
            }
          }
        }}
      >
        {additionalCategoryOptions}
      </AdditionalCategoryOptionsContainer>

      {hasError && (
        <StyledIncorrectMessage data-testid="requiredErrorMessage">
          {lostItemCategoryCopy.categoryRequiredErrorMessage}
        </StyledIncorrectMessage>
      )}

      <Button
        data-testid="nextButton"
        aria-label="next button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        type="submit"
        onClick={() => {
          if (category[0] === OTHER_CATEGORY_ID) setHasError(true);
          else {
            setPrevCategory(category);
            setStep(nextStep);
          }
        }}
      >
        {reviewUpdate ? lostItemCategoryCopy.nextButtonUpdate : lostItemCategoryCopy.nextButton}
      </Button>

      <Button
        aria-label="back button"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        data-testid="backButton"
        variant="outline"
        onClick={() => {
          setCategory(prevCategory);
          reviewUpdate ? setStep("categoryUpdate") : setStep("category");
        }}
      >
        <ArrowLeft
          className="m-2"
          accessibilityTitle="Go back"
          titleId="GoBackArrowLeftTitle"
          height="20px"
        />
        <Text fontWeight={fontWeights.bold}>{lostItemCategoryCopy.previousButton}</Text>
      </Button>
      {reviewUpdate && category[0] !== "" && (
        <Button
          aria-label="cancel button"
          fullWidth={true}
          size={isMd ? "xl" : "2xl"}
          className="mt-3"
          data-testid="cancelButton"
          variant="outline"
          onClick={() => {
            if (prevCategory[0] === OTHER_CATEGORY_ID) return setHasError(true);
            setCategory(prevCategory);
            setStep("review");
          }}
        >
          <Text fontWeight={fontWeights.bold}>{lostItemCategoryCopy.previousButtonUpdate}</Text>
        </Button>
      )}
    </Content>
  );
}
