import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

// ACTIONS
import { useCreatePartnerClaim } from "../../../actions/partnerClaims";
import {
  useCreateMatch,
  useSendAcceptMatch,
  useSendCompleteMatch,
} from "../../../actions/partnerMatches";
import { useCreateUser } from "../../../actions/partners";

// COMPONENTS
import PickupConfirmationInterior from "../Returns/PickupConfirmationInterior";

// CONTEXT

// TYPES / THEME
import { Category, ItemStatus, PartnerClaim, PartnerItem } from "../../../types";

import { useQueryClient } from "@tanstack/react-query";
import { getPartnerItemFromId } from "client/actions/partnerItems";
import queryFactory from "client/hooks/data/partner/queryFactory";
import { useHistory } from "react-router-dom";
import { ShowModalsState } from ".";
import { updatePartnerReturn, useCompletePartnerReturn } from "../../../actions/partnerReturns";
import { isLgQuery } from "../../../helpers/mediaQuery";
import {
  ContactDetailsRequest,
  CreatePartnerClaimRequest,
  ReturnModeEnum,
  ReturnStatus,
} from "../../../types/models";
import {
  ConfirmPickupModal,
  ContactDetailsModal,
  ItemShippedModal,
  RefundNeededModal,
  SuccessModal,
  UnreviewedMatchesModal,
  WarningModal,
} from "./Modals";
import ArchiveModal from "./Modals/ArchiveModal";

interface ModalFlowProps {
  artifactData: PartnerClaim | PartnerItem;
  categories: Category[];
  dateLostFound?: Date;
  matchId?: string;
  partnerClaimData?: PartnerClaim;
  partnerId: string;
  setShowModals: (value: React.SetStateAction<ShowModalsState>) => void;
  showModals: ShowModalsState;
}

const ModalFlow: React.FC<ModalFlowProps> = props => {
  const {
    artifactData,
    categories,
    dateLostFound,
    matchId,
    partnerClaimData,
    partnerId,
    setShowModals,
    showModals,
  } = props;

  const isLg = isLgQuery();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [userContactDetails, setUserContactDetails] = useState<ContactDetailsRequest>({
    email: "",
    first_name: "",
    full_name: "",
    last_name: "",
    phone_number: "",
  });

  // API Calls in order of operation
  const [
    createUser,
    { data: createUserResponse, loading: createUserLoading, error: createUserError },
  ] = useCreateUser();

  const [
    createPartnerClaim,
    { data: createClaimResponse, loading: createClaimLoading, error: createClaimError },
  ] = useCreatePartnerClaim();

  const [
    createMatch,
    { data: createMatchResponse, loading: createMatchLoading, error: createMatchError },
  ] = useCreateMatch();

  const acceptMatch = useSendAcceptMatch(partnerId);

  const [
    completeMatch,
    { data: completeMatchData, loading: completeMatchLoading, error: completeMatchError },
  ] = useSendCompleteMatch();

  const [
    completeReturn,
    { data: completeReturnData, loading: completeReturnLoading, error: completeReturnError },
  ] = useCompletePartnerReturn();

  const isLoading =
    createUserLoading ||
    createClaimLoading ||
    createMatchLoading ||
    completeMatchLoading ||
    completeReturnLoading ||
    acceptMatch.isPending;

  // USE EFFECTS in order of operation

  /**
   * STEP 2: Create Partner Claim
   * TRIGGER: Create User Success / Error (on error the user already exists)
   *
   * Create a new claim that is an exact copy of the item data
   */
  useEffect(() => {
    if (
      dateLostFound &&
      !createUserLoading &&
      (createUserError !== null || createUserResponse !== null)
    ) {
      const claimRequest: CreatePartnerClaimRequest = {
        partnerId,
        claim: {
          name: artifactData.name,
          description: artifactData.description || "",
          category: artifactData.category,
          lost_location: artifactData.lost_location,
          lost_at: dateLostFound,
          user: userContactDetails.email,
          exclude_from_matching: true,
        },
      };
      createPartnerClaim(claimRequest);
    }
  }, [
    artifactData,
    createUserError,
    createUserLoading,
    createUserResponse,
    dateLostFound,
    partnerId,
    userContactDetails.email,
  ]);

  /**
   * STEP 3: Create Match
   * TRIGGER: Create Partner Claim Success
   *
   * Create a match from the newly created claim and item, do not use the matching engine
   */
  useEffect(() => {
    if (createClaimResponse && !createClaimError && !createClaimLoading) {
      createMatch({
        partnerId,
        match: {
          item: artifactData.id,
          claim: createClaimResponse.id,
        },
      });
    }
  }, [artifactData, createClaimError, createClaimLoading, createClaimResponse, partnerId]);

  /**
   * STEP 4: Accept match
   * TRIGGER: Create Partner Match Success
   *
   * On successful creation of a match, update its status to MATCHED, and update the claims count by status
   */
  useEffect(() => {
    if (createMatchResponse && !createMatchLoading) {
      artifactData.status = ItemStatus.HAS_MATCHES;

      // TODO: BE will make a ticket that can allow us to specify match status when creating a match,
      // this will allow us to skip the accept step in the future
      acceptMatch.mutate({ partnerId, matchId: createMatchResponse.id });
    }
  }, [createMatchLoading, createMatchResponse, partnerId]);

  const completeMatchOrReturn = useCallback(
    ({ matchId }: { matchId?: string }) => {
      const returnId = artifactData?.return_object?.id;
      if (matchId) {
        if (!completeMatchData && !completeMatchLoading) completeMatch(partnerId, matchId);
      } else if (returnId) {
        if (!completeReturnData && !completeReturnLoading) {
          updatePartnerReturn({
            id: returnId,
            partnerId,
            mode: ReturnModeEnum.PICKUP,
          }).finally(() => {
            completeReturn(partnerId, returnId);
          });
        }
      }
    },
    [
      artifactData?.return_object?.id,
      completeMatch,
      completeMatchData,
      completeMatchLoading,
      completeReturn,
      completeReturnData,
      completeReturnLoading,
      partnerId,
    ],
  );

  /**
   * STEP 5: Complete match
   * TRIGGER: Accept match success
   *
   * Complete the match with the provided information
   */
  useEffect(() => {
    const returnId = artifactData?.return_object?.id;
    const matchId = acceptMatch.data?.id;

    if (acceptMatch.isSuccess && (returnId || matchId)) {
      completeMatchOrReturn({ matchId: matchId });
    }
  }, [
    acceptMatch.data?.id,
    acceptMatch.isSuccess,
    artifactData?.return_object?.id,
    completeMatchOrReturn,
    matchId,
    partnerId,
  ]);

  /**
   * STEP 6: Show the success modal
   * TRIGGER: Successful tranistion of match to status COMPLETED
   *
   * Show the success modal and set the status to COMPLETED. Update counts.
   */
  useEffect(() => {
    if (completeMatchData && !completeMatchError) {
      artifactData.status = ItemStatus.COMPLETED;
      queryClient.invalidateQueries({ queryKey: queryFactory.items(partnerId) });

      updatePartnerReturn({
        id: completeMatchData.return,
        partnerId,
        mode: ReturnModeEnum.PICKUP,
      }).then(res => {
        if (res) {
          completeReturn(partnerId, completeMatchData.return);
        }
      });

      setShowModals(prev => ({
        ...prev,
        confirmationModal: false,
        successModal: true,
      }));
    }
  }, [completeMatchData, completeMatchError]);

  useEffect(() => {
    if (completeReturnData) {
      setShowModals(prev => ({
        ...prev,
        confirmationModal: false,
        successModal: true,
      }));
    }
  }, [completeReturnData]);

  // TOAST MESSAGES
  useEffect(() => {
    if (completeMatchError) {
      toast.error("Error completing this pickup.");
    }
  }, [completeMatchError]);

  useEffect(() => {
    if (completeReturnError) {
      toast.error("Error completing this pickup.");
    }
  }, [completeReturnError]);

  useEffect(() => {
    if (acceptMatch.isError) {
      toast.error("Error accepting this match.");
    }
  }, [createMatchError]);

  useEffect(() => {
    if (createClaimError) {
      toast.error("Error creating this claim.");
    }
  }, [createClaimError]);

  useEffect(() => {
    if (createMatchError) {
      toast.error("Error creating this match.");
    }
  }, [createMatchError]);

  return (
    <div className="no-print">
      {/**
       * STEP 0: Open the no matches warning modal
       * TRIGGER: User clicks the return now button and the status of the item is HAS_MATCHES or NO_MATCHES
       */}
      <WarningModal setShowModals={setShowModals} showModal={showModals.noMatchesModal} />
      <UnreviewedMatchesModal
        itemId={artifactData.id}
        setShowModals={setShowModals}
        showModal={showModals.unreviewedMatchesModal}
      />
      {/* Modal to create a new user */}
      <ContactDetailsModal
        closeModal={() => {
          setShowModals(prev => ({
            ...prev,
            contactDetailsModal: false,
          }));
        }}
        showModal={showModals.contactDetailsModal}
        topBtnLoading={createClaimLoading || createMatchLoading || acceptMatch.isPending}
        onNextStep={userDetails => {
          setUserContactDetails({ ...userDetails });
          setShowModals(prev => ({
            ...prev,
            contactDetailsModal: false,
            confirmationModal: true,
          }));
        }}
      />
      {/* Modal to confirm that the item should be returned now */}
      <ConfirmPickupModal
        closeModal={() => {
          setShowModals(prev => ({
            ...prev,
            confirmationModal: false,
          }));
        }}
        children={
          <PickupConfirmationInterior
            artifact={artifactData}
            categories={categories || []}
            contactDetails={(partnerClaimData?.user || userContactDetails)!}
            images={artifactData.images}
          />
        }
        modalLoading={!partnerClaimData && !userContactDetails.email}
        partnerId={partnerId}
        showModal={showModals.confirmationModal}
        onSubmit={async () => {
          const itemData = await getPartnerItemFromId({ itemId: artifactData.id, partnerId });
          const returnObject = itemData?.return_object;
          if (
            itemData?.status === ItemStatus.COMPLETED &&
            returnObject?.status !== ReturnStatus.PENDING
          ) {
            toast.error("This item has already been returned.");
            history.push("/partner/inventory");
            queryClient.invalidateQueries({ queryKey: queryFactory.items(partnerId) });
          } else {
            if (!partnerClaimData?.user) {
              createUser(userContactDetails);
            } else {
              completeMatchOrReturn({
                matchId: itemData?.status === ItemStatus.COMPLETED ? undefined : matchId,
              });
            }
          }
        }}
        isLoading={isLoading}
      />
      {/* Modal to show successful item return */}
      <SuccessModal
        closeModal={() => {
          setShowModals(prev => ({
            ...prev,
            successModal: false,
          }));
        }}
        image={artifactData.images.length > 0 ? artifactData.images[0].image : undefined}
        isLg={isLg}
        showModal={
          showModals.successModal &&
          !completeMatchLoading &&
          !completeMatchError &&
          !completeReturnError
        }
      />
      {/* Modal to show successful item return */}
      <ItemShippedModal
        isLg={isLg}
        showModal={showModals.itemShippedModal}
        closeModal={() => setShowModals(prev => ({ ...prev, itemShippedModal: false }))}
      />
      {/* Modal to show successful item return */}
      <RefundNeededModal
        isLg={isLg}
        showModal={showModals.refundNeededModal}
        closeModal={() => setShowModals(prev => ({ ...prev, refundNeededModal: false }))}
      />
      {/* Modal to show successful item return */}
      <ArchiveModal
        isLg={isLg}
        item={artifactData as any}
        showModal={showModals.archiveModal}
        closeModal={() => setShowModals(prev => ({ ...prev, archiveModal: false }))}
      />
    </div>
  );
};

export default ModalFlow;
