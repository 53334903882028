import styled from "styled-components";

export const SubLocationItem = styled.a`
  position: relative;
  width: 100%;
  background: transparent;
  border: 1px solid ${props => props.theme.ui.colors.gray500};
  outline: 1px solid transparent;
  border-radius: 8px;
  transition:
    background-color,
    border-color,
    outline-color 0.2s ease-in-out;

  &:hover {
    outline-color: ${props => props.theme.ui.colors.primary500};
    border-color: ${props => props.theme.ui.colors.primary500};
    background-color: ${props => props.theme.ui.colors.primary300};
  }

  &:focus,
  &:focus-visible {
    outline-color: ${props => props.theme.ui.colors.primary500};
    border-color: ${props => props.theme.ui.colors.primary500};
  }

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
  }
`;
