import styled from "styled-components";

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const MediaSection = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 51.45%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 46.875%;
  }
`;

export const CopySection = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 48.55%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 53.125%;
  }
`;
