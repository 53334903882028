import { Link } from "client/ui-components";
import React from "react";
import { useHasStorageLocations } from "../../../../../hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { useInventoryStore } from "../../helpers/inventoryStore";

export default function ToggleBulkOptions() {
  const { setHasBulkOptions, hasBulkOptions } = useInventoryStore();
  const hasStorageLocations = useHasStorageLocations();

  if (!hasStorageLocations) return null;

  return (
    <>
      <Link
        isButton
        onClick={() => setHasBulkOptions(!hasBulkOptions)}
        dataTestId="bulkOptionsToggle"
        variant={hasBulkOptions ? "default" : "gray"}
        style={
          (hasBulkOptions
            ? { color: "#0772E4", lineHeight: "20px" }
            : { lineHeight: "20px" }) as React.CSSProperties
        }
      >
        {hasBulkOptions ? "Hide bulk options" : "Show bulk options"}
      </Link>
    </>
  );
}
