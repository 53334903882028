import { regionCodeToName } from "client/utils/countries";
import React, { useContext, useMemo } from "react";
import { LocationPin } from "../../../assets/icons/icons";
import { ClaimDetailsContext } from "../../../context/ClaimDetails";
import theme from "../../../theme";
import { Address } from "../../../types";
import Text from "../../../ui-components/Text";
import { AddressDisplay } from "./styles";

export function ReadyForPickupAddressDisplay() {
  const { claim } = useContext(ClaimDetailsContext);

  const address = useMemo<Address | null>(
    () => (claim === undefined || claim === null ? null : claim.lost_location.address),
    [claim],
  );

  const hydratedAddress = useMemo(() => {
    if (address === null) return "";

    const { address1, address2, postal_code, city, region_code } = address;

    return [
      [address1, address2].join(" "),
      [`${city},`, regionCodeToName(region_code), postal_code].join(" "),
    ];
  }, [address]);

  return (
    <AddressDisplay className="d-flex flex-row p-35 align-items-center">
      <LocationPin
        titleId="location-pin-icon"
        accessibilityTitle="Location Pin Icon"
        color={theme.colors.gray3}
      />

      <Text className="mb-0 ms-25">
        {hydratedAddress[0]} <br /> {hydratedAddress[1]}
      </Text>
    </AddressDisplay>
  );
}
