import React from "react";
import { SVGProps } from "./types";

export default function EmailIcon({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) {
  return (
    <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <title id={titleId}>{accessibilityTitle}</title>
      {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8306 5H19.7143C21.1272 5 22.2725 6.11207 22.2725 7.48387V16.5161C22.2725 17.8879 21.1272 19 19.7143 19H4.8306C3.41778 19 2.27246 17.8879 2.27246 16.5161V7.48387C2.27246 6.11207 3.41778 5 4.8306 5ZM4.35993 6.45113L11.5235 12.3085C11.9563 12.6624 12.5887 12.6624 13.0215 12.3085L20.185 6.45114C20.0411 6.38922 19.8819 6.35481 19.7143 6.35481H4.83061C4.66308 6.35481 4.50383 6.38921 4.35993 6.45113ZM20.8771 7.65648L13.9203 13.3448C12.9681 14.1234 11.5769 14.1234 10.6247 13.3448L3.66782 7.65646V16.5161C3.66782 17.1396 4.18842 17.6451 4.83061 17.6451H19.7143C20.3565 17.6451 20.8771 17.1396 20.8771 16.5161V7.65648Z"
        fill="currentColor"
      />
    </svg>
  );
}
