import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryFactory from "client/hooks/data/partner/queryFactory";
import { ApiHandlerRes, useApiHandler } from ".";
import {
  CreateMatchRequest,
  GetPartnerMatchesRequest,
  GetPartnerMatchRequest,
  Match,
  MatchSearchResponse,
} from "../types";
import fetchData from "../utils/fetchData";
import getQueryString from "../utils/getQueryString";
import { getCommonHeaders } from "../utils/headers";

type StatusResponse = {
  status: string;
};

export const getMatches = async (
  partnerMatchesReq: GetPartnerMatchesRequest,
): Promise<MatchSearchResponse | null> => {
  const queryString = partnerMatchesReq.filters ? getQueryString(partnerMatchesReq.filters) : "/";
  return fetchData(`/api/partner/partners/${partnerMatchesReq.partnerId}/matches/${queryString}`);
};

export const getMatchFromId = async (
  partnerMatchIdReq: GetPartnerMatchRequest,
): Promise<Match | null> => {
  return fetchData(
    `/api/partner/partners/${partnerMatchIdReq.partnerId}/matches/${partnerMatchIdReq.matchId}/`,
  );
};

const createMatch = (match: CreateMatchRequest): Promise<Match | null> => {
  return fetchData(`/api/partner/partners/${match.partnerId}/matches/`, {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(match.match),
  });
};

const sendAcceptMatch = ({
  partnerId,
  matchId,
}: {
  partnerId: string;
  matchId: string;
}): Promise<Match | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/matches/${matchId}/accept/`, {
    method: "POST",
    headers: getCommonHeaders(),
  });
};

const sendCompleteMatch = (partnerId: string, matchId: string): Promise<Match | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/matches/${matchId}/complete/`, {
    method: "POST",
    headers: getCommonHeaders(),
  });
};

const sendDeclineMatch = ({
  partnerId,
  matchId,
}: {
  partnerId: string;
  matchId: string;
}): Promise<Match | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/matches/${matchId}/decline/`, {
    method: "POST",
    headers: getCommonHeaders(),
  });
};

const triggerMatchingForClaim = (
  partnerId: string,
  claimId: string,
): Promise<StatusResponse | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/claims/${claimId}/match-now/`, {
    method: "POST",
    headers: getCommonHeaders(),
  });
};

export const useGetMatches = (): ApiHandlerRes<
  GetPartnerMatchesRequest,
  MatchSearchResponse | null
> => useApiHandler(getMatches);

export const useGetMatchFromId = (): ApiHandlerRes<GetPartnerMatchRequest, Match | null> =>
  useApiHandler(getMatchFromId);

export const useCreateMatch = (): ApiHandlerRes<CreateMatchRequest, Match | null> =>
  useApiHandler(createMatch);

export const useSendAcceptMatch = (partnerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendAcceptMatch,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.items(partnerId),
      });
      queryClient.invalidateQueries({
        queryKey: queryFactory.claims(partnerId),
      });
    },
    onError: error => console.error(error),
  });
};

export const useSendDeclineMatch = (partnerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendDeclineMatch,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.items(partnerId),
      });
    },
    onError: error => console.error(error),
  });
};

export const useSendCompleteMatch = (): ApiHandlerRes<string, Match | null> =>
  useApiHandler(sendCompleteMatch);

export const useTriggerMatchingForClaim = (): ApiHandlerRes<string, StatusResponse | null> =>
  useApiHandler(triggerMatchingForClaim);
