import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Camera } from "./../../assets/icons/icons";

const StyledUpload = styled.div`
  border: 2px solid
    ${props => (props.disabled ? props.theme.colors.disabled : props.theme.colors.primaryBlue)};
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => (props.disabled ? props.theme.colors.disabled : props.theme.colors.primaryBlue)};
  }
  border-radius: ${props => props.theme.borderRadius.sm};
  height: ${props => props.theme.heights.input};
  .addPhotos,
  svg path {
    color: ${props => (props.disabled ? props.theme.colors.gray4 : props.theme.colors.primaryBlue)};
    font-weight: 600;
    line-height: 1.2;
  }
  .optionalText {
    color: ${props => props.theme.colors.gray4};
    font-weight: ${props => props.theme.fontWeights.default};
  }
  &.dz-max-files-reached {
    pointer-events: none;
    cursor: default;
  }
`;

function Dropzone({ setFiles, files, loading, maxFiles, revokeObjectUrl, tabIndex }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".heic", ".heif"],
    },
    maxFiles: maxFiles - files.length,
    noClick: loading,
    tabIndex: tabIndex,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (revokeObjectUrl) {
        files?.forEach(file => URL.revokeObjectURL(file.preview));
      }
    },
    [revokeObjectUrl],
  );

  return (
    <div className={`row mx-0 justify-content-between`}>
      <StyledUpload
        disabled={maxFiles <= files.length || loading}
        {...getRootProps({
          className: `dropzone col ${
            maxFiles === files.length && "d-none d-lg-flex dz-max-files-reached"
          }`,
        })}
      >
        <input aria-label="Upload photos" {...getInputProps()} />
        <Row className={`ps-1 text-primary text-center align-items-center h-100`}>
          <div className="ps-3 d-flex align-items-center">
            <Camera className="me-2" accessibilityTitle="Add photos" titleId="AddPhotosIconTitle" />
            <div className="addPhotos ps-1 pe-2 text-start">
              {maxFiles - files.length === 1
                ? "Add Photo"
                : `Add ${maxFiles === files.length ? "" : maxFiles - files.length} Photos`}
            </div>{" "}
            <div className="optionalText">Optional</div>
          </div>
        </Row>
      </StyledUpload>
    </div>
  );
}

export default Dropzone;
