import styled from "styled-components";

export const Container = styled.div`
  background-color: ${props => props.theme.colors.gray6};
`;

export const Content = styled.main`
  .cursor-pointer {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  color: ${props => props.theme.colors.white};

  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};
  height: fit-content;
  margin-top: -1.2rem;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.md};
  border-radius: ${props => props.theme.borderRadius.lg};
  padding: 0;

  &.header-style {
    height: 126.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
