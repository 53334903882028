import React from "react";

export const EditItemIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 3.99632H6.8C5.11984 3.99632 4.27976 3.99632 3.63803 4.3233C3.07354 4.61092 2.6146 5.06986 2.32698 5.63435C2 6.27609 2 7.11616 2 8.79632V17.1963C2 18.8765 2 19.7166 2.32698 20.3583C2.6146 20.9228 3.07354 21.3817 3.63803 21.6693C4.27976 21.9963 5.11984 21.9963 6.8 21.9963H15.2C16.8802 21.9963 17.7202 21.9963 18.362 21.6693C18.9265 21.3817 19.3854 20.9228 19.673 20.3583C20 19.7166 20 18.8765 20 17.1963V12.9963M7.99997 15.9963H9.67452C10.1637 15.9963 10.4083 15.9963 10.6385 15.9411C10.8425 15.8921 11.0376 15.8113 11.2166 15.7016C11.4184 15.5779 11.5914 15.405 11.9373 15.0591L21.5 5.49632C22.3284 4.66789 22.3284 3.32475 21.5 2.49632C20.6716 1.66789 19.3284 1.66789 18.5 2.49632L8.93723 12.0591C8.59133 12.405 8.41838 12.5779 8.29469 12.7798C8.18504 12.9587 8.10423 13.1538 8.05523 13.3579C7.99997 13.588 7.99997 13.8326 7.99997 14.3218V15.9963Z"
        stroke="#00174A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
