import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  transition:
    max-width,
    padding-bottom 0.3s ease-in-out;

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding-bottom: 164px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;
