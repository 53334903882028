import React from "react";
import { Footer, Navbar } from "../../../ui-components";
import { Layout } from "../styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  showFooter?: boolean;
};

export default function BaseLayout({ children, showFooter = true }: Props) {
  return (
    <Layout>
      <Navbar />

      <main>{children}</main>

      {showFooter && <Footer />}
    </Layout>
  );
}
