import React from "react";
import { Footer, Navbar } from "../..";
import { Partner } from "../../../types";
import { Layout } from "../styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  coBrandNavbarLogoUrl?: Partner["logo"];
  showFooter?: boolean;
};

export default function UserPageLayout({
  children,
  coBrandNavbarLogoUrl,
  showFooter = false,
}: Props) {
  return (
    <Layout>
      <Navbar coBrandNavbarLogoUrl={coBrandNavbarLogoUrl} />

      <main>{children}</main>

      {showFooter && <Footer />}
    </Layout>
  );
}
