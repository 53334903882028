import React from "react";
import { Image } from "../../../typings/endpoints/image";
import { TrashCan } from "../../assets/icons/icons";
import Loader from "../../components/Loader";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { StyledClose, StyledImage, StyledPositioningDiv } from "./styles";

interface NewDropzoneThumbnailsProps {
  className?: string;
  deleteImage: (p: number, id: string) => void;
  canDeleteImages?: boolean;
  imagesLoading?: number;
  images: Image[];
}

const NewDropzoneThumbnails: React.FC<NewDropzoneThumbnailsProps> = ({
  deleteImage,
  canDeleteImages = true,
  images,
  imagesLoading,
}) => {
  const imageCards: Array<React.ReactNode> = [];
  for (let i = 0; i < images.length + (imagesLoading || 0); i++) {
    imageCards.push(
      <>
        <div key={i} className={`px-0`}>
          <StyledPositioningDiv className="position-relative">
            <StyledImage style={{ backgroundImage: `url(${images[i]?.preview})` }}>
              <span role="img" aria-label={`Image ${1 + i} of ${images.length}`} />
              {i >= images.length && <Loader />}
            </StyledImage>
            {canDeleteImages && i < images.length && (
              <StyledClose className="hidden close-btn d-flex align-items-center justify-content-center">
                <TrashCan
                  onClick={() => {
                    deleteImage(i, images[i]?.id);
                  }}
                  onKeyDown={e => {
                    if (e.key === KEYBOARD_KEY.ENTER) {
                      deleteImage(i, images[i]?.id);
                    }
                  }}
                  tabIndex={0}
                  accessibilityTitle="Remove photo"
                  titleId="RemovePhotoXButtonTitle"
                  data-testid="removePhotoXButton"
                />
              </StyledClose>
            )}
          </StyledPositioningDiv>
        </div>
      </>,
    );
  }

  return (
    <div className="mx-0 px-0 d-flex flex-row justify-content-center" style={{ gap: 12 }}>
      {imageCards}
    </div>
  );
};

export default NewDropzoneThumbnails;
