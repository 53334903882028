import React from "react";
import { ShowModalsState } from "..";
import { NoMatchesIcon } from "../../../../assets/icons/icons";
import Modal from "../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../components/Modal/Modal.types";
import { NormalSubtext } from "../../../../components/singleViewStyles";
import theme from "../../../../theme";

interface WarningModalProps {
  setShowModals: (value: React.SetStateAction<ShowModalsState>) => void;
  showModal: boolean;
}

const WarningModal: React.FC<WarningModalProps> = props => {
  const { setShowModals, showModal } = props;
  return (
    <Modal
      bottomBtnText={ModalButtonTextEnum.CANCEL}
      bottomBtnOnClick={() => {
        setShowModals(prev => ({
          ...prev,
          noMatchesModal: false,
        }));
      }}
      header={"This item has no match"}
      icon={
        <NoMatchesIcon
          className="mx-auto mb-25 mt-2"
          color1={theme.colors.gray8}
          color2={theme.colors.gray8}
          height={54}
          width={63}
          accessibilityTitle="No match"
          titleId="NoMatchesModalIconTitle"
        />
      }
      children={
        <NormalSubtext className="mx-auto">
          No matches for this item exist! What do you want to do?
        </NormalSubtext>
      }
      show={showModal}
      showXButton
      topBtnOnClick={() => {
        setShowModals(prev => ({
          ...prev,
          noMatchesModal: false,
          contactDetailsModal: true,
        }));
      }}
      topBtnText={"Return anyway"}
    />
  );
};

export default WarningModal;
