import React from "react";

import { BoomerangArrowRight } from "../../assets/icons/icons";
import Badge, { BadgeSizesEnum } from "../../components/Badge";
import { NoPhotoImagePlaceholder } from "../../components/ImageCarousel";
import { TextBold } from "../../components/typography";
import { isLgQuery } from "../../helpers/mediaQuery";
import { BadgeVariantsEnum } from "../Badge/index";
import {
  InfoWrapper,
  PhotoCarousel,
  StyledCarouselItem,
  StyledLabel,
  StyledListItem,
} from "./styles";

interface ItemSnapshotProps {
  active?: boolean;
  additionalComponent?: React.ReactNode;
  additionalMessage?: React.ReactNode;
  categoryName: string;
  className?: string;
  highlight?: boolean;
  icon?: React.ReactNode;
  images?: any[];
  snapshotKey: number;
  label?: string;
  name: string;
  onClick?: () => void;
  $showSingleImage?: boolean;
  useLgCarousel?: boolean;
  maxHeight?: number;
}

const ItemSnapshot: React.FC<ItemSnapshotProps> = props => {
  const {
    active,
    additionalComponent,
    additionalMessage,
    categoryName,
    className,
    highlight,
    icon,
    images,
    snapshotKey,
    label,
    name,
    onClick,
    $showSingleImage,
    useLgCarousel,
    maxHeight,
  } = props;

  const isLg = isLgQuery();

  return (
    <StyledListItem
      className={`${className} ${active ? "active" : ""}`}
      key={snapshotKey}
      cursorPointer={typeof onClick === "function"}
      highlight={highlight}
      onClick={typeof onClick === "function" ? onClick : () => {}}
    >
      <div
        className="d-flex p-0 m-0 w-100 align-items-center"
        style={isLg && !!additionalMessage ? { maxWidth: "50%" } : {}}
      >
        <div className="px-0 col-auto">
          {images && images.length > 0 ? (
            <PhotoCarousel
              touch
              interval={null}
              prevIcon={false}
              nextIcon={false}
              controls={!$showSingleImage && images.length > 1}
              $showSingleImage={$showSingleImage}
              indicators={!$showSingleImage && images.length > 1}
              className={`${useLgCarousel ? "large" : ""} ${additionalComponent ? "portrait" : ""}`}
            >
              {$showSingleImage ? (
                <StyledCarouselItem
                  key={0}
                  style={{ backgroundImage: `url(${images[0]?.image})` }}
                  className={`${useLgCarousel ? "large" : ""} ${
                    additionalComponent ? "portrait" : ""
                  }`}
                >
                  <span role="img" aria-label={`image of ${name}`} />
                </StyledCarouselItem>
              ) : (
                (images || []).map((file, index) => (
                  <StyledCarouselItem
                    key={index}
                    style={{ backgroundImage: `url(${file?.image})` }}
                    className={`${useLgCarousel ? "large" : ""} ${
                      additionalComponent ? "portrait" : ""
                    }`}
                  >
                    <span role="img" aria-label={`image ${index + 1} of ${images.length}`} />
                  </StyledCarouselItem>
                ))
              )}
            </PhotoCarousel>
          ) : (
            <NoPhotoImagePlaceholder
              className="w-100 d-flex align-items-stretch h-100"
              width={useLgCarousel ? "140px" : additionalComponent ? "159px" : "100px"}
              square={!additionalComponent}
            >
              <span role="img" aria-label={`No image placeholder image`} />
            </NoPhotoImagePlaceholder>
          )}
        </div>
        <InfoWrapper
          className={`d-flex flex-column mx-0 w-100 align-items-stretch ${
            additionalComponent ? "align-content-between" : ""
          } pe-0 ps-35`}
          style={{
            maxHeight: maxHeight ? `${maxHeight}px` : "auto",
          }}
        >
          <div className="d-flex flex-column p-0 w-100">
            {!!additionalComponent && label && (
              <StyledLabel className="p-0 mb-25">{label}</StyledLabel>
            )}
            <div className="px-0 d-flex align-items-start mb-25 w-100 justify-content-between">
              <Badge
                variant={BadgeVariantsEnum.Contained}
                className="text-truncate text-nowrap fw500"
                size={BadgeSizesEnum.Small}
                label={categoryName}
              />
              {icon ||
                (active && (
                  <BoomerangArrowRight
                    accessibilityTitle="Active found item or claim"
                    titleId="ActiveItemSnapshotTitle"
                  />
                ))}
            </div>
            <div className="px-0">
              <TextBold className="pe-35 mb-35">{name}</TextBold>
            </div>
            {!additionalComponent && label && (
              <StyledLabel className="p-0" data-testid="itemInventoryId">
                {label}
              </StyledLabel>
            )}
          </div>
          {!isLg && additionalMessage}
          {!!additionalComponent && additionalComponent}
        </InfoWrapper>
      </div>
      {isLg && additionalMessage}
    </StyledListItem>
  );
};

export default ItemSnapshot;
