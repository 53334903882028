import classNames from "classnames";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import React, { useContext } from "react";
import { PartnerClaimContext } from "../../../../../context";
import { ResultType } from "../../../../../pages/PartnerGlobalSearch";
import { PartnerClaimCanceledReasonEnum, PartnerClaimStatusEnum } from "../../../../../types";
import { Heading, Text } from "../../../../../ui-components";
import StatusBadge from "../../../../../ui-components/StatusBadge";
import TextDisplay from "../TextDisplay";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function ClaimStatus({ className, ...props }: Props) {
  const { claim } = useContext(PartnerClaimContext);
  const dateFormatter = useDateFormatter();

  return claim === null ? null : (
    <div
      {...props}
      className={classNames("d-flex flex-column gap-35", className)}
      data-testid="claimStatusSection"
    >
      <Heading variant="h4">Claim status</Heading>

      <div className="d-flex" data-testid="badgeStatus">
        <StatusBadge
          objectType={ResultType.CLAIMS}
          objectStatus={claim.status}
          returnObject={claim.return_object}
          data-testid="claimStatus"
        />
      </div>

      <div className="d-flex flex-column gap-45 mt-35">
        {PartnerClaimStatusEnum[claim.status] === PartnerClaimStatusEnum.CANCELED ? (
          <>
            <TextDisplay
              title="Canceled"
              content={
                claim?.canceled_at ? dateFormatter(new Date(claim.canceled_at), "long") : "-"
              }
            />

            <TextDisplay
              title="Reason for cancellation"
              content={
                !claim.canceled_reason
                  ? "-"
                  : PartnerClaimCanceledReasonEnum[claim.canceled_reason] ===
                      PartnerClaimCanceledReasonEnum.OTHER
                    ? claim.canceled_reason_other
                    : PartnerClaimCanceledReasonEnum[claim.canceled_reason]
              }
            />
          </>
        ) : (
          <>
            {PartnerClaimStatusEnum[claim.status] === PartnerClaimStatusEnum.HAS_MATCHES && (
              <Text>Don't forget to check out the potential match for this item.</Text>
            )}

            <div className="col-12 col-lg-9">
              <TextDisplay
                title="Claim submitted"
                data-testid="itemDateLostOrFound"
                content={dateFormatter(new Date(claim.created_at), "long")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
