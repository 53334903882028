import React from "react";

function ArrowUpRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke="#0772E4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M5.833 14.167l8.333-8.334m0 0H5.833m8.333 0v8.334"
      ></path>
    </svg>
  );
}

export default ArrowUpRight;
