import { useSearchParam } from "client/hooks";
import { useCategoryCounts } from "client/hooks/data/partner/partnerItemCounts";
import useUnclaimedStatusCounts, {
  initialUnclaimedStatusFilter,
} from "client/hooks/data/partner/partnerItemCounts/useUnclaimedStatusCounts";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { ItemStatus } from "client/types";
import { FilterItem } from "client/ui-components/FilterDropdown/types";
import { stringToDate } from "client/utils/dateUtils";
import { stringToYellingCase } from "client/utils/stringUtils";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { allArchiveUnclaimedStatuses } from ".";
import { ArchiveFilters, ArchiveFilterValues, FilterOptions, initialCategoryFilter } from "..";

const useArchiveFilters: () => {
  filters: ArchiveFilters;
  setFilter: (type: FilterOptions, value: string) => void;
  categories: FilterItem[];
  unclaimedStatuses: FilterItem[];
  hasFilters: boolean;
  resetFilters: () => void;
  filterValues: ArchiveFilterValues;
} = () => {
  const history = useHistory();
  const { partnerUserLocation } = usePartnerLocationStore();
  const [category, setCategory] = useSearchParam<string>("category", "ALL");
  const [dateBefore, setDateBefore] = useSearchParam<string>("date_before", "ALL");
  const [dateAfter, setDateAfter] = useSearchParam<string>("date_after", "ALL");
  const [unclaimedStatus, setUnclaimedStatus] = useSearchParam<string>("unclaimed_status", "ALL");
  const [categoryValue, setCategoryValue] = useState<string>("ALL");
  const filterValues: ArchiveFilterValues = useMemo(
    () => ({
      status: ItemStatus.UNCLAIMED,
      category: categoryValue || "ALL",
      date_before: dateBefore === "ALL" ? null : stringToDate(dateBefore),
      date_after: dateAfter === "ALL" ? null : stringToDate(dateAfter),
      unclaimed_status: unclaimedStatus === "ALL" ? allArchiveUnclaimedStatuses : unclaimedStatus,
    }),
    [categoryValue, dateBefore, dateAfter, unclaimedStatus],
  );

  const countFilters = {
    ...filterValues,
    lost_locations: partnerUserLocation?.id,
  };
  const { categories } = useCategoryCounts(countFilters);
  const { unclaimedStatusCounts: unfilteredUnclaimedStatus } =
    useUnclaimedStatusCounts(countFilters);

  const unclaimedStatusCounts = unfilteredUnclaimedStatus.filter(item => item.value !== "NULL");
  const selectedCategory = useMemo(
    () =>
      categories.find(item => stringToYellingCase(item.name) === category) || initialCategoryFilter,
    [categories, category],
  );

  const selectedUnclaimedStatus = useMemo(
    () =>
      unclaimedStatusCounts.find(item => item.value === unclaimedStatus) ||
      initialUnclaimedStatusFilter,
    [unclaimedStatus, unclaimedStatusCounts],
  );

  useEffect(() => {
    if (selectedCategory) {
      setCategoryValue(selectedCategory.value);
    }
  }, [selectedCategory]);

  function setFilter(type: FilterOptions, value: string) {
    switch (type) {
      case "category":
        setCategory(stringToYellingCase(value));
        break;
      case "date_before":
        setDateBefore(value);
        break;
      case "date_after":
        setDateAfter(value);
        break;
      case "unclaimed_status":
        setUnclaimedStatus(value);
        break;
      default:
        break;
    }
  }

  const filters = useMemo(() => {
    return {
      category: selectedCategory,
      date_before: dateBefore === "ALL" ? null : stringToDate(dateBefore),
      date_after: dateAfter === "ALL" ? null : stringToDate(dateAfter),
      unclaimed_status: selectedUnclaimedStatus,
    };
  }, [selectedCategory, dateBefore, dateAfter, selectedUnclaimedStatus]);

  const hasFilters = useMemo(() => {
    return (
      filters.category.value !== "ALL" ||
      filters.date_before !== null ||
      filters.date_after !== null ||
      filters.unclaimed_status.value !== "ALL"
    );
  }, [filters]);

  const resetFilters = () => {
    history.push({
      search: "",
    });
  };
  return {
    filters,
    setFilter,
    categories,
    unclaimedStatuses: unclaimedStatusCounts,
    hasFilters,
    resetFilters,
    filterValues,
  };
};

export default useArchiveFilters;
