import { PickupReady } from "@assets/icons/icons";
import { ClaimDetailsContext } from "client/context/ClaimDetails";
import { Button, Heading, Modal } from "client/ui-components";
import React, { useContext } from "react";

type Props = {
  isOpened?: boolean;
  setIsOpened: (isOpen: boolean) => void;
};

export function ClaimOptionsModal({ isOpened = false, setIsOpened }: Props) {
  const { claim, shipment, returnResponse } = useContext(ClaimDetailsContext);

  return (
    <Modal
      isOpened={isOpened}
      description=""
      hasCloseButton
      heading={
        <Heading variant="h4" as="h2">
          Claim options
        </Heading>
      }
      setIsOpened={setIsOpened}
      buttons={
        <div className="pt-4 gap-2 w-100 text-center d-flex flex-column">
          <Button
            variant="outline"
            className="mb-1"
            href={`/claim/${claim?.id}/change-to-pickup`}
            aria-label="Pick up instead"
            data-testid="pickUpInstead"
            size="2xl"
            icon={
              <PickupReady
                height={24}
                width={24}
                titleId="ready-for-pickup-icon"
                accessibilityTitle="Ready for pickup icon"
              />
            }
          >
            Pick up instead
          </Button>
          <Button
            aria-label="enter payment details"
            data-testid="enterPaymentDetails"
            href={`/shippingcheckout?return_object=${
              returnResponse?.id || ""
            }&claim_id=${claim?.id}${shipment?.id ? `&shipment_id=${shipment?.id}` : ""}`}
            size="2xl"
          >
            Enter payment details
          </Button>
        </div>
      }
    />
  );
}
