import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useClickAway } from "../../hooks";
import {
  ButtonWrapper,
  CloseButton,
  ModalContent,
  ModalDialog,
  ModalHeader,
  ModalWrapper,
} from "./styles";

export type Props = {
  isOpened: boolean;
  heading: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  buttons?: React.ReactNode;
  hasCloseButton?: boolean;
  setIsOpened: (isOpened: boolean) => void;
  clickAwayCallback?: () => void;
};

export default function Modal({
  isOpened,
  heading,
  hasCloseButton,
  description,
  icon,
  buttons,
  setIsOpened,
  clickAwayCallback,
}: Props): React.ReactPortal | null {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = useState<{ isReady: boolean }>({ isReady: false });

  useClickAway(modalRef, () => {
    if (clickAwayCallback) {
      clickAwayCallback();
    }
    setIsOpened(false);
  });

  useEffect(() => {
    setState(s => ({ ...s, isReady: true }));
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  });

  return !state.isReady
    ? null
    : createPortal(
        <ModalWrapper
          className={classNames("modal fade", isOpened && "show", "modal-dialog-centered")}
          id="modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Modal"
          aria-hidden={!isOpened}
          isOpened={isOpened}
        >
          <ModalDialog ref={modalRef} className="modal-dialog m-0" role="document">
            <ModalContent
              hasIcon={!!icon}
              hasCloseButton={hasCloseButton}
              className="modal-content"
              data-testid="claimOptionsModal"
            >
              <ModalHeader className="modal-header justify-content-end">
                {hasCloseButton && (
                  <CloseButton
                    type="button"
                    className="close"
                    data-testid="closeModalXButton"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setIsOpened(false)}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8332 1.16675L1.1665 12.8334M1.1665 1.16675L12.8332 12.8334"
                        stroke="#00174A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </CloseButton>
                )}
              </ModalHeader>

              <div className="modal-body p-0" style={{ paddingLeft: "64px", paddingRight: "64px" }}>
                {icon && (
                  <div className="text-center pb-25" style={{ paddingTop: "36px" }}>
                    {icon}
                  </div>
                )}

                {heading && <div className="d-flex justify-content-center">{heading}</div>}

                {description && <div className="d-flex justify-content-center">{description}</div>}

                {buttons && (
                  <ButtonWrapper className="d-flex flex-column gap-25">{buttons}</ButtonWrapper>
                )}
              </div>
            </ModalContent>
          </ModalDialog>
        </ModalWrapper>,
        document.body,
        "modal",
      );
}
