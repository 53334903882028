import styled from "styled-components";
import Button from "../../../../ui-components/Button";
import Text from "../../../../ui-components/Text";

export const HelperText = styled(Text)`
  max-width: 280px;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin-inline: auto;
  }
`;
export const CustomButton = styled(Button)`
  color: ${props => props.theme.ui.colors.primary500} !important;
  vertical-align: baseline;
`;
