import React from "react";
import HeaderRow from "./HeaderRow";
import { SeeAll, StyledCol, StyledRow, TableWrapper } from "./styles";

import history from "../../context/history";
import { ClickableWrapper } from "../ClickableWrapper";
import Loader from "../Loader";
import { LegacyTextComponent } from "../typography";

export type Column = {
  name: string;
  size?: number;
};

export interface TableProps {
  artifactIds?: (string | undefined)[];
  className?: string;
  columns: Column[];
  flex?: boolean;
  justifyEnd?: boolean;
  rows: React.ReactNode[][];
  seeAllLink?: string;
  tableLoading?: boolean;
  title?: string;
  totalCount?: number;
  onRowClick?: (id?: string) => Promise<void>;
}

const Table: React.FC<TableProps> = ({
  artifactIds,
  className,
  columns,
  flex,
  justifyEnd,
  rows,
  seeAllLink,
  tableLoading,
  title,
  totalCount,
  onRowClick,
}) => {
  return (
    <TableWrapper className={`${className || ""} pt-lg-35`}>
      {title || (totalCount && seeAllLink) ? (
        <div className="mx-45 mt-35 mb-45 fw-500 d-flex align-items-center">
          {title && <LegacyTextComponent>{title}</LegacyTextComponent>}
          {!tableLoading && totalCount && seeAllLink && (
            <SeeAll
              className="ms-auto"
              onClick={() => history.push(seeAllLink)}
              data-testid="seeAllReturnsButton"
            >{`See all (${totalCount})`}</SeeAll>
          )}
        </div>
      ) : null}
      <HeaderRow flex={flex} columns={columns} justifyEnd={justifyEnd} />
      {tableLoading || rows.length === 0 ? (
        <Loader height={title && seeAllLink ? "402px" : undefined} />
      ) : (
        rows.map((row, i) => (
          <ItemRow
            artifactId={artifactIds ? artifactIds[i] : undefined}
            justifyEnd={justifyEnd}
            key={i}
            flex={flex}
            lastItem={i === rows.length - 1}
            row={row}
            sizes={columns.map(column => column.size || 0)}
            onRowClick={onRowClick}
          />
        ))
      )}
    </TableWrapper>
  );
};

interface ItemRowProps {
  artifactId?: string;
  justifyEnd?: boolean;
  flex?: boolean;
  lastItem?: boolean;
  row: React.ReactNode[];
  sizes: number[];
  onRowClick?: (id?: string) => Promise<void>;
}

const ItemRow: React.FC<ItemRowProps> = props => {
  const { artifactId, justifyEnd, flex, lastItem, row, sizes, onRowClick } = props;
  return (
    <ClickableWrapper
      clickable={typeof onRowClick !== "undefined"}
      onClick={() => onRowClick && onRowClick(artifactId)}
    >
      <StyledRow
        data-testid="itemRow"
        className={`${lastItem ? "no-border" : ""} ${
          typeof onRowClick !== "undefined" ? "hover-bg" : ""
        } py-3 px-45 mx-0 align-items-center ${flex ? "d-flex gap-3" : "row"}`}
        flex={flex ? 1 : 0}
      >
        {row.map((column, i) => {
          return (
            <StyledCol
              key={i}
              className={`
              ${
                i === sizes.length - 1
                  ? justifyEnd
                    ? "d-flex justify-content-end p-0"
                    : "p-0"
                  : "ps-0"
              }${" "} 
              ${!flex && sizes[i] ? `col-lg-${sizes[i]}` : flex ? "" : "col"}${" "}
              `}
              flex={flex ?? false ? sizes[i] : 0}
              data-testid={`column-${i}`}
            >
              {column}
            </StyledCol>
          );
        })}
      </StyledRow>
    </ClickableWrapper>
  );
};

export default Table;
