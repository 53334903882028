import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CancelClaimRequest } from "client/types";
import cancelClaim from "./fetchFunctions/cancelClaim";
import queryFactory from "./queryFactory";

export default () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ claimId, request }: { claimId: string; request: CancelClaimRequest }) => {
      return cancelClaim(claimId, request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.claims(),
        refetchType: "all",
      });
    },
  });
};
