import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthState } from "../../context/auth";
import { isLgQuery } from "../../helpers/mediaQuery";
import { useClickAway } from "../../hooks";
import useMountTransition from "../../hooks/useMountTransition";
import { Partner as PartnerType } from "../../types";
import Hamburger from "../Hamburger";
import Link from "../Link";
import { Logo, LogoVariants } from "../Logo";
import type { NavbarContent } from "./static";
import { Partner, PartnerDashboard, User, UserDashboard } from "./static";
import {
  CoBrandLogo,
  DashboardNavItems,
  NavBarDropdown,
  NavigationBar,
  NavigationWrapper,
  StyledNav,
  VerticalDivider,
} from "./styles";

type NavbarVariant = "user" | "partner" | "user-dashboard" | "partner-dashboard";

export type NavbarProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  fallback?: Omit<NavbarVariant, "user-dashboard" | "partner-dashboard">;
  coBrandNavbarLogoUrl?: PartnerType["logo"];
};

const navbarContents: Record<NavbarVariant, NavbarContent> = {
  "partner-dashboard": PartnerDashboard,
  "user-dashboard": UserDashboard,
  partner: Partner,
  user: User,
};

const Navbar = ({ fallback = "user", coBrandNavbarLogoUrl, ...props }: NavbarProps) => {
  const { user, partner } = useAuthState();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
  const hasTransitionedIn = useMountTransition(hamburgerOpen, 600);
  const isLg = isLgQuery();

  const key = useMemo<"user" | "user-dashboard" | "partner" | "partner-dashboard">(() => {
    if (partner !== null) {
      return "partner-dashboard";
    }

    if (Object.keys(user).length > 0) {
      return "user-dashboard";
    }

    return fallback === "partner" ? "partner" : "user";
  }, [user, partner]);

  useClickAway(ref, () => {
    setHamburgerOpen(false);
  });

  const toggleMenu = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  useEffect(() => {
    setHamburgerOpen(false);
  }, [location]);

  useEffect(() => {
    if (isLg) {
      setHamburgerOpen(false);
    }
  }, [isLg]);

  return (
    <StyledNav {...props} hamburgerOpen={hamburgerOpen}>
      <div ref={ref}>
        <NavigationWrapper>
          <NavigationBar className="px-35 px-md-45 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-25">
              {coBrandNavbarLogoUrl && (
                <>
                  <CoBrandLogo src={coBrandNavbarLogoUrl} alt="co-brand logo" />

                  <VerticalDivider className="vr" />
                </>
              )}

              <Link href={key === "partner" || key === "partner-dashboard" ? "/for-partners" : "/"}>
                <Logo
                  name={
                    key === "partner" || key === "partner-dashboard"
                      ? LogoVariants.BoomerangPartner
                      : LogoVariants.Boomerang
                  }
                  alt="Boomerang Partner"
                />
              </Link>

              <DashboardNavItems className="list-unstyled d-none d-lg-flex ms-45">
                {navbarContents[key].leftMenuItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </DashboardNavItems>
            </div>

            {!isLg ? <Hamburger onClick={toggleMenu} isOpen={hamburgerOpen} /> : null}

            <div className="d-none d-lg-flex flex-lg-row align-items-lg-center gap-25">
              {navbarContents[key].rightMenuItems.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </NavigationBar>
        </NavigationWrapper>

        {!isLg && (hamburgerOpen || hasTransitionedIn) ? (
          <NavBarDropdown className={classNames({ open: hamburgerOpen && hasTransitionedIn })}>
            <ul className="list-unstyled d-flex flex-column gap-2 py-35">
              {navbarContents[key].leftMenuItemsMobile.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <hr className="my-0" />

            <div className="px-3 py-35">
              {navbarContents[key].rightMenuItemsMobile.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </NavBarDropdown>
        ) : null}
      </div>
    </StyledNav>
  );
};

export default Navbar;
