import theme from "client/theme";
import { Button, Typography } from "client/ui-components";
import React, { useEffect } from "react";
import { isMdQuery } from "../../helpers/mediaQuery";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { mountBodyNoOverflow, unmountBodyNoOverflow } from "../../utils/bodyStyle";
import Loader from "../Loader";
import {
  BackgroundOverlay,
  MobileBackgroundOverlay,
  MobileModalWrapper,
  ModalWrapper,
  ScrollableContainer,
  StyledCloseBig,
} from "./styles";

interface ModalProps {
  bottomBtnOnClick?: () => void;
  bottomBtnText?: string;
  color?: string;
  header?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  maxWidth?: string;
  modalLoading?: boolean;
  onXButton?: () => void;
  show: boolean;
  showXButton?: boolean;
  subHeader?: string;
  topBtnDisabled?: boolean;
  topBtnOnClick?: () => void;
  topBtnLoading?: boolean;
  topBtnText?: string;
  topBtnClassName?: string;
  wideContentDesktop?: boolean;
  withoutTopMargin?: boolean;
}

const Modal: React.FC<ModalProps> = props => {
  const {
    bottomBtnOnClick,
    bottomBtnText,
    color,
    header,
    icon,
    children,
    maxWidth,
    modalLoading,
    onXButton,
    show,
    showXButton,
    subHeader,
    topBtnDisabled,
    topBtnOnClick,
    topBtnLoading,
    topBtnText,
    wideContentDesktop,
    withoutTopMargin,
  } = props;

  const isMd = isMdQuery();

  useEffect(() => {
    show ? mountBodyNoOverflow() : unmountBodyNoOverflow();

    return function cleanup() {
      unmountBodyNoOverflow();
    };
  }, [show]);

  return (
    <>
      {isMd && show ? (
        <BackgroundOverlay className={`justify-content-center ${show ? "visible" : ""}`}>
          <ModalWrapper
            color={color}
            className={`${wideContentDesktop ? "px-35" : "px-6"}  pt-6 pb-45`}
            data-testid="modal"
          >
            {showXButton && (
              <StyledCloseBig
                onClick={typeof onXButton === "function" ? onXButton : bottomBtnOnClick}
                disabled={topBtnLoading}
                accessibilityTitle="Close modal"
                titleId="CloseModalXButtonDesktopTitle"
                data-testid="closeModalXButton"
                tabIndex={0}
                onKeyDown={e => {
                  if (e.key === KEYBOARD_KEY.ENTER) {
                    typeof onXButton === "function"
                      ? onXButton()
                      : bottomBtnOnClick && bottomBtnOnClick();
                  }
                }}
              />
            )}
            {modalLoading ? (
              <Loader />
            ) : (
              <>
                {icon}
                <ScrollableContainer maxWidth={maxWidth}>
                  {header && (
                    <Typography
                      className={`${withoutTopMargin ? "line-height-1" : "mt-md-3"} ${
                        subHeader ? "mb-25" : "mb-35"
                      } justify-content-center text-center`}
                      data-testid="modalTitle"
                      as="h1"
                      heading
                      fontSize={isMd ? theme.ui.fontSizes.xxl : theme.ui.fontSizes.xl}
                      color={theme.ui.colors.primary700}
                      fontWeight={theme.ui.fontWeights.bold}
                      lineHeight={subHeader === undefined ? 1.25 : 1}
                    >
                      {header}
                    </Typography>
                  )}
                  {subHeader && (
                    <Typography
                      className="mb-45 text-center"
                      data-testid="modalSubHeader"
                      as="p"
                      fontSize="16px"
                      color={theme.ui.colors.primary700}
                      fontWeight={theme.ui.fontWeights.normal}
                      lineHeight={1.5}
                    >
                      {subHeader}
                    </Typography>
                  )}
                  {children}
                  <div className="d-flex flex-column align-items-center pt-35">
                    {topBtnText && (
                      <Button
                        aria-label={topBtnText}
                        data-testid="topButton"
                        style={{ width: 328 }}
                        color={theme.ui.colors.white}
                        disabled={topBtnLoading || topBtnDisabled}
                        loading={topBtnLoading}
                        onClick={typeof topBtnOnClick === "function" ? topBtnOnClick : () => {}}
                        size="xl"
                      >
                        {topBtnText}
                      </Button>
                    )}
                    {bottomBtnText && (
                      <div className="pt-35">
                        <Button
                          aria-label={bottomBtnText}
                          data-testid="bottomButton"
                          variant="text"
                          disabled={topBtnLoading}
                          onClick={
                            typeof bottomBtnOnClick === "function" ? bottomBtnOnClick : () => {}
                          }
                          size="xl"
                        >
                          {bottomBtnText}
                        </Button>
                      </div>
                    )}
                  </div>
                </ScrollableContainer>
              </>
            )}
          </ModalWrapper>
        </BackgroundOverlay>
      ) : (
        <MobileBackgroundOverlay className={`justify-content-center ${show ? "visible" : ""}`}>
          <MobileModalWrapper
            color={color}
            className={`px-35 pb-35 px-sm-45 ${show ? "visible" : ""}`}
          >
            {showXButton && (
              <StyledCloseBig
                onClick={typeof onXButton === "function" ? onXButton : bottomBtnOnClick}
                disabled={topBtnLoading}
                accessibilityTitle="Close modal"
                tabIndex={0}
                titleId="CloseModalXButtonMobileTitle"
                onKeyDown={e => {
                  if (e.key === KEYBOARD_KEY.ENTER) {
                    typeof onXButton === "function" ? onXButton : bottomBtnOnClick;
                  }
                }}
              />
            )}
            {modalLoading ? (
              <Loader />
            ) : (
              // This section is for smaller views (!Md)
              <ScrollableContainer maxWidth="100%">
                {/*To be used if swiping is implemented<StyledRectangle className="mt-n4 mb-4 mx-auto"/>*/}
                {icon}
                {header && (
                  <Typography
                    className={`${
                      subHeader || icon ? "mb-25" : "mb-45"
                    } mb-xs-35 justify-content-center text-center`}
                    as="h1"
                    heading
                    fontSize={theme.ui.fontSizes.xxl}
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    lineHeight={1.2}
                  >
                    {header}
                  </Typography>
                )}
                {subHeader && (
                  <Typography
                    className="mb-35 text-center"
                    data-testid="modalSubHeader"
                    as="p"
                    fontSize="16px"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.normal}
                    lineHeight={1.5}
                  >
                    {subHeader}
                  </Typography>
                )}
                {children}
                <div className="pt-35">
                  {topBtnText && (
                    <Button
                      aria-label={topBtnText}
                      fullWidth
                      disabled={topBtnLoading || topBtnDisabled}
                      loading={topBtnLoading}
                      onClick={typeof topBtnOnClick === "function" ? topBtnOnClick : () => {}}
                      size="xl"
                    >
                      {topBtnText}
                    </Button>
                  )}
                  {bottomBtnText && (
                    <div className="pt-35">
                      <Button
                        aria-label={bottomBtnText}
                        fullWidth
                        disabled={topBtnLoading}
                        variant="text"
                        onClick={
                          typeof bottomBtnOnClick === "function" ? bottomBtnOnClick : () => {}
                        }
                        size="xl"
                      >
                        {bottomBtnText}
                      </Button>
                    </div>
                  )}
                </div>
              </ScrollableContainer>
            )}
          </MobileModalWrapper>
        </MobileBackgroundOverlay>
      )}
    </>
  );
};

export default Modal;
