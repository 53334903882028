import classNames from "classnames";
import React from "react";
import Logout from "../../../../assets/ui-icons/Logout";
import { useAuthState } from "../../../../context/auth";
import { colors, fontSizes, fontWeights } from "../../../../theme/uiTheme";
import Link from "../../../Link";
import Text from "../../../Text";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function UserDetails({ className, ...props }: Props) {
  const { user } = useAuthState();

  return (
    <div {...props} className={classNames("d-flex", "flex-column", "gap-4", className)}>
      <div>
        <Text color={colors.gray600} fontSize={fontSizes.sm} className="mb-25">
          You’re currently signed as
        </Text>

        <Text fontWeight={fontWeights.bold}>{user.full_name}</Text>

        <Text color={colors.gray600}>{user.email}</Text>
      </div>

      <Link variant="error" href="/sign-out" className="d-flex flex-row align-items-center gap-2">
        <Logout accessibilityTitle="sign-out" titleId="sign-out" className="me-2" />
        Sign out
      </Link>
    </div>
  );
}
