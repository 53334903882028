import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isLgQuery } from "../../helpers/mediaQuery";

import { CustomerMenuItems } from "../../types/menuElements";
import { UserRoles } from "../../types/models";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";

import { useAuthState } from "../../context/auth";
import history from "../../context/history";

import UserProfileDropdown from "../../components/Dropdowns/UserProfileDropdown";
import MainHeader from "../../components/MainHeader/index";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import { UserRoutesEnum } from "../../components/Routes";
import theme from "../../theme/index";

const Content = styled.main`
  background-color: ${props => props.theme.colors.gray6};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${theme.widths.claimMax};
  }
  .styled-content-container {
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows.md};
    border-radius: ${props => props.theme.borderRadius.lg};
  }
`;

interface DesktopStylingWrapperProps {
  contentOverlapsHeader?: boolean;
}

const DesktopStylingWrapper = styled.div<DesktopStylingWrapperProps>`
  display: block;
  ${props =>
    props.contentOverlapsHeader &&
    `
    position: relative;
    top: -28px;
    `}
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    position: relative;
    top: -28px;
  }
`;

interface UserViewProps {
  children: React.ReactNode;
  heading?: string | React.ReactNode;
  topSubHeaderText?: string;
  subHeaderText?: string | (() => string);
  isOverview?: boolean;
  useLogo?: boolean;
  hideMenu?: boolean;
  hidePrimaryButton?: boolean;
  contentOverlapsHeader?: boolean;
  leftArrowText?: string;
  leftArrowLink?: UserRoutesEnum | string;
  hideMatchIndicator?: boolean;
  showLostSomethingLink?: boolean;
}

function UserView(props: UserViewProps) {
  const isLg = isLgQuery();
  const {
    children,
    heading,
    subHeaderText,
    topSubHeaderText,
    isOverview,
    useLogo: logoOverride,
    hideMenu,
    hidePrimaryButton,
    contentOverlapsHeader,
    leftArrowText,
    leftArrowLink,
    hideMatchIndicator,
    showLostSomethingLink,
  } = props;
  const { user } = useAuthState();

  const onAddItemClick = () => {
    history.push("/lost");
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);

  useEffect(() => {
    mountBodyGray6();
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  return (
    <div className="px-0 container-fluid">
      <MainHeader
        isEndUser
        title={isOverview ? `Hi, ${user.full_name?.split(" ")[0]}!` : heading}
        topSubHeaderText={topSubHeaderText}
        subHeaderText={subHeaderText}
        leftArrowText={leftArrowText ? leftArrowText : isOverview ? undefined : "Claims"}
        logoOverride={logoOverride || isOverview}
        onLeftArrowClick={() => {
          history.push(leftArrowLink ? leftArrowLink : UserRoutesEnum.CLAIMS);
        }}
        hamburgerOpen={hamburgerOpen}
        onHamburgerClick={() => {
          setHamburgerOpen(!hamburgerOpen);
        }}
        handlePrimaryButtonClick={isOverview ? onAddItemClick : undefined}
        primaryButtonClaimsStyled
        dropdown={<UserProfileDropdown />}
        primaryButtonText={!hidePrimaryButton && isOverview ? "Report a lost item" : undefined}
        useHeaderWrapper
        isOverviewUserRole={isOverview || hideMatchIndicator ? UserRoles.USER : undefined}
        contentOverlapsHeader={contentOverlapsHeader}
        hideMenu={hideMenu}
        showLostSomethingLink={showLostSomethingLink}
      />
      {hamburgerOpen ? (
        <MobileMenu
          handleOnClick={() => {
            setHamburgerOpen(!hamburgerOpen);
          }}
          menuItems={CustomerMenuItems}
          showSwitchAccount={showSwitchAccount}
          setShowSwitchAccount={setShowSwitchAccount}
        />
      ) : (
        <Content
          className={classNames("mx-0 justify-content-center px-3 p-lg-0 mx-lg-auto", {
            "pt-5": isOverview && !contentOverlapsHeader && !isLg,
          })}
        >
          <DesktopStylingWrapper
            className="mx-lg-auto"
            contentOverlapsHeader={contentOverlapsHeader}
          >
            {children}
          </DesktopStylingWrapper>
        </Content>
      )}
    </div>
  );
}

export default UserView;
