import classNames from "classnames";
import React from "react";
import { LegacyTextComponent, Subheader } from "./typography";

interface StepHeaderProps {
  className?: string;
  noBottomMargin?: boolean;
  noBottomPadding?: boolean;
  text?: React.ReactNode;
  title: string;
  warning?: string;
}

export const StepHeader: React.FC<StepHeaderProps> = StepHeaderProps => {
  const { className, noBottomMargin, noBottomPadding, text, title, warning } = StepHeaderProps;

  return (
    <div
      className={classNames({
        [`${className}`]: className !== undefined,
        "mb-45": !noBottomMargin,
      })}
    >
      <Subheader className={text ? "pb-3" : "pb-2"}>{title}</Subheader>
      {text && (
        <LegacyTextComponent className={`${!noBottomPadding && "pb-3"}`} data-testid="subheader">
          {text}
        </LegacyTextComponent>
      )}

      {warning && <LegacyTextComponent className="fw-bold">{warning}</LegacyTextComponent>}
    </div>
  );
};

export default StepHeader;
