import { PartnerLocationRequest, PartnerLocationSearchResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import getQueryString from "client/utils/getQueryString";

export default (request: PartnerLocationRequest): Promise<PartnerLocationSearchResponse> => {
  const queryString = request.filters ? getQueryString(request.filters) : "";
  return fetchApiData({
    url: `/api/partner/partners/${request.partnerId}/locations/${
      request.locationId !== undefined ? request.locationId + "/" : ""
    }${queryString}`,
  });
};
