import { EmailIcon, PhoneIcon } from "@assets/icons/icons";
import formatPhoneNumber from "client/utils/formatPhoneNumber";
import React from "react";
import { User } from "../../../../../types";
import { ContactDetail, ContactName, IconContainer } from "./styles";

type Props = {
  user?: User;
};

export default function ContactDetailsCell({ user }: Props) {
  return user === undefined ? null : (
    <div className="d-flex flex-column gap-1" style={{ minWidth: 0, maxWidth: "100%" }}>
      <ContactName>{user.full_name}</ContactName>

      <IconContainer>
        <EmailIcon
          height="15px"
          width="15px"
          accessibilityTitle="Email"
          titleId="ContactDetailsSnapshotEmailTitle"
        />

        <ContactDetail>{user.email}</ContactDetail>
      </IconContainer>

      <IconContainer>
        <PhoneIcon
          height="15px"
          width="15px"
          accessibilityTitle="Phone number"
          titleId="ContactDetailsSnapshotPhoneNumberTitle"
        />

        <ContactDetail>{formatPhoneNumber(user.phone_number ?? "")}</ContactDetail>
      </IconContainer>
    </div>
  );
}
