import React, { useEffect } from "react";

const useClickAway = <E extends Event = Event>(
  ref: React.RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  eventName: string = "mousedown",
) => {
  useEffect(() => {
    const handleDocumentClick = event => {
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return;
      }
      onClickAway(event);
    };

    document.addEventListener(eventName, handleDocumentClick);

    return () => {
      document.removeEventListener(eventName, handleDocumentClick);
    };
  }, []);
};

export default useClickAway;
