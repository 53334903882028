import React from "react";
import { ShowModalsState } from "..";
import { PotentialMatchesIcon } from "../../../../assets/icons/icons";
import Button from "../../../../components/Button";
import { ButtonModes, ButtonTextColors } from "../../../../components/Button/Button.types";
import Modal from "../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../components/Modal/Modal.types";
import { NormalSubtext } from "../../../../components/singleViewStyles";
import history from "../../../../context/history";
import theme from "../../../../theme";

interface UnreviewedMatchesModalProps {
  itemId: string;
  setShowModals: (value: React.SetStateAction<ShowModalsState>) => void;
  showModal: boolean;
}

const UnreviewedMatchesModal: React.FC<UnreviewedMatchesModalProps> = props => {
  const { itemId, setShowModals, showModal } = props;
  return (
    <Modal
      bottomBtnText={ModalButtonTextEnum.CANCEL}
      bottomBtnOnClick={() => {
        setShowModals(prev => ({
          ...prev,
          unreviewedMatchesModal: false,
        }));
      }}
      header={"This item has unreviewed matches"}
      icon={
        <PotentialMatchesIcon
          className="mx-auto mb-25 mt-2"
          color1={theme.colors.primaryBlue}
          color2={theme.colors.lightBlue}
          height={54}
          width={63}
          accessibilityTitle="Unreviewed matches"
          titleId="UnreviewedMatchesModalIconTitle"
        />
      }
      children={
        <>
          <NormalSubtext className="mx-auto mb-35">
            Potential matches for this item exist! What do you want to do?
          </NormalSubtext>
          <Button
            ariaLabel="Return item anyway"
            data-testid="returnItemAnywayButton"
            className="w-100"
            mode={ButtonModes.open}
            onClick={() => {
              setShowModals(prev => ({
                ...prev,
                unreviewedMatchesModal: false,
                contactDetailsModal: true,
              }));
            }}
            text={"Return item anyway"}
            textColor={ButtonTextColors.danger}
          />
        </>
      }
      show={showModal}
      showXButton
      topBtnOnClick={() => {
        history.push(`/partner/matches/matching/?itemId=${itemId}`, {
          returnPath: "/partner/inventory",
        });
      }}
      topBtnText={"See potential matches"}
      topBtnClassName="mb-35 mt-35"
    />
  );
};

export default UnreviewedMatchesModal;
