import { useCreateMailingAddress, usePatchMailingAddress } from "client/actions/shippingCheckout";
import React from "react";
import Modal from "../../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../../components/Modal/Modal.types";
import SuccessModalInterior from "../../../../../components/SuccessModalInterior";
import history from "../../../../../context/history";
import theme from "../../../../../theme";
import { PartnerClaim, PartnerItem } from "../../../../../types";
import Typography from "../../../../../ui-components/Typography";
import { formattedInventoryId } from "../../../../../utils/stringUtils";
import { useMatchingStore } from "../../matchingStore";

const SuccessModal = ({ matchingClaimToItems, isShippingEnabled }) => {
  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);
  const nextClaimToMatch = useMatchingStore(state => state.nextClaimToMatch);
  const showSuccessModal = useMatchingStore(state => state.showSuccessModal);
  const setShowSuccessModal = useMatchingStore(state => state.setShowSuccessModal);
  const currentCandidate = useMatchingStore(state => state.currentCandidate);
  const [, , resetMailingAddress] = useCreateMailingAddress();
  const [, , resetPatchedMailingAddress] = usePatchMailingAddress();

  const shippingDetailFormReset = () => {
    resetMailingAddress();
    resetPatchedMailingAddress();
  };

  const item = (matchingClaimToItems ? currentCandidate : artifactToMatch) as PartnerItem;
  const claim = (matchingClaimToItems ? artifactToMatch : currentCandidate) as PartnerClaim;

  if (!item || !claim) return null;

  return (
    <Modal
      bottomBtnText={(nextClaimToMatch && ModalButtonTextEnum.DONE_MATCHING) as string}
      bottomBtnOnClick={() => {
        history.push("/partner/matches");
        setShowSuccessModal(false);
      }}
      show={showSuccessModal}
      topBtnOnClick={() => {
        setShowSuccessModal(false);
        if (nextClaimToMatch) {
          history.replace(`/partner/matches/matching/?claimId=${nextClaimToMatch?.id}`);
          shippingDetailFormReset();
        } else {
          history.push("/partner/overview");
        }
        if (!nextClaimToMatch) history.push("/partner/overview");
      }}
      topBtnLoading={false}
      topBtnText={nextClaimToMatch ? ModalButtonTextEnum.REVIEW_ANOTHER_CLAIM : "Go to overview"}
      showXButton
      onXButton={() => {
        setShowSuccessModal(false);
        history.push("/partner/matches");
      }}
    >
      <SuccessModalInterior
        header={isShippingEnabled ? `Match created!` : `It's a match!`}
        description={
          <div className="pb-2">
            <Typography
              className="text-center"
              data-testid="successModalDescription"
              as="p"
              fontSize={theme.ui.fontSizes.base}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.normal}
              lineHeight={1.75}
            >
              You've matched
              <Typography
                as="span"
                fontSize={theme.ui.fontSizes.base}
                color={theme.ui.colors.primary700}
                fontWeight={theme.ui.fontWeights.bold}
                lineHeight={1.75}
              >
                {` ${item.name} (ID: ${item && formattedInventoryId(item.id)}) `}
              </Typography>
              to a claim! An email has been sent to
              <Typography
                as="span"
                fontSize={theme.ui.fontSizes.base}
                color={theme.ui.colors.primary700}
                fontWeight={theme.ui.fontWeights.bold}
                lineHeight={1.75}
              >
                {` ${claim.user.full_name} `}
              </Typography>
              at
              <Typography
                as="span"
                fontSize={theme.ui.fontSizes.base}
                color={theme.ui.colors.primary700}
                fontWeight={theme.ui.fontWeights.bold}
                lineHeight={1.75}
              >
                {` ${claim.user.email} `}
              </Typography>
              prompting them to review the item.
            </Typography>
          </div>
        }
      />
    </Modal>
  );
};

export default SuccessModal;
