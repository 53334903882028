// Gets a list of month since a specific date
export const dateOptionsFromDate = date => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const initialYear = date.getFullYear();
  const initialMonth = date.getMonth();
  const monthDistance = (currentYear - initialYear) * 12 + (currentMonth - initialMonth);
  let options = [];
  for (let month = monthDistance; month >= 0; month--) {
    let option = new Date(initialYear, initialMonth + month, 1);
    options.push(option);
  }
  return options;
};
