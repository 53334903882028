import styled from "styled-components";
import { SearchResultMagnifyingGlass } from "../../../../assets/icons/icons";

export const StyledSearchResultMagnifyingGlass = styled(SearchResultMagnifyingGlass)`
  margin-top: 40px;
  height: 58px;
  width: 58px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 98px;
    width: 98px;
    margin-top: 68px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 72px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 40px);
  text-align: center;
`;
