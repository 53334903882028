import React from "react";

import {
  AboveTheFold,
  Benefits,
  CallToAction,
  Features,
  Matching,
  PartnerLogos,
  SignForNewsletter,
  Testimonials,
} from "./components";

import { PartnerLayout } from "../../ui-components";

export default function Partner() {
  return (
    <PartnerLayout>
      <AboveTheFold />

      <PartnerLogos />

      <Matching />

      <Testimonials />

      <Benefits />

      <CallToAction />

      <Features />

      <SignForNewsletter />
    </PartnerLayout>
  );
}
