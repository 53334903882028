export const contactDetailsCopy = {
  heading: "How should we contact you?",
  subheading: "Lost something?",
  supportingText: "Your contact details will help us update you on your lost item.",
  inputFirstNameLabel: "First name",
  inputLastNameLabel: "Last name",
  inputEmailLabel: "Email",
  inputPhoneLabel: "Phone number",
  inputFirstNamePlaceholder: "Jane",
  inputLastNamePlaceholder: "Doe",
  inputEmailPlaceholder: "jane@thanksboomerang.com",
  inputEmptyMessage: "This field is required.",
  inputEmailErrorMessage: "Invalid email address",
  inputPhoneErrorMessage: "Invalid phone number",
  nextButton: "Next",
  nextButtonUpdate: "Update",
  previousButton: "Go back",
  previousButtonUpdate: "Cancel",
};
