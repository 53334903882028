import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: 0;
  }
`;

export const DropdownButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid #dee2e6;
  font-weight: ${props => props.theme.fontWeights.default};
  width: 100%;
  text-align: left;
  height: 36px;
  padding: 6px 12px;

  &&:focus,
  &&:focus-within,
  &[aria-expanded="true"] {
    box-shadow: none;
    outline-style: solid;
    outline-color: ${props => props.theme.colors.primaryBlue};
  }

  &:after {
    content: none;
  }
`;

export const DropdownMenu = styled.div`
  top: 46px;
  cursor: pointer;
  padding-block: 8px;
  border-radius: ${props => props.theme.borderRadius.lg};
  box-shadow: ${props => props.theme.shadows.lg};
  width: 334px;
  max-height: 508px;
`;

export const MenuItem = styled.li<{
  active: boolean;
}>`
  font-size: ${props => props.theme.fontSizes.default};
  color: ${props => props.theme.colors.gray1};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${props => (props.active ? 600 : 500)};
  background-color: ${props =>
    props.active ? props.theme.colors.gray6 : props.theme.colors.white};
  padding: 20px;
  transition: background-color 200ms ease-in;

  &:hover,
  &:focus-within {
    background: ${props => props.theme.colors.gray6};
  }
`;

export const ContainerRounded = styled.button`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  width: 100%;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 150px;
  border: 1px solid #d0d5dd;
  background: #fff;
  height: 36px;
  color: ${props => props.theme.ui.colors.primary700};
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  transition: all 0.2s ease-in-out;
  outline: none;

  &:focus {
    border: 2px solid rgba(7, 114, 228, 1) !important;
    box-shadow: 0px 0px 0px 4px rgba(3, 70, 245, 0.2) !important;
  }

  &:hover {
    border: 1px solid rgba(7, 114, 228, 1);
    box-shadow: 0px 0px 0px 2px rgba(3, 70, 245, 0.2);
  }
`;

export const ContainerRoundedButton = styled.div`
  display: flex;
  width: 160px;
  padding: 8px 14px;
  align-items: center;
  gap: 10px;
  border-radius: 150px;
  border: 1px solid #dce1e8;
  background: #fff;
  margin-top: 4px;
  height: 36px;
  color: #00174a;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
