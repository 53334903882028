import { format, parseISO } from "date-fns";
import React from "react";
import ContactDetailsSnapshot from "../../components/ContactDetailsSnapshot";
import ItemSnapshot from "../../components/ItemSnapshot";
import { LightLabel, TableColumnValue } from "../../components/typography";
import { ResultType } from "../../pages/PartnerGlobalSearch";
import theme from "../../theme";
import { ArtifactType, FormattedClaim, FormattedItem } from "../../types";
import StatusBadge from "../../ui-components/StatusBadge";
import { convertToDateIgnoringTime } from "../dateUtils";

const getItemRow = (
  item: FormattedItem,
  index: number,
  dateFormatter = format,
): React.ReactNode[] => {
  const label = item?.inventoryId ? `ID: ${item?.inventoryId}` : "";
  return [
    <ItemSnapshot
      categoryName={item.categoryName}
      images={item.images}
      snapshotKey={index}
      key={index}
      name={item.name}
      label={label}
      $showSingleImage
    />,
    <TableColumnValue>{item.description}</TableColumnValue>,
    <LightLabel key={index}>
      {item.date && dateFormatter(parseISO(String(item.date)), "long")}
    </LightLabel>,
    <StatusBadge objectType={ResultType.ITEMS} objectStatus={item.status} />,
  ];
};

const getClaimRow = (
  claim: FormattedClaim,
  index: number,
  dateFormatter = format,
): React.ReactNode[] => {
  return [
    <ItemSnapshot
      categoryName={claim.categoryName}
      images={claim.images}
      snapshotKey={index}
      key={index}
      name={claim.name}
      $showSingleImage
    />,
    <ContactDetailsSnapshot
      email={claim.user.email}
      name={claim.user.full_name}
      phoneNumber={claim.user.phone_number}
      textColor={theme.colors.gray3}
      key={index}
      useSmallHeader
    />,
    <LightLabel key={index}>
      {claim.date ? dateFormatter(convertToDateIgnoringTime(claim.date.toString()), "long") : "-"}
    </LightLabel>,
    <StatusBadge objectType={ResultType.CLAIMS} objectStatus={claim.status} />,
  ];
};

const getItemClaimRow = (
  artifact: FormattedItem | FormattedClaim,
  artifactType: ArtifactType,
  index: number,
  dateFormatter: (date: Date, format: "short" | "long") => string = format,
): React.ReactNode[] => {
  switch (artifactType) {
    case ArtifactType.InventoryItem:
      return getItemRow(artifact as FormattedItem, index);
    case ArtifactType.Claim:
      return getClaimRow(artifact as FormattedClaim, index, dateFormatter);
  }
};

export default getItemClaimRow;
