import { useQuery } from "@tanstack/react-query";
import DestroyIcon from "client/assets/destroy_icon.svg";
import DonateIcon from "client/assets/donate_item_icon.svg";
import GivenToStaffIcon from "client/assets/given_to_staff.svg";
import MoreLifeIcon from "client/assets/more_life_icon.svg";
import RestockIcon from "client/assets/restocked.svg";
import SellIcon from "client/assets/sell_icon.svg";
import StoreIcon from "client/assets/store_icon.svg";
import { usePartnerUnclaimedStatuses } from "client/hooks/data/category-by-partner";
import queryFactory from "client/hooks/data/partner/queryFactory";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import theme from "client/theme";
import { UnclaimedStatus } from "client/types";
import { Heading, Text } from "client/ui-components";
import SuggestedItem from "client/ui-components/SuggestedItem";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React from "react";
import styled from "styled-components";

const routeStart = "/partner/inventory/unclaimed";

type SuggestedStatus = {
  name: string;
  value: string;
  icon: React.ReactNode;
  description: string;
  link: string;
  count: number;
};

const SUGGESTED_STATUSES: Record<string, SuggestedStatus> = {
  DONATED: {
    name: "Donate",
    value: "DONATED",
    icon: <img src={DonateIcon} alt="Donate" />,
    description: "Suggested list of items you should donate.",
    link: `${routeStart}/donate`,
    count: 0,
  },
  DESTROYED: {
    name: "Destroy",
    value: "DESTROYED",
    icon: <img src={DestroyIcon} alt="Destroy" />,
    description: "Suggested list of items you should destroy.",
    link: `${routeStart}/destroy`,
    count: 0,
  },
  STORED: {
    name: "Store",
    value: "STORED",
    icon: <img src={StoreIcon} alt="Store" />,
    description: "Suggested list of items you should store.",
    link: `${routeStart}/store`,
    count: 0,
  },

  SOLD: {
    name: "Sell",
    value: "SOLD",
    icon: <img src={SellIcon} alt="Sell" />,
    description: "Suggested list of items you should sell.",
    link: `${routeStart}/sell`,
    count: 0,
  },
  MORE_LIFE: {
    name: "More Life By Boomerang",
    value: "MORE_LIFE",
    icon: <img src={MoreLifeIcon} alt="More Life" />,
    description: "Suggested list of items you should use in the More Life program.",
    link: `${routeStart}/more-life`,
    count: 0,
  },
  GIVEN_TO_STAFF: {
    name: "Give to Staff",
    value: "GIVEN_TO_STAFF",
    icon: <img src={GivenToStaffIcon} alt="Given to Staff" style={{ width: 24, height: 24 }} />,
    description: "Suggested list of items you should give to staff.",
    link: `${routeStart}/give-to-staff`,
    count: 0,
  },
  RESTOCKED: {
    name: "Restock",
    value: "RESTOCKED",
    icon: <img src={RestockIcon} alt="Restock" style={{ width: 24, height: 24 }} />,
    description: "Suggested list of items you should restock.",
    link: `${routeStart}/restock`,
    count: 0,
  },
};

const SuggestedListsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media (min-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default function SuggestedListsSection() {
  const partnerId = useCurrentPartner();
  const { partnerUserLocation } = usePartnerLocationStore();
  const { data = [], isLoading } = useQuery(
    queryFactory.itemCountBySuggestedUnclaimedFiltered({
      partnerId,
      queryObject: {
        status: "UNCLAIMED",
        lost_locations: partnerUserLocation?.id,
        unclaimed_status: "NULL",
      },
    }),
  );
  const { data: unclaimedStatuses } = usePartnerUnclaimedStatuses(partnerId);
  const unclaimedStatusesWithMappings = unclaimedStatuses
    ?.filter(({ category }) => !!category)
    .map(({ unclaimed_status }) => unclaimed_status);
  const suggestedStatuses = data
    ?.map(item =>
      SUGGESTED_STATUSES[item.value]
        ? { ...SUGGESTED_STATUSES[item.value], count: item.count }
        : null,
    )
    .filter((item): item is SuggestedStatus => item !== null)
    .filter(status => unclaimedStatusesWithMappings?.includes(status.value as UnclaimedStatus));
  if (isLoading) return null;
  if (!suggestedStatuses.length) return null;

  return (
    <div>
      <Heading
        data-testid="suggestedHeading"
        variant="h4"
        style={{
          paddingBottom: 8,
        }}
      >
        Suggested Lists
      </Heading>
      <Text
        data-testid="suggestedCopy"
        color={theme.ui.colors.gray600}
        style={{ paddingBottom: 20 }}
      >
        Based on your onboarding interview, we’ve created suggested lists for items you should
        donate, destroy, store, or sell. If you’d like to modify this criteria, please reach out to
        your account manager at{" "}
        <a
          style={{
            color: theme.ui.colors.primary500,
            fontWeight: theme.ui.fontWeights.bold,
          }}
          href="mailto:support@thanksboomerang.com"
        >
          support@thanksboomerang.com
        </a>
        .
      </Text>
      <SuggestedListsWrapper data-testid="suggestedListSection">
        {suggestedStatuses.map(status => {
          return (
            <SuggestedItem
              key={status.value}
              name={status.name}
              count={status.count}
              icon={status.icon}
              subtitle={status.description}
              link={status.link}
            />
          );
        })}
      </SuggestedListsWrapper>
    </div>
  );
}
