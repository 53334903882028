import { HTMLAttributes } from "react";
import styled from "styled-components";

const FOOTER_HEIGHT = 3.75;

export const StyledClaimList = styled.div`
  background: ${props => props.theme.colors.white};
  width: 340px;
`;

export const StyledContainer = styled.div`
  .thumbnail {
    min-height: 84px;
    min-width: 84px;
    width: 84px;
    height: 84px;
  }
`;

export const BackButtonContainer = styled.div`
  margin: 28px 0 12px 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  width: 340px;
`;

export const MainWrapper = styled.div`
  flex: 1 1 auto;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  padding: 1.25rem;
`;

export const MatchingCard = styled.div`
  height: 865px;
  padding-bottom: ${FOOTER_HEIGHT + 2.5}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    border-radius: 16px;
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    border-radius: ${props => props.theme.borderRadius.lg};
  }
`;

interface MatchingCardProps extends HTMLAttributes<HTMLDivElement> {
  widerContent?: boolean;
}

export const ContentWrapper = styled.div<MatchingCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  margin-top: 74px;
  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    width: 332px;
    margin-top: 163px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: ${props => (props.widerContent ? "496px" : "476px")};
    margin-top: 260px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 244px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-top: 180px;
  }

  h1 {
    margin-top: 1.25rem;
    text-align: center;
  }

  div.progress {
    margin-top: 2.5rem;
    height: 8px;
    width: 230px;
    border-radius: 4px;
    @media (min-width: ${props => props.theme.breakpoints.xs}) {
      width: 320px;
    }
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 337px;
    }
  }

  a {
    margin-top: 28px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 40px);
  text-align: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 320px) {
    gap: 20px;
  }
`;
