import React from "react";

import { Hero, HowItWorksSection } from "./components";

import { BaseLayout } from "../../ui-components";
import { SignForNewsletter } from "../Partner/components";

export default function Home() {
  return (
    <BaseLayout>
      <Hero />
      <HowItWorksSection />
      <SignForNewsletter isClientForm />
    </BaseLayout>
  );
}
