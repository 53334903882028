import { useQuery } from "@tanstack/react-query";
import queries from "client/hooks/data/user/queryFactory";
import React, { createContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Location, Partner } from "../../types";

type CoBrandedPageContextType = {
  partner: Partner | null;
  urlPartnerSlug?: string | null;
  urlLocationSlug?: string | null;
  isLoading: boolean;
  partnerLocations?: Location[];
};
export const CoBrandedPageContext = createContext<CoBrandedPageContextType>({
  partner: null,
  urlPartnerSlug: null,
  urlLocationSlug: null,
  isLoading: false,
});

type URLParams = {
  urlPartnerSlug: string;
  urlLocationSlug: string;
};

export default function CoBrandedPageContextProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const { urlPartnerSlug, urlLocationSlug } = useParams<URLParams>();
  const {
    data: partner,
    isLoading: isPartnerLoading,
    error,
  } = useQuery(queries.partnerBySlug(urlPartnerSlug));
  const { data: partnerLocations, isLoading: locationsLoading } = useQuery(
    queries.locationsForPartner(partner?.id || ""),
  );

  useEffect(() => {
    if (error) {
      history.push("/");
    }
  }, [error, history]);

  const values = useMemo(
    () => ({
      partner: partner || null,
      urlPartnerSlug,
      urlLocationSlug,
      partnerLocations,
      isLoading: isPartnerLoading || locationsLoading,
    }),
    [
      partner,
      urlPartnerSlug,
      urlLocationSlug,
      isPartnerLoading,
      partnerLocations,
      locationsLoading,
    ],
  );

  return <CoBrandedPageContext.Provider value={values}>{children}</CoBrandedPageContext.Provider>;
}
