import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Appearance } from "@stripe/stripe-js/types/stripe-js/elements-group";
import React from "react";
import { config } from "../../frontendConfig";
import CheckoutForm from "./CheckoutForm";

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      (config as unknown as Record<"stripePublicKey", string>).stripePublicKey,
    );
  }
  return stripePromise;
};

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["form"]> & {
  clientSecret: string | null;
};

export default function StripeCreditCardForm({ clientSecret, ...props }: Props) {
  const options: StripeElementsOptions = {
    clientSecret: clientSecret as string,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Lato:wght@500;600;700&family=Raleway:wght@500;600;700&display=swap",
      },
    ],
    appearance: {
      theme: "stripe" as Appearance["theme"],
      labels: "floating",
      variables: {
        fontFamily: "Lato",
        colorPrimary: "#076ad5",
        colorTextPlaceholder: "#525860",
      },
      rules: {
        ".Input": {
          "border-radius": "8px",
          "line-height": "1.25",
          "box-shadow": "none",
          color: "#00174A",
          border: "1px solid #525860",
        },
      },
    },
  };

  return clientSecret === null ? null : (
    <Elements stripe={getStripe()} options={options}>
      <CheckoutForm {...props} />
    </Elements>
  );
}
