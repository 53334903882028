import styled from "styled-components";

export const InfoWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

export const NameWrapper = styled.div`
  color: ${props => props.theme.colors.gray1};
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: ${props => props.theme.fontWeights.button};
  margin-bottom: 8px;
`;

export const SubTextWrapper = styled.div`
  color: ${props => props.theme.colors.gray3};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.default};
  svg {
    color: ${props => props.theme.colors.gray4};
  }
`;
