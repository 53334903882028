import { CSSProperties } from "react";
import styled from "styled-components";
import { responsiveSizeValue } from "../../utils/responsiveSizeValue";

export type TextProps = {
  className?: string;
  color?: string;
  display?: boolean;
  fontSize?: string | { [key: string]: string };
  fontWeight?: number;
  lineHeight?: number | string | { [key: string]: number };
  preLine?: boolean;
  heading?: boolean;
  textAlign?: CSSProperties["textAlign"];
  whiteSpace?: CSSProperties["whiteSpace"];
};

const Typography = styled("div").withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["color", "display", "fontSize", "fontWeight", "lineHeight", "preLine", "textAlign"].includes(
      prop,
    ) && defaultValidatorFn(prop),
})<TextProps>`
  ${props => props.preLine && "white-space: pre-line;"}
  color: ${props => props.color ?? props.theme.colors.gray1};
  font-family: ${props =>
    props.heading
      ? props.theme.fontFamilies.heading
      : props.display
        ? props.theme.fontFamilies.display
        : props.theme.fontFamilies.paragraph};
  font-size: ${props =>
    (typeof props.fontSize === "string"
      ? props.fontSize
      : props.fontSize && responsiveSizeValue(props.fontSize).toString()) ??
    props.theme.fontSizes.default};
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 500};
  line-height: ${props =>
    (typeof props.lineHeight !== "object"
      ? props.lineHeight
      : props.lineHeight && Number(responsiveSizeValue(props.lineHeight))) ?? 1.5};
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: ${props => props.textAlign ?? "left"};
  white-space: ${props => props.whiteSpace};
`;

export default Typography;
