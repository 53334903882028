import heic2any from "heic2any";

export type HeicFile = File & { preview: string };

export const convertFromHEIC = async (file: HeicFile): Promise<HeicFile> => {
  if (file.type.toLowerCase() === "image/heic" || file.type.toLowerCase() === "image/heif") {
    const blob = await heic2any({
      blob: file,
      toType: "image/jpeg",
    });
    const imageName = file.name.toLowerCase().replace(/.{5}$/, ".jpeg");
    const newImage = new File(Array.isArray(blob) ? blob : [blob], imageName, {
      type: "image/jpeg",
    });
    Object.assign(newImage, {
      preview: URL.createObjectURL(newImage),
    });
    return newImage as HeicFile;
  } else {
    return file;
  }
};
