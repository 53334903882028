export const lostItemPhotosCopy = {
  heading: "Do you have a photo?",
  subheading: "Lost something?",
  supportingText1: "Upload up to 3 photos.",
  supportingText2: "(Highly recommended)",
  skipButton: "Skip",
  nextButton: "Next",
  updateButton: "Update",
  uploadMoreButton: "Upload more",
  previousButton: "Go back",
  cancelButton: "Cancel",
  addPhotosTextMobile: "Click to upload",
  addPhotosText: "Click or drag to upload",
  optionalText: "PNG, JPG, HEIC or HEIF (max. 800x400px)",
};
