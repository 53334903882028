import { useQuery } from "@tanstack/react-query";
import useClaimsCountByStatus from "client/hooks/data/partner/partnerClaimCounts/useClaimsCountByStatus";
import useReturnsCountByRecoveryMethod from "client/hooks/data/partner/partnerReturnCounts/useReturnsCountByMode";
import queryFactory from "client/hooks/data/partner/queryFactory";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { PartnerItem, ReturnModeEnum, ReturnStatus } from "../../types";
import getQueryString from "../../utils/getQueryString";
import useCurrentPartner from "../../utils/useCurrentPartner";
import { useAuthDispatch } from "../auth";
import { logout } from "../auth/actions";
import { usePartnerUserState } from "../PartnerUser";
import getItemsFromReturns, { ExtendedPartnerItem } from "./getItemsFromReturns";

type Counts = {
  noMatches: number;
  readyForPickup: number;
  readyToShip: number;
  readyToMatch: number;
  totalClaims: number;
  totalPendingReturnsCount: number;
};

type OverviewContextType = {
  counts: Counts;
  returnByMailUrl: string;
  isLoading: boolean;
  readyForPickupItems: Array<PartnerItem & { match: string }>;
  readyToShipItems: Array<PartnerItem & { match: string }>;
  userShips?: boolean;
};

export const OverviewContext = createContext<OverviewContextType>({
  counts: {
    noMatches: 0,
    readyForPickup: 0,
    readyToMatch: 0,
    readyToShip: 0,
    totalClaims: 0,
    totalPendingReturnsCount: 0,
  },
  isLoading: false,
  readyForPickupItems: [],
  readyToShipItems: [],
  returnByMailUrl: "",
  userShips: undefined,
});

const errorMessage =
  "Failed to retrieve requested information. Check your internet and try again. Contact support if problem persists.";

export default function OverviewContextProvider({ children }: { children: React.ReactNode }) {
  const partnerId = useCurrentPartner();
  const dispatch = useAuthDispatch();

  const { partnerUserStateLoading } = usePartnerUserState();
  const { partnerUserLocation } = usePartnerLocationStore();
  const {
    returnsCountByRecoveryMethod: pendingReturnsCount,
    isLoading: pendingReturnsCountLoading,
  } = useReturnsCountByRecoveryMethod({
    partnerId,
    filters: {
      status: ReturnStatus.PENDING,
      ...(partnerUserLocation.id !== "" && {
        lost_locations: [partnerUserLocation.id],
      }),
    },
  });
  const { data: claimsCountByStatus, isLoading: claimsCountByStatusLoading } =
    useClaimsCountByStatus({
      partnerId,
      partnerLocationId: partnerUserLocation.id,
    });

  const { error: isUserAllowedError } = useQuery({
    ...queryFactory.locations(partnerId),
    enabled: !!partnerId,
    retry: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReadyToShipFetched, setIsReadyToShipFetched] = useState<boolean>(false);
  const [isReadyForPickupFetched, setIsReadyForPickupFetched] = useState<boolean>(false);
  const [readyToShip, setReadyToShip] = useState<Array<ExtendedPartnerItem>>([]);
  const [readyForPickup, setReadyForPickup] = useState<Array<ExtendedPartnerItem>>([]);
  const [counts, setCounts] = useState<Counts>({
    noMatches: 0,
    readyForPickup: 0,
    readyToShip: 0,
    readyToMatch: 0,
    totalClaims: 0,
    totalPendingReturnsCount: 0,
  });

  // Check if user has credentials to fetch
  useEffect(() => {
    if (isUserAllowedError?.message === "Unauthorized") {
      logout(dispatch);
    }
  }, [dispatch, isUserAllowedError]);

  useEffect(() => {
    if (!isReadyToShipFetched && !isLoading) {
      setIsLoading(true);

      getItemsFromReturns({
        partnerId,
        partnerUserLocation,
        recoveryMethodIds: [ReturnModeEnum.PICKUP, ReturnModeEnum.SHIPPING],
      })
        .then(res => {
          setReadyToShip(() => {
            setIsReadyToShipFetched(true);

            if (res === null) {
              toast.error(errorMessage);

              return [];
            }

            return res.items;
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [partnerId, partnerUserLocation, isReadyToShipFetched, isLoading]);

  useEffect(() => {
    if (isReadyToShipFetched && !isReadyForPickupFetched && !isLoading) {
      setIsLoading(true);

      getItemsFromReturns({
        partnerId,
        partnerUserLocation,
        recoveryMethodIds: [ReturnModeEnum.PICKUP],
      })
        .then(res => {
          setReadyForPickup(() => {
            setIsReadyForPickupFetched(true);

            if (res === null) {
              toast.error(errorMessage);

              return [];
            }

            return res.items;
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [isReadyToShipFetched, isReadyForPickupFetched, isLoading, partnerId, partnerUserLocation]);

  useEffect(() => {
    setCounts(prev => ({
      ...prev,
      noMatches: claimsCountByStatus === undefined ? 0 : claimsCountByStatus.NO_MATCHES,
      readyToShip:
        (pendingReturnsCount || []).find(({ name }) => name === ReturnModeEnum.SHIPPING)?.count ||
        0,
      readyForPickup:
        (pendingReturnsCount || []).find(({ name }) => name === ReturnModeEnum.PICKUP)?.count || 0,
      readyToMatch: claimsCountByStatus === undefined ? 0 : claimsCountByStatus.HAS_MATCHES,
      totalClaims: Object.entries(claimsCountByStatus || {}).reduce(
        (acc: number, [, value]) => acc + value,
        0,
      ),
      totalPendingReturnsCount: (pendingReturnsCount || []).reduce(
        (acc, { count }) => acc + count,
        0,
      ),
    }));
  }, [claimsCountByStatus, pendingReturnsCount]);

  const returnByMailUrl = useMemo<string>(() => {
    return getQueryString({
      recoveryMethod: ReturnModeEnum.SHIPPING,
    });
  }, []);

  const values: OverviewContextType = {
    counts,
    isLoading:
      (isLoading && claimsCountByStatusLoading) ||
      partnerUserStateLoading ||
      pendingReturnsCountLoading ||
      !isReadyForPickupFetched ||
      !isReadyToShipFetched,
    readyToShipItems: readyToShip,
    readyForPickupItems: readyForPickup,
    returnByMailUrl,
    userShips: true,
  };

  return <OverviewContext.Provider value={values}>{children}</OverviewContext.Provider>;
}
