export const reviewClaimCopy = {
  heading: "Are these details correct?",
  subheading: "Lost something?",
  supportingText:
    "Before moving on to the final step, review your claim to make sure all of the details are accurate.",
  claimDetailsSectionLabel: "Claim details",
  contactDetailsSectionLabel: "Contact details",
  itemNameLabel: "Name of lost item",
  dateLostLabel: "Date lost",
  itemCategoryLabel: "Category",
  itemPhotosLabel: "Photos",
  itemDescriptionLabel: "Description",
  contactFirstNameLabel: "First name",
  contactLastNameLabel: "Last name",
  contactEmailLabel: "Email",
  contactPhoneLabel: "Phone number",
  editButton: "Edit",
  nextButton: "Yes, send me a code",
  nextButtonSignedIn: "Submit claim",
  previousButton: "Go back",
  verificationEmailHeading: "Get a verification email",
  reCaptchaError: "Please check the box to continue.",
};
