import React from "react";
import { MatchIcon } from "../../../../assets/icons/icons";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import {
  ArtifactToMatchSnapshot,
  GoBackLink,
  PotentialMatch,
  Typography,
} from "../../../../ui-components";
import Text from "../../../../ui-components/Text";
import { useMatchingStore } from "../matchingStore";
import { BackButtonContainer, HeaderWrapper, StyledClaimList } from "./styles";

interface Props {
  isLookingForMatches?: boolean;
  isFinishedReviewing?: boolean;
  matchingClaimToItems?: boolean;
}

const SideOrTopPanel = ({
  isLookingForMatches,
  isFinishedReviewing,
  matchingClaimToItems,
}: Props) => {
  const currentCandidateIndex = useMatchingStore(state => state.currentCandidateIndex);
  const setCurrentCandidateIndex = useMatchingStore(state => state.setCurrentCandidateIndex);
  const artifactToMatch = useMatchingStore(state => state.artifactToMatch);
  const candidates = useMatchingStore(state => state.candidates);
  const showAllCaughtUp = useMatchingStore(state => state.showAllCaughtUp);

  const isLg = isLgQuery();

  const headerContent = (
    <>
      <BackButtonContainer>
        <GoBackLink dataTestId="matching-back-link" />
      </BackButtonContainer>
      <Typography
        className="mb-1 ms-35 mb-lg-0"
        as="h1"
        heading
        fontSize={theme.ui.fontSizes.xl}
        color={theme.ui.colors.primary700}
        fontWeight={theme.ui.fontWeights.bold}
        lineHeight={1.35}
        data-testid="reviewAndMatchCopy"
      >
        Review & match
      </Typography>
      {!isLg && candidates.length > 0 && (
        <Typography className="ms-35 mb-0 pb-md-25" as="p" color={theme.ui.colors.gray600}>
          {candidates.length > 1
            ? `Viewing 1 of ${candidates.length} potential matches for this ${
                matchingClaimToItems ? "claim" : "item"
              }`
            : `Viewing the only potential match for this ${
                matchingClaimToItems ? "claim" : "item"
              }`}
        </Typography>
      )}
    </>
  );

  const matchesListContainer =
    currentCandidateIndex > -1 && !isLookingForMatches ? (
      <ArtifactToMatchSnapshot
        matchingClaimToItems={matchingClaimToItems}
        artifactToMatch={artifactToMatch!}
      />
    ) : (
      <div className="d-flex flex-column align-items-center justify-content-center px-35">
        <MatchIcon
          accessibilityTitle="Match icon"
          titleId="match-icon"
          width={80}
          height={80}
          hideOutline
        />
        <Text textAlign="center">
          If potential matches exist for this claim, they will be listed here.
        </Text>
      </div>
    );

  return (
    <div className="d-flex flex-column bg-white p-0">
      {isLg ? <HeaderWrapper>{headerContent}</HeaderWrapper> : headerContent}
      <StyledClaimList
        className="mx-0 col-auto px-0 d-none d-lg-block"
        style={{
          marginTop: showAllCaughtUp
            ? 0
            : isLookingForMatches || (candidates.length === 0 && !isFinishedReviewing)
              ? 244
              : 0,
        }}
      >
        {isLg && matchesListContainer}
        {!(isLookingForMatches || isFinishedReviewing || candidates.length === 0) && (
          <>
            <Typography
              className="ms-35 my-25"
              as="p"
              fontSize={theme.ui.fontSizes.sm}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              lineHeight={1.2}
            >
              {`${candidates.length} Potential match${candidates.length > 1 ? "es" : ""}`}
            </Typography>
            {!isFinishedReviewing &&
              (candidates || [])?.map((candidate, index) => (
                <PotentialMatch
                  key={candidate.id}
                  candidate={candidate}
                  active={index === currentCandidateIndex}
                  onClick={() => {
                    setCurrentCandidateIndex(index);
                  }}
                />
              ))}
          </>
        )}
      </StyledClaimList>
    </div>
  );
};

export default SideOrTopPanel;
