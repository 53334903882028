import styled from "styled-components";
import { ContainerBgColors } from ".";
import theme from "../../theme";

export const StyledSection = styled.section<{
  bgColor: ContainerBgColors;
  fullWidth: boolean;
}>`
  background-color: ${props =>
    props.bgColor === ContainerBgColors.BLUE
      ? theme.ui.colors.primary300
      : props.bgColor === ContainerBgColors.DARKBLUE
        ? theme.ui.colors.primary700
        : theme.ui.colors.transparent};
  > div:first-child {
    width: 100%;
    max-width: ${props => !props.fullWidth && "1280px"};
  }
`;
