import React, { useContext } from "react";
import CarouselGallery, { GallerySides } from "../../../components/CarouselGallery";
import { PartnerClaimContext } from "../../../context";
import Details from "./Details";
import Header from "./Header";
import { LeftSliderWrapper, Main } from "./styles";
import SubMenu from "./SubMenu";

export default function PartnerSingleClaimView() {
  const { claim, subMenuHeight } = useContext(PartnerClaimContext);

  if (!claim) return null;

  return (
    <>
      <Header claim={claim} />

      <Main subMenuHeight={subMenuHeight}>
        <div className="d-flex flex-row">
          <LeftSliderWrapper className="d-none d-lg-flex flex-column">
            <CarouselGallery images={claim.images} gallerySide={GallerySides.Bottom} />
          </LeftSliderWrapper>

          <Details className="mx-lg-45 flex-grow-1" />
        </div>

        <SubMenu claim={claim} />
      </Main>
    </>
  );
}
