import styled from "styled-components";

export const DateSelectButton = styled.button<{
  fullWidth: boolean;
}>`
  appearance: none;
  padding: 20px;
  background-color: ${props => props.theme.ui.colors.white};
  gap: 10px;
  border: 2px solid ${props => props.theme.ui.colors.primary500};
  border-radius: 8px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.fullWidth && `width: 100%;`}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DayNamesHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  text-align: center;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`;

export const DayPickerButton = styled.button`
  border-color: transparent;
  background-color: white;
  color: ${props => props.theme.ui.colors.primary700};
  font-weight: 700;
  height: 40px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:disabled {
    color: ${props => props.theme.ui.colors.gray500};
  }

  &.active {
    background-color: ${props => props.theme.ui.colors.primary500};
    color: ${props => props.theme.ui.colors.white};
  }
`;
