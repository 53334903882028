import styled from "styled-components";

export const StyledHeader = styled.h1.attrs((/* props */) => ({
  className: "mb-3 mt-1",
}))`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.xxxl};
  line-height: 1.219;
  color: ${props => props.theme.colors.gray1};
`;

export const StyledSubHeader = styled.h2.attrs((/* props */) => ({
  className: "mt-5 mb-2",
}))`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.lg};
  line-height: 1.219;
  color: ${props => props.theme.colors.gray1};
`;
