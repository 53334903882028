import { IncorrectMessage } from "client/components/VerificationCode/styles";
import { HelperText } from "client/pages/Login/components/CodeVerificationView/styles";
import ReactCodeInput from "react-code-input";
import styled from "styled-components";

export const Content = styled.div`
  text-align: left;
  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 0 auto;
    max-width: 400px;
  }
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
    width: 576px;
  }
  .submit-button {
    margin-top: 28px;
  }
`;

export const StyledReactCodeInput = styled(ReactCodeInput)`
  display: inline-flex !important;
  margin-bottom: 0 !important;
  align-items: center;
  column-gap: 4px;
  font-family: ${props => props.theme.ui.fontFamilies.heading};
  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    justify-content: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    height: 44px;
    width: 40px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.ui.colors.gray500};
    outline: 1px solid transparent;
    transition:
      width,
      height 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: ${props => props.theme.ui.colors.primary500};
    text-align: center;
    &:disabled {
      outline: none !important;
    }
    &:not(:disabled) {
      &:focus-visible,
      &:active,
      &.filled {
        border-color: ${props => props.theme.ui.colors.primary500};
        outline-color: ${props => props.theme.ui.colors.primary500};
      }
      &:not([value]),
      &:not([value=""]) {
        border-color: ${props => props.theme.ui.colors.primary500};
        outline-color: ${props => props.theme.ui.colors.primary500};
      }
      &:invalid {
        color: ${props => props.theme.ui.colors.error500};
        border-color: ${props => props.theme.ui.colors.error500};
        outline-color: ${props => props.theme.ui.colors.error500};
      }
    }
    @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
      font-size: 36px;
      line-height: 48px;
      height: 60px;
      width: 60px;
    }
  }
`;

export const StyledIncorrectMessage = styled(IncorrectMessage)`
  font-family: ${props => props.theme.ui.fontFamilies.default};
  font-size: ${props => props.theme.ui.fontSizes.sm};
  line-height: 20px;
  margin-top: 8px;
`;

export const StyledHelperText = styled(HelperText)`
  margin-top: 12px;
`;
