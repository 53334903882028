export enum ModalHeaderTextEnum {
  USER_RECOVERY_METHOD = "How would you like to get it back?",
}

export enum ModalButtonTextEnum {
  MARK_AS_PACKAGED = "Yes, mark as packaged",
  PAY_AND_CONFIRM = "Pay & Confirm",
  CONFIRM = "Confirm",
  CONTINUE = "Continue",
  YES_COMPLETE_PICKUP = "Yes, complete pickup",
  CANCEL = "Cancel",
  GO_TO_RETURNS = "Go to returns",
  GO_BACK_HOME = "Go back home",
  YES_CONFIRM_MATCH = "Yes, confirm match",
  CONFIRM_AND_CONTINUE = "Confirm and continue",
  REVIEW_ANOTHER_CLAIM = "Review another claim",
  DONE_MATCHING = "Done matching",
  BACK = "Back",
}
