import { MatchCreatedPartyIcon } from "@assets/icons/icons";
import styled from "styled-components";
import { Title } from "../typography";

export const CenterSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;

export const StyledDescription = styled.div<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.white};
  width: 100%;
  max-width: 334px;
  text-align: center;
  line-height: 1.75;
`;

export const StyledImage = styled.div<{ imageUrl: string }>`
  width: 100%;
  max-width: 334px;
  border: none;
  border-radius: ${props => props.theme.borderRadius.lg};
  height: 190px;
  background-image: url(${props => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SuccessTitle = styled(Title)<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.white};
  text-align: center;
  line-height: 2;
  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    line-height: 1.411;
  }
`;

export const StyledMatchCreatedPartyIcon = styled(MatchCreatedPartyIcon)`
  width: 80px;
  height: 77px;
`;
