import React from "react";
import { SVGProps } from "./types";

export const AlertIcon = ({
  accessibilityDesc,
  accessibilityTitle,
  titleId,
  ...props
}: SVGProps) => (
  <svg className={props.className} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <title id={titleId}>{accessibilityTitle}</title>
    {accessibilityDesc && <desc id={accessibilityDesc.descId}>{accessibilityDesc.desc}</desc>}
    <path
      d="M7.99984 6.00003V8.6667M7.99984 11.3334H8.0065M7.07671 2.59451L1.59346 12.0656C1.28932 12.5909 1.13725 12.8536 1.15973 13.0692C1.17933 13.2572 1.27784 13.4281 1.43075 13.5392C1.60605 13.6667 1.90956 13.6667 2.51658 13.6667H13.4831C14.0901 13.6667 14.3936 13.6667 14.5689 13.5392C14.7218 13.4281 14.8203 13.2572 14.8399 13.0692C14.8624 12.8536 14.7104 12.5909 14.4062 12.0656L8.92296 2.59451C8.61991 2.07107 8.46839 1.80935 8.2707 1.72145C8.09826 1.64477 7.90141 1.64477 7.72897 1.72145C7.53128 1.80935 7.37976 2.07107 7.07671 2.59451Z"
      stroke="#B01121"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
