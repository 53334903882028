import classNames from "classnames";
import { useFocus } from "client/hooks";
import React, { useContext, useEffect } from "react";
import { MarkerPin, XSmallCircle } from "../../../../assets/ui-icons";
import { PartnerSelectionContext } from "../../../../context";
import theme from "../../../../theme";
import { Form } from "../../../../ui-components";
import { ResetButton } from "../PartnerSelectSearchView/styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["form"]>;

export default function PartnerSelectInput({ className, ...props }: Props) {
  const { searchText, setSearchText, isSearchViewVisible, setIsSearchViewVisible } =
    useContext(PartnerSelectionContext);

  const [inputRef, setFocus] = useFocus();

  useEffect(() => {
    if (isSearchViewVisible) setFocus();
  }, [isSearchViewVisible]);

  return (
    <Form
      {...props}
      className={classNames("flex-grow-1 flex-md-grow-0 position-relative", className)}
      onSubmit={e => e.preventDefault()}
    >
      <Form.Group>
        <Form.Control
          autoComplete="off"
          fullWidth
          type="text"
          id="search-partner"
          placeholder="Search for an airport, stadium, hotel, etc."
          onFocus={() => setIsSearchViewVisible(true)}
          onChange={e => setSearchText(e.target.value)}
          icon={{
            position: "start",
            element: (
              <MarkerPin
                color={theme.ui.colors.gray600}
                accessibilityTitle="Search partner"
                titleId="search-partner"
              />
            ),
          }}
          ref={inputRef}
          value={searchText}
          data-testid="partnerSearchInput"
        />

        {searchText && (
          <ResetButton
            variant="text"
            className="p-0"
            aria-label="reset-partner-search"
            onClick={() => setSearchText("")}
          >
            <XSmallCircle
              accessibilityTitle="reset-partner-search-icon"
              titleId="reset-partner-search-icon"
              data-testid="resetPartnerSearchIcon"
            />
          </ResetButton>
        )}
      </Form.Group>
    </Form>
  );
}
