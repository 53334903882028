import { dateFormatter } from "client/utils/dateUtils";
import useCurrentPartner from "client/utils/useCurrentPartner";
import usePartnerLocations from "./data/partner/usePartnerLocations";

export const useDateFormatter = () => {
  // prefer partner location if we are in the context of a partner
  const partnerId = useCurrentPartner();
  const { defaultPartnerLocation } = usePartnerLocations(partnerId || "");
  // force type to string until we update the partner location endpoint
  const country = defaultPartnerLocation?.address.country_code;
  const isInternational = country !== "US";

  return (date: Date, type: "short" | "long" = "short") => {
    return dateFormatter({ date, isInternational, type });
  };
};
