import classNames from "classnames";
import React, { CSSProperties, HTMLAttributes } from "react";
import { HeadingWrapper } from "./styles";

export type TagType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

export type HeadingType = HTMLAttributes<HTMLHeadingElement>;

export type Props = React.PropsWithoutRef<HeadingType> & {
  as?: TagType;
  size?: "xs" | "sm" | "md" | "lg" | "responsive";
  color?: CSSProperties["color"];
  textAlign?: CSSProperties["textAlign"];
  fontWeight?: CSSProperties["fontWeight"];
  whiteSpace?: CSSProperties["whiteSpace"];
  variant?: TagType;
};

export default function Heading({
  as = "h1",
  className,
  children,
  size = "responsive",
  variant,
  ...props
}: Props) {
  return (
    <HeadingWrapper
      as={as}
      {...props}
      className={classNames(className, `text-${variant || as || "h1"}`, size && `text-${size}`)}
    >
      {children}
    </HeadingWrapper>
  );
}
