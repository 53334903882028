import React, { useContext } from "react";
import { TabContext } from "../index";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  index: number;
};

export default function Panel({ children, index, ...props }: Props) {
  const { currentIndex } = useContext(TabContext);

  return (
    <div
      {...props}
      aria-labelledby={`tab-${index}`}
      className={`${currentIndex === index ? "d-flex" : "d-none"} px-35 px-sm-45 mb-sm-35 mt-45`}
      id={`tabpanel-${index}`}
      role="tabpanel"
    >
      {children}
    </div>
  );
}
