import styled from "styled-components";

export const BorderWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray500};
  display: flex;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 20px !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  padding: 12px 20px 0px 20px !important;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
  }
`;
export const ClaimsImageThumbnail = styled.div<{ url: string }>`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;

export const InfoWrapper = styled.div`
  min-width: 0;
`;
