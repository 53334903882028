import { ALL_OPTION } from "client/hooks";

export type OptionalReturnsCountQueryFilters = {
  category?: string;
  custom_status?: string;
  detailed_fields?: string;
  include_filtered_counts?: string;
  storage_location?: string;
};

export const RETURNS_ALL = ALL_OPTION;

export const generateAllOption = <T extends { count: number }[]>(counts: T) => {
  return {
    count: counts.reduce((prev, curr) => prev + curr.count, 0),
    name: "Showing all",
    value: RETURNS_ALL,
  };
};

export function removeFromFilters<T extends OptionalReturnsCountQueryFilters>(
  filters: T,
  keys: (keyof T)[],
): T {
  const newFilters = { ...filters };
  keys.forEach(key => {
    delete newFilters[key];
  });
  return newFilters;
}

// Removes the ALL_OPTION from the filters
export function removeAllFromFilters(filters: Record<string, string>) {
  const newFilters = { ...filters } as OptionalReturnsCountQueryFilters;
  for (const key in newFilters) {
    if (newFilters[key] === RETURNS_ALL) {
      delete newFilters[key];
    }
  }
  return newFilters;
}
