import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCurrentPartner from "../../../utils/useCurrentPartner";
import updateStorageLocationForItems from "./fetchFunctions/updateStorageLocationForItems";
import queryFactory from "./queryFactory";

export default () => {
  const partnerId = useCurrentPartner();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) =>
      updateStorageLocationForItems(partnerId, data.item_ids, data.storage_location),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.items(partnerId),
      });
      queryClient.invalidateQueries({
        queryKey: [queryFactory.returns(partnerId)],
      });
    },
    onError: error => console.error(error),
  });
};
