import styled from "styled-components";

export const Badge = styled.div<{
  borderColor: string;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  min-width: 100px;
  padding: 4px 18px;
  gap: 0.5rem;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px;
  border-color: ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
`;
