import React from "react";
import { useLocation } from "react-router-dom";
import { StyledButtonLink, StyledExternalLink, StyledInternalLink, StyledLinkText } from "./styles";

export type LinkVariants = "default" | "gray" | "error" | "dark"; // Named after Figma, color is the default, and dark is for dark backgrounds.

type Props = React.PropsWithoutRef<
  JSX.IntrinsicElements["a"] & {
    variant?: LinkVariants;
    isButton?: boolean;
    onClick?: (event) => void;
    dataTestId?: string;
  }
>; // I wanted to pass the variants as boolean props, but I went with variant instead

const Link: React.FC<Props> = ({
  className,
  children,
  href,
  target,
  variant,
  onClick,
  isButton,
  dataTestId,
  ...props
}) => {
  const location = useLocation();

  const handleClick = onClick
    ? onClick
    : (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (href === location.pathname) {
          event.preventDefault();
          window.scrollTo(0, 0);
        }
      };

  const isExternalLink = href?.startsWith("http") || href?.startsWith("mailto");

  const useLinkStyles =
    children &&
    (typeof children["type"] !== "function" ||
      children["type"] !== "svg" ||
      children["type"].displayName !== "img");

  const linkContent = useLinkStyles ? (
    <StyledLinkText
      data-testid={dataTestId || "styledLink"}
      variant={variant ?? "default"}
      style={props.style}
    >
      {children}
    </StyledLinkText>
  ) : (
    children
  );

  if (isExternalLink) {
    const opensInNewTab = target === "_blank";

    return (
      <StyledExternalLink
        {...props}
        className={className}
        href={href}
        rel={opensInNewTab ? "noopener noreferrer" : undefined}
        target={target}
        variant={variant ?? "default"}
      >
        {linkContent}
      </StyledExternalLink>
    );
  }

  if (isButton) {
    return (
      <StyledButtonLink
        className={className}
        onClick={onClick}
        variant={variant ?? "default"}
        style={props.style}
        type="button"
      >
        {linkContent}
      </StyledButtonLink>
    );
  }

  return (
    <StyledInternalLink
      {...props}
      className={className}
      onClick={handleClick}
      target={target}
      to={href || "#"}
      variant={variant ?? "default"}
    >
      {linkContent}
    </StyledInternalLink>
  );
};

export default Link;
