import classNames from "classnames";
import React from "react";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { CopySection, MediaSection, StyledRow } from "./styles";

interface Props {
  copySection: React.ReactNode;
  mediaSection: React.ReactNode;
  mediaSectionRight?: boolean;
}

const TwoColumnLayout: React.FC<Props> = ({ copySection, mediaSection, mediaSectionRight }) => {
  const isLg = isLgQuery();
  return (
    <StyledRow className="align-items-center">
      {!mediaSectionRight || !isLg ? (
        <>
          <MediaSection>{mediaSection}</MediaSection>

          <CopySection className={classNames({ "text-end": !mediaSectionRight && !isLg })}>
            {copySection}
          </CopySection>
        </>
      ) : (
        <>
          <CopySection>{copySection}</CopySection>

          <MediaSection>{mediaSection}</MediaSection>
        </>
      )}
    </StyledRow>
  );
};

export default TwoColumnLayout;
