import classnames from "classnames";
import React from "react";
import { BigChevronLeft, BigChevronRight } from "../../assets/icons/icons";
import { SmallText } from "../typography";
import { StyledUl } from "./styles";
import { DOTS, usePagination } from "./usePagination";

interface PaginationProps {
  className?: string;
  children?: React.ReactNode;
  currentPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  queryStringsOnPageNumberClick?: string;
  siblingCount?: number;
  pageSize: number;
  showPagination?: boolean;
}

export default function Pagination(props: PaginationProps) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    children,
    showPagination,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const displayedRows = (
    <SmallText>
      Showing {(currentPage - 1) * pageSize + 1}–
      {currentPage * pageSize >= totalCount ? totalCount : currentPage * pageSize} of {totalCount}
    </SmallText>
  );
  const lastPage = paginationRange && Number(paginationRange[paginationRange.length - 1]);

  if (!showPagination) {
    return <>{children}</>;
  }
  return (
    <>
      <div className="d-block d-lg-none mb-35">{displayedRows}</div>

      {children}

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-none d-lg-flex">{displayedRows}</div>

        <StyledUl
          className={`mt-1 mt-lg-25 ${classnames("pagination", {
            [String(className)]: className,
          })}`}
        >
          <li
            className={classnames("page-item", {
              disabled: currentPage === 1,
            })}
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
          >
            <div className="page-link d-none d-lg-block fw500">Previous</div>
            <div className="page-link d-flex h-100 align-items-center d-lg-none px-2">
              <BigChevronLeft
                accessibilityTitle="Previous"
                titleId="PreviousChevronPaginationTitle"
              />
            </div>
          </li>

          {(paginationRange || [])?.map((pageNumber, i) => {
            if (pageNumber === DOTS) {
              return (
                <li className="page-item dots" key={i}>
                  <div className="page-link">&#8230;</div>
                </li>
              );
            }

            return (
              <li
                key={i}
                className={classnames("page-item", {
                  active: pageNumber === currentPage,
                })}
                onClick={() => {
                  handlePageChange(pageNumber);
                }}
                data-test-id={`index-${i}`}
              >
                <div className="page-link fw500">{pageNumber}</div>
              </li>
            );
          })}

          <li
            className={classnames("page-item", {
              disabled: currentPage === lastPage,
            })}
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            data-testid="nextPage"
          >
            <div className="page-link d-none d-lg-block fw500">Next</div>
            <div className="page-link d-flex h-100 align-items-center d-lg-none px-2">
              <BigChevronRight accessibilityTitle="Next" titleId="NextChevronPaginationTitle" />
            </div>
          </li>
        </StyledUl>
      </div>
    </>
  );
}
