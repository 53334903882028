import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetPartnerImagesFromItemId,
  useGetPartnerItemFromId,
} from "../../../actions/partnerItems";
import Loader from "../../../components/Loader";

import { usePartnerUserDispatch, usePartnerUserState } from "../../../context/PartnerUser";
import { updateCurrentItemAndImages } from "../../../context/PartnerUser/actions";
import useCurrentPartner from "../../../utils/useCurrentPartner";
import ItemForm from "./ItemForm";

interface UrlParams {
  itemId: string;
}

const EditItem = () => {
  const { itemId } = useParams<UrlParams>();
  const partnerId = useCurrentPartner();
  const partnerUserDispatch = usePartnerUserDispatch();
  const partnerUserState = usePartnerUserState();
  const [hasLoaded, setHasLoaded] = useState(false);

  const [getPartnerItem, { data: item, loading: itemLoading }] = useGetPartnerItemFromId();
  const [getItemImages, { data: itemImages }] = useGetPartnerImagesFromItemId();

  useEffect(() => {
    if (itemLoading) {
      return;
    }
    const stateClaimMatchesUrlClaimId = partnerUserState.currentItem?.id === itemId;

    if (!item && !stateClaimMatchesUrlClaimId) {
      getPartnerItem({
        partnerId: partnerId,
        itemId: itemId,
        filters: { detailed_fields: "storage_location" },
      });
      getItemImages({
        partnerId,
        itemId,
      });
    } else {
      setHasLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!item && !itemImages) {
      return;
    }
    updateCurrentItemAndImages(
      partnerUserDispatch,
      item || partnerUserState.currentItem,
      itemImages,
    );
  }, [item, itemImages]);

  useEffect(() => {
    if (item && itemImages) {
      setHasLoaded(true);
    }
  }, [partnerUserState.currentItem, partnerUserState.currentItemImages]);

  return hasLoaded ? (
    <ItemForm item={partnerUserState.currentItem} itemImages={partnerUserState.currentItemImages} />
  ) : (
    <Loader />
  );
};

export default EditItem;
