import React, { useContext, useEffect, useRef } from "react";
import { DropdownMenuWrapper, ListItem } from "./styles";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { MenuItem } from "../../types/menuElements";
import { DropdownAlignment } from "./types";

interface MoreDropdownProps {
  dropdownOpen: boolean;
  menuItems: MenuItem[];
  pathname: string;
  setDropdownOpen: (boolean) => void;
}

const MoreDropdown = (props: MoreDropdownProps) => {
  const { dropdownOpen, menuItems, pathname, setDropdownOpen } = props;
  const dropdownRef = useRef<any>();
  const { isSearchModeOn, setIsSearchModeOn, setSearchTerm, setShowAllResults } = useContext(
    PartnerGlobalSearchContext,
  );

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleDropdownClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  const handleDropdownClick = e => {
    // If the clicked element is a nav option or the More wrapper / svg let onClick functions handle
    if (
      e.target.classList?.contains("nav-option") ||
      e.target.classList?.contains("more-wrapper") ||
      e.target.parentElement?.classList?.contains("more-wrapper")
    ) {
      return;
    }
    if (dropdownRef?.current?.contains(e.target)) {
      setDropdownOpen(true);
      return;
    }
    // outside click
    setDropdownOpen(false);
  };

  return (
    <DropdownMenuWrapper
      dropdownAlignment={DropdownAlignment.BOTTOM_END}
      className={`dropdown-menu ${dropdownOpen ? "show" : ""} me-2 py-25`}
      ref={dropdownRef}
      top="80px"
    >
      {menuItems.map((menuItem, i) => {
        return (
          <ListItem
            className={`d-flex p-35 m-0 nav-option ${menuItem.url === pathname ? "active" : ""}`}
            key={i}
            onClick={() => {
              setDropdownOpen(false);
              if (isSearchModeOn) {
                setIsSearchModeOn(false);
                setSearchTerm("");
                setShowAllResults(false);
              }
              menuItem.onClick();
            }}
            onKeyDown={e => {
              if (e.key === KEYBOARD_KEY.ENTER) {
                setDropdownOpen(false);
                menuItem.onClick();
                if (isSearchModeOn) {
                  setIsSearchModeOn(false);
                  setSearchTerm("");
                  setShowAllResults(false);
                }
              }
            }}
            tabIndex={0}
            width={"230px"}
          >
            {<div className="me-3">{menuItem.icon}</div>}
            {menuItem.name}
          </ListItem>
        );
      })}
    </DropdownMenuWrapper>
  );
};

export default MoreDropdown;
