import { isSameDay } from "date-fns";
import React, { useContext, useMemo } from "react";
import { LostItemCreationContext } from "../../../../../../../context";
import { isLgQuery, isSmQuery } from "../../../../../../../helpers/mediaQuery";
import theme from "../../../../../../../theme";
import { DraggableSlider, Heading, RadioCard, Text } from "../../../../../../../ui-components";
import { StyledDateInput } from "./styles";

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

type DatePill = {
  description: string;
  date: string;
};

type DateFormProps = {
  nextStep: "review" | "category";
};

export default function DateForm({ nextStep }: DateFormProps) {
  const isSm = isSmQuery();
  const isLg = isLgQuery();
  const { dateLost, setDateLost, setStep } = useContext(LostItemCreationContext);

  const dates = useMemo<DatePill[]>(() => {
    const flag = new Date();

    const arr: DatePill[] = [];

    for (let i = 0; i < (isLg ? 4 : 3); i++) {
      arr.push({
        description: i === 0 ? "Today" : i === 1 ? "Yesterday" : i === 2 ? `2 days ago` : ``,
        date: flag.toISOString(),
      });

      flag.setDate(flag.getDate() - 1);
    }

    return arr.reverse();
  }, [isLg]);

  return (
    <form
      id="date-form"
      data-testid="dateForm"
      onSubmit={e => {
        e.preventDefault();
        setStep(nextStep);
      }}
    >
      <DraggableSlider
        interactive={!isSm}
        slideWidth={122}
        slides={dates.map(({ description, date }, index) => (
          <RadioCard
            key={index}
            data-testid={`dateTile-${index}`}
            radioProps={{
              name: "date",
              value: date,
              checked: isSameDay(new Date(date), dateLost),
              onChange: e => setDateLost(new Date(e.target.value)),
            }}
          >
            <div className="text-center d-flex flex-column align-items-center">
              <Text
                className="mb-25"
                color={theme.ui.colors.primary500}
                fontSize="14px"
                lineHeight={1.2}
                fontWeight={700}
              >
                {description}
              </Text>

              <Heading className="mb-2" variant="h3" size="sm" as="h6" fontWeight={700}>
                {new Date(date).getDate()}
              </Heading>

              <Text color={theme.ui.colors.gray600}>{days[new Date(date).getDay()]}</Text>
            </div>
          </RadioCard>
        ))}
        startFrom="end"
      />

      <StyledDateInput
        fullWidth
        className="mt-3"
        data-testid="datePicker"
        onChange={date => setDateLost(date)}
        currentDate={dateLost}
        limitPrevDays={90}
      />
    </form>
  );
}
