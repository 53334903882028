import React from "react";
import { CardWrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function ClaimCard({ children }: Props) {
  return (
    <CardWrapper data-testid="cardWrapper" className="mt-lg-35">
      {children}
    </CardWrapper>
  );
}
