import { RightSmallChevron } from "@assets/icons/icons";
import { Calender } from "@assets/ui-icons";
import theme from "client/theme";
import { Text } from "client/ui-components";
import { DatePickerInputProps } from "client/ui-components/DateRangePicker";
import React, { forwardRef, useEffect, useState } from "react";
import { ContainerRounded } from "../FilterDropdown/styles";

const DateFilterInput = forwardRef<
  HTMLButtonElement,
  DatePickerInputProps & React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const { isOpen, setIsOpen, value, handleClose, ...rest } = props;

  const [title, setTitle] = useState<string>("All time");

  useEffect(() => {
    // Only update the title if the dropdown is closed
    if (!isOpen) {
      if (value?.endsWith("- ")) {
        setTitle(value.slice(0, -2));
      } else {
        setTitle(value || "All time");
      }
    }
  }, [value, isOpen]);

  const openOrClose = () => {
    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(prev => !prev);
    }
  };

  return (
    <div className="dropdown p-0 d-flex align-self-start w-100" data-testid="dateFoundFilter">
      <ContainerRounded
        aria-expanded={isOpen}
        aria-haspopup="true"
        className="dropdown-toggle"
        data-testid="dateFoundDropdownContainer"
        data-toggle="dropdown"
        {...rest}
        onClick={openOrClose}
        onKeyDown={e => {
          if (e.key === "Enter") {
            openOrClose();
            e.preventDefault();
          } else if (e.key === "Escape") {
            handleClose();
          }
        }}
        ref={ref}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: "10px",
          }}
        >
          <Calender
            titleId="Calendar icon"
            width={20}
            height={20}
            accessibilityTitle="calendar icon"
          />
          <Text fontWeight={theme.ui.fontWeights.normal}>{title}</Text>
        </span>
        {isOpen ? (
          <RightSmallChevron
            accessibilityTitle={`close dropdown icon`}
            titleId="Big chevron pointing up"
            data-testid="close"
            key={"chevron"}
            style={{
              transform: "rotate(180deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          />
        ) : (
          <RightSmallChevron
            accessibilityTitle={`open dropdown icon`}
            titleId="Big chevron pointing down"
            data-testid="open"
            key={"chevron"}
            style={{
              transition: "transform 0.2s ease-in-out",
            }}
          />
        )}
      </ContainerRounded>
    </div>
  );
});

export default DateFilterInput;
