import { CSSProperties } from "react";
import styled from "styled-components";

export const HeadingWrapper = styled.h1<{
  color?: CSSProperties["color"];
  textAlign?: CSSProperties["textAlign"];
  fontWeight?: CSSProperties["fontWeight"];
  whiteSpace?: CSSProperties["whiteSpace"];
}>`
  margin: 0;
  text-align: ${props => props.textAlign || "left"};
  color: ${props => props.color || props.theme.ui.colors.primary700};
  font-family: ${props => props.theme.ui.fontFamilies.heading};
  font-weight: ${props => props.fontWeight || props.theme.ui.fontWeights.bold};
  white-space: ${props => props.whiteSpace || "normal"};
  transition: font-size 200ms ease-in;

  &.text-h1 {
    font-size: ${props => props.theme.ui.fontSizes.big};
    line-height: 3rem;

    &.text-responsive {
      @media (min-width: ${props => props.theme.ui.breakpoints.xs}) {
        font-size: ${props => props.theme.ui.fontSizes.bigger};
        line-height: 3.5rem;
      }

      @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
        font-size: ${props => props.theme.ui.fontSizes.biggest};
        line-height: 5.75rem;
        letter-spacing: -0.02em;
      }
    }

    &.text-xs {
      font-size: ${props => props.theme.ui.fontSizes.big};
      line-height: 3rem;
    }

    &.text-sm {
      font-size: ${props => props.theme.ui.fontSizes.bigger};
      line-height: 3.5rem;
    }

    &.text-md,
    &.text-lg {
      font-size: ${props => props.theme.ui.fontSizes.biggest};
      line-height: 5.75rem;
      letter-spacing: -0.02em;
    }
  }

  &.text-h2 {
    font-size: ${props => props.theme.ui.fontSizes.xxl};
    line-height: 2.5rem;

    &.text-responsive {
      @media (min-width: ${props => props.theme.ui.breakpoints.xs}) {
        font-size: ${props => props.theme.ui.fontSizes.big};
        line-height: 3rem;
      }

      @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
        font-size: ${props => props.theme.ui.fontSizes.bigger};
        line-height: 3.5rem;
      }
    }

    &.text-xs {
      font-size: ${props => props.theme.ui.fontSizes.xxl};
      line-height: 2.5rem;
    }

    &.text-sm,
    &.text-md {
      font-size: ${props => props.theme.ui.fontSizes.big};
      line-height: 3rem;
    }

    &.text-lg {
      font-size: ${props => props.theme.ui.fontSizes.bigger};
      line-height: 3.5rem;
    }
  }

  &.text-h3 {
    font-size: ${props => props.theme.ui.fontSizes.xl};
    line-height: 2rem;

    &.text-responsive {
      @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
        font-size: ${props => props.theme.ui.fontSizes.xxl};
        line-height: 2.5rem;
      }

      @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
        font-size: ${props => props.theme.ui.fontSizes.big};
        line-height: 3rem;
      }
    }

    &.text-xs,
    &.text-sm {
      font-size: ${props => props.theme.ui.fontSizes.xl};
      line-height: 2rem;
    }

    &.text-md {
      font-size: ${props => props.theme.ui.fontSizes.xxl};
      line-height: 2.5rem;
    }

    &.text-lg {
      font-size: ${props => props.theme.ui.fontSizes.big};
      line-height: 3rem;
    }
  }

  &.text-h4 {
    font-size: ${props => props.theme.ui.fontSizes.md};
    line-height: 1.5rem;

    &.text-responsive {
      @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
        font-size: ${props => props.theme.ui.fontSizes.lg};
        line-height: 1.875rem;
      }
    }

    &.text-lg {
      font-size: ${props => props.theme.ui.fontSizes.lg};
      line-height: 1.875rem;
    }
  }

  &.text-h5 {
    font-size: ${props => props.theme.ui.fontSizes.base};
    line-height: 1.5rem;
  }

  &.text-h6 {
    font-size: ${props => props.theme.ui.fontSizes.sm};
    line-height: 1.5rem;
  }
`;
