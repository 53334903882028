import { Envelope } from "@styled-icons/boxicons-regular";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const Email = styled.a`
  color: ${props => props.theme.colors.primaryBlue};
  font-family: Raleway;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.md};
  line-height: 2;
  letter-spacing: -0.01em;
`;

function Support() {
  return (
    <section className="overflow-hidden position-relative p-5">
      <Container>
        <Row>
          <Col xl="4">
            <h2 className="mt-0">Contact us</h2>
            <div>
              <Email
                href="mailto:support@thanksboomerang.com"
                target="_blank"
                rel="noreferrer noopener"
                className="no-decorate"
              >
                <Envelope size="22" /> <span>support@thanksboomerang.com</span>
              </Email>
            </div>
          </Col>
          <Col xl="8">
            <h2 className="mt-0">Partner&apos;s FAQ</h2>
            <Row className="mx-0">
              <Col sm="4" className="ps-0">
                <h3>How much does Boomerang cost us?</h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Depending on the partnership, Boomerang can either be free to you (the company) or
                  it can be free to the lost item customer. We have some partners that pay for the
                  packaging, shipping and our $9.99 service fee per item delivered while others pass
                  the cost onto the owner of the lost item.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>What items does Boomerang collect and store from their partners?</h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Boomerang collects and stores thousands of items. The most common items are
                  glasses, jewelry, wallets, purses, watches, clothing, ID’s, passports, credit
                  cards, phones, backpacks, chargers, cameras, and luggage.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>
                  Will Boomerang collect sensitive items such as credit cards, passports, Drivers
                  Licenses, etc?
                </h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Yes, sensitive items are best stored with us instead of the lost and found drawer
                  at your location. Boomerang even proactively attempts to get in touch with the
                  owner of the item before they realize they lost the item.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>How do we notify Boomerang that we have a lost item to be picked up?</h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Boomerang will schedule the pick-up frequency with you to determine the most
                  optimal pick-up schedule for the partnership e.g. daily, twice daily, the day
                  after an event, etc.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>
                  How do we know if our customer ever received their lost item back from Boomerang?
                </h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Boomerang provides you real-time access into the operation via the Boomerang
                  Partner Portal which allows you to see all of the items collected, the details
                  around the item, and the status e.g. collected, stored, returned, unclaimed, etc.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>What items will Boomerang not pick up?</h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Boomerang will not pick up drinks or perishable items like food and snacks.
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>I have a comment / feedback I would like to share. How can I reach out?</h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  Partners can provide feedback and suggestions to Boomerang at{" "}
                  <a
                    href="mailto:support@thanksboomerang.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="no-decorate"
                  >
                    support@thanksboomerang.com
                  </a>
                  .
                </p>
              </Col>
              <div className="w-100 my-4 border border-1 border-top-0" />
              <Col sm="4" className="ps-0">
                <h3>
                  The lost item was returned before Boomerang’s arrival to pick it up. What should I
                  do?
                </h3>
              </Col>
              <Col sm="8">
                <p className="mb-0">
                  No problem. We are glad the item was returned to the rightful owner. There’s no
                  action needed, as Boomerang only manages the items that we collect from you.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Support;
