import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useTriggerMatchingForClaim } from "../../../../actions/partnerMatches";
import SuperMatchIcon from "../../../../assets/ui-icons/SuperMatchIcon";
import history from "../../../../context/history";
import theme from "../../../../theme";
import { PartnerClaim } from "../../../../types";
import { Button, Text } from "../../../../ui-components";
import useCurrentPartner from "../../../../utils/useCurrentPartner";

function LookForMatchesCTA({ claim }: { claim: PartnerClaim }) {
  const partnerId = useCurrentPartner();
  const [triggerMatching, { data, error }, reset] = useTriggerMatchingForClaim();

  useEffect(() => {
    if (error) {
      toast.error(`Error from server: ${error}`);
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.status.includes("success")) {
        history.push(`/partner/matches/matching/?claimId=${claim.id}&lookingForMatches`, {
          returnPath: `${location.pathname}?back=/partner/claims`,
        });
      } else {
        toast.error(data.status);
      }
    }
  }, [data]);

  return (
    <div className="d-flex flex-grow-1 justify-content-end align-items-center gap-35">
      <div className="d-none d-lg-flex flex-row align-items-center gap-35">
        <div className="d-flex flex-row align-items-center gap-25" data-testid="headerCopy">
          <SuperMatchIcon
            accessibilityTitle="review-match-icon"
            titleId="review-match-icon"
            color={theme.ui.colors.primary500}
          />

          <Text color={theme.ui.colors.white}>Match now or wait for potential matches!</Text>
        </div>

        <Button
          aria-label="review matches"
          data-testid="reviewMatchesButton"
          size="2xl"
          onClick={async () => {
            triggerMatching(partnerId, claim.id);
          }}
          icon={
            <SuperMatchIcon accessibilityTitle="review-match-icon" titleId="review-match-icon" />
          }
        >
          Look for matches
        </Button>
      </div>
    </div>
  );
}

export default LookForMatchesCTA;
