import React, { createContext, useEffect, useMemo, useState } from "react";
import { isMdQuery } from "../../helpers/mediaQuery";
import getLocationForPartner from "../../hooks/data/user/fetchFunctions/fetchLocations";
import fetchPartners from "../../hooks/data/user/fetchFunctions/fetchPartners";
import { LocationSearchResponse, Partner, PartnerSearchResponse } from "../../types";

type SearchPartnerResult = Partner & {
  hydratedSlug: {
    text: string;
    isMatch: boolean;
  }[];
  hydratedName: {
    text: string;
    isMatch: boolean;
  }[];
  hydratedAddress: {
    text: string;
    isMatch: boolean;
  }[];
};

type PartnerSelectionContextType = {
  searchResults: (SearchPartnerResult | undefined)[];
  isSearchViewVisible: boolean;
  setIsSearchViewVisible: React.Dispatch<
    React.SetStateAction<PartnerSelectionContextType["isSearchViewVisible"]>
  >;
  isLoading: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<PartnerSelectionContextType["searchText"]>>;
};

export const PartnerSelectionContext = createContext<PartnerSelectionContextType>({
  searchResults: [],
  isSearchViewVisible: false,
  setIsSearchViewVisible: () => undefined,
  searchText: "",
  isLoading: false,
  setSearchText: () => undefined,
});

function matchSearchText(text: string, search: RegExp) {
  const matches = Array.from(text.toLowerCase().matchAll(search), match => match);

  let firstIndex = 0;
  const arr: Array<SearchPartnerResult["hydratedSlug"][0]> = [];

  if (matches.length === 0) {
    arr.push({
      text,
      isMatch: false,
    });
  }

  matches.forEach((match, index) => {
    arr.push({
      text: text.slice(firstIndex, match.index),
      isMatch: false,
    });

    arr.push({
      text: text.slice(match.index, (match.index as number) + match[0].length),
      isMatch: true,
    });

    firstIndex = (match.index as number) + match[0].length;

    if (index === matches.length - 1) {
      arr.push({
        text: text.slice(firstIndex),
        isMatch: false,
      });
    }
  });

  return arr;
}

export default function PartnerSelectionProvider({ children }: { children: React.ReactNode }) {
  const isMd = isMdQuery();
  const [isSearchViewVisible, setIsSearchViewVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [partners, setPartners] = useState<PartnerSearchResponse | null>(null);
  const [locations, setLocations] = useState<(LocationSearchResponse | null)[] | null>(null);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);
  const [isPartnersLoading, setIsPartnersLoading] = useState(false);
  const [hydratedPartners, setHydratedPartners] = useState<PartnerSearchResponse | null>(null);

  useEffect(() => {
    if (searchText === "" || searchText.length < 3) {
      setPartners(null);
    }
  }, [searchText]);

  let timeoutId;
  useEffect(() => {
    if (searchText.length < 3) return;

    clearTimeout(timeoutId);

    setIsPartnersLoading(true);
    timeoutId = setTimeout(() => {
      fetchPartners(searchText).then(partners => {
        setIsPartnersLoading(false);
        setPartners(partners);
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText]);

  useEffect(() => {
    if (!partners || partners === null || isPartnersLoading) return;

    Promise.all(
      partners.results.map(p => {
        setIsLocationsLoading(true);
        return getLocationForPartner({ partner: p.id }).then(res => res);
      }),
    )
      .then(locations => {
        setLocations(locations);
        setIsLocationsLoading(false);
      })
      .catch(() => {
        setIsPartnersLoading(false);
        setIsLocationsLoading(false);
      });
  }, [partners, isPartnersLoading]);

  useEffect(() => {
    if (
      partners === null ||
      locations === null ||
      locations === undefined ||
      results === null ||
      results === undefined
    )
      return;

    setHydratedPartners({
      ...partners,
      results: partners.results.map((partner, index) => {
        return {
          ...partner,
          locations: {
            ...locations[index],
            results: locations[index]?.results?.map(location => ({
              ...location,
              address: {
                ...location.address,
              },
            })),
            count: locations[index]?.results?.length || 0,
          },
        };
      }) as Partner[],
    });
  }, [partners, locations]);

  const results = useMemo<(SearchPartnerResult | undefined)[]>(() => {
    if (
      isPartnersLoading ||
      isLocationsLoading ||
      partners === null ||
      partners.count === 0 ||
      hydratedPartners === null ||
      !hydratedPartners.results.length ||
      hydratedPartners.count === 0
    )
      return [];

    return searchText.length < 3
      ? []
      : hydratedPartners.results.map(p => {
          const search = new RegExp(searchText, "ig");

          if (p.locations !== undefined && p.locations?.count && p.locations?.count >= 1) {
            return {
              ...p,
              hydratedSlug: matchSearchText(p.slug, search),
              hydratedName: matchSearchText(p.name, search),
              hydratedAddress: matchSearchText(
                `${
                  p.locations.count > 1
                    ? "See locations"
                    : [
                        p.locations.results![0].address.address1,
                        p.locations.results![0].address.city,
                        p.locations.results![0].address.region_code,
                        p.locations.results![0].address.postal_code,
                      ].join(", ")
                }`,
                search,
              ),
            };
          }
        });
  }, [hydratedPartners]);

  useEffect(() => {
    setSearchText("");
  }, [isSearchViewVisible]);

  useEffect(() => {
    setIsSearchViewVisible(false);
  }, [isMd]);

  return (
    <PartnerSelectionContext.Provider
      value={{
        searchText,
        setSearchText,
        isLoading: isPartnersLoading || isLocationsLoading,
        searchResults: results,
        isSearchViewVisible,
        setIsSearchViewVisible,
      }}
    >
      {children}
    </PartnerSelectionContext.Provider>
  );
}
