import { useDateFormatter } from "client/hooks/useDateFormatter";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import React from "react";
import { ArrowLeft } from "../../assets/icons/icons";
import history from "../../context/history";
import { PartnerClaim, PartnerItem, PartnerLocation } from "../../types";
import { NoPhotoImagePlaceholder, StyledCarousel } from "../ImageCarousel";
import {
  StyledCarouselItem,
  StyledHeader,
  StyledHeaderLabelText,
  StyledHeaderTitle,
  StyledTextLabelValue,
} from "./styles";

export enum DateLocationVerbEnum {
  LOST = "lost",
  FOUND = "found",
  RETURNED = "returned",
}

interface SingleViewHeaderProps {
  artifactData: PartnerClaim | PartnerItem;
  artifactLocation: PartnerLocation;
  buttons: React.ReactNode;
  dateLostFound: Date;
  pageTitle: string;
  pageDescription: string;
  dateLocationVerb: DateLocationVerbEnum;
}

const SingleViewHeader = (props: SingleViewHeaderProps) => {
  const {
    artifactData,
    artifactLocation,
    buttons,
    dateLostFound,
    dateLocationVerb,
    pageTitle,
    pageDescription,
  } = props;
  const dateFormatter = useDateFormatter();
  return (
    <StyledHeader className="row mx-0 align-items-start ps-35 pe-45 py-45 py-lg-35  justify-content-between">
      <div className="col-auto row mx-0 px-0">
        <ArrowLeft
          className="pe-2 pe-lg-35 col-auto px-0 cursor-pointer"
          tabIndex={0}
          onClick={() => history.goBack()}
          accessibilityTitle="Go back"
          titleId="ArrowLeftSingleViewHeaderTitle"
          data-testid="arrowBackButton"
        />
        <div className="d-none d-lg-flex col-auto px-0">
          {artifactData?.images?.length !== 0 ? (
            <StyledCarousel
              touch
              interval={null}
              prevIcon={false}
              nextIcon={false}
              controls={false}
              indicators={false}
              className="align-items-stretch col-auto px-0"
              style={{ width: "84px" }}
            >
              <StyledCarouselItem
                key={0}
                style={{
                  backgroundImage: `url(${artifactData.images[0].image})`,
                }}
                className="thumbnail rounded-1"
              >
                <span role="img" aria-label={`image of ${artifactData.name}`} />
              </StyledCarouselItem>
            </StyledCarousel>
          ) : (
            <NoPhotoImagePlaceholder square width="84px">
              <span role="img" aria-label="No image placeholder image" />
            </NoPhotoImagePlaceholder>
          )}
        </div>
        <div className="col px-0 ps-lg-35">
          <StyledHeaderLabelText
            onClick={() => history.goBack()}
            className="mt-1 mb-45 mt-lg-2 mb-lg-2 d-block d-lg-none cursor-pointer"
          >
            {pageTitle}
          </StyledHeaderLabelText>
          <StyledHeaderLabelText className="mt-1 mb-45 mt-lg-2 mb-lg-2 d-none d-lg-block">
            {pageDescription}
          </StyledHeaderLabelText>
          <StyledHeaderTitle className="ms-n45 ps-25 px-lg-0 ms-lg-0 col-auto">
            {artifactData?.name}
          </StyledHeaderTitle>
        </div>
      </div>

      <div className="col-auto px-0 row mx-0 mt-25">
        <div className="col-auto px-0 me-45 ms-xxl-225 me-xl-5 d-none d-xl-flex">
          <StyledTextLabelValue
            className="header-label-light"
            label={`Date ${dateLocationVerb}`}
            value={dateLostFound && dateFormatter(convertToDateIgnoringTime(dateLostFound), "long")}
          />
        </div>
        <div className="col-auto me-45 ms-xxl-225 me-xl-5 px-0 d-none d-xl-flex">
          <StyledTextLabelValue
            className="header-label-light"
            label={`Location ${dateLocationVerb}`}
            value={artifactLocation?.name}
          />
        </div>
        <div className="ms-45 col px-0 mx-0 gap-2 d-none d-lg-flex">{buttons}</div>
      </div>
    </StyledHeader>
  );
};

export default SingleViewHeader;
