import { SearchResultMagnifyingGlass } from "@assets/icons/icons";
import styled from "styled-components";

export const StyledSearchResultMagnifyingGlass = styled(SearchResultMagnifyingGlass)`
  margin-top: 40px;
  height: 58px;
  width: 58px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 98px;
    width: 98px;
    margin-top: 68px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 72px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.ui.colors.white};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: 48px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0px 44px 0 44px !important;
    max-width: 1072px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.xl}) {
    margin: 0px auto 97px auto !important;
    max-width: 1072px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0 20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: 20px 44px 0 44px;
    max-width: 1072px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 20px auto 0 auto;
    max-width: 1072px;
  }
`;
