import classNames from "classnames";
import useClaim from "client/hooks/data/user/useClaim";
import useGetImagesWithClaimId from "client/hooks/data/user/useGetImagesWithClaimId";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { format } from "date-fns";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AddressLocationSnapshot } from "../../../../components/AddressLocationSnapshot";
import { ClaimDetailsContext } from "../../../../context/ClaimDetails";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import useCategories from "../../../../hooks/data/user/useCategories";
import theme from "../../../../theme";
import { UserItem } from "../../../../types";
import { Heading, ImageCarousel, Text } from "../../../../ui-components";
import { getCategoryName } from "../../../../utils/mappers";
import { CardContent } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  title?: string;
  imagePosition?: "start" | "end";
};

function formatItem(item: UserItem) {
  return {
    ...item,
    lost_at: item.collected_at,
    category: {
      id: item.category,
    },
  };
}

export default function MainDisplay({
  className,
  title = "Lost item",
  imagePosition = "start",
  ...rest
}: Props) {
  const categories = useCategories();
  const { itemImages, item } = useContext(ClaimDetailsContext);
  const { claimId } = useParams<{ claimId: string }>();
  const { data: claimImages } = useGetImagesWithClaimId(claimId);
  const { claim } = useClaim(claimId);
  const isLg = isLgQuery();

  const hasItem = !!item;
  const selectedArtifact = hasItem ? formatItem(item) : claim;
  const artifactImages = hasItem ? itemImages : claimImages;

  const imageDisplay = useMemo<React.ReactNode>(() => {
    return artifactImages !== null &&
      artifactImages !== undefined &&
      artifactImages.count > 0 &&
      artifactImages.results.length > 0 ? (
      <>
        {imagePosition === "start" && isLg && (
          <div className="d-flex">
            <div className="vr mx-35 h-100"></div>
          </div>
        )}

        <ImageCarousel
          images={artifactImages.results.map(image => image.image)}
          className={classNames(
            "mb-0 mx-auto  d-block flex-shrink-0",
            imagePosition === "end" && "ms-lg-45",
          )}
          dimensions={
            isLg
              ? {
                  width: 340,
                  height: 456,
                }
              : {
                  width: 192,
                  height: 256,
                }
          }
          controls={artifactImages.count > 1}
          indicators={artifactImages.count > 1}
        />
      </>
    ) : null;
  }, [artifactImages, isLg, imagePosition]);

  if (!selectedArtifact) return null;
  return (
    <CardContent
      {...rest}
      className={classNames(
        "d-flex flex-column bg-white",
        imagePosition === "start" ? "flex-lg-row-reverse" : "flex-lg-row",
        className,
      )}
    >
      <div className="d-flex flex-column flex-grow-1">
        <Heading variant="h4" as="h3" size="lg" className="mb-35">
          {title}
        </Heading>

        {!isLg ? imageDisplay : null}

        <div
          className={classNames(
            "d-flex flex-column flex-grow-1",
            imagePosition === "start" ? "flex-lg-row-reverse" : "flex-lg-row",
          )}
        >
          <div className="d-flex flex-column flex-grow-1">
            <Heading variant="h4" as="h4" size="sm" className="mb-1">
              {selectedArtifact.name}
            </Heading>

            <Text className="mb-35">{selectedArtifact.description}</Text>

            <Text className="mb-1" fontWeight={400} color={theme.ui.colors.gray600}>
              Category
            </Text>
            <Text className="mb-35" fontWeight={700}>
              {getCategoryName(categories.data ?? [], selectedArtifact.category?.id)}
            </Text>

            <Text className="mb-1" fontWeight={400} color={theme.ui.colors.gray600}>
              {hasItem ? "Date found" : "Date lost"}
            </Text>
            <Text className="mb-35" fontWeight={700}>
              {format(convertToDateIgnoringTime(selectedArtifact.lost_at), "LLL dd, yyyy")}
            </Text>

            <Text className="mb-1" fontWeight={400} color={theme.ui.colors.gray600}>
              {hasItem ? "Found item location" : "Lost item location"}
            </Text>
            <Text fontWeight={700}>
              <AddressLocationSnapshot address={selectedArtifact.lost_location} showName />
            </Text>
          </div>

          {isLg ? imageDisplay : null}
        </div>
      </div>
    </CardContent>
  );
}
