import React from "react";

import { NoPhotoImagePlaceholder } from "../ImageCarousel";
import { GridImage } from "./styles";

export interface ImageGalleryProps {
  images?: any[];
  imageWidth?: string;
  imageHeight?: string;
  verticalPadding?: string;
  horizontalPadding?: string;
  onImageClick?: (index: number) => void;
  currentIndex?: number;
}

const ImageGallery: React.FC<ImageGalleryProps> = props => {
  const {
    images,
    imageWidth,
    imageHeight,
    verticalPadding,
    horizontalPadding,
    onImageClick,
    currentIndex,
  } = props;

  return (
    <div
      className={`row mx-0 ${images?.length === 1 ? "d-none" : ""} ${
        images?.length === 3 ? "justify-content-between" : "gap-3"
      }`}
    >
      {images?.length !== 0 ? (
        (images || [])?.map((file, index) => (
          <GridImage
            className="px-0 cursor-pointer"
            width={imageWidth}
            height={imageHeight}
            key={index}
            src={String(file.image)}
            verticalPadding={verticalPadding}
            horizontalPadding={horizontalPadding}
            active={currentIndex === index}
            onClick={() => {
              onImageClick && onImageClick(index);
            }}
          >
            <span role="img" aria-label={`image ${index + 1} of ${images?.length}`} />
          </GridImage>
        ))
      ) : (
        <NoPhotoImagePlaceholder width="100%" className="mt-n35">
          <span role="img" aria-label="no image placeholder image" />
        </NoPhotoImagePlaceholder>
      )}
    </div>
  );
};

export default ImageGallery;
