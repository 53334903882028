import classNames from "classnames";
import React from "react";
import theme from "../../theme";
import { TextBold, Typography } from "../typography";

export interface TextLabelValueProps {
  className?: string;
  label?: string;
  largeLabelFontSize?: boolean;
  value: React.ReactNode | Date | string | number;
  preLine?: boolean;
  largeValueFontSize?: boolean;
  noLabelBottomPadding?: boolean;
  valueDisplayFlex?: boolean;
}

const TextLabelValue: React.FC<TextLabelValueProps> = props => {
  const {
    className,
    value,
    label,
    preLine,
    largeValueFontSize,
    largeLabelFontSize,
    noLabelBottomPadding,
    valueDisplayFlex,
  } = props;
  return (
    <div className={classNames(className)} data-testid={props["data-testid"]}>
      <Typography
        fontSize={largeLabelFontSize ? theme.fontSizes.default : theme.fontSizes.sm}
        color={theme.colors.gray3}
        className={classNames({ "pb-2": !noLabelBottomPadding })}
        lineHeight={1.25}
        as="label"
        data-testid="label"
      >
        {label}
      </Typography>
      <TextBold
        preLine={preLine}
        className={classNames({
          fs20: largeValueFontSize,
          "d-flex": valueDisplayFlex,
        })}
        data-testid="labelValue"
      >
        {value}
      </TextBold>
    </div>
  );
};

export default TextLabelValue;
