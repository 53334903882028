// eslint-disable-next-line
// @ts-nocheck
// TODO: Add TS checking back to this file after upgrading to TS 5.1
// See:https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1627850951
import { useEffect } from "react";

const useScrollToTop = (deps: any[]) => {
  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    scroll();
  }, deps);

  return scroll;
};

export default useScrollToTop;
