import React from "react";

import formatPhoneNumber from "../../utils/formatPhoneNumber";

import EmailLink from "client/components/EmailLink";
import { EmailIcon, PhoneIcon } from "../../assets/icons/icons";
import { StyledLabel, StyledTextBold } from "./styles";

interface ContactDetailsSnapshotProps {
  email?: string;
  header?: boolean;
  headerText?: string;
  name?: string;
  className?: string;
  phoneNumber?: string;
  textColor?: string;
  useSmallHeader?: boolean;
  hasEmailLink?: boolean;
}

const ContactDetailsSnapshot = (props: ContactDetailsSnapshotProps) => {
  const {
    email,
    header,
    headerText,
    name,
    className,
    phoneNumber,
    textColor,
    useSmallHeader,
    hasEmailLink,
  } = props;
  return (
    <div className={`d-flex flex-column p-0 ${String(className)}`}>
      {header && (
        <StyledLabel
          color={textColor}
          className={`contact-details-label ${useSmallHeader ? "mb-1" : "mb-2"}`}
        >
          {headerText || "Contact details"}
        </StyledLabel>
      )}
      {name && (
        <StyledTextBold
          className={`text-truncate ${useSmallHeader ? "mb-1" : "mb-2"}`}
          small={useSmallHeader}
        >
          {name}
        </StyledTextBold>
      )}
      {email && (
        <div className="d-flex gap-2 align-items-center mb-2">
          <EmailIcon
            color={textColor}
            height="18px"
            width="18px"
            accessibilityTitle="Email"
            titleId="ContactDetailsSnapshotEmailTitle"
          />
          {hasEmailLink ? (
            <EmailLink fontFamily="Montserrat" fontWeight={600} email={email} />
          ) : (
            <StyledLabel className="contact-details-snapshot-email" color={textColor}>
              {email}
            </StyledLabel>
          )}
        </div>
      )}
      <div className="d-flex gap-2 align-items-center">
        <PhoneIcon
          color={textColor}
          height="18px"
          width="18px"
          accessibilityTitle="Phone number"
          titleId="ContactDetailsSnapshotPhoneNumberTitle"
        />
        <StyledLabel color={textColor} className="contact-details-snapshot-phone">
          {phoneNumber ? formatPhoneNumber(phoneNumber) : "---"}
        </StyledLabel>
      </div>
    </div>
  );
};

export default ContactDetailsSnapshot;
