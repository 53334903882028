import { useSearchParam } from "client/hooks";
import useSearchParams from "client/hooks/useSearchParams";
import React from "react";

export function ClearFilterButton() {
  const [, setSearch] = useSearchParams();
  const [category] = useSearchParam<string>("category", "ALL");
  const [dateAfter] = useSearchParam<string>("date_after", "ALL");
  const [dateBefore] = useSearchParam<string>("date_before", "ALL");

  const onClearFilter = () =>
    setSearch({
      category: [],
      date_after: "",
      date_before: "",
    });

  if (category === "ALL" && dateAfter === "ALL" && dateBefore === "ALL") return null;

  return (
    <button
      className="btn btn-link mt-md-4 text-decoration-none fw-bold"
      data-testid="clearAllButton"
      onClick={onClearFilter}
    >
      Clear All
    </button>
  );
}
