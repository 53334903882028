import { fetchApiData } from "client/utils/fetchApiData";
import { UpdateClaimRequest, UpdateClaimResponse } from "../../../../types";
import { getCommonHeaders } from "../../../../utils/headers";

export default (claimRequest: UpdateClaimRequest): Promise<UpdateClaimResponse> => {
  return fetchApiData({
    url: `/api/user/claims/${claimRequest.id}/`,
    options: {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify({
        name: claimRequest.name,
        description: claimRequest.description,
        category: claimRequest.category,
        lost_location: claimRequest.lost_location,
        lost_at: claimRequest.lost_at,
      }),
    },
  });
};
