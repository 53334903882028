import React from "react";
import { Link } from "./styles";

type Props = {
  email: string;
  fontFamily: string;
  fontWeight: number;
};

export default function EmailLink({ email, fontFamily, fontWeight }: Props) {
  return (
    <Link
      data-testid="userEmailLink"
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      href={`mailto:${email}`}
      tabIndex={0}
    >
      {email}
    </Link>
  );
}
