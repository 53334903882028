import { PartnerLocationFragment } from "client/hooks/data/partner/usePartnerLocationStore";
import React from "react";
import { StyledArrowLeft } from "../../assets/icons/icons";
import { isLgQuery } from "../../helpers/mediaQuery";
import theme from "../../theme";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { PartnerLocation } from "../../types/models";
import PartnerLocationDropdown from "../Dropdowns/PartnerLocationDropdown";
import { Hamburger } from "../Hamburger";
import {
  HeaderTextWrapper,
  HeaderWrapper,
  StyledCol,
  StyledSubHeader,
  StyledTitle,
  TopBackgroundAccent,
} from "./styles";

interface HeaderProps {
  additionalComponent?: React.ReactNode;
  currentPartnerLocation?: PartnerLocationFragment;
  hamburgerOpen?: boolean;
  leftArrowText?: string;
  maxWidth?: string;
  onHamburgerClick?: () => void;
  onLeftArrowClick?: () => void;
  onLocationSelection?: (_: PartnerLocationFragment) => void;
  partnerLocations?: PartnerLocation[];
  subHeaderText?: string;
  title: string;
  useHeaderWrapper?: boolean;
}

const Header = (props: HeaderProps) => {
  const {
    additionalComponent,
    currentPartnerLocation,
    hamburgerOpen = false,
    leftArrowText,
    maxWidth,
    onHamburgerClick,
    onLeftArrowClick,
    onLocationSelection,
    partnerLocations,
    subHeaderText,
    title,
    useHeaderWrapper,
  } = props;

  const useLocationDropdown = partnerLocations && onLocationSelection && currentPartnerLocation;
  const isLg = isLgQuery();

  const interiorComponent = (
    <>
      <HeaderTextWrapper
        className={`mx-0 row align-items-start mt-n4 ${
          !onLeftArrowClick ? "justify-content-end" : ""
        } ${maxWidth ? "px-0" : useHeaderWrapper ? "px-lg-1" : "px-lg-45"} ${
          useLocationDropdown && !isLg ? "gap-45" : ""
        }`}
        maxWidth={maxWidth}
      >
        {typeof onLeftArrowClick === "function" && (
          <StyledCol
            onClick={onLeftArrowClick}
            xs="auto"
            className="px-0 no-decorate mt-3 cursor-pointer"
          >
            <StyledArrowLeft
              onKeyPress={(e: React.KeyboardEvent) => {
                if (e.key === KEYBOARD_KEY.ENTER) {
                  onLeftArrowClick && onLeftArrowClick();
                }
              }}
              data-testid="back-arrow"
              tabIndex={isLg ? 0 : 1}
              className="me-2"
              accessibilityTitle="Go back"
              titleId="SimpleHeaderArrowLeftTitle"
            />
            {leftArrowText}
          </StyledCol>
        )}
        {hamburgerOpen && onHamburgerClick && (
          <div className="col-auto d-flex align-items-center d-lg-none pe-0 align-self-end">
            <Hamburger
              onKeyPress={onHamburgerClick}
              onClick={onHamburgerClick}
              open={hamburgerOpen!}
            />
          </div>
        )}
        {subHeaderText && (
          <StyledSubHeader data-testid="header-subheader" className="px-0 pb-2 pt-3">
            {subHeaderText}
          </StyledSubHeader>
        )}
        <div
          className={`d-flex px-0 ${
            !isLg ? "flex-column gap-3" : "flex-row align-items-center"
          } justify-content-between`}
        >
          <StyledTitle data-testid="header-title" className="px-0">
            {title}
          </StyledTitle>
          {useLocationDropdown && (
            <PartnerLocationDropdown
              currentPartnerLocation={currentPartnerLocation}
              partnerLocations={partnerLocations}
              onLocationSelection={onLocationSelection}
              className={!isLg ? "w-100" : ""}
            />
          )}
        </div>
      </HeaderTextWrapper>
      {additionalComponent}
    </>
  );

  return (
    <TopBackgroundAccent
      role="banner"
      smHeight={useLocationDropdown ? theme.heights.mobileLocationDropdownHeader : ""}
      className={`row justify-content-center mx-0 px-3 ${maxWidth ? "" : "px-lg-0"} ${
        useHeaderWrapper ? (!hamburgerOpen ? "mb-1 pt-45" : "pt-45") : "align-items-center"
      }`}
    >
      {useHeaderWrapper ? <HeaderWrapper>{interiorComponent}</HeaderWrapper> : interiorComponent}
    </TopBackgroundAccent>
  );
};

export default Header;
