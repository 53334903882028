import styled from "styled-components";

export const Thumbnail = styled.div`
margin-bottom:20px;
@media (min-width: ${props => props.theme.breakpoints.lg}) {
  margin-bottom: 0;
}
  .hidden {
    display: none;
  }
  &:hover .hidden {
      display: block;
    }
  }
  .close-btn {
    border: 2px solid  ${props => props.theme.colors.white};
  }
`;

export const StyledImage = styled.div`
  width: 179px;
  margin: auto;
  border: none;
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid ${props => props.theme.colors.gray8};
  height: 269px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
    height: 337px;
  }
`;

export const StyledPositioningDiv = styled.div`
  width: 179px;
  margin: auto;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }
`;

export const StyledClose = styled.div`
  border-radius: 100%;
  background: ${props => props.theme.colors.primaryBlue};
  position: absolute;
  top: 6px;
  right: 6px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  svg {
    color: ${props => props.theme.colors.white};
  }
`;
