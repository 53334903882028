import { create } from "zustand";

interface InventoryState {
  hasBulkOptions: boolean;
  setHasBulkOptions: (_: boolean) => void;
}

export const useInventoryStore = create<InventoryState>()(set => ({
  hasBulkOptions: false,
  setHasBulkOptions: hasBulkOptions => set(() => ({ hasBulkOptions })),
}));
