import React, { createContext } from "react";
import { FieldError } from "react-hook-form";
import FormError from "../FormError";
import { Group } from "./styles";

type FormGroupContextType = {
  disabled: boolean;
  error?: FieldError;
  hasError: boolean;
  variant: "rounded" | "square";
};

export const FormGroupContext = createContext<FormGroupContextType>({
  disabled: false,
  error: undefined,
  hasError: false,
  variant: "rounded",
});

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  disabled?: boolean;
  error?: FieldError;
  variant?: "rounded" | "square";
};

export default function FormGroup({
  children,
  disabled = false,
  error,
  variant = "rounded",
  ...props
}: Props) {
  return (
    <FormGroupContext.Provider value={{ disabled, error, hasError: !!error, variant }}>
      <Group {...props}>
        {children}

        <FormError />
      </Group>
    </FormGroupContext.Provider>
  );
}
