import styled from "styled-components";

export const Img = styled.img`
  height: auto;
  width: 100%;
  margin-top: 1.5rem;
  transition: margin-top 200ms ease-in;

  @media (min-width: ${props => props.theme.ui.breakpoints.xs}) {
    margin-top: 1.75rem;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin-top: 1.5rem;
  }
`;
