import scrollToTop from "client/utils/scrollToTop";
import React, { useContext } from "react";
import { LostItemCreationContext } from "../../../../../../context";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import theme from "../../../../../../theme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import { StyledCheckSolidIcon, Wrapper } from "./styles";

export default function SubmittedConfirmation(): JSX.Element {
  const isMd = isMdQuery();
  const { submittedClaim } = useContext(LostItemCreationContext);

  scrollToTop();

  return (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <div className="text-center">
        <StyledCheckSolidIcon
          accessibilityTitle="Success checkmark"
          titleId="SuccessModalCheckmarkTitle"
        />
      </div>
      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-center">
        Your claim has been submitted!
      </Heading>
      <Text
        className="mb-25 mb-md-3 text-center"
        fontSize={theme.ui.fontSizes.base}
        color={theme.ui.colors.primary700}
      >
        Sit back, relax and let us do the searching for you.
      </Text>
      <Button
        fullWidth
        aria-label="goToClaimDetailsButton"
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        data-testid="goToClaimDetailsButton"
        href={`/claim/${submittedClaim?.id}`}
      >
        See claim details
      </Button>
      <Button
        fullWidth
        aria-label="goToClaimsButton"
        className="mt-3"
        data-testid="goToClaimsButton"
        variant="outline"
        href="/claims"
      >
        Go to all claims
      </Button>
    </Wrapper>
  );
}
