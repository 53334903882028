import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArtifactImage, ImagePreview } from "client/types";
import { compressImageAsync } from "client/utils/compressImage";
import { blobRegex } from "client/utils/regEx";
import { createClaimImage } from "./fetchFunctions/createClaimImage";
import queryFactory from "./queryFactory";

// Return a mutation that will issue create requests simultaneously
// and return any successfully created images. Throws an error
// if all create requests failed.
export default () => {
  const queryClient = useQueryClient();

  const createImages = ({
    claimId,
    images,
  }: {
    claimId: string;
    images: ImagePreview[];
  }): Promise<ArtifactImage[]> => {
    const createRequests: Promise<ArtifactImage | null>[] = images
      .map(async (image, index) => {
        if (blobRegex.test(image.preview)) {
          const result = await compressImageAsync(image);
          return createClaimImage({
            claim_id: claimId,
            image: result,
            index,
          });
        }
        return null;
      })
      .filter(v => v !== null);

    const successImages: ArtifactImage[] = [];

    return Promise.allSettled(createRequests)
      .then(results => {
        results.forEach(result => {
          if (result.status === "fulfilled" && result.value !== null) {
            successImages.push(result.value);
          }
        });
        return successImages;
      })
      .finally(() => {
        if (successImages.length === 0) {
          throw new Error("error creating all images");
        }
      });
  };

  const mutation = useMutation({
    mutationFn: createImages,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryFactory.claimImages(variables.claimId).queryKey,
      });
    },
  });

  return mutation;
};
