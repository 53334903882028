import React from "react";
import { Image } from "../../../typings/endpoints/image";
import { Close } from "../../assets/icons/icons";
import Loader from "../../components/Loader";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { StyledClose, StyledImage, StyledPositioningDiv, Thumbnail } from "./styles";

interface DropzoneThumbnailsProps {
  className?: string;
  deleteImage: (p: number, id: string) => void;
  canDeleteImages?: boolean;
  imagesLoading?: number;
  images: Image[];
  maxFilesUpload: number;
  tabIndex?: number;
}

const DropzoneThumbnails: React.FC<DropzoneThumbnailsProps> = ({
  deleteImage,
  canDeleteImages = true,
  images,
  imagesLoading,
  maxFilesUpload,
  className,
  tabIndex,
}) => {
  const imageCards: Array<React.ReactNode> = [];
  for (let i = 0; i < images.length + (imagesLoading || 0); i++) {
    imageCards.push(
      <>
        <Thumbnail
          key={i}
          className={`col-12 col-lg-4 position-relative px-0 ${
            i === 0 ? "ps-lg-0 pe-lg-225" : i === 2 ? "pe-lg-0 ps-lg-225" : "px-lg-225"
          }`}
        >
          <StyledPositioningDiv className="position-relative">
            <StyledImage style={{ backgroundImage: `url(${images[i]?.preview})` }}>
              <span role="img" aria-label={`Image ${1 + i} of ${images.length}`} />
              {i >= images.length && <Loader />}
            </StyledImage>
            {canDeleteImages && i < images.length && (
              <StyledClose className="hidden close-btn d-flex align-items-center justify-content-center">
                <Close
                  size="20"
                  onClick={() => {
                    deleteImage(i, images[i]?.id);
                  }}
                  onKeyDown={e => {
                    if (e.key === KEYBOARD_KEY.ENTER) {
                      deleteImage(i, images[i]?.id);
                    }
                  }}
                  tabIndex={tabIndex ?? 0}
                  accessibilityTitle="Remove photo"
                  titleId="RemovePhotoXButtonTitle"
                />
              </StyledClose>
            )}
          </StyledPositioningDiv>
        </Thumbnail>
        <div className="w-100 d-block d-lg-none" />
      </>,
    );
  }

  return (
    <div className={`col-12 row mx-0 px-0 justify-content-center order-5 px-0 mt-0 ${className}`}>
      {imageCards}
      {maxFilesUpload === images?.length && (
        <div className="py-4 text-center">3 of 3 photos added</div>
      )}
    </div>
  );
};

export default DropzoneThumbnails;
