import { fetchApiData } from "client/utils/fetchApiData";
import { ArtifactImage, CreateClaimImageRequest } from "../../../../types";
import { getCommonHeaders } from "../../../../utils/headers";

export const createClaimImage = (
  claimReq: CreateClaimImageRequest,
): Promise<ArtifactImage | null> => {
  const formData = new FormData();
  formData.append("image", claimReq.image, claimReq.image.name);
  formData.append("index", claimReq.index.toString());
  return fetchApiData({
    url: `/api/user/claims/${claimReq.claim_id}/images/`,
    options: {
      method: "POST",
      headers: getCommonHeaders(true, true),
      body: formData,
    },
  });
};
