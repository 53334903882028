import React, { useContext, useEffect, useReducer } from "react";

import { updatePartnerUserLoading } from "./actions";
import { PartnerUserReducer, PartnerUserReducerContext } from "./reducer";
import { initialPartnerUserState, PartnerUserContextLegacy, PartnerUserStateLegacy } from "./state";

import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCurrentPartner from "../../utils/useCurrentPartner";

export function usePartnerUserState(): PartnerUserStateLegacy {
  const context = useContext(PartnerUserContextLegacy);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a PartnerUser Provider");
  }

  return context;
}

// Auth if you need to modify the auth state
export function usePartnerUserDispatch() {
  const context = useContext(PartnerUserReducerContext);

  if (context === undefined) {
    throw new Error("usePartnerUserDispatch must be used within a PartnerUser Provider");
  }

  return context;
}

// add both read and write providers
export const PartnerUserContextProviderLegacy = (props: {
  children: React.ReactNode;
  initialState?: PartnerUserStateLegacy;
}) => {
  const { children, initialState } = props;
  const [state, dispatch] = useReducer(PartnerUserReducer, initialState ?? initialPartnerUserState);

  const partnerId = useCurrentPartner();

  const {
    partnerLocations,
    defaultPartnerLocation,
    isLoading: locationsLoading,
  } = usePartnerLocations(partnerId);
  const { setPartnerUserLocation } = usePartnerLocationStore();

  useEffect(() => {
    updatePartnerUserLoading(dispatch, locationsLoading);
  }, [locationsLoading]);

  useEffect(() => {
    if (partnerLocations?.length === 1 && defaultPartnerLocation) {
      setPartnerUserLocation(defaultPartnerLocation);
    }
  }, [partnerLocations, defaultPartnerLocation]);

  return (
    <PartnerUserContextLegacy.Provider value={state}>
      <PartnerUserReducerContext.Provider value={dispatch}>
        {children}
      </PartnerUserReducerContext.Provider>
    </PartnerUserContextLegacy.Provider>
  );
};
