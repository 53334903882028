import styled from "styled-components";
import theme from "../../theme";

export const Link = styled.a<{
  fontFamily: string;
  fontWeight: number;
}>`
  cursor: pointer;
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${theme.ui.colors.primary500};

  &:hover {
    color: ${theme.ui.colors.primary500};
  }

  &:focus-visible {
    outline-color: ${theme.ui.colors.primary500};
    padding: 6px;
  }
`;
