import styled from "styled-components";

export const RightPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  height: fit-content;
  padding: 28px 40px 64px 40px;

  @media (max-width: ${({ theme }) => theme.ui.breakpoints.md}) {
    padding: 28px 20px 0px 20px;
    margin-bottom: 64px;
  }

  @media (max-width: ${({ theme }) => theme.ui.breakpoints.xs}) {
    padding: 20px;
    margin-bottom: 64px;
  }

  @media print {
    padding: 0 !important;
    margin: 0;
    gap: 10px;
    contain: paint;
  }
`;

export const CardContainer = styled.div`
  border-radius: 10px;
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  overflow: hidden;
  background: #fff;

  & > * {
    overflow: hidden;
  }

  @media print {
    box-shadow: none;
    border: 1px solid ${props => props.theme.ui.colors.gray200};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  height: 78px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.ui.colors.gray200};
`;

export const TitleHeader = styled.h1`
  color: #101828;
  font-family: ${props => props.theme.ui.fontFamilies.heading};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const CardBody = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
`;

export const ContainerParagraph = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.ui.breakpoints.md}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.ui.colors.primary700};
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
`;

export const ContainerPhotos = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  & > * {
    width: 120px;
    height: 162px;
    border-radius: 16px;
    object-fit: cover;
  }
`;

export const DetailsLink = styled.a`
  color: ${props => props.theme.ui.colors.primary700};
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const TextDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const SpanTitle = styled.span`
  line-height: 24px;
`;

export const DescriptionContainer = styled.span`
  color: ${props => props.theme.ui.colors.primary700};
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const LocationFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
