import React from "react";

import { Col } from "react-bootstrap";
import Button from "../Button";
import { ButtonModes } from "../Button/Button.types";

import { BigChevronRight } from "../../assets/icons/icons";
import theme from "../../theme";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { Location, Partner, PartnerLocation } from "../../types/models";
import { Subheader, Subheader2 } from "../typography";
import { StyledLi } from "./styled";

// interface ListItem {
//   name: string;
//   id: string;
// }

interface ListProps {
  buttonText: string;
  onButtonClick: () => void;
  onSelection: (p: Location | PartnerLocation | Partner) => void;
  items?: any[]; //TODO ListItem is different for Location and Partners.
  subheading: string;
}

const List: React.FC<ListProps> = props => {
  const { buttonText, items, onButtonClick, onSelection, subheading } = props;
  return (
    <div data-testid="list" className="px-35 px-lg-0">
      <div className="row mx-0 align-items-center px-0 border-bottom py-3">
        <Col className="py-1 px-0 ps-lg-45 text-center">
          <Subheader data-testid="list-header" className="ps-0 text-lg-start">
            {subheading}
          </Subheader>
        </Col>
      </div>
      <ul data-testid="list-items" className="px-0 py-0 row mx-0 justify-content-center">
        {(items || [])?.map((item, index) => {
          return (
            <StyledLi
              key={index}
              data-testid="list-item"
              className={`row mx-0 px-0 px-lg-45 justify-content-around w-100 fw600 cursor-pointer`}
              tabIndex={0}
              onClick={() => {
                onSelection(item);
              }}
              onKeyPress={e => {
                if (e.key === KEYBOARD_KEY.ENTER) {
                  onSelection(item);
                }
              }}
            >
              <Col className="px-0 mx-0 row align-items-center">
                <Subheader2 data-testid="list-item-name">{item.name}</Subheader2>
              </Col>
              <Col className="px-0 d-flex align-items-center" xs="auto">
                <BigChevronRight
                  className="px-0"
                  color={theme.colors.gray3}
                  accessibilityTitle={`Select ${item.name}`}
                  titleId={`ListSelectionChevron${index}Title`}
                />
              </Col>
            </StyledLi>
          );
        })}
        <div
          data-testid="list-cancel-button"
          className="d-none d-lg-flex row mx-0 justify-content-center w-50"
        >
          <Button
            ariaLabel={buttonText}
            className="w-50 row mx-0 justify-content-center align-items-center"
            data-testid="list-action-btn"
            mode={ButtonModes.open}
            onClick={onButtonClick}
            text={buttonText}
          />
        </div>
      </ul>
    </div>
  );
};

export default List;
