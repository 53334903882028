import { Claim, UserReturn } from "../../types";

export interface UserState {
  currentReturn?: UserReturn;
  claimsReadyForPickup?: Claim[];
}

export const initialUserState: UserState = {
  currentReturn: undefined,
  claimsReadyForPickup: [],
};
