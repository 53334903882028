import React from "react";

import { Heading, SectionContainer, Text } from "../../../../ui-components";
import TwoColumnAsymmetricalLayout from "../TwoColumnAsymmetricalLayout";

import sectionOneImg from "../../../../assets/ui-icons/features-section-one.png";
import sectionThreeImg from "../../../../assets/ui-icons/features-section-three.png";
import sectionTwoImg from "../../../../assets/ui-icons/features-section-two.png";
import { featuresCopy } from "../../content";
import CopySection from "./CopySection";

import { colors, fontWeights } from "../../../../theme/uiTheme";
import { MediaWrapper } from "./styles";

const Features = () => {
  return (
    <SectionContainer className="mb-6 mb-lg-8 ">
      <div className="text-center">
        <Text
          color={colors.primary500}
          lineHeight={1.5}
          fontWeight={fontWeights.bold}
          textAlign="center"
        >
          {featuresCopy.title}
        </Text>
        <Heading className="mt-25 m-auto" style={{ maxWidth: "768px" }} as="h2" textAlign="center">
          {featuresCopy.heading}
        </Heading>
      </div>
      <div className="mt-6 mt-lg-8 pb-6 pb-lg-8">
        <TwoColumnAsymmetricalLayout
          mediaSectionRight
          copySection={
            <CopySection
              title={featuresCopy.firstSection.title}
              heading={featuresCopy.firstSection.heading}
              body={featuresCopy.firstSection.body}
              mediaSectionRight
            />
          }
          mediaSection={
            <MediaWrapper>
              <img
                loading="lazy"
                width={907}
                height={901}
                src={sectionOneImg}
                alt="screenshot of our add found item screen"
              />
            </MediaWrapper>
          }
        />
      </div>
      <div className="mt-6 mt-lg-8 pb-6 pb-lg-8">
        <TwoColumnAsymmetricalLayout
          copySection={
            <CopySection
              textAlignment="end"
              title={featuresCopy.secondSection.title}
              heading={featuresCopy.secondSection.heading}
              body={featuresCopy.secondSection.body}
            />
          }
          mediaSection={
            <MediaWrapper>
              <img
                loading="lazy"
                width={900}
                height={901}
                src={sectionTwoImg}
                alt="screenshot of our matching screen"
              />
            </MediaWrapper>
          }
        />
      </div>
      <div className="mt-6 mt-lg-8">
        <TwoColumnAsymmetricalLayout
          mediaSectionRight
          copySection={
            <CopySection
              title={featuresCopy.thirdSection.title}
              heading={featuresCopy.thirdSection.heading}
              body={featuresCopy.thirdSection.body}
              mediaSectionRight
            />
          }
          mediaSection={
            <MediaWrapper>
              <img
                loading="lazy"
                width={900}
                height={901}
                src={sectionThreeImg}
                alt="screenshot of one of our users getting a message from a item we found for them"
              />
            </MediaWrapper>
          }
        />
      </div>
    </SectionContainer>
  );
};
export default Features;
