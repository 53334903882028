import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import theme from "../../theme";

interface ThreePanelLayoutProps {
  TopPanel: React.ReactNode;
  LeftPanel: React.ReactNode;
  RightPanel: React.ReactNode;
  leftPanelWidth: string;
  headerHeight?: string;
}

const StyledMain = styled.main<{
  headerHeight: string;
}>`
  background-color: ${props => props.theme.colors.gray6};
`;

const LeftPanelContainer = styled.div<{
  leftPanelWidth: string;
}>`
  width: ${props => props.leftPanelWidth};
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
`;

const RightPanelContainer = styled.div<{
  leftPanelWidth: string;
}>`
  position: relative;
  padding: 0;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: calc(100% - ${props => props.leftPanelWidth});
  }
`;

const ThreePanelLayout = (props: ThreePanelLayoutProps) => {
  const { TopPanel, LeftPanel, RightPanel, leftPanelWidth, headerHeight } = props;

  return (
    <>
      <header className="mx-0 row">
        <Col xs={12} className="px-0">
          {TopPanel}
        </Col>
      </header>
      <StyledMain
        headerHeight={headerHeight ?? theme.heights.singleViewHeaderLg}
        className="row mx-0 flex-grow-1"
      >
        <LeftPanelContainer className="d-none d-lg-block" leftPanelWidth={leftPanelWidth}>
          {LeftPanel}
        </LeftPanelContainer>
        <RightPanelContainer leftPanelWidth={leftPanelWidth}>{RightPanel}</RightPanelContainer>
      </StyledMain>
    </>
  );
};

export default ThreePanelLayout;
