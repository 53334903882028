import ArrowLeft from "@assets/ui-icons/ArrowLeft";
import React, { useContext } from "react";
import { UserLoginContext } from "../../../../context";
import { isMdQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { Button, Heading, Text } from "../../../../ui-components";
import { Wrapper } from "../EmailView/styles";
import { CustomButton, HelperText } from "./styles";
import VerificationCodeInput from "./VerifictionCodeInput";

export default function CodeVerificationView() {
  const isMd = isMdQuery();
  const { email, setStep, resendCountdown, resentCode, isLoading, handleCodeResent } =
    useContext(UserLoginContext);

  return (
    <Wrapper className="d-flex flex-column align-content-md-center">
      <Text
        color={theme.ui.colors.primary500}
        fontSize={theme.ui.fontSizes.base}
        fontWeight={theme.ui.fontWeights.bold}
        className="mb-1 text-md-center"
      >
        Sign in
      </Text>

      <Heading variant="h2" as="h1" className="mb-25 mb-md-3 text-md-center">
        Check your email!
      </Heading>

      <Text fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-3">
        Enter the six digit code we sent to{" "}
        <Text as="span" fontWeight={700}>
          {email}
        </Text>{" "}
        to sign in.
      </Text>

      <VerificationCodeInput />

      <HelperText fontWeight={theme.ui.fontWeights.normal} className="text-md-center mb-25" as="p">
        <Text as="span">Haven’t received your code in 5 minutes? &nbsp;</Text>

        {resentCode ? (
          <Text color={theme.ui.colors.secondary500} fontWeight={700} as="span">
            Code resent!
          </Text>
        ) : (
          <span>
            <CustomButton
              aria-label="resend the code"
              disabled={resentCode || isLoading}
              className="p-0 my-0 border-0 h-auto text-primary500 lh-base"
              onClick={handleCodeResent}
              variant="text"
            >
              Resend the code.
            </CustomButton>
          </span>
        )}
      </HelperText>

      {resentCode && (
        <Text
          color={theme.ui.colors.gray600}
          fontSize="14px"
          className="text-md-center mb-25"
          as="p"
        >
          No luck? After {`${resendCountdown} second${resendCountdown > 1 ? "s" : ""}`}, you can try
          resending the code again.
        </Text>
      )}

      <Button
        aria-label="go back to sign-in form"
        className="mt-25 align-self-md-center"
        onClick={() => setStep("email")}
        disabled={isLoading}
        icon={<ArrowLeft accessibilityTitle="go-back" titleId="user-sign-in-go-back" />}
        maxWidth={isMd ? 280 : undefined}
        fullWidth
        loadingText="Loading..."
        size="2xl"
        type="submit"
        variant="outline"
      >
        Go back
      </Button>
    </Wrapper>
  );
}
