import { useQuery } from "@tanstack/react-query";
import useCategories from "client/hooks/data/user/useCategories";
import { Category, PartnerItemsCountByCategoryResponse } from "client/types";
import { FilterItem } from "client/ui-components/FilterDropdown/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useMemo } from "react";
import { OptionalCountQueryFilters } from ".";
import queryFactory from "../queryFactory";

export const defaultCategoryFilter: FilterItem<"ALL"> = {
  name: "Showing all",
  count: 0,
  value: "ALL",
} as const;

function formatCategoryFilters({
  categories,
  categoryCounts,
}: {
  categories: Category[];
  categoryCounts: PartnerItemsCountByCategoryResponse;
}): FilterItem[] {
  return [
    {
      ...defaultCategoryFilter,
      count: categoryCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...categories.map(({ id, name }) => {
      const category = categoryCounts.find(item => item.id === id);

      return {
        name,
        count: category?.count || 0,
        value: id,
      };
    }),
  ];
}

export const useCategoryCounts = (queryObject: OptionalCountQueryFilters) => {
  const partnerId = useCurrentPartner();
  const categories = useCategories();

  const { data: categoryCounts, refetch } = useQuery(
    queryFactory.itemCountByCategoryFiltered({
      partnerId,
      queryObject,
    }),
  );

  const formattedCategories = useMemo(() => {
    if (!categoryCounts || categories.data === undefined) return [];
    return formatCategoryFilters({
      categories: categories.data,
      categoryCounts,
    });
  }, [categoryCounts, categories]);

  return { categories: formattedCategories, refetch };
};

export default useCategoryCounts;
