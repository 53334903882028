import styled from "styled-components";
import theme from "../../../theme";

export const TextInputWrapper = styled.div`
  font-family: ${theme.ui.fontFamilies.default};
  font-size: ${theme.ui.fontSizes.base};
  line-height: 1.375;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  font-weight: ${theme.ui.fontWeights.normal};

  @media (min-width: ${theme.ui.breakpoints.md}) {
    width: 275px;
  }

  span {
    position: relative;
    bottom: -6px;
    font-size: ${theme.ui.fontSizes.xs};
    color: ${theme.ui.colors.error500};
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &.rounded {
      color: ${theme.ui.colors.primary700};
      border: 1px solid;
      border-color: ${theme.ui.colors.gray500};
      border-radius: ${theme.ui.borderRadius.rounded} !important;
      -webkit-border-radius: ${theme.ui.borderRadius.rounded} !important;
      -moz-border-radius: ${theme.ui.borderRadius.rounded} !important;
      box-shadow: ${theme.ui.shadows.input};
      width: 100%;

      ::placeholder {
        color: ${theme.ui.colors.gray600};
      }
      &:not(:disabled) {
        &:focus-visible {
          margin: 1px;
          outline: 2px solid ${theme.ui.colors.primary500};
          border: 1px;
          box-shadow:
            ${theme.ui.shadows.button},
            0px 0px 0px 5px rgba(3, 70, 245, 0.15);
          &.is-invalid {
            outline: 2px solid ${theme.ui.colors.error500};
            box-shadow:
              0px 1px 3px rgba(16, 24, 40, 0.05),
              0px 0px 0px 5px #fee4e2;
          }
        }

        &.is-invalid {
          border-color: ${theme.ui.colors.error500};
        }
      }

      &:disabled {
        color: ${theme.ui.colors.gray600};
        background: ${theme.ui.colors.gray300};
        cursor: not-allowed;
        &::placeholder {
          color: ${theme.ui.colors.gray600};
        }
      }
    }
  }
`;
