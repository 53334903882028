import styled from "styled-components";

export const Content = styled.div`
  text-align: left;

  @media (min-width: ${props => props.theme.ui.breakpoints.md}) {
    margin: 0 auto;
    max-width: 400px;
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
    width: 576px;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;
