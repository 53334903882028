import { UploadCloud } from "client/assets/ui-icons/UploadCloud";
import { isMdQuery } from "client/helpers/mediaQuery";
import useCategories from "client/hooks/data/user/useCategories";
import { Button, Heading, Text } from "client/ui-components";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import DropzoneThumbnailsNew from "../../../../../../components/DropzoneThumbnailsNew";
import { LostItemCreationContext } from "../../../../../../context";
import { colors, fontFamilies, fontWeights } from "../../../../../../theme/uiTheme";
import { convertFromHEIC } from "../../../../../../utils/imageConversion";
import { lostItemPhotosCopy } from "./content";
import { Content, StyledUpload, StyledUploadCloudContainer } from "./styles";

type PhotoUploadArgs = {
  reviewUpdate?: boolean;
};

export default function PhotosUpload({ reviewUpdate = false }: PhotoUploadArgs) {
  const isMd = isMdQuery();
  const categories = useCategories();
  const [imagesLoading, setImagesLoading] = useState(0);
  const { setStep, category, images, setImages } = useContext(LostItemCreationContext);

  const imagesExist = () => {
    return images.length > 0 && images.length < 3;
  };

  const onAddImages = async newImages => {
    setImagesLoading(newImages.length);
    for (let i = 0; i < newImages.length; i++) {
      newImages[i] = await convertFromHEIC(newImages[i]);
    }
    setImages([...images, ...newImages]);
    setImagesLoading(0);
  };

  const onDeleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".heic", ".heif"],
    },
    maxFiles: 3 - images.length,
    noDrag: imagesExist(),
    onDrop: acceptedFiles => {
      onAddImages(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  scrollToTop();

  useEffect(() => {
    return () => {
      images?.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, []);

  return (
    <Content>
      <div>
        <Text
          className="mb-1"
          color={colors.primary500}
          lineHeight={1.5}
          fontWeight={fontWeights.bold}
          textAlign={isMd ? "center" : "left"}
        >
          {lostItemPhotosCopy.subheading}
        </Text>
        <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
          {lostItemPhotosCopy.heading}
        </Heading>
        <div
          className="text-md-center"
          style={{ fontFamily: fontFamilies.default, lineHeight: "24px" }}
        >
          {lostItemPhotosCopy.supportingText1}{" "}
          <span style={{ color: colors.primary500 }}>{lostItemPhotosCopy.supportingText2}</span>
        </div>
      </div>
      {images.length === 0 && imagesLoading === 0 && (
        <StyledUpload
          {...getRootProps({
            className: "dropzone col",
          })}
        >
          <input aria-label="Upload photos" {...getInputProps()} data-testid="initialInput" />
          <div
            className="drag-area ps-3 d-flex justify-content-center align-items-center"
            data-testid="photoUpload"
          >
            <StyledUploadCloudContainer>
              <UploadCloud
                accessibilityTitle="Cloud with up arrow"
                titleId="upload-cloud"
                color={colors.primary500}
              />
            </StyledUploadCloudContainer>
            <div>
              <div className="addPhotos ps-1 pe-2 text-start">
                {isMd ? lostItemPhotosCopy.addPhotosText : lostItemPhotosCopy.addPhotosTextMobile}
              </div>{" "}
              <div className="optionalText">{lostItemPhotosCopy.optionalText}</div>
            </div>
          </div>
        </StyledUpload>
      )}
      <DropzoneThumbnailsNew
        deleteImage={onDeleteImage}
        images={images}
        imagesLoading={imagesLoading}
      />
      {imagesExist() && (
        <div {...getRootProps({ tabIndex: -1 })}>
          <input {...getInputProps()} data-testid="uploadMoreInput" />
          <Button
            aria-label="upload more button"
            fullWidth={true}
            size="2xl"
            variant="outline"
            style={{ color: colors.primary500 }}
            icon={
              <UploadCloud
                accessibilityTitle="Cloud with up arrow"
                titleId="upload-cloud"
                color={colors.primary500}
              />
            }
          >
            {lostItemPhotosCopy.uploadMoreButton}
          </Button>
        </div>
      )}
      <Button
        aria-label="next button"
        data-testid="nextButton"
        fullWidth={true}
        size="2xl"
        className="submit-button"
        type="submit"
        onClick={() => {
          setStep(reviewUpdate ? "review" : "description");
        }}
      >
        {reviewUpdate
          ? lostItemPhotosCopy.updateButton
          : images.length > 0
            ? lostItemPhotosCopy.nextButton
            : lostItemPhotosCopy.skipButton}
      </Button>
      <Button
        aria-label="back button"
        data-testid="backButton"
        fullWidth={true}
        size="lg"
        variant="outline"
        onClick={() => {
          setStep(
            reviewUpdate
              ? "review"
              : categories.data
                    ?.filter(({ icon }) => icon)
                    .map(({ id }) => id)
                    .includes(category[0])
                ? "category"
                : "additionalCategory",
          );
        }}
      >
        {reviewUpdate || (
          <ArrowLeft
            className="m-2"
            accessibilityTitle={lostItemPhotosCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
        )}
        <Text fontWeight={fontWeights.bold}>
          {reviewUpdate ? lostItemPhotosCopy.cancelButton : lostItemPhotosCopy.previousButton}
        </Text>
      </Button>
    </Content>
  );
}
