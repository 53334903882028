import { PartnerItemsCountByUnclaimedStatusResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import { formatFilters } from "../partnerItemCounts";

export default ({
  partnerId,
  queryObject,
}): Promise<PartnerItemsCountByUnclaimedStatusResponse> => {
  const query = formatFilters({
    ...queryObject,
  });

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/count-by-suggested-unclaimed-status/?${query}`,
  });
};
