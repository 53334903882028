import { ColumnDef } from "@tanstack/react-table";
import BlankMessage from "client/components/BlankMessage";
import Loader from "client/components/Loader";
import { isMdQuery } from "client/helpers/mediaQuery";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import { PartnerItem, UnclaimedStatus } from "client/types";
import DataTable from "client/ui-components/DataTable";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import emptyBoxImg from "../../../../../assets/empty_box.svg";
import { UnclaimedSuggestionStatus } from "../../helpers";
import EmptyState from "../EmptyState";
import ItemSnapshot from "../ItemSnapshot";
import { SuggestedStatus } from "../SuggestedStatus";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  items: PartnerItem[];
  count: number;
  status: UnclaimedSuggestionStatus;
  pageSize?: number;
  dataTestId?: string;
  hasFilters?: boolean;
  bulkActions?: React.FC<{
    selectedItems: PartnerItem[];
  }>[];
  bulkBottomActions?: React.FC<{
    selectedItems: PartnerItem[];
  }>[];
  isLoading?: boolean;
};

type styledColumnDef = (ColumnDef<PartnerItem> & {
  style?: React.CSSProperties;
})[];

const useItemTableColumns = () => {
  const isMd = isMdQuery();
  const dateFormatter = useDateFormatter();
  const columns: styledColumnDef = useMemo(() => {
    if (!isMd) {
      return [
        {
          id: "singleColumn",
          header: "",
          cell: ({ row: { original } }) => (
            <ItemSnapshot
              categoryName={original.category}
              images={original.images}
              snapshotKey={+original.id}
              key={original.id}
              name={original.name}
              label={original.inventoryId || ""}
              dateFound={dateFormatter(convertToDateIgnoringTime(original.collected_at), "long")}
              status={original.suggested_unclaimed_status as UnclaimedStatus}
            />
          ),
          style: {
            flexGrow: 1,
          },
        },
      ];
    }

    return [
      {
        id: "item",
        header: "Item name & category",
        cell: ({ row: { original } }) => (
          <ItemSnapshot
            categoryName={original.category}
            images={original.images}
            snapshotKey={+original.id}
            key={original.id}
            name={original.name}
            label={original.inventoryId ? `ID: ${original.inventoryId}` : ""}
            status={original.status}
            $showSingleImage
          />
        ),
        style: {
          width: "18%",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }) => (
          <div
            style={
              {
                lineClamp: 3,
                display: "-webkit-box",
                boxOrient: "vertical",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textAlign: "left",
              } as React.CSSProperties
            }
          >
            {row.getValue("description")}
          </div>
        ),
        style: {
          width: "39.83%",
        },
      },
      {
        accessorKey: "collected_at",
        header: "Date found",
        cell: ({ row }) => dateFormatter(new Date(row.getValue("collected_at")), "long"),
        style: {
          width: "15%",
        },
      },
      {
        accessorKey: "unclaimedStatus",
        header: () => <div style={{ textAlign: "right", paddingRight: 20 }}>Suggested status</div>,
        cell: ({ row }) => (
          <SuggestedStatus align="right" status={row.original.suggested_unclaimed_status} />
        ),
        style: {
          minWidth: "11.66%",
        },
      },
    ];
  }, [dateFormatter, isMd]);

  return columns;
};

export default function SuggestionTable({
  items,
  hasFilters,
  count,
  status,
  pageSize,
  bulkActions,
  bulkBottomActions,
  isLoading,
}: Props) {
  const history = useHistory();
  const columns = useItemTableColumns();
  const isMd = isMdQuery();

  if (isLoading) return <Loader />;

  if (count == 0 && hasFilters) {
    return (
      <BlankMessage alt="No items to show" image={String(emptyBoxImg)} title="No items">
        There are no suggested items with those filters for this location.
      </BlankMessage>
    );
  }
  if (!count) return <EmptyState status={status} />;

  return (
    <DataTable
      data={items}
      columns={columns}
      hasBulkOptions
      itemsCount={count}
      pageSize={pageSize}
      bulkActions={bulkActions}
      bulkBottomActions={bulkBottomActions}
      onRowClick={item => {
        history.push({
          pathname: `/partner/item/${item.id}`,
          state: {
            query: history.location.search,
          },
        });
      }}
      isList={!isMd}
    />
  );
}
