import styled from "styled-components";
import { BoomerangArrowLeft, SvgProps } from "../../../assets/icons/icons";
import { LegacyTextComponent } from "../../../components/typography";

export const EmailText = styled(LegacyTextComponent)`
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 1.714;
`;

export const StatPillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: column;
  }
`;

export const StyledBoomerangArrowLeft = styled(BoomerangArrowLeft)<SvgProps>`
  padding: 2px;
  &.small {
    padding: 5px;
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.portalMax};
  display: flex;
  flex-direction: column;
  margin: 0 max(16px, calc(50% - ${props => props.theme.widths.portalMax} / 2));
`;
