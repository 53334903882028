import { Col } from "react-bootstrap";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import { Title } from "../typography";

export const HeaderTextWrapper = styled.div<{ maxWidth?: string }>`
  max-width: ${props => props.maxWidth || props.theme.widths.claimMax};
  min-height: 126.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 75%;
    margin: 0 auto;
    flex-direction: row;
  }
`;

export const StyledCol = styled(Col)`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.button};

  &.cursor-pointer {
    cursor: pointer;
  }
`;

export const StyledSubHeader = styled.div`
  color: ${props => props.theme.colors.gray6};
  line-height: 1.2;
  svg {
    color: ${props => props.theme.colors.gray4};
  }
`;
export const StyledImg = styled.img`
  width: ${props => props.theme.widths.iconLogo};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: ${props => props.theme.widths.logoMain};
  }
`;

export const StyledTitle = styled(Title)`
  color: ${props => props.theme.colors.white};
  line-height: 1.2;
`;

export const TopBackgroundAccent = styled.div<{ smHeight?: string }>`
  background-color: ${props => props.theme.ui.colors.primary700};
  height: ${props => props.smHeight || props.theme.heights.mobileDefaultHeader};
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: ${props => props.theme.heights.desktopHeader};
  }
`;
