import theme from "client/theme";
import React from "react";
import {
  CircleCheckIcon,
  ErrorCircleIcon,
  InfoCircleIcon,
  WarningCircleIcon,
} from "../../assets/icons/icons";
interface Props {
  type: "success" | "warning" | "error" | "info";
}

export function ToastIcon({ type }: Props) {
  if (type === "success")
    return (
      <CircleCheckIcon
        accessibilityTitle="success icon"
        titleId="successIcon"
        color={theme.ui.colors.success600}
      />
    );

  if (type === "error")
    return (
      <ErrorCircleIcon
        accessibilityTitle="error icon"
        titleId="errorIcon"
        color={theme.ui.colors.error600}
      />
    );
  if (type === "warning")
    return (
      <WarningCircleIcon
        accessibilityTitle="warning icon"
        titleId="warningIcon"
        color={theme.ui.colors.yellow600}
      />
    );

  return (
    <InfoCircleIcon
      accessibilityTitle="info icon"
      titleId="infoIcon"
      color={theme.ui.colors.primary600}
    />
  );
}
