import { useDateFormatter } from "client/hooks/useDateFormatter";
import React, { Dispatch, SetStateAction } from "react";
import { Carousel } from "react-bootstrap";
import Badge, { BadgeSizesEnum, BadgeVariantsEnum } from "../../../../components/Badge";
import MatchedClaimSnapshot from "../../../../components/MatchedClaimSnapshot";
import {
  StyledActionFooter,
  StyledContactDetailsSnapshot,
  StyledImage,
  StyledNoPhotoImagePlaceholder,
  StyledRightPanel,
} from "../../../../components/singleViewStyles";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { Category, PartnerClaim, PartnerItem, PartnerReturn } from "../../../../types";
import { getCategoryName } from "../../../../utils/mappers";
import SingleReturnButtons from "./SingleReturnButtons";

import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { NoPhotoImagePlaceholder, StyledCarousel } from "../../../../components/ImageCarousel";
import TextLabelValue from "../../../../components/TextLabelValue/TextLabelValue";
import { ResultType } from "../../../../pages/PartnerGlobalSearch";
import { ReturnObject } from "../../../../pages/PartnerGlobalSearch/components/ResultsSection";
import { PartnerShipment } from "../../../../types/actions";
import { ReturnStatus } from "../../../../types/models";
import StatusBadge from "../../../../ui-components/StatusBadge";

interface SingleReturnRightPanelProps {
  itemData: PartnerItem;
  claimData: PartnerClaim;
  returnData: PartnerReturn;
  categories: Category[];
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  setShowSwitchModal: Dispatch<SetStateAction<boolean>>;
  shipmentData?: PartnerShipment | null;
}

const SingleReturnRightPanel: React.FC<SingleReturnRightPanelProps> = props => {
  const {
    claimData,
    itemData,
    returnData,
    categories,
    setShowConfirmationModal,
    setShowSwitchModal,
    shipmentData,
  } = props;
  const isLg = isLgQuery();
  const { partnerLocations } = usePartnerLocations(useCurrentPartner());
  const dateFormatter = useDateFormatter();

  const itemLocation = partnerLocations?.find(location => location.id === itemData?.lost_location);

  const returnObject: ReturnObject = {
    ...returnData,
    shipment: shipmentData!,
  };

  return (
    <StyledRightPanel
      className={`p-35 p-lg-45 my-lg-35 mx-lg-45 bg-white pb-5 ${isLg ? "rounded-3" : ""}`}
    >
      {/*Only shows on mobile*/}
      <StyledCarousel
        className={`d-block d-md-none mt-n2 mb-45 ${itemData.images.length === 0 ? "d-none" : ""}`}
        $useImg
        indicators={itemData.images.length > 1}
        touch
        interval={null}
      >
        {(itemData.images || [])?.map((file, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={file.image} alt={index.toString() + " image"} />
          </Carousel.Item>
        ))}
      </StyledCarousel>
      <div className="row mx-0 mb-25 d-none d-md-flex d-lg-none">
        {" "}
        {/*Only shows on tablet*/}
        {itemData.images.length !== 0 &&
          (itemData.images || [])?.map((file, index) => (
            <div
              key={index}
              className={`col-4 ${index === 0 && "ps-0 pe-2"} ${
                index === 1 && "px-2"
              } ${index === 2 && "ps-2 pe-0"}`}
            >
              <StyledImage className="border-0" style={{ backgroundImage: `url(${file?.image})` }}>
                <span role="img" aria-label={`image ${1 + index} of ${itemData.images.length}`} />
              </StyledImage>
            </div>
          ))}
        {itemData.images.length !== 0 && !itemData.images[1] && (
          <div className="col-4 px-2">
            <StyledNoPhotoImagePlaceholder width="100%">
              <span role="role" aria-label="no photo placeholder image" />
            </StyledNoPhotoImagePlaceholder>
          </div>
        )}
        {itemData.images.length !== 0 && !itemData.images[2] && (
          <div className="col-4 ps-2 pe-0">
            <StyledNoPhotoImagePlaceholder width="100%">
              <span role="role" aria-label="no photo placeholder image" />
            </StyledNoPhotoImagePlaceholder>
          </div>
        )}
      </div>
      {itemData.images.length === 0 && (
        <NoPhotoImagePlaceholder
          square
          className={`rounded-1 my-35 mx-auto mx-md-0 ${
            itemData.images.length === 0 && "d-lg-none"
          }`}
        >
          <span role="img" aria-label="no photo placeholder image" />
        </NoPhotoImagePlaceholder>
      )}

      <div className="row mx-0">
        <TextLabelValue
          className="px-0 mt-n2 mt-md-0 pb-45"
          largeValueFontSize
          label={isLg ? "Item name" : undefined}
          value={itemData.name}
          data-testid="itemName"
        />
        <TextLabelValue
          className="px-0 pb-45"
          label="Status"
          value={
            returnData && (
              <StatusBadge
                objectType={ResultType.RETURNS}
                objectStatus={returnData.status}
                returnObject={returnObject}
                showReturnsIcon
              />
            )
          }
          valueDisplayFlex
          data-testid="returnStatus"
        />

        <TextLabelValue
          className="px-0 pb-45"
          label="Matched claim"
          value={<MatchedClaimSnapshot claim={claimData} categories={categories} />}
          data-testid="itemSnapshot"
        />

        {claimData.user && (
          <StyledContactDetailsSnapshot
            className="pb-45 col-12 col-md-6 col-lg-12"
            header
            headerText="Contact details"
            name={claimData.user.full_name}
            email={claimData.user.email}
            phoneNumber={claimData.user.phone_number ?? ""}
            hasEmailLink
          />
        )}

        <TextLabelValue
          className="px-0 pb-45"
          label="ID"
          value={<div className="fw500">{itemData.inventoryId}</div>}
          data-testid="itemInventoryId"
        />
        <TextLabelValue
          className="px-0 pb-45"
          label="Item description"
          value={<div className="fw500">{itemData.description}</div>}
          data-testid="itemDescription"
        />
        <TextLabelValue
          className="px-0 pb-45"
          label="Category"
          value={
            categories.length > 0 &&
            itemData.category && (
              <Badge
                variant={BadgeVariantsEnum.Contained}
                className="fw500"
                size={BadgeSizesEnum.Small}
                label={getCategoryName(categories, itemData.category)}
              />
            )
          }
          data-testid="itemCategory"
        />
        <TextLabelValue
          className="px-0 col-12 col-md-6 col-lg-12 lb-45"
          label={`Date found`}
          value={
            itemData.collected_at &&
            dateFormatter(convertToDateIgnoringTime(itemData.collected_at), "long")
          }
          data-testid="itemDateFound"
        />
        <TextLabelValue
          className="px-0 col-12 col-md-6 col-lg-12 pb-45"
          label={`Location found`}
          value={itemLocation?.name}
          data-testid="itemLocationFound"
        />

        {itemData.created_by && (
          <StyledContactDetailsSnapshot
            className="pb-0 col-12 col-md-6 col-lg-12"
            header
            headerText={"Logged by"}
            name={itemData.created_by.full_name}
            email={itemData.created_by.email}
            phoneNumber={itemData.created_by.phone_number ?? ""}
          />
        )}
      </div>
      {returnData.status !== ReturnStatus.COMPLETED && (
        <StyledActionFooter className="p-35 gap-2 d-flex d-lg-none">
          <SingleReturnButtons
            setShowConfirmationModal={setShowConfirmationModal}
            returnData={returnData}
            shipmentData={shipmentData}
            setShowSwitchModal={setShowSwitchModal}
          />
        </StyledActionFooter>
      )}
    </StyledRightPanel>
  );
};

export default SingleReturnRightPanel;
