import theme from "client/theme";
import Typography from "client/ui-components/Typography";
import React from "react";
import Modal from "../../../../components/Modal";
import SuccessModalInterior from "../../../../components/SuccessModalInterior";
import history from "../../../../context/history";

interface SuccessModalProps {
  image?: string;
  isLg: boolean;
  closeModal: () => void;
  showModal: boolean;
}

const SuccessModal: React.FC<SuccessModalProps> = props => {
  const { closeModal, showModal } = props;
  return (
    <Modal
      topBtnText={"Go back to inventory"}
      topBtnOnClick={() => {
        history.push("/partner/inventory");
      }}
      children={
        <SuccessModalInterior
          header="Item returned!"
          description={
            <Typography
              className="text-center"
              data-testid="successModalDescription"
              as="p"
              fontSize={theme.ui.fontSizes.base}
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.normal}
              lineHeight={1.75}
            >
              You've successfully returned an item!
            </Typography>
          }
        />
      }
      onXButton={closeModal}
      show={showModal}
      showXButton
    />
  );
};

export default SuccessModal;
