import styled from "styled-components";
import { CheckSolidIcon } from "../../../../../../assets/icons/icons";

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  transition:
    max-width,
    padding-bottom 0.3s ease-in-out;
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;

export const StyledCheckSolidIcon = styled(CheckSolidIcon)`
  width: 38px;
  height: 38px;
  color: ${props => props.theme.ui.colors.success500};
  margin-bottom: 20px;
  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    width: 57px;
    height: 57px;
  }
`;
