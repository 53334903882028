import { UserAction, UserActionType } from "./actions";
import { UserState } from "./state";

// ADDING IN ANY TYPE BELOW UNTIL WE MAKE A SEPARATE USERCLAIMS CONTEXT
const userReducer = (state: UserState, action: UserAction | any): UserState => {
  switch (action.type) {
    case UserActionType.UPDATE_CURRENT_RETURN:
      return {
        ...state,
        currentReturn: action.payload.currentReturn,
      };

    default:
      return state;
  }
};

export default userReducer;
