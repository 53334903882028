import { CircleCheck } from "@assets/icons/icons";
import { ArrowNarrowLeft } from "@assets/ui-icons";
import { ClaimDetailsContext } from "client/context/ClaimDetails";
import theme from "client/theme";
import { colors } from "client/theme/uiTheme";
import React, { useContext } from "react";
import history from "../../../context/history";
import { Button, Container, Heading, Text } from "../../../ui-components";
import Pickup from "../ClaimDetails/UserReturnProcess/Pickup";
import {
  CardContainer,
  CardContent,
  GoBackButton,
  HeaderWrapper,
  PageWrapper,
  Wrapper,
} from "./styles";

export default function PickupConfirmed(): JSX.Element {
  const { claim } = useContext(ClaimDetailsContext);

  return (
    <PageWrapper>
      <HeaderWrapper>
        <GoBackButton
          className="btn btn-link text-white px-0"
          onClick={() => history.push(`/claim/${claim?.id}`)}
        >
          <ArrowNarrowLeft
            accessibilityTitle="go-back"
            titleId="go-back"
            className="flex-shrink-0"
            color="black"
            data-testid="goBackArrow"
          />
        </GoBackButton>
      </HeaderWrapper>
      <Container className="h-100">
        <Wrapper>
          <CircleCheck
            color={colors.white}
            className="mx-auto mb-3"
            backgroundColor={colors.success500}
            height={56}
            width={56}
            accessibilityTitle="Circle Check Icon"
            titleId="circleCheckIcon"
          />
          <Heading variant="h2" as="h1" className="mb-md-3 text-center">
            Pickup confirmed
          </Heading>
          <Text fontWeight={theme.ui.fontWeights.normal} className="text-center mb-4">
            You've successfully changed your recovery method.
          </Text>
          <CardContainer>
            <CardContent>
              <Pickup />
            </CardContent>
          </CardContainer>
          <div className="d-flex flex-column flex-grow-1 justify-content-end pt-275">
            <Button
              href={`/claim/${claim?.id}`}
              aria-label="Pick up instead"
              data-testid="pickUpInstead"
              size="2xl"
            >
              Go back to my claim
            </Button>
          </div>
        </Wrapper>
      </Container>
    </PageWrapper>
  );
}
