import styled from "styled-components";
import BoomerangSignEnd from "./boomerang-sign-end.svg";
import BoomerangSignStart from "./boomerang-sign-start.svg";

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition:
    padding,
    background-size 200ms ease-in;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    background-size:
      120px 102px,
      105px 102px;
    background-image: url(${BoomerangSignStart}), url(${BoomerangSignEnd});
    background-position:
      left bottom,
      right top;
    background-repeat: no-repeat, no-repeat;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: relative;
  padding: 40px 20px 0;

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.md}) {
    padding: 64px 40px 0;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    padding: 94px 40px;
  }

  @media screen and (min-width: ${props => props.theme.ui.breakpoints.xxl}) {
    padding: 96px 60px;
  }
`;
