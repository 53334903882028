import styled from "styled-components";
import Link from "../../../../../ui-components/Link";

export const ForgotPasswordLink = styled(Link)`
  font-size: ${({ theme }) => theme.ui.fontSizes.sm};
  line-height: 1.25;
  margin-top: 0.125rem;
  margin-bottom: 1.5rem;

  span {
    font-weight: 400;
  }
`;
