import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface PartnerLocationFragment {
  id: string;
  name: string;
}

export const AllPartnerLocations = {
  id: "",
  name: "",
};

interface PartnerLocationState {
  partnerUserLocation: PartnerLocationFragment;
  setPartnerUserLocation: (_: PartnerLocationFragment) => void;
  resetPartnerUserLocation: () => void;
  globalSearchLocation: PartnerLocationFragment;
  setGlobalSearchLocation: (_: PartnerLocationFragment) => void;
}

export default create<PartnerLocationState>()(
  devtools(
    set => ({
      partnerUserLocation: AllPartnerLocations,
      setPartnerUserLocation: currentLocation => {
        set({ partnerUserLocation: currentLocation });
      },
      resetPartnerUserLocation: () =>
        set({
          partnerUserLocation: AllPartnerLocations,
          globalSearchLocation: AllPartnerLocations,
        }),
      globalSearchLocation: AllPartnerLocations,
      setGlobalSearchLocation: globalSearchLocation =>
        set({ globalSearchLocation: globalSearchLocation }),
    }),
    { enabled: process.env["BOOMERANG_FE_ENV"] === "local" },
  ),
);
