import styled from "styled-components";

export const MenuWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dce1e8;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 360px;
  overflow-y: auto;
`;

export const Wrapper = styled.div<{
  searchMenuOpen?: boolean;
}>`
  max-width: 400px;
  margin: 0 auto;
  padding-top: 0;
  transition:
    max-width,
    padding-top 0.3s ease-in-out;

  @media (min-width: ${props => props.theme.ui.breakpoints.sm}) {
    padding-top: ${props => (props.searchMenuOpen ? "0" : "100px")};
  }

  @media (min-width: ${props => props.theme.ui.breakpoints.lg}) {
    max-width: 576px;
  }
`;
