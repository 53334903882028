import { EmailIcon, UserIcon } from "@assets/icons/icons";
import { useContactDetailsForm } from "client/components/ContactDetailsForm";
import privacyPolicy from "client/documents/privacyPolicy.pdf";
import Button from "client/ui-components/Button";
import Link from "client/ui-components/Link";
import Text from "client/ui-components/Text";
import getCountryDefaultPhonenumber from "client/utils/getCountryDefaultPhonenumber";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import StyledPhoneInputV2 from "../../../../../../components/StyledPhoneInputV2";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import Form from "../../../../../../ui-components/Form";
import Heading from "../../../../../../ui-components/Heading";
import { LostItemCreationContext } from "./../../../../../../context";
import { contactDetailsCopy } from "./content";
import { Content, PrivacyPolicyContainer } from "./styles";

type ContactDetailsArgs = {
  reviewUpdate?: boolean;
};

export default function ContactDetailsNew({ reviewUpdate = false }: ContactDetailsArgs) {
  const isMd = isMdQuery();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    setStep,
    partnerLocation,
  } = useContext(LostItemCreationContext);

  const country = partnerLocation?.address.country_code ?? "US";

  const defaultPhoneNumber = getCountryDefaultPhonenumber(country);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    trigger,
    control,
  } = useContactDetailsForm();

  scrollToTop();

  useEffect(() => {
    if (firstName !== "" || email !== "" || phoneNumber !== "" || lastName !== "") {
      setValue("firstName", firstName);
      setValue("email", email);
      setValue("phoneNumber", phoneNumber);
      setValue("lastName", lastName);
    }
  }, [firstName, lastName, email, phoneNumber]);

  const onSubmit = data => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setStep("review");
  };

  return (
    <Content>
      <Text
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {contactDetailsCopy.subheading}
      </Text>
      <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
        {contactDetailsCopy.heading}
      </Heading>
      <div className="text-md-center">
        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
        >
          {contactDetailsCopy.supportingText}
        </Text>
      </div>
      <Form
        className="justify-content-center mt-3"
        data-testid="contactForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign="left"
        >
          {contactDetailsCopy.inputFirstNameLabel}
        </Text>
        <Form.Group
          disabled={isSubmitting}
          error={errors.firstName}
          className="mb-3"
          data-testid="firstNameInputContainer"
        >
          <Form.Control
            {...register("firstName", {
              required: contactDetailsCopy.inputEmptyMessage,
            })}
            autoFocus={isMd}
            autoComplete="off"
            fullWidth
            type="text"
            id="first-name"
            data-testid="firstNameInput"
            placeholder={contactDetailsCopy.inputFirstNamePlaceholder}
            onBlur={() => trigger("firstName")}
            icon={{
              position: "start",
              element: (
                <UserIcon
                  accessibilityTitle="First name"
                  ariaHidden={true}
                  titleId="FirstNameClaimCreationIconTitle"
                />
              ),
            }}
          />
        </Form.Group>

        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign="left"
        >
          {contactDetailsCopy.inputLastNameLabel}
        </Text>
        <Form.Group
          disabled={isSubmitting}
          error={errors.lastName}
          className="mb-3"
          data-testid="lastNameInputContainer"
        >
          <Form.Control
            {...register("lastName", {
              required: contactDetailsCopy.inputEmptyMessage,
            })}
            autoComplete="off"
            fullWidth
            type="text"
            id="last-name"
            data-testid="lastNameInput"
            placeholder={contactDetailsCopy.inputLastNamePlaceholder}
            onBlur={() => trigger("lastName")}
            icon={{
              position: "start",
              element: (
                <UserIcon
                  accessibilityTitle="Last name"
                  ariaHidden={true}
                  titleId="LastNameClaimCreationIconTitle"
                />
              ),
            }}
          />
        </Form.Group>

        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign="left"
        >
          {contactDetailsCopy.inputEmailLabel}
        </Text>
        <Form.Group
          disabled={isSubmitting}
          error={errors.email}
          className="mb-3"
          data-testid="emailInputContainer"
        >
          <Form.Control
            {...register("email", {
              required: contactDetailsCopy.inputEmptyMessage,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: contactDetailsCopy.inputEmailErrorMessage,
              },
            })}
            autoComplete="off"
            fullWidth
            type="text"
            id="email"
            data-testid="emailInput"
            placeholder={contactDetailsCopy.inputEmailPlaceholder}
            onBlur={() => trigger("email")}
            icon={{
              position: "start",
              element: (
                <EmailIcon
                  accessibilityTitle="Email"
                  ariaHidden={true}
                  titleId="EmailClaimCreationIconTitle"
                />
              ),
            }}
          />
        </Form.Group>

        <Text
          as="span"
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign="left"
        >
          {contactDetailsCopy.inputPhoneLabel}
        </Text>
        <Form.Group
          disabled={isSubmitting}
          error={errors.phoneNumber}
          className="mb-3"
          data-testid="phoneInputContainer"
        >
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledPhoneInputV2
                value={value}
                onChange={onChange}
                onBlur={() => trigger("phoneNumber")}
                ariaLabel="Mobile number"
                label="Mobile number"
                isValidated={errors.phoneNumber}
                defaultCountry={country}
                className={errors?.phoneNumber?.types ? "is-invalid" : "is-valid"}
                placeholder={defaultPhoneNumber}
              />
            )}
            rules={{
              required: contactDetailsCopy.inputEmptyMessage,
              validate: value =>
                isValidPhoneNumber(String(value)) || contactDetailsCopy.inputPhoneErrorMessage,
            }}
          />
        </Form.Group>
        <Button
          aria-label="next button"
          fullWidth={true}
          size={isMd ? "xl" : "2xl"}
          className="submit-button"
          type="submit"
        >
          {reviewUpdate ? contactDetailsCopy.nextButtonUpdate : contactDetailsCopy.nextButton}
        </Button>
      </Form>
      <Button
        aria-label="back button"
        data-testid="backButton"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        variant="outline"
        onClick={() => (reviewUpdate ? setStep("review") : setStep("description"))}
      >
        {!reviewUpdate && (
          <ArrowLeft
            className="m-2"
            accessibilityTitle={contactDetailsCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
        )}
        <Text fontWeight={fontWeights.bold}>
          {reviewUpdate
            ? contactDetailsCopy.previousButtonUpdate
            : contactDetailsCopy.previousButton}
        </Text>
      </Button>
      <PrivacyPolicyContainer>
        <Text
          as="span"
          fontSize={{ base: fontSizes.sm }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
          color={colors.gray600}
        >
          By providing your information, you consent to the use of your information in accordance
          with our{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href={privacyPolicy}
            onMouseDown={e => e.preventDefault()} // This prevents onBlur from triggering on the form elements
          >
            Privacy Policy
          </Link>
          . We take your privacy seriously and will only process your information for its intended
          purposes.
        </Text>
      </PrivacyPolicyContainer>
    </Content>
  );
}
