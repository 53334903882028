import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNewUpdateItem } from "client/actions/partnerItems";
import BlankMessage from "client/components/BlankMessage";
import Loader from "client/components/Loader";
import { isMdQuery } from "client/helpers/mediaQuery";
import queryFactory from "client/hooks/data/partner/queryFactory";
import useCategories from "client/hooks/data/user/useCategories";
import { PartnerItem, UnclaimedStatus } from "client/types";
import { Button, DataTable } from "client/ui-components";
import { If } from "client/utils/If";
import { notNull } from "client/utils/NotNull";
import useCurrentPartner from "client/utils/useCurrentPartner";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import emptyBoxImg from "../../../assets/empty_box.svg";
import { EditToolbar } from "./EditToolbar";
import { useUnclaimedInventoryColumns } from "./use-unclaimed-inventory-columns";
import {
  ITEMS_PER_PAGE,
  useUnclaimedPartnerItemsFilter,
} from "./use-unclaimed-partner-items-filter";

export function UnclaimedItemsTable() {
  const isMd = isMdQuery();
  const [count, setCount] = useState(0);
  const partnerId = useCurrentPartner();
  const { data: categories } = useCategories();
  const [updatedItems, setUpdatedItems] = useState<Record<string, PartnerItem>>({});
  const { mutateAsync: updatePartnerItem, isPending: isUpdating } = useNewUpdateItem();
  const filters = useUnclaimedPartnerItemsFilter({ count });
  const { data, isLoading } = useQuery(queryFactory.itemsFiltered({ partnerId, query: filters }));
  const queryClient = useQueryClient();
  const onItemChange = useCallback(updated => {
    setUpdatedItems(items => ({
      ...items,
      [updated.id]: { ...(items[updated.id] ?? {}), ...updated },
    }));
  }, []);
  const columns = useUnclaimedInventoryColumns({ isMd, categories, onItemChange });

  const partnerItemsWithEdits = useMemo(
    () =>
      data?.results?.map(result => {
        const { unclaimed_status = UnclaimedStatus.NULL, unclaimed_status_set_at = null } =
          updatedItems[result.id] ?? {};
        return {
          ...result,
          unclaimed_status,
          unclaimed_status_set_at,
        };
      }) ?? [],
    [data?.results, updatedItems],
  );

  const validEdits = Object.keys(updatedItems)
    .map(key => {
      const { unclaimed_status, unclaimed_status_set_at } = updatedItems[key] ?? {};
      const item = data?.results.find(item => item.id === key);

      if (
        !!unclaimed_status &&
        !!unclaimed_status_set_at &&
        item &&
        unclaimed_status !== UnclaimedStatus.NULL &&
        item.unclaimed_status !== unclaimed_status &&
        item.unclaimed_status_set_at !== unclaimed_status_set_at
      ) {
        return updatedItems[key];
      }
      return null;
    })
    .filter(notNull);

  const onApplyEdits = useCallback(async () => {
    // TODO: Ideally this should be done through a bulk update endpoint.
    const promises = validEdits.map(item =>
      updatePartnerItem({
        item: {
          name: item.name,
          category: item.category,
          unclaimed_status: item.unclaimed_status,
          unclaimed_status_set_at: item.unclaimed_status_set_at,
        },
        partner_id: partnerId,
        item_id: item.id,
      }).catch(() => null),
    );
    const results = await Promise.all(promises);
    const successCount = results.filter(notNull).length;
    const failureCount = results.length - successCount;

    toast.success(
      `${successCount} ${pluralize("item", successCount)} ${successCount > 1 ? "have" : "has"} been successfully archived.`,
    );

    if (failureCount > 0) {
      toast.error(`Failed to archive ${failureCount} ${pluralize("item", failureCount)}.`);
    }

    setUpdatedItems(items => {
      // filter out ids that got successfully updated
      const updatedIds = results.map(item => item?.id).filter(notNull);
      return Object.keys(items)
        .filter(key => !updatedIds.includes(key))
        .reduce((p, n) => ({ ...p, [n]: items[n] }), {});
    });
    queryClient.invalidateQueries({ queryKey: queryFactory.items(partnerId), refetchType: "all" });
  }, [validEdits, queryClient, partnerId, updatePartnerItem]);

  useEffect(() => {
    if (!data?.count) return;
    setCount(data?.count);
  }, [data?.count]);

  if (isLoading) return <Loader />;

  if (!data?.results || data?.results.length == 0) {
    return (
      <BlankMessage
        alt="Boomerang empty box"
        image={String(emptyBoxImg)}
        title={"No items without any suggestion"}
      >
        There are no items to show in given date range or category.
      </BlankMessage>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      <EditToolbar
        editedItemCount={validEdits.length}
        onApplyEdits={onApplyEdits}
        loading={isUpdating}
      />
      <DataTable
        data={partnerItemsWithEdits}
        columns={columns}
        itemsCount={data?.count}
        pageSize={ITEMS_PER_PAGE}
        isList={!isMd}
      />
      <If condition={validEdits.length > 0}>
        <div className="d-flex justify-content-end">
          <Button
            aria-label="Apply updates"
            onClick={onApplyEdits}
            loading={isUpdating}
            style={{ fontSize: 14 }}
          >
            {`Apply updates to ${validEdits.length} ${pluralize("item", validEdits.length)}`}
          </Button>
        </div>
      </If>
    </div>
  );
}
