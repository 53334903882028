import React, { useEffect, useRef, useState } from "react";

import { DownChevronSolid } from "../../assets/icons/icons";
import { DropdownMenuWrapper, DropdownWrapper, ListItem, StyledSelect } from "./styles";

import {
  AllPartnerLocations,
  PartnerLocationFragment,
} from "client/hooks/data/partner/usePartnerLocationStore";
import { isLgQuery } from "../../helpers/mediaQuery";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { PartnerLocation } from "../../types/models";
import { DropdownAlignment } from "./types";

interface DropdownProps {
  className?: string;
  currentPartnerLocation: PartnerLocationFragment;
  onLocationSelection: (_: PartnerLocationFragment) => void;
  partnerLocations: PartnerLocation[];
  isSearchModeOn?: boolean;
}

const PartnerLocationDropdown = (props: DropdownProps) => {
  const {
    className,
    currentPartnerLocation,
    onLocationSelection,
    partnerLocations,
    isSearchModeOn,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<any>();

  const onePartnerLocation = partnerLocations.length === 1;

  const isLg = isLgQuery();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleDropdownClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, [onePartnerLocation]);

  const dropdownTabIndex = onePartnerLocation ? undefined : 0;
  const dropdownOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onePartnerLocation) {
      return;
    }

    if (e.key === KEYBOARD_KEY.ENTER) {
      handleDropdownClick(e);
    }
  };

  const handleDropdownClick = e => {
    if (e.target.classList.contains("location-option")) {
      return;
    }
    if (e.target.classList.contains("toggler") && e.target.classList.contains("open")) {
      setDropdownOpen(false);
      return;
    } else if (
      (e.target.classList.contains("toggler") || dropdownRef?.current?.contains(e.target)) &&
      !onePartnerLocation
    ) {
      setDropdownOpen(true);
      return;
    }
    // outside click
    setDropdownOpen(false);
  };

  return (
    <DropdownWrapper
      className={`${className || ""} ${!isLg ? "mobile" : ""} dropdown`}
      ref={dropdownRef}
      isSearchModeOn={isSearchModeOn}
    >
      <StyledSelect
        className={`toggler ${!onePartnerLocation ? "cursor-pointer" : ""} ${
          dropdownOpen ? "open" : ""
        }`}
        dropdownOpen={dropdownOpen}
        tabIndex={dropdownTabIndex}
        onKeyDown={e => dropdownOnEnter(e)}
        isSearchModeOn={isSearchModeOn}
        data-testid="partner-location-dropdown"
      >
        <div className={`toggler ${!isSearchModeOn && "fw500"} ${dropdownOpen ? "open" : ""}`}>
          {currentPartnerLocation.name || "All locations"}
        </div>
        {!onePartnerLocation && (
          <DownChevronSolid
            accessibilityTitle="Location selection"
            titleId="PartnerLocationSelectionIconTitle"
          />
        )}
      </StyledSelect>
      <DropdownMenuWrapper
        data-testid="locationDropdown"
        dropdownAlignment={DropdownAlignment.BOTTOM_END}
        className={`dropdown-menu ${dropdownOpen ? "show" : ""} mt-2 pb-25 pt-0`}
      >
        <ListItem
          aria-label="Select a location dropdown menu title"
          className="p-35 m-0 title"
          darkHover
          key={0}
          width="334px"
        >
          Select a location
        </ListItem>
        <ListItem
          aria-label="All locations"
          className={`p-35 m-0 location-option ${currentPartnerLocation.id === "" ? "active" : ""}`}
          darkHover
          key={1}
          onClick={() => {
            setDropdownOpen(false);
            onLocationSelection(AllPartnerLocations);
          }}
          width="334px"
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === KEYBOARD_KEY.ENTER) {
              setDropdownOpen(false);
              onLocationSelection(AllPartnerLocations);
            }
          }}
        >
          All locations
        </ListItem>
        {(partnerLocations || []).map(function (option, i) {
          return (
            <ListItem
              aria-label={option.name}
              className={`p-35 m-0 location-option ${
                currentPartnerLocation.id === option.id ? "active" : ""
              }`}
              darkHover
              key={i + 2}
              onClick={() => {
                const optionLocation: PartnerLocationFragment = {
                  name: option.name,
                  id: option.id,
                };
                setDropdownOpen(false);
                onLocationSelection(optionLocation);
              }}
              onKeyDown={e => {
                if (e.key === KEYBOARD_KEY.ENTER) {
                  const optionLocation: PartnerLocationFragment = {
                    name: option.name,
                    id: option.id,
                  };
                  setDropdownOpen(false);
                  onLocationSelection(optionLocation);
                }
              }}
              width="334px"
              tabIndex={0}
            >
              {option.name}
            </ListItem>
          );
        })}
      </DropdownMenuWrapper>
    </DropdownWrapper>
  );
};

export default PartnerLocationDropdown;
