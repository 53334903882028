import { Content } from "@radix-ui/react-tooltip";
import styled from "styled-components";

export const TooltipButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  outline: none;
  transition: color 0.2s ease-in-out;
  color: ${props => props.theme.ui.colors.gray600};
  align-self: flex-start;

  /* Shadow/lg */
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  &:hover {
    color: ${props => props.theme.ui.colors.primary500};
    stroke: ${props => props.theme.ui.colors.primary500};
  }
`;

export const TooltipContent = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: ${props => props.theme.ui.colors.primary300};
  padding: 12px;
`;
