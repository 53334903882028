import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { StyledCarousel } from "../ImageCarousel";

const { Item } = Carousel;

export const PhotoCarousel = styled(StyledCarousel)`
.carousel-control-prev,
.carousel-control-next {
  display: ${props => (props.$showSingleImage ? "none" : "flex")};} 
}
  &&&{
    height: 133px;
    max-width: 100px;
    min-width: 100px;
    min-height 100px;
  }
`;

export const StyledCarouselItem = styled(Item)`
  background-image: url(${props => props.url});
  &&&{
    height: 133px;
    max-width: 100px;
    min-width: 100px;
    min-height 100px;
  }
`;
