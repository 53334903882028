import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import usePartnerLocationStore, {
  PartnerLocationFragment,
} from "client/hooks/data/partner/usePartnerLocationStore";
import { BoomerangArrowLeft, PlusSignBig, StyledArrowLeft } from "../../assets/icons/icons";
import history from "../../context/history";
import { isLgQuery, isSmQuery } from "../../helpers/mediaQuery";
import { useUserState } from "../../hooks/useUserState";
import theme from "../../theme";
import { KEYBOARD_KEY } from "../../types/keyboardKey";
import { MenuItem } from "../../types/menuElements";
import { PartnerLocation, UserRoles } from "../../types/models";
import GlobalSearch from "../../ui-components/Search/components/GlobalSearch";
import WideSearch from "../../ui-components/Search/components/WideSearch";
import Button from "../Button";
import { ButtonModes } from "../Button/Button.types";
import PartnerLocationDropdown from "../Dropdowns/PartnerLocationDropdown";
import { Hamburger } from "../Hamburger";
import { PartnerRoutesEnum } from "../Routes";
import { Typography } from "../typography";
import iconLogo from "./../../assets/iconLogo.svg";
import Navigation from "./Navigation";
import {
  ButtonWrapper,
  ContentRow,
  GridElementWrapper,
  HeaderWrapper,
  NavRow,
  ReturnsIndicator,
  SearchBarSeparator,
  StyledContainerLg,
  StyledImg,
  StyledSubHeader,
  StyledTitle,
  TopBackgroundAccent,
} from "./styles";

export interface HeaderProps {
  /** If the header instance is appearing on an end user view */
  isEndUser: boolean;
  /** A state variable to keep track of if the hamburger menu is open.
   * Determines the size of the header and what should be displayed in it.
   */
  /** State modifier to open or close the hamburger on click */
  hamburgerOpen?: boolean;
  hideMenu?: boolean;
  /** A state variable to keep track of if the page is on user overview */
  isOverviewUserRole?: UserRoles;
  onHamburgerClick?: () => void;
  /** optional function to perform on click of the left arrow / logo if overriden */
  onLeftArrowClick?: () => void;
  /** Should the left arrow be overriden by the logo */
  logoOverride?: boolean;
  /** Optional prop, if passed in there will be a button in the header.
   * This function determines what happens on click
   */
  handlePrimaryButtonClick?: () => void;
  /** What the primary button says */
  primaryButtonText?: string;
  /** The title of the page, can be blank */
  title?: string | React.ReactNode;
  /** Optional text area next to the left arrow */
  leftArrowText?: React.ReactNode;
  /** List of locations for the locations dropdown */
  partnerLocations?: PartnerLocation[];
  /** Function to be performed on location selection */
  onLocationSelection?: (_: PartnerLocationFragment) => void;
  /** The current partner location */
  currentPartnerLocation?: PartnerLocationFragment;
  /** Optional component to be placed in top right of the header */
  dropdown?: React.ReactNode;
  /** Optional text area above the title */
  topSubHeaderText?: string;
  /** Optional text area below the title */
  subHeaderText?: string | (() => string);
  /** Optional, if passed there will be a navbar on the desktop view of the header */
  menuItems?: Array<MenuItem>;
  /** Count of how many items are matched */
  pendingReturnsCount?: number;
  /** Count of how many items are potential match count */
  potentialMatchCount?: number;
  /** Optional, used to support styling matching customer view */
  useHeaderWrapper?: boolean;
  /** Optional, if true styles the primary button to the AllClaims page */
  primaryButtonClaimsStyled?: boolean;
  /** Optional, the pathname of the site used in the Navigation component */
  pathname?: string;
  /** Optional, if the user has a featured claim at the top of the page */
  contentOverlapsHeader?: boolean;
  showLostSomethingLink?: boolean;
}

const MainHeader: React.FC<HeaderProps> = ({
  hamburgerOpen,
  hideMenu,
  onLeftArrowClick,
  logoOverride,
  leftArrowText,
  menuItems,
  pathname,
  isOverviewUserRole,
  partnerLocations,
  onLocationSelection,
  currentPartnerLocation,
  dropdown,
  onHamburgerClick,
  topSubHeaderText,
  subHeaderText,
  title,
  handlePrimaryButtonClick,
  primaryButtonText,
  primaryButtonClaimsStyled,
  useHeaderWrapper,
  pendingReturnsCount,
  potentialMatchCount,
  isEndUser,
  contentOverlapsHeader,
  showLostSomethingLink,
}) => {
  const isLg = isLgQuery();
  const isSm = isSmQuery();
  const [userState] = useUserState();
  const { claimsReadyForPickup } = userState;
  const fullNavFits = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.navFits})`,
  });
  const { isSearchModeOn, setLocationFilter, setIsSearchModeOn, setSearchTerm, setShowAllResults } =
    useContext(PartnerGlobalSearchContext);
  const { globalSearchLocation, setGlobalSearchLocation } = usePartnerLocationStore();

  useEffect(() => {
    setLocationFilter(globalSearchLocation.id);
  }, [globalSearchLocation]);

  const usePrimaryButton = typeof handlePrimaryButtonClick === "function" && !!primaryButtonText;
  const showLocationDropdown = partnerLocations && onLocationSelection && currentPartnerLocation;

  const getHeaderHeight = () => {
    if (isLg || contentOverlapsHeader) {
      return theme.heights.desktopHeader;
    } else if (hamburgerOpen) {
      return theme.heights.mobileMenuHeader;
    } else if (partnerLocations !== undefined && partnerLocations.length > 1 && isSearchModeOn) {
      return isSm ? "207px" : theme.heights.mobileSearchModeOnHeader;
    } else if (partnerLocations !== undefined && partnerLocations.length === 1 && isSearchModeOn) {
      return isSm ? "201px" : "236px";
    } else if (showLocationDropdown) {
      return theme.heights.mobileLocationDropdownHeader;
    } else {
      return theme.heights.mobileDefaultHeader;
    }
  };
  const onGlobalSearchLocationSearch = (newLocation: PartnerLocationFragment) => {
    setGlobalSearchLocation(newLocation);
  };

  const searchModeOffRow = (
    <>
      {
        <div className="w-100 px-0">
          <ContentRow
            className={classNames("p-0", {
              "negative-margin": usePrimaryButton && !primaryButtonClaimsStyled,
            })}
            useFullRow={showLocationDropdown && !isLg}
            userViewMaxWidth={isEndUser}
            restrictWidth
          >
            <GridElementWrapper column={1}>
              {topSubHeaderText && (
                <StyledSubHeader className="px-0">{topSubHeaderText}</StyledSubHeader>
              )}
              <div className="w-100" />
              {title && !hamburgerOpen && (
                <StyledContainerLg className="px-0 py-35 py-xs-2 ms-0 ms-xl-auto">
                  <StyledTitle className="px-0 col-auto">{title}</StyledTitle>
                  {subHeaderText && (
                    <StyledSubHeader className="px-0">{subHeaderText}</StyledSubHeader>
                  )}
                </StyledContainerLg>
              )}
              {!isLg && !hamburgerOpen && showLocationDropdown && (
                <PartnerLocationDropdown
                  currentPartnerLocation={currentPartnerLocation}
                  partnerLocations={partnerLocations}
                  onLocationSelection={onLocationSelection}
                  className={isOverviewUserRole === UserRoles.PARTNER_USER ? "mb-3" : "large-mb"}
                />
              )}
            </GridElementWrapper>
            {isLg && showLocationDropdown && (
              <GridElementWrapper
                className={classNames({
                  "justify-self-start ms-45": usePrimaryButton && !primaryButtonClaimsStyled,
                })}
                column={usePrimaryButton && !primaryButtonClaimsStyled ? 2 : 3}
              >
                <PartnerLocationDropdown
                  currentPartnerLocation={currentPartnerLocation}
                  partnerLocations={partnerLocations}
                  onLocationSelection={onLocationSelection}
                />
              </GridElementWrapper>
            )}
          </ContentRow>
        </div>
      }
      {Array.isArray(claimsReadyForPickup) &&
        claimsReadyForPickup.length === 0 &&
        usePrimaryButton && (
          <div className="w-100 px-0">
            <ButtonWrapper
              isUserButton={isEndUser}
              className="px-0 mt-35 mt-lg-n45 mx-auto text-right"
              claimsStyle={primaryButtonClaimsStyled}
            >
              {!hamburgerOpen && !isSearchModeOn && (
                <Button
                  ariaLabel={primaryButtonText}
                  text={primaryButtonText}
                  loading={!isEndUser && !partnerLocations}
                  icon={
                    !isEndUser && (
                      <PlusSignBig
                        className="me-2"
                        accessibilityTitle={primaryButtonText}
                        ariaHidden={true}
                        titleId="PrimaryHeaderButtonPlusSignTitle"
                      />
                    )
                  }
                  mode={ButtonModes.primary}
                  onClick={handlePrimaryButtonClick}
                  style={{ borderColor: theme.ui.colors.primary700, opacity: 1 }}
                />
              )}
            </ButtonWrapper>
          </div>
        )}
    </>
  );

  const searchModeOnRow = !isEndUser && !hamburgerOpen && (
    <WideSearch
      hasMultipleLocations={!!partnerLocations && partnerLocations.length > 1}
      locationDropdown={
        showLocationDropdown && isSearchModeOn && !isEndUser && !hamburgerOpen ? (
          <>
            {partnerLocations && partnerLocations.length > 1 && (
              <PartnerLocationDropdown
                isSearchModeOn={isSearchModeOn}
                currentPartnerLocation={globalSearchLocation}
                partnerLocations={partnerLocations}
                onLocationSelection={onGlobalSearchLocationSearch}
              />
            )}
          </>
        ) : undefined
      }
    />
  );

  const interiorComponent = (
    <>
      <NavRow role="navigation" className="p-0 gap-35" height="59px">
        <GridElementWrapper onClick={onLeftArrowClick} column={1} className="px-0 no-decorate">
          {logoOverride ? (
            <StyledImg
              onClick={() => {
                history.push("/partner/overview");
                if (hamburgerOpen && onHamburgerClick) {
                  onHamburgerClick();
                  if (isSearchModeOn) {
                    setIsSearchModeOn(false);
                    setSearchTerm("");
                    setShowAllResults(false);
                    return;
                  }
                }
                if (isSearchModeOn) {
                  setIsSearchModeOn(false);
                  setSearchTerm("");
                  setShowAllResults(false);
                }
              }}
              tabIndex={0} // for accessibility
              src={iconLogo}
              alt="ThanksBoomerang - Logo"
              onKeyPress={e => {
                if (e.key === KEYBOARD_KEY.ENTER) {
                  history.push("/partner/overview");
                  hamburgerOpen && onHamburgerClick && onHamburgerClick();
                  if (isSearchModeOn) {
                    setIsSearchModeOn(false);
                    setSearchTerm("");
                    setShowAllResults(false);
                  }
                }
              }}
            />
          ) : (
            // TODO Make a component for Icons with text?
            <div className="d-flex cursor-pointer align-items-center">
              <StyledArrowLeft
                tabIndex={0}
                className="me-2"
                data-testid="back-arrow"
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === KEYBOARD_KEY.ENTER) {
                    onLeftArrowClick && onLeftArrowClick();
                  }
                }}
                accessibilityTitle="Go back"
                titleId="ArrowLeftMainHeaderTitle"
              />
              <span>{leftArrowText}</span>
            </div>
          )}
        </GridElementWrapper>
        {isLg && menuItems && (
          <GridElementWrapper
            column={2}
            className={classNames("clip-overflow", {
              "justify-self-center": fullNavFits,
            })}
          >
            <Navigation
              potentialMatchCount={potentialMatchCount || 0}
              menuItems={menuItems}
              pathname={pathname || ""}
            />
          </GridElementWrapper>
        )}
        <GridElementWrapper
          column={3}
          className="px-0 no-decorate d-flex align-items-center gap-35"
        >
          {showLostSomethingLink && (
            <Typography
              color={theme.colors.white}
              fontSize={theme.fontSizes.default}
              fontWeight={theme.fontWeights.semiBold}
            >
              Lost something?{" "}
              <Link to="/lost" className="text-white">
                Report lost item
              </Link>
            </Typography>
          )}

          {!hamburgerOpen && !isEndUser && <GlobalSearch />}

          {isOverviewUserRole !== UserRoles.USER && pendingReturnsCount !== undefined && (
            <ReturnsIndicator
              tabIndex={0}
              className={classNames({
                active: pathname === PartnerRoutesEnum.RETURNS,
              })}
              data-testid="returnsLink"
              onClick={() => {
                history.push(PartnerRoutesEnum.RETURNS);
                hamburgerOpen && onHamburgerClick && onHamburgerClick();
                if (isSearchModeOn) {
                  setIsSearchModeOn(false);
                  setSearchTerm("");
                  setShowAllResults(false);
                }
              }}
              onKeyPress={e => {
                if (e.key === KEYBOARD_KEY.ENTER) {
                  history.push(PartnerRoutesEnum.RETURNS);
                  hamburgerOpen && onHamburgerClick && onHamburgerClick();
                  if (isSearchModeOn) {
                    setIsSearchModeOn(false);
                    setSearchTerm("");
                    setShowAllResults(false);
                  }
                }
              }}
            >
              <BoomerangArrowLeft
                width={16}
                height={16}
                className="me-2"
                accessibilityTitle="Go to Returns"
                titleId="MatchedIndicatorBoomerangArrowTitle"
              />
              {pendingReturnsCount >= 99 ? "99+" : pendingReturnsCount}
            </ReturnsIndicator>
          )}
          {isOverviewUserRole === UserRoles.USER &&
            Array.isArray(claimsReadyForPickup) &&
            claimsReadyForPickup.length > 0 &&
            isLg && (
              <p className="me-25">
                Lost something? <Link to="/lost">Report a lost item</Link>
              </p>
            )}
          {!hideMenu && isLg ? (
            <>{dropdown !== undefined && dropdown}</>
          ) : (
            !hideMenu &&
            onHamburgerClick &&
            hamburgerOpen !== undefined && (
              <div className="col-auto d-flex align-items-center pe-0 me-2">
                <Hamburger
                  onClick={() => {
                    onHamburgerClick();
                  }}
                  onKeyPress={e => {
                    e.preventDefault();
                    if (e.key === KEYBOARD_KEY.ENTER) {
                      onHamburgerClick();
                    }
                  }}
                  open={hamburgerOpen}
                />
              </div>
            )
          )}
        </GridElementWrapper>
      </NavRow>
      {isSearchModeOn ? (
        <SearchBarSeparator
          multipleLocations={!!(partnerLocations && partnerLocations.length > 1)}
        />
      ) : null}
      {!isSearchModeOn ? searchModeOffRow : searchModeOnRow}
    </>
  );

  return (
    <TopBackgroundAccent
      role="banner"
      height={getHeaderHeight()}
      className={classNames(
        "row mx-0 px-35 pt-35 pt-lg-2",
        {
          "align-content-between": !useHeaderWrapper,
          "mb-1": !hamburgerOpen,
        },
        "justify-content-between align-items-center",
      )}
    >
      {useHeaderWrapper ? (
        <HeaderWrapper className="p-0 h-100">{interiorComponent}</HeaderWrapper>
      ) : (
        interiorComponent
      )}
    </TopBackgroundAccent>
  );
};

export default MainHeader;
